import { userActions } from 'administration/components/user/reducers/UserReducer'
import ApplicationConf from 'conf/ApplicationConf'
import fetch from 'isomorphic-fetch'
import { chunk, flatten } from 'lodash'
import { materielActions } from 'materiel/reducers/MaterielReducer'
import moment from 'moment'
import { PiezometryActionConstant } from 'piezometry/reducers/PiezometryReducer'
import { QualityActionConstant } from 'quality/reducers/QualityReducer'
import { ContactActionConstant } from 'referencial/components/contact/reducers/ContactReducer'
import { ContributorActionConstant } from 'referencial/components/contributor/reducers/ContributorReducer'
import i18n from 'simple-react-i18n'
import ToastrAction from 'toastr/actions/ToastrAction'
import WaitAction from 'wait/WaitAction'
import UserAction from '../../administration/components/user/actions/UserAction'
import CampaignAction from '../../campaign/actions/CampaignAction'
import EventsAction from '../../events/actions/EventsAction'
import HomeAction from '../../home/actions/HomeAction'
import LogAction from '../../log/actions/LogAction'
import MaterielAction from '../../materiel/actions/MaterielAction'
import CentralAction from '../../materiel/components/central/actions/CentralAction'
import EquipmentAction from '../../materiel/components/equipment/actions/EquipmentAction'
import PowerSupplyAction from '../../materiel/components/powerSupply/actions/PowerSupplyAction'
import SensorAction from '../../materiel/components/sensor/actions/SensorAction'
import SimAction from '../../materiel/components/sim/actions/SimAction'
import SubscriptionAction from '../../materiel/components/subscription/actions/SubscriptionAction'
import TelecomAction from '../../materiel/components/telecom/actions/TelecomAction'
import VariousMaterielAction from '../../materiel/components/variousMateriel/actions/VariousMaterielAction'
import QualityAction from '../../quality/actions/QualityAction'
import ReferencialAction from '../../referencial/action/ReferencialAction'
import CityAction from '../../referencial/components/city/actions/CityAction'
import ContactAction from '../../referencial/components/contact/actions/ContactAction'
import ContributorAction from '../../referencial/components/contributor/actions/ContributorAction'
import NetworkAction from '../../referencial/components/network/actions/NetworkAction'
import StationAction from '../../station/actions/StationAction'
import AppStore from '../../store/AppStore'
import {
    checkAuth,
    checkError,
    genericPromise, getAbortSignal,
    getAuthorization,
    getJson,
    promiseAllProgress,
    promiseAllWithCancel,
} from '../../utils/ActionUtils'
import { hasValue } from '../../utils/NumberUtil'
import { getLabel } from '../../utils/StoreUtils'
import { getSetting } from 'utils/SettingUtils'
import { DEFAULT_CONTRIBUTOR_TYPE } from 'administration/components/user/constants/UserConstants'


const PiezometryAction = {
    fetchIfNeeded(prop, fetchFunc, param1) {
        return (dispatch) => {
            const elem = AppStore.getState().PiezometryReducer[prop]
            if (elem.length) {
                if (elem.length !== 0) {
                    return { type: '' }
                }
            } else if (Object.keys(elem).length !== 0) {
                return { type: '' }
            }
            return dispatch(fetchFunc(param1))
        }
    },

    promisePiezometers() {
        return fetch(ApplicationConf.piezometer.getAll(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
    },

    fetchPiezometersListSpecific(cb = () => {}) {
        return (dispatch) => {
            return PiezometryAction.promisePiezometerListSpecific()
                .then((json = []) => {
                    dispatch(PiezometryActionConstant.receivePiezometerListSpecific(json))
                    cb()
                })
                .catch((err) => {
                    dispatch(LogAction.logError(`${i18n.fetchError} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError))
                })
        }
    },

    promisePiezometerListSpecific() {
        return genericPromise(ApplicationConf.piezometer.listSpecific())
    },

    promisePiezometersLight() {
        return genericPromise(`${ApplicationConf.piezometer.getAll()}?lightMode=true`)
    },

    fetchPiezometersLight(cb = () => {}) {
        return (dispatch) => {
            return PiezometryAction.promisePiezometersLight()
                .then((json = []) => {
                    dispatch(PiezometryActionConstant.receiveAllPiezometersLight(json))
                    cb()
                })
                .catch((err) => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.piezometers} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.piezometers))
                })
        }
    },

    fetchPiezometers(callback = () => {}) {
        return (dispatch) => {
            return PiezometryAction.promisePiezometers()
                .then((json = []) => {
                    dispatch(UserAction.fetchBookmarks())
                    dispatch(PiezometryActionConstant.receiveAllPiezometers(json))
                    callback()
                })
                .catch((err) => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.piezometers} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.piezometers))
                })
        }
    },

    promisePiezometersWithCampaignsAndEvents() {
        return fetch(ApplicationConf.piezometer.getPiezometerDataWithCampaignsAndEvents(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
    },

    fetchPiezometersWithCampaignsAndEvents(callback = () => {}) {
        return (dispatch) => {
            return PiezometryAction.promisePiezometersWithCampaignsAndEvents()
                .then((json = []) => {
                    dispatch(PiezometryActionConstant.receivePiezometerStationDataCampaignsEventsRead(json))
                    callback()
                })
                .catch((err) => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.piezometers} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.piezometers))
                })
        }
    },

    fetchAllPiezometers: () => dispatch => PiezometryAction.promisePiezometers().then((json = []) => {
        dispatch(PiezometryActionConstant.receiveAllPiezometers(json))
        return json
    }),

    fetchKeyFigures() {
        return (dispatch) => {
            return fetch(ApplicationConf.keyfigures.piezometry(), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then((json = []) => {
                    dispatch(PiezometryActionConstant.receivePiezometerKeyFigures(json))
                })
        }
    },

    promiseAllPiezometrySituation(piezometers) {
        return Promise.all(piezometers.map(piezometer => fetch(ApplicationConf.piezometer.piezometerMeasures(piezometer.id), {
            method: 'GET',
            headers: getAuthorization(),
        }).then(checkAuth).then(getJson)))
    },

    fetchPiezometrySituation(piezometers) {
        return (dispatch) => {
            return PiezometryAction.promiseAllPiezometrySituation(piezometers)
                .then(tabResults => dispatch(PiezometryActionConstant.receivePiezometrySituation(tabResults.map((results, index) => ({
                    piezometerId: piezometers[index].id,
                    measures: results,
                })))))
        }
    },

    fetchPiezometrySituations(date = moment().valueOf(), nbDays = 30) {
        return (dispatch) => {
            return fetch(ApplicationConf.piezometer.situation(date, nbDays), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then(json => {
                    dispatch(WaitAction.waitStop())
                    dispatch(PiezometryActionConstant.receivePiezometrySituations(json))
                })
        }
    },

    promisePiezoSituationLastMeasures(ids) {
        return genericPromise(ApplicationConf.piezometer.situationLastMeasures(), 'POST', { ids })
    },
    fetchPiezoSituationLastMeasures(ids) {
        return (dispatch) => {
            return PiezometryAction.promisePiezoSituationLastMeasures(ids)
                .then(json => {
                    dispatch(PiezometryActionConstant.receivePiezoSituationLastMeasures(json))
                })
                .catch((err) => {
                    dispatch(LogAction.logError(`${i18n.fetchError} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError))
                })
        }
    },

    promisePiezometrySituation(criterias, stations, type) {
        return fetch(ApplicationConf.piezometer.situation(type), {
            method: 'POST',
            headers: getAuthorization(),
            signal: getAbortSignal(),
            body: JSON.stringify({ ...criterias, piezometerIds: stations }),
        })
            .then(checkAuth).then(getJson).then(checkError).catch(err => {
                if (err.name !== 'AbortError') {
                    AppStore.dispatch(ToastrAction.error(i18n.dataError))
                    AppStore.dispatch(LogAction.logError(`${i18n.dataError}: ${err}`))
                }
                return []
            })
    },

    fetchPiezometryTypeSituation(criterias, piezometerIds, progressCallback = () => {}, callback = () => {}) { // date, nbDays, dataType, piezometerIds (list of ids)
        return (dispatch) => {
            const groupsSize = piezometerIds.length / 50 > 20 ? piezometerIds.length / 20 : 50
            const promises = chunk(piezometerIds, groupsSize).map(stationsGroup => PiezometryAction.promisePiezometrySituation(criterias, stationsGroup, 'type'))
            return promiseAllWithCancel(promises, progressCallback, dispatch)
                .then(json => {
                    dispatch(PiezometryActionConstant.receivePiezometryTypeSituation(flatten(json)))
                    callback()
                })
        }
    },

    fetchPiezometryTypeSituationWithDays(criterias, piezometerIds, progressCallback = () => {}, callback = () => {}) { // date, nbDays, dataType, piezometerIds (list of ids)
        return (dispatch) => {
            const groupsSize = piezometerIds.length / 50 > 20 ? piezometerIds.length / 20 : 50
            const promises = chunk(piezometerIds, groupsSize).map(stationsGroup => PiezometryAction.promisePiezometrySituation(criterias, stationsGroup, 'type'))
            const allPromises = [...promises, PiezometryAction.promisePiezometrySituation(criterias, piezometerIds, 'days')]
            const count = allPromises.length
            return promiseAllWithCancel(allPromises, progressCallback, dispatch)
                .then(json => {
                    if (json.length === count) {
                        const days = json.pop()
                        dispatch(PiezometryActionConstant.receivePiezometryTypeSituation(flatten(json)))
                        dispatch(PiezometryActionConstant.receivePiezometryDaysSituation(flatten(days)))
                        callback()
                    } else {
                        dispatch(PiezometryActionConstant.receivePiezometryTypeSituation([]))
                    }
                })
        }
    },

    promisePiezometryDataTypes() {
        return fetch(ApplicationConf.piezometer.dataTypes(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
    },

    fetchPiezometryDataTypes() {
        return (dispatch) => {
            return PiezometryAction.promisePiezometryDataTypes()
                .then(json => {
                    dispatch(PiezometryActionConstant.receivePiezometryDataTypes(json))
                })
                .catch((err) => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.dataTypes} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.dataTypes))
                })
        }
    },

    promisePiezometersAccessibilities() {
        return fetch(ApplicationConf.piezometer.getAllAccessibilities(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
    },

    fetchPiezometersAccessibilities() {
        return dispatch => {
            return PiezometryAction.promisePiezometersAccessibilities()
                .then((json = []) => {
                    dispatch(PiezometryActionConstant.receiveAllPiezometersAccessibilities(json))
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.piezometersAccessibilities} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.piezometersAccessibilities))
                })
        }
    },

    createPiezometer(newPiezometer, callback) {
        return function (dispatch) {
            dispatch(WaitAction.waitStart())
            return fetch(ApplicationConf.piezometer.piezometerPath(), {
                method: 'POST',
                headers: getAuthorization(),
                body: JSON.stringify(newPiezometer),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(json => {
                    if (json.insert === 1 && json.id) {
                        dispatch(WaitAction.waitStop())
                        dispatch(PiezometryActionConstant.createPiezometer({ ...newPiezometer, id: json.id }))
                        dispatch(ToastrAction.success(i18n.elementCreateSuccess))
                        callback(json.id)
                    } else {
                        throw new Error(json)
                    }
                })
                .catch((err) => {
                    dispatch(WaitAction.waitStop())
                    dispatch(LogAction.logError(`${i18n.createError + i18n.piezometry} : ${err}`))
                    dispatch(ToastrAction.error(i18n.createError + i18n.piezometry))
                })
        }
    },

    updatePiezometersAccessibilities(idPiezo, listAcc) {
        return dispatch => {
            return fetch(ApplicationConf.piezometer.getAccessibilities(idPiezo), {
                method: 'PUT',
                headers: getAuthorization(),
                body: JSON.stringify(listAcc),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(json => {
                    if (json) {
                        dispatch(PiezometryAction.fetchPiezometersAccessibilities())
                    }
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.updateError + i18n.piezometersAccessibilities} : ${err}`))
                    dispatch(ToastrAction.error(i18n.updateError + i18n.piezometersAccessibilities))
                })
        }
    },

    deletePiezometer(id, callback) {
        return function (dispatch) {
            dispatch(WaitAction.waitStart())
            return fetch(ApplicationConf.piezometer.piezometerPath(), {
                method: 'DELETE',
                headers: getAuthorization(),
                body: JSON.stringify({ id }),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(json => {
                    if (json.delete === 1) {
                        dispatch(WaitAction.waitStop())
                        dispatch(PiezometryActionConstant.deletePiezometer(id))
                        dispatch(ToastrAction.success(i18n.elementDeleteSuccess))
                        callback()
                    } else {
                        throw new Error(json)
                    }
                })
                .catch((err) => {
                    dispatch(WaitAction.waitStop())
                    dispatch(LogAction.logError(`${i18n.deleteError + i18n.piezometry} : ${err}`))
                    dispatch(ToastrAction.error(i18n.deleteError + i18n.piezometry))
                })
        }
    },

    formatPiezometersReferents: (linkContributors, contributors, contacts, contributorType) => {
        const referents = linkContributors.filter(p => p.contributorType === parseInt(contributorType)).map(p => {
            const contact = hasValue(p.contactCode) ? contacts.find(c => c.id === p.contactCode) : null
            const contributor = getLabel(contributors, p.idContributor, 'mnemonique', 'id')
            const label = `${contact ? contact.name : ''} ${contributor ? `[${contributor}]` : ''}`
            return {
                idStation: p.idStation,
                idContributor: p.idContributor,
                contributorType: p.contributorType,
                code: label,
                name: label,
                p,
                contact,
                contributor,
            }
        })
        return referents
    },

    promisePiezometryMeasureModes() {
        return genericPromise(ApplicationConf.piezometer.measureModes())
    },
    fetchPiezometryMeasureModes() {
        return dispatch => {
            return PiezometryAction.promisePiezometryMeasureModes()
                .then((json = []) => {
                    dispatch(PiezometryActionConstant.receivePiezometryMeasureModes(json))
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.piezometersAccessibilities} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.piezometersAccessibilities))
                })
        }
    },

    promisePiezoExportOverview(filter) {
        return genericPromise(ApplicationConf.job.exportOverview(), 'POST', filter)
    },

    // formater coté back
    fetchPiezometersReferents() {
        return (dispatch, getState) => {
            return Promise.all([
                ContactAction.promiseContacts(),
                ContributorAction.promiseContributors(),
                PiezometryAction.promisePiezometersContributors(),
            ]).then(jsonTab => {
                dispatch(ContactActionConstant.receiveAllContacts(jsonTab[0]))
                dispatch(ContributorActionConstant.receiveAllContributors(jsonTab[1]))
                const settings = getState().AdministrationReducer.applicationSettings
                const referentType = getSetting(settings, 'contributorTypeAdministrator') || DEFAULT_CONTRIBUTOR_TYPE.ADMINISTRATOR
                const operatorType = getSetting(settings, 'contributorTypeOperator') || DEFAULT_CONTRIBUTOR_TYPE.OPERATOR
                const referents = PiezometryAction.formatPiezometersReferents(jsonTab[2], jsonTab[1], jsonTab[0], referentType)
                const operators = PiezometryAction.formatPiezometersReferents(jsonTab[2], jsonTab[1], jsonTab[0], operatorType)
                dispatch(PiezometryActionConstant.receiveAllPiezometersReferents(referents))
                dispatch(PiezometryActionConstant.receiveAllPiezometersOperators(operators))
            }).catch(err => {
                dispatch(LogAction.logError(`${i18n.loadError} : ${err}`))
                dispatch(ToastrAction.error(i18n.loadError))
            })
        }
    },

    promisePiezometersContributors() {
        return fetch(ApplicationConf.piezometer.getAllContributorLinks(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
    },

    fetchPiezometersContributors() {
        return dispatch => {
            return PiezometryAction.promisePiezometersContributors()
                .then((json = []) => {
                    dispatch(PiezometryActionConstant.receiveAllPiezometersContributors(json))
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.contributors} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.contributors))
                })
        }
    },

    refreshOnePiezometerSituation(id, dataType = -1) {
        return (dispatch) => {
            const situationCache = AppStore.getState().PiezometryReducer.cachePiezometrySituation
            if (situationCache && situationCache.filter.dataType === dataType) {
                const criterias = { date: situationCache.filter.date, nbDays: situationCache.filter.nbDays, dataType, nbDaysAlert: situationCache.filter.nbDaysAlert, nbDaysUndetected: situationCache.filter.nbDaysUndetected }
                const promises = [PiezometryAction.promisePiezometrySituation(criterias, [id], 'type'), PiezometryAction.promisePiezometrySituation(criterias, [id], 'days')]
                return Promise.all(promises)
                    .then(json => {
                        dispatch(PiezometryActionConstant.refreshOnePiezoSituation({ types: json[0][0], days: json[1][0] }))
                    })
            }
            return dispatch({ type: 'NOTHING' })
        }
    },

    promiseNetworkPiezoLink() {
        return genericPromise(ApplicationConf.piezometer.getAllNetworkPiezoLink())
    },

    fetchNetworkPiezoLink() {
        return (dispatch) => {
            return PiezometryAction.promiseNetworkPiezoLink()
                .then((json = []) => {
                    dispatch(PiezometryActionConstant.receiveNetworkPiezoLink(json))
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.networks} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.networks))
                })
        }
    },

    loadPiezometerValidationPanel(callback = () => {}) {
        return (dispatch) => {
            dispatch(WaitAction.waitStart())
            return Promise.all([
                QualityAction.promiseQualifications(),
                QualityAction.promiseStatus(),
            ]).then(jsonTab => {
                dispatch(QualityActionConstant.receiveQualifications(jsonTab[0]))
                dispatch(QualityActionConstant.receiveStatus(jsonTab[1]))
                dispatch(WaitAction.waitStop())
                callback()
            }).catch((err) => {
                dispatch(WaitAction.waitStop())
                dispatch(LogAction.logError(`${i18n.loadError} : ${err}`))
                dispatch(ToastrAction.error(i18n.loadError))
            })
        }
    },

    promisePiezometerChartLandmarks(idList) {
        return fetch(ApplicationConf.piezometer.chartLandmarks(), {
            method: 'POST',
            headers: getAuthorization(),
            body: JSON.stringify({ ids: idList }),
        }).then(checkAuth)
            .then(getJson)
            .then(checkError)
    },

    fetchPiezometerChartLandmarks(idList) {
        return (dispatch) => {
            return PiezometryAction.promisePiezometerChartLandmarks(idList)
                .then(landmarksTab => {
                    dispatch(PiezometryActionConstant.receivePiezometryChartLandmark(landmarksTab))
                }).catch((err) => {
                    dispatch(WaitAction.waitStop())
                    dispatch(LogAction.logError(`${i18n.loadLandmarkError} : ${err}`))
                    dispatch(ToastrAction.error(i18n.loadLandmarkError))
                })
        }
    },

    loadEventPanel: (progressCallback = () => { }) => dispatch => promiseAllProgress([
        CentralAction.promiseCentrals(),
        CentralAction.promiseCentralTypes(),
        CentralAction.promiseCentralLastSituation(),
        PowerSupplyAction.promisePowerSupplies(),
        PowerSupplyAction.promisePowerSupplyTypes(),
        PowerSupplyAction.promisePowerSuppliesLastSituation(),
        SensorAction.promiseSensors(),
        SensorAction.promiseSensorTypes(),
        SensorAction.promiseSensorLastSituation(),
        TelecomAction.promiseTelecoms(),
        TelecomAction.promiseTelecomTypes(),
        TelecomAction.promiseTelecomLastSituation(),
        SimAction.promiseSims(),
        SimAction.promiseSimTypes(),
        SimAction.promiseSimsLastSituations(),
        VariousMaterielAction.promiseVariousMateriels(),
        VariousMaterielAction.promiseVariousMaterielTypes(),
        VariousMaterielAction.promiseVariousMaterielsLastSituations(),
        EquipmentAction.promiseEquipments(),
        EquipmentAction.promiseEquipmentTypes(),
        EquipmentAction.promiseEquipmentsLastSituations(),
        SubscriptionAction.promiseSubscriptions(),
        SubscriptionAction.promiseSubscriptionTypes(),
        SubscriptionAction.promiseSubscriptionsLastSituations(),
        MaterielAction.promiseMaterielStates(),
    ], progressCallback).then(jsonTab => {
        dispatch(CentralAction.receiveCentrals(jsonTab[0]))
        dispatch(CentralAction.receiveCentralTypes(jsonTab[1]))
        dispatch(CentralAction.receiveCentralsLastSituations(jsonTab[2]))
        dispatch(PowerSupplyAction.receivePowerSupplies(jsonTab[3]))
        dispatch(PowerSupplyAction.receivePowerSupplyTypes(jsonTab[4]))
        dispatch(PowerSupplyAction.receivePowerSupplyLastSituations(jsonTab[5]))
        dispatch(SensorAction.receiveSensors(jsonTab[6]))
        dispatch(SensorAction.receiveSensorTypes(jsonTab[7]))
        dispatch(SensorAction.receiveSensorLastSituations(jsonTab[8]))
        dispatch(TelecomAction.receiveTelecoms(jsonTab[9]))
        dispatch(TelecomAction.receiveTelecomTypes(jsonTab[10]))
        dispatch(TelecomAction.receiveTelecomLastSituations(jsonTab[11]))
        dispatch(SimAction.receiveSims(jsonTab[12]))
        dispatch(SimAction.receiveSimTypes(jsonTab[13]))
        dispatch(SimAction.receiveSimLastSituations(jsonTab[14]))
        dispatch(VariousMaterielAction.receiveVariousMateriels(jsonTab[15]))
        dispatch(VariousMaterielAction.receiveVariousMaterielTypes(jsonTab[16]))
        dispatch(VariousMaterielAction.receiveVariousMaterielLastSituations(jsonTab[17]))
        dispatch(EquipmentAction.receiveEquipments(jsonTab[18]))
        dispatch(EquipmentAction.receiveEquipmentTypes(jsonTab[19]))
        dispatch(EquipmentAction.receiveEquipmentLastSituations(jsonTab[20]))
        dispatch(SubscriptionAction.receiveSubscriptions(jsonTab[21]))
        dispatch(SubscriptionAction.receiveSubscriptionTypes(jsonTab[22]))
        dispatch(SubscriptionAction.receiveSubscriptionsLastSituations(jsonTab[23]))
        dispatch(materielActions.states(jsonTab[24]))
    }),

    loadPiezometersTable: (progressCallback = () => { }, cb = () => {}) => (dispatch, getState) => promiseAllProgress([
        PiezometryAction.promisePiezometerListSpecific(),
        StationAction.promiseFilters(),
        ReferencialAction.promiseSandreCodes(),
        UserAction.promiseBookmarks(),
        ContactAction.promiseContacts(),
        ContributorAction.promiseContributors(),
        CampaignAction.promiseCampaigns('piezometry'),
        CampaignAction.promiseCampaignsProgress('piezometry'),
        CampaignAction.promiseCampaignStations('piezometry'),
        HomeAction.promiseDepartments(),
        PiezometryAction.promisePiezometersContributors(),
        PiezometryAction.promiseNetworkPiezoLink(),
        NetworkAction.promiseNetworks(),
        CityAction.promiseCities(),
    ], progressCallback).then(jsonTab => {
        dispatch(PiezometryActionConstant.receivePiezometerListSpecific(jsonTab[0]))
        dispatch(StationAction.receiveFilters(jsonTab[1]))
        dispatch(ReferencialAction.receiveSandreCodes(jsonTab[2]))
        dispatch(userActions.receiveAllBookmarks(jsonTab[3]))
        dispatch(ContactActionConstant.receiveAllContacts(jsonTab[4]))
        dispatch(ContributorActionConstant.receiveAllContributors(jsonTab[5]))
        dispatch(PiezometryActionConstant.receivePiezometryCampaigns(jsonTab[6]))
        dispatch(PiezometryActionConstant.receivePiezometryCampaignsProgress(jsonTab[7]))
        dispatch(PiezometryActionConstant.receivePiezometryCampaignsStations(jsonTab[8]))
        dispatch(HomeAction.receiveDepartments(jsonTab[9]))
        const settings = getState().AdministrationReducer.applicationSettings
        const referentType = getSetting(settings, 'contributorTypeAdministrator') || DEFAULT_CONTRIBUTOR_TYPE.ADMINISTRATOR
        dispatch(PiezometryActionConstant.receiveAllPiezometersReferents(PiezometryAction.formatPiezometersReferents(jsonTab[10], jsonTab[5], jsonTab[4], referentType)))
        dispatch(PiezometryActionConstant.receiveNetworkPiezoLink(jsonTab[11]))
        dispatch(NetworkAction.receiveNetworks(jsonTab[12]))
        dispatch(CityAction.receiveCities(jsonTab[13]))
        cb()
    }).catch(err => {
        dispatch(LogAction.logError(`${i18n.loadError} : ${err}`))
        dispatch(ToastrAction.error(i18n.loadError))
    }),

    loadPiezometrySituation: (progressCallback = () => { }, cb = () => {}) => (dispatch, getState) => promiseAllProgress([
        PiezometryAction.promisePiezometersLight(),
        StationAction.promiseFilters(),
        UserAction.promiseBookmarks(),
        ContributorAction.promiseContributors(),
        ContactAction.promiseContacts(),
        CampaignAction.promiseCampaigns('piezometry'),
        CampaignAction.promiseCampaignsProgress('piezometry'),
        CampaignAction.promiseCampaignStations('piezometry'),
        PiezometryAction.promisePiezometersContributors(),
        CityAction.promiseCities(),
        PiezometryAction.promisePiezometryDataTypes(),
        EventsAction.promisePiezometryAllEvents(['P', 'S']),
        PiezometryAction.promiseNetworkPiezoLink(),
        NetworkAction.promiseNetworks(),
        PiezometryAction.promisePiezometryMeasureModes(),
        ReferencialAction.promiseSandreCodes(),
        QualityAction.promiseStatus(),
    ], progressCallback).then(jsonTab => {
        dispatch(PiezometryActionConstant.receiveAllPiezometersLight(jsonTab[0]))
        dispatch(StationAction.receiveFilters(jsonTab[1]))
        dispatch(userActions.receiveAllBookmarks(jsonTab[2]))
        dispatch(ContributorActionConstant.receiveAllContributors(jsonTab[3]))
        dispatch(ContactActionConstant.receiveAllContacts(jsonTab[4]))
        dispatch(PiezometryActionConstant.receivePiezometryCampaigns(jsonTab[5]))
        dispatch(PiezometryActionConstant.receivePiezometryCampaignsProgress(jsonTab[6]))
        dispatch(PiezometryActionConstant.receivePiezometryCampaignsStations(jsonTab[7]))
        const settings = getState().AdministrationReducer.applicationSettings
        const referentType = getSetting(settings, 'contributorTypeAdministrator') || DEFAULT_CONTRIBUTOR_TYPE.ADMINISTRATOR
        dispatch(PiezometryActionConstant.receiveAllPiezometersReferents(PiezometryAction.formatPiezometersReferents(jsonTab[8], jsonTab[3], jsonTab[4], referentType)))
        dispatch(CityAction.receiveCities(jsonTab[9]))
        dispatch(PiezometryActionConstant.receivePiezometryDataTypes(jsonTab[10]))
        dispatch(EventsAction.receivePiezometryAllEvents(jsonTab[11]))
        dispatch(PiezometryActionConstant.receiveNetworkPiezoLink(jsonTab[12]))
        dispatch(NetworkAction.receiveNetworks(jsonTab[13]))
        dispatch(PiezometryActionConstant.receivePiezometryMeasureModes(jsonTab[14]))
        dispatch(ReferencialAction.receiveSandreCodes(jsonTab[15]))
        dispatch(QualityActionConstant.receiveStatus(jsonTab[16]))
        cb()
    }).catch(err => {
        dispatch(LogAction.logError(`${i18n.loadError} : ${err}`))
        dispatch(ToastrAction.error(i18n.loadError))
    }),
}

export default PiezometryAction
