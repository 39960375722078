import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { Grid } from '@mui/material'
import { mainBlue } from 'utils/constants/ColorTheme'
import { sieauTooltip } from 'utils/FormUtils'
import { isNil } from 'lodash'

const SimpleTabList = ({
    defaultTab,
    controlTab,
    setControlTab,
    tabs = [],
    children,

    style = {},
}) => {
    const [tab, setTab] = useState(defaultTab ?? tabs[0]?.constant)

    if (isNil(controlTab) + isNil(setControlTab) === 1) { // one is undefined and the other is defined
        // eslint-disable-next-line no-console
        console.error('controlTab and setControlTab must both be defined if you want to control the behavior')
    }

    const currentTab = controlTab ?? tab
    const setCurrentTab = setControlTab ?? setTab

    return (
        <>
            <Grid container style={{ justifyContent: 'flex-end' }}>
                <Grid item style={{ marginRight: 4, marginTop: 10, padding: 0 }}>
                    <Grid container className='tabs round-tab' style={{ borderRadius: '5px 5px 0 0' }}>
                        {tabs.map(({ label, icon, constant }, i) => (
                            <Grid
                                item
                                className={`tab ${tabs.length - 1 !== i ? 'hash-tab' : ''}`}
                                onClick={() => setCurrentTab(constant)}
                                {...sieauTooltip(label)}
                                key={constant}
                            >
                                <a className={`${constant === currentTab ? 'active' : ''}`} style={{ color: constant === currentTab ? 'white' : 'black', backgroundColor: constant !== currentTab && 'white' }}>
                                    <i className='material-icons inherited-line-height'>{icon}</i>
                                </a>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            </Grid>
            <div
                style={{
                    border: `2px solid ${mainBlue}`,
                    backgroundColor: 'white',
                    borderRadius: 5,
                    ...style,
                }}
            >
                {children(currentTab)}
            </div>
        </>
    )
}

SimpleTabList.propTypes = {
    defaultTab: PropTypes.string.isRequired,
    controlTab: PropTypes.string,
    setControlTab: PropTypes.func,
    tabs: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string,
        icon: PropTypes.string,
        constant: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })).isRequired,
    children: PropTypes.func.isRequired,

    style: PropTypes.shape({}),
}

export default SimpleTabList
