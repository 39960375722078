import React from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import Table from '../../../components/datatable/Table'
import { nbPerPageLabel } from '../../../referencial/constants/ReferencialConstants'
import PropTypes from 'prop-types'
import { Grid } from '@mui/material'
import i18n from 'simple-react-i18n'
import { push } from 'connected-react-router'
import { PERIMETERS_STATES_COLORS } from '../constants/PerimetersConstants'
import { searchAllCharacters } from '../../../utils/StringUtil'
import { compact, isUndefined } from 'lodash'
import useSandreList from '../../../utils/customHook/useSandreList'
import { hasValue } from 'utils/NumberUtil'
import useProgressDispatch from 'utils/customHook/useProgressDispatch'
import PerimetersThunk from 'perimeters/thunk/PerimetersThunk'
import CityAction from 'referencial/components/city/actions/CityAction'
import ContributorThunk from 'referencial/components/contributor/actions/ContributorThunk'
import ProgressCard from 'components/card/ProgressCard'
import { WhiteCard } from 'components/styled/Card'
import Card from 'components/card/Card'

const PERIMETERS_FOLDER_HEADER = ['name', 'cities', 'collectivity', 'nbPerimeters', 'stateOfProgress', 'status']

const ParentCard = ({
    dashboard = false,
    title = '',
    children,
}) => !dashboard ? <WhiteCard title={title} round>{children}</WhiteCard> : <Card title={title} round>{children}</Card>

ParentCard.propTypes = {
    dashboard: PropTypes.bool,
    title: PropTypes.string,
    children: PropTypes.element,
}

const PerimetersFolderTable = ({
    dashboard = false,
}) => {
    const dispatch = useDispatch()

    const {
        selectedSearchValues,
        contributors,
        citiesIndex,
        cities,
        perimetersFolder,
    } = useSelector(store => ({
        selectedSearchValues: store.AdministrationReducer.selectedSearchValues,
        contributors: store.ContributorReducer.contributors,
        cities: store.CityReducer.cities,
        citiesIndex: store.CityReducer.citiesIndex,
        perimetersFolder: store.PerimetersReducer.perimetersFolder,
    }), shallowEqual)

    const { isLoaded, progress } = useProgressDispatch(() => compact([
        !perimetersFolder.length && dispatch(PerimetersThunk.getPerimetersFolders()),
        !cities.length && dispatch(CityAction.fetchCities()),
        !contributors.length && dispatch(ContributorThunk.fetchContributors()),
    ]), [])

    const { perimetersFolder: searchValues } = selectedSearchValues
    const {
        searchValue,
        status,
        state,
        city,
        collectivity,
    } = searchValues ?? {}

    const filterData = () => {
        const searchValueCharacters = searchAllCharacters(searchValue)
        const statusParsed = parseInt(status)
        const stateParsed = parseInt(state)
        const cityParsed = parseInt(city)
        const collectivityParsed = parseInt(collectivity)

        const searchFilteredData = !isUndefined(searchValue)
            ? perimetersFolder.filter(folder => searchAllCharacters(folder.name).includes(searchValueCharacters))
            : perimetersFolder

        const statusFilteredData = !isUndefined(status)
            ? searchFilteredData.filter(folder => statusParsed === parseInt(folder.status))
            : searchFilteredData

        const stateFilteredData = !isUndefined(state)
            ? statusFilteredData.filter(folder => stateParsed === parseInt(folder.state))
            : statusFilteredData

        const cityFilteredData = !isUndefined(city)
            ? stateFilteredData.filter(folder => parseInt(cityParsed) === parseInt(folder.city))
            : stateFilteredData

        const collectivityFilteredData = !isUndefined(collectivity)
            ? cityFilteredData.filter(folder => collectivityParsed === parseInt(folder.collectivity))
            : cityFilteredData

        return collectivityFilteredData
    }

    const lexiconStatus = useSandreList('PARCELLES.STATUT')
    const lexiconStates = useSandreList('PARCELLES.ETAT')

    const perimetersFolderFormated = filterData().map(folder => {
        const folderCities = folder.cities?.map(c => citiesIndex[c]?.name)

        return {
            code: folder.id,
            name: folder.name,
            cities: folderCities?.join(', ') ?? '',
            stateOfProgress: lexiconStates.find(s => s.code === folder.state)?.name ?? '',
            status: hasValue(folder.status) ? (
                <Grid container item xs={'auto'} alignItems={'center'}>
                    <Grid item paddingRight={'5px'}>
                        <Grid
                            item
                            sx={{
                                padding: '0.5rem',
                                backgroundColor: PERIMETERS_STATES_COLORS[folder.status] ?? PERIMETERS_STATES_COLORS[0],
                                borderRadius: '50rem',
                                border: 'solid 2px black',
                            }}
                        />
                    </Grid>
                    <Grid item>
                        {lexiconStatus.find(s => s.code === folder.status)?.name ?? ''}
                    </Grid>
                </Grid>
            ) : '',
            collectivity: folder.collectivity ? contributors?.find(c => c.id === folder.collectivity)?.name : '',
            nbPerimeters: folder.perimeters ?? '',
        }
    })

    return (
        <Grid item xs={12} sx={{ paddingTop: dashboard && '10px' }}>
            {!isLoaded ? (
                <ProgressCard progress={progress} />
            ) : (
                <ParentCard
                    dashboard={dashboard}
                    title={!dashboard ? i18n.perimetersFolder : (
                        <a className='waves-effect' onClick={() => dispatch(push('/perimeter'))}>
                            <i className='material-icons '>public</i>
                            {i18n.perimetersFolder}
                        </a>
                    )}
                >
                    <Table
                        showTitle={false}
                        data={perimetersFolderFormated}
                        paging
                        nbPerPageLabel={nbPerPageLabel}
                        type={{ headers: PERIMETERS_FOLDER_HEADER }}
                        onClick={f => dispatch(push(`/perimeter/${f.code}`))}
                        condensed
                        sortable
                        invertedHeaderStyle
                        round={!dashboard}
                    />
                </ParentCard>
            )}
        </Grid>
    )
}

PerimetersFolderTable.propTypes = {
    dashboard: PropTypes.bool,
}

export default PerimetersFolderTable