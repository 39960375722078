import React, { useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { Box, Button, DialogActions, Grid } from '@mui/material'
import Input from '../../../components/forms/Input'
import i18n from 'simple-react-i18n'
import AdministrationAction from '../../../administration/actions/AdministrationAction'
import Select from '../../../components/forms/Select'
import useTitle from '../../../utils/customHook/useTitle'
import useActions from '../../../utils/customHook/useActions'
import { DialogContentMUI, DialogMUI, DialogTitleMUI } from '../../../components/styled/Dialog'
import Icon from '../../../components/icon/Icon'
import { StyledFieldSet } from '../../../components/StyledElements'
import PerimetersThunk from '../../thunk/PerimetersThunk'
import ReferencialAction from '../../../referencial/action/ReferencialAction'
import MultiContributorsAutocomplete from '../../../referencial/components/contributor/components/MultiContributorsAutocomplete'
import useSandreList from '../../../utils/customHook/useSandreList'
import ToastrAction from '../../../toastr/actions/ToastrAction'
import { WhiteCard } from 'components/styled/Card'
import { ButtonMUI } from 'components/styled/Buttons'
import PerimetersFolderTable from './PerimetersFolderTable'

const PERIMETERS_FOLDER_HEADER = ['name', 'cities', 'collectivity', 'nbPerimeters', 'stateOfProgress', 'status']

const FilterPanel = () => {
    const {
        perimetersFolder,
        selectedSearchValues,
        cities,
        contributors,
    } = useSelector(store => ({
        perimetersFolder: store.PerimetersReducer.perimetersFolder,
        selectedSearchValues: store.AdministrationReducer.selectedSearchValues,
        cities: store.CityReducer.cities,
        contributors: store.ContributorReducer.contributors,
    }), shallowEqual)

    const [searchValue, setSearchValue] = useState(selectedSearchValues.perimetersFolder?.searchValue ?? '')
    const [status, setStatus] = useState(selectedSearchValues.perimetersFolder?.status)
    const [state, setState] = useState(selectedSearchValues.perimetersFolder?.state)
    const [city, setCity] = useState(selectedSearchValues.perimetersFolder?.city)
    const [collectivity, setCollectivity] = useState(selectedSearchValues.perimetersFolder?.collectivity)

    const perimetersStatus = useSandreList('PARCELLES.STATUT')
    const perimetersStates = useSandreList('PARCELLES.ETAT')

    const dispatch = useDispatch()

    const onLaunchSearch = () => {
        dispatch(AdministrationAction.setCache('perimetersFolder', { searchValue, status, state, city, collectivity }))
        dispatch(AdministrationAction.setSelectedSearchValues('perimetersFolder', { searchValue, status, state, city, collectivity }))
    }

    const onReset = () => {
        setSearchValue('')
        setStatus(undefined)
        setState(undefined)
        setCity(undefined)
        setCollectivity(undefined)
        onLaunchSearch()
    }

    return (
        <WhiteCard round noMargin={false} cardStyle={{ width: '100%' }}>
            <Grid container alignItems='baseline' sx={{ padding: '1rem' }} columnSpacing={2}>
                <Grid item xs={4}>
                    <Input
                        title={i18n.search}
                        tooltip={i18n.searchThenEnter}
                        value={searchValue}
                        onChange={setSearchValue}
                        onEnterKeyPress={v => {
                            setSearchValue(v)
                            onLaunchSearch()
                        }}
                        clearFunction
                    />
                </Grid>
                <Grid item xs={4}>
                    <Select
                        options={perimetersStates}
                        label={i18n.stateOfProgress}
                        nullLabel='&nbsp;'
                        value={state}
                        noSort
                        onChange={setState}
                    />
                </Grid>
                <Grid item xs={4}>
                    <Select
                        options={perimetersStatus}
                        label={i18n.status}
                        nullLabel='&nbsp;'
                        value={status}
                        noSort
                        onChange={setStatus}
                    />
                </Grid>
                <Grid container item xs={9} columnSpacing={1} alignItems='center'>
                    <Grid item xs={6}>
                        <Select
                            options={cities.filter(c => perimetersFolder.some(perimeterFolder => perimeterFolder.cities.includes(c.id)))}
                            label={i18n.city}
                            nullLabel='&nbsp;'
                            value={city}
                            noSort
                            onChange={setCity}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <MultiContributorsAutocomplete
                            col={12}
                            label={i18n.collectivity}
                            onChange={setCollectivity}
                            values={collectivity}
                            options={contributors.filter(c => perimetersFolder.some(perimeterFolder => perimeterFolder.collectivity === c.id))}
                        />
                    </Grid>
                </Grid>
                <Grid container item xs={3} justifyContent='flex-end' alignItems='center' columnSpacing={1}>
                    <Grid item>
                        <ButtonMUI variant='outlined' onClick={onReset}>
                            {i18n.reinit}
                        </ButtonMUI>
                    </Grid>
                    <Grid item>
                        <ButtonMUI variant='contained' onClick={() => onLaunchSearch()}>
                            {i18n.search}
                        </ButtonMUI>
                    </Grid>
                </Grid>
            </Grid>
        </WhiteCard>
    )
}

const PerimetersDashboard = () => {
    const {
        perimetersFolder,
        contributors,
        sandreCodes,
    } = useSelector(store => ({
        perimetersFolder: store.PerimetersReducer.perimetersFolder,
        contributors: store.ContributorReducer.contributors,
        sandreCodes: store.ReferencialReducer.sandreCodes,
    }), shallowEqual)

    const [createName, setCreateName] = useState()
    const [createStatus, setCreateStatus] = useState()
    const [createState, setCreateState] = useState()
    const [createCollectivity, setCreateCollectivity] = useState()

    const [addPopupIsOpen, setAddPopupIsOpen] = useState(false)

    const dispatch = useDispatch()

    const perimetersStatus = useSandreList('PARCELLES.STATUT')
    const perimetersStates = useSandreList('PARCELLES.ETAT')

    const canBeCreated = createName || createStatus || createState || createCollectivity

    useEffect(() => {
        if (!sandreCodes.length) {
            dispatch(ReferencialAction.fetchSandreCodes())
        }
    }, [])

    useTitle(() => [{
        title: i18n.perimetersFolder,
        href: 'perimeter',
    }], [])

    useActions(() => ({
        export: () => {
            return {
                data: perimetersFolder.map(d => ({
                    identifier: { value: d.id.toString() },
                    name: { value: d.name },
                    cities: { value: '' },
                    collectivity: { value: contributors?.find(c => c.id === d.collectivity)?.name ?? '' },
                    nbPerimeters: { value: d.perimeters.toString() },
                    status: { value: perimetersStatus.find(s => s.code === d.status)?.name ?? '' },
                    stateOfProgress: { value: perimetersStates.find(s => s.code === d.state)?.name ?? '' },
                    headers: ['identifier', ...PERIMETERS_FOLDER_HEADER],
                })),
                exportType: 'xlsx',
                titleFile: i18n.perimetersFolder,
            }
        },
        new: () => {
            setCreateName(undefined)
            setCreateStatus(undefined)
            setCreateState(undefined)
            setCreateCollectivity(undefined)

            setAddPopupIsOpen(true)
        },
    }), [perimetersFolder, sandreCodes, contributors])

    return (
        <Box sx={{ margin: '1rem 1rem 0.5rem 0.5rem' }}>
            <FilterPanel />
            <Grid container item xs={12} sx={{ paddingTop: '1rem' }}>
                <PerimetersFolderTable />
            </Grid>
            <DialogMUI
                fullWidth
                maxWidth='lg'
                open={ addPopupIsOpen }
            >
                <DialogTitleMUI>
                    <Grid container justifyContent='space-between' alignItems='center' style={{ padding: '0 20' }}>
                        <Grid item>
                            {i18n.createPerimetersFolder}
                        </Grid>
                        <Grid item>
                            <Icon style={{ color: 'white' }} size='small' icon='close' onClick={() => setAddPopupIsOpen(false)} />
                        </Grid>
                    </Grid>
                </DialogTitleMUI>
                <DialogContentMUI>
                    <StyledFieldSet style={{ padding: '30px 50px' }}>
                        <Grid container>
                            <Grid container item paddingTop={'10px'}>
                                <Grid item width={'100%'}>
                                    <Input
                                        title={i18n.name}
                                        value={createName}
                                        onChange={setCreateName}
                                        clearFunction
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid container item xs={6}>
                                    <Grid item width={'100%'}>
                                        <Select
                                            options={perimetersStates}
                                            label={i18n.stateOfProgress}
                                            nullLabel='&nbsp;'
                                            value={createState}
                                            noSort
                                            onChange={setCreateState}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container item xs={6}>
                                    <Grid item width={'100%'}>
                                        <Select
                                            options={perimetersStatus}
                                            label={i18n.status}
                                            nullLabel='&nbsp;'
                                            value={createStatus}
                                            noSort
                                            onChange={setCreateStatus}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid container item xs={6}>
                                    <Grid item width={'100%'}>
                                        <MultiContributorsAutocomplete
                                            col={12}
                                            label={i18n.collectivity}
                                            onChange={setCreateCollectivity}
                                            values={createCollectivity}
                                            options={contributors}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </StyledFieldSet>
                </DialogContentMUI>
                <DialogActions>
                    <Button onClick={() => setAddPopupIsOpen(false)} variant='outlined'>
                        { i18n.cancel }
                    </Button>
                    <Button onClick={() => {
                        if (canBeCreated) {
                            dispatch(PerimetersThunk.addPerimetersFolder({
                                name: createName,
                                status: createStatus,
                                state: createState,
                                collectivity: createCollectivity,
                            })).then(res => {
                                if (res.type !== 'addPerimetersFolder/rejected') {
                                    setAddPopupIsOpen(false)
                                }
                            })
                        } else {
                            dispatch(ToastrAction.error(i18n.pleaseEnterAtLeastOneParameter))
                        }
                    }} variant='contained' color='primary'
                    >
                        { i18n.save }
                    </Button>
                </DialogActions>
            </DialogMUI>
        </Box>
    )
}

export default PerimetersDashboard