export default class PerimetersDto {
    constructor(obj) {
        this.id = obj.id // Int
        this.name = obj.name // String
        this.cities = obj.cities?.trim() ? obj.cities.split(',') : [] // Option[String]
        this.collectivity = obj.collectivity // Option[Int]
        this.perimeters = obj.perimeters // Int
        this.status = obj.status // Option[Int]
        this.startDate = obj.startDate // Option[DateTime]
        this.state = obj.state // Option[Int]
        this.updateDate = obj.updateDate // Option[DateTime]
        this.updateLogin = obj.updateLogin // Option[String]
    }
}