module.exports = {
    RESET_ALERT_STORE: 'RESET_ALERT_STORE',
    ADD_FOLLOW_SITE: 'ADD_FOLLOW_SITE',
    ADD_FOLLOW_MEASURES: 'ADD_FOLLOW_MEASURES',
    RESET_FOLLOW: 'RESET_FOLLOW',
    DESCRIPTION: 'DESCRIPTION',
    EVENTS: 'EVENTS',
    CAMPAIGNS: 'CAMPAIGNS',
    THRESHOLDS: 'THRESHOLDS',
    FOLLOWUP: 'FOLLOWUP',
    RESET_POLLUTION_SOURCE_ACTIVITES: 'RESET_POLLUTION_SOURCE_ACTIVITES',
    RECEIVE_POLLUTION_SOURCE_ACTIVITIES: 'RECEIVE_POLLUTION_SOURCE_ACTIVITIES',
    RESET_POLLUTION_SOURCE_PARAMETERS: 'RESET_POLLUTION_SOURCE_PARAMETERS',
    RECEIVE_POLLUTION_SOURCE_PARAMETERS: 'RECEIVE_POLLUTION_SOURCE_PARAMETERS',
    RECEIVE_POLLUTION_SOURCE_PARAMETERS_FULL_DATA: 'RECEIVE_POLLUTION_SOURCE_PARAMETERS_FULL_DATA',
    RECEIVE_TEMP_FORMATTED_SITES: 'RECEIVE_TEMP_FORMATTED_SITES',
    SET_THRESHOLD_PREVI_DATA: 'SET_THRESHOLD_PREVI_DATA',
    DEFAULT_TIME_BEFORE_ALERT: 120,

    SITUATION_MAP_ZOOM: 'defaultSituationMapZoom',
    SITUATION_MAP_X_COORDINATE: 'defaultSituationMapXCoordinate',
    SITUATION_MAP_Y_COORDINATE: 'defaultSituationMapYCoordinate',
}
