import { createSlice } from '@reduxjs/toolkit'
import PerimetersThunk from '../thunk/PerimetersThunk'
import PerimetersDto from 'perimeters/dto/PerimetersDto'
import PerimeterDto from 'perimeters/dto/PerimeterDto'

export const initialState = {
    perimetersFolder: [],
    perimeters: [],
    parcels: [],
    parcelData: {},
    deeds: [],
    deedData: {},
    owners: [],
    perimeterFolderLastEvent: {},
}

const store = createSlice({
    name: 'perimeters',
    initialState,
    reducers: {
    },
    extraReducers: {
        [PerimetersThunk.getPerimetersFolders.pending]: (state) => {
            state.perimetersFolder = []
        },
        [PerimetersThunk.getPerimetersFolders.fulfilled]: (state, action) => {
            state.perimetersFolder = action.payload.map(pf => new PerimetersDto(pf))
        },
        [PerimetersThunk.getPerimeters.pending]: (state) => {
            state.perimeters = []
        },
        [PerimetersThunk.getPerimeters.fulfilled]: (state, action) => {
            state.perimeters = action.payload.map(p => new PerimeterDto(p))
        },
        [PerimetersThunk.getParcels.pending]: (state) => {
            state.parcels = []
        },
        [PerimetersThunk.getParcels.fulfilled]: (state, action) => {
            const parcels = action.payload

            state.parcels = parcels.map(p => {
                const min = 0
                const max = parcels.length - 1
                const current = parcels.findIndex(p2 => p2.id === p.id)

                return {
                    ...p,
                    previousParcel: current - 1 >= min ? current - 1 : max,
                    nextParcel: current + 1 <= max ? current + 1 : min,
                }
            }) ?? []
        },
        [PerimetersThunk.getParcel.pending]: (state) => {
            state.parcelData = {}
        },
        [PerimetersThunk.getParcel.fulfilled]: (state, action) => {
            const parcel = action.payload || {}

            if (parcel.infos && parcel.companyOwners && parcel.privateOwners) {
                state.parcelData = {
                    ...parcel.infos,
                    companyOwners: parcel?.companyOwners,
                    privateOwners: parcel?.privateOwners,
                }
            }
        },
        [PerimetersThunk.getDeeds.pending]: (state) => {
            state.deeds = []
        },
        [PerimetersThunk.getDeeds.fulfilled]: (state, action) => {
            state.deeds = action.payload
        },
        [PerimetersThunk.getDeed.pending]: (state) => {
            state.deedData = {}
        },
        [PerimetersThunk.getDeed.fulfilled]: (state, action) => {
            state.deedData = action.payload
        },
        [PerimetersThunk.getOwners.pending]: (state) => {
            state.owners = []
        },
        [PerimetersThunk.getOwners.fulfilled]: (state, action) => {
            state.owners = action.payload
        },
        [PerimetersThunk.getPerimeterFolderLastEvent.fulfilled]: (state, action) => {
            state.perimeterFolderLastEvent = action.payload
        },
    },
})

export const PerimetersAction = store.actions
export default store.reducer