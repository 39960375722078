const PERIMETERS_STATUS_PROGRESS = 0
const PERIMETERS_STATUS_VALIDATED = 1
const PERIMETERS_STATUS = {
    [PERIMETERS_STATUS_PROGRESS]: 'inProgress',
    [PERIMETERS_STATUS_VALIDATED]: 'validated',
}

const PERIMETERS_STATES_INCOMPLETE = 0
const PERIMETERS_STATES_INITIALIZED = 1
const PERIMETERS_STATES_COMPLETE = 2
const PERIMETERS_STATES = {
    [PERIMETERS_STATES_INCOMPLETE]: 'incomplete',
    [PERIMETERS_STATES_INITIALIZED]: 'initialized',
    [PERIMETERS_STATES_COMPLETE]: 'complete2',
}

const PERIMETERS_TYPES_NOW = 0
const PERIMETERS_TYPES_CLOSE = 1
const PERIMETERS_TYPES_FAR = 2

const PERIMETERS_STATES_COLORS = {
    0: 'orange',
    1: 'lightblue',
    2: 'green',
}

export {
    PERIMETERS_STATUS_PROGRESS,
    PERIMETERS_STATUS_VALIDATED,
    PERIMETERS_STATUS,

    PERIMETERS_STATES_INCOMPLETE,
    PERIMETERS_STATES_INITIALIZED,
    PERIMETERS_STATES_COMPLETE,
    PERIMETERS_STATES,

    PERIMETERS_STATES_COLORS,

    PERIMETERS_TYPES_NOW,
    PERIMETERS_TYPES_CLOSE,
    PERIMETERS_TYPES_FAR,
}