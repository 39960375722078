import Table from 'components/datatable/Table'
import PropTypes from 'prop-types'
import { push } from 'connected-react-router'
import { flatten, intersectionWith, isNil, isUndefined, orderBy, uniq, uniqBy } from 'lodash'
import { CENTRAL_ID, EQUIPMENT_ID, LIST_PANEL, MAP_PANEL, MAT_HEADERS, POWER_SUPPLY_ID, SENSOR_ID, SIM_ID, SUBSCRIPTION_ID, TELECOM_ID, VARIOUS_ID } from 'materiel/constants/MaterielConstants'
import React, { useEffect, useMemo, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { nbPerPageLabel, nbPerPageLabelShorty } from 'referencial/constants/ReferencialConstants'
import i18n from 'simple-react-i18n'
import { STATION_LIST } from 'station/constants/StationConstants'
import { hasValue } from 'utils/NumberUtil'
import { H_MAT_INVENTORY } from '../../../account/constants/AccessRulesConstants'
import ProgressCard from '../../../components/card/ProgressCard'
import Checkbox from '../../../components/forms/Checkbox'
import { getDate, getFullDate } from '../../../utils/DateUtil'
import { componentHasHabilitations } from '../../../utils/HabilitationUtil'
import { createIconMaterialAssignment, createIconMaterialState } from '../../../utils/MaterielUtils'
import { getLabel } from '../../../utils/StoreUtils'
import { searchAllCharacters } from '../../../utils/StringUtil'
import MaterielAction from '../../actions/MaterielAction'
import MaterielsMapComponent from '../map/MaterielsMapComponent'
import useActions from 'utils/customHook/useActions'
import { getDataWithHeaders } from 'utils/ExportDataUtil'
import SimpleTabList from 'components/list/SimpleTabList'
import useSessionStorage from 'utils/customHook/useSessionStorage'
import Card from 'components/card/Card'
import Row from 'components/react/Row'
import { TELECOM } from '../../constants/MaterielConstants'
import { Button, Grid } from '@mui/material'
import Input from 'components/forms/Input'
import Select from 'components/forms/Select'
import CampaignSelect from 'components/forms/specific/CampaignSelect'
import SuperMultiAutocomplete from 'components/forms/SuperMultiAutocomplete'
import MultiContributorsAutocomplete from 'referencial/components/contributor/components/MultiContributorsAutocomplete'
import useTitle from 'utils/customHook/useTitle'
import CentralAction from '../central/actions/CentralAction'
import SensorAction from '../sensor/actions/SensorAction'
import ContributorAction from 'referencial/components/contributor/actions/ContributorAction'
import PowerSupplyAction from '../powerSupply/actions/PowerSupplyAction'
import SimAction from '../sim/actions/SimAction'
import VariousMaterielAction from '../variousMateriel/actions/VariousMaterielAction'
import TelecomAction from '../telecom/actions/TelecomAction'
import EquipmentAction from '../equipment/actions/EquipmentAction'
import SubscriptionAction from '../subscription/actions/SubscriptionAction'
import CardToMuchData from 'components/card/CardToMuchData'
import { StyledFieldSet } from 'components/StyledElements'
import JobAction from 'import/actions/JobAction'
import RefJobAction from 'domain/job/RefJobAction'
import DtoJobLogLight from 'import/dto/DtoJobLogLight'
import useUpdateEffect from 'utils/customHook/useUpdateEffect'
import ToastrAction from 'toastr/actions/ToastrAction'
import useBoolean from 'utils/customHook/useBoolean'
import { promiseAllProgress } from 'utils/ActionUtils'
import NumberField from 'components/forms/NumberField'
import useParseJson from 'utils/customHook/useParseJson'

const MAX_ELEMENTS = 5000

const getcolorLog = (status) => {
    if (status === 'success') {
        return 'green-200'
    } else if (status === 'error') {
        return 'red-200'
    } else if (status === 'warning') {
        return 'yellow-200'
    }
    return undefined
}

const InventoryFilterForm = ({
    filter = {
        administrator: undefined,
        state: undefined,
        manufacturer: undefined,
        network: undefined,
        matType: undefined,
        searchValue: '',
        displayEquipment: false,
        displayOutOfService: false,
        stations: [],
    },
    materiels = [],
    typeList,
    onValidate = () => { },
    materielType = '',
    nbElements = 0,
    showAll = false,
    onShowAll = () => {},
}) => {
    const {
        materielStates,
        contributors,
        networks,
        materielTypes,
    } = useSelector(store => ({
        contributors: store.ContributorReducer.contributors,
        materielStates: store.MaterielReducer.materielStates,
        networks: store.NetworkReducer.networks,
        materielTypes: store.MaterielReducer.materielTypes,
    }), shallowEqual)

    const materielsFiltered = materiels.filter(m => m.assignment).map((m, index) => ({ id: index + 1, name: m.assignment }))
    const materielsStations = uniqBy(materielsFiltered, 'name')
    const defaultStations = filter.stations?.length ? materielsStations.filter(m => filter.stations.includes(m.name)).map(m => m.id) : []

    const [administrator, setAdministrator] = useSessionStorage(`${materielType}_ADMINISTRATOR`, filter.administrator)
    const [state, setState] = useSessionStorage(`${materielType}_STATE`, filter.state)
    const [displayOutOfService, setDisplayOutOfService] = useSessionStorage(`${materielType}_DISPLAY_OUT_OF_SERVICE`, filter.displayOutOfService)
    const [manufacturer, setManufacturer] = useSessionStorage(`${materielType}_MANUFACTURER`, filter.manufacturer)
    const [network, setNetwork] = useSessionStorage(`${materielType}_NETWORK`, filter.network)
    const [matType, setMatType] = useSessionStorage(`${materielType}_TYPE`, filter.matType)
    const [searchValue, setSearchValue] = useSessionStorage(`${materielType}_SEARCH_VALUE`, filter.searchValue)
    const [displayEquipment, setDisplayEquipment] = useSessionStorage(`${materielType}_DISPLAY_EQUIPMENT`, filter.displayEquipment)
    const [stations, setStations] = useSessionStorage(`${materielType}_STATIONS`, defaultStations)

    const [materielTypesSelected, setMaterielTypesSelected] = useState([])

    useEffect(() => {
        const firstTypesSelected = materielTypes.map(({ id }) => id)
        setMaterielTypesSelected(firstTypesSelected)
        onValidate({ availableMaterielTypes: firstTypesSelected })
    }, [materielTypes])

    const stateCodes = uniq(materiels.map(m => m.statusCode)).filter(code => displayOutOfService || (code !== 0 && code !== 5 && code !== 6))
    const filteredStates = stateCodes.map(code => materielStates.find(c => c.code === code)).filter(s => !!s)

    const manufacturerIds = uniq(materiels.map(m => m.manufacturerId))
    const filteredManufacturers = manufacturerIds.map(id => contributors.find(c => c.id === id)).filter(c => !!c)

    const administratorIds = uniq(materiels.map(m => m.administratorId))
    const filteredAdministrators = administratorIds.map(id => contributors.find(c => c.id === id)).filter(c => !!c)

    const networkIds = uniq(materiels.map(m => m.networkCode))
    const filteredNetworks = networkIds.map(id => networks.find(c => c.id === id)).filter(n => !!n)

    const materielTelecom = !!materielTypesSelected && materielTypes.find(m => m.code === TELECOM)

    const changeSelectedMateriel = (id) => {
        if (materielTypesSelected.includes(id)) {
            setMaterielTypesSelected(materielTypesSelected.filter(t => t !== id))
        } else {
            setMaterielTypesSelected([...materielTypesSelected, id])
        }
    }

    useTitle(() => [
        {
            title: i18n.materiels,
            href: 'materiel',
        },
        {
            title: i18n.inventory,
            href: 'materiel/inventory',
        },
    ], [])

    return (
        <Card noMargin={false} className='margin-left-1 margin-right-1'>
            <Row className='padding-top-1 padding-bottom-5-px'>
                <Grid container justifyContent='center'>
                    <Grid container item xs={2}>
                        <Checkbox
                            col={12}
                            label={i18n.displayEquipments}
                            checked={displayEquipment}
                            onChange={setDisplayEquipment}
                        />
                    </Grid>
                    <Grid container item xs={8} justifyContent='space-between'>
                        {!!materielTypesSelected && materielTypes.filter(m => m.code !== TELECOM).map(type => (
                            <Grid item xs='auto'>
                                <Checkbox
                                    label={type.label}
                                    onChange={(e) => changeSelectedMateriel(type.id, e)}
                                    checked={!!materielTypesSelected.some(id => id === type.id)}
                                />
                            </Grid>
                        ))}
                    </Grid>
                    <Grid container item xs='auto'>
                        {!!materielTelecom?.id && (
                            <Checkbox
                                col={12}
                                label={materielTelecom.label}
                                onChange={(e) => changeSelectedMateriel(materielTelecom.id, e)}
                                checked={!!materielTypesSelected.find(id => id === materielTelecom.id)}
                            />
                        )}
                    </Grid>
                </Grid>
            </Row>
            <Row className='padding-top-1 padding-bottom-5-px'>
                <Input
                    col={4}
                    title={i18n.search}
                    value={searchValue}
                    onChange={setSearchValue}
                />
                <Select
                    col={4}
                    label={i18n.state}
                    options={filteredStates}
                    value={state}
                    onChange={setState}
                    keyLabel='label'
                    keyValue='code'
                    integerValue
                    returnNull
                />
                <Checkbox
                    col={4}
                    componentClassName='padding-top-1'
                    label={i18n.displayOutOfService}
                    tooltip={i18n.displayOutOfService}
                    checked={displayOutOfService}
                    onChange={v => {
                        setDisplayOutOfService(v)
                        if (!v && (state === 0 || state === 5 || state === 6)) {
                            setState(undefined)
                        }
                    }}
                />
            </Row>
            <Row className='padding-top-1 padding-bottom-5-px'>
                <Select
                    col={4}
                    label={i18n.network}
                    options={filteredNetworks}
                    value={network}
                    onChange={setNetwork}
                    keyLabel='name'
                    keyValue='id'
                    integerValue
                    returnNull
                />
                <CampaignSelect
                    col={4}
                    changeFilter={() => { }}
                />
                <SuperMultiAutocomplete
                    col={4}
                    label={i18n.stations}
                    options={materielsStations}
                    keyLabel='name'
                    keyValue='id'
                    onChange={setStations}
                    values={stations}
                    multiple
                />
            </Row>
            <Row className='padding-top-1 padding-bottom-5-px'>
                <MultiContributorsAutocomplete
                    col={typeList ? 3 : 4}
                    label={i18n.administrator}
                    options={filteredAdministrators}
                    values={administrator}
                    onChange={setAdministrator}
                    keyLabel='labelDisplay'
                    keyValue='id'
                />
                <MultiContributorsAutocomplete
                    col={typeList ? 3 : 4}
                    label={i18n.manufacturer}
                    options={filteredManufacturers}
                    values={manufacturer}
                    onChange={setManufacturer}
                    keyLabel='labelDisplay'
                    keyValue='id'
                />
                {!!typeList && (
                    <Select
                        col={2}
                        label={i18n.materielTypes}
                        options={typeList}
                        value={matType}
                        onChange={setMatType}
                        keyLabel='label'
                        keyValue='id'
                        integerValue
                        returnNull
                    />
                )}
                <div className='col s1' />
                <div className='col s2'>
                    <Button
                        variant='contained'
                        onClick={() => {
                            onValidate({
                                administrator,
                                state,
                                manufacturer,
                                network,
                                matType,
                                searchValue,
                                displayEquipment,
                                displayOutOfService,
                                stations: materielsStations.filter(m => stations.includes(m.id)).map(m => m.name),
                                availableMaterielTypes: materielTypesSelected,
                            })
                        }}
                        sx={{ width: '100%' }}
                    >
                        {i18n.search}
                    </Button>
                </div>
                {nbElements > MAX_ELEMENTS && !showAll && (
                    <div className='col'>
                        <CardToMuchData
                            nbElem={MAX_ELEMENTS}
                            maxNbElements={nbElements}
                            forceDisplay={onShowAll}
                        />
                    </div>
                )}
            </Row>
        </Card>
    )
}

InventoryFilterForm.propTypes = {
    onValidate: PropTypes.func,
    changeView: PropTypes.func,
    changeSelectedMateriel: PropTypes.func,
    materiels: PropTypes.arrayOf(PropTypes.shape({
        statusCode: PropTypes.number,
        manufacturerId: PropTypes.number,
        administratorId: PropTypes.number,
        networkCode: PropTypes.number,
    })),
    typeList: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        label: PropTypes.string,
    })),
    filter: PropTypes.shape({
        searchValue: PropTypes.string,
        network: PropTypes.string,
        state: PropTypes.string,
        administrator: PropTypes.number,
        manufacturer: PropTypes.number,
        matType: PropTypes.number,
        displayOutOfService: PropTypes.bool,
    }),
    materielTypesSelected: PropTypes.arrayOf(PropTypes.number),
    view: PropTypes.string,
    materielType: PropTypes.string,
    nbElements: PropTypes.number,
    showAll: PropTypes.bool,
    onShowAll: PropTypes.func.isRequired,
}

const JobContent = ({
    job = {},
    setJob = () => {},

    logs = [],
}) => {
    const dispatch = useDispatch()
    const {
        contributors,
    } = useSelector(store => ({
        contributors: store.ContributorReducer.contributors,
    }))

    const {
        json: filters = {},
    } = useParseJson(job.parameters?.filters?.[0] ?? '{}')


    const [fileName, setFileName] = useState()

    const formattedLogs = useMemo(() => {
        const formatted = logs.map(l => ({
            ...l,
            color: getcolorLog(l.status),
            date: getFullDate(l.date),
        }))
        return orderBy(formatted, 'id', 'desc')
    }, [logs])

    const onChange = (obj = {}) => setJob(pj => ({
        ...pj,
        parameters: {
            ...pj.parameters,
            filters: [JSON.stringify({ ...filters, ...obj })],
        },
    }))

    const openFile = e => {
        const reader = new FileReader()
        const fileOpened = e.target.files[0]

        if (isUndefined(fileOpened)) {
            return
        }

        reader.onload = upload => {
            const split = upload.target.result.split(',')

            if (split[0].indexOf('base64') > 0) {
                setFileName(fileOpened.name)
                onChange({ file: split[1], nameOfFile: fileOpened.name })
            } else {
                dispatch(ToastrAction.error(i18n.theSelectedFileMustBeInCsvFormat))
            }
        }
        reader.readAsDataURL(fileOpened)
    }

    return (
        <>
            <StyledFieldSet>
                <Grid container spacing={1} sx={{ padding: '10' }} alignItems='center'>
                    <Grid item xs={2}>
                        <Button
                            variant='contained'
                            color='primary'
                            component='label'
                            fullWidth
                        >
                            <span>{i18n.browse}</span>
                            <input
                                type='file'
                                accept='.xlsx, .XLSX'
                                hidden
                                onChange={openFile}
                            />
                        </Button>
                    </Grid>
                    <Grid item xs={10}>
                        <label style={{ fontSize: '1em' }}>{fileName ?? i18n.pleaseSelectFile}</label>
                    </Grid>
                    <Grid item xs={12}>
                        <MultiContributorsAutocomplete
                            label={i18n.regionalDirection}
                            values={filters.validator}
                            onChange={v => onChange({ validator: v })}
                            options={contributors}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Checkbox
                            label={i18n.hasHeader}
                            onChange={v => onChange({ hasHeader: v })}
                            checked={filters.hasHeader}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <NumberField
                            title={i18n.nbLine}
                            value={filters.nbHeaders ?? 6}
                            onChange={v => onChange({ nbHeaders: v })}
                            floatValue
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Checkbox
                            checked={!!filters.dataIntegration}
                            label={i18n.noDataIntegrationIfError}
                            onChange={v => onChange({ dataIntegration: v })}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Checkbox
                            checked={!!filters.updateImmo}
                            label={i18n.updateImmo}
                            onChange={v => onChange({ updateImmo: v })}
                        />
                    </Grid>
                </Grid>
            </StyledFieldSet>
            {formattedLogs.length !== 0 && (
                <StyledFieldSet>
                    <Table
                        className='padding-left-1'
                        condensed
                        sortable
                        data={formattedLogs}
                        paging
                        nbPerPageLabel={nbPerPageLabelShorty}
                        type={{ headers: ['date', 'value', 'status'] }}
                        customHeaders={ { value: i18n.valueLabel } }
                        maxHeight='62vh'
                        overflow='auto'
                        inverseDisplay
                        showTitle={false}
                    />
                </StyledFieldSet>
            )}
        </>
    )
}

JobContent.propTypes = {
    job: PropTypes.shape({}),
    setJob: PropTypes.func,

    logs: PropTypes.arrayOf(PropTypes.shape({})),
}

const ActionComponent = ({ // just to optimize
    exportData = [],
    materialHeaders = [],
}) => {
    const dispatch = useDispatch()
    const {
        jobs,
    } = useSelector(store => ({
        jobs: store.JobReducer.jobs,
    }), shallowEqual)

    const getJob = () => {
        const job = jobs.find(j => j.id === -19)
        if (isNil(job)) return {}

        return {
            ...job,
            parameters: JSON.parse(job.parameters ?? '{}'),
        }
    }

    const [job, setJob] = useState(getJob) // manual job

    const [lastExecution, setlastExecution] = useState()
    const [logs, setLogs] = useState([])

    const getLogs = () => {
        RefJobAction.fetchJobExecutions(job.id, 1).then(jobExecutions => {
            const [lastExec] = jobExecutions

            if (isNil(lastExec)) return

            JobAction.requestJobLogs(job.id, lastExec.id).then(logsJob => {
                const jobLogs = logsJob.data.map(log => new DtoJobLogLight(log, lastExec.id))
                setLogs(jobLogs)
                setlastExecution(lastExec)
            })
        })
    }

    useUpdateEffect(() => {
        if (isNil(lastExecution) || !isNil(lastExecution.statusCode)) {
            dispatch(ToastrAction.info('Traitement terminer, recharger la page'))
            return
        }

        setTimeout(getLogs, 2000)
    }, [lastExecution])

    useEffect(() => {
        if (jobs.length === 0) {
            dispatch(JobAction.fetchJobs())
        }
    }, [])

    useEffect(() => {
        if (job.id !== -19 && jobs.length !== 0) {
            setJob(getJob)
        }
    }, [job.id, jobs])

    const onSave = () => {
        dispatch(JobAction.updateJob(job, 10)).then(() => {
            dispatch(JobAction.executeJob(job.id, 10)).then(getLogs)
        })
    }

    useActions(() => {
        return {
            referencialActions: {
                actions: {
                    export: {
                        data: getDataWithHeaders(exportData, materialHeaders),
                        titleFile: i18n.materiels,
                    },
                    importMat: {
                        icon: 'upload',
                        title: i18n.importMatAccounting,
                        description: 'Cette action vous permet d\'importer un fichier formaté correspondant à l\'import comptable.',
                        steps: [
                            { label: i18n.setting, constant: 1 },
                        ],
                        componentStep1: (
                            <JobContent
                                job={job}
                                setJob={setJob}

                                logs={logs}
                            />
                        ),
                        validateButton: () => (
                            <Button
                                onClick={onSave}
                                disabled={!isNil(lastExecution) && isNil(lastExecution?.statusCode)}
                                variant='contained'
                            >
                                {i18n.execute}
                            </Button>
                        ),
                    },
                },
            },
        }
    }, [exportData, job, lastExecution, logs])

    return null
}

ActionComponent.propTypes = {
    exportData: PropTypes.arrayOf(PropTypes.shape({})),
    materialHeaders: PropTypes.arrayOf(PropTypes.string),
}

const STATION_HEADERS = ['assignment', 'administrator', 'type', 'manufacturer', 'serialNumber', 'immoNum', 'purchaseDate', 'state', 'stateDate']

const StationListApp = ({
    materiels = [],
    typesHeaders = [],
    exportData = [],
}) => {
    const materialsFiltered = orderBy(materiels.filter(m => hasValue(m.assignment)), ['siteType', 'name'])
    const materialHeaders = [...STATION_HEADERS, ...typesHeaders]

    return (
        <>
            <Table
                title={i18n.stations}
                tableClassName='pin-top'
                condensed paging
                nbPerPageLabel={nbPerPageLabel}
                customHeaders={{ displayStatus: i18n.status }}
                data={materialsFiltered}
                active={false}
                type={{ headers: [...materialHeaders] }}
                sortable
                onClick={({ link }) => push(link)}
            />
            <ActionComponent
                exportData={exportData.filter(e => hasValue(e.assignment))}
                materialHeaders={materialHeaders}
            />
        </>
    )
}

StationListApp.propTypes = {
    typesHeaders: PropTypes.arrayOf(PropTypes.string),
    materiels: PropTypes.array,
    exportData: PropTypes.array,
}

const MaterialListApp = ({
    materiels = [],
    typesHeaders = [],
    exportData = [],
}) => {
    const materialHeaders = [...MAT_HEADERS, ...typesHeaders]

    return (
        <>
            <Table
                title={i18n.materiels}
                condensed paging
                tableClassName='pin-top'
                nbPerPageLabel={nbPerPageLabel}
                customHeaders={{ displayStatus: i18n.status }}
                data={materiels}
                active={false}
                type={{ headers: ['nullValue', ...materialHeaders] }}
                sortable
                onClick={({ link }) => push(link)}
            />
            <ActionComponent
                exportData={exportData}
                materialHeaders={materialHeaders}
            />
        </>
    )
}

MaterialListApp.propTypes = {
    typesHeaders: PropTypes.arrayOf(PropTypes.string),
    materiels: PropTypes.array,
    exportData: PropTypes.array,
}

const InventoryApp = () => {
    const {
        piezometers,
        qualitometers,
        hydrometricStations,
        pluviometers,
        installations,
        materielStates,
        centrals,
        centralsLastSituations,
        centralTypes,
        powerSupplies,
        powerSuppliesLastSituations,
        powerSupplyTypes,
        sensors,
        sensorsLastSituations,
        sensorTypes,
        variousMateriels,
        variousMaterielsLastSituations,
        variousMaterielTypes,
        equipments,
        equipmentTypes,
        equipmentsLastSituations,
        sims,
        simTypes,
        simsLastSituations,
        telecoms,
        telecomTypes,
        telecomsLastSituations,
        subscriptions,
        subscriptionTypes,
        subscriptionsLastSituations,
        contributors,
        materielTypes,
    } = useSelector(store => ({
        piezometers: store.PiezometryReducer.piezometersLight,
        qualitometers: store.QualityReducer.qualitometersLight,
        hydrometricStations: store.HydrometryReducer.hydrometricStations,
        pluviometers: store.PluviometryReducer.pluviometers,
        installations: store.InstallationReducer.installations,
        materielStates: store.MaterielReducer.materielStates,
        centrals: store.CentralReducer.centrals,
        centralsLastSituations: store.CentralReducer.centralsLastSituations,
        centralTypes: store.CentralReducer.centralTypes,
        powerSupplies: store.PowerSupplyReducer.powerSupplies,
        powerSuppliesLastSituations: store.PowerSupplyReducer.powerSuppliesLastSituations,
        powerSupplyTypes: store.PowerSupplyReducer.powerSupplyTypes,
        sensors: store.SensorReducer.sensors,
        sensorsLastSituations: store.SensorReducer.sensorsLastSituations,
        sensorTypes: store.SensorReducer.sensorTypes,
        variousMateriels: store.VariousMaterielReducer.variousMateriels,
        variousMaterielsLastSituations: store.VariousMaterielReducer.variousMaterielsLastSituations,
        variousMaterielTypes: store.VariousMaterielReducer.variousMaterielTypes,
        equipments: store.EquipmentReducer.equipments,
        equipmentTypes: store.EquipmentReducer.equipmentTypes,
        equipmentsLastSituations: store.EquipmentReducer.equipmentsLastSituations,
        sims: store.SimReducer.sims,
        simTypes: store.SimReducer.simTypes,
        simsLastSituations: store.SimReducer.simsLastSituations,
        telecoms: store.TelecomReducer.telecoms,
        telecomTypes: store.TelecomReducer.telecomTypes,
        telecomsLastSituations: store.TelecomReducer.telecomsLastSituations,
        subscriptions: store.SubscriptionReducer.subscriptions,
        subscriptionTypes: store.SubscriptionReducer.subscriptionTypes,
        subscriptionsLastSituations: store.SubscriptionReducer.subscriptionsLastSituations,
        contributors: store.ContributorReducer.contributors,
        materielTypes: store.MaterielReducer.materielTypes,
    }), shallowEqual)

    const [filter, setFilter] = useState({})
    const [view, setView] = useState(LIST_PANEL)
    const [showAll, setShowAll] = useState(false)

    const dispatch = useDispatch()

    useEffect(() => {
        if (!componentHasHabilitations(H_MAT_INVENTORY)) {
            dispatch(push('/unauthorized'))
        }
    }, [])

    useActions(() => [], [])

    const { value: dataload, setTrue: setLoaded, setFalse: setNotLoaded } = useBoolean(false)
    const [progress, setProgress] = useState(0)

    useEffect(() => {
        if (materielTypes.length === 0) return

        setNotLoaded()

        const promisesGeneral = [MaterielAction.fetchMaterielStates, ContributorAction.fetchContributors]
        const promisesCentral = materielTypes.some(t => t.id === CENTRAL_ID) ? [
            () => CentralAction.fetchCentrals(true),
            CentralAction.fetchCentralsLastSituations,
            CentralAction.fetchCentralTypes,
        ] : []
        const promisesSensor = materielTypes.some(t => t.id === SENSOR_ID) ? [
            () => SensorAction.fetchSensors(true),
            SensorAction.fetchSensorsLastSituations,
            SensorAction.fetchSensorTypes,
        ] : []
        const promisesPowerSupply = materielTypes.some(t => t.id === POWER_SUPPLY_ID) ? [
            () => PowerSupplyAction.fetchPowerSupplies(true),
            PowerSupplyAction.fetchPowerSuppliesLastSituations,
            PowerSupplyAction.fetchPowerSupplyTypes,
        ] : []
        const promisesSim = materielTypes.some(t => t.id === SIM_ID) ? [
            () => SimAction.fetchSims(true),
            SimAction.fetchSimsLastSituations,
            SimAction.fetchSimTypes,
        ] : []
        const promisesVariousMateriel = materielTypes.some(t => t.id === VARIOUS_ID) ? [
            VariousMaterielAction.fetchVariousMateriels,
            VariousMaterielAction.fetchVariousMaterielsLastSituations,
            VariousMaterielAction.fetchVariousMaterielTypes,
        ] : []
        const promisesTelecom = materielTypes.some(t => t.id === TELECOM_ID) ? [
            () => TelecomAction.fetchTelecoms(true),
            TelecomAction.fetchTelecomsLastSituations,
            TelecomAction.fetchTelecomTypes,
        ] : []
        const promisesEquipment = materielTypes.some(t => t.id === EQUIPMENT_ID) ? [
            EquipmentAction.fetchEquipments,
            EquipmentAction.fetchEquipmentsLastSituations,
            EquipmentAction.fetchEquipmentTypes,
        ] : []
        const promisesSubscription = materielTypes.some(t => t.id === SUBSCRIPTION_ID) ? [
            SubscriptionAction.fetchSubscriptions,
            SubscriptionAction.fetchSubscriptionsLastSituations,
            SubscriptionAction.fetchSubscriptionTypes,
        ] : []

        promiseAllProgress([
            ...promisesGeneral,
            ...promisesCentral,
            ...promisesSensor,
            ...promisesPowerSupply,
            ...promisesSim,
            ...promisesVariousMateriel,
            ...promisesTelecom,
            ...promisesEquipment,
            ...promisesSubscription,
        ].map(p => dispatch(p())), setProgress).finally(setLoaded)
    }, [dispatch, materielTypes])

    const getCorrespondingSite = ({ siteType, siteCode }) => {
        switch (siteType) {
            case 1:
                return piezometers.find(({ id }) => id === siteCode)
            case 2:
                return pluviometers.find(({ id }) => id === siteCode)
            case 3:
                return qualitometers.find(({ id }) => id === siteCode)
            case 4:
                return hydrometricStations.find(({ id }) => id === siteCode)
            case 7:
                return installations.find(({ id }) => id === siteCode)
            default:
                return {}
        }
    }

    const formatMateriel = (materiel, listOfLastSituations, listOfTypes, materielKey, materielTypeKey) => {
        const lastSituation = listOfLastSituations.find(o => o[materielKey] === materiel.id)
        const labelState = lastSituation ? getLabel(materielStates, lastSituation.statusCode) : ''
        const site = lastSituation && lastSituation.statusCode === 1 && lastSituation.siteType && lastSituation.siteCode && getCorrespondingSite(lastSituation) || {}
        const contributorsFiltered = contributors.filter(c => [materiel.manufacturerId, materiel.administrator].includes(c.id))
        const labelAssignment = (lastSituation && lastSituation.statusCode === 1 && lastSituation.siteName) || site.code || ''
        return {
            type: getLabel(listOfTypes, materiel[materielTypeKey]),
            manufacturer: getLabel(contributorsFiltered, materiel.manufacturerId, 'labelDisplay', 'id'),
            manufacturerId: materiel.manufacturerId,
            stateDate: lastSituation ? getDate(lastSituation.situationDate) : '',
            statusCode: lastSituation?.statusCode,
            serialNumber: materiel.imei || materiel.serialNumber,
            immoNum: materiel.reference,
            administrator: getLabel(contributorsFiltered, materiel.administrator, 'labelDisplay', 'code'),
            administratorId: materiel.administrator,
            networkCode: materiel.networkCode,
            isEquipment: materiel.isEquipment,
            siteType: lastSituation?.siteType,
            siteCode: lastSituation?.siteCode,
            state: labelState,
            assignment: labelAssignment,
        }
    }

    const centralValue = useMemo(() => ({
        id: 1,
        getData: () => centrals.map(mat => ({
            ...formatMateriel(mat, centralsLastSituations, centralTypes, 'idCentral', 'centralType'),
            link: `/materiel/central/${mat.id}`,
            central: 'X',
        })),
    }), [centralTypes, centrals, centralsLastSituations])

    const sensorsValue = useMemo(() => ({
        id: 2,
        getData: () => sensors.map(mat => ({
            ...formatMateriel(mat, sensorsLastSituations, sensorTypes, 'idSensor', 'sensorType'),
            link: `/materiel/sensor/${mat.id}`,
            sensor: 'X',
        })),
    }), [sensorTypes, sensors, sensorsLastSituations])

    const powerSuppliesValue = useMemo(() => ({
        id: 3,
        getData: () => powerSupplies.map(mat => ({
            ...formatMateriel(mat, powerSuppliesLastSituations, powerSupplyTypes, 'idPowerSupply', 'powerSupplyType'),
            link: `/materiel/powerSupply/${mat.id}`,
            powerSupply: 'X',
        })),
    }), [powerSupplies, powerSuppliesLastSituations, powerSupplyTypes])

    const simsValue = useMemo(() => ({
        id: 4,
        getData: () => sims.map(mat => ({
            ...formatMateriel(mat, simsLastSituations, simTypes, 'idSim', 'simType'),
            link: `/materiel/sim/${mat.id}`,
            sim: 'X',
        })),
    }), [simTypes, sims, simsLastSituations])

    const variousMaterielsValue = useMemo(() => ({
        id: 5,
        getData: () => variousMateriels.map(mat => ({
            ...formatMateriel(mat, variousMaterielsLastSituations, variousMaterielTypes, 'idVarious', 'materielType'),
            link: `/materiel/variousMateriel/${mat.id}`,
            variousMateriel: 'X',
        })),
    }), [variousMaterielTypes, variousMateriels, variousMaterielsLastSituations])

    const telecomsValue = useMemo(() => ({
        id: 6,
        getData: () => telecoms.map(mat => ({
            ...formatMateriel(mat, telecomsLastSituations, telecomTypes, 'idTelecom', 'telecomType'),
            link: `/materiel/telecom/${mat.id}`,
            telecom: 'X',
        })),
    }), [telecomTypes, telecoms, telecomsLastSituations])

    const equipmentsValue = useMemo(() => ({
        id: 7,
        getData: () => equipments.map(mat => ({
            ...formatMateriel(mat, equipmentsLastSituations, equipmentTypes, 'idEquipment', 'equipmentType'),
            link: `/materiel/equipment/${mat.id}`,
            central: mat.centralIntegrated ? 'X': '',
            sensor: mat.sensorIntegrated ? 'X': '',
            powerSupply: mat.powerSupplyIntegrated ? 'X': '',
            telecom: mat.telecomIntegrated ? 'X': '',
            variousMateriel: mat.variousMaterielIntegrated ? 'X': '',
            sim: mat.simIntegrated ? 'X': '',
        })),
    }), [equipmentTypes, equipments, equipmentsLastSituations])

    const subscriptionsValue = useMemo(() => ({
        id: 8,
        getData: () => subscriptions.map(mat => ({
            ...formatMateriel(mat, subscriptionsLastSituations, subscriptionTypes, 'idSubscription', 'subscriptionType'),
            link: `/materiel/subscription/${mat.id}`,
            subscription: 'X',
        })),
    }), [subscriptionTypes, subscriptions, subscriptionsLastSituations])

    const materielValue = useMemo(() => [
        centralValue,
        sensorsValue,
        powerSuppliesValue,
        simsValue,
        variousMaterielsValue,
        telecomsValue,
        equipmentsValue,
        subscriptionsValue,
    ], [centralValue, sensorsValue, powerSuppliesValue, simsValue, variousMaterielsValue, telecomsValue, equipmentsValue, subscriptionsValue])

    const materiels = useMemo(() => {
        if (!dataload) {
            return []
        }
        const { availableMaterielTypes } = filter
        const selectedValues = availableMaterielTypes ? materielValue.filter(({ id }) => availableMaterielTypes.includes(id)) : materielValue
        return flatten(selectedValues.map(m => m.getData()))
    }, [filter.availableMaterielTypes, materielValue, dataload])

    const getHash = mat => searchAllCharacters(MAT_HEADERS.map(key => mat[key]).join(''))

    const filterMateriels = (materielsToFilter) => {
        const {
            network,
            state,
            administrator,
            manufacturer,
            displayEquipment,
            searchValue,
            displayOutOfService,
            stations,
        } = filter
        const filterEquipment = !displayEquipment ? materielsToFilter.filter(({ isEquipment }) => !isEquipment) : materielsToFilter
        const filterAdministrator = administrator ? filterEquipment.filter(({ administratorId }) => administrator === administratorId) : filterEquipment
        const filterManufacturer = manufacturer ? filterAdministrator.filter(({ manufacturerId }) => manufacturer === manufacturerId) : filterAdministrator
        const filterStatusCode = hasValue(state) ? filterManufacturer.filter(({ statusCode }) => statusCode === state) : filterManufacturer
        const filterNetwork = network ? filterStatusCode.filter(({ networkCode }) => network === networkCode) : filterStatusCode
        const filterOutOfService = displayOutOfService ? filterNetwork : filterNetwork.filter(({ statusCode }) => statusCode !== 0 && statusCode !== 5 && statusCode !== 6)
        const filterStations = stations?.length ? filterOutOfService.filter(({ assignment }) => stations.includes(assignment)) : filterOutOfService
        const searchValueFormated = searchAllCharacters(searchValue)
        return searchValue ? filterStations.filter(mat => getHash(mat).includes(searchValueFormated)) : filterStations
    }

    const filteredMateriels = useMemo(() => dataload ? filterMateriels(materiels) : [], [materiels, filter, dataload])

    const formatTable = materielsToFormat => materielsToFormat.map(mat => ({
        ...mat,
        state: createIconMaterialState(mat.state, mat.statusCode),
        assignment: mat.statusCode === 1 && createIconMaterialAssignment(mat.assignment, mat.siteType) || '',
        name: mat.assignment.slice(mat.assignment.indexOf('-') + 2, mat.assignment.length - 1),
        nullValue: mat.isEquipment && <i className='material-icons' style={{ fontSize: '1.5rem' }}>devices_other</i>,
        central: mat.central === 'X' ? <Checkbox col={12} checked={true} disabled={true} /> : '',
        sensor: mat.sensor === 'X' ? <Checkbox col={12} checked={true} disabled={true} /> : '',
        powerSupply: mat.powerSupply === 'X' ? <Checkbox col={12} checked={true} disabled={true} /> : '',
        telecom: mat.telecom === 'X' ? <Checkbox col={12} checked={true} disabled={true} /> : '',
        variousMateriel: mat.variousMateriel === 'X' ? <Checkbox col={12} checked={true} disabled={true} /> : '',
        sim: mat.sim === 'X' ? <Checkbox col={12} checked={true} disabled={true} /> : '',
        subscription: mat.subscription === 'X' ? <Checkbox col={12} checked={true} disabled={true} /> : '',
    }))

    const materielsFormatted = useMemo(() => {
        if (dataload) {
            return showAll ? formatTable(filteredMateriels) : formatTable(filteredMateriels.slice(0, MAX_ELEMENTS))
        }
        return []
    }, [filteredMateriels, dataload, showAll])

    const materialHeaders = useMemo(() => {
        const types = materielTypes.map(({ link }) => link)
        if (types.includes('equipment')) {
            return uniq([
                ...types.filter(type => type !== 'equipment'),
                equipments.some(({ centralIntegrated }) => centralIntegrated) && 'central',
                equipments.some(({ sensorIntegrated }) => sensorIntegrated) && 'sensor',
                equipments.some(({ powerSupplyIntegrated }) => powerSupplyIntegrated) && 'powerSupply',
                equipments.some(({ telecomIntegrated }) => telecomIntegrated) && 'telecom',
                equipments.some(({ variousMaterielIntegrated }) => variousMaterielIntegrated) && 'variousMateriel',
                equipments.some(({ simIntegrated }) => simIntegrated) && 'sim',
            ].filter(type => !!type))
        }
        return types
    }, [equipments, materielTypes])

    const exportData = useMemo(() => {
        const { availableMaterielTypes } = filter
        const selectedValues = availableMaterielTypes ? intersectionWith(materielValue, availableMaterielTypes, (a, b) => a.id === b) : materielValue
        const result = selectedValues.map(o => o.getData())
        const exportfilteredMateriels = filterMateriels(flatten(result))
        return exportfilteredMateriels.length ? exportfilteredMateriels : []
    }, [filter.availableMaterielTypes, materielValue, filter])

    return !dataload ? (
        <ProgressCard className='margin-1' progress={progress} />
    ) : (
        <>
            <InventoryFilterForm
                view={view}
                changeView={setView}
                onValidate={setFilter}
                materiels={materiels}
                filter={filter}
                materielType='INVENTORY'
                nbElements={filteredMateriels.length}
                showAll={showAll}
                onShowAll={() => setShowAll(true)}
            />
            <div className='margin-right-1 margin-left-1'>
                <SimpleTabList
                    defaultTab={LIST_PANEL}
                    tabs={[
                        {
                            constant: LIST_PANEL,
                            label: i18n.materiels,
                            icon: 'router',
                        },
                        {
                            constant: STATION_LIST,
                            label: i18n.stations,
                            icon: 'place',
                        },
                        {
                            constant: MAP_PANEL,
                            label: i18n.map,
                            icon: 'map',
                        },
                    ]}
                >
                    {tab => {
                        return (
                            <div style={{ padding: '10' }}>
                                {tab === LIST_PANEL && <MaterialListApp materiels={materielsFormatted} typesHeaders={materialHeaders} exportData={exportData} />}
                                {tab === STATION_LIST && <StationListApp materiels={materielsFormatted} typesHeaders={materialHeaders} exportData={exportData} />}
                                {tab === MAP_PANEL && <MaterielsMapComponent materiels={filteredMateriels} />}
                            </div>
                        )
                    }}
                </SimpleTabList>
            </div>
        </>
    )
}

export default InventoryApp
