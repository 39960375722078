/* eslint-disable react/forbid-prop-types */
import React, { createRef, useCallback, useEffect, useMemo, useState } from 'react'
import { searchAllCharacters } from '../../utils/StringUtil'
import i18n from 'simple-react-i18n'
import { v4 as uuidv4 } from 'uuid'
import DisplayedValue from './DisplayedValue'
import { hasValue } from '../../utils/NumberUtil'
import { sieauTooltip } from '../../utils/FormUtils'
import Icon from '../icon/Icon'
import { shallowEqual, useSelector } from 'react-redux'
import PropTypes from 'prop-types'

const Select = ({
    label = '',
    value,
    options = [],
    keyLabel,
    keyValue,
    className = '',
    onChange = () => { },
    col,
    nullLabel,
    noNullValue,
    disabled,
    group = false,
    withThresholdGroups,
    readMode = false,
    integerValue,
    icon = false,
    stringValue,
    hideNull,
    tableEditable,
    noInputFieldClass,
    keyObj,
    changeObj,
    freezeOpti,
    obligatory,
    tooltip,
    clearFunction,
    id,
    displayWithCode,
    limit = 200,
    noLimit = false,
    sortFunc = undefined,
    noSort = undefined,
    labelSpan = undefined,
    style,
    dropdownHeight = '250px',
    'data-cy': dataCy,
}) => {
    const getId = useCallback((element) => {
        if (element) {
            if (hasValue(element[keyValue])) {
                return element[keyValue]
            } else if (hasValue(element.code)) {
                return element.code
            } else if (hasValue(element.id)) {
                return element.id
            } else if (hasValue(element.value)) {
                return element.value
            }
        }
        return undefined
    }, [keyValue])

    const getName = useCallback((element) => {
        const code = displayWithCode ? getId(element) : undefined
        const codeShown = hasValue(code) && code !== '' ? ` [${code}]` : ''
        return (element?.[keyLabel] || element?.mnemonique || element?.name || element?.label || nullLabel || '') + codeShown
    }, [displayWithCode, getId, keyLabel, nullLabel])

    const getElementFromId = useCallback((idElement) => {
        if (idElement === undefined || idElement === undefined || idElement === '') {
            return undefined
        }
        return options.find(element => getId(element) == idElement)
    }, [getId, options])


    const [input] = useState(createRef())
    const [selectId] = useState(() => {
        const newId = (id || searchAllCharacters(label) || '').replaceAll(' ', '_').replace('numero', '').replace('nom', 'libelle')
        return newId + uuidv4()
    })
    const [searchValue, setSearchValue] = useState(getName(getElementFromId(value)))
    const [focus, setFocus] = useState(false)
    const [editing, setEditing] = useState(false)

    const {
        changeSelectDropdown,
    } = useSelector(store => ({
        changeSelectDropdown: store.SieauReducer.changeSelectDropdown,
    }), shallowEqual)

    const setInputFocus = () => {
        // input.current.focus()
        setFocus(true)
    }

    const onChangeValue = useCallback((element) => {
        const elementId = getId(element)
        const elementValue = (() => {
            if (integerValue && hasValue(elementId)) {
                return parseInt(elementId)
            } else if (stringValue && hasValue(elementId)) {
                return elementId.toString()
            }
            return elementId
        })()
        if (onChange) {
            onChange(elementValue, element)
        }
        if (keyObj && changeObj) {
            changeObj({ [keyObj]: elementValue, LAST_FORM: 'SELECT' })
        }
        setSearchValue(getName(element))
        setEditing(false)
        setFocus(false)
        if (input.current) {
            input.current.selectionStart = input.current.selectionEnd = 0
            input.current.blur()
        }
    }, [changeObj, getId, getName, input, integerValue, keyObj, onChange, stringValue])

    const onBlur = useCallback((event, forceBlur = false) => {
        if (forceBlur || (!disabled)) {
            setFocus(false)
            setSearchValue(getName(getElementFromId(value)))
            setEditing(false)
            if (input.current) {
                input.current.selectionStart = input.current.selectionEnd = 0
                if (forceBlur) {
                    input.current.blur()
                }
            }
        }
    }, [disabled, getElementFromId, getName, input, value])

    const updateDropdown = useCallback(() => {
        const props = {
            label,
            value,
            options,
            keyLabel,
            keyValue,
            className,
            onChange,
            col,
            nullLabel,
            noNullValue,
            disabled,
            group,
            withThresholdGroups,
            readMode,
            integerValue,
            icon,
            stringValue,
            hideNull,
            tableEditable,
            noInputFieldClass,
            keyObj,
            changeObj,
            freezeOpti,
            obligatory,
            tooltip,
            clearFunction,
            id,
            displayWithCode,
            limit,
            sortFunc,
            noLimit,
            noSort,
            dropdownHeight,
        }
        changeSelectDropdown({ searchValue, focus, editing }, props, selectId, onChangeValue, onBlur)
    }, [changeObj, changeSelectDropdown, className, clearFunction, col, disabled, displayWithCode, editing, focus, freezeOpti, group, hideNull, icon, id, integerValue, keyLabel, keyObj, keyValue, label, limit, noInputFieldClass, noLimit, noNullValue, nullLabel, obligatory, onBlur, onChange, onChangeValue, options, readMode, searchValue, selectId, sortFunc, stringValue, tableEditable, tooltip, value, withThresholdGroups])

    useEffect(() => {
        setSearchValue(getName(getElementFromId(value)) || nullLabel || '')
    }, [value, options, getName, getElementFromId, nullLabel])

    useEffect(() => {
        if (focus) {
            setInputFocus()
        }
        updateDropdown()
    }, [focus, searchValue, editing, onChange, updateDropdown])

    const onClick = (event) => {
        if (!disabled && event && !event.currentTarget.contains(event.relatedTarget)) {
            setInputFocus()
        }
        if (!disabled && !focus) {
            setInputFocus()
        }
    }

    const onChangeSearchValue = (event) => {
        setSearchValue(event.target.value)
        setEditing(true)
        setInputFocus()
    }

    const isSearchValueInclude = useCallback((text) => {
        return searchAllCharacters(text).includes(searchAllCharacters(searchValue))
    }, [searchValue])

    const searchElements = useMemo(() => {
        if (options && options.length) {
            if (editing) {
                return options.filter(element => isSearchValueInclude(getName(element)))
            }
            return options
        }
        return []
    }, [options, editing, isSearchValueInclude, getName])

    const onKeyDown = (event) => {
        if (event.key === 'Escape') {
            input.current.blur()
        } else if (event.key === 'Enter') {
            if (nullLabel && isSearchValueInclude(nullLabel)) {
                onChangeValue(undefined)
            } else if (searchElements && searchElements.length) {
                onChangeValue(searchElements[0])
            }
        }
    }

    const clearInput = () => {
        if (!disabled) {
            onChangeValue(undefined)
        }
    }

    const getAutocomplete = () => {
        if (readMode || (tableEditable && !focus)) {
            const element = getElementFromId(value)
            return (
                <DisplayedValue
                    label={label}
                    value={element ? getName(element) : ''}
                    hideNull={hideNull}
                    obligatory={obligatory}
                    onClick={onClick}
                    tableEditable={tableEditable}
                />
            )
        }
        const tooltipObj = tooltip ? sieauTooltip(() => tooltip) : undefined
        return (
            <><div
                className={(noInputFieldClass ? '' : 'input-field ') + className}
                tabIndex='0'
                id={`input-${selectId}`}
            >
                <div
                    onClick={onClick}
                    className={`mAutocomplete ${disabled ? 'disabled' : ''}`}
                    style={{
                        ...(focus ? { borderColor: '#53A1FF', boxShadow: '0 1px 0 0 #53A1FF' } : {}),
                        ...(disabled ? {
                            border: '1px solid #ddd',
                            borderBottom: '1px dotted rgba(0, 0, 0, 0.42)',
                        } : {}),
                    }}
                    {...tooltipObj}
                >
                    <div
                        className='mAutocomplete__chip_container'
                        style={{ marginRight: clearFunction ? '37px' : '18px' }}
                    >
                        <input
                            type='text'
                            id={selectId}
                            className='mAutocomplete__input'
                            autoComplete='off'
                            disabled={disabled}
                            value={searchValue}
                            onChange={onChangeSearchValue}
                            onKeyDown={onKeyDown}
                            onClick={() => setSearchValue('')}
                            ref={input}
                            data-cy={dataCy}
                        />
                    </div>
                    <div className='mAutocomplete__clear_container' style={{ width: (clearFunction ? '37px' : '18px') }}>
                        {clearFunction &&
                            <Icon icon='close' className='mAutocomplete__clear_icon' onClick={clearInput} />
                        }
                        <Icon
                            icon={focus ? 'expand_less' : 'expand_more'}
                            className='mAutocomplete__clear_icon'
                            onClick={focus ? event => onBlur(event, true) : undefined}
                        />
                    </div>
                </div>
                {label && <label style={{ color: (focus ? '#53A1FF' : (disabled ? 'rgba(0, 0, 0, 0.42)' : '#161832')), height: 0 }} htmlFor={selectId}>
                    {label}
                    {obligatory ? <span className='primary-color-text'>{i18n.obligatoryField}</span> : undefined}
                </label>}
            </div>{labelSpan}</>
        )
    }

    if (col) {
        return (
            <div className={`col s${col}`} style={style}>
                {getAutocomplete()}
            </div>
        )
    }
    return getAutocomplete()
}

Select.propTypes = { // correct propTypes if there is some issue
    label: PropTypes.string,
    value: PropTypes.any,
    options: PropTypes.array,
    keyLabel: PropTypes.string,
    keyValue: PropTypes.string,
    className: PropTypes.string,
    onChange: PropTypes.func,
    col: PropTypes.number,
    nullLabel: PropTypes.string,
    noNullValue: PropTypes.bool,
    disabled: PropTypes.bool,
    group: PropTypes.bool,
    withThresholdGroups: PropTypes.bool,
    readMode: PropTypes.bool,
    integerValue: PropTypes.bool,
    icon: PropTypes.bool,
    stringValue: PropTypes.bool,
    hideNull: PropTypes.bool,
    tableEditable: PropTypes.bool,
    noInputFieldClass: PropTypes.bool,
    keyObj: PropTypes.string,
    changeObj: PropTypes.func,
    freezeOpti: PropTypes.bool,
    obligatory: PropTypes.bool,
    tooltip: PropTypes.string,
    clearFunction: PropTypes.bool,
    id: PropTypes.string,
    displayWithCode: PropTypes.bool,
    limit: PropTypes.number,
    noLimit: PropTypes.bool,
    sortFunc: PropTypes.func,
    noSort: PropTypes.bool,
    labelSpan: PropTypes.string,
    style: PropTypes.object,
    dropdownHeight: PropTypes.string,
    'data-cy': PropTypes.string,
}

export default Select