import User from 'account/dto/User'
import SettingDto from 'administration/dto/SettingDto'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'
import ContactDto from 'referencial/components/contact/dto/ContactDto'
import i18n from 'simple-react-i18n'
import Toastr from 'toastr/components/Toastr'
import AdministrationAction from '../../administration/actions/AdministrationAction'
import AdministrationHomePageModal from '../../administration/components/homepage/AdministrationHomePageModal'
import SieauParameterDto from '../../administration/dto/SieauParameterDto'
import Popup from '../../components/modal/Popup'
import Wait from '../../components/progress/Wait'
import {
    applicationName,
    aquasysPath,
    formationUrl,
    supportUrl,
} from '../../conf/SieauConstants'
import aquasysLogoPath from 'assets/pictures/logoaQuasys.png'
import AppStore from '../../store/AppStore'
import { getSetting } from '../../utils/SettingUtils'
import ContactMailDialog from './mail/ContactMailDialog'
import { cacheChecker } from 'utils/CacheChecker'
import useApplicationSetting from 'utils/customHook/useApplicationSetting'


const BubbleHelp = ({ location }) => {
    const [isOpen, setIsOpen] = useState(false)
    const [isOpenMail, setIsOpenMail] = useState(false)
    const [email, setEmail] = useState('')

    const dispatch = useDispatch()

    const {
        settings,
        popup,
        accountUser,
        contacts,
        applicationSettings,
        helpLink,
    } = useSelector(store => ({
        settings: store.AdministrationReducer.settings,
        popup: store.SieauReducer.popup,
        accountUser: store.AccountReducer.accountUser,
        contacts: store.ContactReducer.contacts,
        applicationSettings: store.AdministrationReducer.applicationSettings,
        helpLink: store.HomeReducer.helpLink,
    }), shallowEqual)

    useEffect(() => {
        // cacheChecker()
        dispatch(AdministrationAction.fetchApplicationSettings())
    }, [dispatch])

    // componentWillReceiveProps(nextProps) {
    //     if (getSetting(applicationSettings, 'applicationName') !== getSetting(nextProps.applicationSettings, 'applicationName')) {
    //         document.title = getSetting(nextProps.applicationSettings, 'applicationName') || applicationName
    //     }
    //     if (getSetting(applicationSettings, 'applicationFavicon') !== getSetting(nextProps.applicationSettings, 'applicationFavicon')) {
    //         $('#favicon').attr('href', getSetting(nextProps.applicationSettings, 'applicationFavicon') || aquasysLogoPath)
    //     }
    //     if (nextProps.contacts.length && nextProps.accountUser.contactCode) {
    //         const findContact = contacts.find(o => o.code == accountUser.contactCode)
    //         this.setState({ email: findContact ? findContact.email : '' })
    //     } else if (this.state.email !== '') {
    //         this.setState({ email: '' })
    //     }
    // }

    useEffect(() => {
        cacheChecker(true)
    }, [location.pathname])

    useEffect(() => {
        document.title = getSetting(applicationSettings, 'applicationName') || applicationName
        $('#favicon').attr('href', getSetting(applicationSettings, 'applicationFavicon') || aquasysLogoPath)
    }, [applicationSettings])

    useEffect(() => {
        if (contacts.length && accountUser.contactCode) {
            const findContact = contacts.find(o => o.code == accountUser.contactCode)
            setEmail(findContact ? findContact.email : '')
        } else if (email !== '') {
            setEmail('')
        }
    }, [contacts, accountUser.contactCode])

    const open = (e) => {
        e.preventDefault()
        $('div.tap-target').tapTarget('open')
    }

    const getFormationLink = () => {
        const formationToken = getSetting(applicationSettings, 'formationToken')
        const formationLink = (() => {
            if (helpLink.formationPath && helpLink.formationPath !== '') {
                return `${formationUrl + helpLink.formationPath}/?tmp=${formationToken}`
            }
            return helpLink.module ? `${formationUrl + helpLink.module}/?tmp=${formationToken}` : `${formationUrl}?tmp=${formationToken}`
        })()

        return formationLink ? (
            <div className='row no-margin padding-bottom-1'>
                <div className='col s10 offset-s1'>
                    <a className='btn btn-small right' target='_blank'
                        href={formationLink}
                    >
                        {i18n.goToOnlineFormation}
                    </a>
                </div>
            </div>
        ) : null
    }

    const getHelpLink = () => {
        const formationToken = getSetting(applicationSettings, 'formationToken')
        const link = (() => {
            if (helpLink.module !== '') {
                return helpLink.number !== '' ? `${supportUrl}v7/${helpLink.module}/help-${helpLink.number}/?tmp=${formationToken}` : `${supportUrl}v7/${helpLink.module}/?tmp=${formationToken}`
            }
            return `${supportUrl}?tmp=${formationToken}`
        })()
        return link ? (
            <div className='row no-margin' style={{ paddingBottom: 60 }}>
                <div className='col s10 offset-s1'>
                    <a className='btn btn-small right' target='_blank'
                        href={link}
                    >
                        {i18n.goToOnlineHelp}
                    </a>
                </div>
            </div>
        ) : null
    }

    const supportPhoneNumber = useApplicationSetting('supportPhoneNumber', setting => setting || '')
    const supportContactSchedule = useApplicationSetting('supportContactSchedule', setting => setting || '')
    const supportMailAddress = useApplicationSetting('supportMailAddress', setting => setting || '')

    return (
        <div id='bubbleHelp' className='body-blue'>
            <div className='fixed-action-btn' id='bubble_help'>
                <a id='menu' className='btn btn-floating btn-large secondary-color-back' onClick={open}><i
                    className='material-icons'
                >help</i></a>
                <div className='tap-target' data-activates='menu'>
                    <div className='tap-target-content no-padding'>
                        <div className='row no-margin padding-bottom-1'>
                            <div className='col s6 offset-s5'>
                                <a className='btn btn-small right' onClick={() => window.open('#/sitePlan', 'newwindow', 'width=800,height=900')}>
                                    {i18n.sitePlan}
                                </a>
                            </div>
                        </div>
                        {settings.length && location.pathname === '/' ? (
                            <div className='row no-margin padding-bottom-1'>
                                <div className='col s8 offset-s3'>
                                    <a className='right btn btn-small waves-effect waves-light' onClick={() => setIsOpen(true)}>
                                        <i className='material-icons white-text clickable left'>settings</i>
                                        {i18n.editView}
                                    </a>
                                </div>
                            </div>
                        ) : null}
                        <div className='row no-margin padding-bottom-1'>
                            <div className='col s9 offset-s2'>
                                <a className='right btn btn-small waves-effect waves-light' onClick={() => setIsOpenMail(true)}>
                                    {i18n.contactSupport}
                                </a>
                            </div>
                        </div>
                        {getFormationLink()}
                        {getHelpLink()}
                        <div className='row'>
                            <div className='col s8 offset-s1'>
                                <span>Incident : 1</span><br />
                                <a className='white-text' href={`${aquasysPath}popup/contact/`} target='_blank'>
                                    {supportMailAddress}
                                </a> <br />
                                <span> {supportPhoneNumber} <br />
                                    {supportContactSchedule} </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Toastr store={AppStore} />
            <Wait />
            <Popup
                header={popup?.header}
                actions={popup?.actions}
                idPopup='generalPopup'
            >
                {popup?.content}
            </Popup>
            <AdministrationHomePageModal isOpen={isOpen} setIsOpen={setIsOpen} />
            <ContactMailDialog
                isOpen={isOpenMail}
                setIsOpen={setIsOpenMail}
                email={email}
                setEmail={setEmail}
            />
        </div>
    )
}

BubbleHelp.propTypes = {
    location: PropTypes.shape({
        pathname: PropTypes.string,
        href: PropTypes.string,
    }),
    settings: PropTypes.arrayOf(PropTypes.instanceOf(SettingDto)),
    popup: PropTypes.element,
    accountUser: PropTypes.instanceOf(User),
    contacts: PropTypes.arrayOf(PropTypes.instanceOf(ContactDto)),
    applicationSettings: PropTypes.arrayOf(PropTypes.instanceOf(SieauParameterDto)),
    helpLink: PropTypes.shape({
        module: PropTypes.string,
        number: PropTypes.string,
        formationPath: PropTypes.string,
    }),
}

export default withRouter(BubbleHelp)
