import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import useSandreList from 'utils/customHook/useSandreList'
import { SANDRE } from 'referencial/constants/ReferencialConstants'
import Card from 'components/card/Card'
import SimpleDatePicker from 'components/forms/SimpleDatePicker'
import Input from 'components/forms/Input'
import { getDate, getHour } from 'utils/DateUtil'
import { onChangeHour } from 'utils/FormUtils'
import Select from 'components/forms/Select'
import Checkbox from 'components/forms/Checkbox'
import NumberField from 'components/forms/NumberField'
import Textarea from 'components/forms/Textarea'
import { Grid } from '@mui/material'
import { StyledFieldSet, StyledLegend } from 'components/StyledElements'
import PropTypes from 'prop-types'
import useActions from 'utils/customHook/useActions'
import { push } from 'connected-react-router'
import { isNil, uniq } from 'lodash'
import useBoolean from 'utils/customHook/useBoolean'

const HydrobioOperationDescription = ({
    idStation,
    idOperation,
}) => {
    const dispatch = useDispatch()

    const {
        hydrobioOperation,
        qualitometer,
        qualityCampaignStations,
        qualityCampaigns,
        statusList,
        supports,
        qualifications,
        methods,
        contacts,
        contributors,
    } = useSelector(store => ({
        hydrobioOperation: store.OperationReducer.hydrobioOperation,
        operationAnalysis: store.OperationReducer.operationAnalysis,
        qualitometer: store.QualityReducer.qualitometer,
        qualityCampaignStations: store.QualityReducer.qualityCampaignStations,
        qualityCampaigns: store.QualityReducer.qualityCampaigns,
        statusList: store.QualityReducer.status,
        supports: store.SupportReducer.supports,
        qualifications: store.QualityReducer.qualifications,
        methods: store.MethodReducer.methods,
        contacts: store.ContactReducer.contacts,
        contributors: store.ContributorReducer.contributors,
    }))

    const sandreConservationMode = useSandreList(SANDRE.CONSERVATION_MODE)
    const sandreParticularSituation = useSandreList(SANDRE.PARTICULAR_SITUATION)
    const sandreSampleSpeed = useSandreList(SANDRE.SAMPLE_SPEED)

    useActions(() => {
        if (idOperation === 'new') {
            return {
                cancel: () => dispatch(push(`/station/quality/${idStation}/hydrobioOperation`)),
            }
        }
        return {}
    }, [])

    const { value: editMode } = useBoolean(false)
    // const [isOpen, setIsOpen] = useState(false)

    const [date, setDate] = useState(hydrobioOperation.date)
    const [hourStart, setHourStart] = useState(hydrobioOperation.hourStart)
    const [dateEnd, setDateEnd] = useState(hydrobioOperation.dateEnd)
    const [hourEnd, setHourEnd] = useState(hydrobioOperation.hourEnd)
    const [campaignCode, setCampaignCode] = useState(hydrobioOperation.campaignCode)
    const [status, setStatus] = useState(hydrobioOperation.status)
    const [qualification, setQualification] = useState(hydrobioOperation.qualification)
    const [accreditation, setAccreditation] = useState(hydrobioOperation.accreditation === '1')
    const [bankingAuthorization, setBankingAuthorization] = useState(hydrobioOperation.bankingAuthorization === '1')
    const [point, setPoint] = useState(hydrobioOperation.point)
    const [support, setSupport] = useState(hydrobioOperation.support)
    const [localization, setLocalization] = useState(hydrobioOperation.localization)
    const [length, setLength] = useState(hydrobioOperation.length)
    const [averageWidth, setAverageWidth] = useState(hydrobioOperation.averageWidth)
    const [principalConservationMode, setPrincipalConservationMode] = useState(hydrobioOperation.principalConservationMode)
    const [secondConservationMode, setSecondConservationMode] = useState(hydrobioOperation.secondConservationMode)
    const [comment, setComment] = useState(hydrobioOperation.comment)
    const [interpretation, setInterpretation] = useState(hydrobioOperation.interpretation)
    const [particularSituation, setParticularSituation] = useState(hydrobioOperation.particularSituation)
    const [wetArea, setWetArea] = useState(hydrobioOperation.wetArea)
    const [speed, setSpeed] = useState(hydrobioOperation.speed)
    const [continueOperation, setContinueOperation] = useState(hydrobioOperation.continue === '1')
    const [producer, setProducer] = useState(hydrobioOperation.producer)
    const [producerContact, setProducerContact] = useState(hydrobioOperation.producerContact)
    const [sampler, setSampler] = useState(hydrobioOperation.sampler)
    const [samplerContact, setSamplerContact] = useState(hydrobioOperation.samplerContact)
    const [determiner, setDeterminer] = useState(hydrobioOperation.determiner)
    const [determinerContact, setDeterminerContact] = useState(hydrobioOperation.determinerContact)
    const [biologicalProtocol, setBiologicalProtocol] = useState(hydrobioOperation.biologicalProtocol && `${hydrobioOperation.biologicalProtocol}`)
    const [analysisMethod, setAnalysisMethod] = useState(hydrobioOperation.analysisMethod && `${hydrobioOperation.analysisMethod}`)
    const [resultComment, setResultComment] = useState(hydrobioOperation.resultComment)
    const [microSampling, setMicroSampling] = useState(hydrobioOperation.microSampling === '1')

    useEffect(() => {
        setDate(hydrobioOperation.date)
        setHourStart(hydrobioOperation.hourStart)
        setDateEnd(hydrobioOperation.dateEnd)
        setHourEnd(hydrobioOperation.hourEnd)
        setCampaignCode(hydrobioOperation.campaignCode)
        setStatus(hydrobioOperation.status)
        setQualification(hydrobioOperation.qualification)
        setAccreditation(hydrobioOperation.accreditation === '1')
        setBankingAuthorization(hydrobioOperation.bankingAuthorization === '1')
        setPoint(hydrobioOperation.point)
        setSupport(hydrobioOperation.support)
        setLocalization(hydrobioOperation.localization)
        setLength(hydrobioOperation.length)
        setAverageWidth(hydrobioOperation.averageWidth)
        setPrincipalConservationMode(hydrobioOperation.principalConservationMode)
        setSecondConservationMode(hydrobioOperation.secondConservationMode)
        setComment(hydrobioOperation.comment)
        setInterpretation(hydrobioOperation.interpretation)
        setParticularSituation(hydrobioOperation.particularSituation)
        setWetArea(hydrobioOperation.wetArea)
        setSpeed(hydrobioOperation.speed)
        setContinueOperation(hydrobioOperation.continue === '1')
        setProducer(hydrobioOperation.producer)
        setProducerContact(hydrobioOperation.producerContact)
        setSampler(hydrobioOperation.sampler)
        setSamplerContact(hydrobioOperation.samplerContact)
        setDeterminer(hydrobioOperation.determiner)
        setDeterminerContact(hydrobioOperation.determinerContact)
        setBiologicalProtocol(hydrobioOperation.biologicalProtocol && `${hydrobioOperation.biologicalProtocol}`)
        setAnalysisMethod(hydrobioOperation.analysisMethod && `${hydrobioOperation.analysisMethod}`)
        setResultComment(hydrobioOperation.resultComment)
        setMicroSampling(hydrobioOperation.microSampling === '1')
    }, [hydrobioOperation])

    const campaigns = useMemo(() => {
        const stationId = parseInt(idStation)
        const qualityCampaignsFiltered = qualityCampaigns.filter(cs => cs.campaignType === 2)
        const campaignCodes = qualityCampaignStations.filter(cs => cs.stationId === stationId).map(cs => cs.campaignId)
        return uniq(campaignCodes).map(cCode => qualityCampaignsFiltered.find(c => c.id === cCode)).filter(c => !!c)
    }, [idStation, qualityCampaignStations, qualityCampaigns])

    const filteredPoints = useMemo(() => {
        return qualitometer.link_samplePoints
            .filter(p => isNil(support) || isNil(p.codeSupport) || p.codeSupport === support)
            .filter(p => isNil(p.startDate) || p.startDate <= date)
            .filter(p => isNil(p.endtDate) || p.endtDate >= date)
    }, [date, qualitometer.link_samplePoints, support])

    const formattedPoints = useMemo(() => {
        return filteredPoints.map(p => {
            const supportObj = supports.find(s => s.code === `${p.codeSupport}`)
            return {
                name: `${p.name || (supportObj?.name ?? 'Eau')} [${p.identifiant || (supportObj?.code ?? '031')}] ${p.code ?? ''} ${p.startDepth || p.endDepth ? `${p.startDepth ?? '(...)'}m -> ${p.endDepth ?? '(...)'}m` : ''}`,
                id: p.idPoint,
            }
        })
    }, [filteredPoints, supports])

    return (
        <Card className='no-box-shadow'>
            <div style={{ padding: '10 0 20 0', width: 1200, marginLeft: 'auto', marginRight: 'auto' }}>
                <Grid container columnSpacing={2} rowSpacing={0} alignItems='center'>
                    <Grid item xs={3}>
                        <SimpleDatePicker
                            value={date}
                            id='startDate'
                            label={i18n.beginDate}
                            onChange={setDate}
                            disabled={!editMode}
                            active={editMode}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <Input
                            title={i18n.startHour}
                            value={getHour(hourStart)}
                            onChange={v => onChangeHour(v, setHourStart)}
                            disabled={!editMode}
                            active={editMode}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        {
                            !!hydrobioOperation.majDate && (
                                <Card cardStyle={{ padding: '5' }}>
                                    <span>
                                        {`${i18n.lastUpdateOn} ${getDate(hydrobioOperation.majDate, 'DD/MM/YYYY  à HH:mm')} ${hydrobioOperation.majLogin ? `${i18n.by} ${hydrobioOperation.majLogin}` : ''}`}
                                    </span>
                                </Card>
                            )
                        }
                    </Grid>
                    <Grid item xs={3}>
                        <SimpleDatePicker
                            value={dateEnd}
                            id='endDate'
                            label={i18n.endDate}
                            onChange={setDateEnd}
                            disabled={!editMode}
                            active={editMode}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <Input
                            title={i18n.endHour}
                            value={getHour(hourEnd)}
                            onChange={v => onChangeHour(v, setHourEnd)}
                            disabled={!editMode}
                            active={editMode}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Select
                            options={campaigns}
                            label={i18n.campaign}
                            value={campaignCode}
                            nullLabel='&nbsp;'
                            onChange={setCampaignCode}
                            disabled={!editMode}
                            active={editMode}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <Select
                            options={statusList}
                            label={i18n.status}
                            value={status}
                            nullLabel='&nbsp;'
                            onChange={setStatus}
                            disabled={!editMode}
                            active={editMode}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <Select
                            options={qualifications}
                            label={i18n.qualification}
                            value={qualification}
                            nullLabel='&nbsp;'
                            onChange={setQualification}
                            disabled={!editMode}
                            active={editMode}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <Checkbox
                            componentClassName='padding-top-1'
                            label={i18n.accreditation}
                            checked={accreditation}
                            onChange={setAccreditation}
                            disabled={!editMode}
                            active={editMode}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <Checkbox
                            componentClassName='padding-top-1'
                            label={i18n.authorizedBanking}
                            checked={bankingAuthorization}
                            onChange={setBankingAuthorization}
                            disabled={!editMode}
                            active={editMode}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <Select
                            options={formattedPoints}
                            label={i18n.point}
                            value={point}
                            nullLabel='&nbsp;'
                            keyValue='idPoint'
                            onChange={setPoint}
                            disabled={!editMode}
                            active={editMode}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <Select
                            label={i18n.support}
                            options={supports}
                            value={support}
                            onChange={setSupport}
                            disabled={!editMode}
                            active={editMode}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Input
                            title={i18n.location}
                            value={localization}
                            onChange={setLocalization}
                            disabled={!editMode}
                            active={editMode}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <NumberField
                            title={`${i18n.length} (m)`}
                            value={length}
                            floatValue
                            onChange={setLength}
                            disabled={!editMode}
                            active={editMode}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <NumberField
                            title={i18n.averageWidth}
                            value={averageWidth}
                            floatValue
                            onChange={setAverageWidth}
                            disabled={!editMode}
                            active={editMode}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <Select
                            options={sandreConservationMode}
                            label={i18n.principalConservationMode}
                            value={principalConservationMode}
                            nullLabel='&nbsp;'
                            onChange={setPrincipalConservationMode}
                            disabled={!editMode}
                            active={editMode}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <Select
                            options={sandreConservationMode}
                            label={i18n.secondConservationMode}
                            value={secondConservationMode}
                            nullLabel='&nbsp;'
                            onChange={setSecondConservationMode}
                            disabled={!editMode}
                            active={editMode}
                        />
                    </Grid>
                    <Grid item xs={6} style={{ paddingBottom: 10 }}>
                        <Textarea
                            title={i18n.comment}
                            value={comment}
                            onChange={setComment}
                            disabled={!editMode}
                            active={editMode}
                        />
                    </Grid>
                    <Grid item xs={6} style={{ paddingBottom: 10 }}>
                        <Textarea
                            title={i18n.interpretation}
                            value={interpretation}
                            onChange={setInterpretation}
                            disabled={!editMode}
                            active={editMode}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <Select
                            options={sandreParticularSituation}
                            label={i18n.particularSituation}
                            value={particularSituation}
                            nullLabel='&nbsp;'
                            onChange={setParticularSituation}
                            disabled={!editMode}
                            active={editMode}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <NumberField
                            title={`${i18n.wetArea} (m2)`}
                            value={wetArea}
                            floatValue
                            onChange={setWetArea}
                            disabled={!editMode}
                            active={editMode}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <Select
                            options={sandreSampleSpeed}
                            label={i18n.speed}
                            value={speed}
                            nullLabel='&nbsp;'
                            onChange={setSpeed}
                            disabled={!editMode}
                            active={editMode}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <Checkbox
                            label={i18n.continue}
                            checked={continueOperation}
                            onChange={setContinueOperation}
                            disabled={!editMode}
                            active={editMode}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <StyledFieldSet style={{ padding: '10px 5px' }}>
                            <StyledLegend>{i18n.producer}</StyledLegend>
                            <Grid container columnSpacing={2}>
                                <Grid item xs={8}>
                                    <Select
                                        options={contributors}
                                        label={i18n.producer}
                                        value={producer}
                                        nullLabel='&nbsp;'
                                        keyLabel='labelDisplay'
                                        displayWithCode
                                        onChange={setProducer}

                                        disabled={!editMode}
                                        active={editMode}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <Select
                                        options={contacts}
                                        label={i18n.contact}
                                        value={producerContact}
                                        nullLabel='&nbsp;'
                                        displayWithCode
                                        onChange={setProducerContact}
                                        disabled={!editMode}
                                        active={editMode}
                                    />
                                </Grid>
                            </Grid>
                        </StyledFieldSet>
                    </Grid>
                    <Grid item xs={12}>
                        <StyledFieldSet style={{ padding: '10px 5px' }}>
                            <StyledLegend>{i18n.sampler}</StyledLegend>
                            <Grid container columnSpacing={2}>
                                <Grid item xs={8}>
                                    <Select
                                        options={contributors}
                                        label={i18n.sampler}
                                        value={sampler}
                                        nullLabel='&nbsp;'
                                        displayWithCode
                                        keyLabel='labelDisplay'
                                        onChange={setSampler}

                                        disabled={!editMode}
                                        active={editMode}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <Select
                                        options={contacts}
                                        label={i18n.contact}
                                        value={samplerContact}
                                        nullLabel='&nbsp;'
                                        displayWithCode
                                        onChange={setSamplerContact}

                                        disabled={!editMode}
                                        active={editMode}
                                    />
                                </Grid>
                            </Grid>
                        </StyledFieldSet>
                    </Grid>
                    <Grid item xs={12} style={{ paddingBottom: 10 }}>
                        <StyledFieldSet style={{ padding: '10px 5px' }}>
                            <StyledLegend>{i18n.determiner}</StyledLegend>
                            <Grid container columnSpacing={2}>
                                <Grid item xs={8}>
                                    <Select
                                        options={contributors}
                                        label={i18n.determiner}
                                        value={determiner}
                                        nullLabel='&nbsp;'
                                        keyLabel='labelDisplay'
                                        displayWithCode
                                        onChange={setDeterminer}

                                        disabled={!editMode}
                                        active={editMode}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <Select
                                        options={contacts}
                                        label={i18n.contact}
                                        value={determinerContact}
                                        nullLabel='&nbsp;'
                                        displayWithCode
                                        onChange={setDeterminerContact}

                                        disabled={!editMode}
                                        active={editMode}
                                    />
                                </Grid>
                            </Grid>
                        </StyledFieldSet>
                    </Grid>
                    <Grid item xs={6}>
                        <Select
                            options={methods}
                            label={i18n.protocol}
                            value={biologicalProtocol}
                            nullLabel='&nbsp;'
                            onChange={setBiologicalProtocol}
                            disabled={!editMode}
                            active={editMode}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Select
                            label={i18n.method}
                            options={methods}
                            value={analysisMethod}
                            onChange={setAnalysisMethod}
                            disabled={!editMode}
                            active={editMode}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Textarea
                            title={i18n.comment}
                            value={resultComment}
                            onChange={setResultComment}
                            disabled={!editMode}
                            active={editMode}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Checkbox
                            label={i18n.microSampling}
                            checked={microSampling}
                            onChange={setMicroSampling}
                            disabled={!editMode}
                            active={editMode}
                        />
                    </Grid>
                </Grid>
            </div>
        </Card>
    )
}

HydrobioOperationDescription.propTypes = {
    idStation: PropTypes.string,
    idOperation: PropTypes.string,
}

export default HydrobioOperationDescription