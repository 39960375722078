import AccountApp from 'account/components/AccountApp'
import AdministrationApp from 'administration/components/AdministrationApp'
import AlertApp from 'alerting/components/AlertApp'
import CatchmentApp from 'catchment/components/catchment/CatchmentApp'
import CatchmentsApp from 'catchment/components/catchments/CatchmentsApp'
import CmsEventForm from 'events/components/CmsEventForm'
import ExploitationsApp from 'exploitations/components/ExploitationsApp'
import ContentsApp from 'home/components/contents/ContentsApp'
import DocumentsApp from 'home/components/documents/DocumentsApp'
import GlobalEventsApp from 'home/components/globalEvents/GlobalEventsApp'
import ArrestsApp from 'home/components/prefectoralArrest/ArrestsApp'
import VigilancesApp from 'home/components/vigilance/VigilancesApp'
import HydrometryCampaignApp from 'hydrometry/components/campaign/HydrometryCampaignApp'
import JobContentApp from 'import/components/content/JobContentApp'
import InstallationCampaignApp from 'installation/components/campaign/InstallationCampaignApp'
import InstallationsApp from 'installation/components/installations/components/InstallationsApp'
import { template } from 'lodash'
import BoundaryError from 'log/components/BoundaryError'
import MaterielApp from 'materiel/components/MaterielApp'
import ConversationMessages from 'messages/components/ConversationMessages'
import DashboardMessages from 'messages/components/DashboardMessages'
import PGSSEApp from 'pgsse/PGSSEApp'
import PiezometryCampaignApp from 'piezometry/components/campaign/PiezometryCampaignApp'
import PiezometersApp from 'piezometry/components/piezometers/PiezometersApp'
import PluviometryCampaignApp from 'pluviometry/components/campaign/PluviometryCampaignApp'
import QualityCampaignApp from 'quality/components/campaign/QualityCampaignApp'
import QualityOptionsApp from 'quality/components/options/QualityOptionsApp'
import QualitometersApp from 'quality/components/qualitometers/QualitometersApp'
import React, { useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { Route, Switch } from 'react-router-dom'
import ReferencialApp from 'referencial/components/ReferencialApp'
import i18n from 'simple-react-i18n'
import StationApp from 'station/components/StationApp'
import StationsApp from 'station/components/StationsApp'
import SurveyApp from 'survey/components/survey/SurveyApp'
import PlanificationApp from 'survey/components/PlanificationApp'
import TerritoryApp from 'territory/components/TerritoryApp'
import ToastrAction from 'toastr/actions/ToastrAction'
import AccountAction from '../../account/actions/AccountAction'
import SelectDropdown from '../../components/forms/SelectDropdown'
import AppBar from '../../components/navbar/AppBar'
import SlideNav from '../../components/navbar/SlideNav'
import SieauTooltip from '../../components/tooltip/SieauTooltip'
import MaterielOptionsApp from '../../materiel/components/administration/MaterielOptionsApp'
import SSEActions from '../../sse/actions/SSEActions'
import { FACEBOOK_EVENT_FAILED, FACEBOOK_EVENT_SUCCEEDED, JOB_COMPLETED } from '../../sse/constants/EventsConstants'
import DashboardApp from './DashboardApp'
import JobTableExecutions from '../../import/components/table/JobTableExecutions'
import ActivityRouterApp from 'referencial/components/activity/components/ActivityRouterApp'
import AquiferRouterApp from 'referencial/components/aquifers/components/AquiferRouterApp'
import WatershedRouterApp from 'referencial/components/watershed/components/WatershedRouterApp'
import CityRouterApp from 'referencial/components/city/components/CityRouterApp'
import ContactRouterApp from 'referencial/components/contact/components/ContactRouterApp'
import ContactGroupRouterApp from 'referencial/components/contact/components/ContactGroupRouterApp'
import CultureRouterApp from 'referencial/components/cultures/components/CultureRouterApp'
import CulturesFamilyRouterApp from 'referencial/components/culturesFamilies/components/CulturesFamilyRouterApp'
import HydrogeologicalEntityRouterApp from 'referencial/components/hydrogeologicalEntity/components/HydrogeologicalEntityRouterApp'
import FractionRouterApp from 'referencial/components/fraction/components/FractionRouterApp'
import ContributorRouterApp from 'referencial/components/contributor/components/ContributorRouterApp'
import LexiconRouterApp from 'referencial/components/lexicons/LexiconRouterApp'
import WatermassRouterApp from 'referencial/components/watermass/components/WatermassRouterApp'
import SandreCodeRouterApp from 'referencial/components/sandreCodes/SandreCodeRouterApp'
import ParameterRouterApp from 'referencial/components/parameter/components/ParameterRouterApp'
import ParameterGroupRouterApp from 'referencial/components/parameter/components/ParameterGroupRouterApp'
import CountryRouterApp from 'referencial/components/country/components/CountryRouterApp'
import NetworkRouterApp from 'referencial/components/network/components/NetworkRouterApp'
import SupportRouterApp from 'referencial/components/support/components/SupportRouterApp'
import TaxonRouterApp from 'referencial/components/taxon/components/TaxonRouterApp'
import MatTypeRouterApp from 'referencial/components/matTypes/MatTypeRouterApp'
import UnitRouterApp from 'referencial/components/unit/components/UnitRouterApp'
import ManagementUnitRouterApp from 'referencial/components/managementUnit/components/ManagementUnitRouterApp'
import MethodRouterApp from 'referencial/components/methods/components/MethodRouterApp'
import HydrometersApp from '../../hydrometry/components/hydrometers/HydrometersApp'
import ContributorGroupRouterApp from 'referencial/components/contributor/components/ContributorGroupRouterApp'
import UserRouterApp from 'administration/components/user/components/UserRouterApp'
import QualityAction from 'quality/actions/QualityAction'
import AdministrationAction from 'administration/actions/AdministrationAction'
import HomeAction from 'home/actions/HomeAction'
import ReferencialAction from 'referencial/action/ReferencialAction'
import ResourceApp from 'resource/components/ResourceApp'
import CultureRPGRouterApp from 'referencial/components/cultures/components/CultureRPGRouterApp'
import { getLogin } from 'utils/SettingUtils'
import SectorRouterApp from 'referencial/components/sector/components/SectorRouterApp'
import { TIMEZONE_INTEGRATION, TIMEZONE_LOCAL } from 'utils/DateUtil'
import moment from 'moment-timezone'
import useAccountSetting from 'utils/customHook/useAccountSetting'
import PARApp from 'survey/components/par/PARApp'
import ScenarioApp from 'survey/components/par/ScenarioApp'
import PerimetersApp from '../../perimeters/PerimetersApp'

// eslint-disable-next-line no-unused-vars
const SSE = () => {
    const dispatch = useDispatch()

    const handleJobCompleted = event => {
        const { job } = JSON.parse(event.data)
        const jobCompleted = template(i18n.jobIDCompleted)({ jobId: job.name })
        dispatch(ToastrAction.success(jobCompleted))
    }

    const handleFacebookPublication = event => {
        switch (event.type) {
            case FACEBOOK_EVENT_FAILED:
                dispatch(ToastrAction.error(i18n.fbPublishError))
                break
            case FACEBOOK_EVENT_SUCCEEDED:
                dispatch(ToastrAction.success(i18n.fbPublisherSuccess))
                break
            default:
                break
        }
    }

    useEffect(() => {
        dispatch(SSEActions.connect())
        dispatch(SSEActions.subscribe(JOB_COMPLETED, handleJobCompleted))
        dispatch(SSEActions.subscribe(FACEBOOK_EVENT_FAILED, handleFacebookPublication))
        dispatch(SSEActions.subscribe(FACEBOOK_EVENT_SUCCEEDED, handleFacebookPublication))
        return () => {
            dispatch(SSEActions.stop())
        }
    }, [])

    return null
}

const SieauApp = () => {
    const dispatch = useDispatch()

    const {
        stationTypes,
        settings,
        accountHabilitations,
    } = useSelector(store => ({
        stationTypes: store.ReferencialReducer.stationTypes,
        settings: store.AdministrationReducer.settings,
        accountHabilitations: store.AccountReducer.accountHabilitations,
    }), shallowEqual)

    const timezone = useAccountSetting('usedTimezone')

    const login = getLogin()

    useEffect(() => {
        dispatch(QualityAction.fetchQualityIndicators())
        dispatch(AdministrationAction.fetchPictos())
        dispatch(HomeAction.fetchThemeLayers())
        dispatch(AdministrationAction.fetchSettings())
        dispatch(AdministrationAction.fetchCartographyThemes())
        dispatch(ReferencialAction.fetchStationTypes())
    }, [])

    useEffect(() => {
        switch (timezone) {
            case TIMEZONE_INTEGRATION: moment.tz.setDefault('Europe/London'); break
            case TIMEZONE_LOCAL: moment.tz.setDefault(); break
            default: moment.tz.setDefault()
        }
    }, [timezone])

    useEffect(() => {
        dispatch(AccountAction.fetchUser(login))
        dispatch(AccountAction.fetchAccountStatistics(login))
    }, [login])

    return !!stationTypes.length && !!settings.length && !!accountHabilitations.length && (
        <div className='body-blue'>
            {/* <SSE /> */}
            <SelectDropdown />
            <div id='sieauApp' style={{ minHeight: '100vh' }}>
                <AppBar />
                <SlideNav />
                <BoundaryError>
                    <Switch>
                        <Route exact path='/' render={(props) => <DashboardApp {...props} />} />
                        <Route path='/account' render={(props) => <AccountApp {...props} />} />
                        <Route path='/arrests' render={(props) => <ArrestsApp {...props} />} />
                        <Route path='/alert' render={(props) => <AlertApp {...props} />} />
                        <Route path='/contents/:categoryId/:contentId/:lang' render={(props) => <CmsEventForm {...props} />} />
                        <Route path='/contents/:categoryId/:contentId' render={(props) => <CmsEventForm {...props} />} />
                        <Route path='/contents/:categoryId' render={(props) => <ContentsApp {...props} />} />
                        <Route path='/distributionUnit' render={(props) => <StationsApp {...props} />} />
                        <Route path='/documents' render={(props) => <DocumentsApp {...props} />} />
                        <Route path={['/dossiers/:id', '/dossiers']} render={(props) => <ExploitationsApp {...props} />} />
                        <Route path='/globalEvents' render={(props) => <GlobalEventsApp {...props} />} />
                        <Route path='/hydrometry/campaign/:id' render={(props) => <HydrometryCampaignApp {...props} />} />
                        <Route path='/hydrometry' render={(props) => <HydrometersApp {...props} />} />
                        <Route path='/import/:id' render={(props) => <JobContentApp {...props} />} />
                        <Route path='/installation/campaign/:id' render={(props) => <InstallationCampaignApp {...props} />} />
                        <Route path='/installation' render={(props) => <InstallationsApp {...props} />} />
                        <Route path='/perimeter/:id' render={(props) => <PerimetersApp {...props} />} />
                        <Route path='/perimeter' render={(props) => <PerimetersApp {...props} />} />
                        <Route path='/job' render={(props) => <JobTableExecutions {...props} />} />
                        <Route path='/materiel/administration' render={(props) => <MaterielOptionsApp {...props} />} />
                        <Route path='/materiel' render={(props) => <MaterielApp {...props} />} />
                        <Route path='/messages/:login' render={(props) => <ConversationMessages {...props} />} />
                        <Route path='/messages' render={(props) => <DashboardMessages {...props} />} />
                        <Route path='/pgsse' render={(props) => <PGSSEApp {...props} />} />
                        <Route path='/piezometry/campaign/:id' render={(props) => <PiezometryCampaignApp {...props} />} />
                        <Route path='/piezometry' render={(props) => <PiezometersApp {...props} />} />
                        <Route path='/pluviometry/campaign/:id' render={(props) => <PluviometryCampaignApp {...props} />} />
                        <Route path='/pluviometry' render={(props) => <StationsApp {...props} />} />
                        <Route path='/resource' render={(props) => <StationsApp {...props} />} />
                        <Route path='/productionUnit' render={(props) => <StationsApp {...props} />} />
                        <Route path='/quality/qualityOptions' render={(props) => <QualityOptionsApp {...props} />} />
                        <Route path='/quality/campaign/:id' render={(props) => <QualityCampaignApp {...props} />} />
                        <Route path='/quality' render={(props) => <QualitometersApp {...props} />} />
                        <Route path='/station/catchment/:id' render={(props) => <CatchmentApp {...props} />} />
                        <Route path='/station/resource/:id' render={(props) => <ResourceApp {...props} />} />
                        <Route path='/station/:type/:id' render={(props) => <StationApp {...props} />} />
                        <Route path='/planning' render={(props) => <PlanificationApp {...props} />} />
                        <Route path='/survey/:id' render={(props) => <SurveyApp {...props} />} />
                        <Route path='/par/:id' render={(props) => <PARApp {...props} />} />
                        <Route path='/scenario/:id' render={(props) => <ScenarioApp {...props} />} />
                        <Route path='/territory' render={(props) => <TerritoryApp {...props} />} />
                        <Route path='/vigilances' render={(props) => <VigilancesApp {...props} />} />
                        <Route path='/catchment' render={(props) => <CatchmentsApp {...props} />} />
                        {/* Referencial */}
                        <Route path='/referencial/activity/:code' render={(props) => <ActivityRouterApp {...props} />} />
                        <Route path='/referencial/aquifer/:code' render={(props) => <AquiferRouterApp {...props} />} />
                        <Route path='/referencial/watershed/:code' render={(props) => <WatershedRouterApp {...props} />} />
                        <Route path='/referencial/city/:code' render={(props) => <CityRouterApp {...props} />} />
                        <Route path='/referencial/contact/:code' render={(props) => <ContactRouterApp {...props} />} />
                        <Route path='/referencial/contactGroup/:code' render={(props) => <ContactGroupRouterApp {...props} />} />
                        <Route path='/referencial/culture/:code' render={(props) => <CultureRouterApp {...props} />} />
                        <Route path='/referencial/cultureRPG/:code' render={(props) => <CultureRPGRouterApp {...props} />} />
                        <Route path='/referencial/culturesFamily/:code' render={(props) => <CulturesFamilyRouterApp {...props} />} />
                        <Route path='/referencial/hydrogeologicalEntity/:code' render={(props) => <HydrogeologicalEntityRouterApp {...props} />} />
                        <Route path='/referencial/fraction/:code' render={(props) => <FractionRouterApp {...props} />} />
                        <Route path='/referencial/contributor/:code' render={(props) => <ContributorRouterApp {...props} />} />
                        <Route path='/referencial/contributorGroup/:code' render={(props) => <ContributorGroupRouterApp {...props} />} />
                        <Route path='/referencial/lexicon/:field/:code' render={(props) => <LexiconRouterApp {...props} />} />
                        <Route path='/referencial/watermass/:code' render={(props) => <WatermassRouterApp {...props} />} />
                        <Route path='/referencial/method/:code' render={(props) => <MethodRouterApp {...props} />} />
                        <Route path='/referencial/sandreCode/:field/:code' render={(props) => <SandreCodeRouterApp {...props} />} />
                        <Route path='/referencial/parameter/:code' render={(props) => <ParameterRouterApp {...props} />} />
                        <Route path='/referencial/parameterGroup/:code' render={(props) => <ParameterGroupRouterApp {...props} />} />
                        <Route path='/referencial/country/:code' render={(props) => <CountryRouterApp {...props} />} />
                        <Route path='/referencial/network/:code' render={(props) => <NetworkRouterApp {...props} />} />
                        <Route path='/referencial/support/:code' render={(props) => <SupportRouterApp {...props} />} />
                        <Route path='/referencial/taxon/:code' render={(props) => <TaxonRouterApp {...props} />} />
                        <Route path='/referencial/materielType/:field/:code' render={(props) => <MatTypeRouterApp {...props} />} />
                        <Route path='/referencial/unit/:code' render={(props) => <UnitRouterApp {...props} />} />
                        <Route path='/referencial/managementUnit/:code' render={(props) => <ManagementUnitRouterApp {...props} />} />
                        <Route path='/referencial/sector/:code' render={(props) => <SectorRouterApp {...props} />} />
                        <Route path='/referencial' render={(props) => <ReferencialApp {...props} />} />
                        {/* Administration */}
                        <Route path='/administration/user/:login' render={(props) => <UserRouterApp {...props} />} />
                        <Route path='/administration' render={(props) => <AdministrationApp {...props} />} />
                    </Switch>
                </BoundaryError>
                <SieauTooltip />
            </div>
        </div>
    )
}

export default SieauApp
