/* eslint-disable camelcase */
import { OBSERVATORY } from 'administration/components/user/constants/HabilitationConstants'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import DtoUser from '../../../administration/components/user/dto/DtoUser'
import NumberField from '../../../components/forms/NumberField'
import { getDefaultCoordinates } from '../../../utils/mapUtils/CoordinateUtils'
import { getSetting } from '../../../utils/SettingUtils'
import { MAP_X_COORDINATE, MAP_Y_COORDINATE, MAP_ZOOM } from '../../constants/AccountSettingsConstants'
import DtoAccountSettings from '../../dto/DtoAccountSettings'
import AdministrationAction from '../../../administration/actions/AdministrationAction'
import UserAction from 'administration/components/user/actions/UserAction'
import ProgressBar from 'components/progress/ProgressBar'
import CartographyPanel from 'components/map/CartographyPanel'
import useProgressDispatch from 'utils/customHook/useProgressDispatch'
import { Grid } from '@mui/material'
import moment from 'moment'
import { transform } from 'ol/proj'
import { execByType } from 'utils/StationUtils'
import { componentHasHabilitations } from 'utils/HabilitationUtil'
import { H_HYDRO_MODULE, H_PIEZO_MODULE, H_PLUVIO_MODULE } from 'account/constants/AccessRulesConstants'
import PiezometryThunk from 'piezometry/actions/PiezometryThunk'
import HydrometryThunk from 'hydrometry/actions/HydrometryThunk'
import PluviometryThunk from 'pluviometry/actions/PluviometryThunk'
import { compact } from 'lodash'

const DEFAULT_MAP_ZOOM = 9

const MapAccountUserPanel = ({
    accountUser = {},
    accountUserSettings = [],
    changeSettings = () => {},
}) => {
    const {
        userHabilitations,
        applicationHabilitations,
        applicationSettings,

        piezometersLight,
        hydrometricStations,
        pluviometers,
    } = useSelector(store => ({
        userHabilitations: store.UserReducer.userHabilitations,
        applicationHabilitations: store.UserReducer.applicationHabilitations,
        applicationSettings: store.AdministrationReducer.applicationSettings,

        piezometersLight: store.PiezometryReducer.piezometersLight,
        hydrometricStations: store.HydrometryReducer.hydrometricStations,
        pluviometers: store.PluviometryReducer.pluviometers,
    }), shallowEqual)

    const [x, setX] = useState(undefined)
    const [y, setY] = useState(undefined)
    const [zoom, setZoom] = useState(DEFAULT_MAP_ZOOM)

    const dispatch = useDispatch()

    const { isLoaded, progress } = useProgressDispatch(() => compact([
        !applicationHabilitations.length && dispatch(UserAction.fetchApplicationHabilitations()),
        !userHabilitations.length && dispatch(UserAction.fetchUserHabilitations(accountUser.login)),
        !piezometersLight.length && dispatch(PiezometryThunk.fetchPiezometersLight()),
        !hydrometricStations.length && dispatch(HydrometryThunk.fetchHydrometricStations()),
        !pluviometers.length && dispatch(PluviometryThunk.fetchPluviometers()),
    ]), [])

    const createParameter = (parameter, value) => new DtoAccountSettings({
        login: accountUser.login,
        parameter,
        updateDate: moment().valueOf(),
        value,
    })

    const changeMap = value => {
        const mapZoom = value.getView().getZoom()
        const coordinates = transform(value.getView().getCenter(), 'EPSG:3857', 'EPSG:4326')

        const zoomParameter = createParameter(MAP_ZOOM, mapZoom)
        const xParameter = createParameter(MAP_X_COORDINATE, coordinates[0])
        const yParameter = createParameter(MAP_Y_COORDINATE, coordinates[1])
        const result = [xParameter, yParameter, zoomParameter]

        changeSettings(result)
    }

    const getCoordinate = (parameter, defaultValue) => {
        const paramValue = getSetting(applicationSettings, parameter)
        return parseFloat(paramValue) || defaultValue
    }

    const setDefaultCoordinates = () => {
        const defaultCoordinate = getDefaultCoordinates()
        const x_coordinate = getCoordinate('defaultMapXCoordinate', defaultCoordinate[0])
        const y_coordinate = getCoordinate('defaultMapYCoordinate', defaultCoordinate[1])
        const zoom_coordinate = getCoordinate('defaultMapZoom', DEFAULT_MAP_ZOOM)

        setX(x_coordinate)
        setY(y_coordinate)
        setZoom(zoom_coordinate)
    }

    useEffect(() => {
        const x_user = getSetting(accountUserSettings, MAP_X_COORDINATE)
        const y_user = getSetting(accountUserSettings, MAP_Y_COORDINATE)
        const zoom_user = getSetting(accountUserSettings, MAP_ZOOM)

        if (!x_user || !y_user || !zoom_user) {
            const applicationHabilitationsFiltered = applicationHabilitations.filter(a => a.habilitationType === OBSERVATORY && a.parameter !== OBSERVATORY)
            const applicationHabilitationFinded = applicationHabilitationsFiltered.find(o => userHabilitations.some(hab => hab.habilitation === o.parameter))

            if (applicationHabilitationFinded) {
                dispatch(AdministrationAction.fetchUserSettings(applicationHabilitationFinded.parameter, true)).then(applicativeUserSettings => {
                    const x_applicative = getSetting(applicativeUserSettings, MAP_X_COORDINATE)
                    const y_applicative = getSetting(applicativeUserSettings, MAP_Y_COORDINATE)
                    const zoom_applicative = getSetting(applicativeUserSettings, MAP_ZOOM)

                    if (x_applicative && y_applicative && zoom_applicative) {
                        setX(x_applicative)
                        setY(y_applicative)
                        setZoom(zoom_applicative)
                    } else {
                        setDefaultCoordinates()
                    }
                })
            } else {
                setDefaultCoordinates()
            }
        } else {
            setX(x_user)
            setY(y_user)
            setZoom(zoom_user)
        }
    }, [accountUserSettings, applicationHabilitations, userHabilitations])

    const stations = [
        ...piezometersLight,
        ...hydrometricStations,
        ...pluviometers,
    ].filter(s => execByType(s.typeName, {
        piezometry: () => componentHasHabilitations(H_PIEZO_MODULE, true),
        hydrometry: () => componentHasHabilitations(H_HYDRO_MODULE, true),
        pluviometry: () => componentHasHabilitations(H_PLUVIO_MODULE, true),
    }))

    return (isLoaded && x && y && zoom) ? (
        <Grid container item xs={12} spacing={2}>
            <Grid item xs={4}>
                <NumberField
                    title={i18n.longitude}
                    value={x}
                    disabled
                />
            </Grid>
            <Grid item xs={4}>
                <NumberField
                    title={i18n.latitude}
                    value={y}
                    disabled
                />
            </Grid>
            <Grid item xs={4}>
                <NumberField
                    title={i18n.zoom}
                    value={zoom}
                    disabled
                />
            </Grid>
            <Grid item xs={12}>
                <CartographyPanel
                    layers={['STATIONS_POINTS']}
                    componentType={'specificUser'}
                    stationsPoints={stations}
                    stationsPanelTitle={i18n.stations}
                    height={685}
                    login={accountUser.login}
                    onMoveEnd={changeMap}
                />
            </Grid>
        </Grid>
    ) : <ProgressBar progress={progress} />
}

MapAccountUserPanel.propTypes = {
    accountUser: PropTypes.instanceOf(DtoUser),
    accountUserSettings: PropTypes.arrayOf(PropTypes.instanceOf(DtoAccountSettings)),
    changeSettings: PropTypes.func,
}

export default MapAccountUserPanel
