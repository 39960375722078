import { Button, Grid, Icon } from '@mui/material'
import AgriAction from 'agriAdministration/actions/AgriAction'
import ColorfulCard from 'components/card/ColorfulCard'
import Select from 'components/forms/Select'
import TabList from 'components/list/TabList'
import { push } from 'connected-react-router'
import EventPieChart from 'events/components/chart/EventPieChart'
import EventsStationTypeBarChart from 'events/components/chart/EventsStationTypeBarChart'
import EventsTypeLegendPanel from 'events/components/chart/EventsTypeLegendPanel'
import EventsTypeVerticalBarChart from 'events/components/chart/EventsTypeVerticalBarChart'
import DtoEventExploitation from 'events/dto/DtoEventExploitation'
import DtoEventInstallation from 'events/dto/DtoEventInstallation'
import DtoExploitation from 'exploitations/dto/DtoExploitation'
import DtoExploitationEvent from 'exploitations/dto/DtoExploitationEvent'
import HomeAction from 'home/actions/HomeAction'
import DtoInstallationWithGeoItem from 'installation/components/installations/dto/DtoInstallationWithGeoItem'
import { groupBy, orderBy } from 'lodash'
import VariousMaterielAction from 'materiel/components/variousMateriel/actions/VariousMaterielAction'
import DtoMatEvent from 'materiel/components/variousMateriel/dto/DtoMatEvent'
import DtoMatEventsType from 'materiel/components/variousMateriel/dto/DtoMatEventsType'
import VariousMaterielDto from 'materiel/components/variousMateriel/dto/VariousMaterielDto'
import DtoVariousMaterielType from 'materiel/components/variousMateriel/dto/DtoVariousMaterielType'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import CityDto from 'referencial/components/city/dto/CityDto'
import ContactDto from 'referencial/components/contact/dto/ContactDto'
import ContributorAction from 'referencial/components/contributor/actions/ContributorAction'
import ContributorItem from 'referencial/components/contributor/dto/ContributorItem'
import i18n from 'simple-react-i18n'
import { STATION_NAME_ASSOCIATION } from 'station/constants/StationConstants'
import ToastrAction from 'toastr/actions/ToastrAction'
import { getSiteUrl } from 'utils/mapUtils/SiteTypes'
import { hasValue } from 'utils/NumberUtil'
import { getYear, getFullDate } from 'utils/DateUtil'
import { findStationTypeUrl, getStationTypeNameFromTypeCode } from 'utils/StationUtils'
import { getLabel } from 'utils/StoreUtils'
import { getI18nTitleData, i18nExist } from 'utils/StringUtil'
import Card from '../../components/card/Card'
import DtoSandreCode from 'referencial/dto/DtoSandreCode'
import moment from 'moment'
import { getEventColor } from 'utils/ColorUtil'
import { getEventType } from 'events/utils/EventUtils'
import { getDate } from 'utils/DateUtil'
import ActionComponent from 'components/ActionComponent'
import ExportAction from 'export/actions/ExportAction'
import { formatData } from 'utils/ExportDataUtil'
import { EVENT_TYPES } from 'events/constants/EventsConstants'
import { AccordionMUI, AccordionSummaryMUI, AccordionDetailsMUI } from 'components/styled/Accordions'
import ProgressCard from 'components/card/ProgressCard'

const initFilters = {
    type: null,
}

const headersExport = ['type', 'eventType', 'elementLabel', 'comment', 'date', 'login']

class ExploitationEventsApp extends ActionComponent {
    constructor(props) {
        super(props)
        this.state = {
            idExploitation: parseInt(props.match.params.id),
            dataLoaded: false,
            tmpFilter: initFilters,
            filter: initFilters,
            sortBy: 'date',
        }
    }

    componentDidMount() {
        const { idExploitation } = this.state
        const { exploitation } = this.props
        this.props.fetchExploitationEvents(idExploitation).then(() => {
            if (exploitation && exploitation.idExploitation !== idExploitation) {
                this.props.fetchExploitation(idExploitation).then(() => {
                    this.setExploitation(this.props.exploitation)
                })
            } else {
                this.setExploitation(exploitation)
            }
        })
    }

    setExploitation = (exploitation) => {
        this.props.fetchMatEventsByExploitation(exploitation.idExploitation).then(() => {
            this.props.fetchContributor(exploitation.operatorCode).then(() => {
                const { contributor } = this.props
                this.props.setTitle([
                    {
                        title: i18n.folders,
                        href: 'dossiers',
                    },
                    {
                        title: `${contributor.name} ${exploitation.codification ? `[${exploitation.codification}]` : ''}`,
                        href: `dossiers/${exploitation.idExploitation}/dashboard`,
                    },
                    {
                        title: i18n.journal,
                        href: `dossiers/${exploitation.idExploitation}/events`,
                    },
                ])
                this.setState({ dataLoaded: true, exploitation })
                this.setActions({
                    exportChoice: this.getDataExport(),
                })
            })
        })
    }

    getDataExport = () => {
        const { contributor } = this.props
        return {
            exportChoices: [{
                name: i18n.exportFull,
                formats: [{
                    type: i18n.csv,
                    callback: () => this.getExport(this.formatDataForExport(orderBy(this.getEvents(), 'eventDate', 'desc')), headersExport, 'csv', `${i18n.events}_${contributor.name}_${Date.now()}`),
                },
                {
                    type: i18n.exportXLSX,
                    callback: () => this.getExport(this.formatDataForExport(orderBy(this.getEvents(), 'eventDate', 'desc')), headersExport, 'xlsx', `${i18n.events}_${contributor.name}_${Date.now()}`),
                }],
            }],
        }
    }

    formatDataForExport = (data) => {
        const { matEventsTypes } = this.props
        const { filter } = this.state
        return data.map((e) => {
            const globalTypeLabel = ((this.getEventsTypes() || EVENT_TYPES).find(({ code }) => code === (filter.type === 'pointsPrelevement' ? e.eventType : e.globalType)) || {}).label
            const objectType = e.globalType === 'exploitation' ? e.associateType : e.globalType
            const objectId = e.globalType === 'exploitation' ? e.associateId : e.objectId
            const eventTypeLabel = objectType === 'materiel' && e.globalType !== 'exploitation' ? this.getMaterielType(objectId) : ((this.getEventsTypes(e.globalType) || EVENT_TYPES).find(({ code }) => code === e.eventType) || {}).label
            const dataType = `${i18nExist(globalTypeLabel) || globalTypeLabel || ''}${e.globalType === 'exploitation' && i18nExist(objectType) ? `${globalTypeLabel ? ' - ' : ''}${i18nExist(objectType)}` : ''}`
            const type = (matEventsTypes.find((t) => t.id === e.idType) || {}).name
            return {
                ...e,
                type: filter.type === 'pointsPrelevement' ? type : (filter.type === 'materiels' ? (i18nExist(eventTypeLabel) || eventTypeLabel || '') : dataType),
                eventType: filter.type === 'pointsPrelevement' ? dataType : (filter.type === 'materiels' ? type : (i18nExist(eventTypeLabel) || eventTypeLabel || '')),
                elementLabel: this.getObjectEvent(objectType, objectId, true),
                date: getFullDate(e.eventDate),
                login: e.updateLogin,
            }
        })
    }

    getExport = (data, headers, type, fileName) => {
        const orderedData = orderBy(data, 'codification')
        const dataWithHeader = orderedData.length > 0 ? [{ ...orderedData[0], headers }, ...orderedData.slice(1)] : []
        this.props.export(formatData(dataWithHeader), type, fileName)
    }

    getGroupMethod = () => {
        const { matEventsTypes, contacts } = this.props
        switch (this.state.sortBy) {
            case 'referent':
                return e => hasValue(e.contactCode) || e.updateLogin ? (getLabel(contacts, e.contactCode) || e.updateLogin) : i18n.unknownReferent
            case 'eventType':
                return e => getLabel(matEventsTypes, e.eventType) || getLabel(this.getEventsTypes(), e.eventType) || getLabel(this.getEventsTypes(), e.globalType) || i18n.unknownType
            default:
                return e => getYear(e.date, i18n.thisYear)
        }
    }

    getObjectEvent = (associateType, associateId, exportFormat) => {
        const { installationsWithGeo, variousMateriels, contacts, citiesIndex, sandreCodes } = this.props
        switch (associateType) {
            case 'installation':
                const point = installationsWithGeo.find(({ id }) => id === associateId)
                if (point) {
                    const city = point.townCode ? (citiesIndex[point.townCode] || null) : null
                    const boldInfo = `${point.name ? `${point.name}` : ''}${point.code ? ` [${point.code}]` : ''}`
                    const extraInfo = `${city ? `, ${city.labelWithCode}` : ''}${point.parcel && point.section ? `, ${point.parcel} ${point.section}` : ''}`
                    if (exportFormat) {
                        return `${boldInfo}${extraInfo}`
                    }
                    return (
                        <>
                            <span className='bold'>{boldInfo}</span>
                            {extraInfo}
                        </>
                    )
                }
                return i18n.unknownPoint
            case 'contact':
                const contact = contacts.find(({ id }) => id === associateId)
                if (contact) {
                    if (exportFormat) {
                        return contact.name || ''
                    }
                    return <span className='bold'>{contact.name || ''}</span>
                }
                return i18n.unknownContact
            case 'materiel':
                const materiel = variousMateriels.find(({ id }) => id === associateId)
                if (materiel) {
                    if (materiel.pump) {
                        const pumpCategory = sandreCodes.find((c) => c.field === 'MAT.MOBILITE' && c.code === materiel.mobilityCode)
                        const pumpType = materiel.pump.pumpType ? sandreCodes.find((c) => c.field === 'POMPES.TYPE' && c.code === materiel.pump.pumpType) : null
                        const boldInfo = materiel.reference || ''
                        const extraInfo = `${pumpCategory ? `, ${pumpCategory.name}` : ''}${pumpType ? `, ${pumpType.name}` : ''}`
                        if (exportFormat) {
                            return `${boldInfo}${extraInfo}`
                        }
                        return (
                            <>
                                <span className='bold'>{boldInfo}</span>
                                {extraInfo}
                            </>
                        )
                    }
                    if (materiel.counter) {
                        const boldInfo = materiel.reference || ''
                        const extraInfo = `${materiel.counter.brand ? `, ${materiel.counter.brand}` : ''}${materiel.counter.installationDate ? `, ${getDate(materiel.counter.installationDate)}` : ''}`
                        if (exportFormat) {
                            return `${boldInfo}${extraInfo}`
                        }
                        return (
                            <>
                                <span className='bold'>{boldInfo}</span>
                                {extraInfo}
                            </>
                        )
                    }
                    if (exportFormat) {
                        return materiel.reference || ''
                    }
                    return <span className='bold'>{materiel.reference || ''}</span>
                }
                return i18n.unknownMaterial
            case 'declaration':
                if (exportFormat) {
                    return i18n.declaration
                }
                return <span className='bold'>{i18n.declaration}</span>
            default:
                return i18n.unknown
        }
    }

    getIconExploitationEvent = (event) => {
        switch (event.associateType) {
            case 'installation':
                return 'room'
            case 'contact':
                return 'person'
            case 'materiel':
                return 'router'
            case 'declaration':
                return 'description'
            default:
                return 'folder'
        }
    }

    getCardExploitationEvents = (events) => {
        return (
            <div className='row no-margin'>
                {events.map(e => {
                    const type = (this.getEventsTypes().find((t) => t.code === e.eventType) || {}).label
                    return (
                        <ColorfulCard color={ this.getColor(e.eventType) }>
                            <div className={ 'row no-margin valign-wrapper clickable' }>
                                <div className='col s12 valign-wrapper'>
                                    <div className='col margin-1'>
                                        <Icon>{this.getIconExploitationEvent(e)}</Icon>
                                    </div>
                                    <div className='col s3'>
                                        <p className='collection-title' style={ { fontWeight: 'bold' } }>{type}</p>
                                    </div>
                                    <div className='col s4'>
                                        {this.getObjectEvent(e.associateType, e.associateId)}
                                    </div>
                                    <div className='col s2'>
                                        <p className='collection-content no-margin'>{e.comment}</p>
                                    </div>
                                    <div className='col s2'>
                                        <div className='collection-content'>{e.eventDate ? `${getFullDate(e.eventDate)} ${i18n.by} ${e.updateLogin || i18n.unknown}` : ''}</div>
                                    </div>
                                </div>
                            </div>
                        </ColorfulCard>
                    )
                })}
            </div>
        )
    }

    getCardInstallationEvents = (groups) => {
        const { sortBy } = this.state
        return orderBy(Object.keys(groups), key => key, sortBy === 'date' ? 'desc' : 'asc').map((key, i) => {
            const groupEvents = orderBy(groups[key], 'date', 'desc')
            return (
                <div className='padding-1'>
                    <AccordionMUI defaultExpanded={i === 0}>
                        <AccordionSummaryMUI>
                            {`${key} (${groupEvents.length} ${getI18nTitleData(i18n.event, i18n.events, groupEvents)})`}
                        </AccordionSummaryMUI>
                        <AccordionDetailsMUI nopadding className='row no-margin'>
                            {groupEvents.map(e => {
                                const icon = getSiteUrl(STATION_NAME_ASSOCIATION[getStationTypeNameFromTypeCode(e.stationType)])
                                return (
                                    <ColorfulCard color={ getEventColor(e.eventType) } onClick={ () => this.props.push(findStationTypeUrl(e.stationType, e.code, e.number)) }>
                                        <div className='row no-margin valign-wrapper padding-left-1'>
                                            <div className='col s1'>
                                                <div className='iconeTypeResearchWrapper'>
                                                    <img src={icon} className='responsive-img iconeTypeResearch' />
                                                </div>
                                            </div>
                                            <div className='col s2'>
                                                { getEventType(e) }
                                            </div>
                                            <div className='col s4'>
                                                {this.getObjectEvent('installation', e.code)}
                                            </div>
                                            <div className='col s3'>
                                                <p className='collection-content no-margin'>{e.comment}</p>
                                            </div>
                                            <div className='col s2'>
                                                <div className='collection-content'>{e.date ? `${getFullDate(e.date)} ${i18n.by} ${e.updateLogin}` : ''}</div>
                                            </div>
                                        </div>
                                    </ColorfulCard>
                                )
                            })}
                        </AccordionDetailsMUI>
                    </AccordionMUI>
                </div>
            )
        })
    }

    getMaterielType = (materielId) => {
        const { variousMateriels, variousMaterielTypes } = this.props
        const materiel = variousMateriels.find(({ id }) => id === materielId) || {}
        const typeFound = variousMaterielTypes.find(({ id }) => id === materiel.materielType) || {}
        return typeFound.name || i18n.unknown
    }

    getCardAllEvents = (groups) => {
        const { sortBy } = this.state
        return orderBy(Object.keys(groups), key => key, sortBy === 'date' ? 'desc' : 'asc').map((key, i) => {
            const groupEvents = groups[key]
            const orderedEvents = orderBy(groupEvents, 'eventDate', 'desc')
            return (
                <div className='padding-1'>
                    <AccordionMUI defaultExpanded={i === 0}>
                        <AccordionSummaryMUI>
                            {`${key} (${groupEvents.length} ${i18n[`event${groupEvents.length >= 2 ? 's' : ''}`]})`}
                        </AccordionSummaryMUI>
                        <AccordionDetailsMUI nopadding>
                            <div className='row no-margin'>
                                {orderedEvents.map(e => {
                                    const objectType = e.globalType === 'exploitation' ? e.associateType : e.globalType
                                    const objectId = e.globalType === 'exploitation' ? e.associateId : e.objectId
                                    const globalTypeLabel = (this.getEventsTypes().find(({ code }) => code === e.globalType) || {}).label
                                    const eventTypeLabel = objectType === 'materiel' && e.globalType !== 'exploitation' ? this.getMaterielType(objectId) : ((this.getEventsTypes(e.globalType) || []).find(({ code }) => code === e.eventType) || {}).label
                                    return (
                                        <ColorfulCard color={ this.getColor(e.globalType) }>
                                            <div className='row no-margin valign-wrapper clickable'>
                                                <div className='col margin-1'>
                                                    <Icon>{e.icon}</Icon>
                                                </div>
                                                <div className='col s3'>
                                                    <p className='collection-title' style={ { fontWeight: 'bold' } }>{`${globalTypeLabel || ''}${eventTypeLabel ? ` - ${eventTypeLabel}` : ''}`}</p>
                                                    <div className='collection-content'>{e.globalType === 'exploitation' ? i18nExist(objectType) : ''}</div>
                                                </div>
                                                <div className='col s4' style={{ display: 'flex' }}>
                                                    {this.getObjectEvent(objectType, objectId)}
                                                </div>
                                                <div className='col s2'>
                                                    <p className='collection-content no-margin'>{e.comment}</p>
                                                </div>
                                                <div className='col s2'>
                                                    <div className='collection-content'>{e.eventDate ? `${getFullDate(e.eventDate)} ${i18n.by} ${e.updateLogin || i18n.unknown}` : ''}</div>
                                                </div>
                                            </div>
                                        </ColorfulCard>
                                    )
                                })}
                            </div>
                        </AccordionDetailsMUI>
                    </AccordionMUI>
                </div>
            )
        })
    }

    getData = (events) => {
        const { matEventsTypes } = this.props
        const { filter, sortBy } = this.state
        const groups = groupBy(events, this.getGroupMethod())
        if (!filter.type) {
            return this.getCardAllEvents(groups)
        }
        if (filter.type === 'pointsPrelevement') {
            return this.getCardInstallationEvents(groups)
        }
        return orderBy(Object.keys(groups), key => key, sortBy === 'date' ? 'desc' : 'asc').map((key, i) => {
            const groupEvents = groups[key]
            const eventsGroup = groupBy(groupEvents, filter.type === 'exploitation' ? 'associateType' : 'objectId')
            const eventsCard = filter.type === 'exploitation' ? this.getCardExploitationEvents(groupEvents) : Object.keys(eventsGroup).map(objectId => {
                const orderedEvents = orderBy(eventsGroup[objectId], 'eventDate', 'desc')
                return (
                    <div className='row no-margin'>
                        {orderedEvents.map(e => {
                            const type = (matEventsTypes.find((t) => t.id === e.idType) || {}).name
                            const eventTypeLabel = this.getMaterielType(e.idMateriel)
                            return (
                                <ColorfulCard color={ this.getColor(e.eventType) }>
                                    <div className={ 'row no-margin valign-wrapper clickable' }>
                                        <div className='col s12 valign-wrapper'>
                                            <div className='col margin-1'>
                                                <Icon>router</Icon>
                                            </div>
                                            <div className='col s3'>
                                                <p className='collection-title' style={ { fontWeight: 'bold' } }>{`${type || ''}${eventTypeLabel ? ` - ${eventTypeLabel}` : ''}`}</p>
                                            </div>
                                            <div className='col s3'>
                                                {this.getObjectEvent('materiel', parseInt(objectId))}
                                            </div>
                                            <div className='col s2'>
                                                <p className='collection-content no-margin'>{e.comment}</p>
                                            </div>
                                            <div className='col s3'>
                                                <div className='collection-content'>{e.eventDate ? `${getFullDate(e.eventDate)} ${i18n.by} ${e.updateLogin || i18n.unknown}` : ''}</div>
                                            </div>
                                        </div>
                                    </div>
                                </ColorfulCard>
                            )
                        })}
                    </div>
                )
            })
            return (
                <div className='padding-1'>
                    <AccordionMUI defaultExpanded={i === 0}>
                        <AccordionSummaryMUI>
                            {`${key} (${groupEvents.length} ${i18n[`event${groupEvents.length >= 2 ? 's' : ''}`]})`}
                        </AccordionSummaryMUI>
                        <AccordionDetailsMUI nopadding>
                            {eventsCard}
                        </AccordionDetailsMUI>
                    </AccordionMUI>
                </div>
            )
        })
    }

    getEvents = () => {
        const { matEventsExploitation, installationAllEvents, exploitationEvents, exploitation } = this.props
        const { filter } = this.state
        switch (filter.type) {
            case 'exploitation':
                return exploitationEvents.map((e) => ({ ...e, objectId: `${e.associateType}_#_${e.associateId}`, color: this.getColor(e.eventType), globalType: 'exploitation' }))
            case 'pointsPrelevement':
                return installationAllEvents.filter((e) => exploitation.link_samplings.find((p) => p.idInstallation === e.stationId)).map((e) => ({ ...e, date: e.date || e.eventDate, objectId: e.stationId, globalType: 'installation' }))
            case 'materiels':
                return matEventsExploitation.map((e) => ({ ...e, eventType: e.idType, color: this.getColor(e.idType), objectId: e.idMateriel, globalType: 'materiel' }))
            default:
                return [
                    ...exploitationEvents.map((e) => ({ ...e, objectId: `${e.associateType}_#_${e.associateId}`, color: this.getColor(e.eventType), globalType: 'exploitation', icon: 'folder' })),
                    ...installationAllEvents.filter((e) => exploitation.link_samplings.find((p) => p.idInstallation === e.stationId)).map((e) => ({ ...e, date: e.date || e.eventDate, objectId: e.stationId, globalType: 'installation', icon: 'room' })),
                    ...matEventsExploitation.map((e) => ({ ...e, eventType: e.idType, color: this.getColor(e.idType), objectId: e.idMateriel, globalType: 'materiel', icon: 'router' })),
                ]
        }
    }

    getColor = (eventTypeId) => {
        switch (eventTypeId) {
            case 1: case 'c': case 'installation':
                return 'GREEN'
            case 2: case 'u':
                return 'ORANGE'
            case 3: case 'v': case 'declaration': case 'exploitation':
                return 'BLUE'
            case 4: case 'd': case 'materiel':
                return 'RED'
            default:
                return 'GREY'
        }
    }

    getEventsTypes = (forceType) => {
        const { matEventsTypes } = this.props
        const { filter } = this.state
        const type = forceType || filter.type
        switch (type) {
            case 'exploitation':
                return [{
                    code: 'c',
                    id: 1,
                    label: i18n.added,
                    color: this.getColor('c'),
                    icon: 'add_box',
                }, {
                    code: 'u',
                    id: 2,
                    label: i18n.modification,
                    color: this.getColor('u'),
                    icon: 'edit',
                }, {
                    code: 'd',
                    id: 3,
                    label: i18n.deleting,
                    color: this.getColor('d'),
                    icon: 'delete',
                }, {
                    code: 'v',
                    id: 4,
                    label: i18n.validation,
                    color: this.getColor('v'),
                    icon: 'delete',
                }]
            case 'materiels':
                return orderBy(matEventsTypes, 'id').map((t) => {
                    return {
                        code: String(t.id),
                        id: t.id,
                        label: t.name,
                        color: this.getColor(t.id),
                        icon: 'warning',
                    }
                })
            case 'pointsPrelevement': case 'installation':
                return null
            default:
                return [{
                    value: 'exploitation',
                    code: 'exploitation',
                    id: 1,
                    label: i18n.exploitation,
                    color: this.getColor('exploitation'),
                }, {
                    value: 'pointsPrelevement',
                    label: i18n.pointsPrelevement,
                    code: 'installation',
                    id: 1,
                    color: this.getColor('installation'),
                }, {
                    value: 'materiels',
                    label: i18n.materiels,
                    code: 'materiel',
                    id: 1,
                    color: this.getColor('materiel'),
                }]
        }
    }

    getEventTypeForChart = (e) => {
        const { filter } = this.state
        switch (filter.type) {
            case 'exploitation': case 'pointsPrelevement': case 'materiels':
                return e.eventType
            default:
                return e.globalType
        }
    }

    getEventsTypesForChart = () => {
        const { filter } = this.state
        switch (filter.type) {
            case 'exploitation':
                return this.getEventsTypes('exploitation')
            case 'pointsPrelevement':
                return this.getEventsTypes('pointsPrelevement')
            case 'materiels':
                return this.getEventsTypes().map((t) => ({ ...t, code: t.id }))
            default:
                return this.getEventsTypes()
        }
    }

    getPanel = () => {
    }

    render() {
        const { dataLoaded } = this.state

        if (dataLoaded) {
            const { tmpFilter, filter } = this.state
            const events = this.getEvents()
            const eventsForCharts = events.map((e) => ({ ...e, eventType: this.getEventTypeForChart(e) }))
            const yearEvents = eventsForCharts.filter(e => moment(e.date).year() === moment().year())
            const eventsTypes = this.getEventsTypes()
            const eventsTypesForChart = this.getEventsTypesForChart()
            return (
                <div className='no-margin padding-1'>
                    <Grid container spacing={1}>
                        <Grid item xs={9} container direction='column' justifyContent='flex-start' alignItems='stretch'>
                            <Card round>
                                <div className='row no-margin'>
                                    <EventsTypeVerticalBarChart
                                        events={eventsForCharts}
                                        eventsTypes={eventsTypesForChart}
                                        intType={filter.type === 'materiels'}
                                    />
                                </div>
                                <Card round>
                                    <div className='col s12 padding-top-1'>
                                        <Select
                                            col={3}
                                            label={i18n.eventType}
                                            options={[{
                                                value: 'exploitation',
                                                label: i18n.exploitation,
                                            }, {
                                                value: 'pointsPrelevement',
                                                label: i18n.pointsPrelevement,
                                            }, {
                                                value: 'materiels',
                                                label: i18n.materiels,
                                            }]}
                                            value={tmpFilter.type}
                                            onChange={v => this.setState({ tmpFilter: { type: v } })}
                                            nullLabel={i18n.all}
                                            noSort
                                        />
                                    </div>
                                    <Grid container className='padding-1' justifyContent='flex-end'>
                                        <Grid item>
                                            <Button variant='outlined' onClick={() => this.setState({ filter: initFilters, tmpFilter: initFilters })}>
                                                {i18n.reinit}
                                            </Button>
                                        </Grid>
                                        <Grid item className='padding-left-1'>
                                            <Button variant='contained' onClick={() => this.setState({ filter: tmpFilter })}>
                                                {i18n.search}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Card>
                            </Card>
                            <Grid item>
                                <TabList
                                    onChangeTab={(v) => this.setState({ sortBy: v })}
                                    tabs={[
                                        {
                                            value: 'date',
                                            label: i18n.perYear,
                                            icon: 'insert_invitation',
                                        },
                                        {
                                            value: 'referent',
                                            label: i18n.byReferent,
                                            icon: 'person',
                                        },
                                        {
                                            value: 'eventType',
                                            label: i18n.byEventType,
                                            icon: 'donut_small',
                                        },
                                    ]}
                                >
                                    {events.length ? this.getData(events) : <h5 className='margin-left-1'>{i18n.noDataToDisplay}</h5>}
                                </TabList>
                            </Grid>
                        </Grid>
                        <Grid item xs={3} container direction='column'>
                            <Card className='margin-top-1' round>
                                <div className='center-align'>
                                    <EventsTypeLegendPanel eventsTypes={eventsTypes}/>
                                    <h5 className='bold'>{i18n.historic}</h5>
                                    <EventPieChart
                                        events={eventsForCharts}
                                        eventsTypes={eventsTypesForChart}
                                        withoutLegend
                                    />
                                    <EventsStationTypeBarChart events={events} eventsTypes={eventsTypesForChart}/>
                                    <h5 className='bold'>{moment().year()}</h5>
                                    <EventPieChart
                                        events={yearEvents}
                                        eventsTypes={eventsTypesForChart}
                                        withoutLegend
                                    />
                                    <EventsStationTypeBarChart events={yearEvents} eventsTypes={eventsTypesForChart}/>
                                </div>
                            </Card>
                        </Grid>
                    </Grid>
                </div>
            )
        }

        return (
            <div className='no-margin padding-1'>
                <ProgressCard indeterminate withMessage round />
            </div>
        )
    }
}

ExploitationEventsApp.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string,
        }),
    }),
    fetchExploitation: PropTypes.func,
    fetchContributor: PropTypes.func,
    fetchMatEventsByExploitation: PropTypes.func,
    fetchExploitationEvents: PropTypes.func,
    setTitle: PropTypes.func,
    push: PropTypes.func,
    params: PropTypes.shape({
        id: PropTypes.string,
    }),
    exploitation: PropTypes.instanceOf(DtoExploitation),
    contributor: PropTypes.instanceOf(ContributorItem),
    exploitationsAllEvents: PropTypes.arrayOf(PropTypes.instanceOf(DtoEventExploitation)),
    installationAllEvents: PropTypes.arrayOf(PropTypes.instanceOf(DtoEventInstallation)),
    matEventsExploitation: PropTypes.arrayOf(PropTypes.instanceOf(DtoMatEvent)),
    matEventsTypes: PropTypes.arrayOf(PropTypes.instanceOf(DtoMatEventsType)),
    installationsWithGeo: PropTypes.arrayOf(PropTypes.instanceOf(DtoInstallationWithGeoItem)),
    citiesIndex: PropTypes.objectOf(PropTypes.instanceOf(CityDto)),
    variousMateriels: PropTypes.arrayOf(PropTypes.instanceOf(VariousMaterielDto)),
    variousMaterielTypes: PropTypes.arrayOf(PropTypes.instanceOf(DtoVariousMaterielType)),
    exploitationEvents: PropTypes.arrayOf(PropTypes.instanceOf(DtoExploitationEvent)),
    contacts: PropTypes.arrayOf(PropTypes.instanceOf(ContactDto)),
    sandreCodes: PropTypes.arrayOf(PropTypes.instanceOf(DtoSandreCode)),
}

const mapStateToProps = (store) => {
    return {
        exploitation: store.AgriReducer.exploitation,
        exploitationEvents: store.AgriReducer.exploitationEvents,
        contributor: store.ContributorReducer.contributor,
        exploitationsAllEvents: store.EventsReducer.exploitationsAllEvents,
        installationAllEvents: store.EventsReducer.installationAllEvents,
        matEventsExploitation: store.VariousMaterielReducer.matEventsExploitation,
        matEventsTypes: store.VariousMaterielReducer.matEventsTypes,
        contacts: store.ContactReducer.contacts,
        installationsWithGeo: store.InstallationReducer.installationsWithGeo,
        installations: store.InstallationReducer.installations,
        citiesIndex: store.CityReducer.citiesIndex,
        variousMateriels: store.VariousMaterielReducer.variousMateriels,
        variousMaterielTypes: store.VariousMaterielReducer.variousMaterielTypes,
        sandreCodes: store.ReferencialReducer.sandreCodes,
    }
}

const mapDispatchToProps = {
    fetchExploitation: AgriAction.fetchExploitation,
    fetchDeclarationsByExploitationId: AgriAction.fetchDeclarationsByExploitationId,
    getSynthesisDeclaration: AgriAction.getSynthesisDeclaration,
    fetchContributor: ContributorAction.fetchContributor,
    fetchMatEventsByExploitation: VariousMaterielAction.fetchMatEventsByExploitation,
    fetchExploitationEvents: AgriAction.fetchExploitationEvents,
    export: ExportAction.export,
    setTitle: HomeAction.setTitle,
    toastrInfo: ToastrAction.info,
    push,
}

export default connect(mapStateToProps, mapDispatchToProps)(ExploitationEventsApp)
