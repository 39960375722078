import { push } from 'connected-react-router'
import fetch from 'isomorphic-fetch'
import { every, groupBy, isNil, template, uniqBy } from 'lodash'
import i18n from 'simple-react-i18n'
import ToastrAction from 'toastr/actions/ToastrAction'
import { hasValue } from 'utils/NumberUtil'
import WaitAction from 'wait/WaitAction'
import ApplicationConf from '../../conf/ApplicationConf'
import HomeAction from '../../home/actions/HomeAction'
import LogAction from '../../log/actions/LogAction'
import AppStore from '../../store/AppStore'
import { checkAuth, checkError, genericFetch, genericPromise, getAuthorization, getJson } from '../../utils/ActionUtils'
import { RECEIVE_CMS_EVENTS, RECEIVE_CMS_MESSAGES } from '../constants/CMSEventConstants'
import {
    RECEIVE_ALL_CMS_MODEL,
    RECEIVE_CMS_CATEGORIES,
    RECEIVE_CMS_DOCUMENT,
    RECEIVE_CMS_EVENT, RECEIVE_CMS_MODEL, RECEIVE_CMS_SURVEY,
    RECEIVE_CMS_SURVEY_ID, RECEIVE_DOCUMENTS_STATS,
    RECEIVE_DOCUMENTS_STATS_BY_MODULE,
    RECEIVE_DOCUMENTS_STATS_BY_USER,
    RESET_CMS_EVENT,
    UPDATE_CMS_CATEGORIES,
    RECEIVE_CMS_MODEL_STATIONS,
    RECEIVE_CMS_MODEL_OBSTACLES,
    RECEIVE_CMS_MODEL_TIDE_GAUGES,
} from '../constants/EventsConstants'
import DtoCmsStation from 'events/dto/DtoCmsStation'
import DtoCmsObstacle from 'events/dto/DtoCmsObstacle'
import DtoCmsTideGauge from 'events/dto/DtoCmsTideGauge'
import DtoCmsDescriptions from 'events/dto/DtoCmsDescriptions'

const CmsAction = {

    receiveCMSEvents: cmsEvents => ({ type: RECEIVE_CMS_EVENTS, cmsEvents }),

    fetchCMSEvents: () => dispatch => {
        return CmsAction.promiseCMSEvents()
            .then((json = []) => {
                dispatch(CmsAction.receiveCMSEvents(json))
                const obj = {}
                const group = groupBy(json, 'idCategory')
                Object.keys(group).map(categoryId => {
                    obj[`content${categoryId}`] = group[categoryId].length
                })
                dispatch(HomeAction.addNotification(obj))
            })
            .catch(err => {
                const error = `${err}`
                dispatch(LogAction.logError(`${i18n.fetchError + i18n.communication} : ${err}`))
                if (error.includes('500')) {
                    dispatch(ToastrAction.warning(i18n.cmsAqasysError))
                } else {
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.communication))
                }
            })
    },

    updateCMSEvent: (event, isNewEvent, fetchWPCMS = true, wait = true) => dispatch => {
        if (wait) {
            dispatch(WaitAction.waitStart())
        }
        return fetch(isNewEvent ? ApplicationConf.referencial.cmsEvents() : ApplicationConf.referencial.cmsEvent(event.id), {
            method: isNewEvent ? 'POST' : 'PUT',
            headers: getAuthorization(),
            body: JSON.stringify(event),
        })
            .then(checkAuth)
            .then(getJson)
            .then(json => {
                if ((isNewEvent && json.insert >= 1) || (!isNewEvent && json.update >= 1)) {
                    const category = AppStore.getState().EventsReducer.cmsCategories.find(c => c.id == event.idCategory)
                    const categoryName = category ? category.title : ''
                    dispatch(ToastrAction.success(template(isNewEvent ? i18n.contentAddSuccess : i18n.contentUpdateSuccess)({ content: categoryName })))
                    if (fetchWPCMS) {
                        dispatch(CmsAction.fetchCMSEvents())
                    } else {
                        dispatch(CmsAction.fetchCMSWithoutWP())
                    }
                    if (wait) {
                        dispatch(WaitAction.waitStop())
                    }
                } else {
                    if (wait) {
                        dispatch(WaitAction.waitStop())
                    }
                    dispatch(LogAction.logError(i18n.updateError + i18n.communication))
                    dispatch(ToastrAction.error(i18n.updateError + i18n.communication))
                }
                return json.id
            })
            .catch((err) => {
                if (wait) {
                    dispatch(WaitAction.waitStop())
                }
                dispatch(LogAction.logError(i18n.updateError + i18n.communication + err))
                dispatch(ToastrAction.error(i18n.updateError + i18n.communication))
            })
    },

    deleteCMSEvent: (event, fetchWPCMS = true) => dispatch => fetch(ApplicationConf.referencial.cmsEvent(event.id), {
        method: 'DELETE',
        headers: getAuthorization(),
        body: JSON.stringify(event),
    }).then(checkAuth)
        .then(getJson)
        .then(json => {
            if (json.delete) {
                const category = AppStore.getState().EventsReducer.cmsCategories.find(c => c.id == event.idCategory)
                const categoryName = category ? category.title : ''
                dispatch(ToastrAction.success(template(i18n.contentDeleteSuccess)({ content: categoryName })))
                if (fetchWPCMS) {
                    dispatch(CmsAction.fetchCMSEvents())
                } else {
                    dispatch(CmsAction.fetchCMSWithoutWP())
                }
            } else {
                dispatch(LogAction.logError(i18n.deleteError + i18n.event))
                dispatch(ToastrAction.error(i18n.deleteError + i18n.event))
            }
        })
        .catch((err) => {
            dispatch(LogAction.logError(i18n.deleteError + i18n.event + err))
            dispatch(ToastrAction.error(i18n.deleteError + i18n.event))
        }),

    resetCmsEvent: () => ({ type: RESET_CMS_EVENT }),

    receiveCMSEvent: cmsEvent => ({ type: RECEIVE_CMS_EVENT, cmsEvent }),

    fetchCMSEvent: (id, callback = () => { }, wait = true, lang) => dispatch => {
        if (wait) {
            dispatch(WaitAction.waitStart())
        }
        const url = lang ? ApplicationConf.referencial.cmsEventLang(id, lang) : ApplicationConf.referencial.cmsEvent(id)
        return fetch(url, {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
            .then(json => {
                if (wait) {
                    dispatch(WaitAction.waitStop())
                }
                dispatch(CmsAction.receiveCMSEvent(json))
                callback()
                return json
            })
            .catch(err => {
                if (wait) {
                    dispatch(WaitAction.waitStop())
                }
                dispatch(LogAction.logError(`${i18n.fetchError + i18n.communication} : ${err}`))
                dispatch(ToastrAction.error(i18n.fetchError + i18n.communication))
            })
    },

    promiseCMSEvents: () => fetch(ApplicationConf.referencial.cmsEvents(), {
        method: 'GET',
        headers: getAuthorization(),
    })
        .then(checkAuth)
        .then(getJson)
        .then(checkError),

    promiseCMSCategories: () => fetch(ApplicationConf.referencial.cmsCategories(), {
        method: 'GET',
        headers: getAuthorization(),
    }).then(checkAuth)
        .then(getJson)
        .then(checkError),

    fetchCMSCategories: () => dispatch => CmsAction.promiseCMSCategories()
        .then(json => {
            dispatch(CmsAction.receiveCMSCategories(json))
        })
        .catch(err => {
            dispatch(LogAction.logError(`${i18n.fetchError + i18n.categories} : ${err}`))
            dispatch(ToastrAction.error(i18n.fetchError + i18n.categories))
        }),

    receiveCMSCategories: categories => ({ type: RECEIVE_CMS_CATEGORIES, categories }),

    updateCMSCategory: (categories, allCategories, callback = () => { }) => dispatch => {
        dispatch(WaitAction.waitStart())
        const promises = categories.map(category => fetch(ApplicationConf.referencial.cmsCategories(), {
            method: category.isNew ? 'POST' : 'PUT',
            headers: getAuthorization(),
            body: JSON.stringify(category),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError))
        return Promise.all(promises)
            .then(jsonTab => {
                if (every(jsonTab, result => result.insert === 1 || result.update === 1)) {
                    const newCategories = jsonTab.map((result, index) => {
                        if (result.insert === 1) {
                            return Object.assign({}, categories[index], { id: result.id })
                        }
                        return categories[index]
                    })
                    dispatch(ToastrAction.success(i18n.categoryUpdateSuccess))
                    dispatch({ type: UPDATE_CMS_CATEGORIES, categories: uniqBy(newCategories.concat(allCategories), 'id') })
                    dispatch(WaitAction.waitStop())
                    callback()
                } else {
                    throw new Error()
                }
            })
            .catch((err) => {
                dispatch(WaitAction.waitStop())
                dispatch(LogAction.logError(i18n.updateError + i18n.categories + err))
                dispatch(ToastrAction.error(i18n.updateError + i18n.categories))
            })
    },

    deleteCMSCategory: category => dispatch => fetch(ApplicationConf.referencial.cmsCategories(), {
        method: 'DELETE',
        headers: getAuthorization(),
        body: JSON.stringify(category),
    })
        .then(checkAuth)
        .then(getJson)
        .then(json => {
            if (json.update === 1) {
                dispatch(ToastrAction.success(i18n.categoryDeleteSuccess))
                dispatch(CmsAction.fetchCMSCategories())
            } else {
                dispatch(LogAction.logError(i18n.deleteError + i18n.categories))
                dispatch(ToastrAction.error(i18n.deleteError + i18n.categories))
            }
        })
        .catch((err) => {
            dispatch(LogAction.logError(i18n.deleteError + i18n.categories + err))
            dispatch(ToastrAction.error(i18n.deleteError + i18n.categories))
        }),

    postFacebookCMSEvent: event => dispatch => fetch(ApplicationConf.referencial.cmsEventFacebook(event.id), {
        method: 'POST',
        headers: getAuthorization(),
        body: JSON.stringify(event),
    })
        .then(checkAuth)
        .then(getJson)
        .then(json => {
            if (!json.error) {
                dispatch(ToastrAction.success(i18n.contentSuccessfulyShared))
            } else {
                dispatch(ToastrAction.error(i18n.contentCannotBeShared))
            }
        })
        .catch(err => {
            dispatch(LogAction.logError(i18n.contentCannotBeShared + i18n.event + err))
            dispatch(ToastrAction.error(i18n.contentCannotBeShared))
        }),

    postMailCMSEvent: (id, mails) => dispatch => fetch(ApplicationConf.referencial.cmsEventMail(id), {
        method: 'POST',
        headers: getAuthorization(),
        body: JSON.stringify(mails),
    })
        .then(checkAuth)
        .then(getJson)
        .then(json => {
            if (!json.error) {
                dispatch(ToastrAction.success(i18n.contentSuccessfulyShared))
            } else {
                throw new Error()
            }
        })
        .catch(err => {
            dispatch(LogAction.logError(i18n.contentCannotBeShared + i18n.event + err))
            dispatch(ToastrAction.error(i18n.contentCannotBeShared))
        }),

    receiveCMSDocument: document => ({ type: RECEIVE_CMS_DOCUMENT, document }),

    fetchCMSDocument: name => dispatch => fetch(ApplicationConf.referencial.cmsFile(name), {
        method: 'GET',
        headers: getAuthorization(),
    })
        .then(checkAuth)
        .then(getJson)
        .then(checkError)
        .then((json) => {
            dispatch(CmsAction.receiveCMSDocument(json))
        }).catch((err) => {
            dispatch(LogAction.logError(`${i18n.fetchError} : ${err}`))
            dispatch(ToastrAction.error(i18n.fetchError))
        }),

    promiseCMSWithoutWP: () => fetch(ApplicationConf.referencial.cmsWithoutWP(), {
        method: 'GET',
        headers: getAuthorization(),
    })
        .then(checkAuth)
        .then(getJson)
        .then(checkError),

    fetchCMSWithoutWP: () => dispatch => CmsAction.promiseCMSWithoutWP().then(json => {
        dispatch(CmsAction.receiveCMSEvents(json))
        const obj = {}
        const group = groupBy(json, 'idCategory')
        Object.keys(group).map(categoryId => {
            obj[`content${categoryId}`] = group[categoryId].length
        })
        dispatch(HomeAction.addNotification(obj))
    }).catch(err => {
        dispatch(LogAction.logError(`${i18n.fetchError + i18n.communication} : ${err}`))
        dispatch(ToastrAction.error(i18n.fetchError + i18n.communication))
    }),

    playCmsModel: modelId => dispatch => {
        dispatch(WaitAction.waitStart())
        return fetch(ApplicationConf.referencial.playCmsModel(modelId), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
            .then((json) => {
                dispatch(WaitAction.waitStop())
                if (hasValue(json.modelId) && hasValue(json.categoryId)) {
                    dispatch(push(`/contents/${json.categoryId}/${json.modelId}`,))
                }
            }).catch((err) => {
                dispatch(LogAction.logError(`${i18n.fetchError} : ${err}`))
                dispatch(ToastrAction.error(i18n.fetchError))
            })
    },

    regenerateCms: body => dispatch => {
        return fetch(ApplicationConf.referencial.regenerate(), {
            method: 'POST',
            headers: getAuthorization(),
            body: JSON.stringify(body),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
            .then(() => {
                dispatch(ToastrAction.success(i18n.elementUpdateSuccess))
            }).catch((err) => {
                dispatch(LogAction.logError(`${i18n.updateError} : ${err}`))
                dispatch(ToastrAction.error(i18n.updateError))
            })
    },

    updateCmsModel(model) {
        return dispatch => {
            return fetch(ApplicationConf.referencial.updateCmsModel(), {
                method: !isNil(model.modelId) ? 'PUT' : 'POST',
                headers: getAuthorization(),
                body: JSON.stringify(model),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(() => {
                    dispatch(ToastrAction.success(i18n.elementUpdateSuccess))
                    dispatch(CmsAction.fetchAllCMSModels())
                })
                .catch((err) => {
                    dispatch(LogAction.logError(`${i18n.updateError + i18n.model} : ${err}`))
                    dispatch(ToastrAction.error(i18n.updateError + i18n.model))
                })
        }
    },

    sendCmsByModel(cmsId) {
        return dispatch => {
            dispatch(WaitAction.waitStart())
            return fetch(ApplicationConf.referencial.sendCmsByModel(cmsId), {
                method: 'POST',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(() => {
                    dispatch(WaitAction.waitStop())
                    dispatch(ToastrAction.success(i18n.mailSent))
                })
                .catch((err) => {
                    dispatch(LogAction.logError(`${i18n.updateError + i18n.model} : ${err}`))
                    dispatch(ToastrAction.error(i18n.updateError + i18n.model))
                })
        }
    },

    deleteCMSModel: modelId => dispatch => fetch(ApplicationConf.referencial.deleteCmsModel(modelId), {
        method: 'DELETE',
        headers: getAuthorization(),
    })
        .then(checkAuth)
        .then(getJson)
        .then(json => {
            if (json.delete === 1) {
                dispatch(ToastrAction.success(i18n.elementDeleteSuccess))
                dispatch(CmsAction.fetchAllCMSModels())
            } else {
                dispatch(LogAction.logError(i18n.deleteError + i18n.model))
                dispatch(ToastrAction.error(i18n.deleteError + i18n.model))
            }
        })
        .catch((err) => {
            dispatch(LogAction.logError(i18n.deleteError + i18n.categories + err))
            dispatch(ToastrAction.error(i18n.deleteError + i18n.categories))
        }),

    createCmsModelStations: (cmsStationsModels) => dispatch => {
        dispatch(WaitAction.waitStart())
        return fetch(ApplicationConf.referencial.updateCmsModelStations(), {
            method: 'POST',
            headers: getAuthorization(),
            body: JSON.stringify({ cmsStationsModels }),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
            .then(json => {
                if (json.insert > 0) {
                    dispatch(ToastrAction.success(i18n.elementUpdateSuccess))
                    dispatch(WaitAction.waitStop())
                } else {
                    throw new Error()
                }
            })
            .catch((err) => {
                dispatch(WaitAction.waitStop())
                dispatch(LogAction.logError(i18n.createError + i18n.modelStations + err))
                dispatch(ToastrAction.error(i18n.createError + i18n.modelStations))
            })
    },

    updateCmsModelStations: (cmsStationsModels) => dispatch => {
        dispatch(WaitAction.waitStart())
        return fetch(ApplicationConf.referencial.updateCmsModelStations(), {
            method: 'PUT',
            headers: getAuthorization(),
            body: JSON.stringify({ cmsStationsModels }),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
            .then(json => {
                if (json.update > 0) {
                    dispatch(ToastrAction.success(i18n.elementUpdateSuccess))
                    dispatch(WaitAction.waitStop())
                } else {
                    throw new Error()
                }
            })
            .catch((err) => {
                dispatch(WaitAction.waitStop())
                dispatch(LogAction.logError(i18n.updateError + i18n.modelStations + err))
                dispatch(ToastrAction.error(i18n.updateError + i18n.modelStations))
            })
    },

    createCmsModelObstacles: (cmsObstaclesModels) => dispatch => {
        dispatch(WaitAction.waitStart())
        return fetch(ApplicationConf.referencial.updateCmsModelObstacles(), {
            method: 'POST',
            headers: getAuthorization(),
            body: JSON.stringify({ cmsObstaclesModels }),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
            .then(json => {
                if (json.insert > 0) {
                    dispatch(ToastrAction.success(i18n.elementUpdateSuccess))
                    dispatch(WaitAction.waitStop())
                } else {
                    throw new Error()
                }
            })
            .catch((err) => {
                dispatch(WaitAction.waitStop())
                dispatch(LogAction.logError(i18n.createError + i18n.modelObstacles + err))
                dispatch(ToastrAction.error(i18n.createError + i18n.modelObstacles))
            })
    },

    updateCmsModelObstacles: (cmsObstaclesModels) => dispatch => {
        dispatch(WaitAction.waitStart())
        return fetch(ApplicationConf.referencial.updateCmsModelObstacles(), {
            method: 'PUT',
            headers: getAuthorization(),
            body: JSON.stringify({ cmsObstaclesModels }),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
            .then(json => {
                if (json.update > 0) {
                    dispatch(ToastrAction.success(i18n.elementUpdateSuccess))
                    dispatch(WaitAction.waitStop())
                } else {
                    throw new Error()
                }
            })
            .catch((err) => {
                dispatch(WaitAction.waitStop())
                dispatch(LogAction.logError(i18n.updateError + i18n.modelObstacles + err))
                dispatch(ToastrAction.error(i18n.updateError + i18n.modelObstacles))
            })
    },

    createCmsModelTideGauges: (cmsTideGaugesModels) => dispatch => {
        dispatch(WaitAction.waitStart())
        return fetch(ApplicationConf.referencial.updateCmsModelTideGauges(), {
            method: 'POST',
            headers: getAuthorization(),
            body: JSON.stringify({ cmsTideGaugesModels }),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
            .then(json => {
                if (json.insert > 0) {
                    dispatch(ToastrAction.success(i18n.elementUpdateSuccess))
                    dispatch(WaitAction.waitStop())
                } else {
                    throw new Error()
                }
            })
            .catch((err) => {
                dispatch(WaitAction.waitStop())
                dispatch(LogAction.logError(i18n.createError + i18n.modelTideGauges + err))
                dispatch(ToastrAction.error(i18n.createError + i18n.modelTideGauges))
            })
    },

    updateCmsModelTideGauges: (cmsTideGaugesModels) => dispatch => {
        dispatch(WaitAction.waitStart())
        return fetch(ApplicationConf.referencial.updateCmsModelTideGauges(), {
            method: 'PUT',
            headers: getAuthorization(),
            body: JSON.stringify({ cmsTideGaugesModels }),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
            .then(json => {
                if (json.update > 0) {
                    dispatch(ToastrAction.success(i18n.elementUpdateSuccess))
                    dispatch(WaitAction.waitStop())
                } else {
                    throw new Error()
                }
            })
            .catch((err) => {
                dispatch(WaitAction.waitStop())
                dispatch(LogAction.logError(i18n.updateError + i18n.modelTideGauges + err))
                dispatch(ToastrAction.error(i18n.updateError + i18n.modelTideGauges))
            })
    },

    promiseCmsStations: (cmsStations) => fetch(ApplicationConf.referencial.updateCmsStations(cmsStations[0]?.cmsId), {
        method: 'PUT',
        headers: getAuthorization(),
        body: JSON.stringify({ cmsStations }),
    })
        .then(checkAuth)
        .then(getJson)
        .then(checkError),

    updateCmsStations: (cmsStations) => dispatch => {
        dispatch(WaitAction.waitStart())
        return CmsAction.promiseCmsStations(cmsStations)
            .then(json => {
                if (json.update > 0) {
                    dispatch(ToastrAction.success(i18n.elementUpdateSuccess))
                    dispatch(WaitAction.waitStop())
                } else {
                    throw new Error()
                }
            })
            .catch((err) => {
                dispatch(WaitAction.waitStop())
                dispatch(LogAction.logError(i18n.updateError + i18n.stations + err))
                dispatch(ToastrAction.error(i18n.updateError + i18n.stations))
            })
    },

    promiseCmsObstacles: (cmsObstacles) => fetch(ApplicationConf.referencial.updateCmsObstacles(cmsObstacles[0]?.cmsId), {
        method: 'PUT',
        headers: getAuthorization(),
        body: JSON.stringify({ cmsObstacles }),
    })
        .then(checkAuth)
        .then(getJson)
        .then(checkError),

    updateCmsObstacles: (cmsObstacles) => dispatch => {
        dispatch(WaitAction.waitStart())
        return CmsAction.promiseCmsObstacles(cmsObstacles)
            .then(json => {
                if (json.update > 0) {
                    dispatch(ToastrAction.success(i18n.elementUpdateSuccess))
                    dispatch(WaitAction.waitStop())
                } else {
                    throw new Error()
                }
            })
            .catch((err) => {
                dispatch(WaitAction.waitStop())
                dispatch(LogAction.logError(i18n.updateError + i18n.obstacles + err))
                dispatch(ToastrAction.error(i18n.updateError + i18n.obstacles))
            })
    },

    promiseCmsTideGauges: (cmsTideGauges) => fetch(ApplicationConf.referencial.updateCmsTideGauges(cmsTideGauges[0]?.cmsId), {
        method: 'PUT',
        headers: getAuthorization(),
        body: JSON.stringify({ cmsTideGauges }),
    })
        .then(checkAuth)
        .then(getJson)
        .then(checkError),

    updateCmsTideGauges: (cmsTideGauges) => dispatch => {
        dispatch(WaitAction.waitStart())
        return CmsAction.promiseCmsTideGauges(cmsTideGauges)
            .then(json => {
                if (json.update > 0) {
                    dispatch(ToastrAction.success(i18n.elementUpdateSuccess))
                    dispatch(WaitAction.waitStop())
                } else {
                    throw new Error()
                }
            })
            .catch((err) => {
                dispatch(WaitAction.waitStop())
                dispatch(LogAction.logError(i18n.updateError + i18n.tideGauges + err))
                dispatch(ToastrAction.error(i18n.updateError + i18n.tideGauges))
            })
    },

    promiseCmsDescriptions: (cmsDescriptions) => fetch(ApplicationConf.referencial.updateCmsDescriptions(), {
        method: 'POST',
        headers: getAuthorization(),
        body: JSON.stringify({ cmsDescriptions }),
    })
        .then(checkAuth)
        .then(getJson)
        .then(checkError),

    updateCmsDescriptions: (cmsDescriptions, wait = true) => dispatch => {
        if (wait) {
            dispatch(WaitAction.waitStart())
        }
        return CmsAction.promiseCmsDescriptions(cmsDescriptions)
            .then(json => {
                if (json > 0) {
                    dispatch(ToastrAction.success(i18n.elementUpdateSuccess))
                }
                if (wait) {
                    dispatch(WaitAction.waitStop())
                }
            })
            .catch((err) => {
                if (wait) {
                    dispatch(WaitAction.waitStop())
                }
                dispatch(LogAction.logError(i18n.updateError + i18n.cmsDescriptions + err))
                dispatch(ToastrAction.error(i18n.updateError + i18n.cmsDescriptions))
            })
    },

    promiseDeleteCmsDescriptions: (cmsDescriptions) => fetch(ApplicationConf.referencial.updateCmsDescriptions(), {
        method: 'DELETE',
        headers: getAuthorization(),
        body: JSON.stringify({ cmsDescriptions }),
    })
        .then(checkAuth)
        .then(getJson)
        .then(checkError),

    deleteCmsDescriptions: (cmsDescriptions, wait = true) => dispatch => {
        if (wait) {
            dispatch(WaitAction.waitStart())
        }
        return CmsAction.promiseDeleteCmsDescriptions(cmsDescriptions)
            .then(json => {
                if (json > 0) {
                    dispatch(ToastrAction.success(i18n.elementDeleteSuccess))
                }
                if (wait) {
                    dispatch(WaitAction.waitStop())
                }
            })
            .catch((err) => {
                if (wait) {
                    dispatch(WaitAction.waitStop())
                }
                dispatch(LogAction.logError(i18n.updateError + i18n.cmsDescriptions + err))
                dispatch(ToastrAction.error(i18n.updateError + i18n.cmsDescriptions))
            })
    },

    promiseFetchCmsDescriptions: (cmsId) => genericPromise(ApplicationConf.referencial.getCmsDescriptions(cmsId)),

    fetchCmsDescriptions: (cmsId) => dispatch => CmsAction.promiseFetchCmsDescriptions(cmsId)
        .then((json = []) => json.map(s => new DtoCmsDescriptions(s)))
        .catch((err) => {
            dispatch(LogAction.logError(i18n.fetchError + i18n.cmsDescriptions + err))
            dispatch(ToastrAction.error(i18n.fetchError + i18n.cmsDescriptions))
        }),

    promiseFetchCmsStations: (cmsId) => genericPromise(ApplicationConf.referencial.getCmsStations(cmsId)),

    fetchCmsStations: (cmsId) => dispatch => CmsAction.promiseFetchCmsStations(cmsId)
        .then((json = []) => json.map(s => new DtoCmsStation(s)))
        .catch((err) => {
            dispatch(LogAction.logError(i18n.fetchError + i18n.modelStations + err))
            dispatch(ToastrAction.error(i18n.fetchError + i18n.modelStations))
        }),

    promiseFetchCmsObstacles: (cmsId) => genericPromise(ApplicationConf.referencial.getCmsObstacles(cmsId)),

    fetchCmsObstacles: (cmsId) => dispatch => CmsAction.promiseFetchCmsObstacles(cmsId)
        .then((json = []) => json.map(s => new DtoCmsObstacle(s)))
        .catch((err) => {
            dispatch(LogAction.logError(i18n.fetchError + i18n.modelObstacles + err))
            dispatch(ToastrAction.error(i18n.fetchError + i18n.modelObstacles))
        }),

    promiseFetchCmsTideGauges: (cmsId) => genericPromise(ApplicationConf.referencial.getCmsTideGauges(cmsId)),

    fetchCmsTideGauges: (cmsId) => dispatch => CmsAction.promiseFetchCmsTideGauges(cmsId)
        .then((json = []) => json.map(s => new DtoCmsTideGauge(s)))
        .catch((err) => {
            dispatch(LogAction.logError(i18n.fetchError + i18n.modelTideGauges + err))
            dispatch(ToastrAction.error(i18n.fetchError + i18n.modelTideGauges))
        }),

    promiseDocumentsStatistics() {
        return genericPromise(ApplicationConf.referencial.cmsDocumentsStatistics())
    },

    fetchDocumentsStatistics() {
        return genericFetch(CmsAction.promiseDocumentsStatistics(), RECEIVE_DOCUMENTS_STATS)
    },

    promiseDocumentsStatisticsByModule(module) {
        return genericPromise(ApplicationConf.referencial.cmsDocumentsModuleStatistics(module))
    },

    fetchModuleDocumentsStatistics(module) {
        return genericFetch(CmsAction.promiseDocumentsStatisticsByModule(module), RECEIVE_DOCUMENTS_STATS_BY_MODULE)
    },

    promiseDocumentsStatisticsByUser(login) {
        return genericPromise(ApplicationConf.referencial.cmsDocumentsUserStatistics(login))
    },

    fetchUserDocumentsStatistics(login) {
        return genericFetch(CmsAction.promiseDocumentsStatisticsByUser(login), RECEIVE_DOCUMENTS_STATS_BY_USER)
    },

    promiseFetchCMSSurvey: (idSurvey) => genericPromise(ApplicationConf.referencial.getCMSSurvey(idSurvey)),

    fetchCMSSurvey: (idSurvey) => genericFetch(CmsAction.promiseFetchCMSSurvey(idSurvey), RECEIVE_CMS_SURVEY),

    promiseFetchCMSSurveyRestricted: (idSurvey, idInstallation) => genericPromise(ApplicationConf.referencial.getCMSSurveyRestricted(idSurvey, idInstallation)),

    fetchCMSSurveyRestricted: (idSurvey, idInstallation) => genericFetch(CmsAction.promiseFetchCMSSurveyRestricted(idSurvey, idInstallation), RECEIVE_CMS_SURVEY),

    promiseFetchCMSSurveyId: (visitDate) => genericPromise(ApplicationConf.referencial.getCMSSurveyId(visitDate)),

    fetchCMSSurveyId: (visitDate) => genericFetch(CmsAction.promiseFetchCMSSurveyId(visitDate), RECEIVE_CMS_SURVEY_ID),

    resetCMSModel: () => ({ type: RECEIVE_CMS_MODEL, data: {} }),

    promiseFetchCMSModel: (modelId) => genericPromise(ApplicationConf.referencial.getCmsModel(modelId)),

    fetchCMSModel: (modelId) => genericFetch(CmsAction.promiseFetchCMSModel(modelId), RECEIVE_CMS_MODEL),

    promiseFetchAllCMSModels: () => genericPromise(ApplicationConf.referencial.getCmsModels()),

    fetchAllCMSModels: () => genericFetch(CmsAction.promiseFetchAllCMSModels(), RECEIVE_ALL_CMS_MODEL),

    resetCMSModelStations: () => ({ type: RECEIVE_CMS_MODEL_STATIONS, data: [] }),

    promiseFetchCMSModelStations: (modelId) => genericPromise(ApplicationConf.referencial.getCmsModelStations(modelId)),

    fetchCMSModelStations: (modelId) => genericFetch(CmsAction.promiseFetchCMSModelStations(modelId), RECEIVE_CMS_MODEL_STATIONS),

    resetCMSModelObstacles: () => ({ type: RECEIVE_CMS_MODEL_OBSTACLES, data: [] }),

    promiseFetchCMSModelObstacles: (modelId) => genericPromise(ApplicationConf.referencial.getCmsModelObstacles(modelId)),

    fetchCMSModelObstacles: (modelId) => genericFetch(CmsAction.promiseFetchCMSModelObstacles(modelId), RECEIVE_CMS_MODEL_OBSTACLES),

    resetCMSModelTideGauges: () => ({ type: RECEIVE_CMS_MODEL_TIDE_GAUGES, data: [] }),

    promiseFetchCMSModelTideGauges: (modelId) => genericPromise(ApplicationConf.referencial.getCmsModelTideGauges(modelId)),

    fetchCMSModelTideGauges: (modelId) => genericFetch(CmsAction.promiseFetchCMSModelTideGauges(modelId), RECEIVE_CMS_MODEL_TIDE_GAUGES),

    archiveMessage: (cmsId) => dispatch => {
        return fetch(ApplicationConf.referencial.archiveCmsMessage(cmsId), {
            method: 'PUT',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
            .catch((err) => {
                dispatch(WaitAction.waitStop())
                dispatch(LogAction.logError(i18n.updateError + i18n.messages + err))
                dispatch(ToastrAction.error(i18n.updateError + i18n.messages))
            })
    },

    promiseAddCmsMessages: (cmsMessage) => fetch(ApplicationConf.referencial.addCmsMessage(), {
        method: 'PUT',
        headers: getAuthorization(),
        body: JSON.stringify(cmsMessage),
    })
        .then(checkAuth)
        .then(getJson)
        .then(checkError),

    addCmsMessages: (cmsMessages) => dispatch => {
        const promises = cmsMessages.map(cmsMessage => CmsAction.promiseAddCmsMessages(cmsMessage))
        return Promise.all(promises)
            .catch((err) => {
                dispatch(WaitAction.waitStop())
                dispatch(LogAction.logError(i18n.updateError + i18n.messages + err))
                dispatch(ToastrAction.error(i18n.updateError + i18n.messages))
            })
    },

    promiseFetchCmsMessages: (cmsId) => genericPromise(ApplicationConf.referencial.getCmsMessage(cmsId)),

    fetchCmsMessages: (cmsId) => genericFetch(CmsAction.promiseFetchCmsMessages(cmsId), RECEIVE_CMS_MESSAGES),
}


export default CmsAction
