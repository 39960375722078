import Checkbox from 'components/forms/Checkbox'
import { push } from 'connected-react-router'
import { template } from 'lodash'
import moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import StationPiezoContributorPanel from 'station/components/link/StationPiezoContributorPanel'
import ToastrAction from 'toastr/actions/ToastrAction'
import UserDto from '../../administration/components/user/dto/UserDto'
import ActionComponent from '../../components/ActionComponent'
import Card from '../../components/card/Card'
import Input from '../../components/forms/Input'
import NumberField from '../../components/forms/NumberField'
import Select from '../../components/forms/Select'
import Row from '../../components/react/Row'
import UpdatePanel from '../../components/updatePanel/UpdatePanel'
import QualityAction from '../../quality/actions/QualityAction'
import DtoStatus from '../../quality/dto/DtoStatus'
import CityDto from '../../referencial/components/city/dto/CityDto'
import ContributorDto from '../../referencial/components/contributor/dto/ContributorDto'
import DtoManagementUnit from '../../referencial/components/managementUnit/dto/DtoManagementUnit'
import { SANDRE } from '../../referencial/constants/ReferencialConstants'
import DtoSandreCode from '../../referencial/dto/DtoSandreCode'
import LinkedStationsPanel from '../../station/components/associatedStation/LinkedStationsPanel'
import StationAssociatedTownsPanel from '../../station/components/link/StationAssociatedTownsPanel'
import DtoWaterMass from '../../station/dto/DtoWaterMass'
import { getStationArrowNav } from '../../utils/ActionUtils'
import { getDate } from '../../utils/DateUtil'
import { onChangeDate } from '../../utils/FormUtils'
import { getLogin } from '../../utils/SettingUtils'
import { getLinks } from '../../utils/StationUtils'
import { arrayOf, getSandreList, instanceOf } from '../../utils/StoreUtils'
import DistributionUnitAction from '../actions/DistributionUnitAction'
import DtoDistributionUnit from '../dto/DtoDistributionUnit'
import DistributionUnitNetworksPanel from './DistributionUnitNetworksPanel'

class DistributionUnitDescriptionPanel extends ActionComponent {
    state = {
        readMode: false,
        distributionUnit: {},
    }

    componentDidMount() {
        this.setReadMode()
        if (this.props.distributionUnit.id) {
            this.setState({ distributionUnit: this.props.distributionUnit })
        } else {
            this.props.fetchDistributionUnit(this.props.id, () => this.setState({ distributionUnit: this.props.distributionUnit }))
        }

        if (!this.props.status.length) {
            this.props.fetchStatus()
        }
    }

    setReadMode = () => {
        this.setState({ readMode: true })
        this.setActions({
            edit: this.setEditMode,
            delete: () => this.onDelete(),
            links: getLinks(this.props.distributionUnit, this.props),
            arrowNav: getStationArrowNav('distributionUnit', this.props.distributionUnits, this.props.distributionUnit.id, s => this.props.push(`/station/distributionUnit/${s.id}/description`)),
        })
        this.setState({ distributionUnit: this.props.distributionUnit })
    }

    setEditMode = () => {
        this.setState({ readMode: false })
        this.setActions({
            cancel: this.setReadMode,
            save: () => this.onSave(),
            delete: () => this.onDelete(),
            links: getLinks(this.props.distributionUnit, this.props),
            arrowNav: getStationArrowNav('distributionUnit', this.props.distributionUnits, this.props.distributionUnit.id, s => this.props.push(`/station/distributionUnit/${s.id}/description`)),
        })
    }

    onDelete = () => this.props.deleteDistributionUnit(this.props.distributionUnit.id, () => this.props.push('/distributionUnit'))

    onSave = () => {
        if (!this.state.distributionUnit.code) {
            ToastrAction.error(template(i18n.fieldNotSet)({ field: i18n.code }))
        } else {
            this.props.updateDistributionUnit(this.state.distributionUnit).then(() => {
                this.props.fetchDistributionUnitSectors()
            })
            this.setReadMode()
        }
    }

    onChangeDistributionUnit = value => this.setState({ distributionUnit: { ...this.state.distributionUnit, ...value } })

    render() {
        const { distributionUnit, readMode } = this.state
        const mode = { readMode, editMode: !readMode }
        if (distributionUnit.id) {
            return (
                <Row style={{ padding: '0 0 100 10' }}>
                    <div className='col s9 margin-top-1'>
                        <Card>
                            <div className='row no-margin' style={{ padding: '5 0' }}>
                                <Row>
                                    <Input
                                        col={6}
                                        value={distributionUnit.code}
                                        title={i18n.code}
                                        onChange={v => this.onChangeDistributionUnit({ code: v })}
                                        {...mode}
                                    />
                                    <Input
                                        col={6}
                                        value={distributionUnit.name}
                                        title={i18n.name}
                                        onChange={v => this.onChangeDistributionUnit({ name: v })}
                                        {...mode}
                                    />
                                </Row>
                                <Row>
                                    <Input
                                        col={6}
                                        value={distributionUnit.mnemonic}
                                        title={i18n.mnemonic}
                                        onChange={v => this.onChangeDistributionUnit({ mnemonic: v })}
                                        {...mode}
                                    />
                                </Row>
                                <Row>
                                    <Select
                                        col={ 6 }
                                        options={ getSandreList(this.props.sandreCodes, SANDRE.NATURE_EAU) }
                                        label={ i18n.waterNature }
                                        value={ distributionUnit.waterNature }
                                        onChange={ v => this.onChangeDistributionUnit({ waterNature: v }) }
                                        {...mode}
                                    />
                                </Row>
                                <Row>
                                    <Input
                                        col={6}
                                        value={getDate(distributionUnit.creationDate)}
                                        title={i18n.creationDate}
                                        onChange={v => onChangeDate(v, v2 => this.onChangeDistributionUnit({ creationDate: v2 }))}
                                        {...mode}
                                    />
                                </Row>
                                <Row>
                                    <Select
                                        col={ 6 }
                                        value={ distributionUnit.sector }
                                        label={ i18n.sector }
                                        options={ this.props.sectors }
                                        onChange={ v => this.onChangeDistributionUnit({ sector: v }) }
                                        {...mode}
                                    />
                                </Row>
                                <Row>
                                    <Select
                                        col={ 6 }
                                        options={ getSandreList(this.props.sandreCodes, SANDRE.MODE_EXPLOITATION) }
                                        label={ i18n.exploitationModeCode }
                                        value={ distributionUnit.exploitationModeCode }
                                        onChange={ v => this.onChangeDistributionUnit({ exploitationModeCode: v }) }
                                        {...mode}
                                    />
                                </Row>
                                <Row>
                                    <NumberField
                                        col={6}
                                        title={i18n.decreePopulation}
                                        value={distributionUnit.decreePopulation}
                                        onChange={v => this.onChangeDistributionUnit({ decreePopulation: v })}
                                        {...mode}
                                    />
                                    <NumberField
                                        col={6}
                                        title={i18n.permanentPopulation}
                                        value={distributionUnit.permanentPopulation}
                                        onChange={v => this.onChangeDistributionUnit({ permanentPopulation: v })}
                                        {...mode}
                                    />
                                </Row>
                                <Row>
                                    <NumberField
                                        col={6}
                                        title={i18n.summerPopulation}
                                        value={distributionUnit.summerPopulation}
                                        onChange={v => this.onChangeDistributionUnit({ summerPopulation: v })}
                                        {...mode}
                                    />
                                    <NumberField
                                        col={6}
                                        title={i18n.winterPopulation}
                                        value={distributionUnit.winterPopulation}
                                        onChange={v => this.onChangeDistributionUnit({ winterPopulation: v })}
                                        {...mode}
                                    />
                                </Row>
                                <Row>
                                    <NumberField
                                        col={6}
                                        title={i18n.subscriberNumber}
                                        value={distributionUnit.subscriberNumber}
                                        onChange={v => this.onChangeDistributionUnit({ subscriberNumber: v })}
                                        {...mode}
                                    />
                                    <NumberField
                                        col={6}
                                        title={i18n.distributedQuantity}
                                        value={distributionUnit.distributedQuantity}
                                        onChange={v => this.onChangeDistributionUnit({ distributedQuantity: v })}
                                        {...mode}
                                    />
                                </Row>
                                <Row>
                                    <NumberField
                                        col={6}
                                        title={i18n.lengthWaterPipe}
                                        value={distributionUnit.lengthWaterPipe}
                                        onChange={v => this.onChangeDistributionUnit({ lengthWaterPipe: v })}
                                        floatValue
                                        {...mode}
                                    />
                                    <NumberField
                                        col={6}
                                        title={i18n.linearOutConnection}
                                        value={distributionUnit.linearOutConnection}
                                        onChange={v => this.onChangeDistributionUnit({ linearOutConnection: v })}
                                        floatValue
                                        {...mode}
                                    />
                                </Row>
                                <Row>
                                    <NumberField
                                        col={6}
                                        title={i18n.aepNetworkRenewalRate}
                                        value={distributionUnit.aepNetworkRenewalRate}
                                        onChange={v => this.onChangeDistributionUnit({ aepNetworkRenewalRate: v })}
                                        floatValue
                                        {...mode}
                                    />
                                    <NumberField
                                        col={6}
                                        title={i18n.nbConnections}
                                        value={distributionUnit.nbConnections}
                                        onChange={v => this.onChangeDistributionUnit({ nbConnections: v })}
                                        {...mode}
                                    />
                                </Row>
                                <Row>
                                    <NumberField
                                        col={6}
                                        title={i18n.lengthConnections}
                                        value={distributionUnit.lengthConnections}
                                        onChange={v => this.onChangeDistributionUnit({ lengthConnections: v })}
                                        floatValue
                                        {...mode}
                                    />
                                    <NumberField
                                        col={6}
                                        title={i18n.averageAgeConnection}
                                        value={distributionUnit.averageAgeConnection}
                                        onChange={v => this.onChangeDistributionUnit({ averageAgeConnection: v })}
                                        floatValue
                                        {...mode}
                                    />
                                </Row>
                                <Row>
                                    <Checkbox
                                        col={6}
                                        checked={distributionUnit.mixteSyndicate}
                                        label={i18n.mixteSyndicate}
                                        onChange={v => this.onChangeDistributionUnit({ mixteSyndicate: v })}
                                        {...mode}
                                    />
                                    <Checkbox
                                        col={6}
                                        checked={distributionUnit.member}
                                        label={i18n.member}
                                        onChange={v => this.onChangeDistributionUnit({ member: v })}
                                        {...mode}
                                    />
                                </Row>
                                <Row>
                                    <NumberField
                                        col={6}
                                        title={i18n.enterYear}
                                        value={distributionUnit.enterYear}
                                        onChange={v => this.onChangeDistributionUnit({ enterYear: v })}
                                        {...mode}
                                    />
                                    <NumberField
                                        col={6}
                                        title={i18n.exitYear}
                                        value={distributionUnit.exitYear}
                                        onChange={v => this.onChangeDistributionUnit({ exitYear: v })}
                                        {...mode}
                                    />
                                </Row>
                                <Row>
                                    <NumberField
                                        col={6}
                                        title={i18n.treatedlength}
                                        value={distributionUnit.treatedlength}
                                        onChange={v => this.onChangeDistributionUnit({ treatedlength: v })}
                                        floatValue
                                        {...mode}
                                    />
                                    <NumberField
                                        col={6}
                                        title={i18n.connectionWithoutCounter}
                                        value={distributionUnit.connectionWithoutCounter}
                                        onChange={v => this.onChangeDistributionUnit({ connectionWithoutCounter: v })}
                                        {...mode}
                                    />
                                </Row>
                                <Row>
                                    <Input
                                        col={12}
                                        value={distributionUnit.descriptive}
                                        title={i18n.descriptive}
                                        onChange={v => this.onChangeDistributionUnit({ descriptive: v })}
                                        {...mode}
                                    />
                                </Row>
                            </div>
                        </Card>
                        <div className='margin-top-1'>
                            <StationPiezoContributorPanel
                                station={distributionUnit}
                                onChange={this.onChangeDistributionUnit}
                                readMode={ readMode }
                            />
                        </div>
                        <div className='margin-top-1'>
                            <LinkedStationsPanel
                                title={i18n.productionUnit}
                                filteredStations={['productionUnit']}
                                station={distributionUnit}
                                {...mode}
                            />
                        </div>
                        <div className='margin-top-1'>
                            <StationAssociatedTownsPanel
                                station={distributionUnit}
                                onChange={this.onChangeDistributionUnit}
                                {...mode}
                            />
                        </div>
                        <div className='margin-top-1'>
                            <DistributionUnitNetworksPanel
                                station={distributionUnit}
                                onChange={this.onChangeDistributionUnit}
                                {...mode}
                            />
                        </div>
                    </div>
                    <div className='col s3'>
                        {
                            (distributionUnit.updateLogin || distributionUnit.updateDate) &&
                                <div className='margin-top-1'>
                                    <UpdatePanel updateLogin={distributionUnit.updateLogin}
                                        updateDate={distributionUnit.updateDate}
                                    />
                                </div>
                        }
                        <Card noMargin={false} className='margin-top-1'>
                            <Row style={{ padding: '5 0' }}>
                                <Row>
                                    <Input
                                        col={6}
                                        value={distributionUnit.dataOrigin}
                                        title={i18n.dataOrigin}
                                        onChange={v => this.onChangeDistributionUnit({ dataOrigin: v })}
                                        {...mode}
                                    />
                                </Row>
                                <Row>
                                    <Select
                                        col={12}
                                        label={i18n.status}
                                        value={distributionUnit.status}
                                        onChange={v => this.onChangeDistributionUnit({
                                            status: v,
                                            statusLogin: getLogin(),
                                            statusDate: moment().valueOf(),
                                        })}
                                        options={this.props.status}
                                        integerValue
                                        {...mode}
                                    />
                                </Row>
                                <Row>
                                    <Select
                                        col={12}
                                        label={i18n.controlDoneBy}
                                        value={distributionUnit.loginStatus}
                                        onChange={v => this.onChangeDistributionUnit({ loginStatus: v })}
                                        options={this.props.users.map(u => ({ code: u.login, name: u.name || u.login }))}
                                        {...mode}
                                    />
                                </Row>
                                <Row>
                                    <Input
                                        col={6}
                                        value={getDate(distributionUnit.dateStatus)}
                                        title={i18n.controlDoneAt}
                                        onChange={v => onChangeDate(v, v2 => this.onChangeDistributionUnit({ dateStatus: v2 }))}
                                        {...mode}
                                    />
                                </Row>
                            </Row>
                        </Card>
                        <div className='margin-top-1'>
                            <LinkedStationsPanel
                                filteredStations={['quality', 'piezometry', 'hydrometry', 'pluviometry', 'installation', 'distributionUnit']}
                                station={this.props.distributionUnit}
                                {...mode}
                            />
                        </div>
                    </div>
                </Row>
            )
        }
        return null
    }
}

DistributionUnitDescriptionPanel.propTypes = {
    distributionUnit: instanceOf(DtoDistributionUnit),
    stationTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
    cities: arrayOf(CityDto),
    id: PropTypes.number,
    watermasses: arrayOf(DtoWaterMass),
    contributors: PropTypes.arrayOf(PropTypes.instanceOf(ContributorDto)),
    sandreCodes: arrayOf(DtoSandreCode),
    managementUnits: arrayOf(DtoManagementUnit),
    users: arrayOf(UserDto),
    status: arrayOf(DtoStatus),
}

const mapStateToProps = store => ({
    distributionUnit: store.DistributionUnitReducer.distributionUnit,
    distributionUnits: store.DistributionUnitReducer.distributionUnits,
    cities: store.CityReducer.cities,
    watermasses: store.WatermassReducer.watermasses,
    contributors: store.ContributorReducer.contributors,
    sandreCodes: store.ReferencialReducer.sandreCodes,
    managementUnits: store.ManagementUnitReducer.managementUnits,
    users: store.UserReducer.users,
    status: store.QualityReducer.status,
    sectors: store.SectorReducer.sectors,
})

const mapDispatchToProps = {
    push,
    fetchStatus: QualityAction.fetchStatus,
    fetchDistributionUnit: DistributionUnitAction.fetchDistributionUnit,
    deleteDistributionUnit: DistributionUnitAction.deleteDistributionUnit,
    updateDistributionUnit: DistributionUnitAction.updateDistributionUnit,
    fetchDistributionUnitSectors: DistributionUnitAction.fetchDistributionUnitSectors,
}

export default connect(mapStateToProps, mapDispatchToProps)(DistributionUnitDescriptionPanel)
