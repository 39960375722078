/* eslint-disable camelcase */
/* eslint-disable indent */
import { Avatar, Button, Divider, Grid } from '@mui/material'
import { styled, withStyles } from '@mui/styles'
import AgriAction from 'agriAdministration/actions/AgriAction'
import Card from 'components/card/Card'
import Input from 'components/forms/Input'
import ProgressCard from 'components/card/ProgressCard'
import { push } from 'connected-react-router'
import IntervenantCard from 'exploitations/components/cards/IntervenantCard'
import DtoExploitation from 'exploitations/dto/DtoExploitation'
import HomeAction from 'home/actions/HomeAction'
import InstallationAction from 'installation/actions/InstallationAction'
import DtoInstallationWithGeoItem from 'installation/components/installations/dto/DtoInstallationWithGeoItem'
import DtoInstallation from 'installation/dto/installation/DtoInstallation'
import { compact, groupBy, isEmpty, orderBy, uniqBy } from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import ContactAction from 'referencial/components/contact/actions/ContactAction'
import ContactDto from 'referencial/components/contact/dto/ContactDto'
import UsageAction from 'referencial/components/usage/actions/UsageAction'
import DtoUsageAgri from 'referencial/components/usage/dto/DtoUsageAgri'
import DtoSandreCode from 'referencial/dto/DtoSandreCode'
import i18n from 'simple-react-i18n'
import TankModal from 'station/components/link/modals/TankModal'
import DtoVariousMateriel from 'station/dto/materiel/DtoVariousMateriel'
import DtoDeclaration from 'survey/dto/DtoDeclaration'
import DtoIntervenantDeclaration from 'survey/dto/DtoIntervenantDeclaration'
import DtoSamplingPointDecla from 'survey/dto/DtoSamplingPointDecla'
import DtoSurvey from 'survey/dto/DtoSurvey'
import DtoTankDeclaration from 'survey/dto/DtoTankDeclaration'
import ToastrAction from 'toastr/actions/ToastrAction'
import { mainBlue, secondaryBlue } from 'utils/constants/ColorTheme'
import { getDate } from 'utils/DateUtil'
import { hasValue } from 'utils/NumberUtil'
import { getLogin, getSetting } from 'utils/SettingUtils'
import { formatMilliers, getI18nTitleDataLength } from 'utils/StringUtil'
import CounterCard from './cards/CounterCard'
import OuvrageCard from './cards/OuvrageCard'
import PumpCard from './cards/PumpCard'
import RetenueCard from './cards/RetenueCard'
import ModalAddEquipements from './modals/ModalAddEquipements'
import ModalOuvrage from './modals/ModalOuvrage'
import ModalOwner from './modals/ModalOwner'
import ModalRemovePoint from './modals/ModalRemovePoint'
import { POINT_STATUS_DECLARATION } from 'agriAdministration/constants/AgriConstants'
import { AccordionDetailsMUI, AccordionMUI, AccordionSummaryMUI } from 'components/styled/Accordions'
import Icon from 'components/icon/Icon'

const ButtonStep = styled(Button)({
    width: '100%',
})

const styles = () => ({
    blue: {
        color: 'white',
        backgroundColor: `${mainBlue} !important`,
    },
    card: {
        border: `1px solid ${secondaryBlue}`,
        borderWidth: '0 2 2 2',
    },
})

const GridItem = styled(Grid)({
    padding: 10,
})

const ChronicleItem = styled(Grid)({
    padding: '4px !important',
    textAlign: 'center',
})

const TYPE_ESTIM = 1
const TYPE_INDEX = 2

class DeclarationPointDetails extends Component {
    constructor(props) {
        super(props)
        this.state = {
            openModalOuvrage: false,
            openModalOwner: false,
            owner: {},
            openModalRetenue: false,
            openModalEquipement: false,
            equipement: {},
            retenueToUpdate: false,
            openModalRemove: false,
            point: {},
            installation: {},
            usages: [],
            usagesCurrentYear: [],
            dataLoaded: false,
            readMode: props.readMode,
        }
    }

    componentDidMount() {
        this.props.fetchUsages()
        const { match } = this.props
        this.props.fetchExploitationUser().then(() => {
            const { exploitation } = this.props
            this.props.fetchInstallation(match.params.idPoint).then(() => {
                if (!exploitation.idExploitation) {
                    this.props.fetchExploitationUser().then(() => {
                        this.getDeclaration()
                    })
                } else {
                    this.getDeclaration()
                }
            })
        })
        window.scrollTo(0, 0)
    }

    getDeclaration = () => {
        const { match, readMode } = this.props
        this.setState({ dataLoaded: false })
        this.props.fetchDeclaration(match.params.idDeclaration).then(() => {
            const { declaration, contacts } = this.props
            if (declaration.idDeclaration) {
                this.setState({ readMode: declaration.statusCode >= 4 || readMode })
                this.props.fetchDeclarationContributors(declaration.idSurvey).then(() => {
                    if (!contacts.length) {
                        this.props.fetchContacts().then(() => {
                            this.setContributors(declaration)
                        })
                    } else {
                        this.setContributors(declaration)
                    }
                })
            } else {
                this.props.push('/')
            }
        })
    }

    formatContact = (contact, updatedContact) => {
        if (updatedContact) {
            return {
                ...contact,
                ...updatedContact,
                address: updatedContact.road,
                additionalAddress: updatedContact.addressComplement,
                desktopTel: updatedContact.fax,
                postalBox: updatedContact.postalCode,
            }
        }
        return contact
    }

    setContributors = (declaration) => {
        const { installation, exploitation, match, contacts, declarationContributors, survey } = this.props
        const point = declaration
            ? declaration.link_declarationInstallation.find(
                (p) => p.idInstallation === parseInt(match.params.idPoint),
            )
            : new DtoSamplingPointDecla({})
        const linkPoint = declaration.link_exploitationInstallation ? declaration.link_exploitationInstallation.find((p) => p.idInstallation === point.idInstallation) : null
        const ownerUpdated = declarationContributors.find((dc) => dc.link_installations.find((cI) => cI.idInstallation === installation.id && cI.surveyId === declaration.idSurvey))
        const id = ownerUpdated ? (ownerUpdated.idContributorTemp || ownerUpdated.idContributor || ownerUpdated.id) : installation.ownerCode
        const owner = ownerUpdated && ownerUpdated.idContributorTemp ? {} : (contacts.find((c) => c.id === id) || {})
        const ownerFormatted = this.formatContact(owner, ownerUpdated)
        this.setState({
            declaration,
            installation,
            point,
            linkPoint,
            usages: point.link_usages,
            owner: ownerFormatted,
            usagesCurrentYear: point.link_usagesCurrent,
            dataLoaded: true,
        })
        this.props.setTitle([{
            title: i18n.planning,
            href: 'planning',
        }, {
            title: i18n.surveys,
            href: 'planning/surveys',
        }, {
            title: `${survey.name || ''} [${survey.year || ''}]`,
            href: `survey/${survey.idSurvey}`,
        }, {
            title: `${i18n.declaration} ${exploitation.codification}`,
            href: `survey/${survey.idSurvey}/declaration/${declaration.idDeclaration}/stepper`,
        }, {
            title: `${i18n.pointPrelevement} ${installation.code}${installation.location ? ` - ${installation.location}` : ''}`,
            href: `survey/${survey.idSurvey}/declaration/${declaration.idDeclaration}/point/${match.params.idPoint}`,
        }])
    }

    toggleState = (key) => {
        this.setState({ [key]: !this.state[key] })
    }

    onCancelEquipment = () => {
        this.toggleState('openModalEquipement')
    }

    onSaveEquipment = (link_equipments, link_pumps, link_counters) => {
        const { declaration, match } = this.props
        this.setState({ dataLoaded: false })
        const link_declarationInstallation = [
            ...declaration.link_declarationInstallation.filter((p) => p.idInstallation !== parseInt(match.params.idPoint)),
            {
                ...declaration.link_declarationInstallation.find((p) => p.idInstallation === parseInt(match.params.idPoint)),
                link_equipments,
            },
        ]
        const newDeclaration = {
            ...declaration,
            lastStep: declaration.lastStep >= 3 ? declaration.lastStep : 3,
            link_declarationInstallation,
            link_pumps,
            link_counters,
        }
        this.props.updateDeclaration(newDeclaration, true).then(() => {
            this.getDeclaration()
        })
        this.toggleState('openModalEquipement')
    }

    getListUsages = (current) => {
        const { usages: usagesCat } = this.props
        const {
            usagesCurrentYear,
            usages,
        } = this.state
        const usagesSelected = current ? usagesCurrentYear : usages
        const agriUsages = []
        const nonAgriUsages = []
        usagesSelected.forEach((u) => {
            const usageCat = usagesCat.find((uCat) => uCat.idUsage === u.idUsage)
            if (usageCat && usageCat.category === 1) {
                agriUsages.push(u)
            } else if (usageCat && usageCat.category !== 1) {
                nonAgriUsages.push(u)
            }
        })

        let totalAgri = 0
        if (agriUsages.length) {
            agriUsages.forEach((u) => {
                totalAgri = totalAgri + (isNaN(u.requestedYearVolume) ? 0 : u.requestedYearVolume)
            })
        }
        let totalNonAgri = 0
        if (nonAgriUsages.length) {
            nonAgriUsages.forEach((u) => {
                totalNonAgri = totalNonAgri + (isNaN(u.requestedYearVolume) ? 0 : u.requestedYearVolume)
            })
        }
        return (
            <Grid
                container
                direction='column'
                justifyContent='center'
                spacing={1}
            >
                <Input
                    col={12}
                    className='padding-left-1 padding-top-1'
                    title={i18n.agri}
                    value={`${agriUsages.length} ${agriUsages.length > 1 ? i18n.usages : i18n.usage} (${formatMilliers(totalAgri) || 0} m3)`}
                    readMode
                />
                <Input
                    col={12}
                    className='padding-left-1 padding-top-1'
                    title={i18n.nonAgri}
                    value={`${nonAgriUsages.length} ${nonAgriUsages.length > 1 ? i18n.usages : i18n.usage} (${formatMilliers(totalNonAgri) || 0} m3)`}
                    readMode
                />
            </Grid>
        )
    }

    onValidePoint = (declaration) => {
        this.props.push({
            pathname: `/survey/${declaration.idSurvey}/declaration/${declaration.idDeclaration}/stepper`,
            step: 3,
            declaration: {
                ...declaration,
            },
        })
    }

    onSaveOwner = (ownerUpdated) => {
        const { installation, declaration } = this.props
        const { owner } = this.state
        const id = ownerUpdated.idContributorTemp || ownerUpdated.idContributor || ownerUpdated.id

        const newLink = {
            surveyId: declaration.idSurvey,
            idInstallation: installation.id,
            updateLogin: getLogin(),
        }

        const ownerFormatted = {
            ...ownerUpdated,
            road: ownerUpdated.address,
            addressComplement: ownerUpdated.additionalAddress,
            fax: ownerUpdated.desktopTel,
            postalCode: ownerUpdated.postalBox,
            idContributor: id,
            surveyId: declaration.idSurvey,
            contactType: 2,
        }
        // si on utilise un contact existant
        if (!isEmpty(ownerUpdated)) {
            if (id) {
                this.setState({ dataLoaded: false })
                // si on change de propriétaire
                if ((ownerUpdated.idContributorTemp && owner.idContributorTemp && ownerUpdated.idContributorTemp !== owner.idContributorTemp) ||
                    (id !== (owner.idContributor || owner.id))) {
                    this.props.updateDeclarationContributor(declaration.idSurvey, {
                        ...ownerFormatted,
                        link_installations: [
                            ...(ownerUpdated.link_installations || []),
                            {
                                ...newLink,
                                idContributor: id,
                                isTemp: !!ownerUpdated.idContributorTemp,
                            },
                        ],
                    }).then(() => {
                        this.getDeclaration()
                    })
                // si on modifie les infos du propriétaire actuel
                } else {
                    this.props.updateDeclarationContributor(declaration.idSurvey, {
                        ...ownerFormatted,
                        link_installations: [
                            ...(ownerUpdated.link_installations || []),
                            {
                                ...newLink,
                                idContributor: id,
                                isTemp: !!ownerUpdated.idContributorTemp,
                            },
                        ],
                    }).then(() => {
                        this.getDeclaration()
                    })
                }
            } else if (!id) {
                this.setState({ dataLoaded: false })
                this.props.updateDeclarationContributor(declaration.idSurvey, ownerFormatted).then((idReturned) => {
                    this.props.updateDeclarationContributor(declaration.idSurvey, {
                        ...ownerFormatted,
                        idContributorTemp: idReturned,
                        link_installations: [
                            ...(ownerUpdated.link_installations || []),
                            {
                                ...newLink,
                                idContributor: idReturned,
                                isTemp: true,
                            },
                        ],
                    }).then(() => {
                        this.getDeclaration()
                    })
                })
            }
            this.toggleState('openModalOwner')
        } else {
            this.props.warning('Veuillez renseigner un propriétaire')
        }
    }

    onDeclareUnused = (declaration) => {
        const { point } = this.state
        const points = declaration.link_declarationInstallation.filter(
            (p) => p.idInstallation !== point.idInstallation,
        )
        const newDeclaration = {
            ...declaration,
            lastStep: declaration.lastStep >= 3 ? declaration.lastStep : 3,
            link_declarationInstallation: points,
        }
        this.props.updateDeclaration(newDeclaration).then(() => {
            this.toggleState('openModalRemove')
            this.props.push({
                pathname: `/survey/${declaration.idSurvey}/declaration/${declaration.idDeclaration}/stepper`,
                step: 3,
            })
        })
    }

    onDeclareUsed = (declaration) => {
        const { point } = this.state
        const pointToRemove = declaration.link_declarationInstallation.find((p) => p.idInstallation === point.idInstallation)
        const points = [...declaration.link_declarationInstallation.filter(
            (p) => p.idInstallation !== point.idInstallation,
        ), {
            ...pointToRemove,
            stateCode: POINT_STATUS_DECLARATION.USED,
        }]
        const newDeclaration = {
            ...declaration,
            lastStep: declaration.lastStep >= 3 ? declaration.lastStep : 3,
            link_declarationInstallation: points,
        }
        this.props.updateDeclaration(newDeclaration).then(() => {
            this.toggleState('openModalRemove')
            this.props.push({
                pathname: `/survey/${declaration.idSurvey}/declaration/${declaration.idDeclaration}/stepper`,
                step: 3,
            })
        })
    }

    onDeclareAdjourn = (declaration) => {
        const { point } = this.state
        const pointToRemove = declaration.link_declarationInstallation.find((p) => p.idInstallation === point.idInstallation)
        const points = [...declaration.link_declarationInstallation.filter(
            (p) => p.idInstallation !== point.idInstallation,
        ), {
            ...pointToRemove,
            stateCode: POINT_STATUS_DECLARATION.ADJOURNED,
            link_usages: [],
        }]
        const newDeclaration = {
            ...declaration,
            lastStep: declaration.lastStep >= 3 ? declaration.lastStep : 3,
            link_declarationInstallation: points,
        }
        this.props.updateDeclaration(newDeclaration).then(() => {
            this.toggleState('openModalRemove')
            this.props.push({
                pathname: `/survey/${declaration.idSurvey}/declaration/${declaration.idDeclaration}/stepper`,
                step: 3,
            })
        })
    }

    onRemovePoint = (declaration) => {
        const { point } = this.state
        const pointToRemove = declaration.link_declarationInstallation.find((p) => p.idInstallation === point.idInstallation)
        const points = [...declaration.link_declarationInstallation.filter(
            (p) => p.idInstallation !== point.idInstallation,
        ), {
            ...pointToRemove,
            stateCode: POINT_STATUS_DECLARATION.REMOVED,
        }]
        const newDeclaration = {
            ...declaration,
            lastStep: declaration.lastStep >= 3 ? declaration.lastStep : 3,
            link_declarationInstallation: points,
        }
        this.props.updateDeclaration(newDeclaration).then(() => {
            this.toggleState('openModalRemove')
            this.props.push({
                pathname: `/survey/${declaration.idSurvey}/declaration/${declaration.idDeclaration}/stepper`,
                step: 3,
            })
        })
    }

    goToUsages = (type) => {
        const { match } = this.props
        this.props.push(`/survey/${match.params.id}/declaration/${match.params.idDeclaration}/usages/${match.params.idPoint}/${type}`)
    }

    getAssociatedResources = () => {
        const { linkPoint } = this.state
        return linkPoint ? linkPoint.link_samples[0] : null
    }

    getTypePrel = (key) => {
        switch (key) {
            case 1:
                return 'PREL_AGRI.NATURE_PRELEVEMENT_ESO'
            case 2:
                return 'PREL_AGRI.NATURE_PRELEVEMENT_ESU'
            default:
                return 'PREL_AGRI.NATURE_PRELEVEMENT'
        }
    }

    getNature = () => {
        const associatedResources = this.getAssociatedResources()
        if (associatedResources) {
            const { sandreCodes } = this.props
            const codeType = this.getTypePrel(associatedResources.sampleType)
            const codeFind = sandreCodes.find((c) => c.field === codeType && c.code === associatedResources.sampleNature)
            return codeFind ? codeFind.name : ''
        }
        return ''
    }

    getOuvrages = () => {
        const { point, linkPoint, openModalOuvrage, readMode } = this.state
        if (linkPoint?.link_sampleCasings?.length) {
            return (
                linkPoint.link_sampleCasings.map((ouvrage) => {
                    const updatedOuvrage = point.link_sampleCasings.find((o) => o.idInstallation === ouvrage.idInstallation)
                    const ouvrageDecla = {
                        ...ouvrage,
                        ...updatedOuvrage,
                    }
                    return (
                        <GridItem item xs={6} id={ouvrageDecla.id}>
                            <OuvrageCard
                                title={i18n.work}
                                ouvrage={ouvrageDecla}
                                actions={readMode ? [] : [{ onClick: () => this.toggleState('openModalOuvrage'), iconName: 'edit' }]}
                                style={{ height: '100%' }}
                                round
                            />
                            {openModalOuvrage && (
                                <ModalOuvrage
                                    ouvrage={ouvrageDecla}
                                    open={openModalOuvrage}
                                    onCancel={this.onCancelOuvrage}
                                    onValidate={this.onSaveOuvrage}
                                    surveyMode
                                />
                            )}
                        </GridItem>
                    )
                })
            )
        }
        return (
            <GridItem item xs={6}>
                <OuvrageCard
                    title={i18n.work}
                    style={{ height: '100%' }}
                    round
                />
            </GridItem>
        )
    }

    onCancelOuvrage = () => {
        this.toggleState('openModalOuvrage')
    }

    onSaveOuvrage = (ouvrage) => {
        const { point } = this.state
        const { declaration, exploitation } = this.props
        const updatedDeclaration = {
            ...declaration,
            lastStep: declaration.lastStep >= 3 ? declaration.lastStep : 3,
            link_declarationInstallation: [
                ...declaration.link_declarationInstallation.filter((p) => p.idInstallation !== point.idInstallation),
                {
                    ...point,
                    link_sampleCasings: [{
                        ...ouvrage,
                        idSurvey: declaration.idSurvey,
                        idExploitation: exploitation.idExploitation,
                        idInstallation: point.idInstallation,
                    }],
                },
            ],
        }
        this.props.updateDeclaration(updatedDeclaration, true).then(() => {
            this.getDeclaration()
            this.toggleState('openModalOuvrage')
        })
    }

    getRetenues = () => {
        const { linkPoint, point, readMode } = this.state
        const length = linkPoint?.link_sampleTanks?.length || 0
        const title = getI18nTitleDataLength(`${i18n.detention} (${length} ${i18n.element})`, `${i18n.detentions} (${length} ${i18n.elements})`, length)
        return (
            <GridItem item xs={6}>
                <AccordionMUI defaultExpanded={false} round>
                    <AccordionSummaryMUI round color={secondaryBlue}>
                        {title}
                    </AccordionSummaryMUI>
                    <AccordionDetailsMUI round>
                    {length ? (
                        <Grid container direction='column'>
                            {linkPoint.link_sampleTanks.map((retenue) => {
                                const updatedRetenue = point.link_sampleTanks.find((o) => o.idTank === retenue.idTank)
                                const retenueDecla = updatedRetenue ? {
                                    ...retenue,
                                    ...new DtoTankDeclaration(updatedRetenue),
                                    link_periodes: updatedRetenue?.link_periodes?.length ? [...updatedRetenue?.link_periodes] : [...retenue.link_periodes],
                                } : retenue
                                return (
                                    <RetenueCard
                                        id={retenueDecla.idTank}
                                        title={retenueDecla.id || i18n.noName}
                                        retenue={retenueDecla}
                                        actions={readMode ? [] : [{ onClick: () => this.editRetenue(retenueDecla), iconName: 'edit' }]}
                                        className='margin-bottom-1'
                                        round
                                    />
                                )
                            })}
                        </Grid>
                    ) : ''}
                    </AccordionDetailsMUI>
                </AccordionMUI>
            </GridItem>
        )
    }

    onCancelRetenue = () => {
        this.setState({ retenueToUpdate: false })
        this.toggleState('openModalRetenue')
    }

    onSaveRetenue = (retenue) => {
        const { point } = this.state
        const { declaration, exploitation } = this.props
        const updatedDeclaration = {
            ...declaration,
            lastStep: declaration.lastStep >= 3 ? declaration.lastStep : 3,
            link_declarationInstallation: [
                ...declaration.link_declarationInstallation.filter((p) => p.idInstallation !== point.idInstallation),
                {
                    ...point,
                    link_sampleTanks: [
                        ...point.link_sampleTanks.filter((t) => t.idTank !== retenue.idTank),
                        {
                            ...retenue,
                            idSurvey: declaration.idSurvey,
                            idExploitation: exploitation.idExploitation,
                            idInstallation: point.idInstallation,
                            link_periodes: (retenue.link_periodes || []).map((l) => ({
                                ...l,
                                idTank: retenue.idTank,
                                idSurvey: declaration.idSurvey,
                                updateLogin: getLogin(),
                            })),
                        }],
                },
            ],
        }
        this.props.updateDeclaration(updatedDeclaration).then(() => {
            this.getDeclaration()
            this.setState({ retenueToUpdate: false })
            this.toggleState('openModalRetenue')
        })
    }

    editRetenue = (retenue) => {
        this.setState({ retenueToUpdate: retenue })
        this.toggleState('openModalRetenue')
    }

    getExploitationEquipments = () => {
        const { declaration, variousMateriels } = this.props
        const { point } = this.state
        const matsSituPoint = declaration.link_exploitationMateriel.filter((m) => m.siteType === 7 &&
            m.siteCode === point.idInstallation &&
            variousMateriels.find((vM) => vM.id === m.idVarious && (!vM.administrator || vM.administrator === declaration.idContributor)),
        ) || []
        let matsSituOthers = []
        declaration.link_exploitationMateriel.forEach((m) => {
            if (!matsSituPoint.find((mP) => mP.idVarious === m.idVarious) &&
                !matsSituOthers.find((mO) => mO.idVarious === m.idVarious) &&
                m.siteType === 7 &&
                declaration.link_exploitationInstallation.find((p) => m.siteCode === p.idInstallation) &&
                variousMateriels.find((vM) => vM.id === m.idVarious && (!vM.administrator || vM.administrator === declaration.idContributor))) {
                matsSituOthers.push(m)
            }
        })
        return uniqBy([...matsSituPoint, ...matsSituOthers], v => [v.idVarious, v.siteCode, v.siteCode, v.siteType, v.statusCode].join())
    }

    getUpdatedMats(declaration) {
        const { point } = this.state

        const link_equipments = declaration.link_declarationInstallation.find((p) => p.idInstallation === point.idInstallation).link_equipments || []
        const materielsSelected = this.getExploitationEquipments().filter((m) => m.siteCode === point.idInstallation) // pompes installées initialement
        const newMats = link_equipments.filter((mLink) => !mLink.idMatAttachment && mLink.mode === 'c') // pompes ajoutées
        const deletedMats = link_equipments.filter((mLink) => !mLink.idMatAttachment && mLink.mode === 'd') // pompes supprimées

        const newSelectedMatsSitu = compact(newMats.map((mLink) => {
            return declaration.link_exploitationMateriel.find((mSitu) => mSitu.idVarious === mLink.idMat)
        }))
        const mergedSelectedMatSitu = compact([ // Pompes actuellement sur le point
            ...materielsSelected.filter((m) => !deletedMats.find((mLink) => m.idVarious === mLink.idMat)),
            ...newSelectedMatsSitu,
        ])

        const linkAssignments = link_equipments.filter((linkE) => linkE.assignmentRate)

        let linkCounters = link_equipments.filter((linkE) => linkE.idMatAttachment).map((linkE) => { // Liens entre pompes et compteurs
            return {
                idPump: linkE.idMat,
                idCounter: linkE.idMatAttachment,
                mode: linkE.mode,
            }
        })
        mergedSelectedMatSitu.forEach((mat) => {
            const counterSitu = declaration.link_exploitationMateriel.find((mSitu) => mSitu.siteType === 8 && mat.idVarious === mSitu.siteCode)
            if (counterSitu && !linkCounters.find((linkC) => linkC.idPump === counterSitu.siteCode && linkC.idCounter === counterSitu.idVarious)) {
                linkCounters.push({
                    idPump: counterSitu.siteCode,
                    idCounter: counterSitu.idVarious,
                    mode: 'u',
                })
            }
        })
        return {
            mergedSelectedMatSitu: uniqBy(mergedSelectedMatSitu, v => [v.idVarious, v.siteCode, v.siteCode, v.siteType, v.statusCode].join()),
            linkAssignments,
            linkCounters,
        }
    }

    getEquipments = () => {
        const { readMode, declaration } = this.state
        const { variousMateriels, exploitation, match } = this.props
        if (variousMateriels.length) {
            const { mergedSelectedMatSitu = [], linkAssignments = [], linkCounters = [] } = this.getUpdatedMats(declaration)
            const length = mergedSelectedMatSitu.length
            const title = getI18nTitleDataLength(`${i18n.materiel} (${length} ${i18n.element})`, `${i18n.materiels} (${length} ${i18n.elements})`, length)
            return (
                <GridItem item xs={6}>
                    <AccordionMUI defaultExpanded={false} round>
                        <AccordionSummaryMUI round color={secondaryBlue}>
                            <Grid container justifyContent='space-between' className='padding-right-1'>
                                {title}
                                {!readMode && (
                                    <Icon
                                        onClick={(e) => {
                                            e.stopPropagation()
                                            this.toggleState('openModalEquipement')
                                        }}
                                    >
                                        edit
                                    </Icon>
                                )}
                            </Grid>
                        </AccordionSummaryMUI>
                        <AccordionDetailsMUI round>
                            <Grid container direction='column'>
                                {mergedSelectedMatSitu.map((d) => {
                                    const matPump = variousMateriels.find((m) => m.id === d.idVarious) || {}
                                    const pump = matPump.pump || {}
                                    const pumpAssignmentRate = linkAssignments.find((lA) => lA.idMat === d.idVarious && !lA.idMatAttachment)
                                    const pumpRep = exploitation.link_repartition_materiels.find((m) => m.linkType === 1 && m.idElement1 === parseInt(match.params.idPoint) && m.idElement2 === d.idVarious)
                                    const pompeInfos = {
                                        ...declaration.link_exploitationMateriel.find((m) => m.siteType === 8 && m.siteCode === d.idVarious),
                                        ...matPump,
                                        ...pump,
                                        ...declaration.link_pumps.find((p) => p.id === d.idVarious),
                                        assignmentRate: pumpAssignmentRate ? pumpAssignmentRate?.assignmentRate : pumpRep?.repartition,
                                    }
                                    const allCompteursInfo = compact(linkCounters.filter((lC) => lC.idPump === d.idVarious && lC.mode !== 'd').map((lC) => {
                                        const matCounter = variousMateriels.find((c) => c.id === lC.idCounter)
                                        const counterAssignmentRate = linkAssignments.find((lA) => lA.idMat === d.idVarious && lA.idMatAttachment === lC.idCounter)
                                        const counterRep = exploitation.link_repartition_materiels.find((m) => m.linkType === 2 && m.idElement1 === d.idVarious && m.idElement2 === lC.idCounter)
                                        if (matCounter) {
                                            const counter = matCounter.counter || {}
                                            return {
                                                ...matCounter,
                                                ...counter,
                                                ...declaration.link_counters.find((c) => c.id === lC.idCounter),
                                                assignmentRate: counterAssignmentRate ? counterAssignmentRate?.assignmentRate : counterRep?.repartition,
                                            }
                                        }
                                        return null
                                    }))
                                    return (
                                        <>
                                            <PumpCard
                                                title={`${i18n.pumpingEquipment} ${pompeInfos.reference || ''}`}
                                                pump={pompeInfos}
                                            />
                                            {allCompteursInfo.map((counter) => (
                                                <CounterCard
                                                    title={`${i18n.countingDevice} ${counter.reference || ''}`}
                                                    counter={counter}
                                                />
                                            ))}
                                        </>
                                    )
                                })}
                            </Grid>
                        </AccordionDetailsMUI>
                    </AccordionMUI>
                </GridItem>
            )
        }
        return ''
    }

    getMateriels = () => {
        const { declaration, match } = this.props
        const link_equipments = declaration.link_declarationInstallation.find((p) => p.idInstallation === parseInt(match.params.idPoint)).link_equipments || []
        const newMats = link_equipments.filter((mLink) => !mLink.idMatAttachment && mLink.mode !== 'd')
        const deletedMats = link_equipments.filter((mLink) => !mLink.idMatAttachment && mLink.mode === 'd')
        const deletedCounters = link_equipments.filter((mLink) => mLink.idMatAttachment && mLink.mode === 'd')
        const exploitationPumps = declaration.link_exploitationMateriel.filter((m) => m.siteType === 7 && m.siteCode === parseInt(match.params.idPoint) && !deletedMats.find((mLink) => m.idVarious === mLink.idMat)) || []
        const mergedPumpsIds = uniqBy([
            ...exploitationPumps.map((m) => m.idVarious),
            ...newMats.map((m) => m.idMat),
        ], obj => obj)
        const exploitationCountersId = compact(mergedPumpsIds.flatMap((pumpId) => {
            const pumpSitu = declaration.link_exploitationMateriel.filter((situ) => situ.siteType === 8 && situ.siteCode === pumpId && !deletedCounters.find((mLink) => pumpId === mLink.idMat))
            if (pumpSitu) {
                return pumpSitu.map((s) => s.idVarious)
            }
            return null
        }))
        const newCounters = link_equipments.filter((mLink) => mLink.idMatAttachment && mLink.mode !== 'd' && mergedPumpsIds.includes(mLink.idMat))
        const mergedCountersIds = uniqBy([
            ...exploitationCountersId,
            ...newCounters.map((m) => m.idMatAttachment),
        ], obj => obj)
        return [mergedPumpsIds, mergedCountersIds]
    }

    getEvolValue = (type, chronicles, chronicle, index) => {
        if (type === TYPE_INDEX) {
            return chronicle.value - chronicles[index + 1].value
        }
        return Math.round(chronicles.slice(index).reduce((acc, d) => acc + d.value, 0))
    }

    getChroniclesByType = (chroniclesByType, type, readingCoefficient = 1) => {
        if (chroniclesByType?.length) {
            const orderedChronicles = orderBy(chroniclesByType, ['measureDate', 'endDate', 'value'], ['desc', 'desc', 'desc'])
            return (
                <>
                    <ChronicleItem item className='italic-label' xs={3}>
                        {type === TYPE_ESTIM ? i18n.startDate : i18n.statementDate}
                    </ChronicleItem>
                    <ChronicleItem item className='italic-label' xs={3}>
                        {type === TYPE_ESTIM ? i18n.endDate : ''}
                    </ChronicleItem>
                    <ChronicleItem item className='italic-label' xs={3}>
                        {type === TYPE_ESTIM ? i18n.estimateM3 : i18n.index}
                    </ChronicleItem>
                    <ChronicleItem item className='italic-label' xs={3}>
                        {type === TYPE_ESTIM ? i18n.stack : i18n.evolution}
                    </ChronicleItem>
                    {compact(orderedChronicles.map((chronicle, i) => {
                        const valueEvol = i !== orderedChronicles.length - 1 ? this.getEvolValue(type, orderedChronicles, chronicle, i) : null
                        return (i > 4) ? null : (
                            <>
                                <ChronicleItem item xs={3}>
                                    {getDate(chronicle.measureDate)}
                                </ChronicleItem>
                                <ChronicleItem item xs={3}>
                                    {chronicle.endDate ? getDate(chronicle.endDate) : ''}
                                </ChronicleItem>
                                <ChronicleItem item xs={3}>
                                    {`${formatMilliers(chronicle.value) || 0}${chronicle.measureType === 1 ? ' m3' : ''}`}
                                </ChronicleItem>
                                <ChronicleItem item xs={3} style={ valueEvol < 0 ? { color: 'orange' } : {}}>
                                    {hasValue(valueEvol) ? ` ${valueEvol < 0 ? '-' : '+'} ${formatMilliers(Math.abs((valueEvol) * readingCoefficient))} m3` : ''}
                                </ChronicleItem>
                            </>
                        )
                    }))}
                    {orderedChronicles.length > 5 && <ChronicleItem item xs={11}>...</ChronicleItem>}
                    <ChronicleItem item xs={11}>
                        <Divider />
                    </ChronicleItem>
                </>
            )
        }
        return null
    }

    getChronicles = () => {
        const { declaration, variousMateriels, exploitation } = this.props
        const { point } = this.state
        const [pumpsId, countersId] = this.getMateriels()
        const allIds = [...pumpsId, ...countersId]
        const linkPoint = exploitation.link_samplings.find((l) => l.idInstallation === point.idInstallation) || {}
        const groupedByMat = Object.values(groupBy(declaration.link_chronicles.filter((c) => allIds.includes(c.idMat) && (!linkPoint.startDate || c.measureDate >= linkPoint.startDate) && (!linkPoint.endDate || c.measureDate < linkPoint.endDate)), 'idMat'))
        return compact(groupedByMat.map((chronicles) => {
            const variousMat = variousMateriels.find((mat) => mat.id === chronicles[0].idMat) || {}
            if (hasValue(variousMat.administrator) && variousMat.administrator !== exploitation.operatorCode) {
                return null
            }
            const indexChronicles = chronicles.filter((c) => c.measureType === TYPE_INDEX)
            const estimateChronicles = chronicles.filter((c) => c.measureType === TYPE_ESTIM)
            const counterUpdated = declaration.link_counters.find((c) => c.id === variousMat.id) || {}
            const readingCoefficient = counterUpdated.readingCoefficient || variousMat?.counter?.readingCoefficient
            return (
                <Grid
                    item
                    xs={12}
                    container
                    direction='row'
                    justifyContent='center'
                >
                    <ChronicleItem item xs={11} className='bold' style={{ textAlign: 'start' }}>
                        {variousMat.reference}{readingCoefficient ? ` - ${i18n.readingCoefficient} ${readingCoefficient}` : ''}
                    </ChronicleItem>
                    {this.getChroniclesByType(indexChronicles, TYPE_INDEX, readingCoefficient)}
                    {this.getChroniclesByType(estimateChronicles, TYPE_ESTIM)}
                </Grid>
            )
        }))
    }

    render() {
        const {
            openModalOwner,
            openModalRetenue,
            openModalRemove,
            openModalEquipement,
            installation,
            owner,
            retenueToUpdate,
            dataLoaded,
            point,
            readMode,
        } = this.state
        const { classes, declaration = {}, survey } = this.props
        if (dataLoaded) {
            return (
                <>
                    <Grid
                        container
                        alignItems='center'
                        justifyContent='center'
                        style={{
                            padding: '0 10px',
                            backgroundColor: 'white',
                            height: 60,
                            borderBottom: '1px solid grey',
                        }}
                    >
                        <Grid item xs={2} md={1}>
                            <Avatar className={classes.blue}>3/4</Avatar>
                        </Grid>
                        <Grid item xs={10}>
                            <h5 className='italic-label'>Je vérifie mon point de prélèvement</h5>
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        justifyContent='space-between'
                        alignItems='stretch'
                        style={{
                            height: 'calc(100% - 184px)',
                            overflow: 'none',
                        }}
                    >
                        <Grid
                            item
                            container
                            direction='column'
                            justifyContent='flex-start'
                            alignItems='stretch'
                            style={{
                                backgroundColor: 'white',
                            }}
                        >
                            <Grid style={{ padding: '8px 16px' }} item container>
                                <GridItem item xs={2}>
                                    <p><span className='bold'>{i18n.code} : </span>{installation.code}</p>
                                    <p><span className='bold'>{i18n.sampleNature} : </span>{this.getNature()}</p>
                                    <p><span className='bold'>{i18n.location} : </span>{installation.location}</p>
                                </GridItem>
                                <GridItem item xs={10}>
                                    <p><span className='bold'>{i18n.name} : </span>{installation.name}</p>
                                    <p><span className='bold'>{i18n.descriptive} : </span>{installation.descriptive}</p>
                                    <p><span className='bold'>{i18n.comment} : </span>{installation.comments}</p>
                                </GridItem>
                            </Grid>
                            <Grid item container alignItems='flex-start' justifyContent='flex-start' style={{ paddingBottom: 60 }}>
                                <Grid item xs={8} container justifyContent='flex-start'>
                                    <Grid item xs={12} container alignItems='stretch' justifyContent='flex-start' className='padding-bottom-1'>
                                        <GridItem item xs={6}>
                                            <IntervenantCard
                                                title={i18n.owner}
                                                intervenant={owner}
                                                actions={readMode ? [] : [{ onClick: () => this.toggleState('openModalOwner'), iconName: 'edit' }]}
                                                style={{ height: '100%' }}
                                                round
                                            />
                                        </GridItem>
                                        {this.getOuvrages()}
                                    </Grid>
                                    <Grid item xs={12} container alignItems='stretch' justifyContent='flex-start'>
                                        {this.getRetenues()}
                                        {this.getEquipments()}
                                    </Grid>
                                </Grid>
                                <Grid item xs={4} container direction='column' alignItems='stretch' justifyContent='flex-start'>
                                    {((getSetting(survey.link_params, 'usagesReal') || '1') === '1' || (getSetting(survey.link_params, 'consos') || '1') === '1') && (
                                        <GridItem item>
                                            <Card
                                                title={`${i18n.consos} ${survey.surveyType === 2 ? survey?.year : survey?.year - 1}`}
                                                headerStyle={{ backgroundColor: secondaryBlue }}
                                                actions={[{ onClick: () => this.goToUsages('reel'), iconName: 'edit' }]}
                                                className={`row no-margin padding-bottom-1 ${classes.card}`}
                                                round
                                            >
                                                <div className='padding-1'>
                                                    {(getSetting(survey.link_params, 'usagesReal') || '1') !== '0' && this.getListUsages(true)}
                                                    <Grid className='padding-1'>
                                                        <Divider />
                                                    </Grid>
                                                    {(getSetting(survey.link_params, 'consos') || '1') !== '0' && this.getChronicles()}
                                                </div>
                                            </Card>
                                        </GridItem>
                                    )}
                                    {(getSetting(survey.link_params, 'usagesPrevi') || '1') !== '0' && (
                                        <GridItem item>
                                            <Card
                                                title={`${i18n.anticipation} ${survey.year}`}
                                                headerStyle={{ backgroundColor: secondaryBlue }}
                                                actions={[{ onClick: () => this.goToUsages('previ'), iconName: 'edit' }]}
                                                className={`row no-margin padding-bottom-1 ${classes.card}`}
                                                round
                                            >
                                                <div className='padding-1'>
                                                    {this.getListUsages(false)}
                                                </div>
                                            </Card>
                                        </GridItem>
                                    )}
                                </Grid>
                            </Grid>
                            <Grid
                                item
                                container
                                direction='row'
                                justifyContent='space-around'
                                alignItems='center'
                                style={{
                                    position: 'fixed',
                                    bottom: 0,
                                    height: 60,
                                    backgroundColor: 'white',
                                    borderTop: '1px solid grey',
                                    width: 'calc(100% - 60px)',
                                }}
                            >
                                <Grid item xs={2}>
                                    <ButtonStep
                                        variant='contained'
                                        color='primary'
                                        onClick={() => this.toggleState('openModalRemove')}
                                        disabled={readMode}
                                    >
                                        {i18n.changeStatusPoint}
                                    </ButtonStep>
                                </Grid>
                                <Grid item xs={2} />
                                <Grid item xs={2}>
                                    <ButtonStep variant='contained' color='primary' onClick={() => this.onValidePoint(declaration)}>
                                        {i18n.validate}
                                    </ButtonStep>
                                </Grid>
                            </Grid>
                        </Grid>
                    {openModalRemove && (
                        <ModalRemovePoint
                            open={openModalRemove}
                            onDeclareUsed={() => this.onDeclareUsed(declaration)}
                            onDeclareUnused={() => this.onDeclareUnused(declaration)}
                            onDeclareAdjourn={() => this.onDeclareAdjourn(declaration)}
                            onRemovePoint={() => this.onRemovePoint(declaration)}
                            onCancel={() => this.toggleState('openModalRemove')}
                            pointStatus={declaration.link_declarationInstallation.find((p) => p.idInstallation === point.idInstallation).stateCode}
                        />
                    )}
                    {openModalRetenue && (
                        <TankModal
                            tank={retenueToUpdate}
                            open={openModalRetenue}
                            onCancel={this.onCancelRetenue}
                            onValidate={this.onSaveRetenue}
                            lockId
                        />
                    )}
                    {openModalOwner && (
                        <ModalOwner
                            owner={owner || {}}
                            open={openModalOwner}
                            onCancel={() => this.toggleState('openModalOwner')}
                            onSave={this.onSaveOwner}
                        />
                    )}
                    {openModalEquipement && (
                        <ModalAddEquipements
                            open={openModalEquipement}
                            materiels={this.getExploitationEquipments()}
                            point={point}
                            declaration={declaration}
                            onSave={this.onSaveEquipment}
                            onCancel={this.onCancelEquipment}
                        />
                    )}
                    </Grid>
                </>
            )
        }
        return <ProgressCard className='padding-top-1 padding-right-1' indeterminate round />
    }
}

DeclarationPointDetails.propTypes = {
    classes: PropTypes.instanceOf(PropTypes.object),
    match: PropTypes.instanceOf(PropTypes.object),
    declaration: PropTypes.instanceOf(DtoDeclaration),
    exploitation: PropTypes.instanceOf(DtoExploitation),
    setTitle: PropTypes.func,
    push: PropTypes.func,
    survey: PropTypes.instanceOf(DtoSurvey),
    installation: PropTypes.instanceOf(DtoInstallation),
    installationsWithGeo: PropTypes.arrayOf(PropTypes.instanceOf(DtoInstallationWithGeoItem)),
    usages: PropTypes.arrayOf(PropTypes.instanceOf(DtoUsageAgri)),
    sandreCodes: PropTypes.arrayOf(PropTypes.instanceOf(DtoSandreCode)),
    fetchExploitationUser: PropTypes.func,
    fetchInstallation: PropTypes.func,
    updateDeclaration: PropTypes.func,
    fetchDeclaration: PropTypes.func,
    fetchUsages: PropTypes.func,
    fetchDeclarationContributors: PropTypes.func,
    fetchContacts: PropTypes.func,
    updateDeclarationContributor: PropTypes.func,
    fetchInstallationsByExploitationId: PropTypes.func,
    warning: PropTypes.func,
    readMode: PropTypes.bool,
    contacts: PropTypes.arrayOf(PropTypes.instanceOf(ContactDto)),
    declarationContributors: PropTypes.arrayOf(PropTypes.instanceOf(DtoIntervenantDeclaration)),
    variousMateriels: PropTypes.arrayOf(PropTypes.instanceOf(DtoVariousMateriel)),
}

const mapStateToProps = (store) => {
    return {
        usages: store.UsageReducer.usages,
        installationsWithGeo: store.InstallationReducer.installationsWithGeo,
        installation: store.InstallationReducer.installation,
        declaration: store.AgriReducer.declaration,
        exploitation: store.AgriReducer.exploitation,
        sandreCodes: store.ReferencialReducer.sandreCodes,
        contacts: store.ContactReducer.contacts,
        declarationContributors: store.AgriReducer.declarationContributors,
        variousMateriels: store.VariousMaterielReducer.variousMateriels,
        survey: store.AgriReducer.survey,
    }
}

const mapDispatchToProps = {
    fetchUsages: UsageAction.fetchUsages,
    fetchExploitationUser: AgriAction.fetchExploitationUser,
    fetchDeclaration: AgriAction.fetchDeclaration,
    updateDeclaration: AgriAction.updateDeclaration,
    fetchDeclarationContributors: AgriAction.fetchDeclarationContributors,
    fetchInstallation: InstallationAction.fetchInstallation,
    setTitle: HomeAction.setTitle,
    fetchContacts: ContactAction.fetchContacts,
    updateDeclarationContributor: AgriAction.updateDeclarationContributor,
    fetchInstallationsByExploitationId: InstallationAction.fetchInstallationsByExploitationId,
    warning: ToastrAction.warning,
    push,
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(DeclarationPointDetails))
