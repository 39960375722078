import { min, minBy, orderBy, sum, partition, isUndefined, keys, groupBy, uniqBy, max, last } from 'lodash'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import ProgressCard from '../../../components/card/ProgressCard'
import Axis from '../../../components/echart/Axis'
import ChartTabs from '../../../components/echart/ChartTabs'
import EChart from '../../../components/echart/EChart'
import {
    exportExcelIcon,
    exportPictureIcon,
    fullScreenIcon,
    getAxisLabelInterval,
    getBarWidth,
    noDataSymbol,
} from '../../../components/echart/EChartUtils'
import Bar from '../../../components/echart/series/Bar'
import Line from '../../../components/echart/series/Line'
import {
    generateGradient, getColorFromPalette2,
    getColorFromPalettePluvio,
    getThresholdColor,
    getThresholdColorCode,
} from '../../../utils/ColorUtil'
import { getFullDate } from '../../../utils/DateUtil'
import { exportFile } from '../../../utils/ExportDataUtil'
import { DEFROUNDVALUE, hasBooleanValue, hasValue } from '../../../utils/NumberUtil'
import { arrayOf } from '../../../utils/StoreUtils'
import PluviometryAction from '../../actions/PluviometryAction'
import DtoPluviometerStats from '../../dto/DtoPluviometerStats'
import DtoPluvioMeasures from '../../dto/measures/DtoPluvioMeasures'
import PluviometerDto from '../../dto/PluviometerDto'
import AppStore from '../../../store/AppStore'
import Row from 'components/react/Row'
import { WhiteCard } from 'components/styled/Card'
import { Grid } from '@mui/material'
import ToastrAction from '../../../toastr/actions/ToastrAction'
import { tooltipDatePluvio } from '../../../utils/PluviometryUtils'
import { getLocalStorageJson, setLocalStorageJson } from '../../../utils/FormUtils'
import DtoParametrageDataType from '../../../piezometry/dto/DtoParametrageDataType'
import PluviometerThresholdDto from '../../dto/PluviometerThresholdDto'
import { STATION_TYPE_NAME } from '../../../station/constants/StationConstants'
import SimpleChartTabsPluvio from '../../../components/echart/SimpleChartTabsPluvio'
import {
    AUTO, CUMUL_PERSO_MAX,
    PLUVIO_TAB_DISPLAY_MODES,
    SUM_AUTO,
    SUM_DAY,
    SUM_DECADE,
    SUM_HOUR,
    SUM_MONTH,
    SUM_WEEK,
    SUM_YEAR,
} from '../../constants/PluvioOptionConstant'
import { repeatList } from '../../../utils/ListUtil'
import ChartTabPredData from '../../../station/components/iaeauModels/ChartTabPredData'
import { getExportFormat, getI18nOrLabel } from '../../../utils/StringUtil'
import MultiBand from '../../../components/echart/series/MultiBand'

// TODO ATTENTION ! Composant réutilisé dans plusieurs écrans
const DAY = 'DAY'
class PluviometerChartPanel extends Component {
    constructor(props) {
        super(props)
        this.optionCumulSelected = CUMUL_PERSO_MAX
        this.hoursCumul = 1
        this.state = {
            fullScreen: false,
            minDate: undefined,
            maxDate: moment().valueOf(),
            showGroupedDateDropdown: false,
            pluvioGrouped: SUM_AUTO,
            displayModes: getLocalStorageJson(PLUVIO_TAB_DISPLAY_MODES) ?? {
                auto: true,
                all: false,
                sumHour: false,
                sumDay: false,
                sumWeek: false,
                sumDecade: false,
                sumMonth: false,
                sumYear: false,
                personalizedGrouping: false,
            },
            dataLoaded: false,
            progess: 0,
            chartTab: null,
            isEditMode: false,
            optionDayHourSelected: 'HOUR',
            pluvioEndDataArea: getLocalStorageJson('PLUVIO_END_DATA_AREA') ?? false,
            modelData: {
                predMeasures: [],
                selectedPred: undefined,
                predDate: undefined,
                predStats: [],
            },
        }
    }

    getGroupFunc = label => {
        switch (label) {
            case 'auto': return AUTO
            case 'sumHour': return SUM_HOUR
            case 'sumDay': return SUM_DAY
            case 'sumWeek': return SUM_WEEK
            case 'sumDecade': return SUM_DECADE
            case 'sumMonth': return SUM_MONTH
            case 'sumYear': return SUM_YEAR
            case 'personalizedGrouping': return this.state.displayModes.personalizedGroupingValue
            case 'all': default: return label
        }
    }

    loadData = () => {
        const defaultGroupMode = this.state.minDate && this.state.minDate > moment(this.state.maxDate || moment().valueOf()).subtract('1', 'month').valueOf() ? 'all' : 'MAX'
        const groupModeFunc = keys(this.state.displayModes).find(mode => this.state.displayModes[mode])
        const groupFunc = this.getGroupFunc(groupModeFunc)
        const isAuto = groupFunc === 'auto'
        const nGroupF = isAuto ? defaultGroupMode ?? groupFunc : groupFunc
        const inputs = this.props.pluviometerStatistics.map(stat => ({
            stationId: this.props.id,
            dataType: stat.typeId,
            groupFunc: nGroupF.slice(0, 5) === 'CUMUL' ? nGroupF : [-1, 1, 3].includes(stat.typeId) ? nGroupF : defaultGroupMode,
            chartMode: true,
            startDate: this.state.minDate,
            endDate: this.state.maxDate,
        }))
        this.props.loadPluvioChronicMeasures(inputs, p => this.setState({ progress: p })).then(() => this.setState({ dataLoaded: true }))
    }

    componentDidMount() {
        AppStore.dispatch(PluviometryAction.fetchPluviometer(this.props.id))
        AppStore.dispatch(PluviometryAction.fetchPluviometerMeasuresStats(this.props.id))
        AppStore.dispatch(PluviometryAction.fetchPluviometryDataTypes())
        AppStore.dispatch(PluviometryAction.fetchPluviometerThresholds(this.props.id))
    }

    getExportData = () => {
        const { pluviometerStatistics, pluviometryDataTypes, pluvioMeasures, pluviometer } = this.props
        const isMultiPoint = pluviometerStatistics.some(stat => pluviometerStatistics.some(stat2 => stat.typeId === stat2.typeId && stat.codepoint !== stat2.codepoint))
        const data = pluviometerStatistics.flatMap(stat => {
            const roundValue = pluviometryDataTypes.find(pdt => pdt.id === stat.typeId)?.numberDecimal || DEFROUNDVALUE
            const filteredData = pluvioMeasures.find(obj => obj.dataType === stat.typeId && obj.codepoint !== stat.codepoint)?.measures || []
            return filteredData.map(d => ({
                stationCode: { value: pluviometer.code },
                stationName: { value: pluviometer.name },
                date: { value: getFullDate(d.date), format: 'dd/MM/yyyy HH:mm:ss', cellType: 'date' },
                value: { value: d.value, format: getExportFormat(roundValue), cellType: 'number' },
                type: { value: isMultiPoint ? `${stat.label} - ${stat.namePoint ?? ''}` : stat.label },
            }))
        })

        if (data.length) {
            data[0].headers = ['stationCode', 'stationName', 'date', 'value', 'type']
        }
        return data
    }

    getTooltip() {
        return {
            trigger: 'axis',
            formatter: params => {
                const date = tooltipDatePluvio(params[0].value[0], this.state.pluvioGrouped)
                const paramsOrder = orderBy(params, 'axisIndex', 'asc')
                const paramsFormat = paramsOrder.filter(o => hasValue(o.value[1]) && o.seriesName !== i18n.events).map(o => ({
                    marker: o.marker,
                    seriesName: o.seriesName,
                    value: o.value[1],
                    unit: o.value[2],
                }))
                const result = paramsFormat.map(o => `${o.marker} ${o.seriesName} : ${o.value} ${o.unit}`).join('<br/>')
                return `${date}<br />${result}`
            },
        }
    }

    createLine = (data, title, index, option = {}) => {
        return Line({
            name: title,
            serieId: title,
            xAxisIndex: index,
            yAxisIndex: index,
            data,
            connectNulls: false,
            showSymbol: false,
            ...option,
        })
    }

    getMinDate = () => {
        if (this.state.minDate) {
            return this.state.minDate
        }
        const allFirstMeasures = this.props.pluvioMeasures.flatMap(obj => obj.measures.length ? [obj.measures[0].date] : [])
        if (allFirstMeasures.length) {
            return min(allFirstMeasures)
        }
        return this.props.pluviometerStatistics.length ? minBy(this.props.pluviometerStatistics, 'startDate').startDate : moment().subtract(1, 'years').valueOf()
    }

    changeEndDataArea = () => {
        setLocalStorageJson('PLUVIO_END_DATA_AREA', !this.state.pluvioEndDataArea)
        this.setState({ pluvioEndDataArea: !this.state.pluvioEndDataArea })
    }

    addRain = (series, grids, xAxis, yAxis, gridsHeights, stat, chartMinDate, chartMaxDate) => {
        grids.push({
            top: sum(gridsHeights) + 10,
            right: '2%',
            height: 210,
            left: 60,
        })

        const thresholds = this.props.pluviometerThresholds.filter(t => !isUndefined(t.value) && t.dataType === '1').map(t => ({
            yAxis: t.value,
            symbol: 'none',
            label: {
                show: true,
                position: 'middle',
                formatter: () => t.title ? `${t.title} : ${t.value}` : '',
            },
            lineStyle: {
                normal: {
                    color: t.htmlColor || getThresholdColor(t.color),
                    type: 'dashed',
                },
            },
        }))

        const measuresObj = this.props.pluvioMeasures.find(obj => obj.dataType === 1)
        const dataType = this.props.pluviometryDataTypes.find(v => v.id === 1)
        const unit = dataType?.unit ? `[${dataType.unit}]` : ''
        const data = measuresObj?.measures.map(m => [m.date, m.value, unit, m]) || []

        const barWidth = getBarWidth(data.length)

        const axisLabelObj = getAxisLabelInterval(moment(chartMaxDate), moment(chartMinDate), barWidth)


        const markArea = this.state.pluvioEndDataArea && data.length ? {
            itemStyle: {
                color: 'rgba(128, 128, 128, 0.4)',
            },
            data: [
                [
                    {
                        xAxis: data[data.length-1][0],
                    },
                    {
                        xAxis: moment().valueOf(),
                    },
                ],
                [
                    {
                        xAxis: chartMinDate,
                    },
                    {
                        xAxis: data[0][0],
                    },
                ],
            ],
        } : {}

        // série vide pour les seuils
        series.push(Bar({
            name: stat.label,
            barWidth,
            yAxisIndex: grids.length - 1,
            xAxisIndex: grids.length - 1,
            data: [],
            markLine: { silent: false, data: thresholds },
            areaStyle: {},
            color: 'blue',
            boundaryGap: ['50%', '50%'],
            clip: false,
            barGap: '0%',
            markArea,
        }))

        if (data.length && data.find(m => data[0][3].codepoint !== m[3].codepoint)) { // séries par point de prélèvement
            const colors = repeatList(generateGradient('#0000ff', '#FFF', 5), 10)
            const group = groupBy(data, m => m[3].codepoint)
            Object.keys(group).forEach((key, idx) => {
                series.push(Bar({
                    name: `${stat.label} - ${this.props.pluviometer.link_pointPrels.find(p => p.point === parseInt(key))?.name ?? 'Point inconnu'}`,
                    barWidth,
                    yAxisIndex: grids.length - 1,
                    xAxisIndex: grids.length - 1,
                    data: group[key],
                    areaStyle: {},
                    color: colors[idx],
                    boundaryGap: ['50%', '50%'],
                    clip: false,
                    barGap: '0%',
                }))
            })
        } else {
            series.push(Bar({
                name: stat.label,
                barWidth,
                yAxisIndex: grids.length - 1,
                xAxisIndex: grids.length - 1,
                data,
                areaStyle: {},
                color: 'blue',
                boundaryGap: ['50%', '50%'],
                clip: false,
                barGap: '0%',
            }))
        }

        // données prévisionnelles
        this.getModelSeries(1, grids.length - 1, barWidth).forEach(modelSerie => {
            series.push(modelSerie)
        })

        xAxis.push(Axis({
            type: 'time',
            position: 'bottom',
            min: chartMinDate,
            max: chartMaxDate,
            interval: axisLabelObj.interval,
            minInterval: axisLabelObj.interval,
            maxInterval: axisLabelObj.interval,
            gridIndex: grids.length - 1,
            axisLabel: { show: true, formatter: axisLabelObj.formatter },
            axisLine: { show: true },
            axisTick: { show: true },
            showSplitLine: true,
        }))
        yAxis.push(Axis({
            type: 'value',
            nameLocation: 'middle',
            name: `${stat.label} ${unit}`,
            gridIndex: grids.length - 1,
            nameGap: 40,
            showSplitLine: true,
        }))
        gridsHeights.push(255)
    }

    addOther = (series, grids, xAxis, yAxis, gridsHeights, axisLabelObj, stat, chartMinDate, chartMaxDate, color) => {
        grids.push({
            top: sum(gridsHeights),
            right: '2%',
            height: 150,
            left: 60,
        })

        const thresholds = this.props.pluviometerThresholds.filter(t => !isUndefined(t.value) && parseInt(t.dataType) === stat.typeId).map(t => ({
            yAxis: t.value,
            symbol: 'none',
            label: {
                show: true,
                position: 'middle',
                formatter: () => t.title ? `${t.title} : ${t.value}` : '',
            },
            lineStyle: {
                normal: {
                    color: t.htmlColor ?? getThresholdColorCode(t.color),
                    type: 'dashed',
                },
            },
        }))

        const dataType = this.props.pluviometryDataTypes.find(v => v.id === stat.typeId)
        const unit = dataType?.unit ? `[${dataType.unit}]` : ''
        const data = (this.props.pluvioMeasures.find(obj => obj.dataType === stat.typeId)?.measures || []).map(m => [m.date, m.value, unit, m])
        const barWidth = getBarWidth(data.length)
        const markArea = this.state.pluvioEndDataArea && data.length ? {
            itemStyle: {
                color: 'rgba(128, 128, 128, 0.4)',
            },
            data: [
                [
                    {
                        xAxis: data[data.length-1][0],
                    },
                    {
                        xAxis: moment().valueOf(),
                    },
                ],
                [
                    {
                        xAxis: chartMinDate,
                    },
                    {
                        xAxis: data[0][0],
                    },
                ],
            ],
        } : {}

        // données prévisionnelles
        this.getModelSeries(stat.typeId, grids.length - 1, barWidth).forEach(modelSerie => {
            series.push(modelSerie)
        })

        if (stat.typeId === -1) {
            const [superior, inferior] = partition(data, d => d[1] > 0)
            series.push(Bar({
                name: stat.label,
                barWidth,
                data: superior,
                yAxisIndex: grids.length - 1,
                xAxisIndex: grids.length - 1,
                markLine: { silent: false, data: thresholds },
                areaStyle: {},
                color: '#6a37ff',
                boundaryGap: ['50%', '50%'],
                clip: false,
                barGap: '0%',
                serieId: `additional${stat.typeId}`,
                markArea,
            }))
            series.push(Bar({
                name: stat.label,
                barWidth,
                data: inferior,
                yAxisIndex: grids.length - 1,
                xAxisIndex: grids.length - 1,
                areaStyle: {},
                color: '#ff3312',
                boundaryGap: ['50%', '50%'],
                clip: false,
                barGap: '0%',
                serieId: `additional${stat.typeId}`,
            }))
        } else if (stat.typeId === 3) {
            series.push(Bar({
                name: stat.label,
                barWidth,
                areaStyle: {},
                data,
                markLine: { silent: false, data: thresholds },
                color: '#009dbd',
                clip: false,
                barGap: '0%',
                yAxisIndex: grids.length - 1,
                xAxisIndex: grids.length - 1,
                serieId: `additional${stat.typeId}`,
                markArea,
            }))
        } else {
            // série vide pour les seuils
            series.push(Line({
                name: stat.label,
                connectNulls: false,
                showSymbol: false,
                data: [],
                color,
                markLine: { silent: false, data: thresholds },
                yAxisIndex: grids.length - 1,
                xAxisIndex: grids.length - 1,
                serieId: `additional${stat.typeId}`,
                markArea,
            }))

            if (data.length && data.find(m => data[0][3].codepoint !== m[3].codepoint)) { // séries par point de prélèvement
                const colors = repeatList(generateGradient(color, '#FFF', 5), 10)
                const group = groupBy(data, m => m[3].codepoint)
                Object.keys(group).forEach((key, idx) => {
                    series.push(Line({
                        name: `${stat.label} - ${this.props.pluviometer.link_pointPrels.find(p => p.point === parseInt(key))?.name ?? 'Point inconnu'}`,
                        connectNulls: false,
                        showSymbol: false,
                        data: group[key],
                        color: colors[idx],
                        markLine: { silent: false, data: thresholds },
                        yAxisIndex: grids.length - 1,
                        xAxisIndex: grids.length - 1,
                        serieId: `additional${stat.typeId}`,
                        markArea,
                    }))
                })
            } else {
                series.push(Line({
                    name: stat.label,
                    connectNulls: false,
                    showSymbol: false,
                    data,
                    color,
                    markLine: { silent: false, data: thresholds },
                    yAxisIndex: grids.length - 1,
                    xAxisIndex: grids.length - 1,
                    serieId: `additional${stat.typeId}`,
                    markArea,
                }))
            }
        }

        xAxis.push(Axis({
            type: 'time',
            position: 'bottom',
            min: chartMinDate,
            max: chartMaxDate,
            interval: axisLabelObj.interval,
            gridIndex: grids.length - 1,
            axisLabel: { show: true, formatter: axisLabelObj.formatter },
            axisLine: { show: true },
            axisTick: { show: true },
            showSplitLine: true,
        }))
        yAxis.push(Axis({
            type: 'value',
            nameLocation: 'middle',
            name: `${stat.label} ${unit}`,
            gridIndex: grids.length - 1,
            nameGap: 40,
            showSplitLine: true,
        }))
        gridsHeights.push(190)
    }

    getModelSeries = (typeId, gridIndex, barWidth) => {
        const { pluviometer } = this.props
        const { predMeasures, selectedPred, iaeauModels } = this.state.modelData
        const toEchartMeasure = (v, measure) => ({ value: [measure.date, v, { measure }, pluviometer] })
        // const modelSeries = []
        if (selectedPred && selectedPred[typeId] && predMeasures[typeId]?.length) {
            const pred = selectedPred.idModel ? { ...selectedPred[typeId], model: iaeauModels.find(m => m.idModel === selectedPred[typeId].idModel) } : selectedPred[typeId]
            // if (predDisplayMode === 'default') {
            const allSeries = groupBy(predMeasures[typeId], 'serieName')
            const nbLineSeries = Object.keys(allSeries).filter(key => hasValue(allSeries[key][0].value)).length
            return Object.keys(allSeries).flatMap((serieName, idx) => {
                const orderedMeasures = orderBy(allSeries[serieName], 'date')
                if (hasValue(orderedMeasures[0].doubtMin)) {
                    const lower = {
                        showSymbol: false,
                        color: pred?.model?.color || 'grey',
                        data: orderedMeasures.map(m => toEchartMeasure(m.doubtMin, m)),
                        name: `${pred.source} - ${getI18nOrLabel(serieName)}`,
                        gridIndex,
                        xAxisIndex: gridIndex,
                        yAxisIndex: gridIndex,
                    }
                    const upper = {
                        showSymbol: false,
                        color: pred?.model?.color || 'grey',
                        data: orderedMeasures.map(m => toEchartMeasure(m.doubtMax, m)),
                        name: `${pred.source} - ${getI18nOrLabel(serieName)}`,
                        gridIndex,
                        xAxisIndex: gridIndex,
                        yAxisIndex: gridIndex,
                    }
                    return [MultiBand({ bands: [lower, upper], noSort: true, noGap: true, stack: `${pred.source}${idx}` })]
                }
                const func = typeId === 1 || typeId === 3 ? Bar : Line
                return func({
                    barWidth,
                    data: orderedMeasures.map(m => toEchartMeasure(m.value, m)),
                    name: `${pred.source} - ${getI18nOrLabel(serieName)}`,
                    color: nbLineSeries > 1 ? getColorFromPalette2(idx) : (pred?.model?.color || '#6a37ff'),
                    connectNulls: true,
                    gridIndex,
                    xAxisIndex: gridIndex,
                    yAxisIndex: gridIndex,
                    lineStyle: {
                        normal: {
                            color: nbLineSeries > 1 ? getColorFromPalette2(idx) : (pred?.model?.color || '#6a37ff'),
                            width: pred?.model?.lineWidth || 2,
                            type: pred?.model?.lineType || 'dashed',
                            opacity: pred?.model?.lineOpacity || 1,
                        },
                    },
                    itemStyle: {
                        normal: {
                            color: nbLineSeries > 1 ? getColorFromPalette2(idx) : (pred?.model?.color || '#6a37ff'),
                        },
                    },
                    showSymbol: false,
                })
            })
        }
        return []
    }

    getChart = () => {
        if (!this.state.dataLoaded) {
            return <ProgressCard progress={this.state.progress} />
        }


        const minDate = this.getMinDate()
        const chartMinDate = this.state.chartTab === 'HISTO' ? moment(minDate).startOf('year').valueOf() : minDate
        const maxDate = this.state.maxDate || moment().valueOf()
        const tmpChartMaxDate = this.state.chartTab === 'HISTO' ? moment(maxDate).add(1, 'year').startOf('year').valueOf() : maxDate

        const predMeasuresMaxDate = Object.keys(this.state.modelData?.predMeasures ?? {}).length ?
            [moment(max(Object.keys(this.state.modelData?.predMeasures ?? {}).map(key => last(orderBy(this.state.modelData?.predMeasures[key], 'date')).date))).add(2, 'day').valueOf()] : []
        const chartMaxDate = max([tmpChartMaxDate, ...predMeasuresMaxDate])

        const [series, grids, xAxis, yAxis, gridsHeights] = [[], [], [], [], [25]]
        const axisLabelObj = getAxisLabelInterval(moment(chartMaxDate), moment(chartMinDate))

        const stats = orderBy(this.props.pluviometerStatistics, 'typeId')
        const baseStats = stats.filter(s => s.typeId > 0 && s.typeId <= 3)
        const calulatedStats = stats.filter(s => s.typeId < 0)
        const otherStats = stats.filter(s => s.typeId > 3)
        const orderedStats = uniqBy(orderBy([...baseStats, ...calulatedStats, ...otherStats], ['order', 'typeId'], 'asc'), 'typeId')

        orderedStats.filter(stat => !hasBooleanValue(stat.showData) || stat.showData).forEach((stat, idx) => {
            if (stat.typeId === 1) {
                this.addRain(series, grids, xAxis, yAxis, gridsHeights, stat, chartMinDate, chartMaxDate)
            } else {
                this.addOther(series, grids, xAxis, yAxis, gridsHeights, axisLabelObj, stat, chartMinDate, chartMaxDate, stat.color || getColorFromPalettePluvio(idx))
            }
        })

        const options = {
            series,
            tooltip: this.getTooltip(),
            grid: grids,
            xAxis,
            yAxis,
            axisPointer: {
                link: { xAxisIndex: 'all' },
            },
            setDataZoom: true,
            height: sum(gridsHeights) - 40,
            toolbox: {
                show: true,
                right: '35px',
                showTitle: false,
                itemSize: 18,
                tooltip: {
                    show: true,
                    position: 'bottom',
                },
                feature: {
                    restore: {
                        title: i18n.restore,
                        iconStyle: {
                            borderColor: '#5c5c5c',
                        },
                    },
                    saveAsImage: {
                        title: i18n.export,
                        icon: exportPictureIcon,
                        iconStyle: {
                            borderColor: '#5c5c5c',
                        },
                    },
                    myToolExport: {
                        show: true,
                        title: i18n.excelExport,
                        icon: exportExcelIcon,
                        onclick: () => {
                            exportFile({
                                data: this.getExportData(),
                                exportType: 'xlsx',
                                titleFile: i18n.overview,
                            })
                        },
                        iconStyle: {
                            borderColor: '#5c5c5c',
                        },
                    },
                    myToolFullScreen: {
                        show: true,
                        title: i18n.fullScreen,
                        icon: fullScreenIcon,
                        onclick: () => this.setState({ fullScreen: !this.state.fullScreen }),
                        iconStyle: {
                            borderColor: '#5c5c5c',
                        },
                    },
                    myToolFullScreen2: {
                        show: true,
                        title: i18n.noData,
                        icon: noDataSymbol,
                        onclick: this.changeEndDataArea,
                        iconStyle: {
                            borderColor: '#5c5c5c',
                        },
                    },
                },
            },
            legend: null,
        }
        return (
            <EChart options={options} id='pluviometryDashboardChart' scrollable={this.state.fullScreen} />
        )
    }

    changeGroupedDate = (group) => {
        this.setState({ pluvioGrouped: group, showGroupedDateDropdown: false, dataLoaded: false }, this.loadData)
    }

    changeGroupedDatePerzonalize = () => {
        const day = `${this.optionCumulSelected}_${this.hoursCumul * 24}`
        const hour = `${this.optionCumulSelected}_${this.hoursCumul}`
        this.setState({
            pluvioGrouped: this.state.optionDayHourSelected === DAY ? day : hour,
            dataLoaded: false,
            showGroupedDateDropdown: false,
        }, this.loadData)
    }

    personalizedGrouping = () => {
        this.setState({ pluvioGrouped: 'CUMUL' })
    }

    render() {
        if (!this.props.pluviometerStatistics.length) {
            return null
        }

        // calcul du cumul des données de prévision
        const groupModeFunc = keys(this.state.displayModes).find(mode => this.state.displayModes[mode])
        const cumulPluvioPred = (() => {
            if (groupModeFunc?.includes('sum')) {
                return groupModeFunc.toUpperCase().replace('SUM', 'SUM_')
            }
            if (groupModeFunc === 'auto') {
                if (this.state.minDate && this.state.minDate > moment(this.state.maxDate || moment().valueOf()).subtract('7', 'days').valueOf()) { // - de 7 jours -> cumul horaire
                    return 'SUM_HOUR'
                }
                if (this.state.minDate && this.state.minDate > moment(this.state.maxDate || moment().valueOf()).subtract('1', 'month').valueOf()) { // - de 7 jours -> cumul horaire
                    return 'SUM_DAY'
                }
                return 'SUM_WEEK'
            }
            return 'SUM_DAY'
        })()
        return (
            <WhiteCard round noBoxShadow={this.props.noBoxShadow}>
                <Row className={this.state.fullScreen ? 'fullscreen-chart' : ''}>
                    <div className='col row no-margin padding-top-1' style={{ paddingLeft: 48 }}>
                        <div>
                            <ChartTabs
                                stationType={STATION_TYPE_NAME.pluviometry}
                                onChangeDate={dates => this.setState({ ...dates, dataLoaded: false }, this.loadData)}
                            />
                            <Grid sx={{ left: '37rem', position: 'relative' }}>
                                <Grid container sx={{ alignItems: 'center', justifyContent: 'center' }}>
                                    <Grid item>
                                        <ChartTabPredData
                                            stationType='pluviometry'
                                            id={this.props.id}
                                            setParentModelData={modelData => this.setState({ modelData })}
                                            cumulPluvio={cumulPluvioPred}
                                        />
                                    </Grid>
                                    <Grid item sx={{ paddingLeft: '10px' }}>
                                        <SimpleChartTabsPluvio
                                            displayModes={ this.state.displayModes }
                                            displayModesUniqChoise={ true }
                                            changeParent={ v => this.setState({
                                                displayModes: v,
                                                dataLoaded: false,
                                                showGroupedDateDropdown: false,
                                            }, this.loadData) }
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                    <div className='col s12 row no-margin'>
                        { this.getChart() }
                    </div>
                </Row>
            </WhiteCard>
        )
    }
}

PluviometerChartPanel.propTypes = {
    id: PropTypes.number,
    pluviometer: PropTypes.instanceOf(PluviometerDto),
    loadPluvioChronicMeasures: PropTypes.func,
    pluviometerStatistics: arrayOf(DtoPluviometerStats),
    pluvioMeasures: arrayOf(DtoPluvioMeasures),
    pluviometryDataTypes: arrayOf(DtoParametrageDataType),
    toastrWarning: PropTypes.func,
    noBoxShadow: PropTypes.bool,
    pluviometerThresholds: PropTypes.arrayOf(PropTypes.instanceOf(PluviometerThresholdDto)),
}

const mapStateToProps = store => ({
    pluviometer: store.PluviometryReducer.pluviometer,
    pluviometerStatistics: store.PluviometryReducer.pluviometerStatistics,
    pluvioMeasures: store.PluviometryReducer.pluvioMeasures,
    pluviometryDataTypes: store.PluviometryReducer.pluviometryDataTypes,
    pluviometerThresholds: store.PluviometryReducer.pluviometerThresholds,
})

const mapDispatchToProps = {
    loadPluvioChronicMeasures: PluviometryAction.loadPluvioChronicMeasures,
    toastrWarning: ToastrAction.warning,
}

export default connect(mapStateToProps, mapDispatchToProps)(PluviometerChartPanel)