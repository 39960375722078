import ActionComponent from 'components/ActionComponent'
import Button from 'components/forms/Button'
import ApplicationConf from 'conf/ApplicationConf'
import { push } from 'connected-react-router'
import HomeAction from 'home/actions/HomeAction'
import { find, isEqual, omit } from 'lodash'
import moment from 'moment'
import 'moment/locale/fr'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import MoveMapPanel from 'referencial/components/map/MoveMapPanel'
import i18n from 'simple-react-i18n'
import AppStore from 'store/AppStore'
import ToastrAction from 'toastr/actions/ToastrAction'
import { canFetchLocation, getAddressFormatted } from 'utils/mapUtils/CoordinateUtils'
import { v4 as uuidv4 } from 'uuid'
import UserAction from '../../../../administration/components/user/actions/UserAction'
import UserDto from '../../../../administration/components/user/dto/UserDto'
import SieauParameterDto from '../../../../administration/dto/SieauParameterDto'
import Card from '../../../../components/card/Card'
import Checkbox from '../../../../components/forms/Checkbox'
import DatePicker from '../../../../components/forms/DatePicker'
import Input from '../../../../components/forms/Input'
import NumberField from '../../../../components/forms/NumberField'
import Select from '../../../../components/forms/Select'
import SimpleDatePicker from '../../../../components/forms/SimpleDatePicker'
import Textarea from '../../../../components/forms/Textarea'
import Row from '../../../../components/react/Row'
import SieauAction from '../../../../components/sieau/SieauAction'
import {
    PATH_REFERENCIAL,
    PATH_REFERENCIAL_CONTACT,
    PATH_REFERENCIAL_CONTACT_LINK,
    PATH_REFERENCIAL_CONTACT_NEW,
} from '../../../../home/constants/RouteConstants'
import { SANDRE } from '../../../constants/ReferencialConstants'
import DtoFunction from '../../../../station/dto/DtoFunction'
import { updateMaterialize } from '../../../../utils/MaterializeUtils'
import { hasValue } from '../../../../utils/NumberUtil'
import { getLogin, getUser } from '../../../../utils/SettingUtils'
import { arrayOf, getMapStateToProps, getPropTypes, getSandreList } from '../../../../utils/StoreUtils'
import ReferencialAction from '../../../action/ReferencialAction'
import DtoSandreCode from '../../../dto/DtoSandreCode'
import { getReferencialComponent } from '../../../util/ReferencialUtils'
import CityAction from '../../city/actions/CityAction'
import CityCedexDto from '../../city/dto/CityCedexDto'
import DtoCountry from '../../city/dto/DtoCountry'
import ContributorAction from '../../contributor/actions/ContributorAction'
import ContributorDto from '../../contributor/dto/ContributorDto'
import CountryAction from '../../country/actions/CountryAction'
import DeleteModal from '../../DeleteModal'
import ReplaceModal from '../../ReplaceModal'
import ContactAction from '../actions/ContactAction'
import ContactItem from '../dto/ContactDto'
import { ContactActionConstant } from '../reducers/ContactReducer'
import { Grid } from '@mui/material'

moment.locale('fr')

const propsToFetch = {
    cities: false,
}

class ContactApp extends ActionComponent {
    constructor(props) {
        super(props)
        this.state = {
            contact: new ContactItem({}),
            isEditMode: false,
            editMap: false,
        }
    }

    onChangeContact = (value) => {
        this.setState(({ contact }) => ({
            contact: {
                ...contact,
                ...value,
            },
        }))
    }

    onChangeContactCP = (value) => {
        const { cities } = this.props
        const citiesFiltered = cities.filter((c) => c.link_postalCode.find((cp) => cp.startsWith(value)))
        if (citiesFiltered.length === 1) {
            const cityCode = citiesFiltered[0].id
            this.onChangeContact({ postalBox: value, cityCode })
        } else {
            this.onChangeContact({ postalBox: value })
        }
    }

    onChangeContactCity = (value) => {
        const { cities } = this.props
        const city = cities.find((c) => c.id === value) || {}
        if (city.link_postalCode && city.link_postalCode.length) {
            this.onChangeContact({ postalBox: city.link_postalCode[0], cityCode: value })
        } else {
            this.onChangeContact({ cityCode: value })
        }
    }


    onChangeNewsLetter = (value) => {
        const { contact } = this.state
        if (value) {
            this.setState({
                contact: {
                    ...contact,
                    noNewsLetterDate: moment().valueOf(),
                    noNewLetterLogin: getLogin(),
                },
            })
        } else {
            this.setState({
                contact: {
                    ...contact,
                    noNewsLetterDate: null,
                    noNewLetterLogin: '',
                },
            })
        }
    }

    setReadMode = () => {
        this.setState({ isEditMode: false })
    }

    setEditMode = () => {
        this.setState({ isEditMode: true })
    }

    getRequiredFunction = (actions) => {
        AppStore.dispatch(actions)
        this.setReadMode()
    }

    componentWillUnmount() {
        super.componentWillUnmount()
        this.props.resetContact()
    }

    componentWillMount() {
        if (this.props.match.params.code !== 'new') {
            this.props.fetchContact(this.props.match.params.code)
            this.setReadMode()
        } else {
            this.setEditMode()
        }
        AppStore.dispatch(HomeAction.setHelpLink('', ''))
        this.toNavbarContact(this.props)
    }

    componentDidMount() {
        const { contributors, countries, sandreCodes, users, cityCedex, cities } = this.props
        this.toNavbarContact()

        if (!contributors.length) {
            this.props.fetchContributors()
        }
        if (!countries.length) {
            this.props.fetchCountries()
        }
        if (!sandreCodes.length) {
            this.props.fetchSandreCodes()
        }
        if (!users.length) {
            this.props.fetchUsers()
        }
        if (!cities.length) {
            this.props.fetchCities()
        }
        if (!cityCedex.length) {
            this.props.fetchCityCedex()
        }
    }

    componentDidUpdate() {
        this.toNavbarContact()
    }

    componentWillReceiveProps(nextProps) {
        const { match: { params }, contact, contacts, isEditMode } = this.props
        if (nextProps.match.params.code !== params.code) {
            this.props.fetchContact(nextProps.match.params.code)
        } else if (nextProps.match.params.code === 'new') {
            this.props.setTitle([
                {
                    title: i18n.referencials,
                    href: PATH_REFERENCIAL,
                },
                {
                    title: i18n.contacts,
                    href: PATH_REFERENCIAL_CONTACT,
                },
                {
                    title: i18n.new,
                    href: PATH_REFERENCIAL_CONTACT_NEW,
                },
            ])
        } else {
            if (!isEqual(nextProps.contact, contact)) {
                this.props.setTitle([
                    {
                        title: i18n.referencials,
                        href: PATH_REFERENCIAL,
                    },
                    {
                        title: i18n.contacts,
                        href: PATH_REFERENCIAL_CONTACT,
                    },
                    {
                        title: nextProps.contact.name ? nextProps.contact.name : `[${params.code}]`,
                        href: PATH_REFERENCIAL_CONTACT_LINK + params.code,
                    },
                ])
                this.setState({ contact: nextProps.contact })
            }
            if (!isEqual(isEditMode, nextProps.isEditMode) || !isEqual(contact.code, nextProps.contact.code) || !isEqual(contacts, nextProps.contacts)) {
                this.toNavbarContact(nextProps)
            }
        }
    }

    onReplace = newCode => {
        const { contact } = this.state
        this.props.replaceContact(contact.code, newCode.code)
    }

    toNavbarContact(props = this.props) {
        const { match: { params } } = props
        const actions = (() => {
            if (props.match.params.code === 'new' && (!props.contact || !props.contact.code)) {
                return {
                    save: () => {
                        this.getRequiredFunction(ContactAction.createContact(this.state.contact))
                        this.props.fetchContacts()
                    },
                    cancel: () => {
                        this.props.push(PATH_REFERENCIAL_CONTACT)
                        this.setReadMode()
                    },
                }
            }
            if (this.state.isEditMode) {
                return {
                    save: () => {
                        this.getRequiredFunction(ContactAction.updateContact(this.state.contact))
                        this.props.fetchContacts()
                    },
                    cancel: () => {
                        this.setState({ contact: props.contact })
                        this.setReadMode()
                    },
                }
            }
            return {
                edit: () => {
                    this.setEditMode()
                },
                deleteCheck: () => {
                    this.props.fetchCheckDelete(ApplicationConf.referencial.contactCountDelete(params.code))
                    const id = uuidv4()
                    const popup = {
                        id,
                        content: <DeleteModal onDelete={ () => this.props.deleteContact(this.state.contact.code) } />,
                        dismissible: false,
                    }
                    this.props.setPopup(popup)
                },
                replace: () => {
                    this.props.fetchCheckDelete(ApplicationConf.referencial.contactCountDelete(params.code))
                    const id = uuidv4()
                    const popup = {
                        id,
                        content: <ReplaceModal
                            elements={ props.contacts }
                            title={`${i18n.contact} [${this.state.contact.code}]`}
                            label={i18n.contact}
                            onReplace={ this.onReplace }
                        />,
                        dismissible: false,
                    }
                    this.props.setPopup(popup)
                },
            }
        })()
        if (getUser().consultant === '1') {
            this.setActions(omit(actions, ['save', 'edit', 'deleteCheck', 'replace']))
        } else {
            this.setActions(actions)
        }
        updateMaterialize()
    }

    handleChangeContact = (contact) => {
        this.setState({ contact })
    }

    geolocateMe = () => {
        const { contact } = this.state
        if (!contact.address) {
            const city = this.props.cities.find(c => c.id === contact.cityCode)
            this.onChangeContact({ x: city.x, y: city.y, projection: city.projection })
        } else {
            const addressFormatted = getAddressFormatted(contact, 'address')
            if (canFetchLocation(addressFormatted.address)) {
                this.props.error(i18n.addressFormatNotRespected)
            } else {
                this.props.fetchLocation(addressFormatted).then((location) => {
                    this.onChangeContact({ x: location.x, y: location.y, projection: 16 })
                })
            }
        }
    }

    render() {
        const { cities, users, sandreCodes, countries } = this.props
        const { contact, isEditMode, editMap } = this.state
        const disabled = { active: isEditMode, disabled: !isEditMode }

        const isEditMap = isEditMode && editMap

        const userAccountAttached = (() => {
            const findUser = find(users, (b) => {
                return b.contactCode === contact.code
            })
            return findUser && <i className='material-icons person contactUserIcon' style={{ verticalAlign: 'middle' }}>person</i>
        })()

        return (
            <div className='row no-margin'>
                <div id='file' className='col s12'>
                    <Card className='margin-top-1 padding-bottom-1' noMargin={false}>
                        <Row>
                            { userAccountAttached &&
                                <div className='margin-left-1 padding-top-1' style={{ height: '40px' }}>
                                    { userAccountAttached } { contact.name }
                                </div>
                            }
                            <Row className='col m12 l6'>
                                <Card title={i18n.description} className='padding-bottom-1'>
                                    <Row className='padding-top-1'>
                                        <Input
                                            col={ 3 }
                                            title={ i18n.title }
                                            value={ contact.title }
                                            onChange={ v => this.onChangeContact({ title: v }) }
                                            maxLength={ 50 }
                                            { ...disabled }
                                        />
                                        <Input
                                            col={ 4 }
                                            title={ i18n.name }
                                            value={ contact.name }
                                            onChange={ v => this.onChangeContact({ name: v }) }
                                            maxLength={ 50 }
                                            { ...disabled }
                                        />
                                        <Input
                                            col={ 5 }
                                            title={ i18n.firstname }
                                            value={ contact.firstname }
                                            onChange={ v => this.onChangeContact({ firstname: v }) }
                                            maxLength={ 100 }
                                            { ...disabled }
                                        />
                                    </Row>
                                    <Row className='padding-top-1'>
                                        <SimpleDatePicker
                                            id='birthdate'
                                            col={3}
                                            label={i18n.birthdate}
                                            value={contact.birthdate}
                                            onChange={(v) => this.onChangeContact({ birthdate: v })}
                                            {...disabled}
                                        />
                                        <Input
                                            col={ 9 }
                                            title={ i18n.placeOfBirth }
                                            value={ contact.placeOfBirth }
                                            onChange={ v => this.onChangeContact({ placeOfBirth: v }) }
                                            maxLength={ 100 }
                                            { ...disabled }
                                        />
                                    </Row>
                                    <Row className='padding-top-1'>
                                        <Input
                                            col={ 7 }
                                            title={ i18n.socialReason }
                                            value={ contact.socialReason }
                                            onChange={ v => this.onChangeContact({ socialReason: v }) }
                                            maxLength={ 255 }
                                            { ...disabled }
                                        />
                                        <Select
                                            col={5}
                                            label={i18n.functions}
                                            value={ contact.contactFunction }
                                            options={getSandreList(sandreCodes, SANDRE.CONTACTS_FONCTIONS)}
                                            onChange={v => this.onChangeContact({ contactFunction: v })}
                                            { ...disabled }
                                        />
                                    </Row>
                                    <Row className='padding-top-1'>
                                        <Textarea
                                            col={ 12 }
                                            title={ i18n.comment }
                                            value={ contact.comment }
                                            onChange={ v => this.onChangeContact({ comment: v }) }
                                            { ...disabled }
                                        />
                                    </Row>
                                    <Row className='padding-top-1'>
                                        <Checkbox
                                            col={4}
                                            label={i18n.noNewsLetter}
                                            checked={hasValue(contact.noNewLetterLogin)}
                                            onChange={this.onChangeNewsLetter}
                                            { ...disabled }
                                        />
                                        <Row displayIf={hasValue(contact.noNewLetterLogin)}>
                                            <Input
                                                col={ 4 }
                                                title={ i18n.login }
                                                value={ contact.noNewLetterLogin }
                                                maxLength={ 50 }
                                                readMode={true}
                                                disabled={true}
                                            />
                                            <DatePicker
                                                col={4}
                                                title={i18n.noNewsLetterDate}
                                                value={contact.noNewsLetterDate}
                                                readMode={true}
                                                disabled={true}
                                            />
                                        </Row>
                                    </Row>
                                </Card>
                                <Card title={i18n.localisation} className='margin-top-1' noMargin={false}>
                                    <Row className='padding-top-1'>
                                        <Textarea
                                            col={ 12 }
                                            title={ i18n.address }
                                            placeholder={ i18n.exampleAddressFormat }
                                            value={ contact.address }
                                            onChange={ v => this.onChangeContact({ address: v }) }
                                            maxLength={ 255 }
                                            { ...disabled }
                                        />
                                    </Row>
                                    <Row className='padding-top-1'>
                                        <Textarea
                                            col={ 12 }
                                            title={ i18n.additionnalAddress }
                                            value={ contact.additionalAddress }
                                            onChange={ v => this.onChangeContact({ additionalAddress: v }) }
                                            maxLength={ 255 }
                                            { ...disabled }
                                        />
                                    </Row>
                                    <Row className='padding-top-1'>
                                        <Input
                                            col={ 3 }
                                            title={ i18n.postalCode }
                                            value={ contact.postalBox }
                                            onChange={ v => this.onChangeContactCP(v) }
                                            maxLength={ 5 }
                                            { ...disabled }
                                        />
                                        <Select
                                            col={ 9 }
                                            label={ i18n.city }
                                            value={ contact.cityCode }
                                            options={ getReferencialComponent(cities) }
                                            onChange={ (id) => this.onChangeContactCity(id) }
                                            { ...disabled }
                                            clearFunction
                                        />
                                    </Row>
                                    <Row className='padding-top-1'>
                                        <Input
                                            col={ 12 }
                                            title={ i18n.usualCityName }
                                            value={ contact.usualCityName }
                                            onChange={ v => this.onChangeContact({ usualCityName: v }) }
                                            maxLength={ 1000 }
                                            { ...disabled }
                                        />
                                    </Row>
                                    <Row className='padding-top-1'>
                                        <Input
                                            col={ 3 }
                                            title={ i18n.cedexCode }
                                            value={ contact.cedex }
                                            onChange={ v => this.onChangeContact({ cedex: v }) }
                                            maxLength={ 5 }
                                            { ...disabled }
                                        />
                                        <Select
                                            col={ 9 }
                                            label={ i18n.country }
                                            value={ contact.countryCode }
                                            options={ countries }
                                            onChange={ (countryCode) => this.onChangeContact({ countryCode }) }
                                            maxLength={ 30 }
                                            keyValue='countryCode'
                                            keyLabel='name'
                                            { ...disabled }
                                        />
                                    </Row>
                                    <Row className='padding-top-1'>
                                        <NumberField
                                            col={ 6 }
                                            title={ i18n.x }
                                            value={ contact.x }
                                            onChange={ v => this.onChangeContact({ x: v }) }
                                            floatValue= { true }
                                            { ...disabled }
                                        />
                                        <NumberField
                                            col={ 6 }
                                            title={ i18n.y }
                                            value={ contact.y }
                                            onChange={ v => this.onChangeContact({ y: v }) }
                                            floatValue= { true }
                                            { ...disabled }
                                        />
                                    </Row>
                                    <Grid container justifyContent={'center'} sx={{
                                        padding: '10px 0',
                                    }}>
                                        <Button
                                            title={i18n.estimateLocation}
                                            onClick={ this.geolocateMe }
                                            disabled={!(isEditMode && contact.cityCode)}
                                        />
                                    </Grid>
                                </Card>
                            </Row>
                            <Row className='col m12 l6'>
                                <Card title={i18n.coordinates} className='padding-bottom-1'>
                                    <Row className='padding-top-1'>
                                        <Input
                                            col={ 4 }
                                            title={ i18n.phoneTel }
                                            value={ contact.phoneTel }
                                            onChange={ v => this.onChangeContact({ phoneTel: v }) }
                                            maxLength={ 20 }
                                            { ...disabled }
                                        />
                                        <Input
                                            col={ 4 }
                                            title={ i18n.phoneTelSecond }
                                            value={ contact.phoneTelSecond }
                                            onChange={ v => this.onChangeContact({ phoneTelSecond: v }) }
                                            maxLength={ 50 } { ...disabled }
                                        />
                                        <Input
                                            col={ 4 }
                                            title={ i18n.fax }
                                            value={ contact.fax }
                                            onChange={ v => this.onChangeContact({ fax: v }) }
                                            maxLength={ 50 }
                                            { ...disabled }
                                        />
                                    </Row>
                                    <Row className='padding-top-1'>
                                        <Input
                                            col={ 6 }
                                            title={ i18n.desktopTel }
                                            value={ contact.desktopTel }
                                            onChange={ v => this.onChangeContact({ desktopTel: v }) }
                                            maxLength={ 24 }
                                            { ...disabled }
                                        />
                                        <Input
                                            col={ 6 }
                                            title={ i18n.mobile }
                                            value={ contact.mobile }
                                            onChange={ v => this.onChangeContact({ mobile: v }) }
                                            maxLength={ 24 }
                                            { ...disabled }
                                        />
                                    </Row>
                                    <Row className='padding-top-1'>
                                        <Input
                                            col={ 12 }
                                            title={ i18n.email }
                                            value={ contact.email }
                                            onChange={ v => this.onChangeContact({ email: v }) }
                                            maxLength={ 255 }
                                            { ...disabled }
                                        />
                                    </Row>
                                    <Row className='padding-top-1'>
                                        <Input
                                            col={ 12 }
                                            title={ i18n.bankCode }
                                            value={ contact.bankCode }
                                            onChange={ v => this.onChangeContact({ bankCode: v }) }
                                            maxLength={ 50 }
                                            { ...disabled }
                                        />
                                    </Row>
                                </Card>
                                <Card title={i18n.codification} className='margin-top-1 padding-bottom-1' noMargin={false}>
                                    <Row className='padding-top-1'>
                                        <Input
                                            col={ 6 }
                                            title={ i18n.login }
                                            value={ contact.identifier }
                                            onChange={ v => this.onChangeContact({ identifier: v }) }
                                            maxLength={ 50 }
                                            { ...disabled }
                                        />
                                    </Row>
                                </Card>
                                <Card
                                    title={i18n.map}
                                    className='margin-top-1 padding-bottom-1'
                                    noMargin={false}
                                    actions={ isEditMode ? [{ iconName: !editMap ? 'edit' : 'cancel', onClick: () => this.setState({ editMap: !editMap }) }] : [] }
                                >
                                    <Row className='padding-1 no-padding-bottom'>
                                        <MoveMapPanel
                                            station={ contact }
                                            type={ 'contact' }
                                            editMap={ isEditMap }
                                            handleChangeValue={ (newContact) => this.handleChangeContact(newContact) }
                                        />
                                    </Row>
                                </Card>
                            </Row>
                        </Row>
                    </Card>
                </div>
            </div>
        )
    }
}

ContactApp.propTypes = getPropTypes(propsToFetch, {
    params: PropTypes.shape({
        code: PropTypes.string,
    }),
    contact: PropTypes.instanceOf(ContactItem),
    getLink: PropTypes.func,
    changeEditMode: PropTypes.func,
    isEditMode: PropTypes.bool,
    cityCedex: PropTypes.arrayOf(PropTypes.instanceOf(CityCedexDto)),
    contributors: arrayOf(ContributorDto),
    functions: PropTypes.arrayOf(PropTypes.instanceOf(DtoFunction)),
    sandreCodes: arrayOf(DtoSandreCode),
    users: arrayOf(UserDto),
    applicationSettings: PropTypes.arrayOf(PropTypes.instanceOf(SieauParameterDto)),
    countries: PropTypes.arrayOf(PropTypes.instanceOf(DtoCountry)),
    fetchContributors: PropTypes.func,
    fetchSandreCodes: PropTypes.func,
    fetchUsers: PropTypes.func,
    fetchCountries: PropTypes.func,
    fetchCities: PropTypes.func,
    fetchCityCedex: PropTypes.func,
    fetchContact: PropTypes.func,
    replaceContact: PropTypes.func,
    resetContact: PropTypes.func,
    deleteContact: PropTypes.func,
    setTitle: PropTypes.func,
    fetchCheckDelete: PropTypes.func,
    setPopup: PropTypes.func,
    fetchLocation: PropTypes.func,
    error: PropTypes.func,
    fetchContacts: PropTypes.func,
})

const mapStateToProps = store => getMapStateToProps(propsToFetch, {
    contributors: store.ContributorReducer.contributors,
    contact: store.ContactReducer.contact,
    contacts: store.ContactReducer.contacts,
    cityCedex: store.CityReducer.cityCedex,
    functions: store.StationReducer.functions,
    sandreCodes: store.ReferencialReducer.sandreCodes,
    users: store.UserReducer.users,
    applicationSettings: store.AdministrationReducer.applicationSettings,
    countries: store.CountryReducer.countries,
})

const mapDispatchToProps = {
    fetchContributors: ContributorAction.fetchContributors,
    fetchSandreCodes: ReferencialAction.fetchSandreCodes,
    fetchUsers: UserAction.fetchUsers,
    fetchCountries: CountryAction.fetchCountries,
    fetchCities: CityAction.fetchCities,
    fetchCityCedex: CityAction.fetchCityCedex,
    fetchContact: ContactAction.fetchContact,
    replaceContact: ContactAction.replaceContact,
    resetContact: ContactActionConstant.resetContact,
    deleteContact: ContactAction.deleteContact,
    setTitle: HomeAction.setTitle,
    fetchCheckDelete: ReferencialAction.fetchCheckDelete,
    setPopup: SieauAction.setPopup,
    fetchLocation: ReferencialAction.fetchLocation,
    error: ToastrAction.error,
    fetchContacts: ContactAction.fetchContacts,
    push,
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactApp)
