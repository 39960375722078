import React, { useEffect, useMemo, useRef, useState } from 'react'
import i18n from 'simple-react-i18n'
import echarts from 'echarts/lib/echarts'
import ReactECharts from 'echarts-for-react'
import { groupBy, isNil, keys, max, round, uniqBy } from 'lodash'
import { colorGradients, getUsedValues } from 'catchment/utils/CatchmentUtil'
import { LAST_VALUE } from 'catchment/constants/CatchmentConstant'
import { shallowEqual, useSelector } from 'react-redux'
import useListIndexed from 'utils/customHook/useListIndexed'
import { getI18nOrLabel } from 'utils/StringUtil'
import DtoAnalysisLight from 'quality/dto/analyse/DtoAnalysisLight'
import PropTypes from 'prop-types'
import { exportExcelIcon, exportPictureIcon } from 'components/echart/EChartUtils'
import { getDate } from 'utils/DateUtil'
import { exportFile } from 'utils/ExportDataUtil'
import ExportFileModal from 'components/modal/ExportFileModal'
import useBoolean from 'utils/customHook/useBoolean'
import useApplicationSetting, { listStringParser } from 'utils/customHook/useApplicationSetting'
import useFetchAnalysis from 'utils/customHook/useFetchAnalysis'
import useFetchSumAnalysis from 'catchment/utils/useFetchSumAnalysis'
import CircularProgressWithLabel from 'components/progress/CircularProgressWithLabel'
import CatchmentAction from 'catchment/actions/CatchmentAction'
import useAbortController from 'utils/customHook/useAbortController'

const ExportModal = ({
    isOpen = false,
    close = () => { },

    analysis = [],
    exportName = '',
}) => {
    const {
        parameters,
        units,
    } = useSelector(store => ({
        parameters: store.ParameterReducer.parameters,
        units: store.UnitReducer.units,
    }))

    const parametersIndexed = useListIndexed(parameters, 'code')
    const unitsIndexed = useListIndexed(units)

    const onExport = ext => {
        const exportData = analysis.map(({ parameter, sampleDate, result, unit }) => ({
            date: { value: getDate(sampleDate), format: 'dd/MM/yyyy', cellType: 'date' },
            parameterCode: parameter,
            parameter: parametersIndexed[parameter]?.name ?? '',
            value: { value: result, format: '0.00000', cellType: 'number' },
            codeUnit: unit,
            unit: unitsIndexed[unit]?.symbol ?? '',
        }))
        exportFile({
            data: exportData.length ? [
                {
                    ...exportData[0],
                    headers: keys(exportData[0]),
                },
                ...exportData.slice(1),
            ] : [],
            exportType: ext,
            titleFile: exportName,
        }, true)
    }

    return (
        <ExportFileModal
            open={isOpen}
            onClose={close}
            maxWidth='md'
            data={[
                {
                    name: i18n.resultsTable,
                    formats: [{
                        type: i18n.excelFile,
                        callback: () => onExport('xlsx'),
                    }, {
                        type: i18n.csvFile,
                        callback: () => onExport('csv'),
                    }],
                },
            ]}
        />
    )
}

ExportModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    close: PropTypes.func.isRequired,
    analysis: PropTypes.arrayOf(PropTypes.instanceOf(DtoAnalysisLight)).isRequired,
    exportName: PropTypes.string.isRequired,
}

const GraphicPercent = ({
    analysis = [],
    thresholds = [],
    title = '',
    criteria = LAST_VALUE,
    colors = colorGradients[0],
}) => {
    let echartRef = useRef(undefined)
    let displayToolbox = useRef(false)

    const {
        value: isExportOpen,
        setTrue: openExport,
        setFalse: closeExport,
    } = useBoolean(false)

    const {
        settings,
    } = useSelector(store => ({
        settings: store.AdministrationReducer.settings,
    }), shallowEqual)

    const formattedThresholds = [Number.NEGATIVE_INFINITY, ...thresholds]
    const usedValues = getUsedValues(analysis, criteria, settings).map(t => t.value)
    const groupAnalysis = groupBy(usedValues, v => max([0, 1, 2, 3, 4].filter(nb => v >= formattedThresholds[nb])))

    const getBar = (key, group = [], color) => {
        const percentage = group.length && usedValues.length ? group.length / usedValues.length * 100 : 0
        return {
            value: [percentage, key],
            itemStyle: {
                color,
            },
            label: {
                show: true,
                position: 'right',
                color: '#000',
                formatter: () => `${round(percentage, 1)} %`,
            },
        }
    }

    const getToolbox = () => ({
        top: '4%',
        right: '35px',
        showTitle: false,
        itemSize: 18,
        tooltip: {
            show: true,
            position: 'bottom',
        },
        feature: {
            saveAsImage: {
                show: displayToolbox.current,
                title: i18n.pictureExport,
                icon: exportPictureIcon,
                name: title,
            },
            myToolExport: {
                show: displayToolbox.current,
                title: i18n.excelExport,
                icon: exportExcelIcon,
                onclick: openExport,
            },
        },
    })

    const options = {
        title: {
            text: title,
            left: 'center',
            top: '10',
        },
        series: [{
            type: 'bar',
            data: [
                getBar('0', groupAnalysis['0'], colors[0]),
                getBar('1', groupAnalysis['1'], colors[1]),
                getBar('2', groupAnalysis['2'], colors[2]),
                getBar('3', groupAnalysis['3'], colors[3]),
                getBar('4', groupAnalysis['4'], colors[4]),
            ],
        }],
        xAxis: [{
            type: 'value',
            name: i18n.nbCatchment,
            nameLocation: 'middle',
            nameGap: 25,
        }],
        yAxis: [{
            type: 'category',
            data: ['0', '1', '2', '3', '4'],
            axisLabel: {
                formatter: v => {
                    switch (v) {
                        case '0': return `< ${thresholds[v]}`
                        case '1':
                        case '2':
                        case '3': return `>= ${thresholds[v - 1]} et < ${thresholds[v]}`
                        case '4': return `> ${thresholds[v - 1]}`
                        default: return i18n.noData
                    }
                },
            },
        }],
        grid: {
            top: '50',
            left: '0',
            right: '50',
            bottom: '80',
            containLabel: true,
            height: 200,
        },
        toolbox: getToolbox(),
    }
    return (
        <div
            onMouseOver={() => {
                displayToolbox.current = true
                echartRef.current?.getEchartsInstance().setOption({
                    toolbox: getToolbox(),
                })
            }}
            onMouseOut={() => {
                displayToolbox.current = false
                echartRef.current?.getEchartsInstance().setOption({
                    toolbox: getToolbox(),
                })
            }}
        >
            <ReactECharts
                echarts={echarts}
                option={options}
                notMerge={true}
                lazyUpdate={true}
                ref={e => {
                    echartRef.current = e
                }}
            />
            <ExportModal
                isOpen={isExportOpen}
                close={closeExport}
                analysis={analysis}
                exportName={title}
            />
        </div>
    )
}

GraphicPercent.propTypes = {
    analysis: PropTypes.arrayOf(PropTypes.instanceOf(DtoAnalysisLight)),
    thresholds: PropTypes.arrayOf(PropTypes.number),
    title: PropTypes.string,
    criteria: PropTypes.string,
    colors: PropTypes.arrayOf(PropTypes.string),
}

const ParameterGraphicPercent = ({
    parameter,
    ids = [],
    filters = {},
    colors = colorGradients[0],
    thresholds = [0.01, 0.1, 0.5, 2],
    unit = 'mg/L',
}) => {
    const {
        parametersProps,
    } = useSelector(store => ({
        parametersProps: store.ParameterReducer.parameters,
    }), shallowEqual)

    const parametersIndexed = useListIndexed(parametersProps, 'code')

    const excludeProducers = useApplicationSetting('CATCHMENT_PRODUCERS_EXCLUDED', setting => setting?.split(',').filter(c => !!c).map(id => parseInt(id)) || [])

    const analysisFilters = useMemo(() => {
        return {
            lightMode: true,
            startDate: filters.firstStart,
            endDate: filters.firstEnd,
            stations: ids,
            parameters: [parameter],
            excludedProducers: excludeProducers,
            support: '3', // support 'eau'
        }
    }, [excludeProducers, filters.firstEnd, filters.firstStart, ids, parameter])

    const {
        analysis = [],
        isLoaded = false,
        progress = 0,
    } = useFetchAnalysis(analysisFilters)

    return (
        <div style={{ position: 'relative' }}>
            {!isLoaded && (<CircularProgressWithLabel value={progress} />) }
            <GraphicPercent
                analysis={analysis}
                thresholds={thresholds}
                title={parametersIndexed[parameter]?.name}
                unit={unit}
                criteria={filters.criteria}
                colors={colors}
            />
        </div>
    )
}

ParameterGraphicPercent.propTypes = {
    parameter: PropTypes.string,
    ids: PropTypes.arrayOf(PropTypes.number),
    filters: PropTypes.shape({}), //
    colors: PropTypes.arrayOf(PropTypes.string),
    thresholds: PropTypes.arrayOf(PropTypes.number),
    unit: PropTypes.string,
}

const SumParameterGraphicPercent = ({
    filters = {},
    colors = colorGradients[0],
    ids = [],
}) => {
    const excludeProducers = useApplicationSetting('CATCHMENT_PRODUCERS_EXCLUDED', setting => setting?.split(',').filter(c => !!c).map(id => parseInt(id)) || [])
    const pesticides = useApplicationSetting('CATCHMENT_PESTICIDES', listStringParser)
    const listSumPesticides = useApplicationSetting('CATCHMENT_LIST_SUM_PESTICIDES', listStringParser)

    const analysisFilters = useMemo(() => {
        return {
            lightMode: true,
            startDate: filters.firstStart,
            endDate: filters.firstEnd,
            stations: ids,
            parameters: listSumPesticides.length > 0 ? listSumPesticides : pesticides,
            quantificationControl: true,
            excludedProducers: excludeProducers,
            support: '3', // support 'eau'
        }
    }, [excludeProducers, filters.firstEnd, filters.firstStart, ids, listSumPesticides, pesticides])

    const {
        sumAnalysis = [],
        isLoaded = false,
        progress = 0,
    } = useFetchSumAnalysis(analysisFilters)

    return (
        <div style={{ position: 'relative' }}>
            {!isLoaded && (<CircularProgressWithLabel value={progress} />)}
            <GraphicPercent
                analysis={sumAnalysis}
                thresholds={[0.01, 0.1, 0.5, 2]}
                title={i18n.sumPesticides}
                unit='µg/L'
                criteria={filters.criteria}
                colors={colors}
            />
        </div>
    )
}

SumParameterGraphicPercent.propTypes = {
    ids: PropTypes.arrayOf(PropTypes.number),
    filters: PropTypes.shape({}), //
    colors: PropTypes.arrayOf(PropTypes.string),
}

const breakLine = (name = '', nbChar = 30) => {
    const splitName = name.split(' ')
    let temp = 0
    return splitName.reduce((acc, cur) => {
        if (!acc) {
            temp = cur.length
            return cur
        }
        if (temp + cur.length > nbChar) {
            temp = cur.length
            return `${acc}\n${cur}`
        }
        temp = temp + cur.length
        return `${acc} ${cur}`
    }, '')
}

const Pie = ({
    listCount = [],
}) => {
    const {
        parameterGroups,
    } = useSelector(store => ({
        parameterGroups: store.ParameterReducer.parameterGroups,
    }), shallowEqual)

    const parameterGroupsIndexed = useListIndexed(parameterGroups, 'code')

    const options = {
        title: {
            text: i18n.distributionQuantificationsByPesticideFamily,
            left: 'center',
        },
        tooltip: {
            trigger: 'item',
            formatter: '{b} : {c} quantifications ({d}%)',
        },
        legend: {
            type: 'scroll',
            orient: 'vertical',
            right: 60,
            top: 50,
            bottom: 20,
            formatter: breakLine,
        },
        series: [
            {
                type: 'pie',
                radius: '100',
                center: ['30%', '50%'],
                data: listCount.map(({ groupCode, nb }) => ({
                    name: getI18nOrLabel(parameterGroupsIndexed[groupCode]?.name ?? 'unknown'),
                    value: nb,
                })),
                label: { show: false },
                top: 30,
            },
        ],
    }

    return (
        <ReactECharts
            echarts={echarts}
            option={options}
            notMerge={true}
            lazyUpdate={true}
            className={'row no-margin'}
            style={{ height: 300 }}
        />
    )
}

Pie.propTypes = {
    listCount: PropTypes.arrayOf(PropTypes.shape({
        groupCode: PropTypes.number,
        nb: PropTypes.number,
    })),
}

const PieFamilyPesticides = ({
    ids = [],
    filters = {},
}) => {
    const excludeProducers = useApplicationSetting('CATCHMENT_PRODUCERS_EXCLUDED', setting => setting?.split(',').filter(c => !!c).map(id => parseInt(id)) || [])
    const pesticides = useApplicationSetting('CATCHMENT_PESTICIDES', listStringParser)

    const countFilters = useMemo(() => {
        return {
            lightMode: true,
            startDate: filters.firstStart,
            endDate: filters.firstEnd,
            stations: ids,
            parameters: pesticides,
            quantificationControl: true,
            excludedProducers: excludeProducers,
            support: '3', // support 'eau'
        }
    }, [excludeProducers, filters.firstEnd, filters.firstStart, ids, pesticides])

    const {
        controllerRef,
        initController,
    } = useAbortController()

    const {
        value: isLoaded,
        setFalse: setNotLoaded,
        setTrue: setLoaded,
    } = useBoolean(false)

    const [countGroupParameters, setCountGroupParameters] = useState([])

    useEffect(() => {
        if (countFilters.stations.length === 0 || isNil(countFilters.startDate) || isNil(countFilters.endDate) || countFilters.parameters.length === 0) return

        setNotLoaded()
        initController()
        setCountGroupParameters([])

        CatchmentAction.countGroupParameters(countFilters, controllerRef.current.signal)
            .catch(() => [])
            .then(setCountGroupParameters)
            .finally(() => !controllerRef.current.signal.aborted && setLoaded())

        // eslint-disable-next-line consistent-return
        return () => {
            controllerRef.current.abort()
        }
    }, [countFilters])

    return (
        <div style={{ position: 'relative' }}>
            {!isLoaded && (<CircularProgressWithLabel />)}
            <Pie listCount={countGroupParameters} />
        </div>
    )
}

PieFamilyPesticides.propTypes = {
    ids: PropTypes.arrayOf(PropTypes.number),
    filters: PropTypes.shape({}),
}

const GraphicEvolution = ({
    firstAnalysis = [],
    secondAnalysis = [],
    title = '',
    criteria = LAST_VALUE,
    colors = colorGradients[0],
}) => {
    let echartRef = useRef(undefined)
    let displayToolbox = useRef(false)

    const {
        value: isExportOpen,
        setTrue: openExport,
        setFalse: closeExport,
    } = useBoolean(false)

    const {
        settings,
    } = useSelector(store => ({
        settings: store.AdministrationReducer.settings,
    }), shallowEqual)

    const analysis = useMemo(() => [...firstAnalysis, ...secondAnalysis], [firstAnalysis, secondAnalysis])

    const firstValues = getUsedValues(firstAnalysis, criteria, settings)
    const secondValues = getUsedValues(secondAnalysis, criteria, settings)

    // the key is a string due to the groupBy
    const qualitometerIds = uniqBy(analysis, 'qualitometer').map(a => `${a.qualitometer}`)

    const groupAnalysis = groupBy(qualitometerIds, id => {
        const first = firstValues.find(v => v.qualitometer === id)?.value
        const second = secondValues.find(v => v.qualitometer === id)?.value

        if (isNil(first) || isNil(second)) return '7'

        const diff = second - first
        const percent = first !== 0 ? diff * 100 / first : 0

        if (percent >= 80) return '0'
        if (percent >= 40) return '1'
        if (percent >= 10) return '2'
        if (percent >= -10) return '3'
        if (percent >= -40) return '4'
        if (percent >= -80) return '5'
        return '6'
    })

    const getBar = (key, group = [], color) => {
        const percentage = group.length && qualitometerIds.length ? group.length / qualitometerIds.length * 100 : 0
        return {
            value: [percentage, key],
            itemStyle: {
                color,
            },
            label: {
                show: true,
                position: 'right',
                color: '#000',
                formatter: () => `${round(percentage, 1)} %`,
            },
        }
    }

    const labelBar = [
        'Plus de 80',
        'Entre 40 et 80',
        'Entre 10 et 40',
        'Entre -10 et 10',
        'Entre -10 et -40',
        'Entre -40 et -80',
        'Moins de -80',
    ]

    const getToolbox = () => ({
        top: '4%',
        right: '35px',
        showTitle: false,
        itemSize: 18,
        tooltip: {
            show: true,
            position: 'bottom',
        },
        feature: {
            saveAsImage: {
                show: displayToolbox.current,
                title: i18n.pictureExport,
                icon: exportPictureIcon,
                name: `${i18n.evolution} ${title}`,
            },
            myToolExport: {
                show: displayToolbox.current,
                title: i18n.excelExport,
                icon: exportExcelIcon,
                onclick: openExport,
            },
        },
    })

    const options = {
        title: {
            text: title,
            left: 'center',
            top: '10',
        },
        series: [{
            type: 'bar',
            data: [
                getBar('0', groupAnalysis['0'], colors[0]),
                getBar('1', groupAnalysis['1'], colors[1]),
                getBar('2', groupAnalysis['2'], colors[2]),
                getBar('3', groupAnalysis['3'], colors[3]),
                getBar('4', groupAnalysis['4'], colors[4]),
                getBar('5', groupAnalysis['5'], colors[5]),
                getBar('6', groupAnalysis['6'], colors[6]),
                getBar('7', groupAnalysis['7'], '#CCC'),
            ],
        }],
        xAxis: [{
            type: 'value',
            name: i18n.catchmentPercentage,
            nameLocation: 'middle',
            nameGap: 25,
        }],
        yAxis: [{
            type: 'category',
            data: ['7', '6', '5', '4', '3', '2', '1', '0'],
            axisLabel: {
                formatter: v => labelBar[v] ?? i18n.noQuantification,
            },
        }],
        grid: {
            top: '50',
            left: '0',
            right: '50',
            bottom: '80',
            containLabel: true,
            height: 200,
        },
        toolbox: getToolbox(),
    }
    return (
        <div
            onMouseOver={() => {
                displayToolbox.current = true
                echartRef.current?.getEchartsInstance().setOption({
                    toolbox: getToolbox(),
                })
            }}
            onMouseOut={() => {
                displayToolbox.current = false
                echartRef.current?.getEchartsInstance().setOption({
                    toolbox: getToolbox(),
                })
            }}
        >
            <ReactECharts
                echarts={echarts}
                option={options}
                notMerge={true}
                lazyUpdate={true}
                ref={e => {
                    echartRef.current = e
                }}
            />
            <ExportModal
                isOpen={isExportOpen}
                close={closeExport}
                analysis={analysis}
                exportName={`${i18n.evolution} ${title}`}
            />
        </div>
    )
}

GraphicEvolution.propTypes = {
    firstAnalysis: PropTypes.arrayOf(PropTypes.instanceOf(DtoAnalysisLight)),
    secondAnalysis: PropTypes.arrayOf(PropTypes.instanceOf(DtoAnalysisLight)),
    title: PropTypes.string,
    criteria: PropTypes.string,
    colors: PropTypes.arrayOf(PropTypes.string),
}

const ParameterGraphicEvolution = ({
    parameter,
    ids = [],
    filters = {},
    colors = colorGradients[0],
}) => {
    const {
        parametersProps,
    } = useSelector(store => ({
        parametersProps: store.ParameterReducer.parameters,
    }), shallowEqual)

    const parametersIndexed = useListIndexed(parametersProps, 'code')

    const excludeProducers = useApplicationSetting('CATCHMENT_PRODUCERS_EXCLUDED', setting => setting?.split(',').filter(c => !!c).map(id => parseInt(id)) || [])

    const firstAnalysisFilters = useMemo(() => {
        return {
            lightMode: true,
            startDate: filters.firstStart,
            endDate: filters.firstEnd,
            stations: ids,
            parameters: [parameter],
            excludedProducers: excludeProducers,
            support: '3', // support 'eau'
        }
    }, [excludeProducers, filters.firstEnd, filters.firstStart, ids, parameter])

    const {
        analysis: firstAnalysis = [],
        isLoaded: firstIsLoaded = false,
        progress: firstProgress = 0,
    } = useFetchAnalysis(firstAnalysisFilters)

    const secondAnalysisFilters = useMemo(() => {
        return {
            lightMode: true,
            startDate: filters.secondStart,
            endDate: filters.secondEnd,
            stations: ids,
            parameters: [parameter],
            excludedProducers: excludeProducers,
            support: '3', // support 'eau'
        }
    }, [excludeProducers, filters.secondEnd, filters.secondStart, ids, parameter])

    const {
        analysis: secondAnalysis = [],
        isLoaded: secondIsLoaded = false,
        progress: secondProgress = 0,
    } = useFetchAnalysis(secondAnalysisFilters)

    return (
        <div style={{ position: 'relative' }}>
            {(!firstIsLoaded || !secondIsLoaded) && (<CircularProgressWithLabel value={(firstProgress + secondProgress) / 2}/>)}
            <GraphicEvolution
                firstAnalysis={firstAnalysis}
                secondAnalysis={secondAnalysis}
                title={parametersIndexed[parameter]?.name}
                criteria={filters.criteria}
                colors={colors}
            />
        </div>
    )
}

ParameterGraphicEvolution.propTypes = {
    parameter: PropTypes.string,
    ids: PropTypes.arrayOf(PropTypes.number),
    filters: PropTypes.shape({}),
    colors: PropTypes.arrayOf(PropTypes.string),
}

const SumParameterGraphicEvolution = ({
    ids = [],
    filters = {},
    colors = colorGradients[0],
}) => {
    const excludeProducers = useApplicationSetting('CATCHMENT_PRODUCERS_EXCLUDED', setting => setting?.split(',').filter(c => !!c).map(id => parseInt(id)) || [])
    const pesticides = useApplicationSetting('CATCHMENT_PESTICIDES', listStringParser)
    const listSumPesticides = useApplicationSetting('CATCHMENT_LIST_SUM_PESTICIDES', listStringParser)

    const firstAnalysisFilters = useMemo(() => {
        return {
            lightMode: true,
            startDate: filters.firstStart,
            endDate: filters.firstEnd,
            stations: ids,
            parameters: listSumPesticides.length > 0 ? listSumPesticides : pesticides,
            quantificationControl: true,
            excludedProducers: excludeProducers,
            support: '3', // support 'eau'
        }
    }, [excludeProducers, filters.firstEnd, filters.firstStart, ids, listSumPesticides, pesticides])

    const {
        sumAnalysis: firstSumAnalysis = [],
        isLoaded: firstIsLoaded = false,
        progress: firstProgress = 0,
    } = useFetchSumAnalysis(firstAnalysisFilters)

    const secondAnalysisFilters = useMemo(() => {
        return {
            lightMode: true,
            startDate: filters.secondStart,
            endDate: filters.secondEnd,
            stations: ids,
            parameters: listSumPesticides.length > 0 ? listSumPesticides : pesticides,
            quantificationControl: true,
            excludedProducers: excludeProducers,
            support: '3', // support 'eau'
        }
    }, [excludeProducers, filters.secondEnd, filters.secondStart, ids, listSumPesticides, pesticides])

    const {
        sumAnalysis: secondSumAnalysis = [],
        isLoaded: secondIsLoaded = false,
        progress: secondProgress = 0,
    } = useFetchSumAnalysis(secondAnalysisFilters)

    return (
        <div style={{ position: 'relative' }}>
            {(!firstIsLoaded || !secondIsLoaded) && (<CircularProgressWithLabel value={(firstProgress + secondProgress) / 2} />)}
            <GraphicEvolution
                firstAnalysis={firstSumAnalysis}
                secondAnalysis={secondSumAnalysis}
                title={i18n.sumPesticides}
                criteria={filters.criteria}
                colors={colors}
            />
        </div>
    )
}

SumParameterGraphicEvolution.propTypes = {
    parameter: PropTypes.string,
    ids: PropTypes.arrayOf(PropTypes.number),
    filters: PropTypes.shape({}),
    colors: PropTypes.arrayOf(PropTypes.string),
}

export {
    GraphicPercent,
    ParameterGraphicPercent,
    SumParameterGraphicPercent,
    PieFamilyPesticides,
    GraphicEvolution,
    ParameterGraphicEvolution,
    SumParameterGraphicEvolution,
}