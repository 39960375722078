import { createTheme } from '@mui/material'
import { darkBlue, greyBlue, lightBlue, mainBlue, secondaryBlue } from './ColorTheme'

const MAIN_BOX_SHADOW = '0px 2px 16px 0px rgb(0 0 0 / 28%)'
const MAIN_RADIUS = '4px'

const Theme = createTheme({
    palette: {
        primary: {
            main: mainBlue,
            grey: greyBlue,
            light: lightBlue,
        },
        secondary: {
            main: secondaryBlue,
        },
        third: {
            main: darkBlue,
        },
    },
    checkbox: {},
    svgIcon: {},
    components: {
        MuiAccordion: {
            styleOverrides: {
                root: {
                    boxShadow: MAIN_BOX_SHADOW,
                },
            },
        },
    },
})

export { Theme, MAIN_BOX_SHADOW, MAIN_RADIUS }