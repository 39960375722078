import { Button, Dialog, DialogActions, DialogContent } from '@mui/material'
import { push } from 'connected-react-router'
import { isEqual, minBy, orderBy, uniqBy } from 'lodash'
import moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import ToastrAction from 'toastr/actions/ToastrAction'
import User from '../../../account/dto/User'
import ActionComponent from '../../../components/ActionComponent'
import Card from '../../../components/card/Card'
import LastUpdateCard from '../../../components/card/LastUpdateCard'
import ProgressCircleChart from '../../../components/progress/ProgressCircleChart'
import SieauAction from '../../../components/sieau/SieauAction'
import DtoEvent from '../../../events/dto/DtoEvent'
import DtoHydrometricStation from '../../../hydrometry/dto/DtoHydrometricStation'
import DtoInstallation from '../../../installation/dto/installation/DtoInstallation'
import DtoPiezometer from '../../../piezometry/dto/DtoPiezometer'
import PiezometerAccessibilitieDto from '../../../piezometry/dto/PiezometerAccessibilitieDto'
import PluviometerDto from '../../../pluviometry/dto/PluviometerDto'
import ReferencialAction from '../../../referencial/action/ReferencialAction'
import CityDto from '../../../referencial/components/city/dto/CityDto'
import ContactDto from '../../../referencial/components/contact/dto/ContactDto'
import { getDate } from '../../../utils/DateUtil'
import { formatData } from '../../../utils/ExportDataUtil'
import { getPercentage, hasValue } from '../../../utils/NumberUtil'
import { getUser } from '../../../utils/SettingUtils'
import { getStationTypeCodeFromTypeName } from '../../../utils/StationUtils'
import { getLabel } from '../../../utils/StoreUtils'
import CampaignAction from '../../actions/CampaignAction'
import CampaignDto from '../../dto/CampaignDto'
import DtoCampaignProgress from '../../dto/DtoCampaignProgress'
import DtoStationCampaign from '../../dto/DtoStationCampaign'
import CampaignStationPanel from './CampaignStationPanel'
import FormCampaign from './FormCampaign'
import ExportAction from 'export/actions/ExportAction'
import { STATION_TYPE_NAME } from 'station/constants/StationConstants'
import OldSelectStation from './OldSelectStation'

const headers = ['code', 'city', 'name', 'contact', 'access', 'order', 'monitoredStation', 'toPlan']

class DashboardCampaign extends ActionComponent {
    constructor(props) {
        super(props)
        this.state = {
            campaign: {
                contactCode: props.accountUser.contactCode,
                administratorCode: props.accountUser.contributorCode,
                campaignType: 3,
            },
            selectedStations: [],
            tmpSelectedStations: [],
            getStationsList: [],
            editMode: false,
            openPopup: false,
        }
    }

    componentDidMount = () => {
        if (!(this.props.params.id === 'new') && !(this.props.params.id === 'duplicate')) {
            this.setReadMode()
        } else {
            this.setEditMode()
        }
        this.setTitle()

        if (!this.props.sandreCodes.length) {
            this.props.fetchSandreCodes()
        }
    }

    setTitle = () => {
        const {
            params,
            campaign,
            stationType,
        } = this.props
        this.props.forceFetch('title', [
            {
                title: i18n[stationType],
                href: `/${stationType}`,
            },
            {
                title: i18n.campaigns,
                href: `/${stationType}/campaign`,
            },
            (() => {
                switch (params.id) {
                    case 'new':
                        return {
                            title: i18n.new,
                            href: `/${stationType}/campaign/new/dashboard`,
                        }
                    case 'duplicate':
                        return {
                            title: i18n.duplicate,
                            href: `/${stationType}/campaign/duplicate/dashboard`,
                        }
                    default:
                        return {
                            title: campaign.name || params.id,
                            href: `/${stationType}/campaign/${params.id}/dashboard`,
                        }
                }
            })(),
        ])
    }

    onExport = (exportType) => {
        const stations = this.getStationsToMonitor(this.props.stations)
        const data = this.state.selectedStations.map(s => {
            const station = stations.find(p => p.id === s.id) || {}
            const accessibilities = this.props.accessibilities.filter(access => access.idPiezo === station.id)
            const access = accessibilities.length && accessibilities.find(a => hasValue(a.order)) ? minBy(accessibilities, 'order') : (accessibilities.length ? accessibilities[0] : null)
            const contact = this.props.contacts.find(c => c.id === (access && access.contactCode ? access.contactCode : station.contactCode))
            return {
                ...s,
                code: station.code,
                name: station.name,
                city: station.townCode ? `${getLabel(this.props.cities, station.townCode)} [${station.townCode}]` : '',
                monitoredStation: station.monitoringEvents.map(e => e.comment || e.problem || '').join(', '),
                access: orderBy(accessibilities, 'order').map(a => a.description).join(', '),
                contact: contact ? [contact.name || '', contact.address, contact.postalCode, getLabel(this.props.cities, contact.cityCode), contact.phoneTel || contact.mobile].filter(str => !!str).join(', ') : '',
                toPlan: station.toPlanEvents.map(e => e.comment || e.problem || '').join(', '),
            }
        })
        const dataWithHeader = data.length > 0 ? [{ ...data[0], headers }, ...data.slice(1)] : []
        this.props.export(formatData(dataWithHeader), exportType, `${i18n.campaigns}_${i18n[this.props.stationType]}_${this.state.campaign.name || ''}`)
    }

    getExportAction = () => [{
        onClick: () => this.onExport(),
        label: i18n.excel,
    }]

    setReadMode = () => {
        const { campaign, stationType } = this.props
        const hydroObsExport = stationType === STATION_TYPE_NAME.hydrometry ? [{
            name: i18n.flowObservations,
            formats: [{
                type: i18n.exportXLSX,
                callback: () => this.props.exportHydroObsCampaign({ campaignId: campaign.id, campaignName: campaign.name, exportType: 'xlsx' }),
            }, {
                type: i18n.csv,
                callback: () => this.props.exportHydroObsCampaign({ campaignId: campaign.id, campaignName: campaign.name, exportType: 'csv' }),
            }],
        }] : []
        const exportAction = {
            exportChoice: {
                exportChoices: [{
                    name: i18n.exportFull,
                    formats: [{
                        type: i18n.exportXLSX,
                        callback: () => this.onExport('xlsx'),
                    }, {
                        type: i18n.csv,
                        callback: () => this.onExport('csv'),
                    }],
                }, ...hydroObsExport],
            },
        }
        const action = getUser().consultant === '1' ? exportAction : {
            edit: this.setEditMode,
            delete: () => this.props.deleteCampaign(this.props.stationType, this.props.params.id),
            duplicate: () => {
                this.setState({
                    campaign: {
                        ...this.state.campaign,
                        name: '',
                        beginningApplication: undefined,
                        endingApplication: undefined,
                    },
                })
                this.props.push(`/${this.props.stationType}/campaign/duplicate/dashboard`)
            },
            ...exportAction,
        }
        this.setActions(action)
        const selectedStations = this.props.campaignStations.map(s => ({
            id: s.stationId,
            order: s.visitOrder,
        }))
        const orderedStations = orderBy(selectedStations, 'order')
        this.setState({
            campaign: this.props.campaign,
            editMode: false,
            selectedStations: orderedStations,
            tmpSelectedStations: orderedStations,
        })
    }

    setEditMode = () => {
        if (this.props.params.id === 'duplicate' || this.props.params.id === 'new') {
            this.setActions({
                save: this.onSaveNewCampaign,
                cancel: () => this.props.push(`/${this.props.stationType}/campaign`),
            })
        } else {
            this.setActions({
                save: this.onSaveWithStations,
                cancel: this.setReadMode,
                exportList: this.getExportAction(),
            })
        }
        this.setState({ editMode: true })
    }

    onSaveNewCampaign = () => {
        const dateNow = +moment()
        const campaign = {
            ...this.state.campaign,
            beginningApplication: this.state.campaign.beginningApplication || getDate(dateNow),
            updateLogin: getUser().login,
            updateDate: dateNow,
            stationType: getStationTypeCodeFromTypeName(this.props.stationType),
        }
        const stations = this.state.selectedStations.map(s => new DtoStationCampaign({
            stationId: s.id,
            visitOrder: s.order,
        }))
        const uniqStations = uniqBy(stations, 'stationId')
        if (campaign.name) {
            this.props.createCampaign(campaign, this.props.stationType).then((json = {}) =>
                json.id && this.props.updateCampaignStations(uniqStations, this.props.stationType, json.id).then(() => {
                    this.props.push(`/${this.props.stationType}/campaign/${json.id}/dashboard`)
                    this.setReadMode()
                    this.props.toastrSuccess(i18n.successfulCreation)
                }),
            )
        } else {
            this.props.toastrError(i18n.thanksToEnterName)
        }
    }

    onSaveWithStations = () => {
        const stations = this.state.selectedStations.map(s => new DtoStationCampaign({
            stationId: s.id,
            visitOrder: s.order,
        }))
        const uniqStations = uniqBy(stations, 'stationId')
        this.props.updateCampaign(this.state.campaign, this.props.params.id, this.props.stationType).then(() =>
            this.props.updateCampaignStations(uniqStations, this.props.stationType, this.props.params.id).then(() => {
                const deletedStations = this.props.campaignStations.filter(cs => !uniqStations.filter(s => s.stationId === cs.stationId).length)
                deletedStations.forEach(ds => this.props.deleteVisit(ds.campaignId, ds.stationId))
                this.setReadMode()
            }),
        )
    }

    componentDidUpdate = prevProps => {
        if (this.props.params.id !== prevProps.params.id) {
            this.setTitle()
            if (this.props.params.id === 'new' || this.props.params.id === 'duplicate') {
                this.setEditMode()
            } else {
                this.setReadMode()
            }
        }
        if (!isEqual(this.props.campaignStations, prevProps.campaignStations)) {
            const selectedStations = this.props.campaignStations.map(s => ({
                id: s.stationId,
                order: s.visitOrder,
            }))
            const orderedStations = orderBy(selectedStations, 'order')
            this.setState({
                selectedStations: orderedStations,
                tmpSelectedStations: orderedStations,
            })
        }
        if (!isEqual(this.props.campaign, prevProps.campaign)) {
            this.setTitle()
            this.setState({ campaign: this.props.campaign })
        }
    }

    onDeleteStation = value => {
        const selectedStations = this.state.selectedStations.filter(station => station.id !== value.id)
        this.setState({
            selectedStations,
            tmpSelectedStations: [...selectedStations],
        })
    }

    getStationsToMonitor = stations => {
        const dateNow = +moment()
        const {
            monitoringCampaignsEvents,
            toPlanCampaignEvents,
        } = this.props.campaignEvents.reduce((acc, event) => {
            if (!event.closeDate || event.closeDate > dateNow) {
                if (event.eventType === 'S') {
                    acc.monitoringCampaignsEvents.push(event)
                }
                if (event.eventType === 'P') {
                    acc.toPlanCampaignEvents.push(event)
                }
            }
            return acc
        }, { monitoringCampaignsEvents: [], toPlanCampaignEvents: [] })
        return stations.map(s => {
            const monitoringEvents = monitoringCampaignsEvents.filter(e => e.stationId === s.id)
            const toPlanEvents = toPlanCampaignEvents.filter(e => e.stationId === s.id)
            return {
                ...s,
                monitoring: monitoringEvents.length,
                monitoringEvents,
                toPlanEventLength: toPlanEvents.length,
                toPlanEvents,
            }
        })
    }

    onClose = () => this.setState({
        openPopup: false,
        tmpSelectedStations: this.state.selectedStations,
    })


    onAdd = () => this.setState({
        openPopup: false,
        selectedStations: [...this.state.tmpSelectedStations],
    })

    onChangeOrder = (id, order) => {
        const selectedStations = this.state.selectedStations.map(s => {
            if (s.id === id) {
                return { ...s, order }
            }
            return s
        })
        this.setState({ selectedStations })
    }

    render = () => {
        const {
            campaign,
            editMode,
            openPopup,
            tmpSelectedStations,
            selectedStations,
        } = this.state
        const {
            campaignEvents,
            campaignProgress,
            accessibilities,
            stations,
            stationType,
            params,
        } = this.props
        return (
            <div id='campaignApp' className='row no-margin'>
                <div className='col s12'>
                    <div className='card margin-top-0-75-rem'>
                        <div className='card-content'>
                            <div className='row no-margin'>
                                <FormCampaign
                                    campaign={campaign}
                                    disabled={{ active: editMode, disabled: !editMode }}
                                    size={params.id === 'new' || params.id === 'duplicate' ? 'col s12' : 'col s12 m9'}
                                    onChangeCampaign={obj => this.setState({ campaign: { ...campaign, ...obj } })}
                                />
                                {params.id !== 'new' && params.id !== 'duplicate' && (
                                    <div className='col s12 m3'>
                                        <div className='row no-margin'>
                                            <div className='col s12'>
                                                <LastUpdateCard
                                                    element={campaign}
                                                    withHour
                                                />
                                            </div>
                                            <div className='col s12'>
                                                <Card title={stationType === 'installation' && i18n.installationsProgress || i18n.stationsProgress}>
                                                    <ProgressCircleChart
                                                        total={campaignProgress.nbStation}
                                                        complete={campaignProgress.nbStationValidated}
                                                        title={`${getPercentage(campaignProgress.nbStationValidated, campaignProgress.nbStation)} %`}
                                                    />
                                                </Card>
                                            </div>
                                        </div>
                                    </div>
                                )
                                }
                            </div>
                        </div>
                    </div>
                    <CampaignStationPanel
                        campaignId={campaign.id}
                        editMode={editMode}
                        selectedStations={selectedStations}
                        onDeleteStation={this.onDeleteStation}
                        stationType={stationType}
                        stations={this.getStationsToMonitor(stations)}
                        accessibilities={accessibilities}
                        onChangeOrder={this.onChangeOrder}
                        onOpenPopup={() => this.setState({ openPopup: true })}
                    />
                </div>
                <Dialog
                    onClose={this.onClose}
                    fullWidth
                    maxWidth='lg'
                    open={openPopup}
                >
                    <DialogContent>
                        <OldSelectStation
                            stations={stations}
                            selectedStations={tmpSelectedStations}
                            campaignEvents={campaignEvents}
                            stationType={stationType}
                            onChangeSelectedStation={tmpStations => this.setState({ tmpSelectedStations: tmpStations })}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.onClose} variant='outlined' >
                            {i18n.close}
                        </Button>
                        <Button onClick={this.onAdd} variant='contained' color='primary'>
                            {i18n.validate}
                        </Button>
                    </DialogActions>
                </Dialog>
            </div >
        )
    }
}

DashboardCampaign.propTypes = {
    params: PropTypes.shape({
        id: PropTypes.string,
    }),
    accessibilities: PropTypes.arrayOf(PropTypes.oneOfType([
        PropTypes.instanceOf(PiezometerAccessibilitieDto),
    ])),
    stations: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.instanceOf(PluviometerDto)),
        PropTypes.arrayOf(PropTypes.instanceOf(DtoPiezometer)),
        PropTypes.arrayOf(PropTypes.instanceOf(DtoHydrometricStation)),
        PropTypes.arrayOf(PropTypes.instanceOf(DtoInstallation)),
    ]),
    stationType: PropTypes.oneOf(['piezometry', 'pluviometry', 'hydrometry', 'installation']),

    campaign: PropTypes.instanceOf(CampaignDto),
    campaignStations: PropTypes.arrayOf(PropTypes.instanceOf(DtoStationCampaign)),
    campaignEvents: PropTypes.arrayOf(PropTypes.instanceOf(DtoEvent)),
    campaignProgress: PropTypes.instanceOf(DtoCampaignProgress),

    cities: PropTypes.arrayOf(PropTypes.instanceOf(CityDto)),
    contacts: PropTypes.arrayOf(PropTypes.instanceOf(ContactDto)),

    accountUser: PropTypes.instanceOf(User),
}

DashboardCampaign.defaultProps = {
    accessibilities: [],
}

const mapStateToProps = store => ({
    campaign: store.CampaignReducer.campaign,
    campaignStations: store.CampaignReducer.campaignStations,
    campaignEvents: store.CampaignReducer.campaignEvents,
    campaignProgress: store.CampaignReducer.campaignProgress,

    cities: store.CityReducer.cities,
    contacts: store.ContactReducer.contacts,

    accountUser: store.AccountReducer.accountUser,
    sandreCodes: store.ReferencialReducer.sandreCodes,
})

const mapDispatchToProps = {
    push,
    createCampaign: CampaignAction.createCampaign,
    updateCampaign: CampaignAction.updateCampaign,
    deleteCampaign: CampaignAction.deleteCampaign,
    updateCampaignStations: CampaignAction.updateCampaignStations,
    forceFetch: SieauAction.forceFetch,
    toastrError: ToastrAction.error,
    toastrSuccess: ToastrAction.success,
    setPopup: SieauAction.setPopup,
    fetchSandreCodes: ReferencialAction.fetchSandreCodes,
    deleteVisit: CampaignAction.deleteVisit,
    export: ExportAction.export,
    exportHydroObsCampaign: ExportAction.exportHydroObsCampaign,
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardCampaign)
