import React from 'react'
import createClass from 'create-react-class'
import PropTypes from 'prop-types'
import { keys } from 'lodash'
import { push } from 'connected-react-router'
import AppStore from '../../store/AppStore'
import Checkbox from '../forms/Checkbox'
import { hasValue } from '../../utils/NumberUtil'
import { setSieauTooltip, sieauTooltip } from '../../utils/FormUtils'
import { v4 as uuidv4 } from 'uuid'
import Icon from '../icon/Icon'

export default createClass({
    propTypes: {
        index: PropTypes.number,
        data: PropTypes.object.isRequired,
        editable: PropTypes.bool,
        deletable: PropTypes.bool,
        onDelete: PropTypes.func,
        deleteTooltip: PropTypes.string,
        duplicable: PropTypes.bool,
        onDuplicate: PropTypes.func,
        alterable: PropTypes.bool,
        onAlter: PropTypes.func,
        link: PropTypes.string,
        subLink: PropTypes.string,
        onClick: PropTypes.func,
        condensed: PropTypes.bool,
        checkable: PropTypes.bool,
        checked: PropTypes.bool,
        onLineOver: PropTypes.func,
        onLineOut: PropTypes.func,
        headers: PropTypes.arrayOf(PropTypes.string),
        active: PropTypes.bool,
        coloredLine: PropTypes.bool,
        noFlexCell: PropTypes.bool,
    },
    getInitialState() {
        return {
            editableColumn: -1,
            width: 0,
        }
    },
    getDefaultProps() {
        return {
            data: {},
            editable: false,
            condensed: false,
            checked: false,
            duplicable: false,
            onDuplicate: () => { },
            alterable: false,
            onAlter: () => { },
        }
    },
    onClick(idx) {
        this.setState({ editableColumn: idx, width: this.refs[`col-${idx}`].offsetWidth })
    },

    goToLocation(link) {
        AppStore.dispatch(push(link))
    },

    onLink(id) {
        if (this.props.subLink) {
            this.goToLocation(`/${this.props.link}/${id}/${this.props.subLink}`)
        } else {
            this.goToLocation(`/${this.props.link}/${id}`)
        }
    },
    onBlur() {
        this.props.data[keys(this.props.data)[this.state.editableColumn]] = this.refs.inputRef.value
        this.setState({ editableColumn: -1 })
    },
    getValue() {
        return this.refs.checkbox.getValue()
    },

    render() {
        const { deleteTooltip } = this.props
        const rows = this.props.headers.map((val, idx) => {
            const cell = this.props.data[val]
            if (val === 'check') {
                if (this.props.condensed) {
                    return (
                        <td className='table-body-condensed column-checkbox'>
                            <Checkbox checked={this.props.checked} condensed={this.props.condensed} ref='checkbox' />
                        </td>
                    )
                }
                return (<td className='column-checkbox'>
                    <Checkbox checked={this.props.checked} ref='checkbox' />
                </td>)
            }
            if (val === 'del' && this.props.active) {
                const className = (() => {
                    const resultClass = 'small material-icons clickable'
                    if (!this.props.active) {
                        return `${resultClass} i-disabled`
                    }
                    return resultClass
                })()
                const condensed = this.props.condensed ? 'table-body-condensed' : ''
                const tooltip = deleteTooltip ? sieauTooltip(deleteTooltip) : ''
                return (
                    <td className={`${condensed}`} data-cy={`table_del_${this.props.index}`} style={{ width: '26px' }}>
                        <i
                            className={className}
                            id='cross_delete'
                            onClick={() => this.props.onDelete ? this.props.onDelete(this.props.data, this.props.index) : ''}
                            {...tooltip}
                        >
                            close
                        </i>
                    </td>
                )
            }
            if (val === 'alt' && this.props.active) {
                const className = (() => {
                    const resultClass = 'small material-icons clickable'
                    if (!this.props.active) {
                        return `${resultClass} i-disabled`
                    }
                    return resultClass
                })()
                const condensed = this.props.condensed ? 'table-body-condensed' : ''
                return (
                    <td className={`${condensed}`} style={{ width: '26px' }}>
                        <i className={className}
                            onClick={() => this.props.onAlter ? this.props.onAlter(this.props.data, this.props.index) : ''}
                        >create</i>
                    </td>
                )
            }
            if (val === 'dupl' && this.props.active) {
                const className = (() => {
                    const resultClass = 'small material-icons clickable'
                    if (!this.props.active) {
                        return `${resultClass} i-disabled`
                    }
                    return resultClass
                })()
                const condensed = this.props.condensed ? 'table-body-condensed' : ''
                return (
                    <td className={`${condensed}`} style={{ width: '26px' }}>
                        <i className={className}
                            onClick={() => this.props.onDuplicate ? this.props.onDuplicate(this.props.data, this.props.index) : ''}
                        >add_to_photos</i>
                    </td>
                )
            }
            if (val === 'comment' && this.props.data[val]) {
                const tooltip = cell.length <= 80 ? null : sieauTooltip(() => cell, null, 'bottom')
                const clickFunc = (cell && cell.onClick) ? cell.onClick : (this.props.onClick && (() => this.props.onClick(this.props.data)))
                const clickable = clickFunc ? 'clickable ' : ''
                return (
                    <td
                        key={idx}
                        ref={`col-${idx}`}
                        onClick={clickFunc}
                        className={`table-body-condensed truncate-body-table ${clickable}`}
                        {...tooltip}
                    >
                        <span>{`${cell.slice(0, 80)}${cell.length <= 80 ? '' : '...'}`}</span>
                    </td>)
            }
            if (idx === this.state.editableColumn) {
                return (
                    <td key={idx} className='card col' style={{ position: 'absolute', width: this.state.width }}>
                        <input autoFocus type='text' ref='inputRef' onBlur={this.onBlur}
                            defaultValue={cell}
                        />
                    </td>)
            }

            // Editable
            // const editable = (() => {
            //     if (this.props.editable) {
            //         return () => this.onClick(idx)
            //     }
            //     return ''
            // })()
            const idUUid = uuidv4()
            if (val && hasValue(cell)) {
                const leftIcon = hasValue(cell) && cell.leftIcon ? <Icon icon={ cell.leftIcon } style={{ color: cell.leftIconColor || 'grey', fontSize: cell.LeftIconSize }} tooltip={cell.leftIconTip} onClick={ cell.leftIconClick } /> : null
                const rightIcon = hasValue(cell) && cell.rightIcon ? <Icon icon={ cell.rightIcon } style={{ color: cell.rightIconColor || 'grey', fontSize: cell.rightIconSize }} tooltip={cell.rightIconTip} onClick={ cell.rightIconClick } /> : null
                const clickFunc = (cell && cell.onClick) ? cell.onClick : (this.props.onClick && (() => this.props.onClick(this.props.data)))
                const clickable = clickFunc ? 'clickable ' : ''
                const contentTip = hasValue(cell) && cell.contentTip ? { onMouseOver: () => setSieauTooltip(idUUid, cell.contentTip()), onMouseOut: () => setSieauTooltip(null), onMouseDown: () => setSieauTooltip(null) } : {}
                const flexClass = this.props.noFlexCell ? '' : 'flex-row valign-wrapper'
                if (cell.color) {
                    const addedClass = hasValue(cell) ? cell.className || '' : ''
                    const tooltip = hasValue(cell) && hasValue(cell.tooltip) ? sieauTooltip(cell.tooltip) : ''
                    const styleColor = cell.color && cell.color !== 'white' ? { 'background-color': cell.color } : {}
                    const textColor = cell.textColor ? `${cell.textColor}-text` : ''
                    const fontWeight = cell.fontWeight ? cell.fontWeight : ''
                    const condensedClass = this.props.condensed ? 'table-body-condensed' : ''
                    const hover = hasValue(cell) && cell.setTooltip ? { onMouseOver: () => setSieauTooltip(idUUid, cell.setTooltip()), onMouseOut: () => setSieauTooltip(null), onMouseDown: () => setSieauTooltip(null) } : {}

                    return (
                        <td
                            id={idUUid}
                            key={idx}
                            ref={`col-${idx}`}
                            onClick={clickFunc}
                            {...hover}
                            {...tooltip}
                            className={`${textColor} ${condensedClass} truncate-body-table ${clickable} ${addedClass}`}
                            style={styleColor}
                        >
                            <div className={flexClass}>
                                { leftIcon }
                                <span className={`${fontWeight} truncate`} {...contentTip}>{cell.value}</span>
                                { rightIcon }
                            </div>
                        </td>
                    )
                }
                const color = this.props.data.color && this.props.data.color !== 'white' && !this.props.coloredLine ? this.props.data.color : ''
                const textColor = this.props.data.textColor ? `${this.props.data.textColor}-text ` : ''
                const fontWeight = this.props.data.fontWeight ? this.props.data.fontWeight : ''
                if (this.props.condensed) {
                    return (
                        <td
                            key={idx}
                            ref={`col-${idx}`}
                            onClick={clickFunc}
                            className={`table-body-condensed ${fontWeight} ${textColor} ${clickable} ${color}`}
                        >
                            <div className={flexClass}>
                                { leftIcon }
                                <span className={`${fontWeight} truncate`} {...contentTip}>{ cell }</span>
                                { rightIcon }
                            </div>

                        </td>
                    )
                }
                return (
                    <td
                        key={idx}
                        ref={`col-${idx}`}
                        onClick={clickFunc}
                        className={`${fontWeight} ${clickable} ${color} ${textColor}`}
                    >
                        <div className={flexClass}>
                            { leftIcon }
                            <span className={`${fontWeight} truncate`} {...contentTip}>{ cell }</span>
                            { rightIcon }
                        </div>
                    </td>
                )
            }
            return (<td key={idx} ref={`col-${idx}`} onClick={this.props.onClick && (() => this.props.onClick(this.props.data))}> {''}</td>)
        })

        // Linkable
        const link = (() => {
            if (this.props.link) {
                return () => this.onLink(this.props.data.id)
            } else if (this.props.data.link && !this.props.active) {
                return () => this.goToLocation(this.props.data.link)
            }
            return undefined
        })()
        const className = (() => {
            if (link) {
                return 'bottomMargin10 clickable'
            }
            return 'bottomMargin10'
        })()
        const style = this.props.coloredLine ? {
            style: { backgroundColor: this.props.data.color },
        } : {}

        return (
            <tr
                className={className}
                onClick={link}
                onMouseOver={() => this.props.onLineOver(this.props.data)}
                onMouseOut={() => this.props.onLineOut(this.props.data)}
                {...style}
            >
                {rows}
            </tr>
        )
    },
})
