import ReactECharts from 'echarts-for-react'
import echarts from 'echarts/lib/echarts'
import React, { forwardRef, useEffect, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import DtoPiezometerLight from 'piezometry/dto/DtoPiezometerLight'
import MessageCard from 'components/card/MessageCard'
import i18n from 'simple-react-i18n'
import { WhiteCard } from 'components/styled/Card'
import ProgressCard from 'components/card/ProgressCard'
import Select from 'components/forms/Select'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { intParser } from 'utils/customHook/useApplicationSetting'
import { isNil, isUndefined, max, maxBy, min, minBy, round, uniqBy } from 'lodash'
import { getBeginingOfTheYear, getDate, getEndOfTheYear, getFullDate } from 'utils/DateUtil'
import PiezometerStationAction from 'station/actions/PiezometerStationAction'
import useProgressDispatch from 'utils/customHook/useProgressDispatch'
import { MEASURE_COTE } from 'piezometry/constants/PiezometryConstants'
import moment from 'moment'
import {
    exportExcelIcon, exportPictureIcon, getAxisLabelInterval, logIcon, toEchartsData, yAutomaticScaleValues, setLogOptions, setYOptions } from 'components/echart/EChartUtils'
import { exportFile } from 'utils/ExportDataUtil'
import { getColorFromPalette2, getThresholdColor } from 'utils/ColorUtil'
import PiezometryAction from 'piezometry/actions/PiezometryAction'
import { getHardPiezoDataTypes } from 'utils/PiezometryUtils'
import Checkbox from 'components/forms/Checkbox'
import { Grid, Popover } from '@mui/material'
import useBoolean from 'utils/customHook/useBoolean'
import useStartPeriod from 'catchment/utils/useStartPeriod'
import useEndPeriod from 'catchment/utils/useEndPeriod'
import { getStationName } from 'catchment/utils/CatchmentUtil'

const DEFAULT_GRAPH_HEIGHT = 150
const DEFAULT_GAP = 50
const DEFAULT_HEADER_HEIGHT = 40
const DEFAULT_FOOTER_HEIGHT = 75

const PiezoChart = ({
    data = [],
    grids = [],

    headerHeight = DEFAULT_HEADER_HEIGHT,
    footerHeight = DEFAULT_FOOTER_HEIGHT,
}) => {
    // use on mouse event
    let echartRef = useRef(undefined)
    let displayToolbox = useRef(false)

    const [displayLog, setDisplayLog] = useState(false)

    const minDate = min(data.map(d => minBy(d.measures, 'date')?.date).filter(d => !isUndefined(d)))
    const maxDate = max(data.map(d => maxBy(d.measures, 'date')?.date).filter(d => !isUndefined(d)))

    const {
        formatter,
        interval,
    } = getAxisLabelInterval(moment(maxDate), moment(minDate))

    let tmpGap = headerHeight
    const grid = grids.map(({ gridOptions: { top = DEFAULT_GAP, height = DEFAULT_GRAPH_HEIGHT, right = '40px', left = '70px' } = {} }) => {
        const tmpTop = tmpGap + top
        const tmpGrid = {
            top: tmpTop,
            height,
            left,
            right,
        }
        tmpGap = tmpTop + height
        return tmpGrid
    })

    const xAxis = grids.map(({ xOptions = {} }, index) => {
        const {
            type = 'time',
            boundaryGap = true,
            axisLabel = {},
        } = xOptions
        return {
            type,
            boundaryGap,
            axisLabel: {
                rotate: axisLabel.rotate || 0,
                formatter,
                show: true,
            },
            axisLine: { show: true },
            axisTick: { show: true },
            splitLine: { show: true },
            interval,
            gridIndex: index,
            min: minDate,
            max: maxDate,
        }
    })


    const yAxis = grids.map(({ name, yOptions = {} }, index) => {
        const {
            inverse,
            // type,
            // nameLocation,
            // nameGap,
            // boundaryGap,
        } = yOptions
        const filteredData = data.filter(d => d.gridIndex === index)
        const allMeasures = filteredData.flatMap(d => d.measures?.map(m => m.value) ?? [])
        const allThresholds = filteredData.flatMap(d => d.thresholds?.map(t => t.value) ?? [])
        const yScale = yAutomaticScaleValues([...allMeasures, ...allThresholds])
        return {
            type: displayLog ? 'log' : 'value',
            nameLocation: 'middle',
            name,
            gridIndex: index,
            nameGap: 40,
            showSplitLine: true,
            inverse,
            ...setYOptions(null, yScale),
            ...setLogOptions(displayLog),
        }
    })


    const series = data.map(({ id, type = 'line', name = '', color, measures = [], thresholds = [], gridIndex = 0, barMaxWidth, lineStyle = {} }, i) => {
        const filteredMeasures = displayLog ? measures.filter(d => d.value > 0) : measures
        return {
            type,
            data: toEchartsData(filteredMeasures),
            name,
            connectNulls: false,
            color,
            yAxisIndex: gridIndex,
            xAxisIndex: gridIndex,
            serieId: id ?? i,
            barMaxWidth,
            markLine: {
                silent: false,
                data: thresholds.map(t => ({
                    yAxis: t.value,
                    symbol: 'none',
                    label: {
                        show: true,
                        position: 'middle',
                        formatter: () => t.title ? `${t.title} : ${round(t.value, 5)}` : '',
                    },
                    lineStyle: {
                        normal: {
                            color: t.color,
                            type: 'dashed',
                        },
                    },
                })),
            },
            lineStyle: {
                type: lineStyle.type ?? 'solid',
                opacity: lineStyle.opacity ?? 1,
                width: lineStyle.width ?? 2,
            },
        }
    })

    const getLegend = () => ({
        top: 10,
        left: 70,
        right: displayToolbox.current ? `${30 * 4 + 35}px` : 40,
        type: 'scroll',
        show: true,
    })

    const getToolbox = () => ({
        show: displayToolbox.current,
        top: 5,
        right: 40,
        showTitle: false,
        itemSize: 18,
        tooltip: {
            show: true,
            position: 'bottom',
        },
        feature: {
            myLog: {
                show: true,
                title: i18n.logarithm,
                icon: logIcon,
                onclick: () => setDisplayLog(p => !p),
            },
            saveAsImage: {
                show: true,
                title: i18n.pictureExport,
                icon: exportPictureIcon,
                name: i18n.overview,
            },
            myToolExport: {
                show: true,
                title: i18n.excelExport,
                icon: exportExcelIcon,
                onclick: () => {
                    const exportData = data.flatMap(({ name, measures, unit }) => {
                        return measures.map(d => ({
                            date: { value: getDate(d.date), format: 'dd/MM/yyyy', cellType: 'date' },
                            value: { value: d.value, format: '0.00000', cellType: 'number' },
                            name,
                            unit,
                        }))
                    })
                    exportFile({
                        data: exportData.length ? [
                            {
                                ...exportData[0],
                                headers: ['name', 'date', 'value', 'unit'],
                            },
                            ...exportData.slice(1),
                        ] : [],
                        exportType: 'xlsx',
                        titleFile: i18n.overview,
                    }, true)
                },
            },
            restore: { title: i18n.restore },
        },
    })

    const options = {
        series,
        legend: getLegend(),
        xAxis,
        yAxis,
        tooltip: {
            trigger: 'axis',
            formatter: params => {
                const date = getFullDate(params[0].value[0])
                const result = params.map(o => `${o.marker} ${o.seriesName} : ${round(o.value[1], 2)} ${o.unit ?? ''}`).join('<br />')
                return `${date} <br /> ${result}`
            },
        },
        axisPointer: {
            link: { xAxisIndex: 'all' },
        },
        grid,
        dataZoom: [{
            type: 'slider',
            xAxisIndex: grids.map((_, index) => index),
            filterMode: 'none',
        }, {
            bottom: '10px',
            type: 'inside',
            filterMode: 'filter',
            xAxisIndex: grids.map((_, index) => index),
            handleStyle: {
                color: '#fff',
                shadowBlur: 3,
                shadowColor: 'rgba(0, 0, 0, 0.6)',
                shadowOffsetX: 2,
                shadowOffsetY: 2,
            },
        }],
        toolbox: getToolbox(),
        backgroundColor: '#fff',
    }

    const componentHeight = grids.reduce((acc, { gridOptions: { height = DEFAULT_GRAPH_HEIGHT, top = DEFAULT_GAP } = {} }) => acc + height + top, headerHeight + footerHeight)

    return (
        <div
            onMouseOver={() => {
                displayToolbox.current = true
                echartRef.current?.getEchartsInstance().setOption({
                    legend: getLegend(),
                    toolbox: getToolbox(),
                })
            }}
            onMouseOut={() => {
                displayToolbox.current = false
                echartRef.current?.getEchartsInstance().setOption({
                    legend: getLegend(),
                    toolbox: getToolbox(),
                })
            }}
        >
            <ReactECharts
                echarts={echarts}
                option={options}
                notMerge={true}
                lazyUpdate={true}
                className={'row no-margin'}
                style={{ height: componentHeight }}
                ref={e => {
                    echartRef.current = e
                }}
            />
        </div>
    )
}

PiezoChart.propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        type: PropTypes.oneOf(['bar', 'line']),
        name: PropTypes.string,
        color: PropTypes.string,
        barMaxWidth: PropTypes.string,
        measures: PropTypes.arrayOf(PropTypes.shape({
            value: PropTypes.number,
            date: PropTypes.number,
        })),
        thresholds: PropTypes.arrayOf(PropTypes.shape({
            title: PropTypes.string,
            value: PropTypes.number,
            color: PropTypes.string,
        })),
        gridIndex: PropTypes.number,
        lineStyle: PropTypes.shape({

        }),
    })).isRequired,
    grids: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        gridOptions: PropTypes.shape({
            top: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
            height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
            right: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
            left: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        }),
        xOptions: PropTypes.shape({
            type: PropTypes.string,
            boundaryGap: PropTypes.bool,
            showSplitLine: PropTypes.bool,
        }),
        yOptions: PropTypes.shape({
            inverse: PropTypes.bool,
            type: PropTypes.string,
            nameLocation: PropTypes.string,
            nameGap: PropTypes.number,
            boundaryGap: PropTypes.bool,
        }),
    })).isRequired,

    headerHeight: PropTypes.number,
    footerHeight: PropTypes.number,
}

const Chip = forwardRef(({
    label = '',
    color = '',
    onClick,
    disabled = false,
    style = {},
}, ref) => (
    <span
        ref={ref}
        onClick={() => !disabled && onClick?.()}
        style={{
            cursor: disabled ? 'default' : 'pointer',
            backgroundColor: disabled ? '#AAAAAA' : color,
            color: 'white',
            padding: '3px 6px',
            borderRadius: '10px',
            ...style,
        }}
    >
        {label}
    </span>
))

Chip.propTypes = {
    label: PropTypes.string,
    color: PropTypes.string,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    style: PropTypes.object,
}

const getCote = cote => {
    switch (cote) {
        case MEASURE_COTE.DEPTH: return { label: 'Profondeur / Dernier repère', color: '#2196F3' }
        case MEASURE_COTE.NGF: return { label: 'NGF', color: '#283593' }
        case MEASURE_COTE.GROUND: return { label: 'Sol', color: '#ff9800' }
        case MEASURE_COTE.LANDMARK: return { label: 'Historique des repères', color: '#6d4c41' }
        default: return { label: 'Profondeur / Dernier repère', color: '#2196F3' }
    }
}

const PiezoOptionChip = ({
    defaultCote,
    onChangeCote = () => {},
    disabled = false,
}) => {
    const {
        value: isOpen,
        toggle: toggleOpen,
    } = useBoolean(false)
    const anchorEl = useRef()

    const selectedCote = useMemo(() => getCote(defaultCote), [defaultCote])

    const onChange = (cote) => {
        onChangeCote(cote)
        toggleOpen()
    }

    return (
        <>
            <Chip
                label={selectedCote.label}
                color={selectedCote.color}
                disabled={disabled}
                onClick={toggleOpen}
                ref={anchorEl}
            />
            <Popover
                open={isOpen}
                anchorEl={anchorEl.current}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                onClose={toggleOpen}
            >
                <Grid container direction='column' style={{ padding: '5' }} spacing={0.5}>
                    <Grid container item alignItems='center' style={{ cursor: 'pointer' }} onClick={() => onChange(MEASURE_COTE.DEPTH)}>
                        <Checkbox checked={defaultCote === MEASURE_COTE.DEPTH} />
                        <Chip label={'Profondeur / Dernier repère'} color='#2196F3' style={{ padding: '0px 6px' }}/>
                    </Grid>
                    <Grid container item alignItems='center' style={{ cursor: 'pointer' }} onClick={() => onChange(MEASURE_COTE.NGF)}>
                        <Checkbox checked={defaultCote === MEASURE_COTE.NGF} />
                        <Chip label={'NGF'} color='#283593' style={{ padding: '0px 6px' }}/>
                    </Grid>
                    <Grid container item alignItems='center' style={{ cursor: 'pointer' }} onClick={() => onChange(MEASURE_COTE.GROUND)}>
                        <Checkbox checked={defaultCote === MEASURE_COTE.GROUND} />
                        <Chip label={'Sol'} color='#ff9800' style={{ padding: '0px 6px' }}/>
                    </Grid>
                    <Grid container item alignItems='center' style={{ cursor: 'pointer' }} onClick={() => onChange(MEASURE_COTE.LANDMARK)} >
                        <Checkbox checked={defaultCote === MEASURE_COTE.LANDMARK} />
                        <Chip label={'Historique des repères'} color='#6d4c41' style={{ padding: '0px 6px' }}/>
                    </Grid>
                </Grid>
            </Popover>
        </>
    )
}

PiezoOptionChip.propTypes = {
    defaultCote: PropTypes.oneOf([MEASURE_COTE.DEPTH, MEASURE_COTE.NGF, MEASURE_COTE.GROUND, MEASURE_COTE.LANDMARK]),
    onChangeCote: PropTypes.func,
    disabled: PropTypes.bool,
}

const CatchmentFollowUpPiezo = ({
    piezometersLinked = [],
}) => {
    const dispatch = useDispatch()

    const {
        catchment,
        piezometers,
        piezometryDataTypesProps,
        cities,
    } = useSelector(store => ({
        catchment: store.CatchmentReducer.catchment,
        piezometers: store.PiezometryReducer.piezometersLight,
        piezometryDataTypesProps: store.PiezometryReducer.piezometryDataTypes,
        cities: store.CityReducer.cities,
    }), shallowEqual)

    const piezometryDataTypes = useMemo(() => [
        ...getHardPiezoDataTypes(),
        ...piezometryDataTypesProps,
    ], [piezometryDataTypesProps])

    const startPeriod = useStartPeriod(intParser)
    const endPeriod = useEndPeriod(intParser)

    const [thresholds, setThresholds] = useState([])
    const [dataMeasures, setDataMeasures] = useState([])
    const [displayCote, setdisplayCote] = useState(MEASURE_COTE.DEPTH)

    const getDefaultSelectPointId = () => {
        const mainPointFound = catchment.catchmentPoints?.find(({ mainPoint }) => mainPoint)
        const code = mainPointFound?.codeWithoutDesignation
        const idMainPoint = mainPointFound && piezometers.find(q => q.code === code)?.id
        return idMainPoint ?? piezometersLinked[0]?.id
    }

    const [station, setStation] = useState(getDefaultSelectPointId)

    useEffect(() => {
        setStation(getDefaultSelectPointId)
    }, [catchment, piezometers])

    useEffect(() => {
        dispatch(PiezometryAction.fetchPiezometryDataTypes())
    }, [])

    useEffect(() => {
        if (isNil(station)) {
            setThresholds([])
            setdisplayCote(MEASURE_COTE.NGF)
            return
        }
        PiezometerStationAction.getPiezometerThresholds(station, displayCote).then(setThresholds)
    }, [station, displayCote, dispatch])

    const {
        isLoaded,
        progress,
    } = useProgressDispatch((cancelRef) => {
        setDataMeasures([])
        const defaultInput = {
            chartMode: true,
            startDate: getBeginingOfTheYear(startPeriod),
            endDate: getEndOfTheYear(endPeriod),
        }

        return piezometersLinked.flatMap(({ id }) => {
            return [
                dispatch(PiezometerStationAction.getPiezoChartMeasures({ ...defaultInput, dataType: -1, groupFunc: 'MAX', displayCote, stationId: id })), // profondeur
                dispatch(PiezometerStationAction.getPiezoChartMeasures({ ...defaultInput, dataType: -2, groupFunc: 'SUM', stationId: id })), // prélèvent
            ].map(p => p.then(data => !cancelRef.current && data.measures.length && setDataMeasures(prev => uniqBy([data, ...prev], d => `${d.stationId}#${d.dataType}`))))
        })
    }, [displayCote])

    const dataMeasuresFiltered = useMemo(() => {
        if (isNil(station)) return dataMeasures
        return dataMeasures.filter(({ stationId }) => stationId === station)
    }, [dataMeasures, station])

    const formattedDataMeasures = useMemo(() => {
        return dataMeasuresFiltered.map((d, i) => {
            const piezo = piezometers.find(h => h.id === d.stationId)
            const filteredThresholds = thresholds.filter(t => !isUndefined(t.value) && t.code == piezo?.code && t.dataType == d.dataType).map(t => ({
                value: t.value,
                title: t.title,
                color: t.htmlColor || getThresholdColor(t.color),
            }))
            const dataType = piezometryDataTypes.find(h => h.id === d.dataType)
            return {
                name: `${piezo?.name ?? ''} (${dataType?.label ?? ''})`,
                type: d.dataType === -2 ? 'bar' : 'line',
                measures: d.measures,
                thresholds: filteredThresholds,
                gridIndex: i,
                color: getColorFromPalette2(i),
                barMaxWidth: '5px',
                lineStyle: {
                    type: dataType?.lineType,
                    opacity: dataType?.lineOpacity,
                    width: dataType?.lineWidth,
                },
            }
        })
    }, [dataMeasuresFiltered, piezometers, thresholds, piezometryDataTypes])

    const formattedGrids = useMemo(() => {
        return dataMeasuresFiltered.map((d, i) => {
            const dataType = piezometryDataTypes.find(h => h.id === d.dataType)
            return {
                name: dataType ? `${dataType.label ?? ''} ${dataType.unit ? `[${dataType.unit}]` : ''}` : i18n.unknown,
                gridOptions: {
                    top: i === 0 ? 0 : undefined,
                },
                yOptions: {
                    inverse: d.dataType === -1 && displayCote === MEASURE_COTE.DEPTH,
                },
            }
        })
    }, [dataMeasuresFiltered, displayCote, piezometryDataTypes])

    const actions = [
        {
            iconName: 'file_download',
            tooltip: i18n.export,
            color: 'black',
            onClick: () => {
                const exportData = dataMeasures.flatMap(({ measures, stationId, dataType }) => {
                    const piezo = piezometers.find(({ id }) => id === stationId)
                    const dt = piezometryDataTypes.find(h => h.id === dataType)
                    return measures.map(m => ({
                        code: piezo?.code,
                        date: { value: getDate(m.date), format: 'dd/MM/yyyy', cellType: 'date' },
                        value: { value: m.value, format: '0.00000', cellType: 'number' },
                        name: piezo?.name || stationId,
                        dataType: dt?.label ?? i18n.unknown,
                        cote: getCote(displayCote).label,
                    }))
                })
                exportFile({
                    data: exportData.length ? [
                        {
                            ...exportData[0],
                            headers: ['code', 'name', 'dataType', 'date', 'value', 'cote'],
                        },
                        ...exportData.slice(1),
                    ] : [],
                    exportType: 'xlsx',
                    titleFile: i18n.dataPiezo,
                }, true)
            },
        },
    ]

    const formattedPiezometers = useMemo(() => {
        return piezometersLinked.map(piezo => {
            const city = piezo && cities.find(c => c.code === piezo.townCode)
            return {
                name: getStationName(piezo, city),
                id: piezo.id,
            }
        })
    }, [cities, piezometersLinked])

    return (
        <WhiteCard title={i18n.piezometry} actions={actions} data-cy='follow_up_piezo'>
            {
                !isLoaded && (
                    <ProgressCard progress={progress} />
                )
            }
            {
                isLoaded && (
                    <>
                        {
                            dataMeasures.length ? (
                                <div style={{ padding: '0 5 10 5' }}>
                                    <Grid container style={{ paddingTop: '8px', paddingLeft: '0.75rem' }} alignItems='center' spacing={2}>
                                        <Grid item xs={4}>
                                            <Select
                                                label={i18n.catchmentPoint}
                                                value={station}
                                                options={formattedPiezometers}
                                                onChange={setStation}
                                                keyValue={'id'}
                                                noNullValue
                                            />
                                        </Grid>
                                        <Grid item xs={2}>
                                            <PiezoOptionChip
                                                defaultCote={displayCote}
                                                onChangeCote={setdisplayCote}
                                                disabled={isNil(station)}
                                            />
                                        </Grid>
                                    </Grid>
                                    {!!dataMeasuresFiltered.length && (<PiezoChart data={formattedDataMeasures} grids={formattedGrids} />)}
                                    {!dataMeasuresFiltered.length && (<MessageCard>{i18n.noDataToDisplayOnSelectedStation}</MessageCard>)}
                                </div>
                            ) : (
                                <MessageCard>{i18n.noDataToDisplay}</MessageCard>
                            )
                        }
                    </>
                )
            }
        </WhiteCard>
    )
}

CatchmentFollowUpPiezo.propTypes = {
    piezometersLinked: PropTypes.arrayOf(PropTypes.instanceOf(DtoPiezometerLight)).isRequired,
}

export default CatchmentFollowUpPiezo