/* eslint-disable max-nested-callbacks */
import { Grid } from '@mui/material'
import AdministrationAction from 'administration/actions/AdministrationAction'
import Card from 'components/card/Card'
import Select from 'components/forms/Select'
import Tabs from 'components/Tabs'
import { push } from 'connected-react-router'
import HomeAction from 'home/actions/HomeAction'
import HydrometryAction from 'hydrometry/actions/HydrometryAction'
import { flatten, groupBy, isNil, isUndefined, uniqBy } from 'lodash'
import PluviometryAction from 'pluviometry/actions/PluviometryAction'
import PropTypes from 'prop-types'
import QualityAction from 'quality/actions/QualityAction'
import OldOperationAction from 'quality/components/operation/actions/OperationAction'
import PcMonitoringGraph from 'quality/components/pcMonitoring/tab/PcMonitoringGraph'
import PcMonitoringListTable from 'quality/components/pcMonitoring/tab/PcMonitoringListTable'
import PcMonitoringRegroupTable from 'quality/components/pcMonitoring/tab/PcMonitoringRegroupTable'
import PcMonitoringTable from 'quality/components/pcMonitoring/tab/PcMonitoringTable'
import { GRAPH, INDICE, RESULT, SOURCE, THRESHOLD } from 'quality/constants/QualityConstants'
import DtoAnalysisLight from 'quality/dto/analyse/DtoAnalysisLight'
import DtoOperation from 'quality/dto/operation/DtoOperation'
import queryString from 'query-string'
import React, { useEffect, useMemo, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import ContributorAction from 'referencial/components/contributor/actions/ContributorAction'
import MultiContributorsAutocomplete from 'referencial/components/contributor/components/MultiContributorsAutocomplete'
import FractionAction from 'referencial/components/fraction/actions/FractionAction'
import SupportAction from 'referencial/components/support/actions/SupportAction'
import UnitAction from 'referencial/components/unit/actions/UnitAction'
import i18n from 'simple-react-i18n'
import { TmpActionConstant } from 'store/TmpReducer'
import ToastrAction from 'toastr/actions/ToastrAction'
import useActions from 'utils/customHook/useActions'
import useBoolean from 'utils/customHook/useBoolean'
import useIsMounted from 'utils/customHook/useIsMounted'
import useListIndexed from 'utils/customHook/useListIndexed'
import useProgressDispatch from 'utils/customHook/useProgressDispatch'
import useTitle from 'utils/customHook/useTitle'
import useUpdateEffect from 'utils/customHook/useUpdateEffect'
import { H_STATION_QUALITO_SUIVI_PC } from '../../../../account/constants/AccessRulesConstants'
import MessageCard from '../../../../components/card/MessageCard'
import ProgressCard from '../../../../components/card/ProgressCard'
import PiezometryAction from '../../../../piezometry/actions/PiezometryAction'
import ParameterAction from '../../../../referencial/components/parameter/actions/ParameterAction'
import { getStationArrowNav } from '../../../../utils/ActionUtils'
import {
    calculateThresholdResult,
} from '../../../../utils/AnalyseUtils'
import { getLocalStorageJson, setLocalStorageJson } from '../../../../utils/FormUtils'
import { componentHasHabilitations } from '../../../../utils/HabilitationUtil'
import { hasValue } from '../../../../utils/NumberUtil'
import { getSetting } from '../../../../utils/SettingUtils'
import { getLinks, getStationTitle } from '../../../../utils/StationUtils'
import SuivipcAction from '../qualitometer/actions/SuivipcAction'
import SuiviPCcriteriaPanel from './SuiviPCcriteriaPanel'
import SuiviPCSourcePanel from './SuiviPCSourcePanel'
import WaitAction from 'wait/WaitAction'
import OperationAction from 'quality/actions/OperationAction'
import AnalysisAction from 'quality/actions/AnalysisAction'
import DtoQualityThresholds from 'quality/dto/QualityThreshold/DtoQualityThresholds'
import PcMonitoringExportModal from 'quality/components/pcMonitoring/export/PcMonitoringExportModal'
import { exportModelFile, getModelFileType } from 'utils/ExportDataUtil'
import useApplicationSetting from 'utils/customHook/useApplicationSetting'
import useAccountSetting from 'utils/customHook/useAccountSetting'
import { SELECTION } from 'home/constants/RouteConstants'
import IndiceMonitoringTable from 'quality/components/hydrobio/monitoring/IndiceMonitoringTable'

const DEFAULT_SELECTION = '-1'

const CardUpdate = ({
    updatedField = {},
    setUpdatedField = () => {},
}) => {
    const {
        qualitometer,
        contributors,
        status,
        qualifications,
        supports,
        qualityCampaignStations,
        qualityCampaigns,
    } = useSelector(store => ({
        qualitometer: store.QualityReducer.qualitometer,
        contributors: store.ContributorReducer.contributors,
        status: store.QualityReducer.status,
        qualifications: store.QualityReducer.qualifications,
        supports: store.SupportReducer.supports,
        qualityCampaignStations: store.QualityReducer.qualityCampaignStations,
        qualityCampaigns: store.QualityReducer.qualityCampaigns,
    }), shallowEqual)


    const campaigns = useMemo(() => {
        const stationCampaigns = uniqBy(qualityCampaignStations.filter(({ stationId }) => stationId === qualitometer.id), 'campaignId')
        return stationCampaigns.map(({ campaignId }) => qualityCampaigns.find(({ id }) => id === campaignId)).filter(c => !!c)
    }, [qualityCampaignStations, qualitometer.id, qualityCampaigns])

    return (
        <div style={{ paddingBottom: '100' }}>
            <Card>
                <Grid container columnSpacing={2} rowSpacing={1} sx={{ padding: '0 10' }}>
                    <Grid item xs={3}>
                        <MultiContributorsAutocomplete
                            label={i18n.producer}
                            options={contributors}
                            onChange={v => setUpdatedField(prev => ({ ...prev, producer: v }))}
                            values={updatedField.producer}
                            keyLabel='labelDisplay'
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <MultiContributorsAutocomplete
                            label={i18n.laboratory}
                            options={contributors}
                            onChange={v => setUpdatedField(prev => ({ ...prev, labo: v }))}
                            values={updatedField.labo}
                            keyLabel='labelDisplay'
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <Select
                            label={i18n.support}
                            options={supports}
                            value={updatedField.support}
                            keyValue='code'
                            onChange={v => setUpdatedField(prev => ({ ...prev, support: v }))}
                            nullLabel='&nbsp;'
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <Select
                            label={i18n.status}
                            options={status}
                            value={updatedField.status}
                            onChange={v => setUpdatedField(prev => ({ ...prev, status: v }))}
                            nullLabel='&nbsp;'
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <Select
                            label={i18n.qualification}
                            options={qualifications}
                            value={updatedField.qualification}
                            onChange={v => setUpdatedField(prev => ({ ...prev, qualification: v }))}
                            nullLabel='&nbsp;'
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <Select
                            label={i18n.campaign}
                            options={campaigns}
                            value={updatedField.campaign}
                            keyvalue='id'
                            onChange={v => setUpdatedField(prev => ({ ...prev, campaign: v }))}
                            nullLabel='&nbsp;'
                        />
                    </Grid>
                </Grid>
            </Card>
        </div>
    )
}

CardUpdate.propTypes = {
    updatedField: PropTypes.shape({}),
    setUpdatedField: PropTypes.func,
}

const TableTab = ({
    analysis = [],
    operations = [],
    threshold = {},

    hydroDatas = [],
    piezoDatas = [],
    pluvioDatas = [],

    filter = {},

    setOperations = () => { },
    setAnalysis = () => { },
}) => {
    const dispatch = useDispatch()

    const {
        accountUser,
        qualitometer,
        qualitometers,

        isEditMode,
        selectedOperations,
    } = useSelector(store => ({
        accountUser: store.AccountReducer.accountUser,
        qualitometer: store.QualityReducer.qualitometer,
        qualitometers: store.QualityReducer.qualitometersLight,

        isEditMode: store.TmpReducer.isEditMode,
        selectedOperations: store.TmpReducer.selectedOperations,
    }), shallowEqual)

    const {
        value: isExportModalOpen,
        setTrue: openExportModal,
        setFalse: closeExportModal,
    } = useBoolean(false)

    const [updatedField, setUpdatedField] = useState({})

    useActions(() => {
        const readActions = !isEditMode && (accountUser.isAdmin == '1' || accountUser.consultant !== '1') && filter.horizon === 'operations' ? {
            edit: () => dispatch(TmpActionConstant.editMode()),
        } : {}

        const editAction = isEditMode && (accountUser.isAdmin == '1' || accountUser.consultant !== '1') && filter.horizon === 'operations' ? {
            cancel: () => {
                dispatch(TmpActionConstant.readMode())
                dispatch(TmpActionConstant.resetSelectedOperations())
            },
            save: () => {
                const updatedOperations = Object.keys(selectedOperations).filter(key => selectedOperations[key]).map(code => {
                    const [qualitoId, opeId] = code.split('#')
                    return {
                        ...updatedField,
                        support: !isUndefined(updatedField.support) ? parseInt(updatedField.support) : undefined,
                        operationId: parseInt(opeId),
                        qualitometerId: parseInt(qualitoId),
                    }
                })
                if (updatedOperations.length) {
                    dispatch(WaitAction.waitStart())
                    dispatch(OperationAction.operationsValidation(updatedOperations))
                        .then(isSuccess => {
                            if (!isSuccess) {
                                throw new Error('Error')
                            }
                            setUpdatedField({})
                            const promiseOperations = Promise.all(updatedOperations.map(ope => OperationAction.getOperation(ope.operationId, ope.qualitometerId)))
                            const promiseAnalysis = Promise.all(updatedOperations.map(ope => AnalysisAction.getAnalysis({ operations: [ope.operationId], stations: [ope.qualitometerId], lightMode: true }))).then(res => flatten(res))
                            Promise.all([promiseOperations, promiseAnalysis])
                                .then(([listOpe, listAna]) => {
                                    setOperations(prevOperations => [
                                        ...prevOperations.filter(o => !selectedOperations[`${o.qualitometer}#${o.id}`]),
                                        ...listOpe.map(o => new DtoOperation(o)),
                                    ])
                                    setAnalysis(prevAnalysis => [
                                        ...prevAnalysis.filter(a => !selectedOperations[`${a.qualitometer}#${a.operation}`]),
                                        ...listAna.map(a => new DtoAnalysisLight(a)),
                                    ])
                                    dispatch(TmpActionConstant.resetSelectedOperations())
                                })
                                .finally(() => dispatch(WaitAction.waitStop()))
                        })
                        .catch(() => {
                            dispatch(WaitAction.waitStop())
                        })
                } else {
                    dispatch(ToastrAction.info(i18n.noOperationSelectedError))
                }
            },
        } : {}

        const qualityFilters = {
            stations: [qualitometer.id],
            startDate: filter.startDate,
            endDate: filter.endDate,
            parameters: filter.parameters.length > 0 ? filter.parameters : undefined,
            selectionCode: filter.selection,
            groupCode: filter.group,

            producers: filter.producers,
            laboratories: filter.laboratories,
            point: filter.point,
            campaign: filter.campaign,
            support: isNil(filter.support) ? undefined : `${filter.support}`,
            fraction: filter.fraction,
            status: filter.status,
            qualification: filter.qualification,

            quantificationControl: filter.quantificationControl,
            detectionControl: filter.detectionControl,
        }

        return {
            links: getLinks(qualitometer),
            arrowNav: getStationArrowNav('quality', qualitometers, qualitometer?.id, s => dispatch(push(`/station/quality/${s.id}/suiviPC`))),
            exportList: [{
                onClick: openExportModal,
                label: i18n.excel,
            }],
            ...editAction,
            ...readActions,
            controlAnalysis: qualityFilters,
        }
    }, [qualitometer, qualitometers, accountUser, filter, isEditMode, selectedOperations, updatedField])

    return (
        <>
            {
                filter.displayInListMode && (
                    <PcMonitoringListTable
                        analysis={analysis}
                        operations={operations}

                        hydroDatas={hydroDatas}
                        piezoDatas={piezoDatas}
                        pluvioDatas={pluvioDatas}

                        displayAssociatedStations={filter.displayAssociatedStations}

                        isExportModalOpen={isExportModalOpen}
                        closeExportModal={closeExportModal}

                        filter={filter}
                    />
                )
            }
            {
                filter.horizon !== 'operations' && !filter.displayInListMode && (
                    <PcMonitoringRegroupTable
                        analysis={analysis}
                        threshold={threshold}

                        hydroDatas={hydroDatas}
                        piezoDatas={piezoDatas}
                        pluvioDatas={pluvioDatas}

                        regrouping={filter.regrouping}
                        horizon={filter.horizon}

                        groupEquivalences={filter.groupEquivalences}
                        displayAdvancedStatistics={filter.displayAdvancedStatistics}

                        isExportModalOpen={isExportModalOpen}
                        closeExportModal={closeExportModal}

                        filter={filter}
                    />
                )
            }
            {
                filter.horizon === 'operations' && !filter.displayInListMode && (
                    <>
                        <PcMonitoringTable
                            analysis={analysis}
                            operations={operations}
                            threshold={threshold}

                            hydroDatas={hydroDatas}
                            piezoDatas={piezoDatas}
                            pluvioDatas={pluvioDatas}

                            groupEquivalences={filter.groupEquivalences}
                            displayAdvancedStatistics={filter.displayAdvancedStatistics}

                            isExportModalOpen={isExportModalOpen}
                            closeExportModal={closeExportModal}

                            filter={filter}
                        />
                        {
                            isEditMode && (
                                <CardUpdate
                                    updatedField={updatedField}
                                    setUpdatedField={setUpdatedField}
                                />
                            )
                        }
                    </>
                )
            }
        </>
    )
}

TableTab.propTypes = {
    analysis: PropTypes.arrayOf(PropTypes.shape({/* DtoAnalysisLight + calculateThresholdResult */ })),
    operations: PropTypes.arrayOf(PropTypes.instanceOf(DtoOperation)),
    threshold: PropTypes.instanceOf(DtoQualityThresholds),

    hydroDatas: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        type: PropTypes.string,
        calculateFlux: PropTypes.bool,
        coeffFlux: PropTypes.number,
        measures: PropTypes.arrayOf(PropTypes.shape({})),
    })),
    piezoDatas: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        type: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
        ]),
        measures: PropTypes.arrayOf(PropTypes.shape({})),
    })),
    pluvioDatas: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        type: PropTypes.number,
        offset: PropTypes.number,
        cumul: PropTypes.number,
        measures: PropTypes.arrayOf(PropTypes.shape({})),
    })),

    filter: PropTypes.shape({}),

    setOperations: PropTypes.func,
    setAnalysis: PropTypes.func,
}

const GraphTab = ({
    analysis = [],
    operations = [],
    threshold = {},

    hydroDatas = [],
    piezoDatas = [],
    pluvioDatas = [],

    filter = {},
}) => {
    const dispatch = useDispatch()

    const {
        qualitometer,
        qualitometers,
        typeEnvironmentModels,
    } = useSelector(store => ({
        qualitometer: store.QualityReducer.qualitometer,
        qualitometers: store.QualityReducer.qualitometersLight,
        typeEnvironmentModels: store.ExportReducer.typeEnvironmentModels,
    }), shallowEqual)

    const {
        value: isExportModalOpen,
        setTrue: openExportModal,
        setFalse: closeExportModal,
    } = useBoolean(false)

    useActions(() => {
        return {
            links: getLinks(qualitometer),
            arrowNav: getStationArrowNav('quality', qualitometers, qualitometer?.id, s => dispatch(push(`/station/quality/${s.id}/suiviPC`))),
            exportList: [{
                onClick: openExportModal,
                label: i18n.excel,
            }],
        }
    }, [qualitometer, qualitometers])

    const exportModel = useMemo(() => typeEnvironmentModels.map((model) => {
        const fileNameSplit = model.split('.')
        const name = fileNameSplit.slice(0, -1).join('')
        const ext = fileNameSplit[fileNameSplit.length - 1]
        return {
            name,
            formats: [{
                type: getModelFileType(ext),
                callback: () => exportModelFile({
                    stationId: qualitometer.id.toString(),
                    stationCode: qualitometer.code,
                    stationType: qualitometer.typeName,
                    environmentModels: typeEnvironmentModels,
                    filenameModelExport: model,
                    qualityFilter: {
                        stations: [qualitometer.id],
                        startDate: filter.startDate,
                        endDate: filter.endDate,
                        parameters: filter.parameters.length > 0 ? filter.parameters : undefined,
                        selectionCode: filter.selection,
                        groupCode: filter.group,
                        threshold: filter.threshold,

                        producers: filter.producers,
                        laboratories: filter.laboratories,
                        point: filter.point,
                        campaign: filter.campaign,
                        support: isNil(filter.support) ? undefined : `${filter.support}`,
                        fraction: filter.fraction,
                        status: filter.status,
                        qualification: filter.qualification,

                        quantificationControl: filter.quantificationControl,
                        detectionControl: filter.detectionControl,
                    },
                }),
            }],
        }
    }), [dispatch, qualitometer.code, qualitometer.id, qualitometer.typeName, typeEnvironmentModels, filter])

    return (
        <>
            <PcMonitoringGraph
                analysis={analysis}
                operations={operations}
                thresholds={threshold?.thresholds}

                hydroDatas={hydroDatas}
                piezoDatas={piezoDatas}
                pluvioDatas={pluvioDatas}

                filter={filter}
            />
            <PcMonitoringExportModal
                analysis={analysis}
                operations={operations}
                threshold={threshold}

                hydroDatas={hydroDatas}
                piezoDatas={piezoDatas}
                pluvioDatas={pluvioDatas}

                groupEquivalences={filter.groupEquivalences}
                exportModel={exportModel}

                isExportModalOpen={isExportModalOpen}
                closeExportModal={closeExportModal}
            />
        </>
    )
}

GraphTab.propTypes = {
    analysis: PropTypes.arrayOf(PropTypes.shape({/* DtoAnalysisLight + calculateThresholdResult */ })),
    operations: PropTypes.arrayOf(PropTypes.instanceOf(DtoOperation)),
    threshold: PropTypes.instanceOf(DtoQualityThresholds),

    hydroDatas: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        type: PropTypes.string,
        calculateFlux: PropTypes.bool,
        coeffFlux: PropTypes.number,
        measures: PropTypes.arrayOf(PropTypes.shape({})),
    })),
    piezoDatas: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        type: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
        ]),
        measures: PropTypes.arrayOf(PropTypes.shape({})),
    })),
    pluvioDatas: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        type: PropTypes.number,
        offset: PropTypes.number,
        cumul: PropTypes.number,
        measures: PropTypes.arrayOf(PropTypes.shape({})),
    })),

    filter: PropTypes.shape({}),
}

const PcMonitoringApp = ({
    location = {},
}) => {
    const dispatch = useDispatch()
    const isMounted = useIsMounted()

    useEffect(() => {
        if (!componentHasHabilitations(H_STATION_QUALITO_SUIVI_PC)) { // A modifier quand react-router sera à jour
            dispatch(push('/unauthorized'))
        }
        dispatch(HomeAction.setHelpLink('qualite', '11'))
    }, [])

    const {
        qualitometer,
        qualitometers,
        qualityThresholds,
        parameters,
        parameterGroupUsage,
        associatedSites,
        accountUserSettings,
        selectedSearchValues,
    } = useSelector(store => ({
        qualitometer: store.QualityReducer.qualitometer,
        qualitometers: store.QualityReducer.qualitometersLight,
        qualityThresholds: store.QualityReducer.qualityThresholds,
        parameters: store.ParameterReducer.parameters,
        parameterGroupUsage: store.ParameterReducer.parameterGroupUsage,
        associatedSites: store.StationReducer.associatedSites,
        accountUserSettings: store.AccountReducer.accountUserSettings,
        selectedSearchValues: store.AdministrationReducer.selectedSearchValues,
    }), shallowEqual)

    const parametersIndex = useListIndexed(parameters, 'code')

    const settingSelection = useApplicationSetting(`SELECTION_FILTERS_${SELECTION.PC}`)
    const accountSettingSelection = useAccountSetting(`SELECTION_FILTERS_${SELECTION.PC}`)

    const settingThreshold = useApplicationSetting(`SEUILS_${THRESHOLD.PC}`, setting => (setting && setting !== '-1') ? parseInt(setting) : undefined)
    const accountSettingThreshold = useAccountSetting(`SEUILS_${THRESHOLD.PC}`, setting => (setting && setting !== '-1') ? parseInt(setting) : undefined)

    const {
        progress,
        isLoaded,
    } = useProgressDispatch(() => [
        dispatch(QualityAction.fetchQualifications()),
        dispatch(QualityAction.fetchStatus()),
        dispatch(ContributorAction.fetchContributors()),
        dispatch(ParameterAction.fetchParameters()),
        dispatch(UnitAction.fetchUnits()),
        dispatch(AdministrationAction.fetchSettings()),
        dispatch(SupportAction.fetchSupports()),
        dispatch(OldOperationAction.fetchRemarks()),
        dispatch(FractionAction.fetchFractions()),
        dispatch(ParameterAction.fetchParameterGroupUsage()),
        dispatch(QualityAction.fetchThresholds()),
        dispatch(HydrometryAction.fetchHydrometricStations()),
        dispatch(PluviometryAction.fetchPluviometers()),
        dispatch(PiezometryAction.fetchPiezometryDataTypes()),
        dispatch(PiezometryAction.fetchPiezometersLight()),
        dispatch(HydrometryAction.fetchHydrometryDataTypes()),
        dispatch(PluviometryAction.fetchPluviometryDataTypes()),
        dispatch(ParameterAction.fetchParameterGroups()),
        dispatch(ParameterAction.fetchParameterGroupLinks()),
    ])

    useTitle(() => [{
        title: i18n.quality,
        href: 'quality',
    }, {
        title: getStationTitle(qualitometer),
        href: `station/quality/${qualitometer?.id}`,
    }, {
        title: i18n.followUpPC,
        href: `station/quality/${qualitometer?.id}/suiviPC`,
    }], [qualitometer])

    useEffect(() => {
        dispatch(TmpActionConstant.init({
            isEditMode: false,
            selectedOperations: {},
        }))
        return () => {
            dispatch(TmpActionConstant.reset())
        }
    }, [])

    const [filter, setFilter] = useState(() => {
        const queryUrl = queryString.parse(location.search)
        const startDate = parseInt(queryUrl.startDate)
        const endDate = parseInt(queryUrl.endDate)
        const campaign = parseInt(queryUrl.campaign)

        const displayAssociatedStations = getLocalStorageJson('PC_MONITORING_ASSOCIATED_STATIONS')
        const groupEquivalences = getLocalStorageJson('PC_MONITORING_GROUP_EQUIVALENCES')

        return {
            startDate: !isNaN(startDate) ? startDate : undefined,
            endDate: !isNaN(endDate) ? endDate : undefined,
            parameters: [],
            selectionResults: [],
            campaign: !isNaN(campaign) ? campaign : undefined,
            horizon: 'operations',
            regrouping: 'max',
            displayInListMode: selectedSearchValues.quality?.displayInListMode,
            displayAssociatedStations,
            groupEquivalences,

            // ?default value on useEffect:
            // selectionResults,
            // selection,
            // threshold,
            // displayAdvancedStatistics,
            // piezoDatas,
            // hydroDatas,
            // pluvioDatas,
        }
    })
    /*
    filter = {
        startDate, // number
        endDate, // number
        parameters, // array[string]
        selection, // number
        selectionResults, // array of string
        group, // number
        threshold, // number

        producers, // array[number]
        laboratories, // array[number]
        point,
        campaign, // number
        support, // number
        fraction, // string
        status, // string
        qualification, // string

        quantificationControl, // boolean
        horizon,
        regrouping,
        detectionControl, // boolean
        displayInListMode, // boolean
        displayAssociatedStations, // boolean
        displayAdvancedStatistics, // boolean
        groupEquivalences, // boolean

        hydroDatas, // array[object]
        piezoDatas, // array[object]
        pluvioDatas, // array[object]
    }
    */

    const [analysisProgress, setAnalysisProgress] = useState(0)
    const {
        value: isAnalysisLoaded,
        setTrue: analysisLoaded,
        setFalse: analysisNotLoaded,
    } = useBoolean(false)

    const [operations, setOperations] = useState([])
    const [analysis, setAnalysis] = useState([])
    const [indices, setIndices] = useState([])

    const [associatedAnalysisProgress, setAssociatedAnalysisProgress] = useState(0)
    const {
        value: isAssociatedAnalysisLoaded,
        setTrue: associatedAnalysisLoaded,
        setFalse: associatedAnalysisNotLoaded,
    } = useBoolean(!filter.displayAssociatedStations)

    useEffect(() => {
        const threshold = settingThreshold ?? accountSettingThreshold
        const selection = settingSelection ?? accountSettingSelection ?? DEFAULT_SELECTION

        if (selection !== '-1') {
            dispatch(ParameterAction.fetchSelectionParameter(selection))
                .then((result = []) => setFilter(prevFilter => ({ ...prevFilter, selectionResults: result.map(r => r.parameterCode) })))
        }

        setFilter(prevFilter => ({ ...prevFilter, threshold, selection }))
    }, [dispatch]) // issue when modifying the selection field

    useEffect(() => {
        const displayAdvancedStatistics = getSetting(accountUserSettings, 'displayAdvancedStatistics') === '1'
        setFilter(prevFilter => ({ ...prevFilter, displayAdvancedStatistics }))
    }, [accountUserSettings])

    useEffect(() => {
        const piezoIds = associatedSites.filter(a => a.stationLinkedType === 1).map(site => site.stationLinkedId)
        const hydroIds = associatedSites.filter(a => a.stationLinkedType === 4).map(site => site.stationLinkedId)
        const pluvioIds = associatedSites.filter(a => a.stationLinkedType === 2).map(site => site.stationLinkedId)
        setFilter(prevFilter => ({
            ...prevFilter,
            piezoDatas: piezoIds.length ? piezoIds.map(id => ({ id })) : [{}],
            hydroDatas: hydroIds.length ? hydroIds.map(id => ({ id })) : [{}],
            pluvioDatas: pluvioIds.length ? pluvioIds.map(id => ({ id, cumul: 1 })) : [{ cumul: 1 }],
        }))
    }, [associatedSites])

    useUpdateEffect(() => {
        setLocalStorageJson('PC_MONITORING_GROUP_EQUIVALENCES', filter.groupEquivalences)
        setLocalStorageJson('PC_MONITORING_ASSOCIATED_STATIONS', filter.displayAssociatedStations)
    }, [filter])

    useEffect(() => {
        setAnalysis(prevAnalysis => prevAnalysis.filter(a => a.qualitometer !== qualitometer.id))
        setOperations(prevOperations => prevOperations.filter(a => a.qualitometer !== qualitometer.id))
        analysisNotLoaded()
        setAnalysisProgress(0)

        const controller = new AbortController()

        dispatch(QualityAction.fetchOperationAndAnalysis([qualitometer.id], controller.signal, setAnalysisProgress)).then(result => {
            if (isMounted()) {
                analysisLoaded(true)
                setAnalysis(prevAnalysis => [...prevAnalysis, ...result.analysis.map(a => new DtoAnalysisLight(a))])
                setOperations(prevOperations => [...prevOperations, ...result.operations.map(o => new DtoOperation(o))])
            }
        })
        return () => {
            controller.abort()
        }
    }, [qualitometer.id])

    useEffect(() => {
        setAnalysis(prevAnalysis => prevAnalysis.filter(a => a.qualitometer === qualitometer.id))
        setOperations(prevOperations => prevOperations.filter(a => a.qualitometer === qualitometer.id))
        if (!filter.displayAssociatedStations) {
            return () => {}
        }

        associatedAnalysisNotLoaded()
        setAssociatedAnalysisProgress(0)
        const controller = new AbortController()

        const listId = associatedSites.filter(a => a.stationLinkedType === 3).map(site => site.stationLinkedId)

        dispatch(QualityAction.fetchOperationAndAnalysis(listId, controller.signal, setAssociatedAnalysisProgress)).then(result => {
            if (isMounted()) {
                associatedAnalysisLoaded(true)
                setAnalysis(prevAnalysis => [...prevAnalysis, ...result.analysis.map(a => new DtoAnalysisLight(a))])
                setOperations(prevOperations => [...prevOperations, ...result.operations.map(o => new DtoOperation(o))])
            }
        })
        dispatch(SuivipcAction.fetchAssociatedStationsPoints(listId))
        return () => {
            controller?.abort()
        }
    }, [associatedSites, filter.displayAssociatedStations, isMounted, qualitometer.id])

    useEffect(() => {
        const code = qualitometers.find(q => q.id === qualitometer.id)?.code
        if (!isUndefined(code)) {
            QualityAction.promiseSearchIndices({ stations: [code] }).catch(() => []).then((res = []) => setIndices(res))
        }
    }, [qualitometer, qualitometers])

    // const [measuresProgress, setMeasuresProgress] = useState(0)
    const {
        value: isMeasuresLoaded,
        setTrue: measuresLoaded,
        setFalse: measuresNotLoaded,
    } = useBoolean(true)

    const [piezoDatas, setPiezoDatas] = useState([])
    const [hydroDatas, setHydroDatas] = useState([])
    const [pluvioDatas, setPluvioDatas] = useState([])

    useEffect(() => {
        const {
            piezoToLoad = [],
            piezoLoaded = [],
        } = groupBy(filter.piezoDatas?.filter(p => !isUndefined(p.id) && !isUndefined(p.type)), p => piezoDatas.some(pd => pd.id === p.id && pd.type === p.type) ? 'piezoLoaded' : 'piezoToLoad')

        const {
            hydroToLoad = [],
            hydroLoaded = [],
        } = groupBy(filter.hydroDatas?.filter(p => !isUndefined(p.id) && !isUndefined(p.type)), p => hydroDatas.some(pd => pd.id === p.id && pd.type === p.type) ? 'hydroLoaded' : 'hydroToLoad')

        const {
            pluvioToLoad = [],
            pluvioLoaded = [],
        } = groupBy(filter.pluvioDatas?.filter(p => !isUndefined(p.id) && !isUndefined(p.type)), p => pluvioDatas.some(pd => pd.id === p.id && pd.type === p.type && pd.cumul === p.cumul) ? 'pluvioLoaded' : 'pluvioToLoad')

        setPiezoDatas(data => piezoLoaded.map(pl => ({ ...pl, measures: data.find(d => d.id === pl.id && d.type === pl.type)?.measures ?? [] })))
        setHydroDatas(data => hydroLoaded.map(hl => ({ ...hl, measures: data.find(d => d.id === hl.id && d.type === hl.type)?.measures ?? [] })))
        setPluvioDatas(data => pluvioLoaded.map(pl => ({ ...pl, measures: data.find(d => d.id === pl.id && d.type === pl.type && d.cumul === pl.cumul)?.measures ?? [] })))

        if (piezoToLoad.length || hydroToLoad.length || pluvioToLoad.length) {
            // setMeasuresProgress(0)
            measuresNotLoaded()
            dispatch(SuivipcAction.loadStationMeasures(piezoToLoad, pluvioToLoad, hydroToLoad, filter.startDate, filter.endDate/*, setMeasuresProgress*/)).then(measuresTab => {
                const piezoMeasures = measuresTab.filter(({ stationType }) => stationType === 'piezo')
                const hydroMeasures = measuresTab.filter(({ stationType }) => stationType === 'hydro')
                const pluvioMeasures = measuresTab.filter(({ stationType }) => stationType === 'pluvio')
                setPiezoDatas(datas => [...datas, ...piezoMeasures])
                setHydroDatas(datas => [...datas, ...hydroMeasures])
                setPluvioDatas(datas => [...datas, ...pluvioMeasures])
                measuresLoaded()
            })
        }
    }, [dispatch, filter.endDate, filter.hydroDatas, filter.piezoDatas, filter.pluvioDatas, filter.startDate])

    const threshold = useMemo(() => hasValue(filter.threshold) ? qualityThresholds.find(t => t.thresholdCode === `${filter.threshold}`) : null, [filter.threshold, qualityThresholds])

    const analysisFormated = useMemo(() => {
        const thresholds = threshold?.thresholds || []
        return analysis.map(a => {
            const format = calculateThresholdResult(a, thresholds)
            const operation = operations.find(o => o.id === a.operation && o.qualitometer === a.qualitometer)
            return {
                ...a,
                ...format,
                producer: operation?.producer,
            }
        })
    }, [analysis, operations, threshold?.thresholds])

    const operationsFiltered = useMemo(() => {
        const startDateFilter = filter.startDate ? operations.filter(o => o.date >= filter.startDate) : operations
        const endDateFilter = filter.endDate ? startDateFilter.filter(o => o.date <= filter.endDate) : startDateFilter

        const campaignFilter = isUndefined(filter.campaign) ? endDateFilter : endDateFilter.filter(o => o.campaign === filter.campaign)
        const producerFilter = filter.producers?.length ? campaignFilter.filter(o => filter.producers.includes(o.producer)) : campaignFilter
        const pointFilter = isUndefined(filter.point) ? producerFilter: producerFilter.filter(o => o.point === filter.point && o.qualitometer === qualitometer.id)
        return isUndefined(filter.support) ? pointFilter : pointFilter.filter(o => `${o.support}` === filter.support)
    }, [filter.campaign, filter.endDate, filter.point, filter.producers, filter.startDate, filter.support, operations, qualitometer.id])

    const analysisFiltered = useMemo(() => {
        const operationFilter = analysisFormated.filter(a => operationsFiltered.some(o => o.id === a.operation && o.qualitometer === a.qualitometer))

        const quantificationFilter = filter.quantificationControl ? operationFilter.filter(a => a.remark === '1') : operationFilter
        const detectionFilter = filter.detectionControl ? quantificationFilter.filter(a => a.remark !== '2') : quantificationFilter

        const laboratoryFilter = filter.laboratories?.length ? detectionFilter.filter(a => filter.laboratories.includes(a.labo)) : detectionFilter

        const fractionFilter = isUndefined(filter.fraction) ? laboratoryFilter : laboratoryFilter.filter(a => a.fractionCode === filter.fraction)
        const statusFilter = isUndefined(filter.status) ? fractionFilter : fractionFilter.filter(a => a.status === `${filter.status}`)
        const qualificationFilter = isUndefined(filter.qualification) ? statusFilter : statusFilter.filter(a => a.qualification === `${filter.qualification}`)

        const selectionFilter = filter.selection !== '-1' && filter.selectionResults ? qualificationFilter.filter(a => filter.selectionResults.includes(a.parameter)) : qualificationFilter
        const parameterFilter = filter.parameters?.length ? selectionFilter.filter(a => filter.parameters.includes(a.parameter)) : selectionFilter
        return filter.group ? parameterFilter.filter(a => parameterGroupUsage.some(p => p.groupCode === filter.group && p.parameter === a.parameter)) : parameterFilter
    }, [analysisFormated, filter.detectionControl, filter.fraction, filter.group, filter.laboratories, filter.parameters, filter.qualification, filter.quantificationControl, filter.selection, filter.selectionResults, filter.status, operationsFiltered, parameterGroupUsage])

    const filteredIndices = useMemo(() => {
        return indices.filter(i => operationsFiltered.some(o => o.id === i.idOperation && o.qualitometer === i.stationId))
    }, [indices, operationsFiltered])

    const parametersFormatted = useMemo(() => {
        const uniqParameters = uniqBy(analysisFiltered, 'parameter').map(a => a.parameter)
        return uniqParameters.map(code => {
            const parameter = parametersIndex[code]
            return {
                code,
                name: parameter?.displayName ?? i18n.unknownParameter,
            }
        })
    }, [analysisFiltered, parametersIndex])

    const nbParam = uniqBy(analysisFiltered, 'parameter').length
    const nbSample = uniqBy(analysisFiltered, a => `${a.qualitometer}#${a.operation}`).length

    return (
        <div className='padding-left-2 padding-right-1'>
            {
                !isLoaded && <ProgressCard progress={progress} />
            }
            {
                !isAnalysisLoaded && <ProgressCard progress={analysisProgress} message={i18n.analysisLoading} />
            }
            {
                !isAssociatedAnalysisLoaded && <ProgressCard progress={associatedAnalysisProgress} message={i18n.associatedAnalysisLoading} />
            }
            {
                !isMeasuresLoaded && <ProgressCard indeterminate/* progress={measuresProgress}*/ message={i18n.measuresLoading} />
            }
            {
                isLoaded && isAnalysisLoaded && isMeasuresLoaded && isAssociatedAnalysisLoaded && (
                    <div className='row no-margin'>
                        <SuiviPCcriteriaPanel
                            onValidate={setFilter}
                            operations={operations}
                            analysis={analysis}
                            defaultFilter={filter}
                        />

                        <Tabs
                            defaultTab={RESULT}
                            tabs={[
                                {
                                    constant: RESULT,
                                    label: `${nbParam > 1 ? i18n.result : i18n.results} (${nbParam} ${nbParam > 1 ? i18n.parameter : i18n.parameters} / ${nbSample} ${nbSample > 1 ? i18n.nbSample : i18n.sample})`,
                                },
                                {
                                    constant: GRAPH,
                                    label: i18n.graph,
                                },
                                {
                                    constant: INDICE,
                                    label: i18n.indices,
                                },
                                {
                                    constant: SOURCE,
                                    label: i18n.source,
                                },
                            ]}
                        >
                            {tab => {
                                if (!analysisFiltered.length) {
                                    return (
                                        <MessageCard>{i18n.noDataToDisplay}</MessageCard>
                                    )
                                }
                                return (
                                    <>
                                        {
                                            tab === RESULT && (
                                                <TableTab
                                                    analysis={analysisFiltered}
                                                    operations={operationsFiltered}
                                                    threshold={threshold}

                                                    hydroDatas={hydroDatas}
                                                    piezoDatas={piezoDatas}
                                                    pluvioDatas={pluvioDatas}

                                                    filter={filter}

                                                    setOperations={setOperations}
                                                    setAnalysis={setAnalysis}
                                                />
                                            )
                                        }
                                        {
                                            tab === GRAPH && (
                                                <GraphTab
                                                    analysis={analysisFiltered}
                                                    operations={operationsFiltered}
                                                    threshold={threshold}

                                                    hydroDatas={hydroDatas}
                                                    piezoDatas={piezoDatas}
                                                    pluvioDatas={pluvioDatas}

                                                    filter={filter}
                                                />
                                            )
                                        }
                                        {
                                            tab === INDICE && (
                                                <IndiceMonitoringTable
                                                    indices={filteredIndices}
                                                    operations={operations}
                                                />
                                            )
                                        }
                                        {
                                            tab === SOURCE && (
                                                <SuiviPCSourcePanel
                                                    parameterList={parametersFormatted}
                                                />
                                            )
                                        }
                                    </>
                                )
                            }}
                        </Tabs>
                    </div>
                )
            }
        </div>
    )
}

PcMonitoringApp.propTypes = {
    location: PropTypes.shape({
        search: PropTypes.string,
    }),
}

export default PcMonitoringApp
