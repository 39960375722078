import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import Select from '../../../components/forms/Select'
import { isUndefined } from 'lodash'
import ToastrAction from 'toastr/actions/ToastrAction'
import MultiContributorsAutocomplete
    from '../../../referencial/components/contributor/components/MultiContributorsAutocomplete'

const ImportQualityDefaultValue = ({
    defaultValue = {},
    isEditMode = false,
    hydrobio = false,
    onChange = () => { },
}) => {
    const dispatch = useDispatch()
    const {
        contributors,
        qualifications,
        status,
        fractions,
        supports,
    } = useSelector(store => ({
        contributors: store.ContributorReducer.contributors,
        qualifications: store.QualityReducer.qualifications,
        status: store.QualityReducer.status,
        fractions: store.FractionReducer.fractions,
        supports: store.SupportReducer.supports,
    }), shallowEqual)

    useEffect(() => {
        const sampler = contributors.find(c => c.id === defaultValue.sampler)
        if (isUndefined(sampler)) return
        if (isUndefined(sampler.siret) && isUndefined(sampler.sandre)) {
            dispatch(ToastrAction.error(i18n.siretOrSandre))
            onChange({ sampler: undefined })
        }
    }, [defaultValue.sampler, contributors])

    useEffect(() => {
        const producer = contributors.find(c => c.id === defaultValue.producer)
        if (isUndefined(producer)) return
        if (isUndefined(producer.siret) && isUndefined(producer.sandre)) {
            dispatch(ToastrAction.error(i18n.siretOrSandre))
            onChange({ producer: undefined })
        }
    }, [defaultValue.producer, contributors])

    useEffect(() => {
        const labo = contributors.find(c => c.id === defaultValue.labo)
        if (isUndefined(labo)) return
        if (isUndefined(labo.siret) && isUndefined(labo.sandre)) {
            dispatch(ToastrAction.error(i18n.siretOrSandre))
            onChange({ labo: undefined })
        }
    }, [defaultValue.labo, contributors])

    useEffect(() => {
        const determiner = contributors.find(c => c.id === defaultValue.determiner)
        if (isUndefined(determiner)) return
        if (isUndefined(determiner.siret) && isUndefined(determiner.sandre)) {
            dispatch(ToastrAction.error(i18n.siretOrSandre))
            onChange({ determiner: undefined })
        }
    }, [defaultValue.labo, contributors])

    return (
        <fieldset>
            <legend>{i18n.defaultValue}</legend>
            <div>
                <div className='row no-margin padding-top-1'>
                    {/*      <Input
                    col={3}
                    title={i18n.stationCodePrefix}
                    value={defaultValue.stationCodePrefix}
                    onChange={(id) => onChange({ stationCodePrefix: id })}
                    disabled={!isEditMode}
                /> */}
                    <MultiContributorsAutocomplete
                        options={contributors}
                        label={i18n.sampler}
                        col={3}
                        onChange={(id) => onChange({ sampler: id })}
                        values={defaultValue.sampler}
                        disabled={!isEditMode}
                    />
                    <MultiContributorsAutocomplete
                        options={contributors}
                        label={i18n.producer}
                        col={3}
                        onChange={(id) => onChange({ producer: id }) }
                        values={defaultValue.producer}
                        disabled={!isEditMode}
                    />
                    {!hydrobio && (
                        <MultiContributorsAutocomplete
                            options={contributors}
                            label={i18n.laboratory}
                            col={3}
                            onChange={(id) => onChange({ labo: id }) }
                            values={defaultValue.labo}
                            disabled={!isEditMode}
                        />
                    )}
                    {hydrobio && (
                        <MultiContributorsAutocomplete
                            options={contributors}
                            label={i18n.determiner}
                            col={3}
                            onChange={(id) => onChange({ determiner: id }) }
                            values={defaultValue.determiner}
                            disabled={!isEditMode}
                        />
                    )}
                </div>
                <div className='row no-margin padding-top-1'>
                    <Select
                        col={3}
                        label={i18n.support}
                        options={supports}
                        value={defaultValue.support}
                        onChange={(code) => onChange({ support: parseInt(code) || undefined })}
                        clearFunction
                        displayWithCode
                        active={isEditMode}
                        disabled={!isEditMode}
                    />
                    {!hydrobio && (
                        <Select
                            col={3}
                            label={i18n.fractions}
                            options={fractions}
                            value={defaultValue.fraction}
                            onChange={(code) => onChange({ fraction: code })}
                            clearFunction
                            displayWithCode
                            active={isEditMode}
                            disabled={!isEditMode}
                        />
                    )}
                    <Select
                        col={3}
                        label={i18n.status}
                        options={status}
                        value={defaultValue.status}
                        onChange={(v) => onChange({ status: v })}
                        nullLabel='&nbsp;'
                        active={isEditMode}
                        disabled={!isEditMode}
                    />
                    <Select
                        col={3}
                        label={i18n.qualification}
                        options={qualifications}
                        value={defaultValue.qualification}
                        onChange={(v) => onChange({ qualification: v })}
                        nullLabel='&nbsp;'
                        active={isEditMode}
                        disabled={!isEditMode}
                    />
                </div>
            </div>
        </fieldset>
    )
}

ImportQualityDefaultValue.propTypes = {
    defaultValue: PropTypes.shape({
        sampler: PropTypes.number,
        producer: PropTypes.number,
        labo: PropTypes.number,
        determiner: PropTypes.number,
        support: PropTypes.number,
        fraction: PropTypes.number,
        status: PropTypes.number,
        qualification: PropTypes.number,
    }).isRequired,
    onChange: PropTypes.func.isRequired,
    isEditMode: PropTypes.bool,
    hydrobio: PropTypes.bool,
}

export default ImportQualityDefaultValue