import React, { Component } from 'react'
import { connect } from 'react-redux'
import StationAction from '../../actions/StationAction'
import { arrayOf, createIndex, getLabel } from '../../../utils/StoreUtils'
import DtoMapSituation from './dto/DtoMapSituation'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import CartographyPanel from '../../../components/map/CartographyPanel'
import ProgressCard from '../../../components/card/ProgressCard'
import AppStore from '../../../store/AppStore'
import HomeAction from '../../../home/actions/HomeAction'
import { getLogin, getSettingJson, getUser } from '../../../utils/SettingUtils'
import { setActions } from '../../../components/ActionUtil'
import { push } from 'connected-react-router'
import DtoMapSituationResult from './dto/DtoMapSituationResult'
import Select from '../../../components/forms/Select'
import Checkbox from '../../../components/forms/Checkbox'
import { hasValue } from '../../../utils/NumberUtil'
import { getI18nOrLabel, getI18nTitleData, getSandreLabel, searchAllCharacters } from '../../../utils/StringUtil'
import SectionList from '../../../components/list/section/SectionList'
import { setStationPointScale } from '../../../components/map/mapTreatments/AddingLayers'
import StationMapSituationCard from './StationMapSituationCard'
import { groupBy, isUndefined, orderBy, range } from 'lodash'
import { getIPS_Pie, getIPSLegend } from './paramsDialog/IPSDialog'
import {
    getFrequencyStatLegend,
    getFrequencyStatPie,
    getMapSituationRefUsedList,
} from './paramsDialog/FrequencyStatDialog'
import { getThresholdIndicatorLegend, getThresholdIndicatorPie } from './paramsDialog/ThresholdIndicatorDialog'
import { getIndicatorComment, getMapSituationCalendar, IPSColorIndex } from './MapSituationUtils'
import { MAP_SITUATION_CLASS, MAP_SITUATION_TYPE } from '../../constants/piezo/PiezometerStationConstants'
import moment from 'moment'
import Row from '../../../components/react/Row'
import { getDate, getMonthList } from '../../../utils/DateUtil'
import { getUrls } from '../../../alerting/components/dashboard/AlertMapUtils'
import AlertAction from '../../../alerting/actions/AlertAction'
import WaitAction from '../../../wait/WaitAction'
import { DESCRIPTION } from '../../../alerting/constants/AlertConstants'
import AlertMapPopin from '../../../alerting/components/dashboard/AlertMapPopin'
import { getOndeLegend, getOndePie } from './paramsDialog/OndeDialog'
import { getTrendThreshold, TrendLevelObject } from 'utils/TrendLevelUtil'
import { getMeasureArrayValues } from 'alerting/utils/MeasureUtil'
import DtoPiezometer from 'piezometry/dto/DtoPiezometer'
import { SANDRE } from 'referencial/constants/ReferencialConstants'
import DtoSandreCode from 'referencial/dto/DtoSandreCode'
import ReferencialAction from 'referencial/action/ReferencialAction'
import DtoParametrageDataType from 'piezometry/dto/DtoParametrageDataType'
import GlobalParametersDto from 'administration/dto/GlobalParameterDto'
import { execByType } from 'utils/StationUtils'
import { getDataWithHeaders } from 'utils/ExportDataUtil'
import User from 'account/dto/User'
import { getArrowDown, getArrowRight, getArrowUp } from 'utils/SvgUtil'
import Input from '../../../components/forms/Input'
import { StyledFieldSet, StyledLegend } from '../../../components/StyledElements'
import DtoAccountSettings from '../../../account/dto/DtoAccountSettings'
import AccountAction from '../../../account/actions/AccountAction'
import favorit from '../../../assets/pictures/markers/favori.gif'
import unfavori from 'assets/pictures/markers/unfavori.gif'
import { setLocalStorageJson } from '../../../utils/FormUtils'

const INDICATOR = {
    IPS: 1,
    THRESHOLD: 2,
    FREQUENCY_STAT: 3,
    ONDE: 4,
}

const PIEZO_SITUATION_MANAGER = 'PIEZO_SITUATION_MANAGER'
const HYDRO_SITUATION_MANAGER = 'HYDRO_SITUATION_MANAGER'
const PLUVIO_SITUATION_MANAGER = 'PLUVIO_SITUATION_MANAGER'

class MapSituationApp extends Component {
    constructor(props) {
        super(props)
        this.st = (window.location.href || document.URL).split('#')[1].split('/')[1]
        this.state = {
            dataLoaded: false,
            progress: 0,
            msSelected: null,
            showEmptyResults: false,
            showNoClass: true,
            resultsLoaded: false,
            selectedYear: moment().valueOf(),
            selectedMonth: moment().valueOf(),
            selectedDate: moment().valueOf(),
            existingDates: [], // liste des dates qui ont des résultats

            // éléments pour la popin station
            selectedSiteEvents: [],
            selectedSiteCampaigns: [],
            selectedSiteThreshold: [],
            selectedSite: {},
            onlyDescription: false,
            panel: DESCRIPTION,
            sortBy: 'date',
            searchValue: '',
        }
    }

    exportAction = (cb) => {
        const headers = ['code', 'city', 'name', 'date', 'category', 'classe', 'trend', 'threshold', 'comment', 'x', 'y', 'projection', 'cityCode', 'cityName', 'departmentNumber', 'department', `${i18n.code} ${i18n.watermass}`, 'watermass', 'aquifer', `${i18n.level} Min`, `${i18n.date} Min`, `${i18n.level} Max`, `${i18n.date} Max`, 'fillingTx']
        const ids = this.props.mapSituationResults.map(s => s.id)
        Promise.all([
            HomeAction.promiseDepartments(),
            StationAction.promiseWatermassLinksWithNames(this.st, ids),
            StationAction.promiseAquiferLinksWithNames(this.st, ids),
            StationAction.promiseLevelMinMaxStats(this.st, ids, this.state.msSelected.typeId),
        ]).then(jsonTab => {
            const deptsMap = createIndex(jsonTab[0], 'code')
            const watermassMap = createIndex(jsonTab[1], 'idStation')
            const aquiferMap = createIndex(jsonTab[2], 'idStation')
            const levelMap = createIndex(jsonTab[3], 'idStation')
            const data = this.getResults().map(d => ({
                code: d.code,
                city: d.city,
                name: d.name,
                date: getDate(d.indicatorDate),
                category: this.state.msSelected.indicatorType === INDICATOR.IPS && d.thresholdName ? i18n[d.thresholdName] : d.thresholdName,
                classe: d.resultClass,
                threshold: d.thresholdValue,
                comment: d.comment ? getIndicatorComment(d.comment) : null,
                x: d.x,
                y: d.y,
                projection: getSandreLabel(this.props.sandreCodes, SANDRE.PROJECTION, d.projection),
                trend: d.trendLabel === 'up' ? 1 : (d.trendLabel === 'right' ? 0 : (d.trendLabel === 'down' ? -1 : null)),
                departmentNumber: d.townCode?.substring(0, 2),
                department: deptsMap?.[d.townCode?.substring(0, 2)]?.name,
                [`${i18n.level} Min`]: levelMap?.[d.id]?.minValue,
                [`${i18n.date} Min`]: getDate(levelMap?.[d.id]?.minDate),
                [`${i18n.level} Max`]: levelMap?.[d.id]?.maxValue,
                [`${i18n.date} Max`]: getDate(levelMap?.[d.id]?.maxDate),
                fillingTx: levelMap?.[d.id]?.filledPercent,
                cityCode: d.townCode,
                cityName: this.props.citiesIndex[d.townCode]?.name,
                [`${i18n.code} ${i18n.watermass}`]: watermassMap[d.id]?.code_me,
                watermass: watermassMap[d.id]?.name,
                aquifer: aquiferMap[d.id]?.name,

            }))
            if (data.length && this.state.msSelected) {
                cb({
                    data: getDataWithHeaders(data, headers),
                    exportType: 'xlsx',
                    titleFile: this.state.msSelected.name,
                })
            } else {
                cb({
                    data: [],
                    exportType: 'xlsx',
                    titleFile: i18n.situation,
                })
            }
        })
    }

    getManagerConstant = () => {
        const stationType = (window.location.href || document.URL).split('#')[1].split('/')[1]
        return execByType(stationType, {
            piezometry: () => PIEZO_SITUATION_MANAGER,
            hydrometry: () => HYDRO_SITUATION_MANAGER,
            pluviometry: () => PLUVIO_SITUATION_MANAGER,
        })
    }

    settingActions = () => {
        const { globalParameters } = this.props
        const user = getUser()
        const managerLogin = globalParameters.find(param => param.parameter === this.getManagerConstant())?.value
        if (!managerLogin && (user.isAdmin === '1' || user.metadata === '1' || user.labo === '1')) {
            setActions({
                edit: () => this.props.push(`/${this.st}/mapSituations/settings`),
                exportAsync: this.exportAction,
            })
        } else if (managerLogin && (user.login === managerLogin || user.isAdmin === '1')) {
            setActions({
                edit: () => this.props.push(`/${this.st}/mapSituations/settings`),
                exportAsync: this.exportAction,
            })
        } else {
            setActions({
                exportAsync: this.exportAction,
            })
        }
    }

    componentDidMount() {
        AppStore.dispatch(HomeAction.setTitle([{
            title: i18n[this.st],
            href: this.st,
        }, {
            title: i18n.mapSituations,
            href: `${this.st}/mapSituations`,
        }]))

        this.props.fetchSandreCodes()
        this.props.fetchMapSituations(this.st).then(() => {
            if (!this.props.mapSituations.length) {
                this.setState({ dataLoaded: true, progress: 50, resultsLoaded: true })
            } else {
                const { accountUser } = this.props
                const msAuthorized = this.props.mapSituations.filter(m => (!m.contributorCode || accountUser.isAdmin === '1' || (m.contributorCode === accountUser.contributorCode)))
                const urlMapId = new URLSearchParams(this.props.location.search).get('mapId')
                const msSelected = this.props.mapSituations.find(ms => urlMapId ? ms.id === parseInt(urlMapId) : ms.default) || this.props.mapSituations[0]
                const msSelectedFiltered = msAuthorized.some(ms => ms.id === msSelected.id) ? msSelected : msAuthorized[0]
                if (msSelectedFiltered) {
                    this.setState({ msSelected: msSelectedFiltered, selectedDate: msSelectedFiltered.endDate || moment().valueOf() }, this.loadSelectedMapResults)
                } else {
                    this.setState({ dataLoaded: true, progress: 50, resultsLoaded: true })
                }
            }
            this.settingActions()
        })
    }

    loadSelectedMapResults = () => {
        const { msSelected, selectedYear, selectedMonth, selectedDate } = this.state
        setLocalStorageJson('MAP_SITUATION_SELECTED', msSelected)
        this.props.fetchMapSituationsResults(this.st, msSelected, selectedDate).then(() => {
            StationAction.promiseMapSituationDates(this.st, msSelected.id, moment(selectedYear).month(moment(selectedMonth).month()).valueOf()).then(existingDates => {
                this.setState({ dataLoaded: true, progress: 100, resultsLoaded: true, existingDates })
            })
        })
    }

    reloadExistingDates = () => {
        const { msSelected, selectedYear, selectedMonth } = this.state
        StationAction.promiseMapSituationDates(this.st, msSelected.id, moment(selectedYear).month(moment(selectedMonth).month()).valueOf()).then(existingDates => {
            this.setState({ dataLoaded: true, progress: 100, resultsLoaded: true, existingDates })
        })
    }

    getAccordeon = (stations, title, color, stabilityPercent, valueLabel) => {
        const titleAccordeon = (
            <div>
                <span style={{ fontWeight: 'bold' }}>{title}</span><span style={{ fontStyle: 'italic' }}>{` (${stations.length} ${getI18nTitleData(i18n.element, i18n.elements, stations)})`}</span>
            </div>
        )
        return (
            <div className='margin-bottom-2'>
                <SectionList title={titleAccordeon} color={color} loadIfOpen={stations.length > 50}>
                    {
                        stations.map(s => (
                            <StationMapSituationCard
                                onClick={this.onGetPopinInfo}
                                onHover={(site, huge) => setStationPointScale(site, huge)}
                                station={s}
                                stabilityPercent={stabilityPercent}
                                valueLabel={valueLabel}
                            />
                        ))
                    }
                </SectionList>
            </div>
        )
    }

    getDataTypes = () => execByType(this.st, {
        // pluviometry: () => this.props.pluviometryDataTypes,
        piezometry: () => this.props.piezometryDataTypes,
        hydrometry: () => this.props.hydrometryDataTypes,
        // installation: () => this.props.installationDataTypes,
        default: () => [],
    })

    getHash = r => searchAllCharacters(['code', 'name', 'city', 'townCode'].map(key => r[key]).join('   '))

    indicatorsListPanel = (displayedResults) => {
        const { msSelected } = this.state
        if (!displayedResults.length || !this.state.resultsLoaded || !msSelected) {
            return <h6>{ i18n.noResults }</h6>
        }
        const params = JSON.parse(msSelected.params || '{}')
        const searchValue = searchAllCharacters(this.state.searchValue)
        const filteredResults = displayedResults.filter(r => this.getHash(r).includes(searchValue))
        const isIPS = msSelected.indicatorType === INDICATOR.IPS
        const isFrequencyStat = msSelected.indicatorType === INDICATOR.FREQUENCY_STAT
        const group = groupBy(filteredResults, 'trendColor')
        const keys = isIPS ? orderBy(Object.keys(group), IPSColorIndex) :
            (isFrequencyStat ? orderBy(Object.keys(group), color => MAP_SITUATION_CLASS.find(c => c.imgColor === color)?.order || 20, 'desc')
                : Object.keys(group))
        const allAccordeons = keys.map(key => {
            const stations = group[key]
            return this.getAccordeon(
                stations,
                (isIPS && stations[0].thresholdName ? getI18nOrLabel(stations[0].thresholdName) : stations[0].thresholdName) || i18n.noResults,
                stations[0].trendColor === 'white' ? 'gray' : stations[0].trendColor || 'gray',
                params?.stabilityPercent ?? params?.fluctuationPercentage ?? 10,
                isIPS ? undefined : getLabel(this.getDataTypes(), msSelected.typeId, 'label', 'id')
            )
        })
        return (
            <>
                <StyledFieldSet className='padding-1' >
                    <StyledLegend>{`${msSelected?.name} - ${getI18nOrLabel(getLabel(MAP_SITUATION_TYPE, msSelected?.indicatorType))} ${getDate(msSelected.date)}`}</StyledLegend>
                    <div>
                        <Row>
                            <div className='col s6'>
                                <Row>
                                    <Input title={i18n.search} onChange={s => this.setState({ searchValue: s })}/>
                                </Row>
                                <div className='row no-margin' onClick={ () => this.setState({ showEmptyResults: !this.state.showEmptyResults }) }>
                                    <Checkbox checked={ this.state.showEmptyResults } label={ i18n.showStationsWithoutResults } />
                                </div>
                                <div className='row no-margin' onClick={ () => this.setState({ showNoClass: !this.state.showNoClass }) }>
                                    <Checkbox checked={ this.state.showNoClass } label={ i18n.showMapSituationNoClass } />
                                </div>
                                <Row>
                                    <span className='bold'>{i18n.chronicType}</span> : {getLabel(this.getDataTypes(), msSelected.typeId, 'label', 'id')}
                                </Row>
                                <Row>
                                    <span className='bold'>Valeur utilisée</span> : {getLabel(getMapSituationRefUsedList(this.st), params?.refUsed)}
                                </Row>
                                <Row>
                                    <span className='bold'>{i18n.validation}</span> : { params?.validOnly ? 'Données validées uniquement' : 'Données brutes et validées' }
                                </Row>
                                <Row>
                                    <span className='bold'>Pas de résultat</span> si + de {params?.nbDaysWithoutMeasuresMax ?? params?.nbDaysWithoutMeasuresRequired} jours sans données
                                </Row>
                                <Row>
                                    <span className='bold'>Stabilité</span> : Prend la valeur {params?.nbDaysForTrendCalculation ?? params?.monthTrendDays ?? 7} jours avant
                                </Row>
                            </div>
                            <div className='col s6'>
                                { this.getPie(filteredResults) }
                            </div>
                        </Row>
                    </div>
                </StyledFieldSet>
                <div className='padding-top-2'/>
                <div>
                    { allAccordeons }
                </div>
            </>
        )
    }

    changeWidgetOnDashboard = (ms) => {
        const obj = getSettingJson(this.props.accountUserSettings, 'MAP_SITUATION_DASHBOARD_WIDGET') ?? {}
        const key = `${this.st}-${ms.id}`
        const newObj = {
            ...obj,
            [key]: !obj[key],
        }
        AppStore.dispatch(AccountAction.updateOrCreateSetting(getLogin(), 'MAP_SITUATION_DASHBOARD_WIDGET', JSON.stringify(newObj)))
    }

    criteriasPanel = (displayedResults) => {
        const { accountUser } = this.props
        const { msSelected, selectedYear, selectedMonth, selectedDate, existingDates } = this.state

        const dashboardWidgetObj = getSettingJson(this.props.accountUserSettings, 'MAP_SITUATION_DASHBOARD_WIDGET') ?? {}
        const dashboardWidgetKey = `${this.st}-${msSelected?.id}`

        const years = range(moment().year(), 1949, -1).map(y => ({ value: y, label: y }))
        if (!msSelected) {
            return null
        }
        return (
            <div>
                <StyledFieldSet className='padding-1' >
                    <StyledLegend>{i18n.map}</StyledLegend>
                    <div className='row no-margin padding-top-1 padding-left-4 padding-right-4 valign-wrapper'>
                        <Select
                            col={ 8 }
                            options={ this.props.mapSituations.filter(m => (!m.contributorCode || accountUser.isAdmin === '1' || (m.contributorCode === accountUser.contributorCode))) }
                            label={ i18n.mapSituation }
                            onChange={ v => {
                                const newMs = this.props.mapSituations.find(ms => ms.id === v)
                                this.setState({
                                    msSelected: newMs,
                                    resultsLoaded: false,
                                    selectedMonth: newMs?.endDate || moment().valueOf(),
                                    selectedYear: newMs?.endDate || moment().valueOf(),
                                    selectedDate: newMs?.endDate || moment().valueOf(),
                                }, this.loadSelectedMapResults)
                            } }
                            value={msSelected?.id}
                            noNullValue
                        />
                        <div className='col s1'>
                            <img className='favorit-img clickable' id='favorit_img' src={ dashboardWidgetObj[dashboardWidgetKey] ? favorit : unfavori } onClick={ () => this.changeWidgetOnDashboard(msSelected) }/>
                        </div>
                        <div className='col s3'/>
                    </div>
                    <Row>
                        <div className='col s5'>
                            <h6 className='bold right'>{i18n.calculationDate} : </h6>
                        </div>
                        <div className='col s4'>
                            <Row>
                                <div className='col s7 no-padding'>
                                    <Select value={moment(selectedMonth).month()+1} options={getMonthList()} onChange={v => this.setState({ selectedMonth: moment().month(v-1).valueOf() }, this.reloadExistingDates)} noInputFieldClass noSort/>
                                </div>
                                <div className='col s5 no-padding'>
                                    <Select value={moment(selectedYear).year()} options={years} onChange={v => this.setState({ selectedYear: moment().year(v).valueOf() }, this.reloadExistingDates)} noInputFieldClass noSort/>
                                </div>
                            </Row>
                            <Row>
                                {getMapSituationCalendar(selectedYear, selectedMonth, existingDates, selectedDate, v => this.setState({ selectedDate: v }, this.loadSelectedMapResults))}
                            </Row>
                        </div>
                    </Row>
                </StyledFieldSet>

                <StyledFieldSet className='padding-1' >
                    <StyledLegend>{i18n.legend}</StyledLegend>
                    { this.getCriteriasLegend(displayedResults) }
                </StyledFieldSet>

            </div>
        )
    }

    getCriteriasLegend = (results) => {
        if (!results.length || !this.state.resultsLoaded) {
            return <h6>{ i18n.noResults }</h6>
        }
        const { msSelected } = this.state
        switch (msSelected.indicatorType) {
            case INDICATOR.IPS:
                return getIPSLegend()
            case INDICATOR.THRESHOLD:
                return getThresholdIndicatorLegend()
            case INDICATOR.ONDE:
                return getOndeLegend()
            default:
                return getFrequencyStatLegend(msSelected)
        }
    }

    getPie = (results) => {
        if (!results.length || !this.state.resultsLoaded) {
            return <h6>{ i18n.noResults }</h6>
        }
        const { msSelected, selectedDate } = this.state
        switch (msSelected.indicatorType) {
            case INDICATOR.IPS:
                return getIPS_Pie(results, selectedDate)
            case INDICATOR.THRESHOLD:
                return getThresholdIndicatorPie(results, selectedDate)
            case INDICATOR.ONDE:
                return getOndePie(results, selectedDate)
            default:
                return getFrequencyStatPie(results, selectedDate)
        }
    }

    onGetPopinInfo = (site, panelToOpen, eventsSortBy, onlyDescription) => {
        const urls = getUrls(site)
        if (urls && !onlyDescription) {
            this.props.waitStart()
            this.props.loadStationData(urls)
                .then(jsonTab => {
                    this.setState({
                        selectedSiteEvents: jsonTab[0],
                        selectedSiteCampaigns: jsonTab[1],
                        selectedSiteThreshold: jsonTab[2],
                        selectedSite: site,
                        panel: panelToOpen,
                        onlyDescription: false,
                        sortBy: eventsSortBy ? eventsSortBy : 'date',
                    })
                    this.props.waitStop()
                })
        } else {
            this.setState({
                selectedSite: site,
                onlyDescription,
                panel: panelToOpen,
            })
        }
    }

    getResults = () => {
        const { msSelected } = this.state
        const dataType = this.props.piezometryDataTypes.find(dt => dt.id === (msSelected || {}).typeId)
        const stationsPoints = this.props.mapSituationResults.map(s => {
            const markerIcon = (() => {
                if (msSelected?.indicatorType === INDICATOR.ONDE) {
                    return s.trendLabel || s.trendColor ? `pictures/markers/map_point_${s.trendColor || 'white'}.png` : null
                }
                if (isUndefined(s.trendLabel) && isUndefined(s.trendColor)) {
                    return null
                }
                switch (s.trendLabel) {
                    case 'up': return getArrowUp(s.trendColor ?? 'gray')
                    case 'down': return getArrowDown(s.trendColor ?? 'gray')
                    default: return getArrowRight(s.trendColor ?? 'gray')
                }
            })()
            const scale = s.trendLabel || s.trendColor ? 0.5 : 0.2
            return {
                ...s,
                markerIcon,
                cityName: s.city,
                typeName: this.st,
                scale: msSelected?.indicatorType === INDICATOR.ONDE ? scale + 0.2 : scale, // scale png when INDICATOR.ONDE
                onClick: this.onGetPopinInfo,
                unitSymbol: dataType?.unit,
            }
        })
        const displayedResults = this.state.showEmptyResults ? stationsPoints : stationsPoints.filter(s => hasValue(s.trendLabel) || hasValue(s.trendColor))
        return this.state.showNoClass ? displayedResults : displayedResults.filter(s => hasValue(s.trendColor) && s.trendColor !== 'gray')
    }

    render() {
        const { dataLoaded, progress, msSelected, onlyDescription } = this.state
        if (!dataLoaded) {
            return <ProgressCard progress={progress}/>
        }

        const displayedResults2 = this.getResults()
        const panels = [
            {
                id: 'criterias',
                icon: 'border_color',
                title: i18n.criterias,
                content: this.criteriasPanel(displayedResults2),
            },
        ]
        if (this.props.mapSituationResults.length) {
            panels.push({
                id: 'indicators',
                icon: 'view_list',
                title: i18n.indicators,
                content: this.indicatorsListPanel(displayedResults2),
            })
        }
        const { selectedSite, selectedSiteEvents, selectedSiteCampaigns, selectedSiteThreshold } = this.state
        const sortedResults = (msSelected || {}).indicatorType === INDICATOR.IPS ? orderBy(displayedResults2, d => IPSColorIndex(d.trendColor)) :
            ((msSelected || {}).indicatorType === INDICATOR.FREQUENCY_STAT ? orderBy(displayedResults2, d => MAP_SITUATION_CLASS.find(c => c.imgColor === d.trendColor)?.order || 20, 'desc')
                : displayedResults2)

        // POPIN
        const lastMeasure = hasValue(selectedSite.lastMeasureDate) && hasValue(selectedSite.lastMeasureValue) ? [{
            measureDate: selectedSite.lastMeasureDate,
            value: selectedSite.lastMeasureValue,
        }] : []

        const trendObject = TrendLevelObject(
            getMeasureArrayValues(lastMeasure),
            selectedSiteThreshold,
        )
        const trendThreshold = getTrendThreshold(trendObject, false)
        //

        const piezo = hasValue(selectedSite.id) ? {
            ...this.props.piezometer,
            ...selectedSite,
            lastMeasure,
            natureLabel: getSandreLabel(this.props.sandreCodes, SANDRE.PIEZOMETER_NATURE, this.props.piezometer.nature),
            creationDate: getDate(this.props.piezometer.creationDate),
            thresholdName: getI18nOrLabel(selectedSite?.thresholdName || ''),
            trendThreshold,
            color: selectedSite.trendColor,
        } : {}

        return (
            <div>
                <CartographyPanel
                    layers={['STATIONS_POINTS']}
                    stationsPoints={sortedResults}
                    stationsPanelTitle={i18n.stations}
                    heightToSubstract={450}
                    noStationPanel={false}
                    panels={panels}
                />
                <AlertMapPopin
                    siteData={{
                        selectedSite: piezo,
                        selectedSiteEvents,
                        selectedSiteCampaigns,
                        selectedSiteThreshold,
                    }}
                    onlyDescription={onlyDescription}
                    onClose={() => this.setState({ selectedSite: {}, panel: DESCRIPTION })}
                    citiesIndex={this.props.citiesIndex}
                    navigateTo={this.props.push}
                    onReload={(site, panel) => this.onGetPopinInfo(site, panel)}
                />
            </div>
        )
    }
}

MapSituationApp.propTypes = {
    mapSituations: arrayOf(DtoMapSituation),
    mapSituationResults: arrayOf(DtoMapSituationResult),
    fetchMapSituations: PropTypes.func,
    fetchMapSituationsResults: PropTypes.func,
    push: PropTypes.func,
    loadStationData: PropTypes.func,
    waitStop: PropTypes.func,
    waitStart: PropTypes.func,
    citiesIndex: PropTypes.object,
    piezometer: PropTypes.instanceOf(DtoPiezometer),
    sandreCodes: PropTypes.arrayOf(PropTypes.instanceOf(DtoSandreCode)),
    fetchSandreCodes: PropTypes.func,
    piezometryDataTypes: PropTypes.arrayOf(PropTypes.instanceOf(DtoParametrageDataType)),
    globalParameters: PropTypes.arrayOf(PropTypes.instanceOf(GlobalParametersDto)),
    accountUser: PropTypes.instanceOf(User),
    hydrometryDataTypes: arrayOf(DtoParametrageDataType),
    accountUserSettings: PropTypes.arrayOf(PropTypes.instanceOf(DtoAccountSettings)),
    location: PropTypes.shape({ search: PropTypes.string }),
}

const mapStateToProps = store => ({
    piezometer: store.StationReducer.piezometer,
    mapSituations: store.StationReducer.mapSituations,
    mapSituationResults: store.StationReducer.mapSituationResults,
    citiesIndex: store.CityReducer.citiesIndex,
    sandreCodes: store.ReferencialReducer.sandreCodes,
    piezometryDataTypes: store.PiezometryReducer.piezometryDataTypes,
    globalParameters: store.AdministrationReducer.globalParameters,
    accountUser: store.AccountReducer.accountUser,
    hydrometryDataTypes: store.HydrometryReducer.hydrometryDataTypes,
    accountUserSettings: store.AccountReducer.accountUserSettings,
})

const mapDispatchToProps = {
    fetchMapSituations: StationAction.fetchMapSituations,
    fetchMapSituationsResults: StationAction.fetchMapSituationsResults,
    fetchSandreCodes: ReferencialAction.fetchSandreCodes,
    loadStationData: AlertAction.loadStationData,
    waitStart: WaitAction.waitStart,
    waitStop: WaitAction.waitStop,
    push,
}

export default connect(mapStateToProps, mapDispatchToProps)(MapSituationApp)