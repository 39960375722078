import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import { getExport } from 'utils/linkUtils'
import { nbPerPageLabel } from 'referencial/constants/ReferencialConstants'
import Table from 'components/datatable/Table'
import DtoDistributionUnit from 'distributionUnit/dto/DtoDistributionUnit'
import { hasValue } from 'utils/NumberUtil'
import DistributionUnitNetworkModal from './DistributionUnitNetworkModal'
import InstallationAction from 'installation/actions/InstallationAction'
import { INSTALLATION_TYPE } from 'installation/constants/InstallationConstants'
import DtoNetworkTableLine from 'installation/components/network/dto/DtoNetworkTableLine'
import { push } from 'connected-react-router'
import { useDispatch } from 'react-redux'

const headers = ['network', 'code', 'name']

const DistributionUnitNetworksPanel = ({
    station = [],
    onChange = () => { },
    readMode = false,
    invertedHeaderStyle = false,
}) => {
    const dispatch = useDispatch()

    const [isOpen, setIsOpen] = useState(false)
    const [installationNetworks, setInstallationNetworks] = useState([])
    const [selectedNetwork, setSelectedNetwork] = useState({})

    useEffect(() => {
        InstallationAction.promiseNetworkTable({ installationType: INSTALLATION_TYPE.NETWORK })
            .then(json => {
                setInstallationNetworks(json?.data?.map(d => new DtoNetworkTableLine(d)))
            })
    }, [])

    const distriNetworks = station.link_networks.map((c, index) => ({
        ...c,
        index,
    }))

    const tableData = distriNetworks.map((dn) => {
        return {
            ...dn,
            network: dn.idNetwork,
        }
    })

    const exportAction = [getExport(tableData, i18n.networks, headers)]

    const actions = !readMode ? [{
        iconName: 'note_add',
        tooltip: i18n.add,
        onClick: () => {
            setSelectedNetwork({})
            setIsOpen(true)
        },
    }, exportAction] : [exportAction]

    const updateNetworks = (network) => {
        if (!hasValue(selectedNetwork.index)) {
            onChange({ link_networks: [...distriNetworks, network] })
        } else {
            onChange({ link_networks: distriNetworks.map((d, i) => i === setSelectedNetwork.index ? network : d) })
        }
    }

    const linkNetworksIds = station.link_networks.map(ln => ln.id)
    return (
        <>
            <Table
                title={i18n.networks}
                condensed
                data={tableData}
                type={{ headers }}
                sortable={!!tableData.length}
                actions={actions}
                round paging
                nbPerPageLabel={nbPerPageLabel}
                deletable={!readMode}
                invertedHeaderStyle={invertedHeaderStyle}
                onDelete={element => onChange({ link_networks: distriNetworks.filter(lc => lc.index !== element.index) })}
                onClick={element => dispatch(push(`/station/installation/${element.idNetwork}/dashboard`))}
            />
            {isOpen && (
                <DistributionUnitNetworkModal
                    idInstallation={station.id}
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                    network={selectedNetwork}
                    onRegister={updateNetworks}
                    networks={installationNetworks.filter(inet => !linkNetworksIds.includes(inet.id))}
                />
            )}
        </>
    )
}

DistributionUnitNetworksPanel.propTypes = {
    station: PropTypes.instanceOf(DtoDistributionUnit),
    onChange: PropTypes.func,
    readMode: PropTypes.bool,
    invertedHeaderStyle: PropTypes.bool,
}

export default DistributionUnitNetworksPanel