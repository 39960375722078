import fetch from 'isomorphic-fetch'
import {
    DELETE_PIEZOMETER_MEASURES,
    DELETE_PIEZOMETER_MEASURES_BRUTE,
    DELETE_PIEZOMETER_SAMPLES,
    RECEIVE_MULTIPLE_PIEZOMETERS_ADDITIONAL_MEASURES,
    RECEIVE_PIEZO_VALIDATION_CHART_MEASURES,
    RECEIVE_PIEZOMETER_ADDITIONAL_MEASURES,
    RECEIVE_PIEZOMETER_CHART_MEASURES,
    RECEIVE_PIEZOMETER_CHART_OPTIONS,
    RECEIVE_PIEZOMETER_FILES,
    RECEIVE_PIEZOMETER_INDICATORS,
    RECEIVE_PIEZOMETER_MEASURES,
    RECEIVE_PIEZOMETER_MEASURES_BRUTE,
    RECEIVE_PIEZOMETER_PICTURES,
    RECEIVE_PIEZOMETER_SAMPLES,
    RECEIVE_PIEZOMETER_STATISTICS,
    RECEIVE_ALL_PIEZOMETER_THRESHOLDS,
    RESET_PIEZOMETER_STATION,
    UPDATE_PIEZOMETER_ADDITIONAL_MEASURES,
    UPDATE_PIEZOMETER_CHART_OPTIONS,
    UPDATE_PIEZOMETER_MEASURES,
    UPDATE_PIEZOMETER_MEASURES_BRUTE,
    RECEIVE_PIEZOMETER_THRESHOLDS,
    UPDATE_PIEZOMETER_SAMPLES,
    UPDATE_PIEZOMETER_THRESHOLDS,
} from '../constants/piezo/PiezometerStationConstants'
import ApplicationConf from '../../conf/ApplicationConf'
import ToastrAction from 'toastr/actions/ToastrAction'
import WaitAction from 'wait/WaitAction'
import {
    checkAuth,
    checkError,
    fetchWithRetries,
    genericFetch,
    genericPromise,
    getAuthorization,
    getJson,
    promiseAllProgress,
} from '../../utils/ActionUtils'
import i18n from 'simple-react-i18n'
import moment from 'moment'
import PiezometryAction from '../../piezometry/actions/PiezometryAction'
import DtoPiezometryStationMeasure from '../dto/piezometer/DtoPiezometryStationMeasure'
import DtoPiezometerSample from '../dto/piezometer/DtoPiezometerSample'
import { chunk, flatten, orderBy } from 'lodash'
import { hasValue } from '../../utils/NumberUtil'
import { getDateWithHour } from '../../utils/DateUtil'
import { removeNullKeys } from '../../utils/StoreUtils'
import { getLogin } from '../../utils/SettingUtils'
import EventsAction from '../../events/actions/EventsAction'
import DtoPiezoMeasureLight from '../../piezometry/dto/chart/DtoPiezoMeasureLight'
import DtoPiezoChartMeasures from '../../piezometry/dto/chart/DtoPiezoChartMeasures'
import LogAction from '../../log/actions/LogAction'
import { PiezometryActionConstant } from 'piezometry/reducers/PiezometryReducer'
import AppStore from '../../store/AppStore'
import DtoPiezoThreshold from 'station/dto/piezometer/DtoPiezoThreshold'

const hardCodedPictures = []

const hardCodedFiles = []

const PiezometerStationAction = {
    receivePiezometerMeasures(measures) {
        return { type: RECEIVE_PIEZOMETER_MEASURES, measures }
    },
    promisePiezometerMeasures: id => fetch(ApplicationConf.piezometer.piezometerMeasuresBrute(id), {
        method: 'GET',
        headers: getAuthorization(),
    })
        .then(checkAuth)
        .then(getJson)
        .then(checkError),
    fetchPiezometerMeasures: (stationId, callback = () => { }) => dispatch => PiezometerStationAction.promisePiezometerMeasures(stationId)
        .then((json = []) => {
            if (!json.error) {
                dispatch(PiezometerStationAction.receivePiezometerMeasures(json))
            } else {
                dispatch(PiezometerStationAction.receivePiezometerMeasures([]))
            }
            dispatch(WaitAction.waitStop())
            callback()
        })
        .catch((err) => {
            dispatch(WaitAction.waitStop())
            dispatch(LogAction.logError(`${i18n.fetchError + i18n.piezometerMesures} : ${err}`))
            dispatch(ToastrAction.error(i18n.fetchError + i18n.piezometerMesures))
        }),
    promisePiezometerMeasuresWithRetries(stationId, dates = {}) {
        return fetchWithRetries(ApplicationConf.piezometer.piezometerMeasures(stationId), Object.assign({}, {
            method: dates.startDate || dates.endDate ? 'POST' : 'GET',
            headers: getAuthorization(),
        }, dates.startDate || dates.endDate ? { body: JSON.stringify(dates) } : {}
        )).then(checkAuth)
            .then(getJson)
            .then((json = []) => {
                return json
            })
    },
    receivePiezometerSamples(samples) {
        return { type: RECEIVE_PIEZOMETER_SAMPLES, samples }
    },
    fetchPiezometerSamples(stationId) {
        return (dispatch) => {
            return PiezometerStationAction.promisePiezometerSamples(stationId)
                .then((json = []) => {
                    dispatch(WaitAction.waitStop())
                    dispatch(PiezometerStationAction.receivePiezometerSamples(json))
                })
                .catch((err) => {
                    dispatch(WaitAction.waitStop())
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.piezometerSamples} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.piezometerSamples))
                })
        }
    },
    promisePiezometerSamples(stationId, dates = {}) {
        const input = {
            stationId,
            ...dates,
            dataType: -2,
        }
        return PiezometerStationAction.promisePiezoChartMeasures(input)
    },
    receivePiezometerMeasureBrute(piezometerMeasureBrute) {
        return { type: RECEIVE_PIEZOMETER_MEASURES_BRUTE, piezometerMeasureBrute }
    },
    promisePiezometerMeasureBrute(stationId, dates = {}) {
        return fetchWithRetries(ApplicationConf.piezometer.piezometerMeasuresBrute(stationId), Object.assign({}, {
            method: dates.startDate || dates.endDate ? 'POST' : 'GET',
            headers: getAuthorization(),
        }, dates.startDate || dates.endDate ? { body: JSON.stringify(dates) } : {}
        )).then(checkAuth).then(getJson)
    },
    fetchPiezometerMeasureBrute: (stationId, dates = {}, callback = () => {}) => dispatch => {
        return PiezometerStationAction.promisePiezometerMeasureBrute(stationId, dates)
            .then((json = []) => {
                if (!json.error) {
                    dispatch(PiezometerStationAction.receivePiezometerMeasureBrute(json))
                    callback()
                }
                dispatch(WaitAction.waitStop())
            })
            .catch(err => {
                dispatch(WaitAction.waitStop())
                dispatch(LogAction.logError(`${i18n.fetchError + i18n.piezometerMesures} : ${err}`))
                dispatch(ToastrAction.error(i18n.fetchError + i18n.piezometerMesures))
            })
    },
    receiveAllPiezometerThresholds(piezometerThresholds) {
        return { type: RECEIVE_ALL_PIEZOMETER_THRESHOLDS, piezometerThresholds }
    },
    promiseAllPiezometerThreshold: (displayCote) => {
        return fetch(ApplicationConf.piezometer.allThresholds(displayCote), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
    },
    fetchAllPiezometerThreshold: (displayCote) => dispatch => {
        return PiezometerStationAction.promiseAllPiezometerThreshold(displayCote)
            .then((json = []) => {
                dispatch(PiezometerStationAction.receiveAllPiezometerThresholds(json))
            })
            .catch(err => {
                dispatch(LogAction.logError(`${i18n.fetchError + i18n.piezometerThreshold} : ${err}`))
                dispatch(ToastrAction.error(i18n.fetchError + i18n.piezometerThreshold))
            })
    },

    receivePiezometerThresholds(piezometerThresholds) {
        return { type: RECEIVE_PIEZOMETER_THRESHOLDS, piezometerThresholds }
    },
    fetchPiezometerThresholds: (stationId, displayCote) => dispatch => {
        return PiezometerStationAction.promisePiezometerThresholds(stationId, displayCote)
            .then((json = []) => {
                if (!json.error) {
                    dispatch(PiezometerStationAction.receivePiezometerThresholds(json))
                }
                dispatch(WaitAction.waitStop())
            })
            .catch(err => {
                dispatch(WaitAction.waitStop())
                dispatch(LogAction.logError(`${i18n.fetchError + i18n.piezometerThreshold} : ${err}`))
                dispatch(ToastrAction.error(i18n.fetchError + i18n.piezometerThreshold))
            })
    },
    promisePiezometerThresholds(stationId, displayCote) {
        return fetch(ApplicationConf.piezometer.thresholds(stationId, displayCote), {
            method: 'GET',
            headers: getAuthorization(),
        }).then(checkAuth).then(getJson).then(checkError)
    },
    getPiezometerThresholds: (stationId, displayCote) => PiezometerStationAction.promisePiezometerThresholds(stationId, displayCote)
        .then((json = []) => {
            if (json.error) return []
            return json.map(t => new DtoPiezoThreshold(t))
        }),
    updatePiezometerThresholds(stationId, thresholds = [], callback = () => {}, displayCote) {
        return (dispatch) => {
            dispatch(WaitAction.waitStart())
            return fetch(ApplicationConf.piezometer.thresholds(stationId, displayCote), {
                method: 'PUT',
                headers: getAuthorization(),
                body: JSON.stringify(thresholds),
            }).then(checkAuth).then(getJson).then(checkError)
                .then(json => {
                    if (json.update === thresholds.length) {
                        dispatch({ type: UPDATE_PIEZOMETER_THRESHOLDS, thresholds })
                        dispatch(WaitAction.waitStop())
                        dispatch(ToastrAction.success(i18n.elementUpdateSuccess))
                        callback()
                    } else {
                        throw new Error(json)
                    }
                }).catch((err) => {
                    dispatch(WaitAction.waitStop())
                    dispatch(LogAction.logError(`${i18n.updateError + i18n.thresholds} : ${err}`))
                    dispatch(ToastrAction.error(i18n.updateError + i18n.thresholds))
                })
        }
    },
    receivePiezometerChartOptions(chartOptions) {
        return { type: RECEIVE_PIEZOMETER_CHART_OPTIONS, chartOptions }
    },
    promisePiezometerChartOptions(stationId) {
        return fetch(ApplicationConf.piezometer.chartOptions(stationId), {
            method: 'GET',
            headers: getAuthorization(),
        }).then(checkAuth).then(getJson).then(checkError).then(tab => tab.map(dt => ({ ...dt, dataType: hasValue(dt.dataType) ? dt.dataType : '-1' })))
    },
    fetchPiezometerChartOptions: (stationId, callback = () => {}) => dispatch => {
        dispatch(WaitAction.waitStart())
        return PiezometerStationAction.promisePiezometerChartOptions(stationId)
            .then((json = []) => {
                dispatch(PiezometerStationAction.receivePiezometerChartOptions(json))
                dispatch(WaitAction.waitStop())
                callback()
            })
            .catch(err => {
                dispatch(WaitAction.waitStop())
                dispatch(LogAction.logError(`${i18n.fetchError + i18n.piezometryOptions} : ${err}`))
                dispatch(ToastrAction.error(i18n.fetchError + i18n.piezometryOptions))
            })
    },
    updatePiezometerChartOptions(stationId, options = [], callback = () => {}) {
        return (dispatch) => {
            dispatch(WaitAction.waitStart())
            return fetch(ApplicationConf.piezometer.chartOptions(stationId), {
                method: 'PUT',
                headers: getAuthorization(),
                body: JSON.stringify(options),
            }).then(checkAuth).then(getJson).then(checkError)
                .then(json => {
                    if (json.update === options.length) {
                        dispatch({ type: UPDATE_PIEZOMETER_CHART_OPTIONS, chartOptions: options })
                        dispatch(WaitAction.waitStop())
                        dispatch(ToastrAction.success(i18n.elementUpdateSuccess))
                        callback()
                    } else {
                        throw new Error(json)
                    }
                }).catch((err) => {
                    dispatch(WaitAction.waitStop())
                    dispatch(LogAction.logError(`${i18n.updateError + i18n.piezometryOptions} : ${err}`))
                    dispatch(ToastrAction.error(i18n.updateError + i18n.piezometryOptions))
                })
        }
    },
    receivePiezometersIndicatorsById(indicators) {
        return { type: RECEIVE_PIEZOMETER_INDICATORS, indicators }
    },
    promisePiezometersIndicatorsById(ids, indicatorId) {
        return fetch(ApplicationConf.piezometer.getPiezometersIndicatorsById(), {
            method: 'POST',
            headers: getAuthorization(),
            body: JSON.stringify({ ids, indicatorId }),
        }).then(checkAuth).then(getJson).then(checkError)
    },
    fetchPiezometersIndicatorsById(ids, indicatorId, progressCallback = () => {}) {
        return (dispatch) => {
            const group = ids.length / 20 < 50 ? Math.floor(ids.length / 20) : 50
            const promises = chunk(ids, group > 0 ? group : 1).map(groupIds => PiezometerStationAction.promisePiezometersIndicatorsById(groupIds, indicatorId))

            return promiseAllProgress(promises, progressCallback).then(jsonResults => {
                dispatch(PiezometerStationAction.receivePiezometersIndicatorsById(flatten(jsonResults)))
            })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.piezometerSPI} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.piezometerSPI))
                })
        }
    },
    receivePiezometerPictures(pictures) {
        return { type: RECEIVE_PIEZOMETER_PICTURES, pictures }
    },
    fetchPiezometerPictures() {
        return (dispatch) => {
            // TODO: fetch real pictures in link with station with ID :  stationId)
            dispatch(PiezometerStationAction.receivePiezometerPictures(hardCodedPictures))
        }
    },
    receivePiezometerFiles(files) {
        return { type: RECEIVE_PIEZOMETER_FILES, files }
    },
    fetchPiezometerFiles() {
        return (dispatch) => {
            // TODO: fetch real files in link with station with ID : stationId
            dispatch(PiezometerStationAction.receivePiezometerFiles(hardCodedFiles))
        }
    },
    receivePiezoMeasuresStats(json) {
        return { type: RECEIVE_PIEZOMETER_STATISTICS, data: json }
    },
    promisePiezoMeasuresStats(id) {
        return genericPromise(ApplicationConf.piezometer.measuresStats(id))
    },
    fetchPiezoMeasuresStats(id) {
        return genericFetch(PiezometerStationAction.promisePiezoMeasuresStats(id), RECEIVE_PIEZOMETER_STATISTICS)
    },
    receivePiezometerAditionalMeasures(piezometerAdditionalMeasures) {
        return { type: RECEIVE_PIEZOMETER_ADDITIONAL_MEASURES, piezometerAdditionalMeasures }
    },
    promisePiezometerAdditionalMeasures(piezometerId, type, dates = {}) {
        const input = {
            stationId: piezometerId,
            ...dates,
            dataType: type,
            groupFunc: type === -2 ? 'SUM' : 'MAX',
            displayCote: type === -1 ? 1 : undefined
        }
        return PiezometerStationAction.promisePiezoChartMeasures(input)
    },
    oldPromisePiezometerAdditionalMeasures(piezometerId, type, body = {}) {
        return fetchWithRetries(ApplicationConf.piezometer.piezometerTypeMeasures(piezometerId, type), {
            method: 'POST',
            headers: getAuthorization(),
            body: JSON.stringify(body),
        }).then(checkAuth).then(getJson).then(checkError)
    },
    fetchPiezoValidationMeasures(piezometerId, type, body = {}) {
        return genericFetch(PiezometerStationAction.oldPromisePiezometerAdditionalMeasures(piezometerId, type, body), RECEIVE_PIEZO_VALIDATION_CHART_MEASURES)
    },
    fetchPiezometerAdditionalMeasures(piezometerId, types, callback = () => {}) {
        return function (dispatch) {
            return Promise.all(types.map(t => PiezometerStationAction.promisePiezometerAdditionalMeasures(piezometerId, t)))
                .then(jsonTab => {
                    const result = types.map((t, index) => ({ type: t, measures: jsonTab[index] }))
                    dispatch(PiezometerStationAction.receivePiezometerAditionalMeasures(result))
                    callback()
                }).catch((err) => {
                    dispatch(WaitAction.waitStop())
                    dispatch(LogAction.logError(`${i18n.additionalData} : ${err}`))
                    dispatch(ToastrAction.error(i18n.additionalData))
                })
        }
    },
    receiveMultiplePiezometersAditionalMeasures(multiplePiezometersAdditionalMeasures) {
        return { type: RECEIVE_MULTIPLE_PIEZOMETERS_ADDITIONAL_MEASURES, multiplePiezometersAdditionalMeasures }
    },
    loadPiezometersAdditionalMeasuresByDataType: (piezoIds, details, dataType, progressCallback = () => {}) => dispatch => {
        const allPromises = piezoIds.map(id => {
            const input = {
                stationId: id,
                ...details,
                dataType,
            }
            return PiezometerStationAction.promisePiezoChartMeasures(input).catch(() => {
                dispatch(ToastrAction.error(i18n.loadError))
                return []
            })
        })
        return promiseAllProgress(allPromises, progressCallback).then(jsonResults => {
            dispatch(PiezometerStationAction.receiveMultiplePiezometersAditionalMeasures(jsonResults.map((result) => ({
                type: dataType,
                measures: result,
            }))))
        })
    },
    loadAEPIndicatorsData: (piezoId, dates, additionalDataTypes, progressCallback = () => {}) => dispatch => {
        const allPromises = [
            PiezometryAction.promisePiezometerChartLandmarks([piezoId]),
            PiezometerStationAction.promisePiezometerAdditionalMeasures(piezoId, -1, dates).catch(() => {
                dispatch(ToastrAction.error(i18n.loadError))
                return []
            }),
            PiezometerStationAction.promisePiezometerAdditionalMeasures(piezoId, -2, dates).catch(() => {
                dispatch(ToastrAction.error(i18n.loadError))
                return []
            }),
            ...additionalDataTypes.map(d =>
                PiezometerStationAction.promisePiezometerAdditionalMeasures(piezoId, d, dates).catch(() => {
                    dispatch(ToastrAction.error(i18n.loadError))
                    return []
                })
            ),
        ]
        return promiseAllProgress(allPromises, progressCallback).then(jsonResults => {
            dispatch(PiezometryActionConstant.receivePiezometryChartLandmark(jsonResults[0]))
            return jsonResults.slice(1)
        })
    },
    updatePiezometerMeasures(piezometerId, measures, allMeasures, callback = () => {}) {
        return function (dispatch) {
            dispatch(WaitAction.waitStart())
            return fetch(ApplicationConf.piezometer.piezometerMeasures(piezometerId), {
                method: 'PUT',
                headers: getAuthorization(),
                body: JSON.stringify(measures),
            }).then(checkAuth).then(getJson).then(checkError)
                .then(json => {
                    const newMeasures = measures.filter(m => m.isNew).length
                    if (json.update === measures.length - newMeasures && json.insert === newMeasures) {
                        const updatedMeasures = allMeasures.map(m => new DtoPiezometryStationMeasure(Object.assign({}, m, {
                            initialHour: m.hour,
                            initialCreationDate: m.creationDate,
                        })))
                        dispatch({ type: UPDATE_PIEZOMETER_MEASURES, measures: updatedMeasures })
                        dispatch(WaitAction.waitStop())
                        dispatch(ToastrAction.success(i18n.measuresUpdated))
                        callback(updatedMeasures)
                    } else {
                        throw new Error(json)
                    }
                }).catch((err) => {
                    dispatch(WaitAction.waitStop())
                    dispatch(LogAction.logError(`${i18n.updateError + i18n.measures} : ${err}`))
                    dispatch(ToastrAction.error(i18n.updateError + i18n.measures))
                })
        }
    },
    deletePiezometerMeasures(piezometerId, measures, allMeasures, callback = () => {}) {
        return function (dispatch) {
            dispatch(WaitAction.waitStart())
            return fetch(ApplicationConf.piezometer.piezometerMeasures(piezometerId), {
                method: 'DELETE',
                headers: getAuthorization(),
                body: JSON.stringify(measures),
            }).then(checkAuth).then(getJson).then(checkError)
                .then(json => {
                    if (json.delete === measures.length) {
                        const updatedMeasures = allMeasures.filter(m => !m.selected)
                        dispatch({ type: DELETE_PIEZOMETER_MEASURES, measures: updatedMeasures })
                        dispatch(WaitAction.waitStop())
                        dispatch(ToastrAction.success(i18n.measuresDeleted))
                        callback(updatedMeasures)
                    } else {
                        throw new Error(json)
                    }
                }).catch((err) => {
                    dispatch(WaitAction.waitStop())
                    dispatch(LogAction.logError(`${i18n.deleteError + i18n.measures} : ${err}`))
                    dispatch(ToastrAction.error(i18n.deleteError + i18n.measures))
                })
        }
    },
    promiseUpdatePiezometerTypeMeasures(piezometerId, measures, type) {
        return fetch(ApplicationConf.piezometer.piezometerTypeMeasures(piezometerId, type), {
            method: 'PUT',
            headers: getAuthorization(),
            body: JSON.stringify(measures.map(m => ({
                ...m,
                importDate: m.importDate || m.updateDate || moment().valueOf(),
                updateLogin: m.updateLogin || getLogin(),
                updateDate: m.updateDate || moment().valueOf(),
            }))),
        }).then(checkAuth).then(getJson).then(checkError)
    },
    updatePiezometerTypeMeasures(piezometerId, measures, allMeasures, callback = () => {}, type) {
        return function (dispatch) {
            dispatch(WaitAction.waitStart())
            return PiezometerStationAction.promiseUpdatePiezometerTypeMeasures(piezometerId, (measures.map(m => ({
                ...m,
                importDate: m.importDate || m.updateDate || moment().valueOf(),
                updateLogin: m.updateLogin || getLogin(),
                updateDate: m.updateDate || moment().valueOf(),
            }))), type)
                .then(json => {
                    if (json.insert > 0 || json.update > 0 || json.delete > 0) {
                        const updatedMeasures = allMeasures.map(m => removeNullKeys({ ...m, updated: false }))
                        dispatch({ type: UPDATE_PIEZOMETER_ADDITIONAL_MEASURES, measures: updatedMeasures, dataType: type })
                        dispatch(WaitAction.waitStop())
                        dispatch(ToastrAction.success(i18n.measuresUpdated))
                        callback(updatedMeasures)
                    } else if (json.insert === 0 && json.update === 0 && json.delete === 0) {
                        dispatch(WaitAction.waitStop())
                        dispatch(ToastrAction.info(i18n.noUpdateOrInsertion))
                    } else {
                        throw new Error(json)
                    }
                }).catch((err) => {
                    dispatch(WaitAction.waitStop())
                    dispatch(LogAction.logError(`${i18n.updateError + i18n.measures} : ${err}`))
                    dispatch(ToastrAction.error(i18n.updateError + i18n.measures))
                })
        }
    },
    deletePiezometerTypeMeasures(piezometerId, measures, updatedMeasures, type, callback = () => {}) {
        return function (dispatch) {
            dispatch(WaitAction.waitStart())
            return Promise.all([
                fetch(ApplicationConf.piezometer.piezometerTypeMeasures(piezometerId, type), {
                    method: 'DELETE',
                    headers: getAuthorization(),
                    body: JSON.stringify(measures.map(m => ({ ...m, importDate: m.importDate || moment().valueOf() }))),
                }).then(checkAuth).then(getJson).then(checkError),
                updatedMeasures.length ? PiezometerStationAction.promiseUpdatePiezometerRawMeasures(piezometerId, updatedMeasures) : null,
            ].filter(p => !!p))
                .then(jsonTab => {
                    jsonTab.map(json => {
                        if (json.delete > 0 || json.update > 0) {
                            if (json.delete > 0) {
                                dispatch(WaitAction.waitStop())
                                dispatch(ToastrAction.success(json.delete.toString() + i18n.nMeasuresDeleted))
                                callback()
                            }
                        } else {
                            throw new Error(json)
                        }
                    })
                }).catch((err) => {
                    dispatch(WaitAction.waitStop())
                    dispatch(LogAction.logError(`${i18n.deleteError + i18n.measures} : ${err}`))
                    dispatch(ToastrAction.error(i18n.deleteError + i18n.measures))
                })
        }
    },
    updatePiezometerSamplesMeasures(piezometerId, samples, allSamples, callback = () => {}) {
        return function (dispatch) {
            dispatch(WaitAction.waitStart())
            return fetch(ApplicationConf.piezometer.piezometerSamples(piezometerId), {
                method: 'PUT',
                headers: getAuthorization(),
                body: JSON.stringify(samples),
            }).then(checkAuth).then(getJson).then(checkError)
                .then(json => {
                    const newSamples = samples.filter(m => m.isNew).length
                    if (json.update === samples.length - newSamples && json.insert === newSamples) {
                        const updatedSamples = allSamples.map(m => new DtoPiezometerSample(Object.assign({}, m, {
                            initialSampleHour: m.sampleHour,
                            initialSampleDate: m.initialSampleDate,
                        })))
                        dispatch({ type: UPDATE_PIEZOMETER_SAMPLES, samples: updatedSamples })
                        dispatch(WaitAction.waitStop())
                        dispatch(ToastrAction.success(i18n.measuresUpdated))
                        callback(updatedSamples)
                    } else {
                        throw new Error(json)
                    }
                }).catch((err) => {
                    dispatch(WaitAction.waitStop())
                    dispatch(LogAction.logError(`${i18n.updateError + i18n.samples} : ${err}`))
                    dispatch(ToastrAction.error(i18n.updateError + i18n.samples))
                })
        }
    },
    deletePiezometerSamples(piezometerId, samples, allSamples, callback = () => {}) {
        return function (dispatch) {
            dispatch(WaitAction.waitStart())
            return fetch(ApplicationConf.piezometer.piezometerSamples(piezometerId), {
                method: 'DELETE',
                headers: getAuthorization(),
                body: JSON.stringify(samples),
            }).then(checkAuth).then(getJson).then(checkError)
                .then(json => {
                    if (json.delete === samples.length) {
                        const updatedSamples = allSamples.filter(m => !m.selected)
                        dispatch({ type: DELETE_PIEZOMETER_SAMPLES, samples: updatedSamples })
                        dispatch(WaitAction.waitStop())
                        dispatch(ToastrAction.success(i18n.measuresDeleted))
                        callback(updatedSamples)
                    } else {
                        throw new Error(json)
                    }
                }).catch((err) => {
                    dispatch(WaitAction.waitStop())
                    dispatch(LogAction.logError(`${i18n.deleteError + i18n.samples} : ${err}`))
                    dispatch(ToastrAction.error(i18n.deleteError + i18n.samples))
                })
        }
    },
    purgePiezometerRawMeasures(piezometerId, dates, dataType, selectFilters, codepoint, callback = () => {}) {
        return function (dispatch) {
            dispatch(WaitAction.waitStart())
            return fetch(ApplicationConf.piezometer.piezometerMeasuresBrute(piezometerId), {
                method: 'DELETE',
                headers: getAuthorization(),
                body: JSON.stringify({ ...dates, dataType, selectFilters, codepoint }),
            }).then(checkAuth).then(getJson).then(checkError)
                .then(json => {
                    if (json.delete > 0 || json.update > 0) {
                        if (json.delete > 0) {
                            dispatch(WaitAction.waitStop())
                            dispatch(ToastrAction.success(json.delete.toString() + i18n.nMeasuresDeleted))
                            callback()
                        }
                    } else {
                        throw new Error(json)
                    }
                }).catch((err) => {
                    dispatch(WaitAction.waitStop())
                    dispatch(LogAction.logError(`${i18n.deleteError + i18n.measures} : ${err}`))
                    dispatch(ToastrAction.error(i18n.deleteError + i18n.measures))
                })
        }
    },

    resetPiezometerMeasures(piezometerId, startDate, endDate, dataType, selectFilters, codepoint, callback = () => {}) {
        return function (dispatch) {
            dispatch(WaitAction.waitStart())
            return fetch(ApplicationConf.piezometer.piezometerMeasuresReset(piezometerId), {
                method: 'DELETE',
                headers: getAuthorization(),
                body: JSON.stringify({ id: piezometerId, startDate, endDate, dataType, selectFilters, codepoint }),
            }).then(checkAuth).then(getJson).then(checkError).then(json => {
                if (json.update > 0) {
                    dispatch(EventsAction.fetchStationEvents('piezometry', piezometerId))
                    dispatch(WaitAction.waitStop())
                    dispatch(ToastrAction.success(i18n.measuresUpdated))
                    callback()
                } else {
                    throw new Error(json)
                }
            }).catch((err) => {
                dispatch(WaitAction.waitStop())
                dispatch(LogAction.logError(`${i18n.deleteError + i18n.measures} : ${err}`))
                dispatch(ToastrAction.error(i18n.deleteError + i18n.measures))
            })
        }
    },
    deletePiezometerRawMeasures(piezometerId, measures, allMeasures, callback = () => {}) {
        return function (dispatch) {
            dispatch(WaitAction.waitStart())
            const newMeasures = measures.map(m => Object.assign({}, m, {
                deletion: '1',
                isNew: !hasValue(m.obtainingMode),
                obtainingMode: 'M',
                hour: m.initialHour,
                creationDate: m.initialCreationDate,
                importDate: m.importDate || m.updateDate || moment().valueOf(),
                updateDate: m.updateDate || moment.valueOf(),
                updateLogin: m.updateLogin || getLogin(),
            }))
            return fetch(ApplicationConf.piezometer.piezometerTypeMeasures(piezometerId, 3), {
                method: 'PUT',
                headers: getAuthorization(),
                body: JSON.stringify(newMeasures),
            }).then(checkAuth).then(getJson).then(checkError)
                .then(json => {
                    if (json.delete > 0) {
                        const updatedMeasures = allMeasures.filter(m => !m.selected)
                        dispatch({ type: DELETE_PIEZOMETER_MEASURES_BRUTE, measures: updatedMeasures })
                        dispatch(WaitAction.waitStop())
                        dispatch(ToastrAction.success(i18n.measuresDeleted))
                        callback(updatedMeasures)
                    } else {
                        throw new Error(json)
                    }
                }).catch((err) => {
                    dispatch(WaitAction.waitStop())
                    dispatch(LogAction.logError(`${i18n.deleteError + i18n.measures} : ${err}`))
                    dispatch(ToastrAction.error(i18n.deleteError + i18n.measures))
                })
        }
    },
    promiseUpdatePiezometerRawMeasures(piezometerId, measures) {
        return fetch(ApplicationConf.piezometer.piezometerMeasuresBrute(piezometerId), {
            method: 'PUT',
            headers: getAuthorization(),
            body: JSON.stringify(measures.map(m => ({
                ...m,
                importDate: m.importDate || m.updateDate || moment().valueOf(),
                updateLogin: m.updateLogin || getLogin(),
                updateDate: m.updateDate || moment().valueOf(),
            }))),
        }).then(checkAuth).then(getJson).then(checkError)
    },
    updatePiezometerRawMeasures(piezometerId, measures, allMeasures, callback = () => {}) {
        return function (dispatch) {
            dispatch(WaitAction.waitStart())
            return PiezometerStationAction.promiseUpdatePiezometerRawMeasures(piezometerId, measures)
                .then(json => {
                    if (json.insert > 0 || json.update > 0 || json.delete > 0) {
                        const updatedMeasures = allMeasures.map(m => removeNullKeys({ ...m, updated: false }))
                        dispatch({ type: UPDATE_PIEZOMETER_MEASURES_BRUTE, measures: updatedMeasures })
                        dispatch(WaitAction.waitStop())
                        dispatch(ToastrAction.success(i18n.measuresUpdated))
                        callback(updatedMeasures)
                    } else {
                        throw new Error(json)
                    }
                }).catch((err) => {
                    dispatch(WaitAction.waitStop())
                    dispatch(LogAction.logError(`${i18n.updateError + i18n.measures} : ${err}`))
                    dispatch(ToastrAction.error(i18n.updateError + i18n.measures))
                })
        }
    },
    replacePiezometerRawMeasures(piezometerId, data, oldMeasures, cb = () => {}) {
        return function (dispatch) {
            dispatch(WaitAction.waitStart())
            return fetch(ApplicationConf.piezometer.piezometerMeasuresReplace(piezometerId), {
                method: 'PUT',
                headers: getAuthorization(),
                body: JSON.stringify(data),
            }).then(checkAuth).then(getJson).then(checkError)
                .then(json => {
                    if (json.insert > 0 || json.delete > 0) {
                        const newMeasures = [
                            ...oldMeasures.filter(measure => getDateWithHour(measure.date, measure.hour).valueOf() < data.start || getDateWithHour(measure.date, measure.hour).valueOf() > data.end),
                            ...data.measures,
                        ]
                        dispatch({ type: UPDATE_PIEZOMETER_MEASURES_BRUTE, measures: newMeasures })
                        dispatch(WaitAction.waitStop())
                        dispatch(ToastrAction.success(i18n.measuresUpdated))
                        cb(json)
                    } else {
                        throw new Error(json)
                    }
                }).catch((err) => {
                    dispatch(WaitAction.waitStop())
                    dispatch(LogAction.logError(`${i18n.updateError + i18n.measures} : ${err}`))
                    dispatch(ToastrAction.error(i18n.updateError + i18n.measures))
                })
        }
    },
    loadPiezometerAdditionalMeasures(piezometerId, callback = () => {}) {
        return (dispatch) => {
            return PiezometryAction.promisePiezometryDataTypes()
                .then((json = []) => {
                    const types = orderBy(json, 'order').map(t => t.id)
                    dispatch(PiezometryActionConstant.receivePiezometryDataTypes(json))
                    dispatch(PiezometerStationAction.fetchPiezometerAdditionalMeasures(piezometerId, types, callback))
                })
                .catch((err) => {
                    dispatch(WaitAction.waitStop())
                    dispatch(LogAction.logError(`${i18n.additionalData} : ${err}`))
                    dispatch(ToastrAction.error(i18n.additionalData))
                })
        }
    },
    promisePiezoChartMeasures(input) {
        return genericPromise(ApplicationConf.piezometer.chartMeasures(), 'POST', input)
    },
    loadPiezoChartMeasures(
        listOfInputs, // liste d'objets correspondants à ChartMeasuresInput dans le service piézo
        progressCallback = () => {}) {
        return (dispatch) => {
            const promises = listOfInputs.map(input => PiezometerStationAction.promisePiezoChartMeasures(input))
            return promiseAllProgress(promises, progressCallback).then(jsonResults => {
                const data = jsonResults.map((json, idx) => {
                    return new DtoPiezoChartMeasures({ ...listOfInputs[idx], measures: json.map(j => new DtoPiezoMeasureLight(j)) })
                })
                return dispatch({ type: RECEIVE_PIEZOMETER_CHART_MEASURES, data })
            })
                .catch((err) => {
                    dispatch(WaitAction.waitStop())
                    dispatch(LogAction.logError(`${i18n.measures} : ${err}`))
                    dispatch(ToastrAction.error(i18n.measures))
                })
        }
    },
    getPiezoChartMeasures: dataInput => dispatch => PiezometerStationAction.promisePiezoChartMeasures(dataInput)
        .then(json => {
            return new DtoPiezoChartMeasures({ ...dataInput, measures: json.map(j => new DtoPiezoMeasureLight(j)) })
        })
        .catch((err) => {
            dispatch(LogAction.logError(`${i18n.measures} : ${err}`))
            dispatch(ToastrAction.error(i18n.measures))
        }),
    getPiezosChartMeasures(listOfInputs) { // liste d'objets correspondants à ChartMeasuresInput dans le service piézo
        const promises = listOfInputs.map(input => PiezometerStationAction.promisePiezoChartMeasures(input))
        return Promise.all(promises).then(jsonResults => {
            return jsonResults.map((json, idx) => {
                return new DtoPiezoChartMeasures({ ...listOfInputs[idx], measures: json.map(j => new DtoPiezoMeasureLight(j)) })
            })
        })
            .catch((err) => {
                AppStore.dispatch(WaitAction.waitStop())
                AppStore.dispatch(LogAction.logError(`${i18n.measures} : ${err}`))
                AppStore.dispatch(ToastrAction.error(i18n.measures))
            })
    },
    reset() {
        return {
            type: RESET_PIEZOMETER_STATION,
        }
    },
}

export default PiezometerStationAction
