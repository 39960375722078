export default class PerimeterDto {
    constructor(obj) {
        this.id = obj.id // Int
        this.folder = obj.folder // Int
        this.name = obj.name // String
        this.perimeterType = obj.perimeterType // Int
        this.cities = obj.cities?.trim() ? obj.cities.split(',') : [] // Option[String]
        this.parcels = obj.parcels // Int
        this.privateOwners = obj.privateOwners // Int
        this.companyOwners = obj.companyOwners // Int
        this.zoning = obj.zoning // String
        this.status = obj.status // Option[Int]
    }
}