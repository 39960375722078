import PropTypes from 'prop-types'
import React, { Component } from 'react'
import i18n from 'simple-react-i18n'
import Checkbox from '../../../../components/forms/Checkbox'
import Row from '../../../../components/react/Row'

class CSVGenericAdvancedModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            unwantedColumns: props.unwantedColumns,
        }
    }

    render() {
        const unwanted = (value) => this.state.unwantedColumns.find(v => v === value)
        const handle = (value, checked) => {
            if (checked) {
                this.setState({ unwantedColumns: this.state.unwantedColumns.filter(v => v !== value) })
                this.props.onChange(this.state.unwantedColumns.filter(v => v !== value))
            } else {
                this.setState({ unwantedColumns: [...this.state.unwantedColumns, value] })
                this.props.onChange([...this.state.unwantedColumns, value])
            }
        }
        return (
            <div>
                <Row>
                    <Checkbox label={ i18n.directory } checked={ !unwanted('directory') } onChange={ v => handle('directory', v) } />
                </Row>
                <Row>
                    <Checkbox label={ i18n.excelTab } checked={ !unwanted('excelTab') } onChange={ v => handle('excelTab', v) } />
                </Row>
                <Row>
                    <Checkbox label={ i18n.nbParam } checked={ !unwanted('nbParam') } onChange={ v => handle('nbParam', v) } />
                </Row>
                <Row>
                    <Checkbox label={ i18n.autoDetectCodeFull } checked={ !unwanted('autoDetectCode') } onChange={ v => handle('autoDetectCode', v) } />
                </Row>
                <Row>
                    <Checkbox label={ i18n.conditionColumn } checked={ !unwanted('conditionColumn') } onChange={ v => handle('conditionColumn', v) } />
                </Row>
                <Row>
                    <Checkbox label={ i18n.requiredValue } checked={ !unwanted('requiredValue') } onChange={ v => handle('requiredValue', v) } />
                </Row>
                <Row>
                    <Checkbox label={ i18n.coeff } checked={ !unwanted('coeff') } onChange={ v => handle('coeff', v) } />
                </Row>
                <Row>
                    <Checkbox label={ i18n.offset } checked={ !unwanted('offset') } onChange={ v => handle('offset', v) } />
                </Row>
                <Row>
                    <Checkbox label={ i18n.sandreCode } checked={ !unwanted('sandreCode') } onChange={ v => handle('sandreCode', v) } />
                </Row>
                <Row>
                    <Checkbox label={ i18n.value } checked={ !unwanted('value') } onChange={ v => handle('value', v) } />
                </Row>
                <Row>
                    <Checkbox label={ i18n.unit } checked={ !unwanted('unit') } onChange={ v => handle('unit', v) } />
                </Row>
                <Row>
                    <Checkbox label={ i18n.pointPrelevement } checked={ !unwanted('pointPrelevement') } onChange={ v => handle('pointPrelevement', v) } />
                </Row>
                {
                    this.props.dataTypes.map(d => (
                        <Row>
                            <Checkbox label={ d.label } checked={ !unwanted(`data${d.id}`) } onChange={ v => handle(`data${d.id}`, v) } />
                        </Row>
                    ))
                }
            </div>
        )
    }
}

CSVGenericAdvancedModal.propTypes = {
    unwantedColumns: PropTypes.arrayOf(PropTypes.string),
    onChange: PropTypes.func,
    dataTypes: PropTypes.arrayOf(PropTypes.object),
}

export default CSVGenericAdvancedModal
