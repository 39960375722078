import { createAsyncThunk } from '@reduxjs/toolkit'
import ApplicationConf from '../../conf/ApplicationConf'
import { checkAuth, checkError, getAuthorization, getJson } from '../../utils/ActionUtils'
import WaitAction from '../../wait/WaitAction'
import ToastrAction from '../../toastr/actions/ToastrAction'
import i18n from 'simple-react-i18n'

const PerimetersThunk = {
    getPerimetersFolders: createAsyncThunk(
        'getPerimetersFolders',
        async(_, { rejectWithValue }) => {
            try {
                return await fetch(ApplicationConf.perimeters.perimetersFolders(), {
                    method: 'GET',
                    headers: getAuthorization() })
                    .then(checkAuth)
                    .then(checkError)
                    .then(getJson)
            } catch (err) {
                return rejectWithValue(err)
            }
        },
    ),
    addPerimetersFolder: createAsyncThunk(
        'addPerimetersFolder',
        async(data, { rejectWithValue, dispatch }) => {
            try {
                dispatch(WaitAction.waitStart())

                const response = await fetch(ApplicationConf.perimeters.perimetersFolders(), {
                    method: 'POST',
                    headers: getAuthorization(),
                    body: JSON.stringify(data) })
                    .then(checkAuth)
                    .then(checkError)
                    .then(getJson)
                    .then(res => {
                        dispatch(WaitAction.waitStop())
                        dispatch(ToastrAction.success(i18n.perimetersFolderCreated))
                        return res
                    })
                    .catch(() => {
                        dispatch(WaitAction.waitStop())
                        dispatch(ToastrAction.error(i18n.somethingWentWrong))
                    })

                dispatch(PerimetersThunk.getPerimetersFolders())

                return response
            } catch (err) {
                return rejectWithValue(err)
            }
        },
    ),
    updatePerimetersFolder: createAsyncThunk(
        'updatePerimetersFolder',
        async(data, { rejectWithValue, dispatch }) => {
            try {
                dispatch(WaitAction.waitStart())

                const response = await fetch(ApplicationConf.perimeters.perimetersFolders(), {
                    method: 'PUT',
                    headers: getAuthorization(),
                    body: JSON.stringify(data),
                })
                    .then(checkAuth)
                    .then(checkError)
                    .then(getJson)
                    .then(res => {
                        dispatch(WaitAction.waitStop())
                        dispatch(ToastrAction.success(i18n.perimetersFolderUpdated))
                        return res
                    })
                    .catch(() => {
                        dispatch(WaitAction.waitStop())
                        dispatch(ToastrAction.error(i18n.somethingWentWrong))
                    })

                return response
            } catch (err) {
                return rejectWithValue(err)
            }
        },
    ),
    deletePerimetersFolder: createAsyncThunk(
        'deletePerimetersFolder',
        async(id, { rejectWithValue, dispatch }) => {
            try {
                dispatch(WaitAction.waitStart())

                const response = await fetch(ApplicationConf.perimeters.perimetersFolders(), {
                    method: 'DELETE',
                    headers: getAuthorization(),
                    body: JSON.stringify({
                        id,
                    }) })
                    .then(checkAuth)
                    .then(checkError)
                    .then(getJson)
                    .then(res => {
                        dispatch(WaitAction.waitStop())
                        dispatch(ToastrAction.success(i18n.perimetersFolderDeleted))
                        return res
                    })
                    .catch(() => {
                        dispatch(WaitAction.waitStop())
                        dispatch(ToastrAction.error(i18n.somethingWentWrong))
                    })

                dispatch(PerimetersThunk.getDeeds())

                return response
            } catch (err) {
                return rejectWithValue(err)
            }
        },
    ),
    getPerimeters: createAsyncThunk(
        'getPerimeters',
        async(id, { rejectWithValue }) => {
            try {
                return await fetch(ApplicationConf.perimeters.getPerimeters(id), {
                    method: 'GET',
                    headers: getAuthorization() })
                    .then(checkAuth)
                    .then(checkError)
                    .then(getJson)
            } catch (err) {
                return rejectWithValue(err)
            }
        },
    ),
    getParcels: createAsyncThunk(
        'getParcels',
        async(perimeter, { rejectWithValue }) => {
            try {
                return await fetch(ApplicationConf.perimeters.getParcels(perimeter), {
                    method: 'GET',
                    headers: getAuthorization() })
                    .then(checkAuth)
                    .then(checkError)
                    .then(getJson)
            } catch (err) {
                return rejectWithValue(err)
            }
        },
    ),
    getParcel: createAsyncThunk(
        'getParcel',
        async(parcel, { rejectWithValue }) => {
            try {
                return await fetch(ApplicationConf.perimeters.getParcel(parcel), {
                    method: 'GET',
                    headers: getAuthorization() })
                    .then(checkAuth)
                    .then(checkError)
                    .then(getJson)
            } catch (err) {
                return rejectWithValue(err)
            }
        },
    ),
    addParcel: createAsyncThunk(
        'addParcel',
        async(data, { rejectWithValue, dispatch }) => {
            try {
                dispatch(WaitAction.waitStart())

                const response = await fetch(ApplicationConf.perimeters.parcel(), {
                    method: 'POST',
                    headers: getAuthorization(),
                    body: JSON.stringify(data) })
                    .then(checkAuth)
                    .then(checkError)
                    .then(getJson)
                    .then(res => {
                        dispatch(WaitAction.waitStop())
                        dispatch(ToastrAction.success(i18n.parcelCreated))
                        return res
                    })
                    .catch(() => {
                        dispatch(WaitAction.waitStop())
                        dispatch(ToastrAction.error(i18n.somethingWentWrong))
                    })

                if (data?.perimeterFolderId && data?.perimeterId) {
                    dispatch(PerimetersThunk.getParcels({
                        folder: data.perimeterFolderId,
                        id: data.perimeterId,
                    }))
                }

                return response
            } catch (err) {
                return rejectWithValue(err)
            }
        },
    ),
    deleteParcel: createAsyncThunk(
        'deleteParcel',
        async(data, { rejectWithValue, dispatch }) => {
            try {
                dispatch(WaitAction.waitStart())

                const response = await fetch(ApplicationConf.perimeters.parcel(), {
                    method: 'DELETE',
                    headers: getAuthorization(),
                    body: JSON.stringify(data) })
                    .then(checkAuth)
                    .then(checkError)
                    .then(getJson)
                    .then(res => {
                        dispatch(WaitAction.waitStop())
                        dispatch(ToastrAction.success(i18n.parcelDeleted))
                        return res
                    })
                    .catch(() => {
                        dispatch(WaitAction.waitStop())
                        dispatch(ToastrAction.error(i18n.somethingWentWrong))
                    })

                if (data?.perimeterFolderId && data?.perimeterId) {
                    dispatch(PerimetersThunk.getParcels({
                        folder: data.perimeterFolderId,
                        id: data.perimeterId,
                    }))
                }

                return response
            } catch (err) {
                return rejectWithValue(err)
            }
        },
    ),
    updateParcel: createAsyncThunk(
        'updateParcel',
        async(data, { rejectWithValue, dispatch }) => {
            try {
                dispatch(WaitAction.waitStart())

                const response = await fetch(ApplicationConf.perimeters.parcel(), {
                    method: 'PUT',
                    headers: getAuthorization(),
                    body: JSON.stringify(data) })
                    .then(checkAuth)
                    .then(checkError)
                    .then(getJson)
                    .then(res => {
                        dispatch(WaitAction.waitStop())
                        dispatch(ToastrAction.success(i18n.parcelUpdated))
                        return res
                    })
                    .catch(() => {
                        dispatch(WaitAction.waitStop())
                        dispatch(ToastrAction.error(i18n.somethingWentWrong))
                    })

                if (data?.perimeterFolderId && data?.perimeterId) {
                    dispatch(PerimetersThunk.getParcels({
                        folder: data.perimeterFolderId,
                        id: data.perimeterId,
                    }))
                }

                return response
            } catch (err) {
                return rejectWithValue(err)
            }
        },
    ),
    getDeeds: createAsyncThunk(
        'getDeeds',
        async(_, { rejectWithValue }) => {
            try {
                return await fetch(ApplicationConf.perimeters.deeds(), {
                    method: 'GET',
                    headers: getAuthorization() })
                    .then(checkAuth)
                    .then(checkError)
                    .then(getJson)
            } catch (err) {
                return rejectWithValue(err)
            }
        },
    ),
    getDeed: createAsyncThunk(
        'getDeed',
        async(id, { rejectWithValue }) => {
            try {
                return await fetch(ApplicationConf.perimeters.getDeed(id), {
                    method: 'GET',
                    headers: getAuthorization() })
                    .then(checkAuth)
                    .then(checkError)
                    .then(getJson)
            } catch (err) {
                return rejectWithValue(err)
            }
        },
    ),
    addDeed: createAsyncThunk(
        'addDeed',
        async(data, { rejectWithValue, dispatch }) => {
            try {
                dispatch(WaitAction.waitStart())

                const response = await fetch(ApplicationConf.perimeters.deed(), {
                    method: 'POST',
                    headers: getAuthorization(),
                    body: JSON.stringify(data) })
                    .then(checkAuth)
                    .then(checkError)
                    .then(getJson)
                    .then(res => {
                        dispatch(WaitAction.waitStop())
                        dispatch(ToastrAction.success(i18n.deedCreated))
                        return res
                    })
                    .catch(() => {
                        dispatch(WaitAction.waitStop())
                        dispatch(ToastrAction.error(i18n.somethingWentWrong))
                    })

                dispatch(PerimetersThunk.getDeeds())

                return response
            } catch (err) {
                return rejectWithValue(err)
            }
        },
    ),
    addDeedOwner: createAsyncThunk(
        'addDeedOwner',
        async(data, { rejectWithValue, dispatch }) => {
            try {
                dispatch(WaitAction.waitStart())

                const response = await fetch(ApplicationConf.perimeters.deedOwner(), {
                    method: 'POST',
                    headers: getAuthorization(),
                    body: JSON.stringify(data) })
                    .then(checkAuth)
                    .then(checkError)
                    .then(getJson)
                    .then(res => {
                        dispatch(WaitAction.waitStop())
                        dispatch(ToastrAction.success(i18n.deedCreated))
                        return res
                    })
                    .catch(() => {
                        dispatch(WaitAction.waitStop())
                        dispatch(ToastrAction.error(i18n.somethingWentWrong))
                    })

                dispatch(PerimetersThunk.getDeeds())

                return response
            } catch (err) {
                return rejectWithValue(err)
            }
        },
    ),
    deleteDeed: createAsyncThunk(
        'deleteDeed',
        async(id, { rejectWithValue, dispatch }) => {
            try {
                dispatch(WaitAction.waitStart())

                const response = await fetch(ApplicationConf.perimeters.deed(), {
                    method: 'DELETE',
                    headers: getAuthorization(),
                    body: JSON.stringify({
                        id,
                    }) })
                    .then(checkAuth)
                    .then(checkError)
                    .then(getJson)
                    .then(res => {
                        dispatch(WaitAction.waitStop())
                        dispatch(ToastrAction.success(i18n.deedDeleted))
                        return res
                    })
                    .catch(() => {
                        dispatch(WaitAction.waitStop())
                        dispatch(ToastrAction.error(i18n.somethingWentWrong))
                    })

                dispatch(PerimetersThunk.getDeeds())

                return response
            } catch (err) {
                return rejectWithValue(err)
            }
        },
    ),
    updateDeed: createAsyncThunk(
        'updateDeed',
        async(data, { rejectWithValue, dispatch }) => {
            try {
                dispatch(WaitAction.waitStart())

                const response = await fetch(ApplicationConf.perimeters.deed(), {
                    method: 'PUT',
                    headers: getAuthorization(),
                    body: JSON.stringify(data) })
                    .then(checkAuth)
                    .then(checkError)
                    .then(getJson)
                    .then(res => {
                        dispatch(WaitAction.waitStop())
                        dispatch(ToastrAction.success(i18n.deedUpdated))
                        return res
                    })
                    .catch(() => {
                        dispatch(WaitAction.waitStop())
                        dispatch(ToastrAction.error(i18n.somethingWentWrong))
                    })

                dispatch(PerimetersThunk.getDeeds())

                return response
            } catch (err) {
                return rejectWithValue(err)
            }
        },
    ),
    getOwners: createAsyncThunk(
        'getOwners',
        async(id, { rejectWithValue }) => {
            try {
                const contacts = await fetch(ApplicationConf.referencial.contacts(), {
                    method: 'GET',
                    headers: getAuthorization() })
                    .then(checkAuth)
                    .then(checkError)
                    .then(getJson)

                const intervenants = await fetch(ApplicationConf.referencial.contributors(), {
                    method: 'GET',
                    headers: getAuthorization() })
                    .then(checkAuth)
                    .then(checkError)
                    .then(getJson)

                return {
                    contacts,
                    intervenants,
                }
            } catch (err) {
                return rejectWithValue(err)
            }
        },
    ),
    getPerimeterFolderLastEvent: createAsyncThunk(
        'getPerimeterFolderLastEvent',
        async(perimeterFolderId, { rejectWithValue }) => {
            try {
                const response = await fetch(ApplicationConf.perimeters.getPerimeterFolderLastEvent(perimeterFolderId), {
                    method: 'GET',
                    headers: getAuthorization(),
                })
                    .then(checkAuth)
                    .then(checkError)
                    .then(getJson)

                return response
            } catch (err) {
                return rejectWithValue(err)
            }
        },
    ),
}

export default PerimetersThunk