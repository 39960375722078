import SuperMultiAutocomplete from 'components/forms/SuperMultiAutocomplete'
// import MultiContributorsAutocomplete from 'referencial/components/contributor/components/MultiContributorsAutocomplete'
import Select from 'components/forms/Select'
import PropTypes from 'prop-types'
import { setIntegrationModesPopup } from 'import/utils/ImportUtils'
import React, { useEffect, useMemo } from 'react'
import { getIntegrationModes } from 'utils/JobUtils'
import i18n from 'simple-react-i18n'
import Checkbox from 'components/forms/Checkbox'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import Icon from 'components/icon/Icon'
import Job from 'import/dto/Job'
import ContactAction from 'referencial/components/contact/actions/ContactAction'
import Table from '../../../../components/datatable/Table'
import { nbPerPageLabelTiny } from '../../../../referencial/constants/ReferencialConstants'
import { Grid } from '@mui/material'
import CityAction from 'referencial/components/city/actions/CityAction'
import ReferencialAction from 'referencial/action/ReferencialAction'
import useSandreList from 'utils/customHook/useSandreList'


const baseHeadersT = [ 'city', 'contact', 'sms', 'email', 'apic', 'vigicrueFlash']

const AlertClimatic = ({
    job = {},
    onChangeJob = () => {},
    onChangeDataTypes = () => {},
    isEditMode = false,
}) => {
    const dispatch = useDispatch()
    const {
        cities,
        contactsGroup,
        listContacts,
        sandreCodes,
    } = useSelector(store => ({
        cities: store.CityReducer.cities,
        contactsGroup: store.ContactReducer.contactsGroups,
        listContacts: store.ContactReducer.contacts,
        sandreCodes: store.ReferencialReducer.sandreCodes,
    }), shallowEqual)

    const rainLevel = useSandreList('CLIMATIC.APIC')
    const flooding = useSandreList('CLIMATIC.VIGIECRUE')


    const contactSimpleFormated= listContacts.filter(c => !!c.email || (!!c.phoneTel && (c.phoneTel.startsWith('06') || c.phoneTel.startsWith('07'))))
        .map(c => {
            return { id: `i${c.id}`, name: c.name }
        })

    const groupFormated =contactsGroup.map(cg => ({ id: `g${cg.code}`, name: cg.name }))

    const contactsGroupsFormatted = [
        { id: 'A1', name: '1 - Contact', isGroup: true, elements: contactSimpleFormated },
        { id: 'A2', name: '0 - Groupe de contacts', isGroup: true, elements: groupFormated,
        },
    ]

    const listAllcontactFormated = [ ...groupFormated, ...contactSimpleFormated ]

    useEffect(() => {
        if (!contactsGroup.length) {
            dispatch(ContactAction.fetchContactsGroups())
        }
        if (!listContacts.length) {
            dispatch(ContactAction.fetchContacts())
        }
        if (!cities.length) {
            dispatch(CityAction.fetchCities())
        }
        if (!sandreCodes.length) {
            dispatch(ReferencialAction.fetchSandreCodes())
        }
    }, [dispatch])

    const disabled = { disabled: !isEditMode }


    const dataType = job.parameters.dataTypes.find(d => d.includes('DATA')) ?? ''


    const integrationModes = useMemo(getIntegrationModes, [])

    const helpIcon = (
        <Icon
            icon='help'
            style={{
                paddingLeft: 5,
                fontSize: 14,
                position: 'relative',
                top: '-49px',
                left: '214px',
                height: 0,
            }}
            tooltip={'Description des options'}
            onClick={setIntegrationModesPopup}
        />
    )


    const onChangeDataIntegration = integration => {
        onChangeDataTypes(integration ? [integration] : [])
    }

    const onChange = (newParam, index) => {
        onChangeJob({
            parameters: {
                ...job.parameters,
                parameters: job.parameters.parameters.map((p, i) => i === index ? JSON.stringify(newParam) : p),
            },
        })
    }

    const formattedParameters = job.parameters.parameters.map((p, index) => {
        const param = JSON.parse(p || '{}')
        const {
            insee,
            contacts,
            sms,
            email,
            apic,
            vigicrueFlash,
        } = param
        return {
            city: {
                value: (
                    <Select value={ insee } options={ cities } onChange={ v => onChange({ ...param, insee: v }, index) } {...disabled }/>
                ),
                style: {
                    width: '100%',
                },
            },
            contact: {
                value: (
                    <SuperMultiAutocomplete
                        values={ contacts }
                        obligatory
                        optionsGroups={ contactsGroupsFormatted }
                        options= {listAllcontactFormated}
                        multiple
                        onChange={ v => {
                            onChange({ ...param, contacts: v }, index)
                        } }
                        {...disabled }
                    />
                ),
                style: {
                    width: '100%',
                },
            },
            sms: {
                value: (
                    <Checkbox label={i18n.sms} checked={sms} onChange={ v => onChange({ ...param, sms: v }, index) } {...disabled } />
                ),
                style: {
                    width: '100%',
                },
            },
            email: {
                value: (
                    <Checkbox label={i18n.email} checked={email || false} onChange={ v => onChange({ ...param, email: v }, index) } {...disabled } />
                ),
                style: {
                    width: '100%',
                },
            },
            apic: {
                value: (
                    <Select value={ apic } options={rainLevel} onChange={ v => onChange({ ...param, apic: v }, index) } {...disabled }/>
                ),
                style: {
                    width: '100%',
                },
            },
            vigicrueFlash: {
                value: (
                    <Select value={ vigicrueFlash } options={flooding} onChange={ v => onChange({ ...param, vigicrueFlash: v }, index) } {...disabled }/>
                ),
                style: {
                    width: '100%',
                },
            },
            index: {
                value: index,
                style: {
                    width: '100%',
                },
            },
        }
    })

    const addParameterNew = () => {
        if (isEditMode) {
            const jobParameters = {
                ...job.parameters,
                parameters: [ ...job.parameters.parameters, '{}'],
            }
            onChangeJob({ parameters: jobParameters })
        }
    }

    const onDeleteParameter = (elt) => {
        if (isEditMode) {
            const jobParameters = {
                ...job.parameters,
                parameters: job.parameters.parameters.filter((_, i) => i !== elt.index),
            }
            onChangeJob({ parameters: jobParameters })
        }
    }

    const actions = [
        {
            onClick: addParameterNew,
            iconName: 'add_box',
            tooltip: `${i18n.add}`,
        },
    ]

    return (
        <Grid container spacing={1} alignItems='center'>
            <Grid item xs={4}>
                <Select
                    label={i18n.dataIntegration}
                    options={integrationModes}
                    value={dataType}
                    labelSpan={helpIcon}
                    obligatory
                    onChange={onChangeDataIntegration}
                    disabled={!isEditMode}
                />
            </Grid>
            <Grid item xs={12}>
                <div className='job-parameter-table'>
                    <Table
                        data={ formattedParameters }
                        nbPerPageLabel={ nbPerPageLabelTiny }
                        type={ { headers: baseHeadersT } }
                        sortable
                        condensed
                        paging
                        customHeaders={ baseHeadersT }
                        customWidthHeaders={{
                            city: '17%',
                            contact: '27%',
                            sms: '8%',
                            email: '8%',
                            apic: '18%',
                            vigicrueFlash: '18%',
                        }}
                        activeHeader
                        actions={ actions }
                        deletable={ isEditMode }
                        onDelete={ onDeleteParameter }
                        color
                    />
                </div>
            </Grid>
        </Grid>
    )
}

AlertClimatic.propTypes = {
    job: PropTypes.instanceOf(Job).isRequired,
    isEditMode: PropTypes.bool,
    onChangeDataTypes: PropTypes.func.isRequired,
    onChangeJob: PropTypes.func,
}

export default AlertClimatic
