import React, { useMemo } from 'react'
import i18n from 'simple-react-i18n'
import { H_PERIMETERS_DASHBOARD } from '../account/constants/AccessRulesConstants'
import { componentHasHabilitations } from '../utils/HabilitationUtil'
import FixedSlideNav from '../components/navbar/FixedSlideNav'
import BoundaryError from '../log/components/BoundaryError'
import { Redirect, Route, Switch } from 'react-router-dom'
import PerimetersDashboard from './components/perimeters/PerimetersDashboard'
import PerimetersDeeds from './components/perimeters/PerimetersDeeds'
import PerimeterDashboard from './components/perimeter/PerimeterDashboard'
import PropTypes from 'prop-types'

const PerimetersApp = ({ match: { params: { id } } }) => {
    const navLinks = useMemo(() => {
        const links = parseInt(id) ? [
            {
                icons: 'dashboard',
                name: i18n.dashboard,
                href: `/perimeter/${id}/dashboard`,
                perimeters: H_PERIMETERS_DASHBOARD,
            }, {
                icons: 'map',
                name: i18n.map,
                href: `/perimeter/${id}/map`,
                perimeters: H_PERIMETERS_DASHBOARD,
            }, {
                icons: 'events',
                name: i18n.events,
                href: `/perimeter/${id}/events`,
                perimeters: H_PERIMETERS_DASHBOARD,
            }, {
                icons: 'perm_media',
                name: i18n.documents,
                href: `/perimeter/${id}/documents`,
                perimeters: H_PERIMETERS_DASHBOARD,
            }] : [{
            icons: 'dashboard',
            name: i18n.dashboard,
            href: '/perimeter/dashboard',
            perimeters: H_PERIMETERS_DASHBOARD,
        }, {
            icons: 'perm_media',
            name: i18n.deeds,
            href: '/perimeter/deeds',
            perimeters: H_PERIMETERS_DASHBOARD,
        }]

        return links.filter(l => componentHasHabilitations(l.perimeters))
    }, [id])

    return (
        <div className='row no-margin'>
            <FixedSlideNav links={navLinks}/>
            <main>
                <div className='row no-margin padding-left-1'>
                    <div className='content-page no-padding fullscreen'>
                        <BoundaryError>
                            <Switch>
                                <Redirect exact from='/perimeter' to='/perimeter/dashboard'/>
                                <Route path='/perimeter/dashboard'
                                    render={(props) => <PerimetersDashboard {...props} />}
                                />
                                <Route path='/perimeter/deeds'
                                    render={(props) => <PerimetersDeeds {...props} />}
                                />
                                <Route path='/perimeter/:id/dashboard'
                                    render={(props) => <PerimeterDashboard {...props} />}
                                />
                                <Redirect exact from='/perimeter/:id' to='/perimeter/:id/dashboard'/>
                            </Switch>
                        </BoundaryError>
                    </div>
                </div>
            </main>
        </div>
    )
}

PerimetersApp.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string,
        }),
    }),
}

export default PerimetersApp