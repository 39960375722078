import React from 'react'
import PropTypes from 'prop-types'
import DisplayedValue from './DisplayedValue'
import moment from 'moment'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import 'react-datepicker/dist/react-datepicker-cssmodules.css'
import i18n from 'simple-react-i18n'
import { v4 as uuidv4 } from 'uuid'
import { searchAllCharacters } from '../../utils/StringUtil'
import Input from './Input'
import { registerLocale } from  "react-datepicker"
import { fr } from 'date-fns/locale/fr'
registerLocale('fr', fr)

const dateStyle = {
    border: '1px solid #7a7a7a',
    backgroundColor: 'white',
    color: 'black',
    cursor: 'text',
}

const SimpleDatePicker = ({
    label = '',
    value,
    onChange = () => {},
    col,
    id,
    disabled,
    readMode,
    style,
    styleLabel,
    dateClassName,
    min,
    max,
    obligatory,
    calendarPosition = 'bottom',
    'data-cy': dataCy,
    withHour = false,
}) => {
    const pickerDate = withHour ? 'dd/MM/yyyy HH:mm' : 'dd/MM/yyyy'
    const momentDate = withHour ? 'DD/MM/YYYY HH:mm' : 'DD/MM/YYYY'

    const componentId = id || searchAllCharacters(label + uuidv4()).replaceAll(' ', '_').replace('numero', '').replace('numero', '').replace('nom', 'libelle')
    if (readMode) {
        return (
            <div
                className={col ? `col s${col}` : 'col'}
                style={{ display: 'block' }}
            >
                <DisplayedValue
                    label={label}
                    obligatory={obligatory}
                    value={value ? moment(value).format(momentDate) : ''}
                />
            </div>
        )
    }
    const date = (
        <DatePicker
            id={componentId}
            dateFormat={pickerDate}
            placeholderText={withHour ? 'JJ/MM/AAAA HH:mm' : 'JJ/MM/AAAA'}
            selected={value ? new Date(value) : null}
            className={`sieau-input form-control input-sm ${dateClassName}`}
            onChange={v => onChange(v && moment(v).valueOf())}
            minDate={min ? new Date(min) : null}
            maxDate={max ? new Date(max) : null}
            style={dateStyle}
            popperPlacement={calendarPosition}
            showTimeSelect={withHour}
            locale='fr'
        />
    )

    // DatePicker value is not reset on cancel update, so we have to use this trick
    const dateDisabled = (
        <Input
            id={componentId}
            value={value ? moment(value).format(momentDate) : ''}
            placeholder='JJ/MM/AAAA'
            disabled
        />
    )

    if (!label && !col) {
        return disabled ? dateDisabled : date
    }
    return (
        <div
            className={col ? `col s${col}` : ''}
            style={style}
        >
            <div className='input-field simpleDatePicker' data-cy={dataCy}>
                <label
                    htmlFor={componentId}
                    style={disabled
                        ? { ...styleLabel }
                        : { color: '#161832', ...styleLabel }
                    }
                >
                    {label}
                    {obligatory ? <span className='primary-color-text'>{ i18n.obligatoryField }</span> : null }
                </label>
                {disabled ? dateDisabled : date}
            </div>
        </div>
    )
}

SimpleDatePicker.propTypes = {
    label: PropTypes.string,
    value: PropTypes.number,
    onChange: PropTypes.func.isRequired,
    col: PropTypes.number,
    min: PropTypes.number,
    max: PropTypes.number,
    id: PropTypes.string,
    disabled: PropTypes.bool,
    readMode: PropTypes.bool,
    obligatory: PropTypes.bool,
    style: PropTypes.shape({}),
    styleLabel: PropTypes.shape({}),
    dateClassName: PropTypes.string,
    calendarPosition: PropTypes.oneOf(['auto-start', 'auto', 'auto-end', 'top-start', 'top', 'top-end', 'right-start', 'right', 'right-end', 'bottom-end', 'bottom', 'bottom-start', 'left-end', 'left', 'left-start']),
    'data-cy': PropTypes.string,
    withHour: PropTypes.bool,
}

export default SimpleDatePicker
