import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import AppStore from 'store/AppStore'
import FixedSlideNav from '../../components/navbar/FixedSlideNav'
import { links } from '../../quality/constants/StationsConstants'
import { execByType } from '../../utils/StationUtils'
import i18n from 'simple-react-i18n'
import User from '../../account/dto/User'
import PluviometryAction from '../../pluviometry/actions/PluviometryAction'
import ProductionUnitAction from '../../productionUnit/actions/ProductionUnitAction'
import DistributionUnitAction from '../../distributionUnit/actions/DistributionUnitAction'
import InstallationAction from '../../installation/actions/InstallationAction'
import { Redirect, Route, Switch } from 'react-router-dom'
import PluviometryGraphicApp from 'pluviometry/components/graphic/PluviometryGraphicApp'
import PluviometryCampaigns from 'pluviometry/components/campaign/PluviometryCampaigns'
import AdminPluviometerApp from 'administration/components/pluviometer/AdminPluviometerApp'
import DevelopmentApp from 'inDevelop/components/DevelopmentApp'
import PluviometersExportApp from 'pluviometry/components/export/PluviometersExportApp'
import HomeAction from 'home/actions/HomeAction'
import {
    H_PLUVIO_CAMPAIGN,
    H_PLUVIO_DASHBOARD,
    H_PLUVIO_EXPORT,
    H_PLUVIO_GRAPHIC,
    H_PLUVIO_IMPORT,
    H_PLUVIO_OPTIONS,
    H_PLUVIO_SITUATION,
    H_RESOURCE_DASHBOARD,
    H_STATION_RESOURCE_OPTIONS,
    H_UNITS_COUNTERS,
    H_UNITS_DASHBOARD,
    H_UNITS_OPTIONS,
} from '../../account/constants/AccessRulesConstants'
import { componentHasHabilitations } from '../../utils/HabilitationUtil'
import BoundaryError from 'log/components/BoundaryError'
import ResourceAction from 'resource/actions/ResourceAction'
import PluvioIntegrationApp from '../../pluviometry/components/integration/PluvioIntegrationApp'
import ResourcesOptionApp from 'resource/components/ResourcesOptionApp'
import DistributionUnitsCounterManager from 'distributionUnit/components/DistributionUnitsCounterManager'
import AdminUDIApp from 'administration/components/udi/AdminUDIApp'
import PluviometersDashboard from 'pluviometry/components/dashboard/PluviometersDashboard'
import ProductionUnitsDashboard from 'productionUnit/components/dashboard/ProductionUnitsDashboard'
import DistributionUnitsDashboard from 'distributionUnit/components/dashboard/DistributionUnitsDashboard'
import ResourcesDashboard from 'resource/components/dashboard/ResourcesDashboard'
import AdminProductionUnitApp from 'productionUnit/components/AdminProductionUnitApp'

class StationsApp extends Component {
    constructor(props) {
        super(props)
        this.state = {
            stationType: (() => {
                const type = (window.location.href || document.URL).split('#')[1].split('/')[1]
                if (['piezometry', 'pluviometry', 'hydrometry', 'resource', 'productionUnit', 'distributionUnit', 'installation'].indexOf(type) !== -1) {
                    return type
                }
                return 'pluviometry'
            })(),
        }
    }

    fetchStations = (type) => {
        execByType(type, {
            pluviometry: () => {
                AppStore.dispatch(PluviometryAction.fetchPluviometers())
                AppStore.dispatch(HomeAction.setHelpLink('pluviometrie', ''))
            },
            productionUnit: () => {
                AppStore.dispatch(ProductionUnitAction.fetchProductionUnits())
                AppStore.dispatch(HomeAction.setHelpLink('', ''))
            },
            resource: () => {
                AppStore.dispatch(ResourceAction.fetchResources())
                AppStore.dispatch(HomeAction.setHelpLink('', ''))
            },
            distributionUnit: () => {
                AppStore.dispatch(DistributionUnitAction.fetchDistributionUnits())
                AppStore.dispatch(HomeAction.setHelpLink('', ''))
            },
            installation: () => {
                AppStore.dispatch(InstallationAction.fetchInstallations())
                AppStore.dispatch(HomeAction.setHelpLink('', ''))
            },
        })
    }

    checkStationType = () => {
        const stationType = location.href.split('/')[4]
        if (stationType !== this.state.stationType && ['quality', 'piezometry', 'pluviometry', 'resource', 'productionUnit', 'distributionUnit', 'installation', 'hydrometry'].find(type => type === stationType)) {
            this.setState({ stationType })
            this.fetchStations(stationType)
        }
    }

    componentDidMount() {
        this.checkStationType()
        this.fetchStations(this.state.stationType)
    }

    getLink = (linkName) => {
        if (linkName.includes('dev')) {
            const linkDev = links[linkName.replace('dev', '')]
            return {
                ...linkDev,
                href: `/${this.state.stationType}/develop`,
                name: i18n[linkDev.i18n],
            }
        }
        const link = links[linkName]
        return {
            ...link,
            href: link.href || `/${this.state.stationType}/${link.hrefName || linkName}`,
            name: i18n[link.i18n],
        }
    }

    render() {
        const navBarLinks = execByType(this.state.stationType, {
            pluviometry: () => [
                { habilitation: H_PLUVIO_DASHBOARD, link: 'dashboard' },
                { habilitation: H_PLUVIO_IMPORT, link: 'import' },
                { habilitation: H_PLUVIO_EXPORT, link: 'export' },
                { habilitation: H_PLUVIO_SITUATION, link: 'integrationOverview' },
                { habilitation: H_PLUVIO_CAMPAIGN, link: 'campaign' },
                { habilitation: H_PLUVIO_GRAPHIC, link: 'graphic' },
                { habilitation: H_PLUVIO_OPTIONS, link: 'pluviometryOptions' },
                /* { habilitation: H_HYDRO_MAP_SITUATION, link: 'mapSituations' },*/
            ],
            distributionUnit: () => [
                { habilitation: H_UNITS_DASHBOARD, link: 'dashboard' },
                { habilitation: H_UNITS_COUNTERS, link: 'counters' },
                { habilitation: H_UNITS_OPTIONS, link: 'distributionUnitOptions' },
            ],
            productionUnit: () => [
                { habilitation: H_UNITS_DASHBOARD, link: 'dashboard' },
                { habilitation: H_UNITS_OPTIONS, link: 'productionUnitOptions' },
            ],
            resource: () => [
                { habilitation: H_RESOURCE_DASHBOARD, link: 'dashboard' },
                { habilitation: H_STATION_RESOURCE_OPTIONS, link: 'resourceOptions' },
            ],
        })
            .filter(l => componentHasHabilitations(l.habilitation))
            .map(l => this.getLink(l.link))
            .filter(l => !!l)

        return (
            <div className='row no-margin'>
                <FixedSlideNav links={navBarLinks} />
                <main>
                    <div className='row no-margin padding-left-1'>
                        <div className='content-page no-padding fullscreen'>
                            <BoundaryError>
                                <Switch>
                                    <Redirect exact path='/pluviometry' to='/pluviometry/dashboard' />
                                    <Redirect exact path='/resource' to='/resource/dashboard' />
                                    <Redirect exact path='/productionUnit' to='/productionUnit/dashboard' />
                                    <Redirect exact path='/distributionUnit' to='/distributionUnit/dashboard' />
                                    <Route path={'/pluviometry/dashboard'} render={(props) => <PluviometersDashboard {...props} />} />
                                    <Route path={'/productionUnit/dashboard'} render={(props) => <ProductionUnitsDashboard {...props} />} />
                                    <Route path='/productionUnit/options' render={(props) => <AdminProductionUnitApp {...props} />} />
                                    <Route path={'/distributionUnit/dashboard'} render={(props) => <DistributionUnitsDashboard {...props} />} />
                                    <Route path={'/resource/dashboard'} render={(props) => <ResourcesDashboard {...props} />} />
                                    <Route path='/distributionUnit/counters' render={(props) => <DistributionUnitsCounterManager {...props} />} />
                                    <Route path='/distributionUnit/options' render={(props) => <AdminUDIApp {...props} />} />
                                    <Route path='/pluviometry/export' render={(props) => <PluviometersExportApp {...props} />} />
                                    <Route path={['/pluviometry/develop', '/productionUnit/develop', '/distributionUnit/develop']} render={(props) => <DevelopmentApp {...props} />} />
                                    <Route path='/pluviometry/integrationOverview' render={(props) => <PluvioIntegrationApp {...props} />} />
                                    <Route path='/pluviometry/pluviometryOptions' render={(props) => <AdminPluviometerApp {...props} />} />
                                    <Route path='/pluviometry/campaign' render={(props) => <PluviometryCampaigns {...props} />} />
                                    <Route path='/pluviometry/graphic' render={(props) => <PluviometryGraphicApp {...props} />} />
                                    {
                                        /*
                                        <Route path='/pluviometry/mapSituations/settings' render={(props) => <MapSituationAdminApp {...props}/>} />
                                        <Route path='/pluviometry/mapSituations' render={(props) => <MapSituationApp {...props} />} />
                                        */
                                    }
                                    <Route path='/resource/settings' render={(props) => <ResourcesOptionApp {...props} />} />
                                </Switch>
                                {/* {this.props.children && React.cloneElement(this.props.children, {
                                    stationType: this.state.stationType,
                                    getLink: this.props.getLink
                                })} */}
                            </BoundaryError>
                        </div>
                    </div>
                </main>
            </div>
        )
    }

    componentDidUpdate() {
        this.checkStationType()
    }
}

StationsApp.propTypes = {
    children: PropTypes.element,
    getLink: PropTypes.func,
    popup: PropTypes.element,
    accountUser: PropTypes.instanceOf(User),
}

const mapStateToProps = store => ({
    popup: store.StationReducer.popup,
    accountUser: store.AccountReducer.accountUser,
})

export default connect(mapStateToProps)(StationsApp)
