import { Button, Grid } from '@mui/material'
import Card from 'components/card/Card'
import CollapseTopBar from 'components/topBar/CollapseToBar'
import React, { useEffect, useMemo, useState } from 'react'
import useTitle from 'utils/customHook/useTitle'
import i18n from 'simple-react-i18n'
import SimpleDatePicker from 'components/forms/SimpleDatePicker'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import SuperMultiAutocomplete from 'components/forms/SuperMultiAutocomplete'
import Select from 'components/forms/Select'
import { groupBy, isNil, isUndefined, keys } from 'lodash'
import useApplicationSetting, { intParser } from 'utils/customHook/useApplicationSetting'
import { AVERAGE, LAST_VALUE, MAX, MIN, P90 } from 'catchment/constants/CatchmentConstant'
import QualityAction from 'quality/actions/QualityAction'
import { ParameterGraphicEvolution, ParameterGraphicPercent, PieFamilyPesticides, SumParameterGraphicEvolution, SumParameterGraphicPercent } from './GraphicCompare'
import useListIndexed from 'utils/customHook/useListIndexed'
import { generateGradient, generateMultiGradient } from 'utils/ColorUtil'
import ParameterAction from 'referencial/components/parameter/actions/ParameterAction'
import useBoolean from 'utils/customHook/useBoolean'
import { getBeginingOfTheYear, getEndOfTheYear } from 'utils/DateUtil'
import PropTypes from 'prop-types'
import UnitAction from 'referencial/components/unit/actions/UnitAction'
import useActions from 'utils/customHook/useActions'
import ExportFileModal from 'components/modal/ExportFileModal'
import ExportAction from 'export/actions/ExportAction'
import Checkbox from 'components/forms/Checkbox'
import WatermassAction from 'referencial/components/watermass/actions/WatermassAction'
import useStartPeriod from 'catchment/utils/useStartPeriod'
import useEndPeriod from 'catchment/utils/useEndPeriod'

const CollapseFilterField = ({
    defaultFilters = {},
    onValidate = () => {},
}) => {
    const {
        parametersProps,
        departmentsProps,
        watermassesProps,
        catchments,
    } = useSelector(store => ({
        parametersProps: store.ParameterReducer.parameters,
        departmentsProps: store.CityReducer.departments,
        watermassesProps: store.WatermassReducer.watermasses,
        catchments: store.CatchmentReducer.catchments,
    }), shallowEqual)

    const watermassList = useMemo(() => {
        return watermassesProps.filter(w => w.euroCode && catchments.some(c => c.meCode === w.euroCode)).map(w => ({
            code: w.euroCode,
            name: `[${w.euroCode}] ${w.name}`,
        }))
    }, [catchments, watermassesProps])

    const indexedParameters = useListIndexed(parametersProps, 'code')

    const pesticides = useApplicationSetting('CATCHMENT_PESTICIDES', setting => setting?.split(',').filter(c => !!c) || [])

    const [departments, setDepartments] = useState(defaultFilters.departments ?? [])
    const [watermass, setWatermass] = useState(defaultFilters.watermass ?? [])
    const [firstStart, setFirstStart] = useState(defaultFilters.firstStart)
    const [firstEnd, setFirstEnd] = useState(defaultFilters.firstEnd)
    const [secondStart, setSecondStart] = useState(defaultFilters.secondStart)
    const [secondEnd, setSecondEnd] = useState(defaultFilters.secondEnd)
    const [parameters, setParameters] = useState(defaultFilters.parameters)
    const [criteria, setCriteria] = useState(defaultFilters.criteria)
    const [useOnlyMainPoint, setUseOnlyMainPoint] = useState(defaultFilters.useOnlyMainPoint ?? false)
    const [displayActiveSDAGE, setdisplayActiveSDAGE] = useState(defaultFilters.displayActiveSDAGE ?? false)

    const criterias = useMemo(() => {
        return [
            { id: LAST_VALUE, label: 'Dernière valeur' },
            { id: MIN, label: 'Min' },
            { id: MAX, label: 'Max' },
            { id: AVERAGE, label: 'Moyenne' },
            { id: P90, label: 'P90' },
        ]
    }, [])

    const parametersList = useMemo(() => {
        return pesticides.map(p => indexedParameters[p]).filter(p => !!p)
    }, [pesticides, indexedParameters])

    return (
        <CollapseTopBar>
            <div style={{ backgroundColor: '#d9dfe4', borderLeft: '2px solid #161832', borderRight: '2px solid #161832', padding: '10' }}>
                <Grid container columnSpacing={2} rowSpacing={0.5} alignItems='center'>
                    <Grid item xs={3}>
                        <SuperMultiAutocomplete
                            label={i18n.department}
                            options={departmentsProps}
                            keyValue='code'
                            onChange={setDepartments}
                            values={departments}
                            multiple
                            limit={2}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <SuperMultiAutocomplete
                            label={i18n.watermass}
                            options={watermassList}
                            keyValue='code'
                            onChange={setWatermass}
                            values={watermass}
                            multiple
                            limit={1}
                        />
                    </Grid>

                    <Grid item xs={3}>
                        <SuperMultiAutocomplete
                            label={i18n.parameters}
                            options={parametersList}
                            values={parameters}
                            onChange={setParameters}
                            keyLabel='labelWithCode'
                            keyValue='code'
                            multiple
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <Select
                            label={i18n.criteria}
                            options={criterias}
                            value={criteria}
                            onChange={setCriteria}
                            clearFunction
                        />
                    </Grid>


                    <Grid item xs={2}>
                        Première période
                    </Grid>
                    <Grid item xs={2}>
                        <SimpleDatePicker
                            label={i18n.startDate}
                            value={firstStart}
                            onChange={setFirstStart}
                            max={firstEnd}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <SimpleDatePicker
                            label={i18n.endDate}
                            value={firstEnd}
                            onChange={setFirstEnd}
                            min={firstStart}
                        />
                    </Grid>

                    <Grid item xs={3}>
                        <Checkbox
                            label={i18n.useOnlyDataMainPoint}
                            checked={useOnlyMainPoint}
                            onChange={setUseOnlyMainPoint}
                            truncate={false}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <Checkbox
                            label={i18n.displayActiveSDAGE}
                            checked={displayActiveSDAGE}
                            onChange={setdisplayActiveSDAGE}
                            truncate={false}
                        />
                    </Grid>

                    <Grid item xs={2}>
                        Seconde période
                    </Grid>
                    <Grid item xs={2}>
                        <SimpleDatePicker
                            label={i18n.startDate}
                            value={secondStart}
                            onChange={setSecondStart}
                            max={secondEnd}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <SimpleDatePicker
                            label={i18n.endDate}
                            value={secondEnd}
                            onChange={setSecondEnd}
                            min={secondStart}
                        />
                    </Grid>

                    <Grid item xs={3} />
                    <Grid item xs={3}>
                        <Button
                            onClick={() => onValidate({
                                firstStart,
                                firstEnd,
                                secondStart,
                                secondEnd,
                                parameters,
                                criteria,
                                departments,
                                watermass,
                                displayActiveSDAGE,
                                useOnlyMainPoint,
                            })}
                            variant='contained'
                            fullWidth
                        >
                            {i18n.visualize}
                        </Button>
                    </Grid>
                </Grid>
            </div>
        </CollapseTopBar>
    )
}

CollapseFilterField.propTypes = {
    defaultFilters: PropTypes.shape({
        criteria: PropTypes.oneOf([LAST_VALUE, MIN, MAX, AVERAGE, P90]),
        parameters: PropTypes.arrayOf(PropTypes.string),
        firstStart: PropTypes.number,
        firstEnd: PropTypes.number,
        secondStart: PropTypes.number,
        secondEnd: PropTypes.number,
        useOnlyMainPoint: PropTypes.bool,
        departments: PropTypes.arrayOf(PropTypes.string),
        watermass: PropTypes.arrayOf(PropTypes.string),
        displayActiveSDAGE: PropTypes.bool,
    }),
    onValidate: PropTypes.func,
}

const ExportModal = ({
    isOpen = false,
    close = () => {},
    filters = {},
}) => {
    const dispatch = useDispatch()

    const exportEvolutionContamination = exportType => {
        const options = {
            exportType,
            usedCriteria: filters.criteria,
            additionalParameters: filters.parameters,
            startDate: filters.firstStart,
            endDate: filters.firstEnd,
            compareStartDate: filters.secondStart,
            compareEndDate: filters.secondEnd,
            useOnlyMainPoint: filters.useOnlyMainPoint,
            departments: filters.departments,
            watermass: filters.watermass,
            useActiveSDAGE: filters.displayActiveSDAGE,
        }
        dispatch(ExportAction.exportCatchmentEvolutions(options))
    }

    return (
        <ExportFileModal
            open={isOpen}
            onClose={close}
            data={[{
                name: i18n.evolutionOfContaminations,
                formats: [{
                    type: i18n.excelFile,
                    callback: () => exportEvolutionContamination('xlsx'),
                }, {
                    type: i18n.csvFile,
                    callback: () => exportEvolutionContamination('csv'),
                }],
            }]}
        />
    )
}

ExportModal.propTypes = {
    isOpen: PropTypes.bool,
    close: PropTypes.func,
    filters: PropTypes.shape({
        criteria: PropTypes.oneOf([LAST_VALUE, MIN, MAX, AVERAGE, P90]),
        parameters: PropTypes.arrayOf(PropTypes.string),
        firstStart: PropTypes.number,
        firstEnd: PropTypes.number,
        secondStart: PropTypes.number,
        secondEnd: PropTypes.number,
        useOnlyMainPoint: PropTypes.bool,
        departments: PropTypes.arrayOf(PropTypes.string),
        watermass: PropTypes.arrayOf(PropTypes.string),
        displayActiveSDAGE: PropTypes.bool,
    }),
}

const CatchmentsCompareApp = ({

}) => {
    const dispatch = useDispatch()

    const {
        value: isExportModalOpen,
        setTrue: openExportModal,
        setFalse: closeExportModal,
    } = useBoolean(false)

    useTitle(() => [{
        title: i18n.catchments,
        href: 'catchment',
    }, {
        title: i18n.comparing,
        href: 'catchment/compare',
    }], [])

    const {
        qualitometers,
        catchments,
        catchmentPoints,
    } = useSelector(store => ({
        qualitometers: store.QualityReducer.qualitometersLight,
        catchments: store.CatchmentReducer.catchments,
        catchmentPoints: store.CatchmentReducer.catchmentPoints,
    }), shallowEqual)

    const startPeriod = useStartPeriod(intParser)
    const endPeriod = useEndPeriod(intParser)

    useEffect(() => {
        if (!qualitometers.length) {
            dispatch(QualityAction.fetchQualitometersLight())
        }
        dispatch(WatermassAction.fetchWatermasses())
        dispatch(ParameterAction.fetchParameterGroups())
        dispatch(ParameterAction.fetchParameterGroupLinks())
        dispatch(UnitAction.fetchUnits())
    }, [dispatch])

    const [filters, setFilters] = useState({
        firstStart: getBeginingOfTheYear(startPeriod),
        firstEnd: getEndOfTheYear(endPeriod),
        criteria: LAST_VALUE,
        parameters: [],
        watermass: [],
        departments: [],
    })

    const qualitoIds = useMemo(() => {
        const searchActiveSDAGE = filters.displayActiveSDAGE ? catchments.filter(({ activeSdage }) => activeSdage) : catchments
        const watermassFilter = filters.watermass.length ? searchActiveSDAGE.filter(c => filters.watermass.includes(c.meCode)) : searchActiveSDAGE
        const catchmentFiltered = filters.departments.length ? watermassFilter.filter(c => filters.departments.includes(c.department)) : watermassFilter

        const filteredPoints = catchmentPoints.filter(p => catchmentFiltered.some(c => c.id === p.id))
        const groupByCatchment = groupBy(filteredPoints, 'id')
        const formattedPoints = keys(groupByCatchment).flatMap(key => {
            if (groupByCatchment[key].length === 1) {
                return [{
                    ...groupByCatchment[key][0],
                    mainPoint: true,
                }]
            }
            return groupByCatchment[key]
        })
        const formattedCatchmentPoints = filters.useOnlyMainPoint ? formattedPoints.filter(p => p.mainPoint) : formattedPoints

        return formattedCatchmentPoints.map(point => {
            return qualitometers.find(q => q.code === point.codeWithoutDesignation)?.id
        }).filter(q => !isUndefined(q)) || []
    }, [catchmentPoints, catchments, filters.departments, filters.displayActiveSDAGE, filters.useOnlyMainPoint, filters.watermass, qualitometers])

    useActions(() => ({
        exportList: [{
            onClick: openExportModal,
            label: i18n.excel,
        }],
    }), [])

    const gradientGraphPercent = useMemo(() => {
        return [
            generateGradient('#fbee7c', '#d13104', 5),
            generateGradient('#fbc7dd', '#b2024d', 5),
            generateGradient('#a9e2b9', '#025585', 5),
        ]
    }, [])

    const gradientGraphEvol = useMemo(() => {
        return [
            generateMultiGradient([['#b2024d', '#fbc7dd', 3], ['#fbee7c', '#fbee7c', 1], ['#a9e2b9', '#025585', 3]], false),
        ]
    }, [])

    return (
        <>
            <CollapseFilterField defaultFilters={filters} onValidate={setFilters}/>
            <Card cardContentStyle={{ marginBottom: '100px' }}>
                <Grid container sx={{ padding: '20 10 10 10' }}>
                    {
                        (isNil(filters.secondStart) || isNil(filters.secondEnd)) && (
                            <>
                                <Grid item xs={4}>
                                    <PieFamilyPesticides
                                        ids={qualitoIds}
                                        filters={filters}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <ParameterGraphicPercent
                                        parameter='1340'
                                        ids={qualitoIds}
                                        filters={filters}
                                        colors={gradientGraphPercent[0]}
                                        thresholds={[10, 25, 40, 50]}
                                        unit='mg/L'
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <SumParameterGraphicPercent
                                        ids={qualitoIds}
                                        filters={filters}
                                        colors={gradientGraphPercent[1]}
                                    />
                                </Grid>
                                {
                                    filters.parameters?.map((param, i) => (
                                        <Grid item xs={4}>
                                            <ParameterGraphicPercent
                                                parameter={param}
                                                ids={qualitoIds}
                                                filters={filters}
                                                colors={gradientGraphPercent[(i + 2) % 3]}
                                                thresholds={[0.01, 0.1, 0.5, 2]}
                                                unit='mg/L'
                                            />
                                        </Grid>
                                    ))
                                }
                            </>
                        )
                    }
                    {
                        !isNil(filters.secondStart) && !isNil(filters.secondEnd) && (
                            <>
                                <Grid item xs={6}>
                                    <ParameterGraphicEvolution
                                        parameter='1340'
                                        ids={qualitoIds}
                                        filters={filters}
                                        colors={gradientGraphEvol[0]}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <SumParameterGraphicEvolution
                                        ids={qualitoIds}
                                        filters={filters}
                                        colors={gradientGraphEvol[0]}
                                    />
                                </Grid>
                                {
                                    filters.parameters?.map(param => (
                                        <Grid item xs={4}>
                                            <ParameterGraphicEvolution
                                                parameter={param}
                                                ids={qualitoIds}
                                                filters={filters}
                                                colors={gradientGraphEvol[0]}
                                            />
                                        </Grid>
                                    ))
                                }
                            </>
                        )
                    }
                </Grid>
            </Card>
            <ExportModal
                isOpen={isExportModalOpen}
                close={closeExportModal}
                filters={filters}
            />
        </>
    )
}

export default CatchmentsCompareApp