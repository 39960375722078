import { shallowEqual, useSelector } from 'react-redux'
import React, { useState, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Card, CardContent, Grid } from '@mui/material'
import { getSiteUrl } from '../../../../../utils/mapUtils/SiteTypes'
import { STATION_NAME_ASSOCIATION } from '../../../../../station/constants/StationConstants'
import i18n from 'simple-react-i18n'
import Checkbox from '../../../../../components/forms/Checkbox'
import { getDate } from '../../../../../utils/DateUtil'
import PiezometerStationAction from '../../../../../station/actions/PiezometerStationAction'
import HydrometryAction from '../../../../../hydrometry/actions/HydrometryAction'
import PluviometryAction from '../../../../../pluviometry/actions/PluviometryAction'
import {groupBy, unzip, flatten, orderBy, round, keys} from 'lodash'
import { execByType } from '../../../../../utils/StationUtils'
import { MEASURE_COTE } from '../../../../constants/PiezometryConstants'
import Button from '../../../../../components/forms/Button'
import Row from '../../../../../components/react/Row'
import Line from '../../../../../components/echart/series/Line'
import { getColorFromPalette, getThresholdColorHtml } from '../../../../../utils/ColorUtil'
import Bar from '../../../../../components/echart/series/Bar'
import PiezometryAction from '../../../../actions/PiezometryAction'
import SwapComponent from '../../../../../components/card/SwapComponent'
import Icon from '../../../../../components/icon/Icon'
import NumberField from '../../../../../components/forms/NumberField'
import Select from '../../../../../components/forms/Select'
import { hasValue } from '../../../../../utils/NumberUtil'
import DtoPiezometer from '../../../../dto/DtoPiezometer'
import { instanceOf } from '../../../../../utils/StoreUtils'
import { WhiteCard } from 'components/styled/Card'

import {
    CUMUL_PERSO_AVERAGE,
    CUMUL_PERSO_MAX,
    CUMUL_PERSO_MIN,
    CUMUL_PERSO_SUM,
} from '../../../../../pluviometry/constants/PluvioOptionConstant'

const getPromise = (stationType) => execByType(stationType, {
    piezometry: () => PiezometerStationAction.promisePiezoChartMeasures,
    hydrometry: () => HydrometryAction.promiseHydroChronicMeasures,
    pluviometry: () => PluviometryAction.promisePluvioChronicMeasures,
})

const getGroups = () => [
    { code: 'MAX', name: 'Max' },
    { code: 'MIN', name: 'Min' },
    { code: 'AVERAGE', name: i18n.mean },
    { code: 'SUM', name: i18n.sum },
    { code: 'all', name: i18n.brute },
]

const PiezoSuiviAssociationsTab2 = ({
    displayCote,
    landmarkValue, // sers à caluler la profondeur : depth = landmarkValue - NGF
    changeParent, // met à jour les state du parent (dont les séries liées à cette tab)
    tab, // tab sélectionné en haut à gauche
    minDate,
    maxDate,
    piezometer,
}) => {
    const {
        associatedSites,
    } = useSelector(store => ({
        associatedSites: store.StationReducer.associatedSites,
    }), shallowEqual)

    const [swap, setSwap] = useState({})
    const changeSwap = (key, v) => setSwap(({ ...swap, [key]: v }))

    const [stats, setStats] = useState([])

    const [statMin, setStatMin] = useState({})
    const [statMax, setStatMax] = useState({})
    const [statGroup, setStatGroup] = useState({})
    const changeStat = (func, obj, key, v) => func({ ...obj, [key]: v })
    const [allStatObj, setAllStatObj] = useState({})

    const [stations, setStations] = useState([])
    const [readyApply, setReadyApply] = useState(false)
    const [preselectedAssociations, setPreselected] = useState([])
    const [selectedAssociations, setSelected] = useState([])
    const changeSelected = (key, v) => {
        setPreselected(v ? [...preselectedAssociations, key] : preselectedAssociations.filter(key2 => key2 !== key))
        setReadyApply(true)
    }
    const applyChanges = () => {
        setReadyApply(false)
        setAllStatObj({ statMin, statMax, statGroup })
        setSelected(preselectedAssociations)
    }
    const [chartLandmarks, setChartLandmarks] = useState([])

    const [axisPluvio, setAxisPluvio] = useState(true)
    const [nbTime, setNbTime] = useState(7)
    const [cumul, setCumul] = useState(CUMUL_PERSO_MAX)
    const optionCumul = useMemo(() => [
        { value: CUMUL_PERSO_MAX, label: i18n.max },
        { value: CUMUL_PERSO_MIN, label: i18n.min },
        { value: CUMUL_PERSO_SUM, label: i18n.sum },
        { value: CUMUL_PERSO_AVERAGE, label: i18n.average },
    ], [])

    const toEchartMeasure = (m, lastLandmark, unit) => ({ value: [m[0], displayCote === MEASURE_COTE.NGF ? m[1] : lastLandmark - m[1], { NGF: m[1], depth: lastLandmark - m[1] }, m[2], m[3]], isPiezo: true, unit: unit ?? undefined })

    useEffect(() => {
        const thisStation = { typeName: 'piezometry', stationLinkedCode: piezometer.code, stationLinkedName: piezometer.name, stationLinkedId: piezometer.id }
        const piezos = [ thisStation, ...associatedSites.filter(ass => ass.typeName === 'piezometry') ]
        const piezoPromises = piezos.map(ass => PiezometerStationAction.promisePiezoMeasuresStats(ass.stationLinkedId))
        const hydros = associatedSites.filter(ass => ass.typeName === 'hydrometry')
        const hydrosPromises = hydros.map(ass => HydrometryAction.promiseHydroStatistics(ass.stationLinkedId))
        const pluvios = associatedSites.filter(ass => ass.typeName === 'pluviometry')
        const pluviosPromises = pluvios.map(ass => PluviometryAction.promisePluviometerMeasuresStats(ass.stationLinkedId))
        Promise.all([...piezoPromises, ...hydrosPromises, ...pluviosPromises]).then(jsonTab => {
            setStats(jsonTab)
            setStations([...piezos, ...hydros, ...pluvios])
            PiezometryAction.promisePiezometerChartLandmarks(piezos.map(ass => ass.stationLinkedId)).then(res => setChartLandmarks(res))
        })
    }, [])

    const getThresholds = (cb) => {
        const otherStations = selectedAssociations.filter(key => key.split(':')[2] !== '-1')
        if (!otherStations.length) {
            cb([])
        } else {
            const promises = otherStations.map(key => {
                const [stationType, id, rest] = key.split(':')
                switch (stationType) {
                    case 'piezometry':
                        return PiezometerStationAction.promisePiezometerThresholds(id)
                    case 'hydrometry':
                        return HydrometryAction.promiseHydroThresholds(id)
                    default:
                        return PluviometryAction.promisePluviometerThresholds(id)
                }
            })
            Promise.all(promises).then(jsonTab => {
                cb(otherStations.reduce((acc, key, idx) => {
                    acc[key] = jsonTab[idx]
                    return acc
                }, {}))
            })
        }
    }

    useEffect(() => {
        const grouped = groupBy(selectedAssociations, key => key.split(':')[0])
        const [promises, infos] = unzip(Object.keys(grouped).flatMap(stationType => {
            const keys = grouped[stationType]
            return keys.map(key => {
                const [typeName, stationId, typeId, codepoint, label, isPiezo] = key.split(':')
                const groupFunc = allStatObj.statGroup[key] || (parseInt(typeId) === -2 || (stationType === 'pluviometry' && parseInt(typeId) === 1) ? 'SUM' : 'MAX')
                const input = {
                    stationId: parseInt(stationId),
                    displayCote: parseInt(typeId) === -1 || isPiezo === 'true' ? MEASURE_COTE.NGF : null,
                    dataType: parseInt(typeId),
                    codepoint: parseInt(codepoint),
                    startDate: minDate,
                    endDate: maxDate,
                    chartMode: true,
                    groupFunc,
                    group: groupFunc,
                    label,
                    link: stations.find(s => s.typeName === stationType && s.stationLinkedId === parseInt(stationId)),
                    isPiezo: isPiezo === 'true',
                    stationType,
                    key,
                }
                if (stationType === 'pluviometry' && input.dataType === 1) {
                    const pluvioInput = { ...input, groupFunc: `${cumul}_${nbTime * 24}`, group: `${cumul}_${nbTime * 24}` }
                    return [getPromise(stationType)(pluvioInput), pluvioInput]
                }
                return [getPromise(stationType)(input), input]
            })
        }))
        Promise.all(promises || []).then(jsonTab => {
            getThresholds(thresholds => {
                const [series, axis] = jsonTab.reduce(([series, axis], measures, idx) => {
                    const { label, isPiezo, link, stationType, dataType, codepoint, stationId, key } = infos[idx]
                    const isPluvio = stationType === 'pluviometry' && dataType === 1
                    const isFirstAxis = (isPiezo && stationId === piezometer.id) || dataType === -1
                    const axisName = label // + (isPiezo ? ` [${link.stationLinkedCode}]` : '')
                    const statFound = flatten(stats).find(s => s.typeId === dataType && s.label === label && s.codepoint === codepoint)
                    const newAxis = axis.some(a => a.name === label) || isFirstAxis ? [] : [{
                        name: axisName,
                        isPluvio: axisPluvio ? isPluvio : false,
                        isPiezo,
                        min: hasValue(allStatObj.statMin[key]) ? allStatObj.statMin[key] : (isPluvio ? 0 : undefined),
                        max: hasValue(allStatObj.statMax[key]) ? allStatObj.statMax[key] : undefined,
                        dontUseBandCorrection: true,
                        unit: statFound?.unit ?? '',
                    }]
                    const thresholdPart = dataType !== -1 ? (() => {
                        const found = thresholds[key].filter(t => parseInt(t.dataType) === dataType && parseInt(t.code) === stationId)
                        if (found.length) {
                            return {
                                markLine: { data: found.map(t => ({
                                    yAxis: round(t.value, 2),
                                    position: 'middle',
                                    lineStyle: {
                                        color: getThresholdColorHtml(t),
                                    },
                                    label: {
                                        show: true,
                                        position: 'middle',
                                        formatter: () => `${t.name ? `${t.name} : ${round(t.value, 2)}` : ''} [${statFound?.unit ?? ''}]`,
                                    },
                                })), symbol: 'none' },
                            }
                        }
                        return {}
                    })() : {}
                    if (!measures.length) {
                        if (!keys(thresholdPart).length) {
                            return [series, [...axis, ...newAxis]]
                        }
                        const emptySerie = Line({
                            data: [],
                            axisName,
                            isPiezo,
                            dontUseBandCorrection: !isFirstAxis,
                            isFirstAxis,
                            ...thresholdPart,
                        })
                        return [[...series, emptySerie], [...axis, ...newAxis]]
                    }
                    const newSerie = (isPluvio || dataType === -2 ? Bar : Line)({
                        data: isPiezo ? measures.map(m => toEchartMeasure(m, chartLandmarks.find(c => c.id === stationId)?.lastlandmark || landmarkValue)) : measures.map(m => ({ value: m, unit: statFound?.unit ?? undefined })),
                        name: `${label} [${link.stationLinkedCode}] ${link.stationLinkedName} - ${statFound.namePoint}`,
                        isPiezo,
                        dontUseBandCorrection: !isFirstAxis,
                        connectNulls: false,
                        showSymbol: false,
                        lineStyle: {
                            normal: {
                                color: statFound?.color || getColorFromPalette(idx),
                                width: statFound?.lineWidth || 2,
                                type: statFound?.lineType || 'solid',
                                opacity: statFound?.lineOpacity || 1,
                            },
                        },
                        itemStyle: {
                            normal: {
                                color: statFound?.color || getColorFromPalette(idx),
                            },
                        },
                        axisName,
                        isFirstAxis,
                        ...thresholdPart,
                    })
                    return [[...series, newSerie], [...axis, ...newAxis]]
                }, [[], []])
                changeParent({ associationsSeries: series, associationsAxis: axis })
            })
        })
    }, [tab, selectedAssociations, allStatObj])

    return (
        <>
            {
                !!stations.filter(f => f.stationType === 1).length &&
                <Grid container paddingTop={3} justifyContent='center' alignItems='center' spacing={3} className='margin-top-1'>
                    <Card sx={{ width: '90%' }} elevation={10}>
                        <WhiteCard title={i18n.stationsSetting}>
                            <Grid container style={{ height: 75 }} justifyContent='center' alignItems='center' spacing={2}>
                                <Grid item xs={1}>
                                    <img src={getSiteUrl('PLUVIOMETER')} style={{ maxHeight: '30px' } } />
                                </Grid>
                                <Grid item xs={10}>
                                    <h6>{i18n.pluviometry}</h6>
                                </Grid>
                            </Grid>
                            <Grid container style={{ height: 50 }} justifyContent='center' alignItems='center' spacing={3}>
                                <Grid item xs={4}>
                                    {i18n.modeGrouping} : {i18n.rain}
                                </Grid>
                                <Grid item xs={3}>
                                    <Select
                                        options={optionCumul}
                                        label={i18n.modeCumul}
                                        value={cumul}
                                        onChange={setCumul}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <NumberField
                                        title={i18n.numberHoursDays}
                                        value={nbTime}
                                        onChange={setNbTime}
                                        min={0}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container paddingTop={3} justifyContent='center' alignItems='center' spacing={3}>
                                <Grid item xs={6}>
                                    {i18n.reversingScale}
                                </Grid>
                                <Grid container item paddingBottom={2} alignItems={'center'} direction={'row'} xs={5}>
                                    <Grid>
                                        <Checkbox
                                            checked={axisPluvio}
                                            onChange={() => setAxisPluvio(!axisPluvio)}
                                        />
                                    </Grid>
                                    <Grid>
                                        <Icon icon='info' style={{ fontSize: 18, color: 'grey' } }
                                            tooltip={(
                                                <p className='no-margin'>{i18n.displayMode}<br/>
                                                    {i18n.ifScaleEnabled}<br/>
                                                    {i18n.ifScaleDisabled}<br/>
                                                </p>
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            {/* <Grid container paddingTop={1} paddingBottom={2} justifyContent='center' alignItems='center' spacing={3}>
                                <Grid item xs={6}>
                                    {i18n.superimposeDifferentCumul}
                                </Grid>
                                <Grid container item alignItems={'center'} direction={'row'} xs={5}>
                                    <Grid>
                                        <Checkbox
                                            checked={stackPluvio}
                                            onChange={() => setStackPluvio(!stackPluvio)}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>*/}
                        </WhiteCard>
                    </Card>
                </Grid>
            }
            {
                stations.map((station, idx) => {
                    const statsPanel = orderBy(stats[idx], 'order').map(originalStat => {
                        const sStat = originalStat.typeId === -1 && station.typeName === 'pluviometry' ? { ...originalStat, countTotal: i18n.calculatedMeasures } : originalStat
                        const key = `${station.typeName}:${station.stationLinkedId}:${sStat.typeId}:${sStat.codepoint}:${sStat.label}:${sStat.isPiezo || sStat.typeId === -1 || false}`
                        const left = (
                            <Grid container justifyContent='center' alignItems='center'>
                                <Grid item xs={1}>
                                    <Checkbox checked={preselectedAssociations.includes(key)} onChange={v => changeSelected(key, v) } disabled={originalStat.typeId === -1 && station.stationLinkedId === piezometer.id}/>
                                </Grid>
                                <Grid item xs={3}>
                                    <Grid container justifyContent='center' alignItems='center' spacing={ 1 }>
                                        <h6>{ sStat.label }</h6>
                                    </Grid>
                                    <Grid container justifyContent='center' alignItems='center' spacing={ 1 }>
                                        <h6 style={ { fontSize: 11 } }>{ sStat.namePoint }</h6>
                                    </Grid>
                                </Grid>
                                <Grid item xs={4}>
                                    <h6>{`${i18n.fromDate} ${getDate(sStat.startDate)} ${i18n.to} ${getDate(sStat.endDate)}`}</h6>
                                </Grid>
                                <Grid item xs={3}>
                                    <h6>{(sStat.typeId === -1 && station.typeName === 'pluviometry') ? `${sStat.countTotal}` : `${sStat.countTotal} ${i18n.measures}`}</h6>
                                </Grid>
                                {
                                    !(station.stationType === 1 && originalStat.typeId === 1) && (
                                        <Grid item xs={1}>
                                            <Icon
                                                icon='chevron_right'
                                                onClick={() => changeSwap(key, true)}
                                                tooltip={i18n.seeMore}

                                            />
                                        </Grid>
                                    )
                                }
                                {
                                    (station.stationType === 1 && originalStat.typeId === 1) && (
                                        <Grid xs={1} />
                                    )
                                }
                            </Grid>
                        )
                        const right = (
                            <Grid container justifyContent='center' alignItems='center' spacing={1}>
                                <Grid item xs={1}>
                                    <Icon icon='chevron_left' onClick={() => changeSwap(key, false)} tooltip={i18n.seeMore} />
                                </Grid>
                                <Grid item xs={3}>
                                    <Grid container justifyContent='center' alignItems='center' spacing={ 1 }>
                                        <h6>{ sStat.label }</h6>
                                    </Grid>
                                    <Grid container justifyContent='center' alignItems='center' spacing={ 1 }>
                                        <h6 style={ { fontSize: 11 } }>{ sStat.namePoint }</h6>
                                    </Grid>
                                </Grid>
                                <Grid item xs={2}>
                                    <NumberField value={statMin[key]} title='Min' onChange={v => changeStat(setStatMin, statMin, key, v)} disabled={originalStat.typeId === -1 && station.stationLinkedId === piezometer.id}/>
                                </Grid>
                                <Grid item xs={2}>
                                    <NumberField value={statMax[key]} title='Max' onChange={v => changeStat(setStatMax, statMax, key, v)} disabled={originalStat.typeId === -1 && station.stationLinkedId === piezometer.id}/>
                                </Grid>
                                <Grid item xs={4}>
                                    {
                                        (station.stationType === 1 && originalStat.typeId === 1) && (
                                            <Select
                                                options={getGroups()}
                                                label={i18n.dailyRegroup}
                                                value={statGroup[key]}
                                                onChange={v => changeStat(setStatGroup, statGroup, key, v)}
                                                disabled
                                            />
                                        )
                                    }
                                    {
                                        !(station.stationType === 1 && originalStat.typeId === 1) && (
                                            <Select
                                                options={getGroups()}
                                                label={i18n.dailyRegroup}
                                                value={statGroup[key]}
                                                onChange={v => changeStat(setStatGroup, statGroup, key, v)}
                                                disabled={originalStat.typeId === -1 && station.stationLinkedId === piezometer.id}
                                            />
                                        )
                                    }
                                </Grid>
                            </Grid>
                        )
                        return (
                            <div>
                                <SwapComponent left={ left } right={ right } isSwapped={swap[key]}/>
                            </div>
                        )
                    })
                    return (
                        <Grid container justifyContent='center' alignItems='center' spacing={3} className='margin-top-1'>
                            <Card sx={{ width: '90%' }} elevation={10}>
                                <CardContent elevation={10}>
                                    <Grid container justifyContent='center' alignItems='center' spacing={3}>
                                        <Grid item xs={1}>
                                            <img src={getSiteUrl(STATION_NAME_ASSOCIATION[station.typeName])} style={{ maxHeight: '30px' } } />
                                        </Grid>
                                        <Grid item xs={2}>
                                            <h6>{i18n[station.typeName]}</h6>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <h6>{`${station.stationLinkedCode} - ${station.stationLinkedName}`}</h6>
                                        </Grid>
                                    </Grid>
                                    <div>
                                        { statsPanel }
                                    </div>
                                </CardContent>
                            </Card>
                        </Grid>

                    )
                })
            }
            <Row className='padding-bottom-1 padding-top-1 center-align'>
                <Button tooltip={ i18n.apply } onClick={ applyChanges } icon='border_color' className={`btn-floating btn-large ${readyApply ? 'pulse' : ''}`}/>
            </Row>
        </>
    )
}

PiezoSuiviAssociationsTab2.propTypes = {
    displayCote: PropTypes.number,
    piezometer: instanceOf(DtoPiezometer),
    landmarkValue: PropTypes.number,
    changeParent: PropTypes.func,
    tab: PropTypes.string,
    minDate: PropTypes.number,
    maxDate: PropTypes.number,
}

export default PiezoSuiviAssociationsTab2