import bg1 from 'assets/pictures/background/bg1.jpg'
import bg2 from 'assets/pictures/background/bg2.jpeg'
import bg3 from 'assets/pictures/background/bg3.jpeg'
import bg4 from 'assets/pictures/background/bg4.jpeg'
import { push } from 'connected-react-router'
import { template } from 'lodash'
import PropTypes from 'prop-types'
import queryString from 'query-string'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import ToastrAction from 'toastr/actions/ToastrAction'
import SieauParameterDto from '../../administration/dto/SieauParameterDto'
import Input from '../../components/forms/InputLogin'
import { contentsPath } from '../../conf/basepath'
import { applicationName, aquasysPath } from '../../conf/SieauConstants'
import aquasysLogoPath from 'assets/pictures/logoaQuasys.png'
import AppStore from '../../store/AppStore'
import { getSetting } from '../../utils/SettingUtils'
import HomeAction from '../actions/HomeAction'
import { NAME_LOGIN_PICTURE } from 'administration/constants/AdministrationConstants'

class ResetPasswordApp extends Component {
    constructor(props) {
        super(props)
        this.state = {
            defaultBackground: false,
            clicked: false,
            error: false,
            visibility: false,
        }
    }

    componentWillMount() {
        AppStore.dispatch(HomeAction.fetchImage(`${contentsPath}_PUBLIC/${getSetting(this.props.applicationSettings, NAME_LOGIN_PICTURE)}`, this.imageFound))
    }

    componentWillUnmount() {
        $('.login-body').css({ 'background-image': '' })
        document.body.classList.remove('login-body')
    }

    componentWillReceiveProps(nextProps) {
        if (getSetting(this.props.applicationSettings, 'applicationLogoConnexion') !== getSetting(nextProps.applicationSettings, 'applicationLogoConnexion')) {
            this.setState({ error: false })
        }
    }

    imageFound = (found) => {
        if (found) {
            document.body.classList.add('login-body')
            $('.login-body').css({ 'background-image': `url('${contentsPath}_PUBLIC/${getSetting(this.props.applicationSettings, NAME_LOGIN_PICTURE)}')` })
        } else {
            this.setState({ defaultBackground: true })
        }
    }

    onValidatingPassword = (e) => {
        e.preventDefault()
        const newPassword = this.refs.newPassword.refs.newPassword.value
        const newPasswordAgain = this.refs.newPasswordAgain.refs.newPasswordAgain.value
        const regex = new RegExp(getSetting(this.props.applicationSettings, 'passwordPolicy'))
        if (newPassword !== newPasswordAgain) {
            AppStore.dispatch(ToastrAction.error(i18n.confirmPasswordError))
        } else if (!regex.test(newPassword)) {
            AppStore.dispatch(ToastrAction.error(i18n.passwordPolicyError))
        } else {
            const { token } = queryString.parse(this.props.location.search)
            AppStore.dispatch(HomeAction.updatePassword(newPassword, token))
        }
    }

    onImageError = (e) => {
        if (e && !this.state.error) {
            this.setState({ error: true })
        }
    }

    getLogo = () => {
        if (!this.state.error) {
            return (<img src={ getSetting(this.props.applicationSettings, 'applicationLogoConnexion') || '' } alt='logo SIEau' className='responsive-img'
                onError={ this.onImageError }
            />)
        }
        return (<h4> { getSetting(this.props.applicationSettings, 'applicationTitle') || '' } </h4>)
    }

    getPanel = (token) => {
        const { visibility, clicked } = this.state
        if (token) {
            return (
                <form name='registration' onSubmit={ this.onValidatingPassword }>
                    <div className='row no-margin'>
                        <div className='input-field col s12 center'>
                            { this.getLogo() }
                        </div>
                    </div>
                    <div className='row' />
                    <div className='row no-margin padding-bottom-1'>
                        <span>
                            { getSetting(this.props.applicationSettings, 'securityPasswordDescription') }
                        </span>
                    </div>
                    <div className='row no-margin'>
                        <Input
                            refChild='newPassword'
                            ref='newPassword'
                            type={ visibility ? 'text' : 'password' }
                            iconInput={ visibility ? 'visibility_off' : 'visibility' }
                            onClickIcon={() => this.setState({ visibility: !visibility }) }
                            maxLength={128}
                            required
                        >
                            { i18n.newPassword }
                        </Input>
                    </div>
                    <div className='row no-margin'>
                        <Input
                            refChild='newPasswordAgain'
                            ref='newPasswordAgain'
                            type={ visibility ? 'text' : 'password' }
                            iconInput={ visibility ? 'visibility_off' : 'visibility' }
                            onClickIcon={() => this.setState({ visibility: !visibility })}
                            maxLength={128}
                            required
                        >
                            { i18n.newPasswordAgain }
                        </Input>
                    </div>
                    <div className='row valign-wrapper'>
                        <button type='submit'
                            className='btn waves-effect waves-light col s12 valign'
                        >
                            { i18n.validate }
                        </button>
                    </div>
                </form>
            )
        }

        const disabled = clicked ? 'disabled' : ''
        return (
            <form name='registration' onSubmit={ this.onSubmit }>
                <div className='row no-margin'>
                    <div className='input-field col s12 center'>
                        { this.getLogo() }
                    </div>
                </div>
                <div className='row no-margin center'>
                    <h5>{ i18n.forgetPassword }</h5>
                </div>
                <div className='row no-margin'>
                    <p>{ i18n.forgetPasswordText }</p>
                </div>
                <div className='row' />
                <div className='row no-margin'>
                    <Input refChild='login' ref='login' type='text' required>{ i18n.login }</Input>
                </div>
                <div className='row valign-wrapper'>
                    <button type='submit'
                        className={ ['btn', 'waves-effect', 'waves-light', 'col', 's12', 'valign', disabled].join(' ') }
                    >
                        { i18n.retrievePassword }
                    </button>
                </div>
                <div className='row valign-wrapper'>
                    <a href='#' onClick={ () => push('/login') }
                        className='btn waves-effect waves-light col s12 valign'
                    >
                        { i18n.toLogin }
                    </a>
                </div>
            </form>
        )
    }
    getSlider = () => {
        if (this.state.defaultBackground) {
            const bgs = [bg1, bg2, bg3, bg4].map(b => {
                return (
                    <li>
                        <img src={ b } />
                    </li>
                )
            })
            return (
                <div className='slider fullscreen'>
                    <ul className='slides'>
                        { bgs }
                    </ul>
                </div>
            )
        }
        return null
    }
    onSubmit = (e) => {
        e.preventDefault()
        this.setState({ clicked: true })
        AppStore.dispatch(HomeAction.resetPassword(this.refs.login.refs.login.value))
    }
    render() {
        const token = new URLSearchParams(this.props.location.search).get('token')
        return (
            <div className='valign-wrapper' style={ {
                width: '100%',
                height: '100%',
                position: 'absolute',
            } }
            >
                { this.getSlider() }
                <div className='valign z-index-10 width-100' >
                    <div className='container'>
                        <div className='row'>
                            <div className='col s6 m6 l4 offset-m3 offset-l4'>
                                <div className='card'>
                                    <div className='card-content'>
                                        { this.getPanel(token) }
                                        <div className='row'>
                                            <div className='col s12 center-align'>
                                                <a href={ aquasysPath } target='_blank'>
                                                    <img src={ getSetting(this.props.applicationSettings, 'applicationFavicon') || aquasysLogoPath } alt='' width='70px'/>
                                                </a>
                                            </div>
                                            <div className='col s12 center-align'>
                                                <span className='black-text'>{ template(i18n.signVersion)({ name: getSetting(this.props.applicationSettings, 'applicationName') || applicationName, version: getSetting(this.props.applicationSettings, 'version') || '' }) }
                                                    <br />
                                                    <a href='http://www.aquasys.fr' target='_blank'><b>{ i18n.aquasysCopyright }</b></a>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

ResetPasswordApp.propTypes = {
    location: PropTypes.objectOf({
        token: PropTypes.string,
    }),
    applicationSettings: PropTypes.arrayOf(PropTypes.instanceOf(SieauParameterDto)),
}

const mapStateToProps = store => {
    return {
        applicationSettings: store.AdministrationReducer.applicationSettings,
    }
}

export default connect(mapStateToProps)(ResetPasswordApp)
