import React, { Component } from 'react'
import { connect } from 'react-redux'
import { instanceOf } from '../../../utils/StoreUtils'
import { getFullDate } from '../../../utils/DateUtil'
import DtoQualitometer from '../../../quality/dto/DtoQualitometer'
import i18n from 'simple-react-i18n'
import AlertSitePanel from '../../../alerting/components/map/AlertSitePanel'
import { isEqual } from 'lodash'
import PluviometryAction from '../../../pluviometry/actions/PluviometryAction'
import { getLastMeasuresFormat } from '../../../alerting/utils/MeasureUtil'
import moment from 'moment'
import PiezometryAction from '../../../piezometry/actions/PiezometryAction'
import PropTypes from 'prop-types'
import HydrometryAction from '../../../hydrometry/actions/HydrometryAction'
import Row from '../../react/Row'
import CityDto from '../../../referencial/components/city/dto/CityDto'
import DtoStation from '../../../station/dto/DtoStation'
import iconQualito from 'assets/pictures/markers/map_popup_qualito.svg'
import iconPiezo from 'assets/pictures/markers/map_popup_piezo.svg'
import iconPluvio from 'assets/pictures/markers/map_popup_pluvio.svg'
import iconHydro from 'assets/pictures/markers/map_popup_hydro.svg'
import iconInstallation from 'assets/pictures/markers/map_popup_installations.svg'
import iconInstallationTank from 'assets/pictures/markers/map_popup_inst_tank.svg'
import iconUnite from 'assets/pictures/markers/map_unites.png'
import iconContact from 'assets/pictures/markers/map_contact.png'
import DtoMeasure from '../../../alerting/dto/alert/DtoMeasure'
import { hasValue } from '../../../utils/NumberUtil'
import { INSTALLATION_TYPES } from '../../../installation/constants/InstallationConstants'
import { getLocalStorageJson } from '../../../utils/FormUtils'
import { Grid } from '@mui/material'
import { ALL_THEME } from '../../../alerting/constants/ThemeConstants'
import AppStore from '../../../store/AppStore'
import { push } from 'connected-react-router'
import CityAction from '../../../referencial/components/city/actions/CityAction'

class MapPopupStation extends Component {
    state = {
        dataLoaded: false,
        lastMeasure: [],
        message: i18n.clickToDisplayStationDashboard,
    }

    putDataIntoState = (json, lastMeasure) => {
        const {
            station,
        } = this.props

        this.setState({
            lastMeasure: [
                {
                    ...lastMeasure[0],
                    id: station.id,
                },
            ],
            dataLoaded: true,
        })
    }

    getCityPopulation = (station) => {
        const {
            citiesIndex,
        } = this.props

        const city = citiesIndex[station.townCode]
        if (city && city.population) {
            return `Population : ${city.population}`
        }
        return ''
    }

    fetchStationMeasure = () => {
        const criterias = { date: moment().valueOf(), nbDays: 30, dataType: -1 }

        const {
            station,
        } = this.props

        switch (station.typeName) {
            case 'pluviometry':
                PluviometryAction.promisePluviometerLastMeasures([station.id])
                    .then(json => {
                        const lastMeasure = getLastMeasuresFormat(station.id, json.map(m => new DtoMeasure(m)), 'pluviometry')
                        this.putDataIntoState(json, lastMeasure.length ? lastMeasure : [{
                            id: station.id,
                        }])
                    })
                break
            case 'piezometry':
                PiezometryAction.promisePiezometrySituation(criterias, [station.id], 'type')
                    .then(json => {
                        const lastMeasure = getLastMeasuresFormat(station.id, json, 'piezometry')
                        this.putDataIntoState(json, lastMeasure.length ? lastMeasure : [{
                            id: station.id,
                        }])
                    })
                break
            case 'hydrometry':
                HydrometryAction.promiseHydroLastMeasures([station.id], getLocalStorageJson('MAP_SITUATION_SELECTED')?.typeId || 4)
                    .then(json => {
                        const lastMeasure = getLastMeasuresFormat(station.id, json, 'hydrometry')
                        this.putDataIntoState(json, lastMeasure.length ? lastMeasure : [{
                            id: station.id,
                        }])
                    })
                break
            default:
                this.setState({ dataLoaded: true, lastMeasure: [], message: this.getCityPopulation(station) })
                break
        }
    }

    componentDidUpdate(prevProps) {
        const {
            station,
            setStation,
            click,
        } = this.props

        if (!isEqual(prevProps.station, station)) {
            if (setStation) {
                setStation(station)
            }
            if (click) {
                this.setState({ dataLoaded: false, lastMeasure: [], message: this.getCityPopulation(station) })
                this.fetchStationMeasure()
                AppStore.dispatch(CityAction.fetchCities())
            } else {
                this.setState({ lastMeasure: [], message: this.getCityPopulation(station) })
            }
        }
        if (prevProps.click !== click && isEqual(prevProps.station, station)) {
            this.setState({ dataLoaded: false, lastMeasure: [], message: this.getCityPopulation(station) })
            this.fetchStationMeasure()
            AppStore.dispatch(CityAction.fetchCities())
        }
        $('ul.tabs').tabs()
    }

    componentDidMount() {
        const {
            click,
        } = this.props

        if (click) {
            this.fetchStationMeasure()
            AppStore.dispatch(CityAction.fetchCities())
        } else {
            AppStore.dispatch(PluviometryAction.fetchPluviometryDataTypes())
            AppStore.dispatch(PiezometryAction.fetchPiezometryDataTypes())
            AppStore.dispatch(HydrometryAction.fetchHydrometryDataTypes())
            this.setState({ dataLoaded: true, lastMeasure: [] })
        }
    }

    getLink = (station) => {
        if (station.typeName === 'contact' || station.typeName === 'contributor') {
            return `#/referencial/${station.typeName}/${station.id}`
        }
        return `#/station/${station.typeName}/${station.id}`
    }

    getInstallationIcon = (type) => {
        switch (type) {
            case 'POLLUTED_SOIL': return iconInstallation
            case 'BOREHOLE': return iconInstallation
            case 'INDUSTRIAL_SITE': return iconInstallation
            case 'FLOW_OBSTRUCTION': return iconInstallation
            case 'FISHING_SPOT': return iconInstallation
            case 'CAVITY': return iconInstallation
            case 'LIFTING_STATION': return iconInstallation
            case 'PUMPING': return iconInstallation
            case 'OTHERS': return iconInstallation
            case 'AEP_PRODUCTION': return iconInstallation
            case 'AEP_DISINFECTION': return iconInstallation
            case 'AEP_OVERPRESSURE': return iconInstallation
            case 'AGRI_PREL': return iconInstallation
            case 'SECTOR_COUNT': return iconInstallation
            case 'ELEC_COUNTER': return iconInstallation
            case 'STRAIT': return iconInstallation
            case 'BRIDGE': return iconInstallation
            case 'SLUICE': return iconInstallation
            case 'TREATMENT': return iconInstallation
            case 'SAMPLE_WORK': return iconInstallation
            case 'CAPTURE': return iconInstallation
            case 'STEP': return iconInstallation
            case 'TANK': return iconInstallationTank
            default: return iconInstallation
        }
    }

    // React cannot trigger clicks from this component which is on the Map, you must use the EventListener in PopupContent.js
    render() {
        const station = this.props.station || {}
        const icon = (() => {
            if (station.markerIcon) {
                return station.markerIcon
            }
            switch (station.typeName) {
                case 'quality':
                    return iconQualito
                case 'piezometry':
                    return iconPiezo
                case 'pluviometry':
                    return iconPluvio
                case 'hydrometry':
                    return iconHydro
                case 'installation':
                    if (hasValue(station.installationType)) {
                        const found = INSTALLATION_TYPES.find(t => t.code === station.installationType)
                        return found ? this.getInstallationIcon(`INSTALLATION_${found.key}`) : iconInstallation
                    }
                    return iconInstallation
                case 'productionUnit':
                    return iconUnite
                case 'contact':
                case 'contributor': return iconContact
                default:
                    return null
            }
        })()
        if (station.popupContent) {
            return (
                <div className='popupStation'>
                    {station.popupContent}
                </div>
            )
        }
        if (station.popupContentWithIcon) {
            return (
                <div className='row no-margin valign-wrapper popupStation'>
                    <div className='col s1 no-padding no-margin-left'>
                        {
                            icon?.startsWith('<svg') ? (
                                <img className='responsive-img' src={`data:image/svg+xml;utf8,${icon}`} />
                            ) : (
                                <img className='max-width-20 margin-right-1 responsive-img' src={icon} />
                            )
                        }
                    </div>
                    {station.popupContentWithIcon}
                </div>
            )
        }

        const color = ALL_THEME[station.typeName.toUpperCase()]?.color ?? 'white'

        const {
            message,
            lastMeasure,
            dataLoaded,
        } = this.state

        const site = {
            ...station,
            lastMeasure,
            measureDate: (lastMeasure[0] && moment(lastMeasure[0]?.measureDate)) ?? station.measureDate,
        }

        const {
            currentStation,
        } = this.props

        return (
            <Grid container item border={`2px solid ${color}`} borderRadius={'10px'} style={{ cursor: 'pointer' }} onClick={() => AppStore.dispatch(push(`/station/${station.typeName}/${site.id}/dashboard`))}>
                <Grid container item xs={2} position={'relative'} backgroundColor={color} direction={'column'} alignItems={'center'} justifyContent={'center'} borderRadius={'7px 0 0 7px'}>
                    <Grid item width={'50%'}>
                        {
                            icon?.startsWith('<svg') ? (
                                <img style={{ width: '100%' }} src={`data:image/svg+xml;utf8,${icon}`} />
                            ) : (
                                <img style={{ width: '100%' }} src={icon} />
                            )
                        }
                    </Grid>
                </Grid>
                {
                    dataLoaded ? (
                        <>
                            {
                                station.typeName === 'cms' ? (
                                    <div className='col s10 no-padding'>
                                        <a href={`#/contents/${station.idCategory}/${station.id}`}>
                                            <Row>
                                                <p className='bold'>{station.title}</p>
                                            </Row>
                                            <Row>
                                                <p>{getFullDate(station.dateDebut)} {station.dateFin ? ` au ${getFullDate(station.dateFin)}` : ''}</p>
                                            </Row>
                                        </a>
                                    </div>
                                ) : (
                                    <Grid container item xs={10} overflow={'hidden'} backgroundColor={'white'} borderRadius={'0 10px 10px 0'}>
                                        <AlertSitePanel
                                            site={site}
                                            measureDate={getFullDate(site.measureDate)}
                                            message={currentStation && (currentStation.id === site.id) ? '' : message}
                                            withoutIcon
                                            onClick={station.onClick}
                                            unit={station.unitSymbol}
                                            detailed={station.typeName === 'quality' || lastMeasure[0]?.id === site.id}
                                        />
                                    </Grid>
                                )
                            }
                        </>
                    ) : (
                        <Grid container item xs={10} backgroundColor={'white'} padding={'20px 0'} borderRadius={'0 10px 10px 0'} justifyContent={'center'} alignItems={'center'} direction={'column'}>
                            {i18n.progressLoading}
                        </Grid>
                    )
                }
            </Grid>
        )
    }
}

MapPopupStation.propTypes = {
    station: instanceOf(DtoQualitometer),
    click: PropTypes.bool,
    setStation: PropTypes.func,
    citiesIndex: PropTypes.objectOf(CityDto),
    currentStation: PropTypes.instanceOf(DtoStation),
}

const mapStateToProps = store => ({
    citiesIndex: store.CityReducer.citiesIndex,
})

export default connect(mapStateToProps)(MapPopupStation)