import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import ReferencialAction from '../../../action/ReferencialAction'
import {
    PATH_REFERENCIAL,
    PATH_REFERENCIAL_TAXON,
    PATH_REFERENCIAL_TAXON_LINK,
    PATH_REFERENCIAL_TAXON_NEW,
} from '../../../../home/constants/RouteConstants'
import TaxonDto from '../dto/TaxonDto'
import TaxonAction from '../actions/TaxonAction'
import QualityAction from '../../../../quality/actions/QualityAction'
import HomeAction from 'home/actions/HomeAction'
import useActions from 'utils/customHook/useActions'
import useTitle from 'utils/customHook/useTitle'
import Input from '../../../../components/forms/Input'
import Select from '../../../../components/forms/Select'
import Textarea from '../../../../components/forms/Textarea'
import Checkbox from '../../../../components/forms/Checkbox'
import useSandreList from 'utils/customHook/useSandreList'
import { getUser } from 'utils/SettingUtils'
import ToastrAction from 'toastr/actions/ToastrAction'
import { push } from 'connected-react-router'
import SimpleDatePicker from 'components/forms/SimpleDatePicker'
import sandre from 'assets/pictures/logos/sandre.png'
import inpn from 'assets/pictures/logos/inpn.png'
import { inpnTaxonLink, sandreTaxonLink } from 'conf/SieauConstants'
import ApplicationConf from 'conf/ApplicationConf'
import MUIReplaceDialog from 'referencial/components/MUIReplaceDialog'

const TaxonApp = ({ match: { params: { code } } }) => {
    const {
        taxonProps,
        taxons,
        sandreCodes,
        qualitometers,
        referencialStatus,
    } = useSelector(store => ({
        taxonProps: store.TaxonReducer.taxon,
        taxons: store.TaxonReducer.taxons,
        sandreCodes: store.ReferencialReducer.sandreCodes,
        qualitometers: store.QualityReducer.qualitometersLight,
        referencialStatus: store.ReferencialReducer.referencialStatus,
    }), shallowEqual)

    const dispatch = useDispatch()

    const [taxon, setTaxon] = useState(new TaxonDto({}))
    const [themes, setThemes] = useState([])
    const [levels, setLevels] = useState([])
    const [isEditMode, setIsEditMode] = useState(code === 'new')
    const [openReplace, setOpenReplace] = useState(false)

    const sandreThemes = useSandreList('TAXONS.THEME')
    const sandreLevels = useSandreList('TAXONS.NIVEAUX')

    const setSandreCodeToState = useCallback(() => {
        setThemes(sandreThemes)
        setLevels(sandreLevels)
    }, [sandreLevels, sandreThemes])

    const isConsultant = useMemo(() => getUser().consultant === '1', [])

    const getTaxonLinks = () => {
        return [
            taxonProps.refmuseum && {
                href: inpnTaxonLink + taxonProps.refmuseum,
                img: inpn,
                label: i18n.inpn.toUpperCase(),
            },
            taxonProps.code && {
                href: sandreTaxonLink + taxonProps.code,
                img: sandre,
                label: i18n.sandre.toUpperCase(),
            },
        ].filter(l => !!l)
    }

    useEffect(() => () => dispatch(TaxonAction.reset()), [])

    useActions(() => {
        if (isConsultant) {
            return {}
        }
        const links = getTaxonLinks()
        const isNewActions = code === 'new' && !taxonProps.code
        const actions = (() => {
            if (isNewActions) {
                return {
                    save: () => {
                        const existCode = taxons.some(f => f.code === taxon.code)
                        if (existCode) {
                            dispatch(ToastrAction.error(i18n.codeAlreadyExists))
                        } else if (!taxon.code) {
                            dispatch(ToastrAction.error(i18n.thanksToEnterCode))
                        } else {
                            dispatch(TaxonAction.createTaxon(taxon))
                            setIsEditMode(false)
                        }
                    },
                    cancel: () => {
                        dispatch(push(PATH_REFERENCIAL_TAXON))
                        setIsEditMode(false)
                    },
                }
            }
            if (isEditMode) {
                return {
                    save: () => {
                        dispatch(TaxonAction.updateTaxon(taxon))
                        setIsEditMode(false)
                    },
                    cancel: () => {
                        setTaxon(taxonProps)
                        setIsEditMode(false)
                    },
                }
            }
            return {
                edit: () => {
                    setIsEditMode(true)
                },
                replace: () => {
                    dispatch(ReferencialAction.fetchCheckDelete(ApplicationConf.referencial.taxonCountDelete(code)))
                    setOpenReplace(true)
                },
            }
        })()
        return (!isNewActions && !isEditMode && links.length > 0) ? { ...actions, links } : actions
    }, [isConsultant, code, taxonProps, taxon, isEditMode])

    useTitle(() => {
        if (code === 'new') {
            return [
                {
                    title: i18n.referencials,
                    href: PATH_REFERENCIAL,
                },
                {
                    title: i18n.taxons,
                    href: PATH_REFERENCIAL_TAXON,
                },
                {
                    title: i18n.new,
                    href: PATH_REFERENCIAL_TAXON_NEW,
                },
            ]
        }
        return [
            {
                title: i18n.referencials,
                href: PATH_REFERENCIAL,
            },
            {
                title: i18n.taxons,
                href: PATH_REFERENCIAL_TAXON,
            },
            {
                title: code + (taxonProps.latinName ? ` - ${taxonProps.latinName}` : ''),
                href: PATH_REFERENCIAL_TAXON_LINK + code,
            },
        ]
    }, [code, taxonProps])

    useEffect(() => {
        if (code !== 'new') {
            dispatch(TaxonAction.fetchTaxon(code)).then(newTaxon => setTaxon(newTaxon))
            setIsEditMode(false)
        } else {
            setIsEditMode(true)
        }

        if (!qualitometers.length) {
            dispatch(QualityAction.fetchQualitometers())
        }

        if (!referencialStatus.length) {
            dispatch(ReferencialAction.fetchReferencialStatus())
        }

        if (!sandreCodes.length) {
            dispatch(ReferencialAction.fetchSandreCodes()).then(setSandreCodeToState)
        } else {
            setSandreCodeToState()
        }
        if (!taxons.length) {
            dispatch(TaxonAction.fetchTaxons())
        }

        dispatch(HomeAction.setHelpLink('', ''))
    }, [code])

    return (
        <div className='row col s12'>
            <div className='card'>
                <div className='row no-margin'>
                    <div className='col s6 offset-s3 padding-top-1'>
                        <Select
                            options={themes}
                            label={i18n.theme}
                            col={12}
                            value={parseInt(taxon.theme)}
                            onChange={theme => setTaxon({ ...taxon, theme })}
                            nullLabel='&nbsp;'
                            disabled={!isEditMode}
                        />
                        <div className='col s12 no-padding valign-wrapper'>
                            <Input
                                col={4}
                                title={i18n.code}
                                value={taxon.code}
                                onChange={newCode => setTaxon({ ...taxon, code: newCode })}
                                disabled={!isEditMode}
                                maxLength={6}
                                obligatory
                            />
                            <Select
                                options={referencialStatus}
                                label={i18n.status}
                                col={4}
                                value={parseInt(taxon.status)}
                                onChange={v => setTaxon({ ...taxon, status: v?.toString() })}
                                nullLabel='&nbsp;'
                                disabled={!isEditMode}
                            />
                            <Checkbox
                                col={4}
                                checked={taxon.active === '1'}
                                onChange={v => setTaxon({ ...taxon, active: v ? '1' : '0' })}
                                label={i18n.active}
                                disabled={!isEditMode}
                            />

                        </div>
                        <Input
                            col={12}
                            title={i18n.latinName}
                            value={taxon.latinName}
                            onChange={latinName => setTaxon({ ...taxon, latinName })}
                            disabled={!isEditMode}
                        />
                        <Input
                            col={12}
                            title={i18n.name}
                            value={taxon.name}
                            onChange={name => setTaxon({ ...taxon, name })}
                            disabled={!isEditMode}
                        />
                        <Textarea
                            col={12}
                            title={i18n.comment}
                            value={taxon.comment}
                            onChange={comment => setTaxon({ ...taxon, comment })}
                            disabled={!isEditMode}
                        />
                        <div className={'col s12 no-padding padding-top-5-px'}>
                            <Textarea
                                col={12}
                                title={i18n.bibliography}
                                value={taxon.bibliographicReference}
                                onChange={bibliographicReference => setTaxon({ ...taxon, bibliographicReference })}
                                disabled={!isEditMode}
                            />
                        </div>
                        <div className={'col s12 no-padding padding-top-5-px'}>
                            <Input
                                col={12}
                                title={i18n.author}
                                value={taxon.author}
                                onChange={author => setTaxon({ ...taxon, author })}
                                disabled={!isEditMode}
                            />
                        </div>
                        <Input
                            col={12}
                            title={i18n.writter}
                            value={taxon.writter}
                            onChange={writter => setTaxon({ ...taxon, writter })}
                            disabled={!isEditMode}
                        />
                        <div className='col s12 no-padding'>
                            <SimpleDatePicker
                                onChange={creationDate => setTaxon({ ...taxon, creationDate })}
                                label={ i18n.creationDate }
                                value={ taxon.creationDate }
                                col={ 4 }
                                max={ taxon.updateDate }
                                disabled={!isEditMode}
                            />
                            <SimpleDatePicker
                                onChange={updateDate => setTaxon({ ...taxon, updateDate })}
                                label={ i18n.modificationDate }
                                value={ taxon.updateDate }
                                col={ 4 }
                                max={ taxon.creationDate }
                                disabled={!isEditMode}
                            />
                        </div>
                        <Select
                            className={'padding-top-5-px'}
                            options={taxons}
                            label={i18n.elementOf}
                            col={12}
                            value={parseInt(taxon.elementOf)}
                            onChange={elementOf => setTaxon({ ...taxon, elementOf })}
                            nullLabel='&nbsp;'
                            disabled={!isEditMode}
                            clearFunction
                            keyValue='code'
                            keyLabel='labelWithCode'
                        />
                        <Select
                            options={levels}
                            label={i18n.level}
                            col={12}
                            value={parseInt(taxon.level)}
                            onChange={level => setTaxon({ ...taxon, level })}
                            nullLabel='&nbsp;'
                            disabled={!isEditMode}
                        />
                        <div className='col s12 no-padding'>
                            <Input
                                col={4}
                                title={i18n.omnidiaCode}
                                value={taxon.omnidiaCode}
                                onChange={omnidiaCode => setTaxon({ ...taxon, omnidiaCode })}
                                disabled={!isEditMode}
                                maxLength={6}
                            />
                            <Input
                                col={4}
                                title={i18n.naturalHistoryMuseum}
                                value={taxon.refmuseum}
                                onChange={refmuseum => setTaxon({ ...taxon, refmuseum })}
                                disabled={!isEditMode}
                            />
                            <Input
                                col={4}
                                title={i18n.macrophyte}
                                value={taxon.macrophyteCode}
                                onChange={macrophyteCode => setTaxon({ ...taxon, macrophyteCode })}
                                disabled={!isEditMode}
                            />

                            <div className='col s12 no-padding'>
                                <Input
                                    col={4}
                                    title={i18n.spomnidia}
                                    value={taxon.spomnidia}
                                    onChange={spomnidia => setTaxon({ ...taxon, spomnidia })}
                                    disabled={!isEditMode}
                                    maxLength={6}
                                />
                                <Input
                                    col={4}
                                    title={i18n.aspe}
                                    value={taxon.aspe}
                                    onChange={aspe => setTaxon({ ...taxon, aspe })}
                                    disabled={!isEditMode}
                                    maxLength={20}
                                />
                                <Input
                                    col={4}
                                    title={i18n.worms}
                                    value={taxon.worms}
                                    onChange={worms => setTaxon({ ...taxon, worms })}
                                    disabled={!isEditMode}
                                    maxLength={20}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <MUIReplaceDialog
                elements={taxons}
                title={`${i18n.taxon} [${taxon.code}]`}
                label={i18n.taxon}
                keyLabel='latinName'
                onValidate={(newCode) => dispatch(TaxonAction.replaceTaxon(taxon.code, newCode.code))}
                open={openReplace}
                onClose={() => setOpenReplace(false)}
            />
        </div>
    )
}

TaxonApp.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            code: PropTypes.string,
        }),
    }),
}

export default TaxonApp
