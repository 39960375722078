import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import React, { useState } from 'react'
import { push } from 'connected-react-router'
import HydrometryAction from 'hydrometry/actions/HydrometryAction'
import JobAction from 'import/actions/JobAction'
import { hasValue } from 'utils/NumberUtil'
import { getManualJobId } from 'utils/JobUtils'
import { getLogin } from 'utils/SettingUtils'
import ToastrAction from 'toastr/actions/ToastrAction'
import StepperDialog from 'components/modal/StepperDialog'
import { HYDRO_IMPORT_TYPE } from 'hydrometry/constants/HydrometryConstants'
import AddHydroStepStation from './steps/AddHydroStepStation'
import AddHydroStepOptions from './steps/AddHydroStepOptions'
import AddStepExecution from 'station/components/addSteps/AddStepExecution'
import AddStepJob from 'station/components/addSteps/AddStepJob'

const STEP = {
    STATION_TYPE: 0,
    OPTIONS: 1,
    EXECUTION: 2,
    JOB: 3,
}

const setJobCode = (stationType, code, parameters, filters) => {
    switch (stationType) {
        case HYDRO_IMPORT_TYPE.HYDRO_PORTAIL:
            return { filters, parameters: [code] }
        default:
            return { parameters, filters }
    }
}

const callExecuteJob = (id, cb) => JobAction.promiseExecuteJob(id).then(json => {
    if (hasValue(json.execution)) {
        cb(json.execution)
    } else {
        setTimeout(() => JobAction.promiseExecuteJob(id).then(json2 => {
            if (hasValue(json2.execution)) {
                cb(json2.execution)
            } else {
                throw new Error('Impossible to launch Job')
            }
        }), 5000)
    }
})

const AddHydroStepper = ({
    isOpen,
    setIsOpen,
}) => {
    const {
        hydrometricStations,
        userStation,
    } = useSelector(store => ({
        hydrometricStations: store.HydrometryReducer.hydrometricStations,
        userStation: store.UserReducer.userStation,
    }), shallowEqual)

    const createStation = (station, dispatch) => {
        dispatch(HydrometryAction.createHydrometer({ ...station, stationType: null }, id => {
            setIsOpen(false)
            dispatch(push(`/station/hydrometry/${id}/description`))
        }))
    }

    const dispatch = useDispatch()

    const [station, setStation] = useState({})
    const [filters, setFilters] = useState({})
    const [jobDataTypes, setJobDataTypes] = useState([])

    const changeStation = (changes) => setStation({ ...station, ...changes })
    const changeFilters = changes => setFilters({ ...filters, ...changes })

    const executeJob = (nextStep) => {
        JobAction.promiseJob(getManualJobId(station.stationType)).then(job => {
            const jobWithCode = setJobCode(station.stationType, station.code, filters)
            const oldParams = JSON.parse(job.parameters)
            const newJob = {
                ...job, parameters: {
                    jobType: oldParams.jobType,
                    routingKey: oldParams.routingKey,
                    filters: jobWithCode.filters ? [JSON.stringify(jobWithCode.filters)] : [],
                    dataTypes: jobDataTypes,
                    parameters: jobWithCode.parameters,
                    alertTypes: [],
                }, login: getLogin(),
            }
            JobAction.promiseUpdateJob(newJob).then(res => {
                if (res.status !== 200) {
                    dispatch(ToastrAction.error(i18n.updateError + i18n.jobs))
                } else {
                    // changeStation({ jobExecutionId: 2877126, job: newJob }) // TODO: pour le test, à enlever
                    // nextStep()
                    callExecuteJob(newJob.id, jobExecutionId => {
                        changeStation({ jobExecutionId, job: newJob })
                        nextStep()
                    }).then(() => { })
                }
            })
        })
    }

    return (
        <StepperDialog
            steps={[
                {
                    label: i18n.station,
                    constant: STEP.STATION_TYPE,
                    nextAvailable: !!station.stationType,
                },
                {
                    label: i18n.options,
                    constant: STEP.OPTIONS,
                    nextAvailable: hasValue(station.code) && userStation.hasRights,
                    nextLabel: station.stationType === HYDRO_IMPORT_TYPE.EMPTY ? i18n.add : i18n.execute,
                    onNext: station.stationType === HYDRO_IMPORT_TYPE.EMPTY ? () => createStation(station, dispatch) : executeJob,
                },
                {
                    label: i18n.execution,
                    constant: STEP.EXECUTION,
                    nextAvailable: (station?.jobExecution?.statusCode === 1 || station?.jobExecution?.statusCode === 2) && !!hydrometricStations?.find(p => p.code === station.code.replaceAll(/\/.*/g, '')),
                },
                {
                    label: i18n.programmation,
                    constant: STEP.JOB,
                },
            ]}
            open={isOpen}
            title={i18n.addOrUpdateStation}
            closeDialog={() => setIsOpen(false)}
        >
            {(step, nextStep, resetStep) => {
                const fullReset = () => {
                    resetStep()
                    setStation({})
                    setFilters({})
                    setJobDataTypes([])
                }
                const params = { setIsOpen, station, changeStation, filters, changeFilters, setFilters, nextStep, jobDataTypes, setJobDataTypes }
                switch (step) {
                    case STEP.STATION_TYPE:
                        return <AddHydroStepStation {...params} />
                    case STEP.OPTIONS:
                        return <AddHydroStepOptions {...params} />
                    case STEP.EXECUTION:
                        return <AddStepExecution {...params} fetchStations={HydrometryAction.fetchHydrometricStations}/>
                    case STEP.JOB:
                    default:
                        return (
                            <AddStepJob
                                {...params}
                                fullReset={fullReset}
                                setIsOpen={setIsOpen}
                                stationType='hydrometry'
                                allStations={hydrometricStations}
                            />
                        )
                }
            }}
        </StepperDialog>
    )
}

AddHydroStepper.propTypes = {
    isOpen: PropTypes.bool,
    setIsOpen: PropTypes.func,
}

export default AddHydroStepper