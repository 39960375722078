import SimpleTabSideList from '../../../components/navbar/SimpleTabSideList'
import i18n from 'simple-react-i18n'
import React, { useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import GraphGenericPluvio from './graphs/GraphGenericPluvio'
import GraphEfficientRain from './graphs/GraphEfficientRain'
import ChoiseTypeChart from './tabs/ChoiseTypeChart'
import PluviometryAction from '../../actions/PluviometryAction'
import useUpdateEffect from '../../../utils/customHook/useUpdateEffect'
import useTitle from '../../../utils/customHook/useTitle'
import { getLinks, getStationTitle } from '../../../utils/StationUtils'
import NormalsGraphPluvio from './graphs/NormalsGraphPluvio'
import GraphMonthsStackRain from './graphs/GraphMonthsStackRain'
import ContributorAction from '../../../referencial/components/contributor/actions/ContributorAction'
import useActions from 'utils/customHook/useActions'
import { getStationArrowNav } from 'utils/ActionUtils'
import { push } from 'connected-react-router'
import GraphComparisonNormal from './graphs/GraphComparisonNormal'
import moment from 'moment/moment'

const STATS = 'STATS'
// const LINKEDSTATIONS = 'linkedStations'


const GENERIC = 'generic'
const NORMALS_YEARS= 'normalsYears'
const NORMALS_MONTHS= 'normalsMonths'
const MONTHS_STACK= 'monthsStack'
const EFFICIENT_RAIN = 'efficientRain'
const NORMAL_COMPARISON = 'comparisonNormal'

const PluvioSuiviGraphApp = ({}) => {
    const {
        pluviometer,
        pluviometerStatistics,
        pluviometers,
        pluviometryDataTypes,
    } = useSelector(store => ({
        pluviometer: store.PluviometryReducer.pluviometer,
        pluviometerStatistics: store.PluviometryReducer.pluviometerStatistics,
        pluviometers: store.PluviometryReducer.pluviometers,
        pluviometryDataTypes: store.PluviometryReducer.pluviometryDataTypes,
    }), shallowEqual)

    const [typeChart, setTypeChart] = useState(GENERIC)
    const [stat, setStat] = useState()
    const [dateComparedNormal, setDateComparedNormal] = useState(moment().subtract('3', 'year').valueOf())
    const [showEnveloppe, setShowEnveloppe] = useState(false)

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(PluviometryAction.fetchPluviometerMeasuresStats(pluviometer.id))
        dispatch(ContributorAction.fetchContributors())
        if (!pluviometryDataTypes.length) {
            dispatch(PluviometryAction.fetchPluviometryDataTypes())
        }
    }, [dispatch, pluviometer.id])

    useUpdateEffect(() => {
        setStat(pluviometerStatistics.find(p => p.typeId=== 1)?.typeId ?? pluviometerStatistics[0]?.typeId)
    }, [pluviometerStatistics])

    useTitle(() => [{
        title: i18n.pluviometry,
        href: 'pluviometry',
    }, {
        title: getStationTitle(pluviometer),
        href: `station/pluviometry/${pluviometer.id}`,
    }, {
        title: i18n.followUp,
        href: `station/pluviometry/${pluviometer.id}/pluviometricFollowUp`,
    }], [pluviometer.id])

    useActions(() => ({
        links: getLinks(pluviometer, this),
        arrowNav: getStationArrowNav('pluviometry', pluviometers, pluviometer.id, s => dispatch(push(`/station/pluviometry/${s.id}/pluviometricFollowUp`))),
    }), [pluviometer.id])


    const [paramsFrequencyStats, setParamsFrequencyStats] = useState({
        measures: [],
        showPreviousYears: [],
        histoYears: 0,
    })

    const tabs = [{
        icon: 'multiline_chart',
        constant: STATS,
        label: i18n.statistics,
    }]

    return (
        <div className={'fullscreen-chart scrolling'}>
            <SimpleTabSideList
                position='right'
                defaultTab={STATS}
                tabs={tabs}
            >
                {
                    tab => (
                        <>
                            {
                                tab === STATS && (
                                    <ChoiseTypeChart
                                        typeChart={typeChart}
                                        setTypeChart={setTypeChart}
                                        pluviometerStatistics={pluviometerStatistics}
                                        stat={stat}
                                        setStat={setStat}
                                        showEnveloppe={showEnveloppe}
                                        dateComparedNormal={dateComparedNormal}
                                        setDateComparedNormal={setDateComparedNormal}
                                        setShowEnveloppe={setShowEnveloppe}
                                        paramsFrequencyStats={paramsFrequencyStats}
                                        setParamsFrequencyStats={setParamsFrequencyStats}
                                    />
                                )
                            }
                        </>
                    )
                }
            </SimpleTabSideList>
            <div>
                { typeChart === GENERIC && (
                    <GraphGenericPluvio
                        pluviometer={pluviometer}
                        stat={stat}
                        showEnveloppe={showEnveloppe}
                        pluviometerStatistics={pluviometerStatistics}
                        paramsFrequencyStats={paramsFrequencyStats}
                        setParamsFrequencyStats={setParamsFrequencyStats}
                    />
                )}
                { typeChart === NORMALS_YEARS && (
                    <NormalsGraphPluvio pluviometer={pluviometer} typeGroupFunc={'YEARS'}/>
                )}
                { typeChart === NORMALS_MONTHS && (
                    <NormalsGraphPluvio pluviometer={pluviometer} />
                )}
                { typeChart === MONTHS_STACK && (
                    <GraphMonthsStackRain pluviometer={pluviometer} />
                )}
                { typeChart === EFFICIENT_RAIN && (
                    <GraphEfficientRain pluviometer={pluviometer} pluviometerStatistics={pluviometerStatistics}/>
                )}
                { typeChart === NORMAL_COMPARISON && (
                    <GraphComparisonNormal pluviometer={pluviometer} stat={stat} dateComparedNormal={dateComparedNormal} pluviometerStatistics={pluviometerStatistics}/>
                )}
            </div>
        </div>
    )
}
export default PluvioSuiviGraphApp