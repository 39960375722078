import { groupBy, inRange, orderBy, range, round, sumBy } from 'lodash'
import moment from 'moment'
import { getDate, getFullDate, getMonthYear, getYearDate } from './DateUtil'
import {
    AUTO,
    SUM_DAY,
    SUM_DECADE,
    SUM_HOUR,
    SUM_MONTH,
    SUM_WEEK,
    SUM_YEAR,
} from '../pluviometry/constants/PluvioOptionConstant'

const DATATYPE_RAIN = 1

const formatPluvioMeasures = listAllMeasure => {
    if (listAllMeasure && listAllMeasure.length) {
        const measures = listAllMeasure.map(m => ({ value: [m.date || m.measureDate, m.value] }))
        return orderBy(measures, m => m.value[0])
    }
    return []
}


const offsetAndCumulPluvio = (measures, offset, cumul) => {
    const offseted = offset ? measures.map(m => ({ ...m, date: moment(m[0]).add('days', offset).valueOf() })) : measures
    if (!cumul) {
        return offseted
    }
    const grouped = groupBy(offseted, d => getDate(d[0]))
    return Object.keys(grouped).map(dateStr => {
        const date = grouped[dateStr][0][0]
        const cumulRain = range(0, cumul+1).reduce((acc, n) => {
            const nStr = getDate(moment(date).subtract('days', n))
            return acc + sumBy(grouped[nStr], m => m[1])
        }, 0)
        return { ...grouped[dateStr][0], rain: cumulRain }
    })
}

const tooltipDatePluvio = (dateToFormat, sumType) => {
    switch (sumType) {
        case 'SUM_HOUR' :
        case 'SUM_DECADE' :
            return getFullDate(dateToFormat)
        case 'SUM_WEEK' :
        case 'SUM_DAY' :
            return getDate(dateToFormat)
        case 'SUM_MONTH' :
            return getMonthYear(dateToFormat)
        case 'SUM_YEAR' :
            return getYearDate(dateToFormat)
        default :
            return getFullDate(dateToFormat)
    }
}

const getAutoCmumul = (minDate, maxDate) => {
    const diffInMs = maxDate - minDate
    const diffInDays = round(diffInMs / (1000 * 60 * 60 * 24)) - 1
    if (diffInDays < 2) {
        return SUM_HOUR
    } else if (inRange(diffInDays, 2, 90)) {
        return SUM_DAY
    } else if (inRange(diffInDays, 365, 1825)) {
        return SUM_MONTH
    } else if (diffInDays > 1825) {
        return SUM_YEAR
    }
    return SUM_WEEK
}

const getGroupFunc = (label, displayModes) => {
    switch (label) {
        case AUTO: return AUTO
        case 'sumHour': return SUM_HOUR
        case 'sumDay': return SUM_DAY
        case 'sumWeek': return SUM_WEEK
        case 'sumDecade': return SUM_DECADE
        case 'sumMonth': return SUM_MONTH
        case 'sumYear': return SUM_YEAR
        case 'personalizedGrouping': return displayModes.personalizedGroupingValue
        case 'all': default: return label
    }
}

const getGroupFuncFrequencyStat = label => {
    switch (label) {
        case AUTO: return SUM_WEEK
        case 'sumHour': return SUM_WEEK
        case 'sumDay': return SUM_WEEK
        case 'sumWeek': return SUM_WEEK
        case 'sumDecade': return SUM_DECADE
        case 'sumMonth': return SUM_MONTH
        case 'sumYear': return SUM_YEAR
        case 'personalizedGrouping': return SUM_WEEK
        case 'all': default: return SUM_WEEK
    }
}

export { formatPluvioMeasures, offsetAndCumulPluvio, tooltipDatePluvio, getAutoCmumul, getGroupFunc, getGroupFuncFrequencyStat, DATATYPE_RAIN }
