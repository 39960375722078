import i18n from 'simple-react-i18n'
import Row from '../../components/react/Row'
import React, { useEffect, useMemo, useState } from 'react'
import ProgressCard from '../../components/card/ProgressCard'
import moment from 'moment/moment'
import HydrometryAction from '../../hydrometry/actions/HydrometryAction'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { min, minBy, orderBy, uniq } from 'lodash'
import { fullScreenIcon, getAxisLabelInterval, toEchartsData } from '../../components/echart/EChartUtils'
import Axis from '../../components/echart/Axis'
import { fixedNumber, nFormatter } from '../../utils/NumberUtil'
import EChart from '../../components/echart/EChart'
import ChartTabs from '../../components/echart/ChartTabs'
import useUpdateEffect from '../../utils/customHook/useUpdateEffect'
import { getDate } from '../../utils/DateUtil'
import ReactDOMServer from 'react-dom/server'
import { statusIcon } from '../../utils/StatusUtil'
import {
    getColorFromPalette, getColorFromPalette2,
} from '../../utils/ColorUtil'
import PropTypes from 'prop-types'
import DtoHydroMeasures from '../../hydrometry/dto/chronicMeasures/DtoHydroMeasures'
import DtoStationAssociation from '../../station/dto/DtoStationAssociation'
import PiezometerStationAction from '../../station/actions/PiezometerStationAction'
import Bar from '../../components/echart/series/Bar'
import PiezometryAction from '../../piezometry/actions/PiezometryAction'
import { promiseAllProgress } from '../../utils/ActionUtils'
import DtoPiezometerLight from '../../piezometry/dto/DtoPiezometerLight'
import { AccordionDetailsMUI, AccordionMUI, AccordionSummaryMUI } from 'components/styled/Accordions'
import MessageCard from 'components/card/MessageCard'

const unitSample = 'm3'

const GraphicSampling = ({
    data,
    minDate,
    maxDate,
    fullScreen,
    setFullScreen,
}) => {
    const minMeasuresDate = data.map(h => minBy(h.measures, 'date')?.date)
    const chartMinDate = minDate ? minDate : min(minMeasuresDate)

    const chartMaxDate = maxDate ? moment(maxDate) : moment()
    const axisLabelObj = getAxisLabelInterval(chartMaxDate, moment(minDate || chartMinDate))

    const grids = [{
        top: 95,
        right: '2%',
        height: 210,
        left: 60,
    }]

    const xAxis = [
        Axis({
            type: 'time',
            position: 'bottom',
            min: chartMinDate,
            max: maxDate || moment().valueOf(),
            gridIndex: 0,
            interval: axisLabelObj.interval,
            axisLabel: { show: true, formatter: axisLabelObj.formatter },
            axisLine: { show: true },
            axisTick: { show: true },
            showSplitLine: true,
        }),
    ]

    const yAxis = [
        Axis({
            type: 'value',
            nameLocation: 'middle',
            name: `${i18n.sample}  (${unitSample})`,
            gridIndex: 0,
            showSplitLine: true,
            axisLabel: { formatter: nFormatter },
            nameGap: 40,
        }),
    ]

    const getBar = (objectMeasure) => {
        return Bar({
            name: `${objectMeasure.nameStation} (${objectMeasure.typeName})`,
            barWidth: 1,
            data: toEchartsData(objectMeasure.measures),
            connectNulls: false,
            showSymbol: false,
            color: objectMeasure.color,
            stack: true,
        })
    }

    const series = data.map(d => {
        return getBar(d)
    })

    const options = {
        series,
        tooltip: {
            trigger: 'axis',
            formatter: params => {
                const date = getDate(params[0].value[2].date)
                const paramsOrder = orderBy(params.filter(o => o.seriesName !== i18n.events).map(o => {
                    return ({
                        seriesIndex: o.seriesIndex,
                        marker: o.marker,
                        seriesName: o.seriesName,
                        value: o.value[2].value,
                        status: ReactDOMServer.renderToString(statusIcon(o.value[2], 20)),
                        axisIndex: o.axisIndex,
                    })
                }), 'seriesIndex', 'asc')
                const resultSample = paramsOrder.filter(p => p.axisIndex === 0).map(o => (`<span style="display:flex;align-items:center;">${o.marker} ${o.seriesName} : ${fixedNumber(o.value)} ${unitSample} <span style="padding-left:5px">${o.status}</span> </span>`)).join('')
                return `${date} <br /> ${i18n.sample} : <br /> ${resultSample}`
            },
        },
        grid: grids,
        xAxis,
        yAxis,
        axisPointer: {
            link: { xAxisIndex: 'all' },
        },
        setDataZoom: true,
        height: 300,
        legend: {
            top: 50,
            type: 'scroll',
        },
        toolbox: {
            show: true,
            feature: {
                myToolFullScreen: {
                    show: true,
                    title: i18n.fullScreen,
                    icon: fullScreenIcon,
                    onclick: () => setFullScreen(!fullScreen),
                },
            },
        },
    }
    return <EChart options={options} id='productionUnitDashboardChart' scrollable={fullScreen} />
}
GraphicSampling.propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape({
        stationId: PropTypes.number,
        measures: PropTypes.oneOfType([
            PropTypes.arrayOf(PropTypes.instanceOf(DtoHydroMeasures)),
            PropTypes.arrayOf(PropTypes.instanceOf(DtoPiezometerLight)),
        ]),
        nameStation: PropTypes.string,
        typeName: PropTypes.string,
        color: PropTypes.string,
    })),
    minDate: PropTypes.number,
    maxDate: PropTypes.number,
    fullScreen: PropTypes.bool,
    setFullScreen: PropTypes.func,
}

const UnitSamplingPanel = ({
    associations,
    expanded = false,
    setExpanded = () => { },
}) => {
    const [fullScreen, setFullScreen] = useState(false)
    const [dataLoaded, setDataLoaded] = useState(false)
    const [progress, setProgress] = useState(0)

    const [minDate, setMinDate] = useState(undefined)
    const [maxDate, setMaxDate] = useState(moment().valueOf())

    const [measuresHydro, setMeasuresHydro] = useState([])
    const [measuresPiezo, setMeasuresPiezo] = useState([])


    const {
        hydrometricStations,
        piezometersStations,
    } = useSelector(store => ({
        hydrometricStations: store.HydrometryReducer.hydrometricStations,
        piezometersStations: store.PiezometryReducer.piezometersLight,
    }), shallowEqual)

    const dispatch = useDispatch()

    useEffect(()=> {
        dispatch(HydrometryAction.fetchHydrometricStations())
        dispatch(PiezometryAction.fetchPiezometersLight())
    }, [dispatch])

    const stationsFilteredHydro = associations.filter(s => s.stationLinkedType === 4)
    const stationsFilteredPiezo = associations.filter(s => s.stationLinkedType === 1)
    const idStationsFilteredHydro = uniq(stationsFilteredHydro.map(s => (s.stationLinkedId)))
    const idStationsFilteredPiezo = uniq(stationsFilteredPiezo.map(s => (s.stationLinkedId)))

    const loadData = (dateMin, dateMax) => {
        setDataLoaded(false)
        // Hydro
        const inputsHydro = idStationsFilteredHydro.map(stat => ({
            stationId: stat,
            dataType: -2,
            groupFunc: 'SUM_DAY',
            chartMode: true,
            startDate: dateMin,
            endDate: dateMax,
        }))
        // Piezo
        const inputsPiezo = idStationsFilteredPiezo.map(stat => ({
            stationId: stat,
            dataType: -2,
            groupFunc: 'SUM_DAY',
            chartMode: true,
            startDate: dateMin,
            endDate: dateMax,
        }))
        promiseAllProgress([
            HydrometryAction.getHydrosChronicMeasures(inputsHydro),
            PiezometerStationAction.getPiezosChartMeasures(inputsPiezo),
        ], (v) => {
            setProgress(v)
        },
        ).then((v) => {
            setMeasuresHydro(v[0])
            setMeasuresPiezo(v[1])
            setDataLoaded(true)
        })
    }

    useEffect(() => {
        loadData(minDate, maxDate)
    }, [])

    useUpdateEffect(()=> {
        loadData(minDate, maxDate)
    }, [minDate, maxDate])

    const onChangeDates = (dates, forced) => {
        if (minDate !== dates.minDate || maxDate !== dates.maxDate || forced) {
            setMinDate(dates?.minDate)
            setMaxDate(dates?.maxDate)
        }
    }

    const measuresLenghtHydro = measuresHydro.filter(h => h.measures.length > 0).filter(m => m.dataType === -2).length
    const measuresLenghtPiezo = measuresPiezo.filter(h => h.measures.length > 0).filter(m => m.dataType === -2).length

    const formatDataHydroMeasures = useMemo(()=> {
        const filteredHydro = measuresHydro.filter(h => h.measures.length > 0).filter(m => m.dataType === -2)
        const seriesHydro = filteredHydro.map((m, idx) => {
            const informationsStation = hydrometricStations.find(s => s.id === m.stationId)
            const hydro = {
                stationId: m.stationId,
                measures: m.measures,
                nameStation: `${informationsStation?.name}`,
                typeName: `${i18n.sample} ${i18n.hydrometry}`,
                color: getColorFromPalette(idx),
            }
            return hydro
        })
        return seriesHydro
    }, [hydrometricStations, measuresHydro])

    const formatDataPiezoMeasures = useMemo(()=> {
        const filteredPiezo = measuresPiezo.filter(h => h.measures.length > 0).filter(m => m.dataType === -2)
        const seriesPiezo = filteredPiezo.map((m, idx) => {
            const informationsStation = piezometersStations.find(s => s.id === m.stationId)
            const hydro = {
                stationId: m.stationId,
                measures: m.measures,
                nameStation: `${informationsStation?.name}`,
                typeName: `${i18n.sample} ${i18n.piezometry}`,
                color: getColorFromPalette2(idx),
            }
            return hydro
        })
        return seriesPiezo
    }, [measuresPiezo, piezometersStations])

    const data = [...formatDataHydroMeasures, ...formatDataPiezoMeasures]

    return (
        <AccordionMUI round style={{ marginTop: 10 }} expanded={expanded} onChange={setExpanded}>
            <AccordionSummaryMUI round iconColor='black' style={{ background: 'white', color: 'black' }}>{i18n.sample}</AccordionSummaryMUI>
            <AccordionDetailsMUI>
                <Row className={fullScreen ? 'fullscreen-chart' : ''}>
                    <div className='col s12 row no-margin padding-top-1' style={{ paddingLeft: 48 }}>
                        <div>
                            <ChartTabs onChangeDate={(dates, forced) => onChangeDates(dates, forced)} />
                        </div>
                    </div>
                    <div className='col s12 row no-margin'>
                        {
                            !dataLoaded && (
                                <div style={{ marginTop: 45 }}>
                                    <ProgressCard
                                        progress={progress}
                                    />
                                </div>
                            )
                        }
                        {
                            dataLoaded && !((measuresLenghtHydro + measuresLenghtPiezo) === 0) && (
                                <div className='col s12 row no-margin'>
                                    <GraphicSampling
                                        data={data}
                                        minDate={minDate}
                                        maxDate={maxDate}
                                        fullScreen={fullScreen}
                                        setFullScreen={setFullScreen}
                                    />
                                </div>
                            )
                        }
                        {
                            dataLoaded && (measuresLenghtHydro + measuresLenghtPiezo) === 0 && (
                                <div style={{ marginTop: 45 }}>
                                    <MessageCard>{i18n.noDataToDisplay}</MessageCard>
                                </div>
                            )
                        }
                    </div>
                </Row>
            </AccordionDetailsMUI>
        </AccordionMUI>
    )
}
UnitSamplingPanel.propTypes = {
    associations: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.instanceOf(DtoStationAssociation)),
        PropTypes.arrayOf(PropTypes.shape({
            code: PropTypes.string,
            id: PropTypes.number,
            name: PropTypes.string,
            stationLinkedCode: PropTypes.string,
            stationLinkedId: PropTypes.number,
            stationLinkedName: PropTypes.string,
            stationLinkedType: PropTypes.number,
            stationType: PropTypes.number,
        })),
    ]),
    expanded: PropTypes.bool,
    setExpanded: PropTypes.func,
}

export default UnitSamplingPanel