import React from 'react'
import { Grid } from '@mui/material'
import i18n from 'simple-react-i18n'
import PropTypes from 'prop-types'
import PresentationCard from 'components/card/PresentationCard'
import { PLUVIO_IMPORT_TYPE } from 'pluviometry/constants/PluviometryConstant'
import { useSelector } from 'react-redux'
import { trim } from 'lodash'

const AddPluvioStepStation = ({
    station,
    changeStation,
    nextStep,
    setFilters,
    setJobDataTypes,
}) => {
    const {
        pluviometryDataTypes,
    } = useSelector(store => ({
        pluviometryDataTypes: store.PluviometryReducer.pluviometryDataTypes,
    }))

    const getDefaultParams = (stationType) => {
        switch (stationType) {
            case PLUVIO_IMPORT_TYPE.METEO_FRANCE:
                const definedDataType = pluviometryDataTypes.reduce((acc, dtt) => {
                    switch (trim(dtt?.label)) {
                        case 'Pluie': return { ...acc, rain: true, rainDt: dtt.id }
                        case 'Rayonnement': return { ...acc, radiation: true, radiationDt: dtt.id }
                        case 'Température': return { ...acc, temperature: true, temperatureDt: dtt.id }
                        case 'ETP': return { ...acc, etp: true, etpDt: dtt.id }
                        default:
                            return acc
                    }
                }, {})
                return {
                    filters: definedDataType,
                    dataTypes: ['stationIntegration', 'DATA_ADD_NEW_DATA'],
                }
            default:
                return { filters: {}, dataTypes: [] }
        }
    }

    const updateParams = (stationType) => {
        const params = getDefaultParams(stationType)
        setFilters(params.filters)
        setJobDataTypes(params.dataTypes)
    }

    const centerStyle = {
        'align-items': 'center',
        display: 'flex',
        justifyContent: 'center',
    }

    return (
        <div className='padding-top-2'>
            <Grid container justifyContent='center' alignItems='center' spacing={3}>
                <h4>{i18n.newStationStep1}</h4>
            </Grid>
            <Grid container justifyContent='center' alignItems='center' spacing={3}>
                <Grid item xs={6} >
                    <PresentationCard
                        iconComponent={
                            <img
                                className='responsive-img'
                                src={'pictures/manualImports/meteofrance.png'}
                                height={70}
                                width={70}
                                style={{
                                    textAlign: 'center',
                                    display: 'table-cell',
                                    verticalAlign: 'middle',
                                }}
                            />}
                        iconContainerStyle={centerStyle}
                        title='Météo France'
                        onClick={() => {
                            changeStation({ stationType: PLUVIO_IMPORT_TYPE.METEO_FRANCE })
                            updateParams(PLUVIO_IMPORT_TYPE.METEO_FRANCE, setFilters, setJobDataTypes)
                            nextStep()
                        }}
                        isFocused={station.stationType === PLUVIO_IMPORT_TYPE.METEO_FRANCE}
                        description={i18n.meteoFranceDescription}
                    />
                </Grid>
                <Grid item xs={6}>
                    <PresentationCard
                        iconComponent={<img className='responsive-img' src={'pictures/markers/map_pluvio.png'} height={50} width={50} />}
                        iconContainerStyle={centerStyle}
                        title='Point vide'
                        onClick={() => {
                            changeStation({ stationType: PLUVIO_IMPORT_TYPE.EMPTY })
                            updateParams(PLUVIO_IMPORT_TYPE.EMPTY, setFilters, setJobDataTypes)
                            nextStep()
                        }}
                        description={i18n.newPluvioDescription}
                        isFocused={station.stationType === PLUVIO_IMPORT_TYPE.EMPTY}
                    />
                </Grid>
            </Grid>
        </div>
    )
}

AddPluvioStepStation.propTypes = {
    station: PropTypes.shape({
        code: PropTypes.string,
        stationType: PropTypes.string,
        name: PropTypes.string,
        jobExecutionId: PropTypes.number,
        job: PropTypes.shape({
            id: PropTypes.number,
        }),
        jobExecution: PropTypes.shape({
            statusCode: PropTypes.number,
        }),
        ids: PropTypes.arrayOf(PropTypes.number),
        nature: PropTypes.string,
    }),
    nextStep: PropTypes.func,
    changeStation: PropTypes.func,
    setFilters: PropTypes.func,
    setJobDataTypes: PropTypes.func,
}

export default AddPluvioStepStation