import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import SieauApp from './home/components/SieauApp'
import AppStore, { history } from 'store/AppStore'
import fr from 'assets/i18n/properties_fr.json'
import en from 'assets/i18n/properties_en.json'
import i18n from 'simple-react-i18n'
import { ThemeProvider } from '@mui/material/styles'
import BubbleHelp from './home/components/BubbleHelp'
import SitePlanApp from './home/components/SitePlanApp'
import ResetPasswordApp from './home/components/ResetPasswordApp'
import Login from './home/components/Login'
import BoundaryError from 'log/components/BoundaryError'
import AuthenticatedRoute from 'AuthenticatedRoute'
import UnauthorizedApp from 'unAuthorized/components/UnauthorizedApp'
import 'material-icons/iconfont/material-icons.css'
import { Theme } from 'utils/constants/Theme'

i18n.addLang(fr, ['fr', 'FR'])
i18n.addLang(en, ['en', 'EN'])
i18n.addLang(fr, 'default')
i18n.init()

render(
    <ThemeProvider theme={Theme}>
        <Provider store={AppStore}>
            <ConnectedRouter history={history}>
                <BoundaryError>
                    <>
                        <BubbleHelp />
                        <Switch>
                            <Route path='/login' render={(props) => <Login {...props} />} />
                            <Route path='/reset' render={(props) => <ResetPasswordApp {...props} />} />
                            <Route path='/sitePlan' render={(props) => <SitePlanApp {...props} />} />
                            <Route path='/unauthorized' render={(props) => <UnauthorizedApp {...props} />} />
                            <AuthenticatedRoute render={(props) => <SieauApp {...props} />} />
                        </Switch>
                    </>
                </BoundaryError>
            </ConnectedRouter>
        </Provider>
    </ThemeProvider>,
    document.getElementById('app'),
)

/*
const container = document.getElementById('app')
const root = createRoot(container)
root.render(
    <ThemeProvider theme={customTheme}>
        <Provider store={AppStore}>
            <ConnectedRouter history={history}>
                <BoundaryError>
                    <>
                        <BubbleHelp />
                        <Switch>
                            <Route path='/login' render={(props) => <Login {...props} />} />
                            <Route path='/reset' render={(props) => <ResetPasswordApp {...props} />} />
                            <Route path='/sitePlan' render={(props) => <SitePlanApp {...props} />} />
                            <Route path='/unauthorized' render={(props) => <UnauthorizedApp {...props} />} />
                            <AuthenticatedRoute render={(props) => <SieauApp {...props} />} />
                        </Switch>
                    </>
                </BoundaryError>
            </ConnectedRouter>
        </Provider>
    </ThemeProvider>,
)
*/