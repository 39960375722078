import React from 'react'
import PropTypes from 'prop-types'
import { getFullDateMini } from '../../utils/DateUtil'
import i18n from 'simple-react-i18n'
import Card from '../card/Card'
import { isNil } from 'lodash'

const UpdatePanel = ({
    updateLogin = '',
    updateDate = 0,
    jobExecId,
    round,
}) => !!updateLogin && !!updateDate && (
    <div className='row no-margin'>
        <div className='col s12' style={{ padding: 0 }}>
            <Card round={round} className='padding-1'>
                <span>{i18n.updatedAt} {getFullDateMini(updateDate)} {i18n.by} {updateLogin} {isNil(jobExecId) ? '' : `${i18n.fromTreatment} n°${jobExecId}`}</span>
            </Card>
        </div>
    </div>
)

UpdatePanel.propTypes = {
    updateLogin: PropTypes.string,
    updateDate: PropTypes.number,
    jobExecId: PropTypes.number,
    round: PropTypes.bool,
}
export default UpdatePanel