import i18n from 'simple-react-i18n'
import { findIndex, groupBy, isNil, isNull, isUndefined, last, maxBy, minBy, orderBy, round, sum, uniqBy } from 'lodash'
import {
    exportExcelIcon,
    exportPictureIcon,
    getTimeAxisInterval, logIcon, setLogOptions,
    setYOptions,
    toEchartsData,
    yAutomaticScaleValues,
} from '../../../../components/echart/EChartUtils'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { DEFROUNDVALUE, groupSameSuccessiveValues, hasValue } from '../../../../utils/NumberUtil'
import { getEventGraph, getEventsBar, getMeasureStatusColor } from '../../../../utils/PiezometryUtils'
import { getDate, getDateWithHour, getFullDate } from '../../../../utils/DateUtil'
import PropTypes from 'prop-types'
import HydrometryAction from '../../../actions/HydrometryAction'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import {
    getColorCircleElement,
    getColorCircleEvent,
    getColorFromPalette,
    getEventColor,
    getRGBColor,
    getThresholdColor,
} from 'utils/ColorUtil'
import { exportFile } from 'utils/ExportDataUtil'
import DtoHydrometryThreshold from '../../../dto/DtoHydrometryThreshold'
import EChart from '../../../../components/echart/EChart'
import Axis from '../../../../components/echart/Axis'
import Line from '../../../../components/echart/series/Line'
import { chunkWithWords, getExportFormat } from '../../../../utils/StringUtil'
import moment from 'moment/moment'
import EventsAction from '../../../../events/actions/EventsAction'
import { getComponentWithId, getLabel } from '../../../../utils/StoreUtils'
import DtoContributor from '../../../../station/dto/DtoContributor'
import DtoHydroMeasures from '../../../dto/chronicMeasures/DtoHydroMeasures'
import ReactDOMServer from 'react-dom/server'
import Row from '../../../../components/react/Row'
import { statusIcon, statusLabel } from '../../../../utils/StatusUtil'
import { VALIDATION } from '../../../constants/HydrometryConstants'
import ProgressCard from '../../../../components/card/ProgressCard'
import { getQualifications, getStatuses, UNQUALIFIED } from '../../../../utils/QualityUtils'

const GraphicValidationHydroMode = ({
    dataLoaded,
    selection,
    setStartDate,
    setEndDate,
    hydroMeasuresFormat,
    measureToUpdate,
    graphicOptions,
    startDate,
    endDate,
    dataType,
    hydroDataTypes,
    minDate,
    maxDate,
    addLinePrevious,
    isPreview,
    isThresholShow,
    tool,
    serieLinePrevious,
    setSerieLinePrevious,
    hydrometryThresholds,
    contributors,
    setAddLinePrevious,
    setValueCorrection,
    measuresChangesValidation,
    filterFullDay,
}) => {
    const dispatch = useDispatch()

    const {
        hydrometricStation,
        hydroStatistics,
        stationEvents,
        hydrometryDataTypes,
    } = useSelector(store => ({
        hydrometricStation: store.HydrometryReducer.hydrometricStation,
        hydrometryDataTypes: store.HydrometryReducer.hydrometryDataTypes,
        hydroStatistics: store.HydrometryReducer.hydroStatistics,
        stationEvents: store.EventsReducer.stationEvents,
    }), shallowEqual)

    const allMeasuresWithMeasureToUpdate = orderBy(uniqBy([
        ...measuresChangesValidation,
        ...hydroMeasuresFormat,
    ], 'date'), m => m.date)

    const measuresSelected = isPreview && tool === VALIDATION ? orderBy(allMeasuresWithMeasureToUpdate, m => m.date) : hydroMeasuresFormat

    const [isLog, setIsLog] = useState(false)

    const gridsHeights = [60]

    const selectPoint = (item) => {
        if (addLinePrevious) {
            return
        }
        const dateSelected = item.date
        if (selection === 'point') {
            setStartDate(dateSelected)
            setEndDate(dateSelected)
        } else if (!startDate) {
            setStartDate(dateSelected)
        } else if (dateSelected < startDate) {
            setStartDate(dateSelected)
            setEndDate(startDate)
        } else {
            setEndDate(dateSelected)
        }
    }

    useEffect(()=> {
        if (hydroMeasuresFormat.length && filterFullDay.validationFilterFullDay) {
            const measuresChecks = hydroMeasuresFormat.filter(m => m.status === 2 || m.status === 3)
            if (measuresChecks.length) {
                const maxMeasureCheck = maxBy(measuresChecks, 'date')
                if (maxMeasureCheck.date !== hydroMeasuresFormat[hydroMeasuresFormat.length-1].date) {
                    setStartDate(moment(maxMeasureCheck.date).startOf('day').valueOf())
                    setEndDate(hydroMeasuresFormat[hydroMeasuresFormat.length-1].date)
                }
            }
        }
    }, [filterFullDay])

    useEffect(()=> {
        if (hydroMeasuresFormat.length) {
            const measuresChecks = hydroMeasuresFormat.filter(m => m.status === 2 || m.status === 3)
            if (measuresChecks.length) {
                const maxMeasureCheck = maxBy(measuresChecks, 'date')
                if (maxMeasureCheck.date !== hydroMeasuresFormat[hydroMeasuresFormat.length-1].date) {
                    setStartDate(maxMeasureCheck.date)
                    setEndDate(hydroMeasuresFormat[hydroMeasuresFormat.length-1].date)
                }
            }
        }
    }, [])

    useEffect(() => {
        dispatch(HydrometryAction.fetchHydroStatistics(hydrometricStation.id))
        dispatch(EventsAction.fetchStationEvents('hydrometry', hydrometricStation.id))
    }, [hydrometricStation])

    const markArea = useMemo(() => {
        const groups = groupSameSuccessiveValues(measuresSelected, 'status')
        const areas = groups.filter(g => g.length && g[0].status).map(group => {
            const start = last(group)
            const end = group[0]
            return [{ xAxis: start.date, itemStyle: { color: getMeasureStatusColor(end.status), opacity: 0.5 } },
                { xAxis: end.date, itemStyle: { color: getMeasureStatusColor(end.status), opacity: 0.5 } }]
        })
        return areas.length ? { data: areas } : {}
    }, [measuresSelected])

    const thresholds = !isThresholShow ? [] : hydrometryThresholds.filter(t => !isUndefined(t.value) && t.dataType === `${dataType}` && t.stationId === `${hydrometricStation.id}` && hydroStatistics.find(h => `${h.typeId}` === t.dataType)).map(t => ({
        yAxis: t.value,
        symbol: 'diamond',
        label: {
            show: true,
            position: 'middle',
            formatter: () => t.title ? `${t.title} : ${t.value}` : '',
        },
        lineStyle: {
            type: 'solid',
            width: 2,
            color: t.htmlColor || getThresholdColor(t.color),
        },
    }))

    const grid = [{
        top: sum(gridsHeights),
        right: 50,
        height: 40,
        left: 100,
        name: 'eventGrid',
    },
    {
        top: 105,
        right: 50,
        height: 450,
        left: 100,
    }]
    gridsHeights.push(60)

    const dataStartDate = hydroMeasuresFormat.find(m => startDate === m.date)
    const dataEndDate = hydroMeasuresFormat.find(m => endDate === m.date)

    const eventsValue = stationEvents.filter(e => {
        if (e.eventType === 'T') {
            return false
        }
        if (e.date) {
            const eventDate = getDateWithHour(e.date, e.eventHour).valueOf()
            return eventDate >= minDate && eventDate <= maxDate && e.graph == '1'
        }
        return false
    })

    const eventOptions = {
        tooltip: {
            trigger: 'item',
            formatter: (params) => {
                const startDate = getFullDate(params.value[2].date)
                const eventsFiltered = eventsValue.filter(e => e.date === params.value[2].date)
                const eventsList = eventsFiltered.filter(e => {
                    const startDate = getDateWithHour(e.date, e.eventHour).valueOf()
                    const endDate = e.endDate && e.endDate - startDate > 20000000 ? e.endDate : startDate + 20000000
                    return startDate >= params.value[0] && endDate <= params.value[1] && e.graph == '1'
                })
                const labelEvents = eventsList.reduce((acc, v) => {
                    const comment = v.comment ? (chunkWithWords(v.comment, 40).replaceAll('\n', '<br />')) : i18n.event
                    if (params.value[2].endDate) {
                        const endDate = getFullDate(params.value[2].endDate)
                        return `${acc}<br />${getColorCircleEvent(v.eventType)} ${startDate}-${endDate} : ${comment}`
                    }
                    return `${acc}<br />${getColorCircleEvent(v.eventType)} ${startDate} : ${comment}`
                }, i18n.events)
                return labelEvents
            },
        },
        itemStyle: {
            normal: {
                opacity: 0.5,
            },
        },
        yAxisIndex: 1,
    }

    const hydroSerie = Line({
        data: toEchartsData(measuresSelected),
        name: hydroDataTypes.find(h => h.id==dataType)?.label || '',
        color: 'black',
        serieId: 'height',
        connectNulls: false,
        xAxisIndex: 1,
        yAxisIndex: 2,
        markArea,
        markLine: {
            symbol: 'none',
            silent: false, data: [...thresholds, {
                xAxis: startDate ?? 0,
                lineStyle: {
                    color: 'grey',
                },
                label: {
                    show: true,
                    position: 'middle',
                    formatter() {
                        return getDate(startDate)
                    },
                },
            }, {
                xAxis: endDate ?? 0,
                lineStyle: {
                    color: 'grey',
                },
                label: {
                    show: true,
                    position: 'middle',
                    formatter() {
                        return getDate(endDate)
                    },
                },
            }],
        },
    })
    gridsHeights.push(470)

    const linePrevious = useMemo(() => {
        if (!addLinePrevious) {
            return []
        }
        switch (tool) {
            case 'purge' :
                return [Line({
                    data: toEchartsData([dataStartDate, dataEndDate]),
                    name: 'previous',
                    color: 'green',
                    serieId: 'AddLine',
                    xAxisIndex: 1,
                    yAxisIndex: 2,
                })]
            case 'compensation' :
                return [Line({
                    data: toEchartsData(serieLinePrevious),
                    name: 'previous',
                    color: 'green',
                    serieId: 'AddLine',
                    xAxisIndex: 1,
                    yAxisIndex: 2,
                })]
            case 'correction' :
                return [Line({
                    data: toEchartsData(serieLinePrevious),
                    name: 'previous',
                    color: 'green',
                    serieId: 'AddLine',
                    xAxisIndex: 1,
                    yAxisIndex: 2,
                })]
            default :
                return []
        }
    }, [addLinePrevious, serieLinePrevious])

    const eventsGraph = getEventsBar(eventsValue, {
        xAxisIndex: 0,
        yAxisIndex: 0,
    })
    const series = [
        getEventGraph(eventsValue, eventOptions),
        eventsGraph,
        hydroSerie,
        ...linePrevious,
    ]


    const selectOnChart = (item) => {
        if (hydroMeasuresFormat.length) {
            const [selectedDate] = getComponentWithId('.sieauChart').getInstance().convertFromPixel({ xAxisIndex: 1, yAxisIndex: 2 }, [item.offsetX, item.offsetY])
            const nextOneIdx = findIndex(hydroMeasuresFormat, m => m.date > selectedDate)
            const next = nextOneIdx !== -1 ? hydroMeasuresFormat[nextOneIdx] : null
            const previous = nextOneIdx !== -1 ? hydroMeasuresFormat[nextOneIdx - 1] : last(hydroMeasuresFormat)
            const foundMeasure = minBy([previous, next].filter(m => !!m), m => m.date > selectedDate ? m.date - selectedDate : selectedDate - m.date)
            selectPoint(foundMeasure)
        }
    }

    const xAxis = [
        Axis({
            type: 'time',
            position: 'bottom',
            min: minDate,
            max: maxDate || moment().valueOf(),
            axisLabel: { show: false },
            axisLine: { show: false },
            axisTick: { show: false },
        }),
        Axis({
            type: 'time',
            position: 'bottom',
            gridIndex: 1,
            min: minDate,
            max: maxDate || moment().valueOf(),
            splitLine: {
                show: hasValue(graphicOptions.showXSplitLines) ? graphicOptions.showXSplitLines : true,
            },
            axisLabel: {
                formatter (value) {
                    return getDate(value)
                },
            },
            maxInterval: getTimeAxisInterval(graphicOptions.intervalChoice),
        })]

    const valueMeasure = hydroMeasuresFormat.map(d => d.value) || []
    const yScale = yAutomaticScaleValues([ ...valueMeasure, ...thresholds.map(t => t.yAxis) ])
    const hydroStat = hydroDataTypes.find(h => h.id==dataType)
    const unit = hydroStatistics.find(v => v.typeId === dataType)?.unit

    const yAxis = [
        Axis({
            type: 'value',
            data: [i18n.events],
            nameLocation: 'middle',
            minInterval: 1,
            nameGap: 40,
            position: 'right',
            axisLabel: { show: false },
            axisLine: { show: false },
            axisTick: { show: false },
        }),
        Axis({
            type: 'category',
            data: [i18n.events],
            nameLocation: 'middle',
            minInterval: 1,
            nameGap: 40,
            position: 'left',
        }),
        Axis({
            type: isLog ? 'log' : 'value',
            nameLocation: 'middle',
            name: unit ? `${hydroStat?.label || ''} [${unit}]` : hydroStat?.label || '',
            gridIndex: 1,
            splitLine: {
                interval: 10,
                show: hasValue(graphicOptions.showYSplitLines) ? graphicOptions.showYSplitLines : true,
            },
            ...setYOptions({ min: graphicOptions.minY, max: graphicOptions.maxY }, yScale),
            ...setLogOptions(isLog),
        })]


    const groups = groupSameSuccessiveValues(hydroMeasuresFormat, 'producer')
    const byProducer = groupBy(groups.filter(g => g.length && hasValue(g[0].producer)), group => group[0].producer)

    Object.keys(byProducer).forEach((producer, idx) => {
        const producerGroups = byProducer[producer]
        const producerName = getLabel(contributors, producer, 'mnemonique')
        grid.push({
            top: sum(gridsHeights),
            right: 55,
            height: 30,
            left: 100,
            name: producerName,
        })
        gridsHeights.push(35)

        const areas = producerGroups.map(group => {
            const start = last(group)
            const end = group[0]
            return [{ xAxis: start.date, itemStyle: { color: getColorFromPalette(idx), opacity: 0.5 } },
                { xAxis: end.date, itemStyle: { color: getColorFromPalette(idx), opacity: 0.5 } }]
        })
        series.push(Line({
            data: [],
            name: producerName,
            yAxisIndex: grid.length,
            xAxisIndex: grid.length - 1,
            connectNulls: false,
            showSymbol: false,
            color: getColorFromPalette(idx),
            serieId: producerName,
            markArea: { data: areas },
        }))
        xAxis.push(Axis({
            type: 'time',
            position: 'bottom',
            min: minDate ? minDate : minBy(hydroMeasuresFormat, 'date').date,
            max: maxDate || moment().valueOf(),
            gridIndex: grid.length - 1,
            axisLabel: { show: false },
            axisLine: { show: false },
            axisTick: { show: false },
            showSplitLine: true,
        }))
        yAxis.push(Axis({
            type: 'value',
            nameLocation: 'center',
            name: producerName,
            gridIndex: grid.length - 1,
            nameGap: 5,
            nameRotate: 0,
            showSplitLine: true,
        }))
    })

    let posClick = useRef([0, 0])

    useEffect(() => {
        setSerieLinePrevious(measureToUpdate ?? [])
    }, [measureToUpdate])

    const getExportData = () => {
        const stat = hydroStatistics.find(s => s.typeId === dataType)
        const roundValue = hydrometryDataTypes.find(hdt => hdt.id === dataType)?.numberDecimal || DEFROUNDVALUE
        const data = hydroMeasuresFormat.map(d => ({
            stationCode: { value: hydrometricStation.code },
            stationName: { value: hydrometricStation.name },
            date: { value: getFullDate(d.date), format: 'dd/MM/yyyy HH:mm:ss', cellType: 'date' },
            value: { value: d.value, format: getExportFormat(roundValue), cellType: 'number' },
            type: { value: stat.label },
            status: { value: getLabel(getStatuses(), d.status), cellType: 'string' },
            qualification: { value: getLabel(getQualifications(), d.qualification || UNQUALIFIED), cellType: 'string' },
            codepoint: { value: d.codepoint },
        }))

        if (data.length) {
            data[0].headers = ['stationCode', 'stationName', 'date', 'value', 'type', 'status', 'qualification', 'codepoint']
        }
        return data
    }

    const options = useMemo(() => {
        return {
            series,
            grid,
            xAxis,
            yAxis,
            height: sum(gridsHeights),
            axisPointer: {
                link: { xAxisIndex: 'all' },
            },
            setDataZoom: (!(tool === 'compensation') || isNull(startDate) || isNull(endDate)),
            toolbox: {
                show: true,
                feature: {
                    restore: { title: i18n.restore },
                    saveAsImage: { title: i18n.export, icon: exportPictureIcon },
                    myLog: {
                        show: true,
                        title: i18n.logarithm,
                        icon: logIcon,
                        onclick: () => setIsLog(!isLog),
                    },
                    myToolExport: {
                        show: true,
                        title: i18n.excelExport,
                        icon: exportExcelIcon,
                        onclick: () => {
                            exportFile({
                                data: getExportData(),
                                exportType: 'xlsx',
                                titleFile: i18n.overview,
                            })
                        },
                    },
                },
                right: 50,
            },
            tooltip: {
                trigger: 'axis',
                formatter: params => {
                    const date = getFullDate(params[0].value[2].date)
                    const paramsOrder = uniqBy(params.filter(o => hasValue(o.value[2].value) && o.seriesName !== i18n.events).map(o => ({
                        marker: o.marker,
                        seriesName: o.seriesName,
                        value: o.value[2].value,
                        status: hasValue(o.value[2]) ? ReactDOMServer.renderToString(<Row className='valign-wrapper'>{statusIcon(o.value[2], 20)}&nbsp;{statusLabel(o.value[2])}</Row>) : '',
                    })).reverse(), 'value')
                    const eventsGraph = stationEvents.filter(e => e.eventType !== 'T')
                    const stationEventsFiltered = eventsGraph.filter(o => moment(o.date).isSame(params[0].value[0], 'day') && o.graph == '1')
                    const labelEvents = stationEventsFiltered.length ? stationEventsFiltered.reduce((acc, v) => {
                        return `${acc} ${
                            getColorCircleElement(getRGBColor(getEventColor(v.eventType)))}${v.comment ? (chunkWithWords(v.comment, 40).replaceAll('\n', '<br />')) : i18n.event}<br />`
                    }, '<br />') : ''
                    const result = paramsOrder.map(o => `${o.marker} ${o.seriesName} : ${o.value} ${unit ? `[${unit}]` : ''} <br/> ${o.status}`).join('<br/>')
                    return `${date} ${labelEvents}<br /> ${result}`
                },
            },
        }
    }, [series, xAxis, yAxis, hydroStatistics, hydroMeasuresFormat, hydrometricStation.name, dataType, isPreview, serieLinePrevious])

    let echartRef = useRef()
    const timeoutRef = useRef()

    clearTimeout(timeoutRef.current)

    timeoutRef.current = setTimeout(() => {
        if (isNil(echartRef.current)) {
            return
        }
        const echartInstance = echartRef.current.getInstance()
        if (isNil(echartRef.current.getInstance())) {
            return
        }
        if ((!(tool === 'compensation') && !(tool === 'correction')) || isNull(startDate) || isNull(endDate) || isPreview) {
            echartInstance.setOption({
                graphic: {
                    type: 'rect',
                    position: [0, 0],
                    shape: {
                        x: 100,
                        y: 120,
                        width: 10000,
                        height: 550,
                    },
                    invisible: true,
                    z: 500,
                    onclick: selectOnChart,
                },
            })
            return
        }
        switch (tool) {
            case 'compensation' :
                echartInstance.setOption({
                    graphic: {
                        id: 'group',
                        type: 'group',
                        draggable: true,
                        ondragstart: (e) => {
                            posClick.current = echartInstance.convertFromPixel({ xAxisIndex: 1, yAxisIndex: 2 }, [e.offsetX, e.offsetY])
                        },
                        ondragend: (e) => {
                            const posRelease = echartInstance.convertFromPixel({ xAxisIndex: 1, yAxisIndex: 2 }, [e.offsetX, e.offsetY])
                            const offset = [
                                posRelease[0] - posClick.current[0],
                                posRelease[1] - posClick.current[1],
                            ]
                            const toUpdate = serieLinePrevious.map(d => {
                                return {
                                    ...d,
                                    value: d.value + offset[1],
                                }
                            })
                            setSerieLinePrevious(toUpdate)
                        },
                        children: [
                            {
                                id: 'clickLine',
                                type: 'polyline',
                                z: 100,
                                invisible: true,
                                shape: {
                                    points: serieLinePrevious.map(d => echartInstance.convertToPixel({ xAxisIndex: 1, yAxisIndex: 2 }, [d.date, d.value])),
                                },
                                style: {
                                    stroke: '#00FF00',
                                    lineWidth: 20,
                                },
                            },
                            {
                                id: 'displayLine',
                                type: 'polyline',
                                origin: echartInstance.convertToPixel({ xAxisIndex: 1, yAxisIndex: 2 }, [measureToUpdate[0].date, measureToUpdate[0].value]),
                                z: 100,
                                shape: {
                                    points: serieLinePrevious.map(d => echartInstance.convertToPixel({ xAxisIndex: 1, yAxisIndex: 2 }, [d.date, d.value])),
                                },
                                style: {
                                    stroke: 'green',
                                    lineWidth: 2,
                                },
                            },
                            ...serieLinePrevious.map((d, i) => ({
                                id: `circle${i}`,
                                type: 'circle',
                                position: echartInstance.convertToPixel({ xAxisIndex: 1, yAxisIndex: 2 }, [d.date, d.value]),
                                shape: {
                                    cx: 0,
                                    cy: 0,
                                    r: 3,
                                },
                                style: {
                                    stroke: 'green',
                                    fill: '#FFFFFF',
                                },
                                z: 101,
                            })),
                        ],
                    },
                })
                return
            case 'correction' :
                const point = measureToUpdate[measureToUpdate.length-1]
                echartInstance.setOption({
                    graphic: {
                        id: 'group',
                        type: 'group',
                        draggable: true,
                        ondragstart: (e) => {
                            posClick.current = echartInstance.convertFromPixel({ xAxisIndex: 1, yAxisIndex: 2 }, [e.offsetX, e.offsetY])
                        },
                        ondragend: (e) => {
                            const posRelease = echartInstance.convertFromPixel({ xAxisIndex: 1, yAxisIndex: 2 }, [e.offsetX, e.offsetY])
                            const value = posRelease[1]
                            setValueCorrection(round(posRelease[1], 2))
                            const len = measureToUpdate?.length
                            const diff = value - measureToUpdate[len-1]?.value
                            const lastDate = measureToUpdate[len-1]?.date
                            const timeDiff = lastDate - measureToUpdate[0]?.date
                            const getDiff = (measure) => diff * (1 - ((lastDate - measure) / timeDiff))

                            const toUpdate = serieLinePrevious.map(d => {
                                return {
                                    ...d,
                                    value: d.value + getDiff(d.date),
                                }
                            })
                            const findIndexStartDate = hydroMeasuresFormat.findIndex(m => m.date===startDate)-1
                            const measureBeforeStartDate = hydroMeasuresFormat[findIndexStartDate]

                            const findIndexEndDate = hydroMeasuresFormat.findIndex(m => m.date===endDate)+1
                            const measureAfterStartDate = hydroMeasuresFormat[findIndexEndDate]

                            const allMeasuresPreview = [
                                ...(measureBeforeStartDate ? [measureBeforeStartDate] : []),
                                ...toUpdate,
                                ...(measureAfterStartDate ? [measureAfterStartDate] : []),
                            ]
                            setAddLinePrevious(true)
                            setSerieLinePrevious(allMeasuresPreview)
                        },
                        children: [
                            {
                                id: 'clickLine',
                                type: 'polyline',
                                z: 100,
                                invisible: true,
                                shape: {
                                    points: echartInstance.convertToPixel({ xAxisIndex: 1, yAxisIndex: 2 }, [point.date, point.value]),
                                },
                                style: {
                                    stroke: '#00FF00',
                                    lineWidth: 20,
                                },
                            },
                            {
                                id: 'displayLine',
                                type: 'polyline',
                                origin: echartInstance.convertToPixel({ xAxisIndex: 1, yAxisIndex: 2 }, [serieLinePrevious[0].date, serieLinePrevious[0].value]),
                                z: 100,
                                shape: {
                                    points: echartInstance.convertToPixel({ xAxisIndex: 1, yAxisIndex: 2 }, [point.date, point.value]),
                                },
                                style: {
                                    stroke: 'green',
                                    lineWidth: 2,
                                },
                            },
                            {
                                id: 'circle1',
                                type: 'circle',
                                position: echartInstance.convertToPixel({ xAxisIndex: 1, yAxisIndex: 2 }, [point.date, point.value]),
                                shape: {
                                    cx: 0,
                                    cy: 0,
                                    r: 3,
                                },
                                style: {
                                    stroke: 'green',
                                    fill: '#FFFFFF',
                                },
                                z: 101,
                            },
                        ],
                    },
                })
                return
            default :

                echartInstance.setOption({
                    graphic: {
                        type: 'rect',
                        position: [0, 0],
                        shape: {
                            x: 100,
                            y: 120,
                            width: 10000,
                            height: 550,
                        },
                        invisible: true,
                        z: 500,
                        onclick: selectOnChart,
                    },
                })
                return
        }
    }, 100)


    return dataLoaded ? (
        <EChart
            options={options}
            id='hydroChart'
            scrollable={false}
            ref={e => {
                if (!isNull(e)) {
                    echartRef.current = e
                }
            }}
        />
    ) : <div style={{ paddingTop: 55 }}><ProgressCard progress={50} /></div>
}

GraphicValidationHydroMode.propTypes = {
    selection: PropTypes.string,
    setStartDate: PropTypes.func,
    setEndDate: PropTypes.func,
    minDate: PropTypes.number,
    maxDate: PropTypes.number,
    hydroMeasuresFormat: PropTypes.instanceOf(DtoHydroMeasures),
    measureToUpdate: PropTypes.instanceOf(DtoHydroMeasures),
    graphicOptions: PropTypes.shape({
        minY: PropTypes.number,
        maxY: PropTypes.number,
        intervalChoice: PropTypes.string,
        showXSplitLines: PropTypes.bool,
        showYSplitLines: PropTypes.bool,
    }),
    startDate: PropTypes.number,
    endDate: PropTypes.number,
    dataType: PropTypes.number,
    hydroDataTypes: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        label: PropTypes.string,
    })),
    dataLoaded: PropTypes.number,
    addLinePrevious: PropTypes.bool,
    isPreview: PropTypes.bool,
    isThresholShow: PropTypes.bool,
    tool: PropTypes.string,
    serieLinePrevious: PropTypes.arrayOf(PropTypes.instanceOf(DtoHydroMeasures)),
    setSerieLinePrevious: PropTypes.func,
    hydrometryThresholds: PropTypes.arrayOf(PropTypes.instanceOf(DtoHydrometryThreshold)),
    contributors: PropTypes.arrayOf(DtoContributor),
    setAddLinePrevious: PropTypes.func,
    setValueCorrection: PropTypes.func,
    measuresChangesValidation: PropTypes.arrayOf(PropTypes.instanceOf(DtoHydroMeasures)),
    filterFullDay: PropTypes.arrayOf(PropTypes.shape({
        validationFilterFullDay: PropTypes.bool,
        setValidationFilterFullDay: PropTypes.func,
    })),
}

export default GraphicValidationHydroMode