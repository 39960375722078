import React, { useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import PluviometryAction from '../../../actions/PluviometryAction'
import moment from 'moment/moment'
import ProgressCard from '../../../../components/card/ProgressCard'
import MessageCard from '../../../../components/card/MessageCard'
import i18n from 'simple-react-i18n'
import Axis from '../../../../components/echart/Axis'
import { DEFROUNDVALUE, nFormatter } from '../../../../utils/NumberUtil'
import {
    exportExcelIcon,
    exportPictureIcon,
    getAxisLabelInterval,
    toEchartsData,
} from '../../../../components/echart/EChartUtils'
import { getFullDate, getMonthYear, getYearDate } from '../../../../utils/DateUtil'
import { findKey, min, minBy, orderBy, partition } from 'lodash'
import ReactDOMServer from 'react-dom/server'
import { statusIcon } from '../../../../utils/StatusUtil'
import EChart from '../../../../components/echart/EChart'
import Bar from '../../../../components/echart/series/Bar'
import PanelEfficientRainCalcul from '../utils/PanelEfficientRainCalcul'
import ChartTabsSuivi from '../../../../components/echart/ChartTabsSuivi'
import { exportFile } from '../../../../utils/ExportDataUtil'
import { getLabel } from '../../../../utils/StoreUtils'
import PropTypes from 'prop-types'
import PluviometerDto from '../../../dto/PluviometerDto'
import DtoPluvioMeasure from '../../../dto/measures/DtoPluvioMeasure'
import DtoPluviometerStats from '../../../dto/DtoPluviometerStats'
import {
    AUTO,
    SUM_DAY,
    SUM_DECADE,
    SUM_HOUR,
    SUM_MONTH,
    SUM_WEEK,
    SUM_YEAR,
} from '../../../constants/PluvioOptionConstant'
import Card from 'components/card/Card'
import SimpleChartTabsPluvio from '../../../../components/echart/SimpleChartTabsPluvio'
import { Grid } from '@mui/material'
import { getExportFormat } from 'utils/StringUtil'

const Graphic = ({
    pluviometerInfos,
    data,
    minDate,
    maxDate,
    pluvioGroupedMode,
}) => {
    const allMeasures = data.map(m => m.measures).map(h => minBy(h, 'date')?.date)
    const chartMinDate = minDate? minDate : min(allMeasures)

    const chartMaxDate = moment(maxDate)
    const axisLabelObj = getAxisLabelInterval(chartMaxDate, moment(minDate || chartMinDate))

    const {
        contributors,
        pluviometryDataTypes,
    } = useSelector(store => ({
        pluviometryDataTypes: store.PluviometryReducer.pluviometryDataTypes,
        contributors: store.ContributorReducer.contributors,
    }), shallowEqual)

    const grids = [{
        top: 35,
        right: '2%',
        height: 275,
        left: 60,
    }]

    const xAxis = [
        Axis({
            type: 'time',
            position: 'bottom',
            min: chartMinDate,
            max: maxDate || moment().valueOf(),
            gridIndex: 0,
            interval: axisLabelObj.interval,
            axisLabel: { show: true, formatter: axisLabelObj.formatter },
            axisLine: { show: true },
            axisTick: { show: true },
            showSplitLine: true,
        }),
    ]

    const yAxis = [
        Axis({
            type: 'value',
            nameLocation: 'middle',
            name: `${i18n.effectiveRain} ${data[0]?.stat?.unit ? `[${data[0]?.stat?.unit}]` : ''}`,
            gridIndex: 0,
            showSplitLine: true,
            axisLabel: { formatter: nFormatter },
            nameGap: 40,
        }),
    ]

    const getLine = (measures, infos, color) => {
        return Bar({
            name: infos?.label ?? '',
            barWidth: 4,
            data: toEchartsData(measures, null, null, infos?.unit ?? ''),
            color,
            connectNulls: false,
            showSymbol: true,
            xAxisIndex: 0,
            yAxisIndex: 0,
        })
    }

    const tooltipDate = (params) => {
        switch (pluvioGroupedMode) {
            case SUM_HOUR :
            case SUM_DAY :
            case SUM_WEEK :
            case SUM_DECADE :
                return getFullDate(params)
            case SUM_MONTH :
                return getMonthYear(params)
            case SUM_YEAR :
                return getYearDate(params)
            default :
                return getFullDate(params)
        }
    }

    const seriesRain = getLine(data[0].measures, data[0].stat, 'blue')
    const seriesETP = getLine(data[1].measures, data[1].stat, '#009dbd')
    const [sup, inf] = partition(data[2].measures, m => m.value > 0)

    const seriesEffientRainSup = getLine(sup, { label: `${data[2]?.stat?.label ?? ''} >0 ` }, '#6a37ff')
    const seriesEffientRainInf = getLine(inf, { label: `${data[2]?.stat?.label ?? ''} <0 ` }, '#ff3312')

    const series = [seriesRain, seriesETP, seriesEffientRainSup, seriesEffientRainInf]

    const exportSerie = (serie, roundValue) => serie.data.filter(d => d.value[0] >= minDate && d.value[0] <= maxDate).map(d => {
        const measure = d.value[2]
        return {
            stationCode: { value: pluviometerInfos.code },
            stationName: { value: pluviometerInfos.name },
            date: { value: getFullDate(measure.date), format: 'dd/MM/yyyy HH:mm:ss', cellType: 'date' },
            value: { value: measure.value, format: getExportFormat(roundValue), cellType: 'number' },
            type: { value: serie.name },
            producer: { value: getLabel(contributors, measure.producer, 'mnemonique'), cellType: 'string' },
        }
    })
    const getExportData = () => {
        const dataExport = series.flatMap(chart => {
            const roundValue = pluviometryDataTypes.find(pdt => pdt.id === chart?.obj?.data?.[0]?.value?.[2]?.dataType)?.numberDecimal || DEFROUNDVALUE
            return exportSerie(chart.obj, roundValue)
        })
        if (dataExport.length) {
            dataExport[0].headers = ['stationCode', 'stationName', 'date', 'value', 'type', 'producer']
        }
        return dataExport
    }

    const options = {
        series,
        title: `${pluviometerInfos.code} - ${pluviometerInfos.name}`,
        tooltip: {
            trigger: 'axis',
            formatter: params => {
                const date = tooltipDate(params[0].value[2].date)
                const paramsOrder = orderBy(params.map(o => {
                    return ({
                        seriesIndex: o.seriesIndex,
                        marker: o.marker,
                        seriesName: o.seriesName,
                        value: o.value[2].value,
                        status: ReactDOMServer.renderToString(statusIcon(o.value[2], 20)),
                        axisIndex: o.axisIndex,
                        unit: o.data.unit ?? '',
                    })
                }), 'seriesIndex', 'asc')
                const result = paramsOrder.filter(p => p.axisIndex === 0).map(o => (`<span style="display:flex;align-items:center;">${o.marker} ${o.seriesName} : ${o.value} ${o.unit}<span style="padding-left:5px">${o.status}</span></span>`)).join('')
                return `${date} <br /> ${result} `
            },
        },
        grid: grids,
        xAxis,
        yAxis,
        axisPointer: {
            link: { xAxisIndex: 'all' },
        },
        setDataZoom: true,
        height: 360,
        legend: {
            bottom: 60,
            type: 'scroll',
        },
        toolbox: {
            show: true,
            feature: {
                saveAsImage: { title: i18n.export, icon: exportPictureIcon },
                myToolExport: {
                    show: true,
                    title: i18n.excelExport,
                    icon: exportExcelIcon,
                    onclick: () => {
                        exportFile({
                            data: getExportData(),
                            exportType: 'xlsx',
                            titleFile: i18n.overview,
                        })
                    },
                },
            },
            right: 65,
        },
    }
    return (
        <EChart options={options} id={'graphEfficientRainChart'} />
    )
}
Graphic.propTypes = {
    pluviometerInfos: PropTypes.shape({
        code: PropTypes.string,
        name: PropTypes.string,
    }),
    data: PropTypes.arrayOf({
        measures: PropTypes.arrayOf(PropTypes.instanceOf(DtoPluvioMeasure)),
        stat: PropTypes.instanceOf(DtoPluviometerStats),
    }),
    minDate: PropTypes.number,
    maxDate: PropTypes.number,
    pluvioGroupedMode: PropTypes.string,
}

const GraphEfficientRain = ({
    pluviometer,
    pluviometerStatistics,
}) => {
    const [dataLoaded, setDataLoaded] = useState(false)
    const [progress, setProgress] = useState(0)

    const [displayModes, setDisplayModes] = useState({
        auto: true,
        all: false,
        sumHour: false,
        sumDay: false,
        sumWeek: false,
        sumDecade: false,
        sumMonth: false,
        sumYear: false,
        personalizedGrouping: false,
    })

    // dates des bordures du graphique
    const [minDate, setMinDate] = useState(undefined)
    const [maxDate, setMaxDate] = useState(moment().valueOf())

    const dispatch = useDispatch()
    const getGroupFunc = label => {
        switch (label) {
            case 'auto': return AUTO
            case 'sumHour': return SUM_HOUR
            case 'sumDay': return SUM_DAY
            case 'sumWeek': return SUM_WEEK
            case 'sumDecade': return SUM_DECADE
            case 'sumMonth': return SUM_MONTH
            case 'sumYear': return SUM_YEAR
            case 'personalizedGrouping': return displayModes.personalizedGroupingValue
            case 'all': default: return label
        }
    }

    useEffect(()=> {
        const defaultGroupMode = (minDate && (minDate > moment(maxDate || moment().valueOf()).subtract('1', 'month').valueOf())) ? 'all' : 'MAX'
        const groupModeFunc = getGroupFunc(findKey(displayModes, mode => displayModes[mode]))

        setDataLoaded(false)
        const inputParams = {
            stationId: pluviometer.id,
            groupFunc: groupModeFunc === AUTO ? defaultGroupMode : groupModeFunc,
            chartMode: false,
            startDate: undefined,
            endDate: moment().valueOf(),
        }
        const rainInput = {
            dataType: 1,
            ...inputParams,
        }
        const ETPInput = {
            dataType: 3,
            ...inputParams,
        }
        const efficientInput = {
            dataType: -1,
            ...inputParams,
        }
        dispatch(PluviometryAction.loadPluvioChartChronicMeasures([rainInput, ETPInput, efficientInput], p => {
            setProgress(p)
        })).then(() => {
            setDataLoaded(true)
        })
    }, [dispatch, displayModes, pluviometer])

    const {
        pluvioMeasures,
    } = useSelector(store => ({
        pluvioMeasures: store.PluviometryReducer.pluvioMeasures,
    }), shallowEqual)


    const onChangeDates = (dates, forced) => {
        if (minDate !== dates.minDate || maxDate !== dates.maxDate || forced) {
            setMinDate(dates?.minDate)
            setMaxDate(dates?.maxDate)
        }
    }

    const endDate = maxDate ?? moment().valueOf()
    const measuresRain = pluvioMeasures[0]?.measures.filter(m => m.date >= minDate && m.date <= endDate)
    const measuresETP = pluvioMeasures[1]?.measures.filter(m => m.date >= minDate && m.date <= endDate)
    const measuresEfficientRain = pluvioMeasures[2]?.measures.filter(m => m.date >= minDate && m.date <= endDate)

    const data = [{
        measures: measuresRain,
        stat: pluviometerStatistics.find(s => s.typeId === 1),
    }, {
        measures: measuresETP,
        stat: pluviometerStatistics.find(s => s.typeId === 3),
    }, {
        measures: measuresEfficientRain,
        stat: pluviometerStatistics.find(s => s.typeId === -1),
    }]

    if (pluvioMeasures.length !== 3) {
        return <ProgressCard progress={progress}/>
    }
    const numberMeasures = pluvioMeasures[0].measures.length + pluvioMeasures[1].measures.length + pluvioMeasures[2].measures.length

    return (
        <div>
            { (dataLoaded && !(numberMeasures < 1)) && (
                <div style={{ paddingTop: 5, margin: '0 10' }}>
                    <PanelEfficientRainCalcul
                        allMeasures={{
                            rain: pluvioMeasures[0]?.measures,
                            ETP: pluvioMeasures[1]?.measures,
                            efficientRain: pluvioMeasures[2]?.measures,
                        }}
                    />
                </div>
            )}
            { !dataLoaded && (
                <div className='margin-1'>
                    <ProgressCard
                        progress={progress}
                        round
                    />
                </div>
            )}
            { (numberMeasures === 0) && (
                <div style={{ paddingTop: 10 }}>
                    <MessageCard>{i18n.noDataToDisplay}</MessageCard>
                </div>
            )}
            {(dataLoaded && !(numberMeasures < 1)) && (
                <Card className='card margin-1' noMargin={false} round>
                    <ChartTabsSuivi
                        onChangeDate={ (dates, forced) => onChangeDates(dates, forced) }
                    />
                    <Grid sx={{ top: '9px', left: '32rem', position: 'absolute', zIndex: 10 }}>
                        <SimpleChartTabsPluvio
                            displayModes={displayModes}
                            displayModesUniqChoise={true}
                            changeParent={v => setDisplayModes(v)}
                        />
                    </Grid>
                    <Grid sx={{ paddingTop: '3rem' }}>
                        <Graphic
                            pluviometerInfos={ {
                                code: pluviometer.code,
                                name: pluviometer.name,
                            } }
                            data={data}
                            minDate={minDate}
                            maxDate={maxDate}
                            pluvioGrouped={getGroupFunc(findKey(displayModes, mode => displayModes[mode]))}
                        />
                    </Grid>
                </Card>
            )}
        </div>
    )
}

GraphEfficientRain.propTypes = {
    pluviometer: PropTypes.instanceOf(PluviometerDto),
    pluviometerStatistics: PropTypes.arrayOf(PropTypes.instanceOf(DtoPluviometerStats)),
}

export default GraphEfficientRain