/* eslint-disable consistent-return */
/* eslint-disable max-nested-callbacks */
import { NITRATES_CODE } from 'catchment/constants/CatchmentConstant'
import { GREEN, RED } from 'components/constants/ColorConstant'
import Table from 'components/datatable/Table'
import QualityChart from 'components/echart/QualityChart'
import { flatten, groupBy, isUndefined, max, maxBy, minBy, orderBy, uniqBy } from 'lodash'
import PropTypes from 'prop-types'
import React, { useEffect, useMemo, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { nbPerPageLabel } from 'referencial/constants/ReferencialConstants'
import i18n from 'simple-react-i18n'
import { calculateAverage, calculateThresholdResult, searchMaxAnalysis, searchMinAnalysis, searchP90Analysis } from 'utils/AnalyseUtils'
import { getDate, getYear } from 'utils/DateUtil'
import { hasValue } from 'utils/NumberUtil'
import { getStationName } from 'catchment/utils/CatchmentUtil'
import TooltipAnalyse from 'quality/components/qualityComponents/TooltipAnalyse'
import { selectedColor } from 'utils/constants/ColorTheme'
import { formatHypeTrend, formatHypeTrendRupture } from 'utils/HypeUtils'
import IAEauAction from 'iaeau/IAEauAction'
import { HYPE_TRENDS_CONSTANTS } from 'quality/constants/QualityConstants'
import { WhiteCard } from 'components/styled/Card'
import ProgressCard from 'components/card/ProgressCard'
import useProgressDispatch from 'utils/customHook/useProgressDispatch'
import MessageCard from 'components/card/MessageCard'
import { exportFile } from 'utils/ExportDataUtil'
import AnalysisAction from 'quality/actions/AnalysisAction'
import DtoAnalysisLight from 'quality/dto/analyse/DtoAnalysisLight'

const getThresholdColor = (value, threshold) => {
    if (!hasValue(threshold) || !hasValue(value)) {
        return
    }
    return value >= threshold ? 'red' : 'green'
}

const FollowUpNitratesTable = ({
    nitratesAnalysis = [],
    threshold,
}) => {
    const {
        qualitometers,
        cities,
        settings,
    } = useSelector(store => ({
        qualitometers: store.QualityReducer.qualitometersLight,
        cities: store.CityReducer.cities,
        settings: store.AdministrationReducer.settings,
    }), shallowEqual)

    const nitratesAnalysisGroup = useMemo(() => groupBy(nitratesAnalysis, 'qualitometer'), [nitratesAnalysis])

    const getTooltip = analyse => ({
        setTooltip: () => (
            <TooltipAnalyse
                title={`${i18n.nitrates} [${NITRATES_CODE}]`}
                analyse={analyse}
                threshold={{
                    parameterCode: analyse.parameter,
                    threshold1: threshold,
                }}
                overrideColor={[GREEN, RED]}
            />
        ),
    })

    const analysisFormated = useMemo(() => {
        const formated = Object.keys(nitratesAnalysisGroup).map(key => {
            const qualitometer = qualitometers.find(q => q.id === parseInt(key))
            const city = qualitometer && cities.find(c => c.code === qualitometer.townCode)
            const stationName = getStationName(qualitometer, city)
            const yearGrounp = groupBy(nitratesAnalysisGroup[key], a => getYear(a.sampleDate))
            return Object.keys(yearGrounp).map(year => {
                const analysis = yearGrounp[year].map(a => ({ ...a, ...calculateThresholdResult(a, []) }))

                const p90 = searchP90Analysis(analysis)
                const minValue = searchMinAnalysis(analysis)
                const maxValue = searchMaxAnalysis(analysis)
                const average = calculateAverage(analysis)

                return {
                    catchmentPoint: { value: stationName },
                    year: { value: year },
                    percentile90: {
                        value: p90?.value,
                        positionCell: 'right',
                        classNameColor: getThresholdColor(p90?.result, threshold),
                        ...getTooltip(p90),
                    },
                    min: {
                        value: minValue?.value,
                        positionCell: 'right',
                        classNameColor: getThresholdColor(minValue?.result, threshold),
                        ...getTooltip(minValue),
                    },
                    average: {
                        value: average,
                        positionCell: 'right',
                        classNameColor: getThresholdColor(average, threshold),
                    },
                    max: {
                        value: maxValue?.value,
                        positionCell: 'right',
                        classNameColor: getThresholdColor(maxValue?.result, threshold),
                        ...getTooltip(maxValue),
                    },
                    nbQuantifications: { value: analysis.length, positionCell: 'right' },
                }
            })
        })
        return orderBy(flatten(formated), ['catchmentPoint.value', 'year.value'], ['asc', 'desc'])
    }, [cities, nitratesAnalysisGroup, qualitometers, threshold, settings])

    return (
        <Table
            showTitle={false}
            withCard={false}
            data={analysisFormated}
            color
            paging
            nbPerPageLabel={nbPerPageLabel}
            type={{ headers: ['catchmentPoint', 'year', 'percentile90', 'min', 'average', 'max', 'nbQuantifications'] }}
            condensed
            sortable
            id='catchment_analysis_table'
        />
    )
}

FollowUpNitratesTable.propTypes = {
    nitratesAnalysis: PropTypes.arrayOf(PropTypes.instanceOf(DtoAnalysisLight)),
    threshold: PropTypes.number,
}

const FollowUpNitratesChart = ({
    nitratesAnalysis = [],
    station,
    threshold,
    trendsMannKendall = [],
    trendsRupture = [],
    startPeriod,
    endPeriod,
}) => {
    const {
        qualitometers,
    } = useSelector(store => ({
        qualitometers: store.QualityReducer.qualitometersLight,
    }), shallowEqual)

    const qualitometerSelected = useMemo(() => qualitometers.find(q => q.id === station), [qualitometers, station])

    const trendMannKendall = useMemo(() => {
        return trendsMannKendall.find(t => t.stationCode === qualitometerSelected?.code)
    }, [qualitometerSelected, trendsMannKendall])

    const trendRupture = useMemo(() => {
        return trendsRupture.find(t => t.stationCode === qualitometerSelected?.code)
    }, [qualitometerSelected, trendsRupture])

    const analysisFiltered = useMemo(() => {
        if (hasValue(station)) {
            return nitratesAnalysis.filter(({ qualitometer }) => station === qualitometer)
        }
        return nitratesAnalysis
    }, [nitratesAnalysis, station])

    const nitratesAnalysisGroup = useMemo(() => groupBy(nitratesAnalysis, 'qualitometer'), [nitratesAnalysis])

    const analysisFormated = useMemo(() => Object.keys(nitratesAnalysisGroup).map(key => {
        const filteredNitratesAnalysis = nitratesAnalysisGroup[key].filter(a => !['0', '2', '3', '5', '7', '10'].includes(a.remark))
        const { qualitometer: qualitometerId } = nitratesAnalysisGroup[key][0]
        const qualitometer = qualitometers.find(q => q.id === qualitometerId)
        return {
            idStation: qualitometerId,
            name: getStationName(qualitometer),
            unit: 'mg/L',
            dataList: filteredNitratesAnalysis.map(analyse => ({ date: analyse.sampleDate, value: analyse.result })),
        }
    }), [nitratesAnalysisGroup, qualitometers])

    const analysisFormatedFiltered = useMemo(() => {
        if (hasValue(station)) {
            return analysisFormated.filter(({ idStation }) => station === idStation)
        }
        return analysisFormated
    }, [analysisFormated, station])

    const hypeFormated = useMemo(() => {
        if (!hasValue(station)) {
            return []
        }
        const minDate = minBy(analysisFiltered, 'sampleDate')?.sampleDate
        const maxDate = maxBy(analysisFiltered, 'sampleDate')?.sampleDate

        const mannKendallTrend = trendMannKendall ? [{
            name: i18n.mannKendallTrend,
            showSymbol: false,
            dataList: formatHypeTrend(trendMannKendall.coefficient, trendMannKendall.value, minDate, maxDate),
        }] : []

        if (trendRupture && (['preValue', 'preCoefficient', 'breakDate'].every(k => hasValue(trendRupture[k])) || ['postValue', 'postCoefficient', 'breakDate'].every(k => hasValue(trendRupture[k])))) {
            const maxValue = max([maxBy(analysisFiltered, 'result')?.result || 0, threshold])
            const ruptureLine = {
                name: i18n.trendRupture,
                dataList: [
                    { date: trendRupture.breakDate, value: 1.1 * maxValue },
                    { date: trendRupture.breakDate, value: 0 },
                ],
                lineStyle: { type: 'dashed' },
            }
            const rupture = {
                name: i18n.trendRupture,
                connectNulls: false,
                showSymbol: false,
                dataList: formatHypeTrendRupture(trendRupture, minDate, maxDate),
            }
            return [
                ...mannKendallTrend,
                ruptureLine,
                rupture,
            ]
        }

        return mannKendallTrend
    }, [analysisFiltered, station, threshold, trendMannKendall, trendRupture])

    return (
        <QualityChart
            exportName={i18n.nitratesFollowUp}
            grids={[{ height: 300 }]}
            xAxis={[{ min: startPeriod, max: endPeriod }]}
            yAxis={[{ name: `${i18n.nitratesContent} (mg/L)` }]}
            series={[...analysisFormatedFiltered, ...hypeFormated]}
            thresholds={[{ name: i18n.nitratesThreshold, dataList: [{ value: threshold }].filter(t => !isUndefined(t.value)) }]}

            headerHeight={0}

            withToolTypeLine
            withToolTypeBar
            withToolLog
            withToolThreshold
            withToolMarker
            withToolLine
        />
    )
}

FollowUpNitratesChart.propTypes = {
    startPeriod: PropTypes.number,
    endPeriod: PropTypes.number,
    nitratesAnalysis: PropTypes.arrayOf(PropTypes.instanceOf(DtoAnalysisLight)),
    threshold: PropTypes.number,
    station: PropTypes.number,
    trendsMannKendall: PropTypes.arrayOf(PropTypes.shape({})),
    trendsRupture: PropTypes.arrayOf(PropTypes.shape({})),
}

const HypeTable = ({
    nitratesAnalysis = [],
    qualitometersFiltered = [],
    station,
    setStation = () => { },
    trendsMannKendall = [],
    trendsRupture = [],
}) => {
    const {
        qualitometers,
    } = useSelector(store => ({
        qualitometers: store.QualityReducer.qualitometersLight,
    }), shallowEqual)

    const groupedAnalysis = useMemo(() => {
        const quantifiedAnalysis = nitratesAnalysis.filter(a => !['0', '2', '3', '5', '7', '10'].includes(a.remark))
        const groupByQualitometer = groupBy(quantifiedAnalysis, 'qualitometer')
        return Object.keys(groupByQualitometer).reduce((acc, id) => {
            const { [id]: analysis = [] } = groupByQualitometer
            acc[id] = analysis.map(a => ({
                result: a.result,
                sampleDate: a.sampleDate,
            }))
            return acc
        }, {})
    }, [nitratesAnalysis])

    const data = useMemo(() => {
        return qualitometersFiltered.map(point => {
            const qualitometer = qualitometers.find(q => q.id === point.id)
            const { [point.id]: analysis = [] } = groupedAnalysis
            const trend = trendsMannKendall.find(t => t.stationCode === qualitometer.code)
            const rupture = trendsRupture.find(t => t.stationCode === qualitometer.code)
            return {
                catchmentPoint: point.name,
                nbQuantifications: analysis.length,
                mannKendallTrend: (!hasValue(trend) || trend?.coefficient === 0) && i18n.noTrend || trend.coefficient > 0 && i18n.rise || i18n.decrease,
                ruptureDate: rupture?.breakDate ? getDate(rupture.breakDate) : i18n.noRupture,
                color: station === point.id ? selectedColor : undefined,
                id: point.id,
            }
        })
    }, [groupedAnalysis, qualitometers, qualitometersFiltered, station, trendsMannKendall, trendsRupture])

    return (
        <Table
            showTitle={false}
            withCard={false}
            data={data}
            type={{ headers: ['catchmentPoint', 'nbQuantifications', 'mannKendallTrend', 'ruptureDate'] }}
            condensed
            onClick={({ id }) => setStation(id === station ? undefined : id)}
            maxHeight={275}
            sortable
            coloredLine
        />
    )
}

HypeTable.propTypes = {
    nitratesAnalysis: PropTypes.arrayOf(PropTypes.instanceOf(DtoAnalysisLight)),
    qualitometersFiltered: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
    })),
    station: PropTypes.number,
    setStation: PropTypes.func,
    trendsMannKendall: PropTypes.arrayOf(PropTypes.shape({})),
    trendsRupture: PropTypes.arrayOf(PropTypes.shape({})),
}

const HypeCardNitrates = ({
    nitratesAnalysis = [],
    qualitometersFiltered = [],
    threshold,
    startPeriod,
    endPeriod,
}) => {
    const dispatch = useDispatch()

    const {
        qualitometers,
    } = useSelector(store => ({
        qualitometers: store.QualityReducer.qualitometersLight,
    }), shallowEqual)

    const [station, setStation] = useState()
    const [trendsMannKendall, setTrendsMannKendall] = useState([])
    const [trendsRupture, setTrendsRupture] = useState([])

    const groupedAnalysis = useMemo(() => {
        const quantifiedAnalysis = nitratesAnalysis.filter(a => !['0', '2', '3', '5', '7', '10'].includes(a.remark))
        return groupBy(quantifiedAnalysis, 'qualitometer')
    }, [nitratesAnalysis])

    useEffect(() => {
        if (!nitratesAnalysis.length) {
            return
        }
        qualitometersFiltered.forEach(({ id }) => {
            const qualitometer = qualitometers.find(q => q.id === id)
            if (!qualitometer) {
                return
            }
            const { [id]: analysis = [] } = groupedAnalysis

            const hypeData = analysis.map((a, index) => ({
                stationCode: qualitometer.code,
                stationTypeCode: qualitometer.stationType,
                parameterCode: parseInt(a.parameter) || 0,
                analysisNumber: index,
                measureDate: a.sampleDate,
                measure: a.result,
                unitCode: 0,
                remarkCode: 1,
            }))
            if (hypeData.length) {
                dispatch(IAEauAction.getHypeTrend(hypeData, qualitometer.code)).then(({ trends, breaks }) => {
                    const trendMannKendall = trends.filter(t => t.statisticsType === HYPE_TRENDS_CONSTANTS.MANN_KENDALL)
                    const trendRupture = breaks.filter(t => t.statisticsType === HYPE_TRENDS_CONSTANTS.TREND_RUPTURE)
                    setTrendsMannKendall(p => uniqBy([...trendMannKendall, ...p], 'stationCode'))
                    setTrendsRupture(p => uniqBy([...trendRupture, ...p], 'stationCode'))
                })
            }
        })
    }, [dispatch, groupedAnalysis, nitratesAnalysis.length, qualitometers, qualitometersFiltered])

    return (
        <>
            <FollowUpNitratesChart
                startPeriod={startPeriod}
                endPeriod={endPeriod}
                nitratesAnalysis={nitratesAnalysis}
                threshold={threshold}
                station={station}
                trendsMannKendall={trendsMannKendall}
                trendsRupture={trendsRupture}
            />
            <div style={{ margin: '0px 20vw 20px 20vw' }}>
                <HypeTable
                    nitratesAnalysis={nitratesAnalysis}
                    qualitometersFiltered={qualitometersFiltered}
                    station={station}
                    setStation={setStation}
                    trendsMannKendall={trendsMannKendall}
                    trendsRupture={trendsRupture}
                />
            </div>
        </>
    )
}

HypeCardNitrates.propTypes = {
    startPeriod: PropTypes.number,
    endPeriod: PropTypes.number,
    nitratesAnalysis: PropTypes.arrayOf(PropTypes.instanceOf(DtoAnalysisLight)),
    qualitometersFiltered: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
    })),
    threshold: PropTypes.number,
}

const CardFollowUpNitrates = ({
    stationIds,
    startPeriod,
    endPeriod,
    producerExcluded,
    nitratesThreshold,
}) => {
    const {
        qualitometers,
    } = useSelector(store => ({
        qualitometers: store.QualityReducer.qualitometersLight,
    }), shallowEqual)

    const [analysis, setAnalysis] = useState([])

    const qualitometersFiltered = useMemo(() => {
        const points = stationIds?.map(id => {
            return qualitometers.find(q => q.id === id)
        }).filter(q => !!q) || []
        return points.map(({ code, name = '', id }) => ({
            name: `${name} [${code}]`,
            id,
        }))
    }, [qualitometers, stationIds])

    const { isLoaded } = useProgressDispatch(cancelRef => [
        AnalysisAction.getAnalysis({
            lightMode: true,
            stations: stationIds,
            startDate: startPeriod,
            endDate: endPeriod,
            excludedProducers: producerExcluded,
            parameters: [NITRATES_CODE],
            support: '3', // support 'eau'
        }).then(json => !cancelRef.current && setAnalysis(json?.map(j => new DtoAnalysisLight(j)))),
    ])

    const nitratesAnalysis = useMemo(() => {
        return analysis.filter(({ sampleDate, result }) => hasValue(sampleDate) && hasValue(result))
    }, [analysis])

    const actions = [{
        iconName: 'file_download',
        tooltip: i18n.export,
        color: 'black',
        onClick: () => {
            const nitratesAnalysisGroup = groupBy(nitratesAnalysis, 'qualitometer')
            const dataFormated = Object.keys(nitratesAnalysisGroup).map(key => {
                const qualitometer = qualitometers.find(q => q.id === parseInt(key))
                const yearGrounp = groupBy(nitratesAnalysisGroup[key], a => getYear(a.sampleDate))
                return Object.keys(yearGrounp).map(year => {
                    const analysisFormated = yearGrounp[year].map(a => ({ ...a, ...calculateThresholdResult(a, []) }))

                    const p90 = searchP90Analysis(analysisFormated)
                    const min = searchMinAnalysis(analysisFormated)
                    const maxValue = searchMaxAnalysis(analysisFormated)
                    const average = calculateAverage(analysisFormated)

                    return {
                        code: { value: qualitometer?.code, cellType: 'string' },
                        name: qualitometer?.name,
                        year: { value: year, format: 'yyyy', cellType: 'date' },
                        percentile90: { value: p90?.value, cellType: 'right', color: getThresholdColor(p90?.result, nitratesThreshold) },
                        min: { value: min?.value, cellType: 'right', color: getThresholdColor(min?.result, nitratesThreshold) },
                        average: { value: average, cellType: 'number', color: getThresholdColor(average, nitratesThreshold) },
                        max: { value: maxValue?.value, cellType: 'right', color: getThresholdColor(maxValue?.result, nitratesThreshold) },
                        nbQuantifications: { value: analysisFormated.length, format: '0', cellType: 'number' },
                    }
                })
            })
            const dataOrdered = orderBy(flatten(dataFormated), ['code.value', 'year.value'], ['asc', 'desc'])
            const data = dataOrdered.length ? [{ ...dataOrdered[0], headers: Object.keys(dataOrdered[0]) }, ...dataOrdered.slice(1)] : []

            exportFile({
                data,
                exportType: 'xlsx',
                titleFile: i18n.nitratesFollowUp,
            })
        },
    }]

    return (
        <WhiteCard title={i18n.nitratesFollowUp} actions={actions} data-cy='follow_up_nitrates'>
            {!isLoaded && (
                <ProgressCard indeterminate />
            )}
            {isLoaded && (
                <>
                    {nitratesAnalysis.length ? (
                        <div style={{ padding: '0 5 10 5' }}>
                            <HypeCardNitrates
                                startPeriod={startPeriod}
                                endPeriod={endPeriod}
                                nitratesAnalysis={nitratesAnalysis}
                                threshold={nitratesThreshold}
                                qualitometersFiltered={qualitometersFiltered}
                            />
                            <div style={{ margin: '0px 20vw 0px 20vw' }}>
                                <FollowUpNitratesTable nitratesAnalysis={nitratesAnalysis} threshold={nitratesThreshold} />
                            </div>
                        </div>
                    ) : (
                        <MessageCard>{i18n.noDataToDisplay}</MessageCard>
                    )}
                </>
            )}
        </WhiteCard>
    )
}

CardFollowUpNitrates.propTypes = {
    stationIds: PropTypes.arrayOf(PropTypes.number),
    startPeriod: PropTypes.number,
    endPeriod: PropTypes.number,
    producerExcluded: PropTypes.arrayOf(PropTypes.number),
    nitratesThreshold: PropTypes.string,
}

export {
    FollowUpNitratesTable,
    FollowUpNitratesChart,
    HypeTable,
    HypeCardNitrates,
    CardFollowUpNitrates,
}