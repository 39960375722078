/* eslint-disable max-nested-callbacks */
import ReactECharts from 'echarts-for-react'
import echarts from 'echarts/lib/echarts'
import MessageCard from 'components/card/MessageCard'
import ProgressCard from 'components/card/ProgressCard'
import { WhiteCard } from 'components/styled/Card'
import moment from 'moment'
import PluviometryAction from 'pluviometry/actions/PluviometryAction'
import PluviometerDto from 'pluviometry/dto/PluviometerDto'
import PropTypes from 'prop-types'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import useProgressDispatch from 'utils/customHook/useProgressDispatch'
import { max, maxBy, min, minBy, orderBy, round, uniqBy } from 'lodash'
import { exportExcelIcon, exportPictureIcon, getAxisLabelInterval } from 'components/echart/EChartUtils'
import { getBeginingOfTheYear, getDate, getEndOfTheYear } from 'utils/DateUtil'
import DtoPluvioMeasures from 'pluviometry/dto/measures/DtoPluvioMeasures'
import { exportFile } from 'utils/ExportDataUtil'
import Select from 'components/forms/Select'
import { hasValue } from 'utils/NumberUtil'
import { PluvioCumulOptionIcon } from 'pluviometry/components/pluviometryComponents/PluvioOption'
import { intParser } from 'utils/customHook/useApplicationSetting'
import { Grid } from '@mui/material'
import useStartPeriod from 'catchment/utils/useStartPeriod'
import useEndPeriod from 'catchment/utils/useEndPeriod'

const Graph = ({
    dataMeasures = [],
}) => {
    const {
        pluviometers,
    } = useSelector(store => ({
        pluviometers: store.PluviometryReducer.pluviometers,
    }), shallowEqual)

    const filteredDataMeasures = dataMeasures.filter(d => !!d)

    const echartRef = useRef()
    const displayToolbox = useRef(false)

    const defaultTimestamp = moment().valueOf()
    const chartMaxDate = moment(max(filteredDataMeasures.map(data => maxBy(data.measures, 'date')?.date)) || defaultTimestamp)
    const chartMinDate = moment(min(filteredDataMeasures.map(data => minBy(data.measures, 'date')?.date)) || defaultTimestamp)

    const {
        formatter,
        interval,
    } = getAxisLabelInterval(chartMaxDate, chartMinDate)

    const series = filteredDataMeasures.map(data => {
        const dataOrdered = orderBy(data.measures, 'date').map(({ date, value }) => ({
            value: [date, value],
        }))
        const pluvio = pluviometers.find(({ id }) => id === data.stationId)
        return {
            type: 'bar',
            data: dataOrdered,
            color: '#2141a3',
            name: pluvio?.name || data.stationId,
            barMaxWidth: '50px',
        }
    })

    const getLegend = () => ({
        top: '5%',
        left: '2%',
        right: displayToolbox.current ? '95px' : '2%', // 30 * nbTool + 35
        type: 'scroll',
    })

    const getToolbox = () => ({
        top: '4%',
        right: '35px',
        showTitle: false,
        itemSize: 18,
        tooltip: {
            show: true,
            position: 'bottom',
        },
        feature: {
            saveAsImage: {
                show: displayToolbox.current,
                title: i18n.pictureExport,
                icon: exportPictureIcon,
                name: i18n.dataPluvio,
            },
            myToolExport: {
                show: displayToolbox.current,
                title: i18n.excelExport,
                icon: exportExcelIcon,
                onclick: () => {
                    const exportData = filteredDataMeasures.flatMap(({ measures, stationId }) => {
                        const pluvio = pluviometers.find(({ id }) => id === stationId)
                        return measures.map(m => ({
                            date: { value: getDate(m.date), format: 'dd/MM/yyyy', cellType: 'date' },
                            value: { value: m.value, format: '0.00000', cellType: 'number' },
                            name: pluvio?.name || stationId,
                        }))
                    })
                    exportFile({
                        data: exportData.length ? [
                            {
                                ...exportData[0],
                                headers: ['name', 'date', 'value'],
                            },
                            ...exportData.slice(1),
                        ] : [],
                        exportType: 'xlsx',
                        titleFile: i18n.dataPluvio,
                    }, true)
                },
            },
        },
    })

    const options = {
        series,
        legend: getLegend(),
        xAxis: [{
            type: 'time',
            boundaryGap: true,
            showSplitLine: true,
            axisLabel: {
                formatter,
                rotate: 50,
            },
            interval,
            min: chartMinDate.valueOf(),
            max: chartMaxDate.valueOf(),
        }],
        yAxis: {
            type: 'value',
            nameLocation: 'center',
            nameGap: 40,
            name: `${i18n.rain} (mm)`,
        },
        tooltip: {
            show: true,
            trigger: 'axis',
            axisPointer: {
                type: 'shadow',
                snap: true,
            },
            formatter: params => {
                return getDate(params[0].axisValue) + params.map(({ marker, seriesName, value: [, result], data: { unit = '' } }) => `<br/>${marker} ${seriesName}: ${result && round(result, 3)} ${unit}`).join('')
            },
        },
        grid: {
            top: '14%',
            bottom: '80px',
            left: '30px',
            right: '20px',
            containLabel: true,
            height: 300,
        },
        toolbox: getToolbox(),
    }
    return (
        <div
            onMouseOver={() => {
                displayToolbox.current = true
                echartRef.current.getEchartsInstance().setOption({
                    legend: getLegend(),
                    toolbox: getToolbox(),
                })
            }}
            onMouseOut={() => {
                displayToolbox.current = false
                echartRef.current.getEchartsInstance().setOption({
                    legend: getLegend(),
                    toolbox: getToolbox(),
                })
            }}
        >
            <ReactECharts
                echarts={echarts}
                option={options}
                notMerge={true}
                lazyUpdate={true}
                className={'row no-margin'}
                style={{ height: 400 }}
                ref={e => {
                    echartRef.current = e
                }}
            />
        </div>
    )
}

Graph.propTypes = {
    dataMeasures: PropTypes.arrayOf(PropTypes.instanceOf(DtoPluvioMeasures)),
}

const CatchmentFollowUpPluvio = ({
    pluviometersLinked = [],
}) => {
    const dispatch = useDispatch()

    const {
        catchment,
        pluviometers,
    } = useSelector(store => ({
        catchment: store.CatchmentReducer.catchment,
        pluviometers: store.PluviometryReducer.pluviometers,
    }), shallowEqual)

    const startPeriod = useStartPeriod(intParser)
    const endPeriod = useEndPeriod(intParser)

    const [dataMeasures, setDataMeasures] = useState([])
    const [group, setGroup] = useState('SUM_AUTO')

    const getDefaultSelectPointId = () => {
        const mainPointFound = catchment.catchmentPoints?.find(({ mainPoint }) => mainPoint)
        const code = mainPointFound?.codeWithoutDesignation
        const idMainPoint = mainPointFound && pluviometers.find(q => q.code === code)?.id
        if (hasValue(idMainPoint) && dataMeasures.find(data => data.stationId === idMainPoint)) {
            return idMainPoint
        }
        return dataMeasures.length ? parseInt(dataMeasures[0]?.stationId) : undefined
    }

    const [station, setStation] = useState(getDefaultSelectPointId)

    useEffect(() => {
        setStation(getDefaultSelectPointId)
    }, [catchment, pluviometers, dataMeasures])

    const {
        isLoaded,
        progress,
    } = useProgressDispatch((cancelRef) => {
        setDataMeasures([])
        const defaultInput = {
            dataType: 1,
            groupFunc: group,
            chartMode: true,
            startDate: getBeginingOfTheYear(startPeriod),
            endDate: getEndOfTheYear(endPeriod),
        }
        return pluviometersLinked.map(({ id }) => {
            return dispatch(PluviometryAction.getPluvioChronicMeasures({ ...defaultInput, stationId: id }))
                .then(data => !cancelRef.current && setDataMeasures(prev => uniqBy([...prev, data], 'stationId')))
        })
    }, [group])

    const dataMeasuresFiltered = useMemo(() => {
        if (hasValue(station)) {
            return dataMeasures.filter(({ stationId }) => stationId === station)
        }
        return dataMeasures
    }, [dataMeasures, station])

    const actions = [
        {
            iconName: 'file_download',
            tooltip: i18n.export,
            color: 'black',
            onClick: () => {
                const exportData = dataMeasures.flatMap(({ measures, stationId }) => {
                    const pluvio = pluviometers.find(({ id }) => id === stationId)
                    return measures.map(m => ({
                        date: { value: getDate(m.date), format: 'dd/MM/yyyy', cellType: 'date' },
                        value: { value: m.value, format: '0.00000', cellType: 'number' },
                        name: pluvio?.name || stationId,
                        dataType: i18n.rain,
                    }))
                })
                exportFile({
                    data: exportData.length ? [
                        {
                            ...exportData[0],
                            headers: ['name', 'dataType', 'date', 'value'],
                        },
                        ...exportData.slice(1),
                    ] : [],
                    exportType: 'xlsx',
                    titleFile: i18n.dataPluvio,
                }, true)
            },
        },
    ]

    const actionsComponents = [
        <PluvioCumulOptionIcon
            style={{ color: 'black' }}
            defaultGroupType={group}
            onChangeGroupType={setGroup}
            disabled={!isLoaded}
        />,
    ]

    return (
        <WhiteCard title={i18n.pluviometry} actions={actions} actionsComponents={actionsComponents} data-cy='follow_up_pluvio'>
            {
                !isLoaded && (
                    <ProgressCard indeterminate={pluviometersLinked.length === 1} progress={progress} />
                )
            }
            {
                isLoaded && (
                    <>
                        {
                            dataMeasures.length ? (
                                <Grid container columnSpacing={2} alignItems='center' sx={{ padding: '8 5 0 20' }}>
                                    <Grid item xs={4}>
                                        <Select
                                            label={i18n.monitoringPoints}
                                            value={station}
                                            options={pluviometersLinked}
                                            onChange={setStation}
                                            keyValue={'id'}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Graph dataMeasures={dataMeasuresFiltered} />
                                    </Grid>
                                </Grid>
                            ) : (
                                <MessageCard>{i18n.noDataToDisplay}</MessageCard>
                            )
                        }
                    </>
                )
            }
        </WhiteCard>
    )
}

CatchmentFollowUpPluvio.propTypes = {
    pluviometersLinked: PropTypes.arrayOf(PropTypes.instanceOf(PluviometerDto)).isRequired,
}

export default CatchmentFollowUpPluvio