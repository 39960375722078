module.exports = {
    RECEIVE_SURVEYS: 'RECEIVE_SURVEYS',
    RECEIVE_SURVEY: 'RECEIVE_SURVEY',
    RECEIVE_EXPLOITATIONS: 'RECEIVE_EXPLOITATIONS',
    RECEIVE_EXPLOITATIONS_LIGHT: 'RECEIVE_EXPLOITATIONS_LIGHT',
    RECEIVE_EXPLOITATIONS_EXPORT_FULL_DATA: 'RECEIVE_EXPLOITATIONS_EXPORT_FULL_DATA',
    RECEIVE_EXPLOITATION: 'RECEIVE_EXPLOITATION',
    RECEIVE_EXPLOITATION_VOLUMES: 'RECEIVE_EXPLOITATION_VOLUMES',
    RECEIVE_SURVEYS_WITH_STATS: 'RECEIVE_SURVEYS_WITH_STATS',
    UPDATE_SURVEY: 'UPDATE_SURVEY',
    EDITION_DECLARATION: 'EDITION_DECLARATION',
    EDITION_NOTIFICATION: 'EDITION_NOTIFICATION',
    EXPORT_OASIS: 'EXPORT_OASIS',
    EXPORT_EDITION_JSON: 'EXPORT_EDITION_JSON',
    EXPORT_EDITION_FULL_JSON: 'EXPORT_EDITION_FULL_JSON',
    RECEIVE_EXPORT_AGENCY_SURVEY: 'RECEIVE_EXPORT_AGENCY_SURVEY',
    RECEIVE_SURVEY_DATA_EXPORT_FULL: 'RECEIVE_SURVEY_DATA_EXPORT_FULL',
    RECEIVE_DECLARATION: 'RECEIVE_DECLARATION',
    RECEIVE_DECLARATION_CONTRIBUTORS: 'RECEIVE_DECLARATION_CONTRIBUTORS',
    RECEIVE_LAST_DECLARATION_STATS: 'RECEIVE_LAST_DECLARATION_STATS',
    RECEIVE_DECLARATIONS_STATS_EXPLOITATION: 'RECEIVE_DECLARATIONS_STATS_EXPLOITATION',
    CHANGE_EXPLOITATION_ADMINISTRATOR: 'CHANGE_EXPLOITATION_ADMINISTRATOR',
    RECEIVE_SURVEYS_VOLUMES: 'RECEIVE_SURVEYS_VOLUMES',
    RECEIVE_BREAKDOWNS_SURVEY: 'RECEIVE_BREAKDOWNS_SURVEY',
    RECEIVE_FORECASTS_SURVEY: 'RECEIVE_FORECASTS_SURVEY',
    RECEIVE_ACTUALUSES_SURVEY: 'RECEIVE_ACTUALUSES_SURVEY',
    RECEIVE_CONSUMPTIONS_SURVEY: 'RECEIVE_CONSUMPTIONS_SURVEY',
    VALIDATE_INSTALLATIONS_SURVEY: 'VALIDATE_INSTALLATIONS_SURVEY',
    RECEIVE_MODES_IRRIGATIONS: 'RECEIVE_MODES_IRRIGATIONS',
    RECEIVE_ALL_EXPLOITATIONS_EVENTS: 'RECEIVE_ALL_EXPLOITATIONS_EVENTS',
    RECEIVE_EXPLOITATION_EVENTS: 'RECEIVE_EXPLOITATION_EVENTS',
    CREATE_EXPLOITATIONS_EVENTS: 'CREATE_EXPLOITATIONS_EVENTS',
    RECEIVE_ALL_SURVEYS_EVENTS: 'RECEIVE_ALL_SURVEYS_EVENTS',
    RECEIVE_SURVEY_EVENTS: 'RECEIVE_SURVEY_EVENTS',
    CREATE_SURVEYS_EVENTS: 'CREATE_SURVEYS_EVENTS',
    RECEIVE_SERVICES: 'RECEIVE_SERVICES',
    DELETE_SERVICE: 'DELETE_SERVICE',
    UPDATE_SERVICE: 'UPDATE_SERVICE',
    RECEIVE_TANKS_TYPES: 'RECEIVE_TANKS_TYPES',
    RECEIVE_TANKS_TYPE: 'RECEIVE_TANKS_TYPE',
    DELETE_TANKS_TYPE: 'DELETE_TANKS_TYPE',
    UPDATE_TANKS_TYPE: 'UPDATE_TANKS_TYPE',
    RESET_TANKS_TYPES: 'RESET_TANKS_TYPES',
    RECEIVE_FORECASTS_UGE: 'RECEIVE_FORECASTS_UGE',
    LOCAL_EXPLOITATIONS_FILTERS: 'LOCAL_EXPLOITATIONS_FILTERS',
    LOCAL_DECLARATIONS_FILTERS: 'LOCAL_DECLARATIONS_FILTERS',
    LOCAL_TERRITORY_FILTERS: 'LOCAL_TERRITORY_FILTERS',
    RECEIVE_RESTRICTIONS: 'RECEIVE_RESTRICTIONS',
    RECEIVE_SLOTS: 'RECEIVE_SLOTS',
    UPDATE_RESTRICTIONS: 'UPDATE_RESTRICTIONS',
    RECEIVE_WATER_TURNS: 'RECEIVE_WATER_TURNS',
    UPDATE_WATER_TURNS: 'UPDATE_WATER_TURNS',
    DELETE_WATER_TURN: 'DELETE_WATER_TURN',
    ADD_WATER_TURNS: 'ADD_WATER_TURNS,',
    RECEIVE_DECLARATIONS_WARNINGS: 'RECEIVE_DECLARATIONS_WARNINGS',
    RECEIVE_SERVICES_SITUATIONS: 'RECEIVE_SERVICES_SITUATIONS',
    RECEIVE_SUMMARY_SURVEYS: 'RECEIVE_SUMMARY_SURVEYS',
    volumesToShow: [
        { value: 'asked', name: 'Demandé', i18n: 'requestedVolumes' },
        { value: 'attributed', name: 'Attribué', i18n: 'allocatedVolumes' },
        { value: 'authorized', name: 'Autorisé', i18n: 'authorizedVolumes' },
        { value: 'consumed', name: 'Consommé (réel)', i18n: 'consumedVolumes' },
        { value: 'theoretical', name: 'Consommation théorique', i18n: 'theoreticalConsumption' },
        { value: 'scenario', name: 'Scénario actif', i18n: 'scenarioVolumes' },
        { value: 'irrigEfficiency', name: 'Efficience de l\'irrigation', i18n: 'irrigEfficiency' },
    ],
    CHRONICLES_CONSTANTS: {
        TYPE_ESTIM: 1,
        TYPE_INDEX: 2,
        TYPE_EVENT: 3,
    },
    POINT_STATUS_DECLARATION: {
        USED: 1,
        REMOVED: 2,
        ADJOURNED: 3,
    },
    SAMPLE_TYPES: {
        UNDERGROUND: 1,
        SUPERFICIAL: 2,
    },
    OPERATOR_TYPES: {
        ADD: 1,
        SUBTRACT: 2,
    },
    UNIT_TYPES: {
        PERCENT: 1,
        VOLUME: 2,
    },
    SURVEY_TYPES: {
        ANNUAL: 1,
        INTERMEDIATE: 2,
    },
    PERIODES: {
        ALL: 1,
        LOW_WATER: 2,
        NOT_LOW_WATER: 3,
    },
    LEVELS: {
        others: -1,
        incomplete: 1,
        conform: 2,
        closeOverrun: 3,
        overrun: 4,
    },
    colorLowWater: '#3AC537',
    colorNotLowWater: '#237522',
    emptyFilterExploitations: {
        searchValue: '',
        structureTypes: [],
        city: undefined,
        managementUnit: undefined,
        subManagementUnit: undefined,
        watershed: undefined,
        filters: undefined,
        domestic: false,
        agri: false,
        nonAgri: false,
        adjourned: false,
        shut: false,
        sampleType: undefined,
        sampleNature: undefined,
        accompanyingAquiferCode: undefined,
        droughtSector: undefined,
        organism: undefined,
    },
}
