import React, { useEffect, useMemo, useState } from 'react'
import i18n from 'simple-react-i18n'
import PerimetersThunk from '../../../thunk/PerimetersThunk'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { Grid, Button, DialogActions } from '@mui/material'
import CityAction from '../../../../referencial/components/city/actions/CityAction'
import { DialogContentMUI, DialogMUI, DialogTitleMUI } from '../../../../components/styled/Dialog'
import PropTypes from 'prop-types'
import Icon from '../../../../components/icon/Icon'
import SimpleDatePicker from '../../../../components/forms/SimpleDatePicker'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import BusinessIcon from '@mui/icons-material/Business'
import { StyledFieldSet } from '../../../../components/StyledElements'
import Select from '../../../../components/forms/Select'
import Table from '../../../../components/datatable/Table'
import { nbPerPageLabelTiny } from '../../../../referencial/constants/ReferencialConstants'
import Input from '../../../../components/forms/Input'
import ConfirmModal from '../../../../components/modal/ConfirmModal'
import { WhiteCard } from '../../../../components/styled/Card'
import ReferencialAction from '../../../../referencial/action/ReferencialAction'
import { exportFile } from '../../../../utils/ExportDataUtil'
import { compact, orderBy } from 'lodash'
import { MAIN_RADIUS } from 'utils/constants/Theme'
import useSandreList from 'utils/customHook/useSandreList'

const OWNERS_HEADER = ['type', 'name', 'address']

const PHYSICAL_CODE = 'PHY-'
const MORAL_CODE = 'MOR-'

const ContactForm = ({
    addNewOwnerContact = false,

    contact = {},
    setContact = () => {},
}) => {
    const {
        cities,
    } = useSelector(store => ({
        cities: store.CityReducer.cities,
    }), shallowEqual)

    return (
        <StyledFieldSet>
            <Grid container sx={{ padding: '1rem 1.5rem' }} alignItems='center' columnSpacing={1}>
                <Grid item>
                    <AccountCircleIcon fontSize={'large'}/>
                </Grid>
                <Grid item sx={{ fontSize: '18px' }}>
                    {addNewOwnerContact ? i18n.newPhysicalOwner : i18n.physicalOwner}
                </Grid>
            </Grid>
            <Grid container rowSpacing={1} sx={{ padding: '0 1.5rem 1rem' }}>
                <Grid container item xs={12} columnSpacing={2}>
                    <Grid item xs={4}>
                        <Input
                            title={i18n.name}
                            value={contact.name}
                            onChange={name => setContact(prev => ({ ...prev, name }))}
                            clearFunction
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <Input
                            title={i18n.firstname}
                            value={contact.firstname}
                            onChange={firstname => setContact(prev => ({ ...prev, firstname }))}
                            clearFunction
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <Input
                            title={i18n.deedOwnership}
                            // value={''}
                            // onChange={(v) => setTmpSearch(v)} A voir avec Killian plus tard
                            clearFunction
                        />
                    </Grid>
                </Grid>
                <Grid container item xs={12} columnSpacing={2}>
                    <Grid item xs={4}>
                        <SimpleDatePicker
                            label={i18n.birthdate}
                            value={contact.birthdate}
                            onChange={birthdate => setContact(prev => ({ ...prev, birthdate }))}
                        />
                    </Grid>
                    <Grid item xs={8}>
                        <Input
                            title={i18n.placeOfBirth}
                            value={contact.placeOfBirth}
                            onChange={placeOfBirth => setContact(prev => ({ ...prev, placeOfBirth }))}
                            clearFunction
                        />
                    </Grid>
                </Grid>
                <Grid container item xs={12} columnSpacing={2}>
                    <Grid item xs={6}>
                        <Input
                            title={i18n.address}
                            value={contact.address}
                            onChange={address => setContact(prev => ({ ...prev, address }))}
                            clearFunction
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Input
                            title={i18n.addressComplement}
                            value={contact.additionalAddress}
                            onChange={additionalAddress => setContact(prev => ({ ...prev, additionalAddress }))}
                            clearFunction
                        />
                    </Grid>
                </Grid>
                <Grid container item xs={12} columnSpacing={2}>
                    <Grid item xs={4}>
                        <Input
                            title={i18n.postalCode}
                            value={contact.postalBox}
                            onChange={postalBox => setContact(prev => ({ ...prev, postalBox }))}
                            clearFunction
                        />
                    </Grid>
                    <Grid item xs={8}>
                        <Select
                            options={cities}
                            label={i18n.cities}
                            nullLabel='&nbsp;'
                            value={contact.cityCode}
                            noSort
                            onChange={cityCode => setContact(prev => ({ ...prev, cityCode }))}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </StyledFieldSet>
    )
}

ContactForm.propTypes = {
    addNewOwnerContact: PropTypes.bool,
    contact: PropTypes.shape({}),
    setContact: PropTypes.func,
}

const ContributorForm = ({
    addNewOwnerContributor = false,
    contributor = {},
    setContributor = () => {},
}) => {
    const {
        cities,
    } = useSelector(store => ({
        cities: store.CityReducer.cities,
    }), shallowEqual)

    return (
        <StyledFieldSet>
            <Grid container sx={{ padding: '1rem 1.5rem' }} alignItems='center' columnSpacing={1}>
                <Grid item>
                    <BusinessIcon fontSize={'large'}/>
                </Grid>
                <Grid item sx={{ fontSize: '18px' }}>
                    {addNewOwnerContributor ? i18n.newMoralOwner : i18n.moralOwner}
                </Grid>
            </Grid>
            <Grid container rowSpacing={1} sx={{ padding: '0 1.5rem 1rem' }}>
                <Grid container item xs={12} columnSpacing={2}>
                    <Grid item xs={4}>
                        <Input
                            title={i18n.name}
                            value={contributor.name}
                            onChange={name => setContributor(prev => ({ ...prev, name }))}
                            clearFunction
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <Input
                            title={i18n.siret}
                            value={contributor.siret}
                            onChange={siret => setContributor(prev => ({ ...prev, siret }))}
                            clearFunction
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <SimpleDatePicker
                            label={i18n.creationDate}
                            value={contributor.creationDate}
                            onChange={creationDate => setContributor(prev => ({ ...prev, creationDate }))}
                        />
                    </Grid>
                </Grid>
                <Grid container item xs={12} columnSpacing={2}>
                    <Grid item xs={6}>
                        <Input
                            title={i18n.address}
                            value={contributor.address}
                            onChange={address => setContributor(prev => ({ ...prev, address }))}
                            clearFunction
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Input
                            title={i18n.addressComplement}
                            value={contributor.additionalAddress}
                            onChange={additionalAddress => setContributor(prev => ({ ...prev, additionalAddress }))}
                            clearFunction
                        />
                    </Grid>
                </Grid>
                <Grid container item xs={12} columnSpacing={2}>
                    <Grid item xs={4}>
                        <Input
                            title={i18n.postalCode}
                            value={contributor.postalCode}
                            onChange={postalCode => setContributor(prev => ({ ...prev, postalCode }))}
                            clearFunction
                        />
                    </Grid>
                    <Grid item xs={8}>
                        <Select
                            options={cities}
                            label={i18n.cities}
                            nullLabel='&nbsp;'
                            value={contributor.cityCode}
                            noSort
                            onChange={cityCode => setContributor(prev => ({ ...prev, cityCode }))}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </StyledFieldSet>
    )
}

ContributorForm.propTypes = {
    addNewOwnerContributor: PropTypes.bool,
    contributor: PropTypes.shape({}),
    setContributor: PropTypes.func,
}

const DialogAddOwner = ({
    open = false,
    onClose = () => {},
}) => {
    const {
        owners,
    } = useSelector(store => ({
        owners: store.PerimetersReducer.owners,
    }), shallowEqual)

    const [addNewOwnerContact, setAddNewOwnerContact] = useState(false)
    const [addNewOwnerContributor, setAddNewOwnerContributor] = useState(false)

    const [tmpSearchedOwner, setTmpSearchedOwner] = useState()
    const [searchedOwner, setSearchedOwner] = useState()

    const [contact, setContact] = useState()
    const [contributor, setContributor] = useState()

    const resetSearchedOwner = () => {
        setTmpSearchedOwner(undefined)
        setSearchedOwner(undefined)
    }

    useEffect(() => {
        return () => {
            resetSearchedOwner()

            setAddNewOwnerContact(false)
            setAddNewOwnerContributor(false)
        }
    }, [])

    useEffect(() => {
        if (searchedOwner) {
            setAddNewOwnerContact(false)
            setAddNewOwnerContributor(false)

            if (searchedOwner.includes(PHYSICAL_CODE)) {
                const findedContact = (owners.contacts || []).find(c => c.id === parseInt(searchedOwner.replace(PHYSICAL_CODE, '')))

                if (findedContact) {
                    setContact(findedContact)
                }
            } else {
                const findedContributor = (owners.contributors || []).find(i => i.id === parseInt(searchedOwner.replace(MORAL_CODE, '')))

                if (findedContributor) {
                    setContributor(findedContributor)
                }
            }
        }
    }, [searchedOwner])

    useEffect(() => {
        if (addNewOwnerContact) {
            resetSearchedOwner()

            setAddNewOwnerContributor(false)
            setContact(undefined)
        }
    }, [addNewOwnerContact])

    useEffect(() => {
        if (addNewOwnerContributor) {
            resetSearchedOwner()

            setAddNewOwnerContact(false)
            setContributor(undefined)
        }
    }, [addNewOwnerContributor])

    const formattedOwners = useMemo(() => {
        const contacts = owners?.contacts?.map(c => ({
            ...c,
            id: `${PHYSICAL_CODE}${c.id}`,
            name: `[${i18n.physical}] - ${c.name}`,
        })) ?? []
        const contributors = owners?.contributors?.map(c => ({
            ...c,
            id: `${MORAL_CODE}${c.id}`,
            name: `[${i18n.moral}] - ${c.name}`,

        })) ?? []
        return [...contacts, ...contributors]
    }, [owners])


    return (
        <DialogMUI
            fullWidth
            maxWidth='lg'
            open={open}
        >
            <DialogTitleMUI>
                <Grid container justifyContent='space-between' alignItems='center' style={{ padding: '0 20' }}>
                    <Grid item>
                        {i18n.addAnOwner}
                    </Grid>
                    <Grid item>
                        <Icon style={{ color: 'white' }} size='small' icon='close' onClick={onClose} />
                    </Grid>
                </Grid>
            </DialogTitleMUI>
            <DialogContentMUI>
                <StyledFieldSet style={{ padding: '1.5rem' }}>
                    <Grid container rowSpacing={1}>
                        <Grid container item xs={12} columnSpacing={2} alignItems='baseline'>
                            <Grid item xs={6}>
                                <Select
                                    options={formattedOwners}
                                    label={i18n.findAnOwner}
                                    nullLabel='&nbsp;'
                                    value={tmpSearchedOwner}
                                    onChange={setTmpSearchedOwner}
                                    noSort
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Button
                                    onClick={() => setSearchedOwner(tmpSearchedOwner)}
                                    disabled={!tmpSearchedOwner}
                                    variant='contained'
                                    color='primary'
                                >
                                    {i18n.select}
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid container item xs={12} columnSpacing={2}>
                            <Grid item>
                                <Button
                                    variant='contained'
                                    color='primary'
                                    onClick={() => setAddNewOwnerContact(true)}
                                >
                                    {i18n.newPhysicalOwner}
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    variant='contained'
                                    color='primary'
                                    onClick={() => setAddNewOwnerContributor(true)}
                                >
                                    {i18n.newMoralOwner}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </StyledFieldSet>
                {(searchedOwner || addNewOwnerContact || addNewOwnerContributor) && (
                    <>
                        {(searchedOwner?.includes('PHY-') || addNewOwnerContact) ? (
                            <ContactForm
                                addNewOwnerContact={addNewOwnerContact}
                                contact={contact}
                                setContact={setContact}
                            />
                        ) : (
                            <ContributorForm
                                addNewOwnerContributor={addNewOwnerContributor}
                                contributor={contributor}
                                setContributor={setContributor}
                            />
                        )}
                    </>
                )
                }
            </DialogContentMUI>
            <DialogActions>
                <Grid container alignItems='center' justifyContent='space-between'>
                    <Grid container item xs={4} columnSpacing={1}>
                        <Grid item>
                            <Button
                                variant='outlined'
                                onClick={onClose}
                            >
                                {i18n.cancel}
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid container item xs={4} justifyContent='flex-end'>
                        <Grid item>
                            <Button
                                variant='contained'
                                color='primary'
                                onClick={() => {}} // A voir avec killian
                            >
                                {i18n.save}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogActions>
        </DialogMUI>
    )
}

DialogAddOwner.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
}

const PerimetersOwnersTable = ({
    privateOwners = [],
    companyOwners = [],
    readMode = true,
    onClick = () => {},
}) => {
    const {
        cities,
        sandreCodes,
    } = useSelector(store => ({
        cities: store.CityReducer.cities,
        sandreCodes: store.ReferencialReducer.sandreCodes,
    }), shallowEqual)

    const lexiconOwnerTypes = useSandreList('PARCELLES.TYPE_PROPRIETE')

    const [addOwnerPopupOpen, setAddOwnerPopupOpen] = useState(false)

    const [deletable, setDeletable] = useState()
    const [isOpenDelete, setIsOpenDelete] = useState(false)

    const dispatch = useDispatch()

    useEffect(() => {
        if (!cities?.length) {
            dispatch(CityAction.fetchCities())
        }

        if (!sandreCodes?.length) {
            dispatch(ReferencialAction.fetchSandreCodes())
        }

        dispatch(PerimetersThunk.getOwners())
    }, [])

    const formattedPrivateOwners = privateOwners.map(p => ({
        ...p,
        nullValue: <AccountCircleIcon style={{ fontSize: '30px' }} />,
        type: lexiconOwnerTypes.find(lot => lot.code === p.propertyType)?.name,
        address: `${p.address ?? ''}${p.cityName ? `, ${p.cityName}` : ''}${p.postalCode ? `, ${p.postalCode}` : ''}`,
    }))

    const formattedCompanyOwners = companyOwners.map(p => ({
        ...p,
        nullValue: <BusinessIcon style={{ fontSize: '30px' }} />,
        type: lexiconOwnerTypes.find(lot => lot.code === p.propertyType)?.name,
        address: `${p.address ?? ''}${p.cityName ? `, ${p.cityName}` : ''}${p.postalCode ? `, ${p.postalCode}` : ''}`,
    }))

    const orderedOwners = orderBy([ ...formattedPrivateOwners, ...formattedCompanyOwners ], 'name')

    const exportData = () => {
        exportFile({
            data: orderedOwners.map(oo => ({
                type: { value: oo.type || '' },
                name: { value: oo.name || '' },
                address: { value: oo.address || '' },
                headers: OWNERS_HEADER,
            })),
            exportType: 'xlsx',
            titleFile: i18n.owners,
        })
    }

    return (
        <Grid container item xs={12} sx={{ border: 'solid 1px black', borderRadius: MAIN_RADIUS }}>
            <DialogAddOwner
                open={addOwnerPopupOpen}
                onClose={() => setAddOwnerPopupOpen(false)}
            />
            <ConfirmModal
                isOpen={isOpenDelete}
                title={i18n.sureDeleteParcel}
                onValidate={() => {
                    dispatch(PerimetersThunk.deleteParcel({
                        perimeterFolder: deletable.perimeterFolder,
                        perimeterId: deletable.perimeterId,
                        parcelId: deletable.parcelId,
                    }))
                    setDeletable(undefined)
                    setIsOpenDelete(false)
                }}
                onClose={() => {
                    setDeletable(undefined)
                    setIsOpenDelete(false)
                }}
            />
            <Grid item xs={12}>
                <WhiteCard
                    title={`${i18n.owners} (${privateOwners.length + companyOwners.length} ${i18n.elements})`}
                    actions={compact([{
                        iconName: 'download',
                        tooltip: i18n.export,
                        color: 'black',
                        onClick: () => exportData(),
                    }, !readMode && {
                        iconName: 'note_add',
                        tooltip: i18n.add,
                        color: 'black',
                        onClick: () => setAddOwnerPopupOpen(true),
                    }])}
                    round
                >
                    <Table
                        data={orderedOwners}
                        showTitle={false}
                        paging
                        nbPerPageLabel={nbPerPageLabelTiny}
                        type={{ headers: ['nullValue', ...OWNERS_HEADER ] }}
                        condensed
                        sortable
                        deletable={!readMode}
                        onDelete={(d) => {
                            setDeletable({
                                perimeterFolder: d.perimeterFolder,
                                perimeterId: d.perimeterId,
                                parcelId: d.parcelId,
                            })
                            setIsOpenDelete(true)
                        }}
                        id='parcel_private_owners'
                        invertedHeaderStyle
                        round
                    />
                </WhiteCard>
            </Grid>
        </Grid>
    )
}

PerimetersOwnersTable.propTypes = {
    privateOwners: PropTypes.arrayOf(PropTypes.shape({})),
    companyOwners: PropTypes.arrayOf(PropTypes.shape({})),
    readMode: PropTypes.bool,
    onClick: PropTypes.func,
}

export default PerimetersOwnersTable