import DtoFile from 'components/file/dto/DtoFile'
import SimpleDatePicker from 'components/forms/SimpleDatePicker'
import ConfirmModal from 'components/modal/ConfirmModal'
import { push } from 'connected-react-router'
import { template, uniq, uniqBy, orderBy, max, isNil, isUndefined } from 'lodash'
import PropTypes from 'prop-types'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import useActions from 'utils/customHook/useActions'
import useSandreList from 'utils/customHook/useSandreList'
import Other from '../../../../../components/actions/Other'
import Card from '../../../../../components/card/Card'
import Checkbox from '../../../../../components/forms/Checkbox'
import Input from '../../../../../components/forms/Input'
import NumberField from '../../../../../components/forms/NumberField'
import Select from '../../../../../components/forms/Select'
import Textarea from '../../../../../components/forms/Textarea'
import Row from '../../../../../components/react/Row'
import { nbPerPageLabel, SANDRE } from '../../../../../referencial/constants/ReferencialConstants'
import { getDate, getFullDate, getHour } from '../../../../../utils/DateUtil'
import { onChangeHour, setConfirmationModal } from '../../../../../utils/FormUtils'
import OperationAction from '../../actions/OperationAction'
import DtoSample from '../../../../dto/DtoSample'
import { arrayOf, instanceOf } from '../../../../../utils/StoreUtils'
import { getQualifications, getStatuses, UNQUALIFIED } from '../../../../../utils/QualityUtils'
import Table from '../../../../../components/datatable/Table'
import DtoSampling from '../../dto/DtoSampling'
import SamplingModal from './SamplingModal'
import ToastrAction from 'toastr/actions/ToastrAction'

const OperationDescriptionPanel = ({
    id,
    code = '',
    uploadFile = () => { },
    files = [],
    getOperationFiles = () => {},
    sample,
    reloadOperation,
    changeParent = () => {},
    samplings,
}) => {
    const dispatch = useDispatch()

    const {
        operation,
        operationAnalysis,
        qualitometer,
        qualityCampaignStations,
        qualityCampaigns,
        supports,
        fractions,
        methods,
        contacts,
        contributors,
        accountUser,
    } = useSelector(store => ({
        operation: store.OperationReducer.operation,
        operationAnalysis: store.OperationReducer.operationAnalysis,
        qualitometer: store.QualityReducer.qualitometer,
        qualityCampaignStations: store.QualityReducer.qualityCampaignStations,
        qualityCampaigns: store.QualityReducer.qualityCampaigns,
        supports: store.SupportReducer.supports,
        fractions: store.FractionReducer.fractions,
        methods: store.MethodReducer.methods,
        contacts: store.ContactReducer.contacts,
        contributors: store.ContributorReducer.contributors,
        accountUser: store.AccountReducer.accountUser,
    }))

    const sandreConservationMode = useSandreList(SANDRE.CONSERVATION_MODE)
    const sandreParticularSituation = useSandreList(SANDRE.PARTICULAR_SITUATION)
    const sandreSampleSpeed = useSandreList(SANDRE.SAMPLE_SPEED)
    const localizations = useSandreList(SANDRE.ANALYSES_LIEU)

    const [editMode, setEditMode] = useState(code === 'new')
    const [isOpen, setIsOpen] = useState(false)
    const [samplingPopupIsOpen, setSamplingPopupIsOpen] = useState(false)
    const [selectedSampling, setSelectedSampling] = useState({})


    const [op, setOp] = useState({
        ...operation,
        id: operation.id || 0, qualitometer: parseInt(id),
        point: (operation.point ?? qualitometer.link_samplePoints.find(p => p.identifiant === '031')?.idPoint) ?? -1,
    })
    const [samp, setSamp] = useState({ ...sample, id: sample.id || 0, qualitometer: parseInt(id) })

    const changeOp = changes => setOp({ ...op, ...changes })
    const changeSamp = changes => setSamp({ ...samp, ...changes })

    const updateSamplings = newSamplings => {
        OperationAction.promiseUpdateSampleSamplings(parseInt(id), sample.id, newSamplings).then(() => {
            changeParent({ samplings: newSamplings })
            setSamplingPopupIsOpen(false)
        })
    }

    const filteredPoints = useMemo(() => {
        return qualitometer.link_samplePoints
            .filter(p => isNil(op.support) || isNil(p.codeSupport) || p.codeSupport === op.support)
            .filter(p => isNil(p.startDate) || p.startDate <= op.date)
            .filter(p => isNil(p.endtDate) || p.endtDate >= op.date)
    }, [op.date, op.support, qualitometer.link_samplePoints])

    useActions(() => {
        if (editMode) {
            return {
                save: () => {
                    if (isUndefined(op.point)) {
                        dispatch(ToastrAction.warning('Veillez sélection un point de prélévement'))
                        return
                    }
                    if (code === 'new') {
                        OperationAction.createOperation(id, op, samp).then(json => {
                            dispatch(push(`/station/quality/${id}/operation/${json.id}`))
                            reloadOperation()
                        })
                    } else {
                        setIsOpen(true)
                    }
                },
                cancel: () => {
                    if (code === 'new') {
                        dispatch(push(`/station/quality/${id}/operation`))
                    } else {
                        setOp(operation)
                        setSamp(sample)
                        setEditMode(false)
                    }
                },
                clear: () => {
                    setOp({})
                    setSamp({})
                },
                importFile: {
                    onClick: uploadFile,
                    format: '',
                    tooltip: i18n.importAnalysisFile,
                },
            }
        }
        const editAction = accountUser.consultant === '1' ? {} : {
            edit: () => {
                setEditMode(true)
            },
        }
        const importFileAction = files.length ? {
            other: {
                other: (
                    <Other
                        className='clickable'
                        tooltip={i18n.operation}
                        icon='attach_file'
                        onClick={getOperationFiles}
                    />
                ),
            },
        } : {}
        return {
            ...editAction,
            ...importFileAction,
            delete: () => {
                OperationAction.deleteOperation(id, operation, sample).then(() => dispatch(push(`/station/quality/${id}/operation`)))
            },
            importFile: {
                onClick: uploadFile,
                format: '',
                tooltip: i18n.importAnalysisFile,
            },
        }
    }, [op, editMode])

    const campaigns = useMemo(() => {
        const stationId = parseInt(id)
        const campaignCodes = qualityCampaignStations.filter(cs => cs.stationId === stationId).map(cs => cs.campaignId)
        return uniq([...campaignCodes, op.campaign]).map(cCode => qualityCampaigns.find(c => c.id === cCode)).filter(c => !!c)
    }, [op.campaign, id, qualityCampaignStations, qualityCampaigns])

    useEffect(() => {
        if (filteredPoints.some(p => p.idPoint === op.point)) return

        if (filteredPoints.length === 1) {
            changeOp({ point: filteredPoints[0].idPoint })
            return
        }

        changeOp({ point: undefined })
    }, [filteredPoints, op.point])

    const formattedPoints = useMemo(() => {
        return filteredPoints.map(p => {
            const support = supports.find(s => s.code === `${p.codeSupport}`)
            return {
                name: `${p.name || (support?.name ?? 'Eau')} [${p.identifiant || (support?.code ?? '031')}] ${p.code ?? ''} ${p.startDepth || p.endDepth ? `${p.startDepth ?? '(...)'}m -> ${p.endDepth ?? '(...)'}m` : ''}`,
                id: p.idPoint,
            }
        })
    }, [filteredPoints, supports])

    const orderedSamplings = orderBy(samplings, 'idSampling')
    const formattedSamplings = orderedSamplings.map((s, idx) => {
        return {
            ...s,
            date: getFullDate(s.date),
            arrivalDateLabo: getFullDate(s.arrivalDate),
            reference: s.ref,
            idx,
        }
    })

    return (
        <Card maxWidth={1200} className='no-box-shadow'>
            <div className='row no-margin padding-top-1'>
                <SimpleDatePicker
                    value={op.date}
                    id='startDate'
                    label={i18n.beginDate}
                    onChange={v => {
                        changeOp({ date: v })
                        changeSamp({ date: v })
                    }}
                    col={3}
                    disabled={!editMode}
                    active={editMode}
                    obligatory
                />
                <Input
                    col={3}
                    title={i18n.startHour}
                    value={getHour(op.date)}
                    onChange={v => onChangeHour(v, v2 => changeOp({ date: v2 }), {}, op.date)}
                    disabled={!editMode}
                    active={editMode}
                />
                {
                    !!operation.updateDate && (
                        <div className='col s6'>
                            <Card className='padding-1'>
                                <span>
                                    {`${i18n.lastUpdateOn} ${getDate(operation.updateDate, 'DD/MM/YYYY  à HH:mm')} ${operation.updateLogin ? `${i18n.by} ${operation.updateLogin}` : ''}`}
                                </span>
                            </Card>
                        </div>
                    )
                }
            </div>
            <Row>
                <Select
                    col={6}
                    options={campaigns}
                    label={i18n.campaign}
                    value={op.campaign}
                    nullLabel='&nbsp;'
                    onChange={v => changeOp({ campaign: v })}
                    disabled={!editMode}
                    active={editMode}
                />
                <Input
                    col={4}
                    title={i18n.jobExecutionId}
                    value={op.jobExecutionId}
                    disabled={true}
                />
            </Row>
            <Row>
                <Select
                    col={3}
                    options={getStatuses()}
                    label={i18n.status}
                    value={op.status}
                    nullLabel='&nbsp;'
                    onChange={v => changeOp({ status: v })}
                    disabled={!editMode}
                    active={editMode}
                />
                <Select
                    col={3}
                    options={getQualifications()}
                    label={i18n.qualification}
                    value={op.qualification || UNQUALIFIED}
                    nullLabel='&nbsp;'
                    onChange={v => changeOp({ qualification: v })}
                    disabled={!editMode}
                    active={editMode}
                />
                <Checkbox
                    col={3}
                    componentClassName='padding-top-1'
                    label={i18n.accreditation}
                    checked={op.accreditation === '1'}
                    onChange={v => {
                        changeOp({ accreditation: v ? '1' : '0' })
                        changeSamp({ accreditation: v ? '1' : '0' })
                    }}
                    disabled={!editMode}
                    active={editMode}
                />
                <Checkbox
                    col={3}
                    componentClassName='padding-top-1'
                    label={i18n.authorizedBanking}
                    checked={op.authorizationBanking === '1'}
                    onChange={v => changeOp({ authorizationBanking: v ? '1' : '0' })}
                    disabled={!editMode}
                    active={editMode}
                />
            </Row>
            <Row>
                <Select
                    col={3}
                    options={formattedPoints}
                    label={i18n.samplePoint}
                    value={op.point}
                    nullLabel='&nbsp;'
                    keyValue='idPoint'
                    onChange={v => changeOp({ point: v })}
                    disabled={!editMode}
                    active={editMode}
                    obligatory
                />
                <Select
                    col={3}
                    label={i18n.support}
                    options={supports}
                    value={op.support}
                    onChange={v => changeOp({ support: v ? parseInt(v) : null })}
                    disabled={!editMode}
                    integerValue
                    active={editMode}
                />
                <div className='col s6'/>
            </Row>
            <Row>
                <Input
                    col={3}
                    title={i18n.analysisNumber}
                    value={samp.numLabo}
                    onChange={v => changeSamp({ numLabo: v })}
                    disabled={!editMode}
                    active={editMode}
                />
                <Input
                    col={3}
                    title={i18n.refLabo}
                    value={samp.refLabo}
                    onChange={v => changeSamp({ refLabo: v })}
                    disabled={!editMode}
                    active={editMode}
                />
                <Select
                    col={6}
                    label={i18n.location}
                    value={op.localization}
                    options={localizations}
                    onChange={v => {
                        changeOp({ localization: v ? `${v}` : null })
                        changeSamp({ localization: v ? `${v}` : null })
                    }}
                    disabled={!editMode}
                    active={editMode}
                />
            </Row>
            <Row>
                <NumberField
                    col={3}
                    title={`${i18n.length} (m)`}
                    value={op.length}
                    floatValue
                    onChange={v => changeOp({ length: v })}
                    disabled={!editMode}
                    active={editMode}
                />
                <NumberField
                    col={3}
                    title={i18n.averageWidth}
                    value={op.mediumWidth}
                    floatValue
                    onChange={v => changeOp({ mediumWidth: v })}
                    disabled={!editMode}
                    active={editMode}
                />
                <Select
                    col={3}
                    options={sandreConservationMode}
                    label={i18n.principalConservationMode}
                    value={op.methodOfPrincipalConservation}
                    nullLabel='&nbsp;'
                    onChange={v => changeOp({ methodOfPrincipalConservation: v })}
                    disabled={!editMode}
                    active={editMode}
                />
                <Select
                    col={3}
                    options={sandreConservationMode}
                    label={i18n.secondConservationMode}
                    value={op.methodOfSecondConservation}
                    nullLabel='&nbsp;'
                    onChange={v => changeOp({ methodOfSecondConservation: v })}
                    disabled={!editMode}
                    active={editMode}
                />
            </Row>
            <Row>
                <Textarea
                    col={4}
                    title={`${i18n.comment} ${i18n.operation}` }
                    value={op.comment}
                    onChange={v => changeOp({ comment: v })}
                    disabled={!editMode}
                    active={editMode}
                />
                <Textarea
                    col={4}
                    title={`${i18n.comment} ${i18n.sample}` }
                    value={samp.comment}
                    onChange={v => changeSamp({ comment: v })}
                    disabled={!editMode}
                    active={editMode}
                />
                <Textarea
                    col={4}
                    title={i18n.interpretation}
                    value={op.interpretation}
                    onChange={v => changeOp({ interpretation: v })}
                    disabled={!editMode}
                    active={editMode}
                />
            </Row>
            <Row className='padding-top-1 valign-wrapper'>
                <Select
                    col={3}
                    options={sandreParticularSituation}
                    label={i18n.particularSituation}
                    value={op.particularSituation}
                    nullLabel='&nbsp;'
                    onChange={v => changeOp({ particularSituation: v })}
                    disabled={!editMode}
                    active={editMode}
                />
                <NumberField
                    col={3}
                    title={`${i18n.wetArea} (m2)`}
                    value={op.wetArea}
                    floatValue
                    onChange={v => changeOp({ wetArea: v })}
                    disabled={!editMode}
                    active={editMode}
                />
                <Select
                    col={3}
                    options={sandreSampleSpeed}
                    label={i18n.speed}
                    value={op.speed}
                    nullLabel='&nbsp;'
                    onChange={v => changeOp({ speed: v ? `${v}` : null })}
                    disabled={!editMode}
                    active={editMode}
                />
                <Checkbox
                    col={3}
                    label={i18n.continue}
                    checked={op.continu === '1'}
                    onChange={v => changeOp({ continu: v ? '1' : '0' })}
                    disabled={!editMode}
                    active={editMode}
                />
            </Row>
            <div className='row no-margin'>
                <div className='col s12'>
                    <fieldset>
                        <legend>&nbsp;{i18n.producer}&nbsp;</legend>
                        <div className='row no-margin'>
                            <Select
                                col={8}
                                options={contributors}
                                label={i18n.producer}
                                value={op.producer}
                                nullLabel='&nbsp;'
                                keyLabel='labelDisplay'
                                displayWithCode
                                onChange={v => {
                                    changeOp({ producer: v })
                                    changeSamp({ producer: v })
                                }}

                                disabled={!editMode}
                                active={editMode}
                            />
                            <Select
                                col={4}
                                options={contacts}
                                label={i18n.contact}
                                value={op.producerContact}
                                nullLabel='&nbsp;'
                                displayWithCode
                                onChange={v => changeOp({ producerContact: v })}

                                disabled={!editMode}
                                active={editMode}
                            />
                        </div>
                        <Row>
                            <Input
                                col={6}
                                title={i18n.producerRef}
                                value={samp.producerRef}
                                onChange={v => changeSamp({ producerRef: v })}

                                disabled={!editMode}
                                active={editMode}
                            />
                        </Row>
                    </fieldset>
                </div>
            </div>
            <div className='row no-margin'>
                <div className='col s12'>
                    <fieldset>
                        <legend>&nbsp;{i18n.laboratory}&nbsp;</legend>
                        <div className='row no-margin'>
                            <Select
                                col={8}
                                options={contributors}
                                label={i18n.laboratory}
                                value={samp.labo}
                                nullLabel='&nbsp;'
                                displayWithCode
                                keyLabel='labelDisplay'
                                onChange={v => changeSamp({ labo: v })}

                                disabled={!editMode}
                                active={editMode}
                            />
                        </div>
                    </fieldset>
                </div>
            </div>
            <div className='row no-margin'>
                <div className='col s12'>
                    <fieldset>
                        <legend>&nbsp;{i18n.sampler}&nbsp;</legend>
                        <div className='row no-margin'>
                            <Select
                                col={8}
                                options={contributors}
                                label={i18n.sampler}
                                value={op.sampler}
                                nullLabel='&nbsp;'
                                displayWithCode
                                keyLabel='labelDisplay'
                                onChange={v => changeOp({ sampler: v })}

                                disabled={!editMode}
                                active={editMode}
                            />
                            <Select
                                col={4}
                                options={contacts}
                                label={i18n.contact}
                                value={op.samplerContact}
                                nullLabel='&nbsp;'
                                displayWithCode
                                onChange={v => changeOp({ samplerContact: v })}

                                disabled={!editMode}
                                active={editMode}
                            />
                        </div>
                    </fieldset>
                </div>
            </div>
            <div className='row no-margin'>
                <div className='col s12'>
                    <fieldset>
                        <legend>&nbsp;{i18n.determiner}&nbsp;</legend>
                        <div className='row no-margin'>
                            <Select
                                col={8}
                                options={contributors}
                                label={i18n.determiner}
                                value={op.determiner}
                                nullLabel='&nbsp;'
                                keyLabel='labelDisplay'
                                displayWithCode
                                onChange={v => changeOp({ determiner: v })}

                                disabled={!editMode}
                                active={editMode}
                            />
                            <Select
                                col={4}
                                options={contacts}
                                label={i18n.contact}
                                value={op.determinerContact}
                                nullLabel='&nbsp;'
                                displayWithCode
                                onChange={v => changeOp({ determinerContact: v })}

                                disabled={!editMode}
                                active={editMode}
                            />
                        </div>
                    </fieldset>
                </div>
            </div>
            <div className='row no-margin padding-top-1'>
                <Select
                    col={6}
                    options={methods}
                    label={i18n.protocol}
                    value={op.method}
                    nullLabel='&nbsp;'
                    onChange={v => changeOp({ method: v })}
                    disabled={!editMode}
                    integerValue
                    active={editMode}
                />
            </div>
            <div className='row no-margin valign-wrapper'>
                <Textarea
                    col={6}
                    title={i18n.resultComment}
                    value={op.resultComment}
                    onChange={v => changeOp({ resultComment: v })}
                    disabled={!editMode}
                    active={editMode}
                />
                <Checkbox
                    col={6}
                    label={i18n.microSampling}
                    checked={op.microSampling === '1'}
                    onChange={v => changeOp({ microSampling: v ? '1' : '0' })}
                    disabled={!editMode}
                    active={editMode}
                />
            </div>
            <div className='padding-bottom-2' />
            <div className='row no-margin'>
                <Table
                    data={formattedSamplings}
                    sortable
                    orderable
                    title={ i18n.samplings }
                    paging
                    nbPerPageLabel={nbPerPageLabel}
                    type={{ headers: ['reference', 'refLabo', 'date', 'arrivalDateLabo'] }}
                    customHeaders={ { status: ' ' } }
                    condensed
                    deletable={editMode}
                    onDelete={ v => setConfirmationModal(() => updateSamplings(samplings.filter(s => s.idSampling !== v.idSampling))) }
                    alterable={editMode}
                    onAlter={ v => {
                        setSelectedSampling(orderedSamplings[v.idx])
                        setSamplingPopupIsOpen(true)
                    } }
                    actions={ !editMode ? [] : [{ iconName: 'note_add', onClick: () => {
                        setSelectedSampling({ idSampling: (max(samplings.map(s => s.idSampling)) ?? 0) + 1, qualitometer: parseInt(id), idSample: sample.id })
                        setSamplingPopupIsOpen(true)
                    } }] }
                />
            </div>
            <div className='padding-bottom-2' />

            <ConfirmModal
                isOpen={isOpen}
                title={template(i18n.thisModificationWillUpdateThsSampleAndTheAnalyzesAttachedToId)({ nbAnalysis: operationAnalysis.length })}
                onValidate={() => {
                    OperationAction.updateOperation(op, samp).then(() => {
                        setIsOpen(false)
                        reloadOperation()
                        setEditMode(false)
                    })
                }}
                onClose={() => setIsOpen(false)}
            />
            { samplingPopupIsOpen ? (
                <SamplingModal
                    onClose={() => setSamplingPopupIsOpen(false)}
                    isOpen={samplingPopupIsOpen}
                    onValidate={newSamp => updateSamplings(uniqBy([newSamp, ...samplings], 'idSampling'))}
                    selectedSampling={selectedSampling}
                />
            ) : null }
        </Card>
    )
}

OperationDescriptionPanel.propTypes = {
    id: PropTypes.string.isRequired,
    code: PropTypes.string.isRequired,
    uploadFile: PropTypes.func,
    files: PropTypes.arrayOf(PropTypes.instanceOf(DtoFile)),
    getOperationFiles: PropTypes.func,
    sample: instanceOf(DtoSample),
    reloadOperation: PropTypes.func,
    samplings: arrayOf(DtoSampling),
    changeParent: PropTypes.func,
}

export default OperationDescriptionPanel