import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { push } from 'connected-react-router'
import { getDate, getDay } from '../../../../utils/DateUtil'
import DtoMaterielAssignment from '../../../dto/materiel/DtoMaterielAssignment'
import { filter, find } from 'lodash'
import AssigmentPanel from './AssigmentPanel'
import DtoAssigmentItem from '../../../dto/materiel/DtoAssigmentItem'
import {
    createIconMaterialState,
    getCloseAssignements,
    getOpenAssignements,
    getReduceAssignementsStatus,
} from '../../../../utils/MaterielUtils'
import { getI18nTitleDataLength } from '../../../../utils/StringUtil'
import { getLabel } from '../../../../utils/StoreUtils'
import DtoMaterielType from '../../../../materiel/dto/DtoMaterielType'
import CloseAssigmentPanel from './CloseAssigmentPanel'
import DtoCentralChannel from '../../../../materiel/components/central/dto/DtoCentralChannel'
import PowerSupplyTypeDto from '../../../../materiel/components/powerSupply/dto/PowerSupplyTypeDto'
import DtoMaterielState from '../../../../materiel/dto/DtoMaterielState'
import i18n from 'simple-react-i18n'
import DtoCentralRange from '../../../../materiel/components/central/dto/DtoCentralRange'
import AppStore from '../../../../store/AppStore'
import SimSubscriptionDto from '../../../../materiel/components/sim/dto/SimSubscriptionDto'
import DtoSim from '../../../dto/materiel/DtoSim'
import DtoSubscription from '../../../dto/materiel/DtoSubscription'
import SubscriptionTypeDto from '../../../../materiel/components/subscription/dto/SubscriptionTypeDto'
import SimTypeDto from '../../../../materiel/components/sim/dto/DtoSimType'

class AssigmentListPanel extends Component {
    constructor(props) {
        super(props)
    }

    getMateriel = (situation) => {
        return find(this.props.listOfStationMateriel, (b) => {
            return b.id == situation[this.props.stationMaterielIdKey]
        })
    }

    getType = (materiel) => {
        if (materiel) {
            return find(this.props.listOfType, (b) => {
                return b.id == materiel[this.props.typeKey]
            })
        }
        return null
    }

    getOpenAssignementPanel = (listOfOpenAssigments) => {
        if (listOfOpenAssigments.length) {
            return listOfOpenAssigments.map((o) => {
                const materiel = this.getMateriel(o)
                const type = this.getType(materiel)
                const title = (() => {
                    const label = type ? type.label : ''
                    const reference = (() => {
                        if (materiel) {
                            if (materiel.imei) {
                                return ` - ${materiel.imei}`
                            } else if (materiel.reference) {
                                return ` - ${materiel.reference}`
                            } else if (materiel.code) {
                                return ` - ${materiel.code}`
                            } else if (materiel.numLine) {
                                return ` - ${materiel.numLine}`
                            }
                            return ''
                        }
                        return ''
                    })()
                    return label + reference
                })()
                const paths = (() => {
                    if (this.props.listOfPath.length) {
                        return this.props.listOfPath.map((path) => {
                            const pathType = (() => {
                                if (path.pathNumber) {
                                    return ` : ${path.pathNumber}`
                                }
                                return ''
                            })()
                            return (
                                <div className='row no-margin'>
                                    <div className='col s12 no-padding'>
                                        { i18n.path } { path.pathId } { pathType }
                                    </div>
                                </div>
                            )
                        })
                    }
                    return ''
                })()
                const secondLineSecondValue = (() => {
                    if (this.props.listOfAlimentationType.length) {
                        const alimentationType = (() => {
                            if (materiel && materiel.powerSupplyType) {
                                const findPowerSupplyType = find(this.props.listOfAlimentationType, (alim) => {
                                    return alim.id == materiel.powerSupplyType
                                })
                                return findPowerSupplyType ? findPowerSupplyType.label : ''
                            }
                            return ''
                        })()
                        if (alimentationType) {
                            return (
                                <div className='row no-margin'>
                                    <div className='col s12 no-padding'>
                                        { alimentationType }
                                    </div>
                                </div>
                            )
                        }
                        return ''
                    } else if (materiel && materiel.voltage) {
                        return `${i18n.scale} : ${materiel.voltage}V`
                    } else if (materiel && materiel.length) {
                        return `${i18n.length} : ${materiel.length}m`
                    }
                    return ''
                })()
                const thirdLineFirstValue = (() => {
                    if (this.props.listOfRange) {
                        const materielListOfRange = (() => {
                            if (materiel && materiel.id) {
                                return filter(this.props.listOfRange, (range) => {
                                    return range.centralId == materiel.id
                                })
                            }
                            return []
                        })()
                        if (materielListOfRange.length) {
                            const result = materielListOfRange.map((range) => {
                                const day = range.day ? `${getDay(range.day)} : ` : ''
                                return (
                                    <div className='row no-margin'>
                                        <div className='col s12 no-padding'>
                                            { day }{ range.range }
                                        </div>
                                    </div>
                                )
                            })
                            return (
                                <div className='row no-margin'>
                                    <div className='col s3 no-padding'>
                                        { getI18nTitleDataLength(i18n.range, i18n.ranges, materielListOfRange.length) } :
                                    </div>
                                    <div className='col s9 no-padding'>
                                        { result }
                                    </div>
                                </div>
                            )
                        }
                        return ''
                    }
                    return ''
                })()
                const link = (() => {
                    if (materiel && materiel.id) {
                        return `${this.props.linkDetail}/${materiel.id}`
                    }
                    return this.props.linkDetail
                })()
                const secondLineFirstValue = (() => {
                    if (!materiel) {
                        return ''
                    }
                    if (this.props.listOfSimSubscription && this.props.listOfSimOrSub && this.props.listOfSimSubscription.length && this.props.listOfSimOrSub.length) {
                        const simSubscription = this.props.listOfSimSubscription.find(simSubLast => simSubLast[this.props.stationMaterielIdKey] === materiel.id) || {}
                        const matAssociated = simSubscription ? this.props.listOfSimOrSub.find(simOrSub => simOrSub.id === simSubscription[this.stationMaterielIdKeySimOrSub()]) : {}
                        return matAssociated ? (this.getTypeMaterielAssociatedSimOrSub(matAssociated) ?
                            `${this.getTypeMaterielAssociatedSimOrSub(matAssociated).label}: ${(matAssociated.serialNumber || matAssociated.numLine || '')}` : (matAssociated.serialNumber || matAssociated.numLine)) : ''
                    }
                    return materiel.serialNumber
                })()
                const label = getLabel(this.props.materielStates, o.statusCode)
                const statusIcon = createIconMaterialState(label, o.statusCode, null, false)
                return (
                    <AssigmentPanel
                        title={ title }
                        installationDate={ o.situationDate }
                        link={ link }
                        statusIcon={ statusIcon }
                        secondLineFirstValue={ secondLineFirstValue }
                        secondLineSecondValue={ secondLineSecondValue }
                        thirdLineFirstValue={ thirdLineFirstValue }
                        thirdLineSecondValue={ paths }
                        fourthLineFirstValue={ o.comment }
                    />
                )
            })
        }
        return null
    }

    stationMaterielIdKeySimOrSub = () => {
        switch (this.props.stationMaterielIdKey) {
            case 'idSubscription': return 'idSim'
            case 'idSim': return 'idSubscription'
            default: return ''
        }
    }

    stationMaterielTypeIdKeySimOrSub = () => {
        switch (this.props.typeKey) {
            case 'subscriptionType': return 'simType'
            case 'simType': return 'subscriptionType'
            default: return ''
        }
    }

    getTypeMaterielAssociatedSimOrSub = (materiel) => {
        if (materiel) {
            return find(this.props.listOfSimOrSubTypes, (b) => {
                return b.id == materiel[this.stationMaterielTypeIdKeySimOrSub()]
            })
        }
        return null
    }

    getCloseAssignement = (listOfCloseAssigments) => {
        return listOfCloseAssigments.map((o) => {
            const materiel = this.getMateriel(o)
            const result = (() => {
                if (materiel) {
                    const type = this.getType(materiel)
                    if (type) {
                        return {
                            ...o,
                            type: type.label,
                            reference: materiel.reference,
                            serialNumber: materiel.serialNumber,
                            situationDate: getDate(o.situationDate),
                            situationEndDate: getDate(o.situationEndDate),
                        }
                    }
                    return o
                }
                return o
            })()
            return { ...o, ...new DtoAssigmentItem(result) }
        })
    }

    getCloseAssigmentPanel = (listOfCloseAssigments) => {
        if (listOfCloseAssigments.length) {
            return (
                <CloseAssigmentPanel
                    link={ this.props.linkDetail }
                    listOfAssigments={ listOfCloseAssigments }
                    materiels={ this.props.listOfStationMateriel }
                    stationMaterielIdKey={ this.props.stationMaterielIdKey }
                />
            )
        }
        return null
    }

    getMaterielPanel = () => {
        const reduceAssigments = getReduceAssignementsStatus(this.props.listOfAssigments)
        const listOfOpenAssigments = getOpenAssignements(reduceAssigments)
        const listOfCloseAssigments = this.getCloseAssignement(getCloseAssignements(reduceAssigments))
        const lengthData = this.props.listOfAssigments.length
        return (
            <div className='row no-margin'>
                <div className='col s12'>
                    <div className='card'>
                        <div className='card-title card-title-light-blue'>
                            <div className='row no-margin'>
                                <div className='col s11 no-padding'>
                                    <i className='material-icons'>{ this.props.icon }</i>
                                    { `${getI18nTitleDataLength(this.props.singularTitle, this.props.plurialTitle, lengthData)} (${lengthData})` }
                                </div>
                                <div className='col s1 no-padding right-align'>
                                    <i className='material-icons clickable'
                                        onClick={ () => AppStore.dispatch(push(this.props.link)) }
                                    >edit</i>
                                </div>
                            </div>
                        </div>
                        <div className='card-content no-padding no-margin'>
                            { this.getOpenAssignementPanel(listOfOpenAssigments) }
                            { this.getCloseAssigmentPanel(listOfCloseAssigments) }
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    render() {
        return (
            this.getMaterielPanel()
        )
    }

    componentDidUpdate() {
        $('.collapsible').collapsible()
    }
}

AssigmentListPanel.propTypes = {
    listOfAssigments: PropTypes.arrayOf(PropTypes.instanceOf(DtoMaterielAssignment)),
    listOfType: PropTypes.arrayOf(PropTypes.instanceOf(DtoMaterielType)),
    listOfAlimentationType: PropTypes.arrayOf(PropTypes.instanceOf(PowerSupplyTypeDto)),
    listOfPath: PropTypes.arrayOf(PropTypes.instanceOf(DtoCentralChannel)),
    listOfRange: PropTypes.arrayOf(PropTypes.instanceOf(DtoCentralRange)),
    listOfSimSubscription: PropTypes.arrayOf(PropTypes.instanceOf(SimSubscriptionDto)),
    listOfSimOrSub: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.instanceOf(DtoSim)),
        PropTypes.arrayOf(PropTypes.instanceOf(DtoSubscription)),
    ]),
    listOfSimOrSubTypes: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.instanceOf(SimTypeDto)),
        PropTypes.arrayOf(PropTypes.instanceOf(SubscriptionTypeDto)),
    ]),
    typeKey: PropTypes.string,
    materielStates: PropTypes.arrayOf(PropTypes.instanceOf(DtoMaterielState)),
    listOfStationMateriel: PropTypes.arrayOf(PropTypes.instanceOf(DtoMaterielType)),
    stationMaterielIdKey: PropTypes.string,
    singularTitle: PropTypes.string,
    plurialTitle: PropTypes.string,
    icon: PropTypes.string,
    link: PropTypes.string,
    linkDetail: PropTypes.string,
}
AssigmentListPanel.defaultProps = {
    listOfPath: [],
    listOfAlimentationType: [],
}
export default AssigmentListPanel
