import { Button, Grid } from '@mui/material'
import Icon from 'components/icon/Icon'
import { AccordionDetailsMUI, AccordionMUI, AccordionSummaryMUI } from 'components/styled/Accordions'
import React, { useEffect, useMemo, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { getStationType } from 'utils/StationUtils'
import { greyBlue, mainBlue } from 'utils/constants/ColorTheme'
import i18n from 'simple-react-i18n'
import Table from 'components/datatable/Table'
import { nbPerPageLabel, nbPerPageLabelMedium } from 'referencial/constants/ReferencialConstants'
import { intersectionWith, isUndefined, keyBy } from 'lodash'
import { getLabel } from 'utils/StoreUtils'
import { searchAllCharacters } from 'utils/StringUtil'
import SimpleSelectionTable, { ParameterFilterField } from 'components/datatable/SimpleSelectionTable'
import PropTypes from 'prop-types'
import { StyledFieldSet, StyledLegend } from 'components/StyledElements'
import { ButtonMUI } from 'components/styled/Buttons'
import StepperDialog from 'components/modal/StepperDialog'
import useListIndexed from 'utils/customHook/useListIndexed'
import Select from 'components/forms/Select'
import { DialogActionsMUI, DialogContentMUI, DialogMUI, DialogTitleMUI } from 'components/styled/Dialog'

const STEP_PARAMETER = 0
const STEP_THRESHOLD = 1

const Indicator = ({
    isEditMode = false,
    indicator = {},

    onUpdate = () => {},
    onUpdateIndicator = () => {},
    onDeleteIndicator = () => {},
}) => {
    const {
        qualitometers,
        thresholds,
        parameters,
    } = useSelector(store => ({
        qualitometers: store.QualityReducer.qualitometersLight,
        thresholds: store.QualityReducer.thresholds,
        parameters: store.ParameterReducer.parameters,
    }), shallowEqual)

    const nbStations = qualitometers.filter(q => q.stationType === `${indicator.stationType}`).length

    const formattedIndicators = useMemo(() => {
        if (isUndefined(indicator.indicators)) {
            return []
        }
        return indicator.indicators.map(i => {
            return {
                type: indicator.stationType,
                code: i.id,
                threshold: getLabel(thresholds, i.threshold),
                name: getLabel(parameters, i.id),
            }
        })
    }, [indicator, parameters, thresholds])

    return (
        <Grid item xs={12}>
            <AccordionMUI>
                <AccordionSummaryMUI sx={{ height: undefined }} color={isEditMode ? mainBlue : greyBlue}>
                    <Grid container alignItems='center' justifyContent='space-between'>
                        <Grid item xs={4}>
                            <span style={{ fontSize: '1.3rem' }}>
                                {getStationType(indicator.stationType).libelle}
                            </span>
                        </Grid>
                        <Grid item xs={3.5}>
                            <span style={{ fontSize: '1.3rem' }}>
                                {`${nbStations} ${i18n.stations}`}
                            </span>
                        </Grid>
                        <Grid item xs={3.5}>
                            <span style={{ fontSize: '1.3rem' }}>
                                {`${indicator.indicators?.length ?? 0} ${i18n.indicators}`}
                            </span>
                        </Grid>
                        <Grid item xs={1}>
                            {
                                isEditMode && (
                                    <>
                                        <Icon
                                            icon='edit'
                                            tooltip={i18n.edit}
                                            onClick={e => {
                                                e.stopPropagation()
                                                onUpdate(indicator.stationType)
                                            }}
                                        />
                                    </>
                                )
                            }
                        </Grid>
                    </Grid>
                </AccordionSummaryMUI>
                <AccordionDetailsMUI sx={{ padding: 0 }}>
                    <Table
                        showTitle={false}
                        data={formattedIndicators}
                        type={{ headers: ['code', 'name', 'threshold'] }}

                        sortable
                        condensed
                        paging
                        nbPerPageLabel={nbPerPageLabelMedium}

                        alterable={isEditMode}
                        onAlter={onUpdateIndicator}
                        deletable={isEditMode}
                        onDelete={onDeleteIndicator}
                    />
                </AccordionDetailsMUI>
            </AccordionMUI>
        </Grid>
    )
}

Indicator.propTypes = {
    isEditMode: PropTypes.bool,
    indicator: PropTypes.shape({
        stationType: PropTypes.number,
        indicators: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number,
            threshold: PropTypes.number,
        })),
    }),
    onUpdate: PropTypes.func,
    onUpdateIndicator: PropTypes.func,
    onDeleteIndicator: PropTypes.func,
}

const SelectParameters = ({
    indicator = {},
    setIndicator = () => { },
}) => {
    const {
        parameters,
    } = useSelector(store => ({
        parameters: store.ParameterReducer.parameters,
    }))

    const parametersFormatted = useMemo(() => {
        return parameters.map(p => ({
            code: p.code,
            name: p.name.length > 60 && p.shortLabel || p.name || '',
            labelSearch: searchAllCharacters(`${p.code}${p.name ?? ''}${p.shortLabel ?? ''}`),
            id: p.code,
        }))
    }, [parameters])

    const selectedParameters = indicator.indicators?.map(i => `${i.id}`) ?? []

    const onChange = listParam => {
        setIndicator(indi => {
            const indicatorsIndexed = keyBy(indi.indicators, 'id')
            const newIndicators = listParam.map(code => {
                const indiParam = indicatorsIndexed[code]
                if (isUndefined(indiParam)) {
                    return { id: parseInt(code) }
                }
                return indiParam
            })
            return {
                ...indi,
                indicators: newIndicators,
            }
        })
    }

    return (
        <SimpleSelectionTable
            onChange={onChange}

            listData={parametersFormatted}
            selectedList={selectedParameters}

            listHeaders={['code', 'name']}
            listTitle={i18n.nonSelectedParameters}
            selectedListTitle={i18n.selectedParameters}
            maxHeightTable={'43vh'}

            filterField={ParameterFilterField}
            filterFunction={(list, { selection = '-1', listParam, searchValue }) => {
                const filterSelection = selection !== '-1' ? intersectionWith(list, listParam, (elem, id) => elem.id === id) : list
                const searchValueFormat = searchAllCharacters(searchValue)
                return searchValue ? filterSelection.filter(p => p.labelSearch.includes(searchValueFormat)) : filterSelection
            }}
        />
    )
}

SelectParameters.propTypes = {
    indicator: PropTypes.shape({
        stationType: PropTypes.number,
        indicators: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number,
            threshold: PropTypes.number,
        })),
    }),
    setIndicator: PropTypes.func,
}

const SelectThresholds = ({
    indicator = {},
    setIndicator = () => { },
}) => {
    const {
        parameters,
        thresholds,
    } = useSelector(store => ({
        parameters: store.ParameterReducer.parameters,
        thresholds: store.QualityReducer.thresholds,
    }), shallowEqual)

    const indexedParameters = useListIndexed(parameters, 'code')

    const [selectedIndicators, setSelectedIndicators] = useState({}) // key: parameter code, value: boolean
    const [filter, setFilter] = useState({})
    const [threshold, setThreshold] = useState()

    const isSomeThresholdsSelected = Object.keys(selectedIndicators).some(key => selectedIndicators[key])

    const formattedThresholds = useMemo(() => {
        if (isUndefined(indicator.indicators)) {
            return []
        }
        return indicator.indicators.map(i => {
            const isSelected = selectedIndicators[i.id]
            const parameter = indexedParameters[i.id]

            return {
                code: i.id,
                parameter: parameter?.displayLabel,
                threshold: getLabel(thresholds, i.threshold),
                nullValue: <i className='material-icons clickable'>{isSelected ? 'check_box' : 'check_box_outline_blank'}</i>,
                color: isSelected ? '#b8d2ff' : '#fff',
                searchValue: [parameter?.name, parameter?.shortLabel, parameter?.code].map((label = '') => searchAllCharacters(label)),
            }
        })
    }, [indexedParameters, indicator.indicators, selectedIndicators, thresholds])


    const filteredThresholds = useMemo(() => {
        const { selection = '-1', listParam, searchValue } = filter
        const filterSelection = selection !== '-1' ? intersectionWith(formattedThresholds, listParam, (a, code) => a.code === code) : formattedThresholds
        const searchValueFormat = searchAllCharacters(searchValue)
        return searchValue ? filterSelection.filter(p => p.searchValue.some(v => v.includes(searchValueFormat))) : filterSelection
    }, [filter, formattedThresholds])

    const isAllThresholdSelected = filteredThresholds.every(th => selectedIndicators[th.code])

    const onClick = () => {
        setSelectedIndicators(prevSelected => {
            return filteredThresholds.reduce((acc, th) => {
                acc[th.code] = !isAllThresholdSelected
                return acc
            }, { ...prevSelected })
        })
    }

    const actions = filteredThresholds.length ? [{
        iconName: isAllThresholdSelected ? 'check_box' : 'check_box_outline_blank',
        tooltip: i18n.edit,
        onClick,
    }] : []

    return (
        <Grid container justifyContent='center' rowSpacing={1}>
            <Grid item xs={12}>
                <StyledFieldSet>
                    <StyledLegend>{i18n.filter}</StyledLegend>
                    <ParameterFilterField
                        filter={filter}
                        setFilter={setFilter}
                    />
                </StyledFieldSet>
            </Grid>
            <Grid item xs={12}>
                <Table
                    title={i18n.thresholds}
                    data={filteredThresholds}
                    actions={actions}
                    type={{ headers: ['code', 'parameter', 'threshold', 'nullValue'] }}
                    coloredLine
                    maxHeight={isSomeThresholdsSelected ? '37vh' : '43vh'}
                    onClick={({ code }) => setSelectedIndicators(prevSelected => ({ ...prevSelected, [code]: !prevSelected[code] }))}
                    sortable
                    condensed
                    paging
                    nbPerPageLabel={nbPerPageLabel}
                />
            </Grid>
            {
                isSomeThresholdsSelected && (
                    <Grid item xs={12}>
                        <StyledFieldSet>
                            <Grid container alignItems='center'>
                                <Grid item xs={6}>
                                    <Select
                                        options={thresholds}
                                        label={i18n.threshold}
                                        nullLabel='&nbsp;'
                                        onChange={setThreshold}
                                        value={threshold}
                                    />
                                </Grid>
                                <Grid item xs={3} />
                                <Grid item xs={3}>
                                    <Button
                                        onClick={() => {
                                            const thCode = threshold && parseInt(threshold)
                                            setIndicator(prevIndicators => ({
                                                ...prevIndicators,
                                                indicators: prevIndicators.indicators.map(i => {
                                                    if (selectedIndicators[i.id]) {
                                                        return { id: i.id, threshold: thCode }
                                                    }
                                                    return i
                                                }),
                                            }))
                                            setThreshold()
                                            setSelectedIndicators({})
                                        }}
                                        variant='contained'
                                        color='primary'
                                        fullWidth
                                    >
                                        {i18n.validate}
                                    </Button>
                                </Grid>
                            </Grid>
                        </StyledFieldSet>
                    </Grid>
                )
            }
        </Grid>
    )
}

SelectThresholds.propTypes = {
    indicator: PropTypes.shape({
        stationType: PropTypes.number,
        indicators: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number,
            threshold: PropTypes.number,
        })),
    }),
    setIndicator: PropTypes.func,
}

const StepperIndicator = ({
    isOpen = false,
    onClose = () => { },
    onSave = () => { },

    selectedIndicator = {},
}) => {
    const [indicator, setIndicator] = useState({})

    useEffect(() => {
        if (isOpen) {
            setIndicator(selectedIndicator)
        }
    }, [isOpen])

    const isThresholdComplete = useMemo(() => {
        if (isUndefined(indicator?.indicators)) {
            return false
        }
        return indicator?.indicators.some(t => isUndefined(t.threshold))
    }, [indicator])

    return (
        <StepperDialog
            steps={[
                {
                    label: i18n.parameters,
                    constant: STEP_PARAMETER,
                },
                {
                    label: i18n.thresholds,
                    constant: STEP_THRESHOLD,
                },
            ]}
            open={isOpen}
            title={i18n.updateIndicator}
            closeDialog={onClose}
            renderSaveButton={step => (step === STEP_THRESHOLD) && (
                <ButtonMUI
                    onClick={() => {
                        onSave(indicator)
                    }}
                    variant='contained'
                    disabled={isThresholdComplete}
                >
                    {i18n.register}
                </ButtonMUI>
            )}
            contentStyle={{ padding: '0 12' }}
        >
            {
                step => (
                    <>
                        {step === STEP_PARAMETER && (
                            <SelectParameters
                                indicator={indicator}
                                setIndicator={setIndicator}
                            />
                        )}
                        {step === STEP_THRESHOLD && (
                            <SelectThresholds
                                indicator={indicator}
                                setIndicator={setIndicator}
                            />
                        )}
                    </>
                )
            }
        </StepperDialog>
    )
}

StepperIndicator.propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    onSave: PropTypes.func,
    selectedIndicator: PropTypes.shape({
        stationType: PropTypes.number,
        indicators: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number,
            threshold: PropTypes.number,
        })),
    }),
}

const PopinIndicator = ({
    isOpen = false,
    indicator = {},
    validate = () => { },
    close = () => { },
}) => {
    const {
        thresholds,
        parameters,
    } = useSelector(store => ({
        thresholds: store.QualityReducer.thresholds,
        parameters: store.ParameterReducer.parameters,
    }), shallowEqual)

    const [threshold, setThreshold] = useState()

    useEffect(() => {
        if (isOpen) {
            setThreshold(indicator.threshold)
        }
    }, [isOpen, indicator.threshold])

    return (
        <DialogMUI
            maxWidth='lg'
            fullWidth
            open={isOpen}
            PaperProps={{
                sx: {
                    minHeight: undefined,
                    maxHeight: undefined,
                },
            }}
        >
            <DialogTitleMUI>
                <Grid container justifyContent='space-between' alignItems='center' style={{ padding: '0 20' }}>
                    <Grid item >
                        {i18n.indicator}
                    </Grid>
                    <Grid item>
                        <Icon style={{ color: 'white' }} size='small' icon='close' onClick={close} />
                    </Grid>
                </Grid>
            </DialogTitleMUI>
            <DialogContentMUI style={{ overflowX: 'hidden' }}>
                <StyledFieldSet>
                    <Grid container columnSpacing={2} rowSpacing={1} alignItems='center' style={{ paddingTop: '5px' }}>
                        <Grid item xs={6}>
                            <Select
                                label={i18n.parameter}
                                value={indicator.id}
                                options={parameters}
                                disabled
                                keyLabel='labelWithCode'
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Select
                                label={i18n.threshold}
                                options={thresholds}
                                value={threshold}
                                onChange={setThreshold}
                            />
                        </Grid>
                    </Grid>
                </StyledFieldSet>
            </DialogContentMUI>
            <DialogActionsMUI>
                <Grid container alignItems='center' justifyContent='right' columnSpacing={2} style={{ padding: '0 10' }}>
                    <Grid container item xs={3} justifyContent='flex-end'>
                        <Button
                            onClick={() => {
                                validate({
                                    id: indicator.id,
                                    threshold: parseInt(threshold),
                                })
                            }}
                            variant='contained'
                            color='primary'
                            disabled={isUndefined(threshold)}
                        >
                            {i18n.validate}
                        </Button>
                    </Grid>
                </Grid>
            </DialogActionsMUI>
        </DialogMUI>
    )
}

PopinIndicator.propTypes = {
    isOpen: PropTypes.bool,
    close: PropTypes.func,
    validate: PropTypes.func,
    indicator: PropTypes.shape({
        id: PropTypes.number,
        threshold: PropTypes.number,
    }),
}

export {
    Indicator,
    StepperIndicator,
    PopinIndicator,
}