import { push } from 'connected-react-router'
import HomeAction from 'home/actions/HomeAction'
import { SIEAU_TOKEN, URL_WANTED } from 'home/constants/HomeConstants'
import { isNil, orderBy } from 'lodash'
import i18n from 'simple-react-i18n'
import AppStore from 'store/AppStore'
import ToastrAction from 'toastr/actions/ToastrAction'
import WaitAction from 'wait/WaitAction'
import SieauAction from '../components/sieau/SieauAction'
import LogAction from '../log/actions/LogAction'
import { hasValue } from './NumberUtil'
import { removeNullKeys } from './StoreUtils'

const codes = {
    200: (res) => res,
    201: (res) => res,
    403: () => {
        const wantedUrl = window.location.href || ''
        const pathToPush = wantedUrl.substring(wantedUrl.indexOf('/#/') + 3)
        if (pathToPush && pathToPush !== 'login' && pathToPush !== 'home') {
            localStorage.setItem(URL_WANTED, pathToPush)
        }
        AppStore.dispatch({ type: 'RESET_ALL_STORE' })
        AppStore.dispatch(push('/login'))
        localStorage.removeItem(SIEAU_TOKEN)
        AppStore.dispatch(HomeAction.disconnected(i18n.disconnectedError))
        throw new Error('Not Authorized')
    },
    404: () => {
        throw new Error('404 Not Found')
    },
    409: () => {
        throw new Error('409 Conflict')
    },
    429: () => {
        AppStore.dispatch(ToastrAction.error(i18n.tooManyLoginRequests))
        throw new Error('429 Too Many Requests')
    },
    500: () => {
        throw new Error('500 error')
    },
}

const checkStatus = (obj, response) => {
    const code = Object.keys(obj).find(element => `${response.status}` === `${element}`)
    if (!code) {
        throw new Error(`Unhandled Error during fetch${response.status}`)
    }
    return obj[code](response)
}

const checkPostgresStatus = (obj, response) => {
    const code = Object.keys(obj).find(element => `${response.status}` === `${element}`)
    if (response.status === 406) {
        return response
    }
    if (!code) {
        throw new Error(`Unhandled Error during fetch${response.status}`)
    }
    return obj[code](response)
}

const fetchWithRetries = (url, options, retries = 2) => {
    const promise = fetch(url, options)
    return promise.then(response => {
        if (response.status === 502) {
            if (retries === 0) {
                return response
            }
            return fetchWithRetries(url, options, retries - 1)
        }
        return response
    })
}
const checkStatusBis = (obj, response) => {
    const code = Object.keys(obj).find(element => `${response.status}` === `${element}`)
    if (!code) {
        throw new Error(`Unhandled Error during fetch${response.statusText}`)
    }
    return obj[code](response)
}


const checkAuthBis = (response, overrideStatus = {}) => {
    return checkStatusBis({
        ...codes,
        ...overrideStatus,
    }, response)
}


const checkAuth = (response, overrideStatus = {}) => {
    return checkStatus({
        ...codes,
        ...overrideStatus,
    }, response)
}

const checkPostgresAuth = (response, overrideStatus = {}) => {
    return checkPostgresStatus({
        ...codes,
        ...overrideStatus,
    }, response)
}

const checkAuthWithoutKicking = (response, overrideStatus = { 403: () => {} }) => {
    return checkStatus({
        ...codes,
        ...overrideStatus,
    }, response)
}

const checkError = (json, errorCodeManagement = {}) => {
    if (json.error && errorCodeManagement[json.error]) {
        errorCodeManagement[json.error]()
    } else if (json.error) {
        AppStore.dispatch(WaitAction.waitStop())
        throw new Error(json.error)
    }
    return json
}

const catchError = (err, message = '') => {
    AppStore.dispatch(WaitAction.waitStop())
    AppStore.dispatch(LogAction.logError(`${i18n.fetchError + message} : ${err}`))
    AppStore.dispatch(ToastrAction.error(i18n.fetchError + message))
}

const getJson = response => response.json()

const getText = response => response.text()

const getAuthorization = () => ({
    Authorization: `Bearer ${localStorage.getItem(SIEAU_TOKEN)}`,
    // Module: 'WEB',
})

const getPayload = () => {
    const token = localStorage.getItem(SIEAU_TOKEN)
    if (token) {
        return atob(token.split('.')[1])
    }
    return ''
}

const getPutFetch = (route, data) => fetch(route, {
    method: 'PUT',
    headers: getAuthorization(),
    body: JSON.stringify(data),
}).then(checkAuth).then(getJson).then(checkError)

const promiseAllProgress = (promises, callback) => {
    let d = 0
    callback(0)
    promises.forEach((p) => {
        p.then(()=> {
            d++
            callback((d * 100) / promises.length)
        })
    })
    return Promise.all(promises)
}

const managePromises = (promises, dispatch, results, resolve, d, total, callbackProgress) => {
    if (promises.length) {
        if (AppStore.getState().SieauReducer.loadingData) {
            const p = promises.pop()
            p.then(r => {
                callbackProgress((d * 100) / total)
                managePromises(promises, dispatch, [...results, r], resolve, d+1, total, callbackProgress)
            })
        } else {
            AppStore.getState().SieauReducer.abortController.abort()
            dispatch(SieauAction.resetAbortSignal())
            resolve(results)
        }
    } else {
        dispatch(SieauAction.setLoadingData(false))
        resolve(results)
    }
}

const promiseAllWithCancel = (promises, callbackProgress, dispatch) => {
    callbackProgress(0)
    dispatch(SieauAction.setLoadingData(true))
    return new Promise((resolve) => managePromises(promises.reverse(), dispatch, [], resolve, 1, promises.length, callbackProgress))
}

const getAbortSignal = () => AppStore.getState().SieauReducer.abortSignal

// eslint-disable-next-line valid-jsdoc
/**
 * @deprecated since version 0.1
 */
const genericPromise = (url, method = 'GET', body = null, overrideStatus = {}) => {
    return fetch(url, removeNullKeys({
        method,
        headers: getAuthorization(),
        body: body ? JSON.stringify(body) : null,
    }))
        .then(r => checkAuth(r, overrideStatus))
        .then(getJson)
        .then(checkError)
}

const genericPromise2 = (url, options = {}, overrideStatus = {}) => {
    return fetch(url, {
        method: options.method ?? 'GET',
        body: options.body ? JSON.stringify(options.body) : undefined,
        headers: getAuthorization(),
        signal: options.signal,
    })
        .then(r => checkAuth(r, overrideStatus))
        .then(getJson)
        .then(checkError)
}

const genericPromiseFile = (url, options = {}, overrideStatus = {}) => {
    return fetch(url, {
        method: options.method ?? 'POST',
        body: options.body ? JSON.stringify(options.body) : undefined,
        headers: getAuthorization(),
        signal: options.signal,
    })
        .then(r => checkAuth(r, overrideStatus))
        .then(resp => resp.blob())
}

const genericUpdate = (url, action, body = null, method = 'PUT') => {
    return (dispatch) => {
        dispatch(WaitAction.waitStart())
        return fetch(url, removeNullKeys({
            method,
            headers: getAuthorization(),
            body: JSON.stringify(body),
        }))
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
            .then(json => {
                dispatch(WaitAction.waitStop())
                if (json.update >= 0 || json.insert >= 0) {
                    dispatch({ type: action, data: body })
                    dispatch(ToastrAction.success(i18n.elementUpdateSuccess))
                    return json
                }
                throw new Error()
            })
            .catch(err => {
                dispatch(WaitAction.waitStop())
                dispatch(LogAction.logError(`${i18n.updateError} : ${err}`))
                dispatch(ToastrAction.error(i18n.updateError))
            })
    }
}

const genericUpdatePromise = (promise) => {
    AppStore.dispatch(WaitAction.waitStart())
    return promise.then(json => {
        AppStore.dispatch(WaitAction.waitStop())
        if (json.update >= 0 || json.insert >= 0) {
            AppStore.dispatch(ToastrAction.success(i18n.elementUpdateSuccess))
            return json
        }
        throw new Error()
    })
        .catch(err => {
            AppStore.dispatch(WaitAction.waitStop())
            AppStore.dispatch(LogAction.logError(`${i18n.updateError} : ${err}`))
            AppStore.dispatch(ToastrAction.error(i18n.updateError))
        })
}

const genericCreate = (url, action, body=null, method='POST', callback = () => {}) => {
    return (dispatch) => {
        dispatch(WaitAction.waitStart())
        return fetch(url, removeNullKeys({
            method,
            headers: getAuthorization(),
            body: JSON.stringify(body),
        }))
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
            .then(json => {
                dispatch(WaitAction.waitStop())
                if (json.insert >= 0) {
                    dispatch({ type: action, data: body })
                    if (json.id) {
                        callback(json.id)
                    }
                } else {
                    throw new Error()
                }
            })
            .catch(err => {
                dispatch(WaitAction.waitStop())
                dispatch(LogAction.logError(`${i18n.createError} : ${err}`))
                dispatch(ToastrAction.error(i18n.createError))
            })
    }
}

const genericCreatePromise = (promise) => {
    AppStore.dispatch(WaitAction.waitStart())
    return promise.then(json => {
        AppStore.dispatch(WaitAction.waitStop())
        if (json.insert <= 0) {
            throw new Error()
        } else {
            AppStore.dispatch(ToastrAction.success(i18n.elementCreateSuccess))
        }
        return json
    })
        .catch(err => {
            AppStore.dispatch(WaitAction.waitStop())
            AppStore.dispatch(LogAction.logError(`${i18n.createError} : ${err}`))
            AppStore.dispatch(ToastrAction.error(i18n.createError))
        })
}

const genericDelete = (url, action, body=null, method='DELETE') => {
    return (dispatch) => {
        dispatch(WaitAction.waitStart())
        return fetch(url, removeNullKeys({
            method,
            headers: getAuthorization(),
            body: JSON.stringify(body),
        }))
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
            .then(json => {
                dispatch(WaitAction.waitStop())
                if (json.delete >= 0) {
                    dispatch({ type: action, data: body })
                } else {
                    throw new Error()
                }
            })
            .catch(err => {
                dispatch(WaitAction.waitStop())
                dispatch(LogAction.logError(`${i18n.deleteError} : ${err}`))
                dispatch(ToastrAction.error(i18n.deleteError))
            })
    }
}

const genericDeletePromise = (promise) => {
    AppStore.dispatch(WaitAction.waitStart())
    return promise.then(json => {
        AppStore.dispatch(WaitAction.waitStop())
        if (json.insert <= 0) {
            throw new Error()
        } else {
            AppStore.dispatch(ToastrAction.success(i18n.elementDeleteSuccess))
        }
        return json
    })
        .catch(err => {
            AppStore.dispatch(WaitAction.waitStop())
            AppStore.dispatch(LogAction.logError(`${i18n.deleteError} : ${err}`))
            AppStore.dispatch(ToastrAction.error(i18n.deleteError))
        })
}

const genericFetch = (promise, action, cb = () => {}) => {
    return (dispatch) => {
        return promise
            .then((json=[]) => {
                dispatch({ type: action, data: json })
                cb()
                return json
            })
            .catch(err => {
                dispatch(WaitAction.waitStop())
                dispatch(LogAction.logError(`${i18n.fetchError} : ${err}`))
                dispatch(ToastrAction.error(i18n.fetchError))
            })
    }
}

const genericFetchNew = (promise, receiveFunc) => {
    return (dispatch) => {
        return promise
            .then((json=[]) => {
                dispatch(receiveFunc(json))
                return json
            })
            .catch(err => {
                dispatch(WaitAction.waitStop())
                dispatch(LogAction.logError(`${i18n.fetchError} : ${err}`))
                dispatch(ToastrAction.error(i18n.fetchError))
            })
    }
}

const getStationArrowNav = (stationType, stations, currentId, onClick) => {
    const searchValues = AppStore.getState().AdministrationReducer.selectedSearchValues[stationType]
    const array = searchValues && searchValues.stations ? searchValues.stations : orderBy(stations, o => hasValue(o.name) ? o.name.toString().toUpperCase() : '}')
    return {
        currentId,
        array,
        labels: ['code', 'name'],
        onClick,
    }
}

const callPromisesSequentiallyRec = (listOfPromiseFunctions = [], listOfParams, callbackProgress, results, lengthTotal, resolved, callBackEnd = () => { }) => {
    if (!listOfPromiseFunctions.length) {
        callBackEnd(results)
    } else {
        const [headPromise, ...restPromises] = listOfPromiseFunctions
        const [headParams, ...restParams] = listOfParams
        if (AppStore.getState().SieauReducer.loadingData) {
            headPromise(...headParams).then(res => {
                callbackProgress(((resolved + 1) * 100) / lengthTotal)
                callPromisesSequentiallyRec(restPromises, restParams, callbackProgress, [...results, res], lengthTotal, resolved + 1, callBackEnd)
            })
        } else {
            callBackEnd(results)
        }
    }
}

const callPromisesSequentially = (listOfPromiseFunctions = [], listOfParams, callbackProgress, callBackEnd) => {
    callbackProgress(0)
    AppStore.dispatch(SieauAction.setLoadingData(true))
    callPromisesSequentiallyRec(listOfPromiseFunctions, listOfParams, callbackProgress, [], listOfPromiseFunctions.length, 0, callBackEnd)
}

/*
list: object light
keys: list of keys
specificFormat: object of function to format a specific value
*/
const arrayLightToObject = (list = [], keys = [], specificFormat = {}) => keys.reduce((res, key, index) => {
    const formatFunction = specificFormat[key]
    const value = isNil(list[index]) ? undefined : list[index]
    res[key] = formatFunction ? formatFunction(value) : value
    return res
}, {})

export {
    checkAuth, getJson, checkAuthBis, checkStatus, getAuthorization, getPayload, getText, checkError, getPutFetch, promiseAllProgress, fetchWithRetries, catchError,
    checkAuthWithoutKicking, genericPromise, genericFetch, genericUpdate, genericCreate, genericDelete, getStationArrowNav, promiseAllWithCancel, getAbortSignal, checkPostgresAuth, callPromisesSequentially,
    genericUpdatePromise, genericCreatePromise, genericPromise2, genericFetchNew, genericDeletePromise, genericPromiseFile, arrayLightToObject,
}
