import { AccordionDetails, Button, Dialog, Grid } from '@mui/material'
import { makeStyles } from '@mui/styles'
import Input from 'components/forms/Input'
import Select from 'components/forms/Select'
import SimpleDatePicker from 'components/forms/SimpleDatePicker'
import Textarea from 'components/forms/Textarea'
import Icon from 'components/icon/Icon'
import TabList from 'components/list/TabList'
import { AccordionMUI, AccordionSummaryMUI } from 'components/styled/Accordions'
import HydrometryAction from 'hydrometry/actions/HydrometryAction'
import DtoHydrometryObservation from 'hydrometry/dto/DtoHydrometryObservation'
import { groupBy, maxBy, omit, orderBy, template, uniq } from 'lodash'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { useEffect, useMemo, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { SANDRE } from 'referencial/constants/ReferencialConstants'
import i18n from 'simple-react-i18n'
import useSandreList from 'utils/customHook/useSandreList'
import { getDateWithHour, getFullDate, getHour, getYearOrString } from 'utils/DateUtil'
import { onChangeHour } from 'utils/FormUtils'
import { getModalite } from 'utils/HydroUtils'
import { getLinks, getStationTitle } from 'utils/StationUtils'
import { getI18nTitleDataLength, getSandreLabel } from 'utils/StringUtil'
import ObservationFilter from './ObservationFilter'
import ObservationCard from './ObservationCard'
import useActions from 'utils/customHook/useActions'
import { getStationArrowNav } from 'utils/ActionUtils'
import { push } from 'connected-react-router'
import useTitle from 'utils/customHook/useTitle'
import { getUser } from 'utils/SettingUtils'
import LastObservationsPanel from './LastObservationsPanel'
import { DialogActionsMUI, DialogContentMUI, DialogTitleMUI } from 'components/styled/Dialog'
import ProgressCard from 'components/card/ProgressCard'
import EventsAction from 'events/actions/EventsAction'
import { STATION_TYPE_CONSTANT, STATION_TYPE_NAME } from 'station/constants/StationConstants'
import { PICTURE, PICTURES_EXTENSIONS } from 'components/file/constant/FileConstants'
import { formatedFileName, getFileExtension } from 'utils/FileUtils'
import StationAction from 'station/actions/StationAction'

const ObservationDialog = ({
    open,
    onClose = () => {},
    stationId,
}) => {
    const [picture, setPicture] = useState()

    const {
        hydrometricStation,
        hydrometryCampaigns,
        hydrometryCampaignStations,
        sandreCodes,
    } = useSelector(store => ({
        hydrometricStation: store.HydrometryReducer.hydrometricStation,
        hydrometryCampaigns: store.HydrometryReducer.hydrometryCampaigns,
        hydrometryCampaignStations: store.HydrometryReducer.hydrometryCampaignStations,
        sandreCodes: store.ReferencialReducer.sandreCodes,
    }), shallowEqual)

    const dispatch = useDispatch()

    const campaignsList = useMemo(() => {
        return hydrometryCampaigns.filter((c) => c.statut === 2
            && hydrometryCampaignStations.some((s) => s.campaignId === c.id && s.stationId === stationId))
    }, [stationId, hydrometryCampaigns, hydrometryCampaignStations])

    const observationTypes = useSandreList(SANDRE.HYDRO_OBSERVATIONS)
    const observationModalites4 = useSandreList(SANDRE.HYDRO_OBSERVATIONS_MODALITES_4)
    const observationModalites5 = useSandreList(SANDRE.HYDRO_OBSERVATIONS_MODALITES_5)

    const initialObservation = {
        date: moment().valueOf(),
        hour: moment().valueOf(),
        siteCode: stationId,
        observationType: SANDRE.HYDRO_OBSERVATIONS_MODALITES_5_ID,
        campaignCode: campaignsList.length === 1 ? campaignsList[0].id : undefined,
    }

    const [newObservation, onSetObservation] = useState(initialObservation)

    const isConditionValidated = !!newObservation.observationType && !!newObservation.date && !!newObservation.hour && !!newObservation.observationCode
    const observationValeurs = getModalite(newObservation.observationType, observationModalites4, observationModalites5)

    const onChangeObservation = (changes) => {
        onSetObservation({ ...newObservation, ...changes })
    }

    const onUploadFile = (event) => {
        // Read File
        if (event.target.files && event.target.files[0]) {
            let reader = new FileReader()
            const file = event.target.files[0]
            reader.onload = (e) => {
                setPicture({
                    stationType: STATION_TYPE_CONSTANT.HYDROMETRY,
                    stationId,
                    path: '',
                    name: file.name,
                    content: e.target.result,
                    fileType: PICTURE,
                    active: 1,
                    extension: file.name && getFileExtension(file.name),
                })
            }
            reader.readAsDataURL(event.target.files[0])
        }
    }

    const addObservation = () => {
        const observationDate = getDateWithHour(newObservation.date, newObservation.hour).valueOf()
        const observationFormated = { ...newObservation, observationDate }
        const pictureName = picture && `${hydrometricStation.code}_OBSERVATION_1_${moment().format('YYYYMMDD-HHmmss')}_HYDROMETRY_${formatedFileName(picture?.formattedName)}.${picture.extension}`
        if (picture) {
            dispatch(StationAction.addStationFile({
                stationType: STATION_TYPE_CONSTANT.HYDROMETRY,
                stationId,
                path: 'PHOTOS/',
                name: pictureName,
                content: picture.content,
            }, PICTURE))
            dispatch(StationAction.fetchPictures(hydrometricStation.code, STATION_TYPE_CONSTANT.HYDROMETRY))
        }
        dispatch(EventsAction.addEvent(
            STATION_TYPE_NAME.hydrometry,
            stationId,
            {
                bankExport: '0',
                chgtpiles: '0',
                code: stationId,
                comment: template(i18n.observationEventComment)({
                    observationCateg: getSandreLabel(sandreCodes, SANDRE.HYDRO_OBSERVATIONS, newObservation.observationType),
                    observationType: observationValeurs.find((m) => m.code === Number(newObservation.observationCode))?.name ?? '',
                }),
                date: observationDate,
                endDate: observationDate,
                eventDate: observationDate,
                eventHour: observationDate,
                eventType: 'C',
                graph: '0',
                razstation: '0',
                campaignCode: newObservation.campaignCode,
            },
            (idEvent) => dispatch(HydrometryAction.createHydrometerObservation({ ...observationFormated, idEvent, pictureName })).then(() => {
                onClose()
                onSetObservation(initialObservation)
                dispatch(HydrometryAction.fetchHydrometryObservations(stationId))
            }),
        ))
    }

    return (
        <Dialog
            onClose={onClose}
            fullWidth
            maxWidth='lg'
            open={open}
        >
            <DialogTitleMUI sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                {i18n.newObservation}
                <Icon
                    sx={{ color: 'white' }}
                    size='small'
                    icon='close'
                    onClick={onClose}
                />
            </DialogTitleMUI>
            <DialogContentMUI className='padding-top-1 no-margin'>
                <Grid container spacing={1}>
                    <Grid item xs={3}>
                        <SimpleDatePicker
                            col={12}
                            obligatory
                            label={i18n.date}
                            value={newObservation.date}
                            max={moment().valueOf()}
                            onChange={ v => onChangeObservation({ date: v })}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <Input
                            col={12}
                            obligatory
                            value={getHour(newObservation.hour)}
                            title={i18n.startHour}
                            onChange={v => onChangeHour(v, v2 => onChangeObservation({ hour: v2 }))}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Select
                            col={ 12 }
                            noSort={true}
                            options={ campaignsList }
                            label={ i18n.campaign }
                            value={ newObservation.campaignCode }
                            onChange={ v => onChangeObservation({ campaignCode: v }) }
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Select
                            noNullValue
                            obligatory
                            col={ 12 }
                            noSort={true}
                            options={ observationTypes }
                            label={ i18n.observationType }
                            value={ newObservation.observationType }
                            onChange={ v => onChangeObservation({ observationType: v }) }
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Select
                            obligatory
                            col={ 12 }
                            noSort={true}
                            options={ observationValeurs }
                            label={ i18n.value }
                            value={ newObservation.observationCode }
                            onChange={ v => onChangeObservation({ observationCode: v?.toString() }) }
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Textarea col={12} value={newObservation.comment} title={i18n.comment} onChange={v => onChangeObservation({ comment: v })} />
                    </Grid>
                    <Grid item xs={12}>
                        <div className='col s12 file-field input-field'>
                            <div className='btn'>
                                <span>{ i18n.importLabel }</span>
                                <input
                                    accept={PICTURES_EXTENSIONS.join(', ')}
                                    id='uploadFile'
                                    type='file'
                                    onChange={onUploadFile}
                                />
                            </div>
                            <div className='file-path-wrapper'>
                                <input className='file-path validate no-margin' type='text' />
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </DialogContentMUI>
            <DialogActionsMUI>
                <Button disabled={!isConditionValidated} onClick={() => addObservation()} variant='contained' color='primary'>
                    {i18n.validate}
                </Button>
            </DialogActionsMUI>
        </Dialog>
    )
}

ObservationDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    stationId: PropTypes.number,
}

const useStyles = makeStyles(() => ({
    root: {
        fontSize: '15px',
        fontWeight: 'bold',
        minHeight: 40,
        maxHeight: 40,
        '&.Mui-expanded': {
            minHeight: 40,
            maxHeight: 40,
        },
    },
}))

const HydrometerObservationsApp = () => {
    const classes = useStyles()
    const {
        hydrometryObservations,
        hydrometricStation,
        hydrometricStations,
    } = useSelector(store => ({
        hydrometryObservations: store.HydrometryReducer.hydrometryObservations,
        hydrometricStation: store.HydrometryReducer.hydrometricStation,
        hydrometricStations: store.HydrometryReducer.hydrometricStations,
    }), shallowEqual)

    const stationId = hydrometricStation.id

    const [observationsLoaded, setObservationLoaded] = useState(false)
    const [openPopup, setPopupAddObservation] = useState(false)
    const [onGroupBy, setGroupBy] = useState('date')
    const [observationFilter, setObservationFilter] = useState()

    const observationTypes = useSandreList(SANDRE.HYDRO_OBSERVATIONS)
    const observationModalites4 = useSandreList(SANDRE.HYDRO_OBSERVATIONS_MODALITES_4)
    const observationModalites5 = useSandreList(SANDRE.HYDRO_OBSERVATIONS_MODALITES_5)

    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(HydrometryAction.fetchHydrometryObservations(stationId)).then(() => setObservationLoaded(true))
    }, [])

    const exportObservations = () => {
        const headers = ['identifier', 'station', 'date', 'typeCode', 'observationType', 'valueCode', 'value', 'comment', 'updateLogin', 'updateDate']
        const observationsToExport = hydrometryObservations.map((obsv, i) => {
            const observationType = observationTypes.find(sandreCode => sandreCode.code === obsv.observationType)?.name
            const modalite = getModalite(obsv.observationType, observationModalites4, observationModalites5)
            const value = modalite.find(sandreCode => sandreCode.code === parseInt(obsv.observationCode))?.name
            const observationFormated = {
                ...obsv,
                observationType,
                typeCode: obsv.observationType,
                value,
                valueCode: obsv.observationCode,
                identifier: hydrometricStation.code,
                station: hydrometricStation.name,
                updateDate: getFullDate(obsv.updateDate),
                date: getFullDate(obsv.observationDate),
                code: obsv.observationCode,
            }
            return i === 0 ? { headers, ...observationFormated } : observationFormated
        })
        return {
            data: observationsToExport,
            exportType: 'xlsx',
            titleFile: `${i18n.observations} - ${getStationTitle(hydrometricStation)}`,
        }
    }

    useTitle(() => [{
        title: i18n.hydrometry,
        href: 'hydrometry',
    }, {
        title: getStationTitle(hydrometricStation),
        href: `station/hydrometry/${stationId}`,
    }, {
        title: i18n.observations,
        href: `station/hydrometry/${stationId}/observations`,
    }], [hydrometricStation])

    useActions(() => {
        const actions = {
            new: () => setPopupAddObservation(true),
            export: () => exportObservations(),
            links: getLinks(hydrometricStation, this),
            arrowNav: getStationArrowNav('hydrometry', hydrometricStations, stationId, s => dispatch(push(`/station/hydrometry/${s.id}/observations`))),
        }
        return getUser().consultant === '1' ? omit(actions, 'new') : actions
    }, [hydrometricStation, hydrometricStations, hydrometryObservations])

    const lastObservation = maxBy(hydrometryObservations, observation => observation.observationDate ? observation.observationDate : 0)

    const observationsHistoric = useMemo(() => {
        return lastObservation ? orderBy(hydrometryObservations, ['observationDate', 'updateDate'], ['desc', 'desc'])
            .filter(observation => observation.observationDate !== lastObservation.observationDate) : []
    }, [lastObservation, hydrometryObservations])

    const observationFiltered = useMemo(() => {
        if (!observationFilter) {
            return observationsHistoric
        }
        const filterByStartDate = observationFilter.startDate ? observationsHistoric.filter(o => o.observationDate > observationFilter.startDate) : observationsHistoric
        const filterByEndDate = observationFilter.endDate ? filterByStartDate.filter(o => o.observationDate < observationFilter.endDate) : filterByStartDate
        const filterByValue = observationFilter.observationValues?.length ? filterByEndDate.filter(o => {
            const modalites = getModalite(o.observationType, observationModalites4, observationModalites5)
            const modalite = modalites.find(sandreCode => `${sandreCode.code}` === o.observationCode)
            return observationFilter.observationValues.includes(modalite?.reference)
        }) : filterByEndDate
        if ([1, 2].includes(observationFilter.modalitesFilter)) {
            return filterByValue.filter(t => t.observationType === observationFilter.modalitesFilter)
        }
        return filterByValue
    }, [observationsHistoric, observationFilter, observationModalites4, observationModalites5])

    const dataGroupBy = useMemo(() => {
        switch (onGroupBy) {
            case 'date': default: return { groups: uniq(observationFiltered.map(o => getYearOrString(o.observationDate, i18n.thisYear, '').toString())), dataGroup: groupBy(observationFiltered, observation => getYearOrString(observation.observationDate, i18n.thisYear, '')) }
            case 'modalite': return { groups: uniq(observationFiltered.map(o => observationTypes.find(sandreCode => sandreCode.code === o.observationType)?.name)), dataGroup: groupBy(observationFiltered, observation => observationTypes.find(sandreCode => sandreCode.code === observation.observationType)?.name) }
        }
    }, [observationFiltered, onGroupBy, observationTypes])

    return (
        <div className='padding-1'>
            {observationsLoaded ?
                <div>
                    <ObservationFilter onValidateFilter={setObservationFilter}/>
                    <LastObservationsPanel readMode={false} />
                    <TabList
                        onChangeTab={(v) => setGroupBy(v)}
                        tabs={[
                            {
                                value: 'date',
                                label: i18n.date,
                                icon: 'insert_invitation',
                            },
                            {
                                value: 'modalite',
                                label: i18n.modality,
                                icon: 'remove_red_eye',
                            },
                        ]}
                    >
                        {observationFiltered.length ?
                            <>
                                { dataGroupBy.groups.map((group, i) => {
                                    const data = dataGroupBy.dataGroup[group]
                                    return (
                                        <div className='padding-1' key={i}>
                                            <AccordionMUI
                                                defaultExpanded={i === 0}
                                                TransitionProps={{ unmountOnExit: true }}
                                            >
                                                <AccordionSummaryMUI
                                                    classes={classes}
                                                >
                                                    {`${group} (${data.length} ${getI18nTitleDataLength(i18n.element, i18n.elements, data.length)})`}
                                                </AccordionSummaryMUI>
                                                <AccordionDetails style={{ padding: '0px' }}>
                                                    {data.map(observation => <ObservationCard observation={observation}/>)}
                                                </AccordionDetails>
                                            </AccordionMUI>
                                        </div>
                                    )
                                })}
                            </> :
                            <div style={ { padding: '5%' } }>
                                <div className='col s12 text-align-center'>
                                    <Icon size='Medium' style={{ color: 'black' }} icon='remove_red_eye'/>
                                </div>
                                <div className='center font-size-20'>{ i18n.noResults }</div>
                            </div>
                        }
                    </TabList>
                </div>
                :
                <ProgressCard indeterminate withMessage round/>
            }
            <ObservationDialog
                stationId={stationId}
                open={openPopup}
                onClose={() => setPopupAddObservation(false)}
            />
        </div>
    )
}

HydrometerObservationsApp.propTypes = {
    observation: PropTypes.instanceOf(DtoHydrometryObservation),
}

export default HydrometerObservationsApp