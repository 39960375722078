import ActionComponent from 'components/ActionComponent'
import download from 'downloadjs'
import { PATH_ADMINISTRATION, PATH_ADMINISTRATION_MODELS } from 'home/constants/RouteConstants'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import Table from '../../../components/datatable/Table'
import HomeAction from 'home/actions/HomeAction'
import { contentsPath } from '../../../conf/basepath'
import { nbPerPageLabel } from '../../../referencial/constants/ReferencialConstants'
import StationAction from '../../../station/actions/StationAction'
import DtoPublicPicture from '../../../station/dto/DtoPublicPicture'
import { getUser } from '../../../utils/SettingUtils'
import AdministrationAction from '../../actions/AdministrationAction'
import DtoModelUpload from './DtoModelUpload'
import { MODELS_TYPES } from './ModelConstants'
import PopupAddModel from './PopupAddModel'
import ProgressCard from 'components/card/ProgressCard'
import { keys } from 'lodash'

class ModelApp extends ActionComponent {
    constructor(props) {
        super(props)
        this.state = {
            dataLoaded: false,
            models: [],
            modelsToDelete: [],
            open: false,
            modelsToAdd: [],
            stationTypeToAdd: 'installation',
        }
    }

    componentDidMount() {
        if (!this.props.allModelsByType.length) {
            this.props.fetchAllModelsByType(MODELS_TYPES.map(model => model.stationType)).then(() => {
                this.getModelsDataTable()
            })
        } else {
            this.getModelsDataTable()
        }
        this.props.setTitle([{
            title: i18n.administration,
            href: PATH_ADMINISTRATION,
        }, {
            title: i18n.models,
            href: PATH_ADMINISTRATION_MODELS,
        }])
        this.setReadOnlyMode()
    }

    setEditMode = () => {
        const actions = {
            new: () => {
                this.setState({ open: true })
            },
            cancel: () => {
                this.setState({ models: this.formatModels(), modelsToDelete: [] })
                this.setReadOnlyMode()
            },
        }
        if (!(getUser().consultant === '1')) {
            actions.save = () => {
                this.deleteModels()
                this.setReadOnlyMode()
            }
        }
        this.setActions(actions)
    }

    setReadOnlyMode = () => {
        this.setState({ disabled: true })
        if (!(getUser().consultant === '1')) {
            const actions = {
                new: () => {
                    this.setState({ open: true })
                },
                edit: () => {
                    this.setState({ disabled: false })
                    this.setEditMode()
                },
            }
            this.setActions(actions)
        } else {
            this.setActions({})
        }
    }

    addModel = () => {
        const { modelsToAdd, stationTypeToAdd: stationType } = this.state
        this.setState({ open: false, dataLoaded: false })
        Promise.all(
            modelsToAdd.map((modelToAdd) => this.props.addModelFile({ ...modelToAdd, stationType })),
        ).then(() => {
            const stationsTypes = MODELS_TYPES.map(model => model.stationType)
            this.props.fetchAllModelsByType(stationsTypes).then(() => {
                this.getModelsDataTable()
            })
        })
    }

    onUploadFile = (event) => {
        // Read File
        const files = event.target?.files
        if (files?.length) {
            keys(files).forEach(key => {
                const file = files[key]
                let reader = new FileReader()
                reader.onload = (e) => {
                    this.setState(({ modelsToAdd, stationTypeToAdd }) => {
                        return {
                            modelsToAdd: [
                                ...modelsToAdd,
                                new DtoModelUpload({
                                    stationType: stationTypeToAdd,
                                    content: e.target.result,
                                    name: file.name.replace(/ /g, '_').normalize('NFD').replace(/[\u0300-\u036f]/g, ''),
                                }),
                            ],
                        }
                    })
                }
                reader.readAsDataURL(file)
            })
        }
    }

    deleteModels = () => {
        const { modelsToDelete } = this.state
        if (modelsToDelete?.length > 0) {
            this.setState({ dataLoaded: false })
            const modelsToDeleteFormatted = modelsToDelete.map((model) => ({
                name: model.name,
                fileType: 'model',
                stationType: model.stationType,
            }))
            this.props.deleteFiles(modelsToDeleteFormatted).then(() => {
                const stationsTypes = MODELS_TYPES.map(model => model.stationType)
                this.props.fetchAllModelsByType(stationsTypes).then(() => {
                    this.getModelsDataTable()
                    this.setState({ modelsToDelete: [] })
                })
            })
        }
    }

    removeModel = (model) => {
        const { models, modelsToDelete } = this.state
        const newModels = models.filter((m) => (m.name !== model.name || m.url !== model.url))
        this.setState({ models: newModels, modelsToDelete: [...modelsToDelete, model] })
    }

    getUrlModel = (model) => {
        return `${contentsPath.substring(0, contentsPath.length-1)}${model.url}${model.name}`
    }

    formatModels = () => {
        const { allModelsByType } = this.props
        return allModelsByType.map((model) => ({
            ...model,
            directory: model.url,
            model: (<span style={{ cursor: 'pointer' }} onClick={() => download(this.getUrlModel(model))}>{model.name}</span>),
            nullValue: (<i className='material-icons clickable' onClick={() => this.removeModel(model)}>close</i>),
        }))
    }

    getModelsDataTable = () => {
        this.setState({ models: this.formatModels(), dataLoaded: true })
    }

    render() {
        const { dataLoaded, models, disabled, open, stationTypeToAdd } = this.state
        const headers = disabled ? ['directory', 'model'] : ['directory', 'model', 'nullValue']
        return (
            <div className='row no-margin'>
                <div className='col s12 padding-top-1'>
                    {dataLoaded ? (
                        <Table
                            title={i18n.models}
                            condensed sortable paging
                            nbPerPageLabel={nbPerPageLabel}
                            filter={this.state.searchValue}
                            data={models}
                            type={{ headers }}
                            round
                        />
                    ) : <ProgressCard indeterminate round />}
                </div>
                {open && (
                    <PopupAddModel
                        openDialog={open}
                        stationType={stationTypeToAdd}
                        changeStationType={(value) => this.setState({ stationTypeToAdd: value })}
                        onUploadFile={event => this.onUploadFile(event)}
                        addModel={(model) => this.addModel(model)}
                        closeDialog={() => this.setState({ open: false, modelsToAdd: [] })}
                    />
                )}
            </div>
        )
    }
}

ModelApp.propTypes = {
    setTitle: PropTypes.func,
    allModelsByType: PropTypes.arrayOf(PropTypes.instanceOf(DtoPublicPicture)),
    fetchAllModelsByType: PropTypes.func,
    addModelFile: PropTypes.func,
    deleteFile: PropTypes.func,
}

const mapStateToProps = store => ({
    allModelsByType: store.AdministrationReducer.allModelsByType,
})

const mapDispatchToProps = {
    setTitle: HomeAction.setTitle,
    fetchAllModelsByType: AdministrationAction.fetchAllModelsByType,
    addModelFile: AdministrationAction.addModelFile,
    deleteFiles: StationAction.deleteFiles,
}

export default connect(mapStateToProps, mapDispatchToProps)(ModelApp)