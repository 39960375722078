import { uniq } from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import ToastrAction from 'toastr/actions/ToastrAction'
import Table from '../../../../components/datatable/Table'
import RadioButtons from '../../../../components/forms/RadioButtons'
import { nbPerPageLabelTiny } from '../../../../referencial/constants/ReferencialConstants'
import AppStore from '../../../../store/AppStore'
import Job from '../../../dto/DtoJob'
import { Buffer } from 'buffer'

class EstablishmentPanel extends Component {
    getFilters = () => {
        const newFilters = this.props.job.parameters.filters.length ? JSON.parse(this.props.job.parameters.filters[0]) : {}
        return { codes: [], codesType: 'siret', ...newFilters }
    }

    onChangeFilters = (changes) => {
        const parameters = {
            ...this.props.job.parameters,
            filters: [JSON.stringify({
                ...this.getFilters(),
                ...changes,
            })],
        }
        this.props.onChangeJob({ parameters })
    }

    onDeleteCode = ({ code }) => {
        if (this.props.isEditMode) {
            const filters = this.getFilters()
            this.onChangeFilters({ codes: filters.codes.filter(p => p !== code) })
        }
    }

    addCode = () => {
        if (this.props.isEditMode) {
            const filters = this.getFilters()
            const code = this.refs.txtaddCode.value
            const exist = filters.codes.find(p => p.toLowerCase() === code.toLowerCase())
            if (!code) {
                AppStore.dispatch(this.props.toastError(i18n.youMustEnterAtLeastOneBssCode))
            } else if (exist) {
                AppStore.dispatch(this.props.toastError(i18n.youHaveAlreadyEnteredThisBssCode))
            } else {
                this.onChangeFilters({ codes: [ code, ...filters.codes ] })
                this.refs.txtaddCode.value = ''
            }
        }
    }

    onChangeFile = e => {
        if (this.props.isEditMode) {
            const reader = new FileReader()
            const file = e.target.files[0]
            reader.onload = upload => {
                const split = upload.target.result.split(',')
                if (split[0].indexOf('text/csv') > 0 || split[0].indexOf('vnd.ms-excel') > 0) {
                    const buf = Buffer.from(split[1], 'base64').toString()
                    const codes = buf.replace(/;/g, '').split('\n').map(b => b.trim())
                    const filteredCodes = codes.filter((c) => c !== '')
                    const filters = this.getFilters()
                    this.onChangeFilters({ codes: uniq([ ...filters.codes, ...filteredCodes ]) })
                } else {
                    AppStore.dispatch(this.props.toastError(i18n.theSelectedFileMustBeInCsvFormat))
                }
                this.refs.importFile.value = ''
            }
            reader.readAsDataURL(file)
        }
    }

    getCodes = () => this.getFilters().codes.map(code => {
        return { code }
    })

    render() {
        const codesTypes = [
            { code: 'siret', name: i18n.siret },
            { code: 'city', name: i18n.cities },
            { code: 'department', name: i18n.departments },
        ]
        const disabled = { disabled: !this.props.isEditMode }
        const filters = this.getFilters()
        return (
            <div>
                <div className='row no-margin valign-wrapper'>
                    <RadioButtons className='no-margin' col={ 3 } title={ i18n.codificationType } elements={ codesTypes } onChange={ v => this.onChangeFilters({ codesType: v }) } selected={ filters.codesType } {...disabled}/>
                </div>
                <div className='row no-margin valign-wrapper'>
                    <div className='col s3'><label>{ i18n.importFile }</label></div>
                    <div className='file-field col s9 no-padding input-field'>
                        <div className='col s3 no-padding btn'>
                            <span>{ i18n.importLabel }</span>
                            <input type='file' ref='importFile' accept='.csv' onChange={ this.onChangeFile }{...disabled}/>
                        </div>
                        <div className='file-path-wrapper col s9'>
                            <input className='file-path' ref='fileInput' type='text' placeholder={ i18n.selectFile }{...disabled}/>
                        </div>
                    </div>
                </div>
                <div className='row valign-wrapper'>
                    <div className='col s3'><label>{ i18n.addCode }</label></div>
                    <div className='input-field col s9 no-padding'>
                        <div className='col s8'>
                            <input id='txtaddCode' type='text' placeholder={ i18n.addCode } ref='txtaddCode'/>
                            <label className='tinyLabel'>{ i18n.theCodeMustBePresentInTheRepositoryAndDefinedByTheCode }</label>
                        </div>
                        <a className='waves-effect waves-light btn col offset-s1 s3' onClick={ this.addCode }>{ i18n.add }</a>
                    </div>
                </div>
                <div className='row valign-wrapper'>
                    <div className='input-field col s12'>
                        <Table
                            type={ { code: '' } }
                            data={ this.getCodes() }
                            title={ i18n.cities }
                            nbPerPageLabel={ nbPerPageLabelTiny }
                            onDelete={ this.onDeleteCode }
                            deletable={ this.props.isEditMode }
                            showNbElements
                            activeHeader
                            paging
                            exportButtonOnHeader
                        />
                    </div>
                </div>
            </div>
        )
    }
}

EstablishmentPanel.propTypes = {
    job: PropTypes.instanceOf(Job).isRequired,
    isEditMode: PropTypes.bool,
    onChangeJob: PropTypes.func,
    toastError: PropTypes.func,
}

const mapDispatchToProps = {
    toastError: ToastrAction.error,
}

export default connect(null, mapDispatchToProps)(EstablishmentPanel)
