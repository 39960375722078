import { push } from 'connected-react-router'
import HomeAction from 'home/actions/HomeAction'
import { omit, orderBy, uniq } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import ActionComponent from '../../../components/ActionComponent'
import Card from '../../../components/card/Card'
import Table from '../../../components/datatable/Table'
import Checkbox from '../../../components/forms/Checkbox'
import Switch from '../../../components/forms/Switch'
import SieauAction from '../../../components/sieau/SieauAction'
import { PATH_ADMINISTRATION, PATH_ADMINISTRATION_CARTOGRAPHY } from '../../../home/constants/RouteConstants'
import { sieauTooltip } from '../../../utils/FormUtils'
import { getUser } from '../../../utils/SettingUtils'
import { getLabel, getMapStateToProps, getPropTypes } from '../../../utils/StoreUtils'
import AdministrationAction from '../../actions/AdministrationAction'
import { LAYER_TYPES } from '../../constants/AdministrationConstants'
import CartographyThemeDto from '../../dto/CartographyThemeDto'
import { homeListOfSelectedLayers } from 'carto/constants/CartoConstants'
import UserAction from '../user/actions/UserAction'
import DtoUserLayerAssignment from '../user/dto/DtoUserLayerAssignment'
import LayerRightsModal from './LayerRightsModal'
import LayerTestPanel from './LayerTestPanel'
import MapAdminPanel from './MapAdminPanel'
import SettingDto from 'administration/dto/SettingDto'
import { withStyles } from '@mui/styles'
import { Grid } from '@mui/material'
import Input from 'components/forms/Input'
import { AccordionMUI, AccordionSummaryMUI } from 'components/styled/Accordions'
import UserDto from '../user/dto/UserDto'

const TAB_THEME = 'themes'
const TAB_MAP = 'map'

const storeProps = {
    themeLayers: true,
    users: true,
}

const styles = (theme) => ({
    tab: {
        fontWeight: 'bold',
    },
    tabSelected: {
        fontWeight: 'bold',
        backgroundColor: `${theme.palette.third.main} !important`,
        color: 'white !important',
    },
    card: {
        border: `2px solid ${theme.palette.third.main}`,
    },
})

class CartographyApp extends ActionComponent {
    constructor(props) {
        super(props)
        this.state = {
            panel: 'file',
            usersLoaded: false,
            layers: [],
            allUsers: [],
            isEditMode: false,
            openSelectUsersModal: false,
            theme: null,
            searchValue: '',
            tab: TAB_THEME,
        }
    }

    componentWillMount() {
        this.props.setHelpLink('administration', '90')
        this.props.fetchAdminCartographyThemes()
        this.props.sieauFetch(storeProps)
        this.props.fetchUsersThemesAssignment()
        this.props.setTitle([
            {
                title: i18n.administration,
                href: PATH_ADMINISTRATION,
            }, {
                title: i18n.cartography,
                href: `${PATH_ADMINISTRATION}/${PATH_ADMINISTRATION_CARTOGRAPHY}`,
            },
        ])
    }

    componentDidMount() {
        const { users, applicativeUsers, themeLayers } = this.props

        this.createActions(this.state.isEditMode)

        if (!users.length || !applicativeUsers.length) {
            this.props.fetchAllUsers().then(() => {
                this.setState({ usersLoaded: true, allUsers: uniq([...this.props.users, ...this.props.applicativeUsers]) })
            })
        } else {
            this.setState({ usersLoaded: true, allUsers: uniq([...this.props.users, ...this.props.applicativeUsers]) })
        }

        const stringLayers = this.getCartographyLayers()
        const layers = stringLayers.length > 0 ? stringLayers.split(',').map(item => {
            return themeLayers.find(l => l && l.id === parseInt(item))
        }).filter(layer => !!layer) : []
        this.setState({ layers })
    }

    createActions = isEditMode => {
        const { tab } = this.state
        const label = isEditMode ? 'cancel' : 'edit'
        const actions = {
            new: this.redirectToThemeForm,
            [label]: this.toggleEditMode,
        }
        if (tab === TAB_THEME) {
            if (getUser().consultant === '1') {
                this.setActions({})
            } else {
                this.setActions(actions)
            }
        } else if ((tab === TAB_MAP)) {
            if (getUser().consultant === '1') {
                this.setActions({})
            } else if (isEditMode) {
                this.setActions({
                    save: this.saveParamsMap,
                })
            } else {
                this.setActions(omit(actions, ['new']))
            }
        }
    }

    saveParamsMap = () => {
        const { paramsMap } = this.state
        if (paramsMap) {
            this.props.updateSieauParameters(paramsMap)
        }
        this.toggleEditMode()
    }

    getSelectedLayersParameter = () => {
        const { settings } = this.props
        return settings.find(s => s.parameter === homeListOfSelectedLayers)?.value
    }

    getCartographyLayers = () => {
        const selectedLayersParameter = this.getSelectedLayersParameter()
        if (selectedLayersParameter?.length) {
            return selectedLayersParameter
        }
        return ''
    }

    redirectToThemeForm = (id = 'new') => this.props.push(`${PATH_ADMINISTRATION}/${PATH_ADMINISTRATION_CARTOGRAPHY}/${id}`)

    componentDidUpdate = (prevProps, prevState) => {
        const { isEditMode, tab } = this.state
        if (prevState.isEditMode !== isEditMode || prevState.tab !== tab) {
            this.createActions(isEditMode)
        }
    }

    handleAssignTheme = (theme, v) => {
        const { allUsers } = this.state
        const logins = v ? allUsers.map(o => o.login) : []

        this.props.assignThemeToUsers(theme.id, logins)
    }

    onUpdateTheme = (theme, key, value) => {
        setTimeout(() => {
            const newTheme = Object.assign({}, theme)
            newTheme[key] = value
            if (key === 'visibility') {
                ['quality', 'piezometry', 'hydrometry', 'pluviometry', 'homepage', 'referencials', 'productionUnit', 'distributionUnit', 'installation'].map(type => {
                    newTheme[type] = value
                })
            }
            this.props.sieauUpdate('adminThemes', newTheme)
        }, 400)
    }

    onUpdateLayer = (layer, key, value) => {
        setTimeout(() => {
            const newLayer = {
                ...layer,
                [key]: value,
            }
            this.props.sieauUpdate('themeLayers', newLayer)
        }, 400)
    }

    getAssignedUsers = themeId => uniq(this.props.usersThemes.filter(o => o.themeId == themeId).map(o => this.state.allUsers.find(u => u.login === o.login)?.login).filter(o => !!o))

    toggleEditMode = () => {
        this.setState({ isEditMode: !this.state.isEditMode })
    }

    toggleModal = () => {
        this.setState(({ openSelectUsersModal }) => ({ openSelectUsersModal: !openSelectUsersModal }))
    }

    getSelectUsersModal = () => {
        const { usersLoaded } = this.state
        const { openSelectUsersModal, theme } = this.state
        if (openSelectUsersModal) {
            const selectedUsers = this.getAssignedUsers(theme.id)
            return (
                <LayerRightsModal
                    theme={theme}
                    selectedUsers={selectedUsers}
                    open={openSelectUsersModal}
                    toggleModal={this.toggleModal}
                    openProgressBar={usersLoaded}
                />
            )
        }
        return null
    }

    saveCartographyLayers = (layers) => {
        const layersId = layers.map(l => l.id)
        const layersIdString = layersId.join() || ''
        this.props.updateSetting(homeListOfSelectedLayers, layersIdString)
    }

    toggleLayer = layer => () => {
        const { layers: layersState } = this.state
        const layers = (() => {
            if (layersState.find(l => l.id === layer.id)) {
                return layersState.filter(l => l.id != layer.id)
            }
            return [...layersState, layer]
        })()
        this.setState({ layers }, () => this.saveCartographyLayers(layers))
    }

    getThemes = () => {
        const { allUsers, searchValue, isEditMode, layers: layersState } = this.state
        const { adminThemes, themeLayers } = this.props
        const filteredThemes = adminThemes.filter(t => (!t.isAquaDBTheme || themeLayers.find(l => t.id == l.theme)))
        const themes = orderBy(filteredThemes, [t => !t.isAquaDBTheme, 'themeOrder']).reduce((a, theme) => {
            const nbSelectedUsers = this.getAssignedUsers(theme.id).length
            const title = (
                <>
                    {
                        isEditMode && (
                            <Checkbox
                                col={1}
                                checked={!!nbSelectedUsers}
                                className='white-checkbox'
                                onChange={v => this.handleAssignTheme(theme, v)}
                                containerStyle={{ display: 'flex', alignItems: 'center' }}
                            />
                        )
                    }
                    <div className='col s8'>
                        <h5>{theme.name}</h5>
                    </div>
                    {isEditMode && !theme.isAquaDBTheme && (
                        <div className='col s1' style={{ display: 'flex', alignItems: 'center' }}>
                            <i
                                className='material-icons right rem-3-size'
                                {...sieauTooltip(i18n.edit, null, 'bottom')}
                                onClick={() => this.redirectToThemeForm(theme.id)}
                            >
                                    edit
                            </i>
                        </div>
                    )}
                    {isEditMode && (
                        <div className='col s1' style={{ display: 'flex', alignItems: 'center' }}>
                            <i className='material-icons left rem-3-size' {...sieauTooltip(i18n.restrictionByUser, null, 'bottom')}
                                onClick={(e) => {
                                    e.stopPropagation()
                                    this.setState({ theme, openSelectUsersModal: true })
                                }}
                            >
                                person
                            </i>
                        </div>
                    )}
                    <div className='col s2' style={{ display: 'flex', alignItems: 'center' }}>
                        <span className='right smallSubListTitle'>{nbSelectedUsers} / {allUsers.length}</span>
                    </div>
                </>
            )
            const layers = orderBy(themeLayers, ['name']).filter(layer => layer.theme === theme.id && layer.name.toLowerCase().includes(searchValue)).map(l => ({
                ...l,
                nullValue3: (
                    <Switch
                        checked={!!layersState.find(layer => layer.id === l.id)}
                        onChange={this.toggleLayer(l)}
                    />
                ),
                type: getLabel(LAYER_TYPES, l.type),
            }))
            const table = layers.length ?
                (<Table
                    data={layers}
                    sortable
                    orderable
                    showTitle={false}
                    type={{ headers: ['name', 'type', 'nullValue3'] }}
                    condensed
                />) : <h5 className='bold center-align'>{i18n.noLayers}</h5>
            const label = theme.isAquaDBTheme ? 'aquadb' : 'other'
            return !searchValue.length || layers.length ? {
                ...a,
                [label]: [...a[label], (
                    <AccordionMUI className='margin-bottom-1'>
                        <AccordionSummaryMUI style={{ backgroundColor: theme.color, height: 60, alignItems: 'center !important' }}>{title}</AccordionSummaryMUI>
                        <Card>
                            <div className='row no-margin'>
                                {table}
                            </div>
                        </Card>
                    </AccordionMUI>
                )],
            } : a
        }, {
            aquadb: [],
            other: [],
        })
        return (
            <Card maxWidth={1000} className='transparent no-box-shadow' contentClassName='transparent'>
                <Input
                    col={12}
                    title={ i18n.search }
                    value={ searchValue }
                    onChange={(v) => this.setState({ searchValue: v })}
                />
                {!!themes.aquadb.length && (
                    <>
                        <h3>{i18n.openDataThemes}</h3>
                        <ul className='sieau-collapsible popout'>
                            {themes.aquadb}
                        </ul>
                    </>
                )}
                {!!themes.other.length && (
                    <>
                        <h3>{i18n.privateThemes}</h3>
                        <ul className='sieau-collapsible popout'>
                            {themes.other}
                        </ul>
                    </>
                )}
            </Card>
        )
    }

    getPanel = () => {
        const { tab, layers, isEditMode } = this.state
        switch (tab) {
            case TAB_THEME: default:
                return (
                    <Grid container alignItems='stretch' justifyContent='space-between' className='padding-left-1 padding-right-1'>
                        <Grid item xs={8}>
                            {this.getThemes()}
                        </Grid>
                        <Grid item xs={4} className='padding-left-1 padding-top-1'>
                            <LayerTestPanel layers={layers} />
                        </Grid>
                        {this.getSelectUsersModal()}
                    </Grid>
                )
            case TAB_MAP:
                return (
                    <div className='row no-margin'>
                        <div className='col s12 no-padding margin-top-1 margin-bottom-1'>
                            <MapAdminPanel disabled={!isEditMode} onChange={(paramsMap) => this.setState({ paramsMap })}/>
                        </div>
                    </div>
                )
        }
    }

    getActive = panel => {
        const { classes } = this.props
        const { tab } = this.state
        return panel === tab ? classes.tabSelected : classes.tab
    }

    render() {
        const { classes } = this.props
        const { isEditMode } = this.state
        return (
            <div className='row no-margin padding-top-1' style={{ padding: '0 0.75rem' }}>
                <ul className='col s12 tabs' style={{ padding: 0 }}>
                    <li className={`tab col s6${isEditMode ? ' disabled' : ''}`} id={TAB_THEME} onClick={isEditMode ? () => {} : () => this.setState({ tab: TAB_THEME })} {...sieauTooltip(i18n.themes, null, 'bottom')}>
                        <a className={this.getActive(TAB_THEME)}>{i18n.themes}</a>
                    </li>
                    <li className={`tab col s6${isEditMode ? ' disabled' : ''}`} id={TAB_MAP} onClick={isEditMode ? () => {} : () => this.setState({ tab: TAB_MAP })} {...sieauTooltip(i18n.settingDefaultView, null, 'bottom')}>
                        <a className={this.getActive(TAB_MAP)}>{i18n.settingDefaultView}</a>
                    </li>
                </ul>
                <div className='col s12' style={{ padding: 0 }}>
                    <Card className={classes.card}>
                        {this.getPanel()}
                    </Card>
                </div>
            </div>
        )
    }
}

CartographyApp.propTypes = getPropTypes(storeProps, {
    usersThemes: PropTypes.arrayOf(PropTypes.instanceOf(DtoUserLayerAssignment)),
    adminThemes: PropTypes.arrayOf(PropTypes.instanceOf(CartographyThemeDto)),
    settings: PropTypes.arrayOf(PropTypes.instanceOf(SettingDto)),
    users: PropTypes.arrayOf(PropTypes.instanceOf(UserDto)),
    applicativeUsers: PropTypes.arrayOf(PropTypes.instanceOf(UserDto)),
    updateSetting: PropTypes.func,
    fetchAllUsers: PropTypes.func,
})

const mapStateToProps = store => getMapStateToProps(storeProps, {
    usersThemes: store.UserReducer.usersThemes,
    adminThemes: store.AdministrationReducer.adminThemes,
    settings: store.AdministrationReducer.settings,
    users: store.UserReducer.users,
    applicativeUsers: store.UserReducer.applicativeUsers,
})

const mapDispatchToProps = {
    updateSetting: AdministrationAction.updateSetting,
    fetchAllUsers: UserAction.fetchAllUsers,
    setHelpLink: HomeAction.setHelpLink,
    setTitle: HomeAction.setTitle,
    fetchAdminCartographyThemes: AdministrationAction.fetchAdminCartographyThemes,
    fetchUsersThemesAssignment: UserAction.fetchUsersThemesAssignment,
    assignThemeToUsers: UserAction.assignThemeToUsers,
    sieauFetch: SieauAction.fetch,
    sieauUpdate: SieauAction.update,
    updateSieauParameters: AdministrationAction.updateSieauParameters,
    push,
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CartographyApp))
