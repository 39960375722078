import i18n from 'simple-react-i18n'
import Row from '../../../../components/react/Row'
import RadioButtons from '../../../../components/forms/RadioButtons'
import { getLabel } from '../../../../utils/StoreUtils'
import Input from '../../../../components/forms/Input'
import { getDate, getDateWithHour, getHour } from '../../../../utils/DateUtil'
import { onChangeDate, onChangeHour, setMessageModal } from '../../../../utils/FormUtils'
import React, { useState } from 'react'
import { getLogin } from '../../../../utils/SettingUtils'
import { WhiteCard } from '../../../../components/styled/Card'
import Button from '../../../../components/forms/Button'
import HydrometryAction from '../../../actions/HydrometryAction'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { DELETE, PURGE, RESET } from '../../../constants/HydrometryConstants'
import DtoHydroMeasure from '../../../dto/chronicMeasures/DtoHydroMeasure'
import ConfirmModal from '../../../../components/modal/ConfirmModal'
import ToastrAction from '../../../../toastr/actions/ToastrAction'

const DeleteHydroToolPanel = ({
    id,
    dataType,
    selectedCodePoint,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    isChange,
    setIsChange,
    isPreview,
    setIsPreview,
    dataToReset,
    createTechnicalEvent,
    displayModes,
}) => {
    const [deleteMode, setDeleteMode] = useState('reset')
    const [modalOpen, setModalOpen] = useState(false)
    const [isPreviewButton, setIsPreviewButton] = useState(false)

    const dispatch = useDispatch()

    const {
        users,
    } = useSelector(store => ({
        users: store.UserReducer.users,
    }), shallowEqual)

    const user = users.find(u => u.login === getLogin())

    const deleteModesObj = [
        { code: RESET, name: i18n.reseting },
        { code: DELETE, name: i18n.deleting },
        user.admin === '1' ? { code: PURGE, name: i18n.purge } : null,
    ].filter(m => !!m)

    const onValidate = () => {
        if (!displayModes.brute) {
            setMessageModal('Veuillez passer en mode "Brute" pour valider les données (choix des courbes au dessus du graphique)')
        } else {
            if (!startDate || !endDate) {
                dispatch(ToastrAction.warning(i18n.datesNoSelected))
            } else {
                if (deleteMode === PURGE || deleteMode === DELETE) {
                    setIsPreview(true)
                }
                setIsPreviewButton(true)
            }
        }
    }

    const onCancel = () => {
        setIsPreviewButton(false)
        setIsPreview(false)
    }

    const resetSave = () => {
        setIsPreviewButton(false)
        setIsPreview(false)
        setIsChange(!isChange)
    }

    // eslint-disable-next-line consistent-return
    const onSave = () => {
        if (!displayModes.brute) {
            setMessageModal('Veuillez passer en mode "Brute" pour valider les données (choix des courbes au dessus du graphique)')
        } else {
            switch (deleteMode) {
                case PURGE:
                    setModalOpen(true)
                    return (
                        <ConfirmModal
                            isOpen={modalOpen}
                            title={i18n.purgeRawMeasuresConfirmation}
                            onValidate={
                                dispatch(HydrometryAction.purgeHydroMeasures(
                                    id, { startDate, endDate }, dataType, selectedCodePoint, () => {
                                        resetSave()
                                        createTechnicalEvent({ mode: 'purge' })
                                    }))
                            }
                            onClose={() => setModalOpen(false)}
                        />
                    )
                case DELETE:
                    setModalOpen(true)
                    return (
                        <ConfirmModal
                            isOpen={modalOpen}
                            title={i18n.deleteMeasuresConfirmation}
                            onValidate={
                                dispatch(HydrometryAction.deleteHydroMeasures(
                                    id, dataToReset, () => {
                                        resetSave()
                                        createTechnicalEvent({ mode: 'delete' })
                                    }))
                            }
                            onClose={() => setModalOpen(false)}
                        />
                    )
                case RESET:
                    setModalOpen(true)
                    return (
                        <ConfirmModal
                            isOpen={modalOpen}
                            title={i18n.resetConfirmation}
                            onValidate={
                                dispatch(HydrometryAction.resetHydroMeasures(
                                    id, { startDate, endDate }, dataType, selectedCodePoint, () => {
                                        resetSave()
                                        createTechnicalEvent({ mode: 'reset' })
                                    }))
                            }
                            onClose={() => setModalOpen(false)}
                        />
                    )
                default:
                    break
            }
        }
    }

    const resetDates = () => {
        setStartDate(null)
        setEndDate(null)
    }

    return (
        <div>
            <WhiteCard title={ i18n.deleteMode } round>
                <Row>
                    <RadioButtons col={ 12 } elements={ deleteModesObj } onChange={ v => setDeleteMode(v) } selected={ deleteMode } disabled={ isPreview }/>
                </Row>
            </WhiteCard>
            <div className='padding-top-1'/>
            <WhiteCard title={ getLabel(deleteModesObj, deleteMode) } round>
                <div className='padding-top-1'/>
                <Row className='padding-bottom-1 valign-wrapper'>
                    <Button
                        tooltip={ i18n.resetDates }
                        icon='loop'
                        className='margin-left-1 validToolBtn'
                        onClick={ () => resetDates() }
                        disabled={ isPreviewButton }
                    />
                    <h6 className='padding-left-1'>
                        { i18n.resetDates }
                    </h6>
                </Row>
                <Row>
                    <Input
                        id='startDate'
                        col={ 6 }
                        title={ i18n.startDate }
                        value={ getDate(startDate)}
                        onChange={ v => {
                            onChangeDate(v, v2 => {
                                return setStartDate(getDateWithHour(v2, startDate).valueOf())
                            })
                        } }
                        disabled={ isPreviewButton }
                    />
                    <Input
                        id='startDateHour'
                        col={ 6 }
                        title={ i18n.startHour }
                        value={ getHour(startDate) }
                        onChange={ v => {
                            onChangeHour(v, v2 => {
                                return setStartDate(getDateWithHour(startDate, v2).valueOf())
                            })
                        } }
                        disabled={ isPreviewButton }
                    />
                </Row>
                <Row>
                    <Input
                        id='endDate'
                        col={ 6 }
                        title={ i18n.endDate }
                        value={ getDate(endDate) }
                        onChange={ v => onChangeDate(v, v2 => {
                            return setEndDate(getDateWithHour(v2, startDate).valueOf())
                        }
                        ) }
                        disabled={ isPreviewButton }
                    />
                    <Input
                        id='endDateHour'
                        col={ 6 }
                        title={ i18n.endHour }
                        value={ getHour(endDate) }
                        onChange={ v => onChangeDate(v, v2 => {
                            return setEndDate(getDateWithHour(startDate, v2).valueOf())
                        }
                        ) }
                        disabled={ isPreviewButton }
                    />
                </Row>
                <Row className='padding-bottom-1 padding-top-1 center-align'>
                    <Button
                        tooltip={ i18n.apply }
                        onClick={ onValidate }
                        icon='border_color'
                        className='btn-floating btn-large'
                        disabled={ isPreviewButton }
                    />
                    <Button
                        tooltip={ i18n.cancel }
                        onClick={ onCancel }
                        icon='cancel'
                        className='red btn-floating btn-large margin-left-2 margin-right-2'
                        disabled={ !isPreviewButton }
                    />
                    <Button
                        tooltip={ i18n.register }
                        onClick={ onSave }
                        icon='save'
                        disabled={ !isPreviewButton }
                        className={ `btn-floating btn-large ${isPreview ? 'pulse' : ''}` }
                    />
                </Row>
            </WhiteCard>
        </div>

    )
}


DeleteHydroToolPanel.propTypes = {
    id: PropTypes.number,
    dataType: PropTypes.number,
    selectedCodePoint: PropTypes.number,
    startDate: PropTypes.number,
    setStartDate: PropTypes.func,
    endDate: PropTypes.number,
    setEndDate: PropTypes.func,
    isChange: PropTypes.bool,
    setIsChange: PropTypes.func,
    isPreview: PropTypes.bool,
    setIsPreview: PropTypes.func,
    createTechnicalEvent: PropTypes.func,
    dataToReset: PropTypes.instanceOf(DtoHydroMeasure),
    displayModes: PropTypes.objectOf(PropTypes.bool),
}

export default DeleteHydroToolPanel

