import React, { forwardRef, useRef, useState } from 'react'
import { Grid, Popover } from '@mui/material'
import PropTypes from 'prop-types'
import Checkbox from '../forms/Checkbox'
import i18n from 'simple-react-i18n'
import Icon from '../icon/Icon'
import { MEASURE_COTE } from '../../piezometry/constants/PiezometryConstants'
import { getLocalStorageJson, setLocalStorageJson } from '../../utils/FormUtils'
import { ButtonMUI } from '../styled/Buttons'
import { PIEZOMETRY_COLOR } from '../../utils/constants/ColorTheme'
import { StyledFieldSet, StyledLegend } from '../StyledElements'
import RadioButtons from '../forms/RadioButtons'
import Select from '../forms/Select'
import NumberField from '../forms/NumberField'
import { getQualificationSelectOptions, getStatusSelectOptions } from '../../utils/StatusUtil'

const DISPLAY_COTE = 'DISPLAY_COTE'
const PIEZO_TAB_DISPLAY_MODES = 'PIEZO_TAB_DISPLAY_MODES'
const PIEZO_TAB_ACCURACY = 'PIEZO_TAB_ACCURACY'
const PERSONALIZED_GROUPING = 'personalizedGrouping'

const DAY = 'DAY'
const HOUR = 'HOUR'

const CUMUL_MAX = 'CUMUL_PERSO_MAX'
const CUMUL_MIN = 'CUMUL_PERSO_MIN'
const CUMUL_SUM = 'CUMUL_PERSO_SUM'
const CUMUL_AVERAGE = 'CUMUL_PERSO_AVERAGE'

const AUTO = 'auto'
const BRUTE = 'brute'
const MIN = 'min'
const MAX = 'max'
const AVERAGE = 'average'
const INITIAL_BRUTE = 'initialBrute'
const getCote = cote => {
    switch (cote) {
        case MEASURE_COTE.DEPTH: return { label: i18n.depthLastlandmark, color: PIEZOMETRY_COLOR.DEPTH }
        case MEASURE_COTE.NGF: return { label: i18n.ngf, color: PIEZOMETRY_COLOR.NGF }
        case MEASURE_COTE.GROUND: return { label: i18n.ground, color: PIEZOMETRY_COLOR.GROUND }
        case MEASURE_COTE.LANDMARK: return { label: i18n.landmarkHistory, color: PIEZOMETRY_COLOR.LANDMARK_HISTORY }
        default: return { label: i18n.depthLastlandmark, color: PIEZOMETRY_COLOR.DEPTH }
    }
}

const ModeItem = ({ label, color, checked, onClick, iconInfos }) => (
    <Grid
        container
        item
        alignItems='center'
        sx={{ cursor: 'pointer' }}
        onClick={onClick}
    >
        <Checkbox checked={checked} />
        <span>
            <span className={`${color} arrests-level-panel ${color}-text`}>OO</span>
            {label} {iconInfos ? <Icon icon={iconInfos.type} style={ { fontSize: 18, color: iconInfos.color } } /> : ''}
        </span>
    </Grid>
)
ModeItem.propTypes = {
    label: PropTypes.string,
    color: PropTypes.string,
    checked: PropTypes.bool,
    onClick: PropTypes.func,
    iconInfos: PropTypes.shape({
        type: PropTypes.string,
        color: PropTypes.string,
    }),
}


const ModeItemStatus = ({ label, checked, onClick, icon }) => (
    <Grid
        container
        item
        alignItems='center'
        sx={{ cursor: 'pointer' }}
        onClick={onClick}
    >
        <Checkbox checked={checked} />
        <Grid paddingRight={'1rem'}>{icon}</Grid>
        <Grid>{label}</Grid>
    </Grid>
)
ModeItemStatus.propTypes = {
    label: PropTypes.string,
    checked: PropTypes.bool,
    onClick: PropTypes.func,
    icon: PropTypes.string,
}

const ReferenceLevelItem = ({ label, color, checked, onClick, disabled }) => (
    <Grid
        container
        item
        alignItems='center'
        sx={{ cursor: 'pointer' }}
        onClick={!disabled ? onClick : () => {}}
    >
        <Checkbox checked={checked} disabled={disabled} />
        <Chip
            label={label}
            color={color}
            style={{ padding: '0px 6px' }}
        />
    </Grid>
)
ReferenceLevelItem.propTypes = {
    label: PropTypes.string,
    color: PropTypes.string,
    checked: PropTypes.bool,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
}

const ChipLabel = forwardRef(({
    label = '',
    onClick = () => {},
    sx = {},
    isOpen= false,
}, ref) => (
    <Grid
        ref={ref}
        onClick={onClick}
        sx={{
            cursor: 'pointer',
            backgroundColor: !isOpen ? 'rgba(53, 96, 159, 1)' : 'white',
            color: !isOpen ? 'white' : 'rgba(53, 96, 159, 1)',
            padding: '0px 9px',
            borderTop: '2px solid rgba(53, 96, 159, 1)',
            borderLeft: '2px solid rgba(53, 96, 159, 1)',
            borderRight: '2px solid rgba(53, 96, 159, 1)',
            borderRadius: isOpen ? '5px 5px 0 0' : '5px',
            ...sx,
        }}
        container
        justifyContent={'flex-start'}
        alignItems={'center'}
    >
        <Grid item padding={'4px'}>
            <Icon tooltip={ i18n.hydrometricGrouping } icon={'settings'}/>
        </Grid>
        <Grid item sx={{ padding: '5px 5px 5px 2px' }}>
            {getCote(label).label}
        </Grid>
    </Grid>

))

ChipLabel.propTypes = {
    label: PropTypes.string,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    sx: PropTypes.object,
    isOpen: PropTypes.bool,
}

const Chip = ({
    label = '',
    color = '',
    style = {},
}) => (
    <span
        style={{
            cursor: 'pointer',
            backgroundColor: color,
            color: 'white',
            padding: '0.3rem 0.6rem',
            borderRadius: '1rem',
            ...style,
        }}
    >
        {label}
    </span>
)

Chip.propTypes = {
    label: PropTypes.string,
    color: PropTypes.string,
    style: PropTypes.object,
}


const SimpleChartTabsPiezo = ({
    displayCote,
    displayModes,
    changeParent = {},
    withInitialDisplay = false,
    displayModesUniqChoise = false,
    hideCustom = false,
    hideAccuracy = true,
    noSol = false,
}) => {
    const anchorEl = useRef()
    const [openModal, setOpenModal] = useState(false)
    const [tmpReferenceLevelSelected, setTmpReferenceLevelSelected] = useState(displayCote)
    const [referenceLevelSelected, setReferenceLevelSelected] = useState(displayCote)
    const [referenceLevel, setReferenceLevel] = useState({
        depth: displayCote === MEASURE_COTE.DEPTH,
        NGF: displayCote === MEASURE_COTE.NGF,
        sol: displayCote === MEASURE_COTE.GROUND,
        landmark: displayCote === MEASURE_COTE.LANDMARK,
    })
    const [group, setGroup] = useState(displayModes ?? {
        auto: false,
        brute: false,
        min: false,
        max: false,
        average: false,
        initialBrute: false,
    })
    const [accuracy, setAccuracy] = useState(getLocalStorageJson('PIEZO_TAB_ACCURACY') ?? {
        qualifications: getQualificationSelectOptions().map(q => q.code),
        status: getStatusSelectOptions().map(s => s.code),
    })
    const [optionDayHourSelected, setOptionDayHourSelected] = useState(HOUR)
    const [optionCumulSelected, setOptionCumulSelected] = useState(CUMUL_MAX)

    const [hoursCumul, setHoursCumul] = useState(1)

    const [showReferenceLevel, setShowReferenceLevel] = useState(true)
    const [showChoiceCurves, setShowChoiceCurves] = useState(true)
    const [showQualification, setShowQualification] = useState(false)
    const [showStatus, setShowStatus] = useState(false)

    const onChangeReferenceLevel = (measureCote) => {
        switch (measureCote) {
            case MEASURE_COTE.DEPTH :
                setTmpReferenceLevelSelected(MEASURE_COTE.DEPTH)
                setReferenceLevel({
                    depth: true,
                    NGF: false,
                    sol: false,
                    landmark: false,
                })
                break
            case MEASURE_COTE.NGF :
                setTmpReferenceLevelSelected(MEASURE_COTE.NGF)
                setReferenceLevel({
                    depth: false,
                    NGF: true,
                    sol: false,
                    landmark: false,
                })
                break
            case MEASURE_COTE.GROUND :
                setTmpReferenceLevelSelected(MEASURE_COTE.GROUND)
                setReferenceLevel({
                    depth: false,
                    NGF: false,
                    sol: true,
                    landmark: false,
                })
                break
            case MEASURE_COTE.LANDMARK : default:
                setTmpReferenceLevelSelected(MEASURE_COTE.LANDMARK)
                setReferenceLevel({
                    depth: false,
                    NGF: false,
                    sol: false,
                    landmark: true,
                })
                break
        }
    }

    const onSave = () => {
        const day = `${optionCumulSelected}_${hoursCumul * 24}`
        const hour = `${optionCumulSelected}_${hoursCumul}`
        const grp = { ...group, personalizedGroupingValue: optionDayHourSelected === DAY ? day : hour }

        setReferenceLevelSelected(tmpReferenceLevelSelected)
        setOpenModal(false)
        setLocalStorageJson(DISPLAY_COTE, tmpReferenceLevelSelected)
        setLocalStorageJson(PIEZO_TAB_DISPLAY_MODES, grp)
        setLocalStorageJson(PIEZO_TAB_ACCURACY, accuracy)
        changeParent({
            displayCote: tmpReferenceLevelSelected,
            displayModes: grp,
            accuracy,
        })
    }

    const onChangesMode = (mode) => {
        const defaultGroup = {
            auto: false,
            brute: false,
            min: false,
            max: false,
            average: false,
            initialBrute: false,
            personalizedGrouping: false,
        }
        switch (mode) {
            case AUTO :
                if (displayModesUniqChoise) {
                    setGroup({ ...defaultGroup, auto: true })
                    break
                }
                setGroup(prev => !prev.auto ? ({
                    auto: !prev.auto,
                    max: prev.auto,
                    min: prev.auto,
                    average: prev.auto,
                    brute: prev.auto,
                    personalizedGrouping: prev.auto,
                    initialBrute: prev.auto,
                }) : {
                    auto: true,
                    max: false,
                    min: false,
                    average: false,
                    brute: false,
                    initialBrute: false,
                    personalizedGrouping: false,
                })
                break
            case BRUTE :
                if (displayModesUniqChoise) {
                    setGroup({ ...defaultGroup, brute: true })
                    break
                }
                setGroup(prev => ({
                    ...prev,
                    auto: !(!prev.brute || prev.min || prev.max || prev.average || prev.personalizedGrouping || prev.initialBrute),
                    brute: !prev.brute,
                }))
                break
            case MIN :
                if (displayModesUniqChoise) {
                    setGroup({ ...defaultGroup, min: true })
                    break
                }
                setGroup(prev => ({
                    ...prev,
                    auto: !(prev.brute || !prev.min || prev.max || prev.average || prev.personalizedGrouping || prev.initialBrute),
                    min: !prev.min,
                }))
                break
            case MAX :
                if (displayModesUniqChoise) {
                    setGroup({ ...defaultGroup, max: true })
                    break
                }
                setGroup(prev => ({
                    ...prev, auto: !(prev.brute || prev.min || !prev.max || prev.average || prev.personalizedGrouping || prev.initialBrute),
                    max: !prev.max,
                }))
                break
            case AVERAGE :
                if (displayModesUniqChoise) {
                    setGroup({ ...defaultGroup, average: true })
                    break
                }
                setGroup(prev => ({
                    ...prev, auto: !(prev.brute || prev.min || prev.max || !prev.average || prev.personalizedGrouping || prev.initialBrute),
                    average: !prev.average,
                }))
                break
            case PERSONALIZED_GROUPING :
                if (displayModesUniqChoise) {
                    setGroup({ ...defaultGroup, personalizedGrouping: true })
                    break
                }
                setGroup(prev => ({
                    ...prev, auto: !(prev.brute || prev.min || prev.max || prev.average || !prev.personalizedGrouping || prev.initialBrute),
                    personalizedGrouping: !prev.personalizedGrouping,
                }))
                break
            case INITIAL_BRUTE : default :
                if (displayModesUniqChoise) {
                    setGroup({ ...defaultGroup, initialBrute: true })
                    break
                }
                setGroup(prev => ({
                    ...prev, auto: !(prev.brute || prev.min || prev.max || prev.average || prev.personalizedGrouping || !prev.initialBrute),
                    initialBrute: !prev.initialBrute,
                }))
                break
        }
    }

    const optionDayHour = [
        { value: HOUR, label: i18n.hour },
        { value: DAY, label: i18n.day },
    ]
    const optionCumul = [
        { value: CUMUL_MAX, label: i18n.max },
        { value: CUMUL_MIN, label: i18n.min },
        { value: CUMUL_SUM, label: i18n.sum },
        { value: CUMUL_AVERAGE, label: i18n.average },
    ]
    const handleStatusClick = (v) => {
        const newStatus = accuracy.status.includes(v) ? accuracy.status.filter((s) => s !== v) : [...accuracy.status, v]
        if (!newStatus.length) {
            setAccuracy(prev => ({ ...prev, status: getStatusSelectOptions().map(s => s.code) }))
        } else {
            setAccuracy(prev => ({ ...prev, status: newStatus }))
        }
    }
    const handleQualificationsClick = (v) => {
        const newQualifications = accuracy.qualifications.includes(v) ? accuracy.qualifications.filter((s) => s !== v) : [...accuracy.qualifications, v]
        if (!newQualifications.length) {
            setAccuracy(prev => ({ ...prev, qualifications: getQualificationSelectOptions().map(q => q.code) }))
        } else {
            setAccuracy(prev => ({ ...prev, qualifications: newQualifications }))
        }
    }


    return (
        <Grid>
            <ChipLabel
                label={referenceLevelSelected}
                onClick={() => setOpenModal(prev => !prev)}
                ref={anchorEl}
                isOpen={openModal}
                sx={{ height: '2.7rem' }}
            />
            <Popover
                open={openModal}
                anchorEl={anchorEl.current}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                slotProps={{
                    paper: {
                        sx: {
                            borderRadius: '0 5px 5px 5px',
                        },
                    },
                }}
                onClose={() => setOpenModal(false)}
            >
                <Grid width={300} sx={{ border: '2px solid rgba(53, 96, 159, 1)', padding: '10px' }}>
                    <Grid
                        container
                        direction='column'
                        spacing={0.5}
                    >
                        <Grid
                            container
                            onClick={() => setShowReferenceLevel(prev => !prev)}
                            alignItems='center'
                            justifyContent='space-between'
                            sx={{
                                paddingTop: '5px',
                                fontWeight: 'bold',
                                cursor: 'pointer',
                            }}
                        >
                            <Grid item>{i18n.referenceLevel}</Grid>
                            <Grid item>{ !showReferenceLevel ? '▼' : '▲' }</Grid>
                        </Grid>
                        {
                            showReferenceLevel && (
                                <>
                                    <ReferenceLevelItem
                                        label={i18n.depthLastlandmark}
                                        color={PIEZOMETRY_COLOR.DEPTH}
                                        checked={referenceLevel.depth}
                                        onClick={() => onChangeReferenceLevel(MEASURE_COTE.DEPTH)}
                                    />
                                    <ReferenceLevelItem
                                        label={i18n.ngf}
                                        color={PIEZOMETRY_COLOR.NGF}
                                        checked={referenceLevel.NGF}
                                        onClick={() => onChangeReferenceLevel(MEASURE_COTE.NGF)}
                                    />
                                    <ReferenceLevelItem
                                        label={i18n.ground}
                                        color={PIEZOMETRY_COLOR.GROUND}
                                        checked={referenceLevel.sol}
                                        disabled={noSol}
                                        onClick={() => onChangeReferenceLevel(MEASURE_COTE.GROUND)}
                                    />
                                    <ReferenceLevelItem
                                        label={i18n.landmarkHistory}
                                        color={PIEZOMETRY_COLOR.LANDMARK_HISTORY}
                                        checked={referenceLevel.landmark}
                                        onClick={() => onChangeReferenceLevel(MEASURE_COTE.LANDMARK)}
                                    />
                                </>
                            )
                        }
                    </Grid>
                    <Grid
                        container
                        direction='column'
                        spacing={0.5}
                    >
                        <Grid
                            container
                            onClick={() => setShowChoiceCurves(prev => !prev)}
                            alignItems='center'
                            justifyContent='space-between'
                            sx={{
                                paddingTop: '5px',
                                fontWeight: 'bold',
                                cursor: 'pointer',
                            }}
                        >
                            <Grid item>{i18n.choiceCurves}</Grid>
                            <Grid item>{ !showChoiceCurves ? '▼' : '▲' }</Grid>
                        </Grid>
                        {
                            showChoiceCurves && (
                                <>
                                    <ModeItem
                                        label={i18n.auto}
                                        color='black'
                                        checked={group.auto}
                                        onClick={() => onChangesMode(AUTO)}
                                        iconInfos={{ type: 'info', color: 'grey' }}
                                    />
                                    <ModeItem
                                        label={i18n.brute}
                                        color='black'
                                        checked={group.brute}
                                        onClick={() => onChangesMode(BRUTE)}
                                        iconInfos={{ type: 'warning', color: 'orange' }}
                                    />
                                    <ModeItem
                                        label={i18n.min}
                                        color='red'
                                        checked={group.min}
                                        onClick={() => onChangesMode(MIN)}
                                    />
                                    <ModeItem
                                        label={i18n.max}
                                        color='blue'
                                        checked={group.max}
                                        onClick={() => onChangesMode(MAX)}
                                    />
                                    <ModeItem
                                        label={i18n.average}
                                        color='green'
                                        checked={group.average}
                                        onClick={() => onChangesMode(AVERAGE)}
                                    />
                                    {
                                        !hideCustom && (
                                            <ModeItem
                                                label={i18n.personalizedGrouping}
                                                color='orange'
                                                checked={group.personalizedGrouping}
                                                onClick={() => onChangesMode(PERSONALIZED_GROUPING)}
                                            />
                                        )}
                                    { !!group.personalizedGrouping && (
                                        <StyledFieldSet>
                                            <StyledLegend>{i18n.modeGrouping}</StyledLegend>
                                            <Grid container justifyContent='space-evenly' sx={{ padding: '10px 10px' }}>
                                                <Grid item>
                                                    <RadioButtons
                                                        colOption={6}
                                                        label={i18n.modeDaysHours}
                                                        elements={optionDayHour}
                                                        selected={optionDayHourSelected}
                                                        onChange={setOptionDayHourSelected}
                                                        disabled={!group.personalizedGrouping}
                                                    />
                                                </Grid>
                                                <Grid container justifyContent='space-evenly' sx={{ padding: '10px 10px' }}>
                                                    <Select
                                                        label={i18n.modeGrouping}
                                                        options={optionCumul}
                                                        value={optionCumulSelected}
                                                        onChange={setOptionCumulSelected}
                                                        disabled={!group.personalizedGrouping}
                                                    />
                                                    <NumberField
                                                        title={i18n.numberHoursDays}
                                                        value={hoursCumul}
                                                        onChange={setHoursCumul}
                                                        min={1}
                                                        disabled={!group.personalizedGrouping}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </StyledFieldSet>
                                    )}
                                    {withInitialDisplay && (
                                        <ModeItem
                                            label={i18n.initialGross}
                                            color='black'
                                            checked={group.initialBrute}
                                            onClick={() => onChangesMode(INITIAL_BRUTE)}
                                        />
                                    )}
                                </>
                            )
                        }
                        { !hideAccuracy && (
                            <>
                                <Grid
                                    onClick={() => setShowQualification(prev => !prev)}
                                    container
                                    justifyContent='space-between'
                                    alignItems='center'
                                    sx={{
                                        paddingTop: '5px',
                                        fontWeight: 'bold',
                                        cursor: 'pointer',
                                    }}
                                >
                                    <Grid item>{i18n.qualification} ({accuracy.qualifications?.length}/4)</Grid>
                                    <Grid item>{ !showQualification ? '▼' : '▲' }</Grid>
                                </Grid>
                                {
                                    showQualification && (
                                        <>
                                            {
                                                getQualificationSelectOptions().map(q => (
                                                    <ModeItemStatus
                                                        label={q.name}
                                                        icon={q.icon}
                                                        checked={accuracy.qualifications.includes(q.code)}
                                                        onClick={() => handleQualificationsClick(q.code)}
                                                    />
                                                ))
                                            }
                                        </>
                                    )
                                }
                                <Grid
                                    onClick={() => setShowStatus(prev => !prev)}
                                    container
                                    justifyContent='space-between'
                                    alignItems='center'
                                    sx={{
                                        paddingTop: '5px',
                                        fontWeight: 'bold',
                                        cursor: 'pointer',
                                    }}
                                >
                                    <Grid item>{i18n.status} ({accuracy.status?.length}/4)</Grid>
                                    <Grid item>{!showStatus ? '▼' : '▲'}</Grid>
                                </Grid>
                                {
                                    showStatus && (
                                        <>
                                            {
                                                getStatusSelectOptions().map(q => (
                                                    <ModeItemStatus
                                                        label={q.name}
                                                        icon={q.icon}
                                                        checked={accuracy.status.includes(q.code)}
                                                        onClick={() => handleStatusClick(q.code)}
                                                    />
                                                ))
                                            }
                                        </>
                                    )
                                }
                            </>
                        )}
                        <Grid container item sx={{ cursor: 'pointer' }} >
                            <Grid item xs={12}>
                                <ButtonMUI
                                    fullWidth
                                    variant={'contained'}
                                    onClick={ onSave }
                                >
                                    { i18n.toLoad }
                                </ButtonMUI>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Popover>
        </Grid>
    )
}

SimpleChartTabsPiezo.propTypes = {
    displayCote: PropTypes.number,
    displayModes: PropTypes.shape({
        auto: PropTypes.bool,
        brute: PropTypes.bool,
        average: PropTypes.bool,
        min: PropTypes.bool,
        max: PropTypes.bool,
    }),
    changeParent: PropTypes.func,
    withInitialDisplay: PropTypes.bool,
    displayModesUniqChoise: PropTypes.bool,
    hideCustom: PropTypes.bool,
    hideAccuracy: PropTypes.bool,
    noSol: PropTypes.bool,
}

export default SimpleChartTabsPiezo