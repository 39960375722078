import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import React, { useEffect, useState } from 'react'
import { Grid } from '@mui/material'
import { template } from 'lodash'
import i18n from 'simple-react-i18n'
import PropTypes from 'prop-types'
import { push } from 'connected-react-router'
import Table from 'components/datatable/Table'
import { SANDRE, nbPerPageLabelTiny } from 'referencial/constants/ReferencialConstants'
import { StyledFieldSet, StyledLegend, StyledSpan } from 'components/StyledElements'
import Input from 'components/forms/Input'
import Select from 'components/forms/Select'
import Button from 'components/forms/Button'
import PresentationCard from 'components/card/PresentationCard'
import CenterIcon from 'components/icon/CenterIcon'
import ToastrAction from 'toastr/actions/ToastrAction'
import JobAction from 'import/actions/JobAction'
import { getObjectLabel } from 'utils/StoreUtils'
import { getSandreLabel } from 'utils/StringUtil'
import { getJobList } from 'utils/JobUtils'
import ReferencialAction from 'referencial/action/ReferencialAction'
import { JOB_TYPES, QUALITO_IMPORT_TYPE } from 'quality/components/qualitometers/addQualito/AddQualitoConstants'
import { hasValue } from 'utils/NumberUtil'
import DtoHydrometricStation from 'hydrometry/dto/DtoHydrometricStation'
import { PLUVIO_IMPORT_TYPE } from 'pluviometry/constants/PluviometryConstant'

const piezoHeaders = ['code', 'name', 'city', 'nature', 'nullValue']
const defaultHeaders = ['code', 'name', 'city', 'nullValue']

const getNumberList = max => [...Array(max)].map((_, i) => ({ value: `${i}`, label: i > 9 ? i : `0${i}` }))
const getHours = () => [ { value: '*', label: i18n.everyHours }, ...getNumberList(24) ]
const getMinutes = () => [
    { value: '*/5', label: template(i18n.everyCustomMinutes)({ minute: 5 }) },
    { value: '*/10', label: template(i18n.everyCustomMinutes)({ minute: 10 }) },
    { value: '*/15', label: template(i18n.everyCustomMinutes)({ minute: 15 }) },
    { value: '*/20', label: template(i18n.everyCustomMinutes)({ minute: 20 }) },
    { value: '*/30', label: template(i18n.everyCustomMinutes)({ minute: 30 }) },
    ...getNumberList(60),
]
const getDays = () => [
    { value: '*', label: i18n.allDays },
    { value: '1', label: i18n.monday },
    { value: '2', label: i18n.tuesday },
    { value: '3', label: i18n.wednesday, disabled: true },
    { value: '4', label: i18n.thursday },
    { value: '5', label: i18n.friday },
    { value: '6', label: i18n.saturday },
    { value: '0', label: i18n.sunday },
]

const AddStepJob = ({
    station,
    fullReset,
    setIsOpen,
    stationType = 'piezometry',
    allStations = [],
    filters = {},
}) => {
    const dispatch = useDispatch()

    const {
        sandreCodes,
        citiesIndex,
        jobTable,
    } = useSelector(store => ({
        sandreCodes: store.ReferencialReducer.sandreCodes,
        citiesIndex: store.CityReducer.citiesIndex,
        jobTable: store.JobReducer.jobTable,
    }), shallowEqual)

    const initialJob = { ...station.job, cron: '0 0 0 * * * *', days: '*', hours: '0', minutes: '0', id: null, name: null }

    const [newJob, setNewJob] = useState(initialJob)
    const [jobsWithStation, setJobsWithStation] = useState([])
    const changeJob = (changes) => setNewJob({ ...newJob, ...changes })

    const jobTableCriterias = {
        jobType: JOB_TYPES[station.stationType],
        forceLoad: true,
        scheduled: true,
        displayMode: 'job',
    }

    const changePlanification = ({ minutes, hours, days }) => {
        const cron = ['0', minutes, hours, '*', '*', days, '*'].join(' ')
        changeJob({ cron, minutes, hours, days })
    }

    const handleChangeTime = (k, v, job) => {
        if (k === 'minutes' && v.includes('/')) {
            changePlanification({ ...job, minutes: v, hours: '*', days: '*' })
        } else if (k === 'hours' && v.includes('/')) {
            changePlanification({ ...job, hours: v, days: '*' })
        } else {
            changePlanification({ ...job, [k]: v })
        }
    }

    const handleChangeDays = (selected, job) => {
        changePlanification({ ...job, days: hasValue(selected) && selected != -1 ? selected.toString() : '*' })
    }

    const getCode = () => {
        if (station.stationType === PLUVIO_IMPORT_TYPE.METEO_FRANCE) {
            switch (filters.codeMode) {
                case 'cities': return filters.cityCodes[0]
                case 'departments': return filters.departmentCodes[0]
                default: return filters.stationCodes[0]
            }
        } else if (station.stationType === QUALITO_IMPORT_TYPE.OROBNAT) {
            return filters.codes[0]
        }
        return station.code
    }

    const getDataType = () => {
        switch (station.stationType) {
            case PLUVIO_IMPORT_TYPE.METEO_FRANCE: return filters.codeMode
            case QUALITO_IMPORT_TYPE.OROBNAT: return 'codes'
            default: return null
        }
    }

    const fetchHasStation = () => {
        return JobAction.jobHasStation(getCode(), getDataType()).then(json => setJobsWithStation(json))
    }

    const addJob = () => {
        if (!hasValue(newJob.name)) {
            dispatch(ToastrAction.warning(i18n.pleaseCompleteAllField))
        } else {
            dispatch(JobAction.addJob(newJob, false)).then(() => {
                setNewJob(initialJob)
                dispatch(JobAction.fetchJobTable(jobTableCriterias))
                fetchHasStation()
            })
        }
    }

    useEffect(() => {
        if (!sandreCodes.length) {
            dispatch(ReferencialAction.fetchSandreCodes())
        }
        dispatch(JobAction.fetchJobTable(jobTableCriterias))
        fetchHasStation()
    }, [])

    const stations = station.ids.map(i => allStations.find(q => q.id === i)).map(s => ({
        code: { value: s.code },
        name: { value: s.name },
        nature: { value: getSandreLabel(sandreCodes, SANDRE.PIEZOMETER_NATURE, station.nature) },
        city: { value: getObjectLabel(citiesIndex[s.townCode], 'labelWithCode') },
        nullValue: {
            value: null,
            rightIcon: 'arrow_forward',
            rightIconColor: '#2196F3',
            rightIconTip: i18n.accessToTheFile,
            rightIconClick: () => dispatch(push(`/station/${stationType}/${s.id}/dashboard`)),
            leftIcon: 'border_color',
            leftIconColor: '#2196F3',
            leftIconTip: i18n.accessDescription,
            leftIconClick: () => dispatch(push(`/station/${stationType}/${s.id}/description`)),
        },
    }))
    const jobList = getJobList(jobTable.data).jobs.map(j => {
        const jobHasStation = jobsWithStation.some(job => j.id === job.id)
        return {
            ...j,
            lastExecution: { value: j.date.value },
            nullValue2: {
                value: null,
                leftIcon: jobHasStation ? 'location_on' : 'location_off',
                leftIconColor: 'rgba(0, 0, 0, 0.87)',
                leftIconTip: () => jobHasStation ? i18n.stationIsIn : i18n.stationIsOut,
                rightIcon: jobHasStation ? 'clear' : 'note_add',
                rightIconColor: '#2196F3',
                rightIconTip: () => jobHasStation ? i18n.removeStationFromJob : i18n.addStationToJob,
                rightIconClick: jobHasStation ?
                    () => JobAction.removeStationFromJob(getCode(), j.id, getDataType()).then(() => fetchHasStation().then(() => {
                        dispatch(ToastrAction.success(i18n.stationHasBeenRemoved))
                    })) :
                    () => JobAction.addStationToJob(getCode(), j.id, getDataType()).then(() => fetchHasStation().then(() => {
                        dispatch(ToastrAction.success(i18n.stationHasBeenAdd))
                    })),
            },
        }
    })

    return (
        <div>
            <Table
                condensed
                sortable
                color
                data={stations}
                type={{
                    headers: stationType === 'piezometry' ? piezoHeaders : defaultHeaders,
                }}
                maxHeight='62vh'
                overflow='auto'
                paging
                nbPerPageLabel={nbPerPageLabelTiny}
                title={i18n.importedData}
            />
            <div className='padding-top-2' />
            <Table
                title={i18n.jobs}
                data={jobList}
                color
                paging
                type={{ headers: ['jobName', 'status', 'lastExecution', 'programmation', 'nullValue', 'nullValue2'] }}
                nbPerPageLabel={nbPerPageLabelTiny}
                sortable
                condensed
            />
            <StyledFieldSet>
                <StyledLegend>
                    <StyledSpan>{i18n.addJob}</StyledSpan>
                </StyledLegend>
                <Grid container justifyContent='center' alignItems='center' spacing={3} className='padding-top-1'>
                    <Grid item xs={5}>
                        <Input title={i18n.name} value={newJob.name} onChange={v => changeJob({ name: v })} />
                    </Grid>
                </Grid>
                <Grid container justifyContent='center' alignItems='center' spacing={3} className='padding-top-1'>
                    <Grid item xs={3}>
                        <Select
                            value={ newJob.hours || 0 }
                            options={ getHours() }
                            label={ i18n.jobHour }
                            onChange={ v => handleChangeTime('hours', v, newJob, changeJob) }
                            noNullValue
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <Select
                            value={ newJob.minutes || 0 }
                            options={ getMinutes() }
                            label={ i18n.jobMinute }
                            onChange={ v => handleChangeTime('minutes', v, newJob, changeJob) }
                            noNullValue
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <Select
                            value={ newJob.days }
                            options={ getDays() }
                            label={ i18n.jobDay }
                            onChange={ v => handleChangeDays(v, newJob, changeJob) }
                            noNullValue
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <Button title={i18n.add} icon='note_add' onClick={() => addJob(getCode())}/>
                    </Grid>
                </Grid>
            </StyledFieldSet>
            <Grid container justifyContent='center' alignItems='center' spacing={3} className='padding-top-2'>
                <h4>{ i18n.andNow }</h4>
            </Grid>
            <Grid container justifyContent='center' alignItems='center' spacing={3}>
                <Grid item xs={4}>
                    <PresentationCard
                        iconComponent={
                            <CenterIcon
                                icon={'note_add'}
                                color='#4f88b5'
                                additionalStyle={{ paddingRight: 5 }}
                            />
                        }
                        title={i18n.addAnotherStation}
                        onClick={fullReset}
                    />
                </Grid>
                <Grid item xs={4}>
                    <PresentationCard
                        iconComponent={
                            <CenterIcon
                                icon={'clear'}
                                color='#4f88b5'
                                additionalStyle={{ paddingRight: 5 }}
                            />
                        }
                        title={i18n.close}
                        onClick={() => setIsOpen(false)}
                    />
                </Grid>
                <Grid item xs={4}>
                    <PresentationCard
                        iconComponent={
                            <CenterIcon
                                icon={'location_on'}
                                color='#4f88b5'
                                additionalStyle={{ paddingRight: 5 }}
                            />
                        }
                        title={i18n.accessToTheFile}
                        onClick={() => dispatch(push(`/station/${stationType}/${station.ids[0]}/dashboard`))}
                    />
                </Grid>
            </Grid>

        </div>
    )
}

AddStepJob.propTypes = {
    station: PropTypes.shape({
        code: PropTypes.string,
        stationType: PropTypes.string,
        name: PropTypes.string,
        jobExecutionId: PropTypes.number,
        job: PropTypes.shape({
            id: PropTypes.number,
        }),
        jobExecution: PropTypes.shape({
            statusCode: PropTypes.number,
        }),
        ids: PropTypes.arrayOf(PropTypes.number),
        nature: PropTypes.string,
    }),
    fullReset: PropTypes.func,
    setIsOpen: PropTypes.func,
    stationType: PropTypes.string,
    allStations: PropTypes.arrayOf(PropTypes.oneOf([DtoHydrometricStation])),
    filters: PropTypes.shape({}),
}

export default AddStepJob