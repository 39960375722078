import SimpleDatePicker from 'components/forms/SimpleDatePicker'
import { push } from 'connected-react-router'
import { template } from 'lodash'
import moment from 'moment'
import DtoProductionUnitLink from 'productionUnit/dto/DroProductionUnitLink'
import PropTypes from 'prop-types'
import React, { useEffect, useReducer, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import ToastrAction from 'toastr/actions/ToastrAction'
import useActions from 'utils/customHook/useActions'
import Card from '../../components/card/Card'
import Checkbox from '../../components/forms/Checkbox'
import Input from '../../components/forms/Input'
import NumberField from '../../components/forms/NumberField'
import Select from '../../components/forms/Select'
import Textarea from '../../components/forms/Textarea'
import Row from '../../components/react/Row'
import QualityAction from '../../quality/actions/QualityAction'
import ReferencialAction from '../../referencial/action/ReferencialAction'
import { SANDRE } from '../../referencial/constants/ReferencialConstants'
import StationMapDashboardPanel from '../../station/components/dashboard/component/map/StationMapDashboardPanel'
import StationContributorPanel from '../../station/components/link/StationContributorPanel'
import StationUpdatePanel from '../../station/components/update/StationUpdatePanel'
import { getStationArrowNav } from '../../utils/ActionUtils'
import { getLogin } from '../../utils/SettingUtils'
import { getLinks } from '../../utils/StationUtils'
import { getSandreList } from '../../utils/StoreUtils'
import ProductionUnitAction from '../actions/ProductionUnitAction'
import ProductionUnitLinks from './ProductionUnitLinks'

const initialState = {
    addedLinks: [],
    deletedLinks: [],
}

const reducer = (state, action) => {
    switch (action.type) {
        case 'add':
            return {
                addedLinks: [...state.addedLinks, ...action.links],
                deletedLinks: state.deletedLinks.filter(dl => !action.links.some(lk => dl.siteType === lk.siteType && dl.siteCode === lk.siteCode && dl.linkType === lk.linkType)),
            }
        case 'delete':
            return {
                addedLinks: state.addedLinks.filter(dl => !action.links.some(lk => dl.siteType === lk.siteType && dl.siteCode === lk.siteCode && dl.linkType === lk.linkType)),
                deletedLinks: [...state.deletedLinks, ...action.links],
            }
        case 'reset':
            return {
                addedLinks: [],
                deletedLinks: [],
            }
        default:
            throw new Error()
    }
}

const ProductionUnitDescriptionPanel = ({
    id = -1,
    stationTypes,
}) => {
    const dispatch = useDispatch()

    const [readMode, setReadMode] = useState(true)
    const [stateProdUnit, setStateProdUnit] = useState({})
    const [stateLinks, dispatchStateLinks] = useReducer(reducer, initialState)

    const {
        productionUnit,
        productionUnits,
        cities,
        watermasses,
        sandreCodes,
        users,
        status,
        sectors,
    } = useSelector(store => ({
        productionUnit: store.ProductionUnitReducer.productionUnit,
        productionUnits: store.ProductionUnitReducer.productionUnits,
        cities: store.CityReducer.cities,
        watermasses: store.WatermassReducer.watermasses,
        sandreCodes: store.ReferencialReducer.sandreCodes,
        users: store.UserReducer.users,
        status: store.QualityReducer.status,
        sectors: store.SectorReducer.sectors,
    }), shallowEqual)

    useEffect(() => {
        if (!sandreCodes.length) {
            dispatch(ReferencialAction.fetchSandreCodes())
        }
        if (!status.length) {
            dispatch(QualityAction.fetchStatus())
        }
    }, [sandreCodes])

    useEffect(() => {
        if (!productionUnit.id) {
            dispatch(ProductionUnitAction.fetchProductionUnit(id))
        } else {
            dispatch(ProductionUnitAction.fetchProductionUnitLinks(productionUnit.id))
            setStateProdUnit(productionUnit)
        }
    }, [productionUnit])

    const onSave = () => {
        if (!stateProdUnit.code) {
            dispatch(ToastrAction.error(template(i18n.fieldNotSet)({ field: i18n.code })))
        } else {
            dispatch(ProductionUnitAction.updateProductionUnit(stateProdUnit))
            if (stateLinks.deletedLinks.length || stateLinks.addedLinks.length) {
                const links = {
                    addedLinks: stateLinks.addedLinks.map(ad => new DtoProductionUnitLink(ad)),
                    deletedLinks: stateLinks.deletedLinks.map(ad => new DtoProductionUnitLink(ad)),
                }
                dispatch(ProductionUnitAction.updateProductionUnitLinks(stateProdUnit.id, links)).then(() => {
                    dispatchStateLinks({ type: 'reset' })
                })
            }
            setReadMode(true)
        }
    }

    useActions(() => {
        return readMode ?
            {
                edit: () => setReadMode(false),
                delete: () => dispatch(ProductionUnitAction.deleteProductionUnit(productionUnit.id, () => dispatch(push('/productionUnit')))),
                links: getLinks(productionUnit, { stationTypes, watermasses }),
                arrowNav: getStationArrowNav('productionUnit', productionUnits, productionUnit.id, s => dispatch(push(`/station/productionUnit/${s.id}/description`))),
            } : {
                cancel: () => {
                    dispatchStateLinks({ type: 'reset' })
                    setStateProdUnit(productionUnit)
                    setReadMode(true)
                },
                save: onSave,
                delete: () => dispatch(ProductionUnitAction.deleteProductionUnit(productionUnit.id, () => dispatch(push('/productionUnit')))),
                links: getLinks(productionUnit, { stationTypes, watermasses }),
                arrowNav: getStationArrowNav('productionUnit', productionUnits, productionUnit.id, s => dispatch(push(`/station/productionUnit/${s.id}/description`))),
            }
    }, [readMode, productionUnit, productionUnits, stateLinks, stateProdUnit])

    const onChangeProductionUnit = value => setStateProdUnit({ ...stateProdUnit, ...value })

    const readModeObj = { readMode, editMode: !readMode }
    if (stateProdUnit.id) {
        return (
            <Row noMargin={false} className={ 'margin-left-2' }>
                <div className='col s9 padding-top-1' style={{ marginBottom: '100px' }}>
                    <Card title={i18n.description}>
                        <Row className={ `${!readMode && 'padding-top-1' || ''}` }>
                            <Input col={6}
                                value={stateProdUnit.code}
                                title={i18n.code}
                                disabled {...readModeObj}
                                onChange={v => onChangeProductionUnit({ code: v })}
                            />
                            <Input col={ 6 }
                                value={ stateProdUnit.name }
                                title={ i18n.name }
                                { ...readModeObj }
                                onChange={v => onChangeProductionUnit({ name: v }) }
                            />
                        </Row>
                        <Row>
                            <Select col={ 6 }
                                value={ stateProdUnit.directUsage }
                                options={ getSandreList(sandreCodes, SANDRE.USAGES_GENERAL) }
                                label={ i18n.directUsage }
                                { ...readModeObj }
                                onChange={v => onChangeProductionUnit({ directUsage: v })}
                            />
                            <Input col={ 6 }
                                value={ stateProdUnit.mnemonic }
                                title={ i18n.mnemonic }
                                { ...readModeObj }
                                onChange={v => onChangeProductionUnit({ mnemonic: v }) }
                            />
                        </Row>
                        <Row className={ `${!readMode && 'padding-bottom-1' || ''}` }>
                            <SimpleDatePicker
                                id='beginDate'
                                label={i18n.beginDate}
                                value={stateProdUnit.startDate}
                                onChange={v => onChangeProductionUnit({ startDate: v })}
                                {...readModeObj}
                                col={6}
                            />
                            <SimpleDatePicker
                                id='endDate'
                                label={i18n.endDate}
                                value={stateProdUnit.endDate}
                                onChange={v => onChangeProductionUnit({ endDate: v })}
                                col={6}
                                {...readModeObj}
                                min={stateProdUnit.startDate}
                            />
                        </Row>
                        <Row>
                            <NumberField col={ 6 }
                                value={ stateProdUnit.controlFrequency }
                                title={ i18n.controledFrequency }
                                { ...readModeObj }
                                onChange={v => onChangeProductionUnit({ controlFrequency: v }) }
                            />
                            <NumberField col={ 6 }
                                value={ stateProdUnit.realisedFrequency }
                                title={ i18n.doneFrequency }
                                { ...readModeObj }
                                onChange={v => onChangeProductionUnit({ realisedFrequency: v }) }
                            />
                        </Row>
                        <Row>
                            <NumberField col={ 6 }
                                value={ stateProdUnit.decretFrequency }
                                title={ i18n.decreeFrequency }
                                { ...readModeObj }
                                onChange={v => onChangeProductionUnit({ decretFrequency: v }) }
                            />
                        </Row>
                        <Row>
                            <SimpleDatePicker
                                id='datePrefectoralDecree'
                                label={i18n.datePrefectoralDecree}
                                value={stateProdUnit.prefectoralArrestDate}
                                { ...readModeObj }
                                onChange={v => onChangeProductionUnit({ prefectoralArrestDate: v })}
                                col={6}
                            />
                            <NumberField col={ 6 }
                                value={ stateProdUnit.arrestDuration }
                                title={ i18n.durationPrefectoralDecree }
                                { ...readModeObj }
                                onChange={v => onChangeProductionUnit({ arrestDuration: v }) }
                            />
                        </Row>
                        <Row>
                            <NumberField col={ 6 }
                                value={ stateProdUnit.exploitationCode }
                                title={ i18n.exploitation }
                                { ...readModeObj }
                                onChange={v => onChangeProductionUnit({ exploitationCode: v }) }
                            />
                            <Select col={ 6 }
                                value={ stateProdUnit.statusCode }
                                options={ getSandreList(sandreCodes, SANDRE.CODE_ETAT) }
                                label={ i18n.state }
                                { ...readModeObj }
                                onChange={v => onChangeProductionUnit({ statusCode: v })}
                            />
                        </Row>
                        <Row>
                            <Select
                                col={6}
                                value={stateProdUnit.sector}
                                options={sectors}
                                label={i18n.sector}
                                {...readModeObj}
                                onChange={v => onChangeProductionUnit({ sector: v })}
                            />
                        </Row>
                        <Row className={ `${!readMode && 'padding-bottom-1' || ''}` }>
                            <Textarea col={ 12 }
                                value={ stateProdUnit.description }
                                title={ i18n.description }
                                { ...readModeObj }
                                onChange={v => onChangeProductionUnit({ description: v }) }
                            />
                        </Row>
                    </Card>
                    <Row>
                        <ProductionUnitLinks
                            readMode={readMode}
                            stateLinks={stateLinks}
                            dispatchStateLinks={dispatchStateLinks}
                        />
                    </Row>
                    <Card title={ i18n.characteristics } className='margin-top-1' noMargin={false}>
                        <Row className={`${!readMode && 'padding-top-1' || ''}` }>
                            <NumberField col={ 6 }
                                value={ stateProdUnit.authorizedFlow }
                                title={ i18n.authorizedFlow }
                                { ...readModeObj }
                                onChange={v => onChangeProductionUnit({ authorizedFlow: v }) }
                            />
                            <NumberField col={ 6 }
                                value={ stateProdUnit.annualWaterAmountExploited }
                                title={ i18n.annualWaterVolumeExploited }
                                { ...readModeObj }
                                onChange={v => onChangeProductionUnit({ annualWaterAmountExploited: v }) }
                            />
                        </Row>
                        <Row>
                            <NumberField col={ 6 }
                                value={ stateProdUnit.volumeAllowDay }
                                title={ i18n.volumeAllowDay }
                                { ...readModeObj }
                                onChange={v => onChangeProductionUnit({ volumeAllowDay: v }) }
                            />
                            <NumberField col={ 6 }
                                value={ stateProdUnit.nbStationInService }
                                title={ i18n.numberActiveStation }
                                { ...readModeObj }
                                onChange={v => onChangeProductionUnit({ nbStationInService: v }) }
                            />
                        </Row>
                        <Row>
                            <NumberField col={6}
                                value={stateProdUnit.builtArea}
                                title={i18n.builtArea}
                                {...readModeObj}
                                floatValue
                                onChange={v => onChangeProductionUnit({ builtArea: v })}
                            />
                            <NumberField col={6}
                                value={stateProdUnit.nonBuiltArea}
                                title={i18n.nonBuiltArea}
                                {...readModeObj}
                                floatValue
                                onChange={v => onChangeProductionUnit({ nonBuiltArea: v })}
                            />
                        </Row>
                        <Row>
                            <SimpleDatePicker
                                id='renovationDate'
                                label={i18n.renovationDate}
                                value={stateProdUnit.renovationDate}
                                {...readModeObj}
                                onChange={v => onChangeProductionUnit({ renovationDate: v })}
                                col={6}
                            />
                        </Row>
                        <Row className={`${!readMode && 'padding-bottom-1' || ''}` }>
                            <Checkbox col={ 12 }
                                checked={ stateProdUnit.perimeter === '1' }
                                label={ i18n.protectionPerimeter }
                                { ...readModeObj }
                                onChange={v => onChangeProductionUnit({ perimeter: v ? '1' : '0' }) }
                            />
                        </Row>
                        <Row className={`${!readMode && 'padding-bottom-1' || ''}` }>
                            <Textarea col={ 12 }
                                value={ stateProdUnit.comments }
                                title={ i18n.comments }
                                { ...readModeObj }
                                onChange={v => onChangeProductionUnit({ comments: v }) }
                            />
                        </Row>
                    </Card>
                    <div className='margin-top-1'>
                        <StationContributorPanel
                            station={stateProdUnit}
                            onChange={onChangeProductionUnit}
                            { ...readModeObj }
                        />
                    </div>
                </div>
                <div className='col s3 padding-top-1' style={{ marginBottom: '100px' }}>
                    <StationUpdatePanel station={ stateProdUnit }/>
                    <Card noMargin={ false } className='margin-top-1'>
                        <Row className={ `${!readMode && 'padding-top-1' || ''}` }>
                            <Input col={12} title={i18n.dataOrigin} value={stateProdUnit.dataOrigin} onChange={v => onChangeProductionUnit({ dataOrigin: v })} {...readModeObj}/>
                        </Row>
                        <Row>
                            <Select col={12} label={i18n.status} value={stateProdUnit.status}
                                onChange={v => onChangeProductionUnit({
                                    status: v,
                                    statusLogin: getLogin(),
                                    statusDate: moment().valueOf(),
                                })}
                                options={status} integerValue {...readModeObj}
                            />
                        </Row>
                        <Row className={ `${!readMode && 'padding-bottom-1' || ''}` }>
                            <SimpleDatePicker
                                id='statusDate'
                                label={i18n.controlDoneAt}
                                value={stateProdUnit.statusDate}
                                { ...readModeObj }
                                onChange={v => onChangeProductionUnit({ statusDate: v })}
                                col={12}
                            />
                        </Row>
                        <Row>
                            <Select col={12} label={i18n.controlDoneBy} value={stateProdUnit.statusLogin} onChange={v => onChangeProductionUnit({ statusLogin: v })}
                                options={users.map(u => ({ code: u.login, name: u.name || u.login }))} {...readModeObj}
                            />
                        </Row>
                    </Card>
                    <StationMapDashboardPanel noMarkerTooltip station={stateProdUnit} type={'stateProdUnit'} />
                    <Card title={i18n.location} className='margin-top-1' noMargin={false}>
                        <Row className={ `${!readMode && 'padding-top-1' || ''}` }>
                            <Input col={ 12 } title={ i18n.address } value={ stateProdUnit.address } onChange={ v => onChangeProductionUnit({ address: v }) } {...readModeObj}/>
                        </Row>
                        <Row>
                            <Select col={ 12 } value={ stateProdUnit.townCode } label={ i18n.city } options={ cities } keyValue='id' displayWithCode
                                onChange={ v => onChangeProductionUnit({ townCode: v }) } {...readModeObj}
                            />
                        </Row>
                        <Row displayIf={stateProdUnit.declarationTownCode !== stateProdUnit.townCode || !readMode}>
                            <Select col={ 12 } value={ stateProdUnit.declarationTownCode } label={ i18n.declarationTownCode } options={ cities } keyValue='id' displayWithCode
                                onChange={ v => onChangeProductionUnit({ declarationTownCode: v }) } {...readModeObj}
                            />
                        </Row>
                        <Row>
                            <Input col={ 12 } value={ stateProdUnit.countryCode } title={ i18n.countryCode } onChange={ v => onChangeProductionUnit({ countryCode: v }) } {...readModeObj}/>
                        </Row>
                        <Row>
                            <Textarea col={ 12 } noMargin={ false } classname={ !readMode && 'margin-bottom-1' || '' } title={ i18n.localisation } value={ stateProdUnit.location } onChange={ v => onChangeProductionUnit({ location: v }) } {...readModeObj}/>
                        </Row>
                        <Row>
                            <NumberField col={ 6 } title='X' value={ stateProdUnit.x } floatValue onChange={ v => onChangeProductionUnit({ x: v }) } {...readModeObj}/>
                            <NumberField col={ 6 } title='Y' value={ stateProdUnit.y } floatValue onChange={ v => onChangeProductionUnit({ y: v }) } {...readModeObj}/>
                        </Row>
                        <Row>
                            <Select col={ 12 } label={ i18n.projection } value={ stateProdUnit.projection }
                                options={ getSandreList(sandreCodes, SANDRE.PROJECTION) }
                                onChange={ v => onChangeProductionUnit({ projection: v }) } {...readModeObj} integerValue
                            />
                        </Row>
                        <Row>
                            <NumberField col={ 12 } value={ stateProdUnit.altitude } title={ i18n.altitude } floatValue onChange={ (_, v) => onChangeProductionUnit({ altitude: v }) } {...readModeObj}/>
                        </Row>
                    </Card>
                </div>
            </Row>
        )
    }
    return null
}

ProductionUnitDescriptionPanel.propTypes = {
    stationTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
    id: PropTypes.number,
}

export default ProductionUnitDescriptionPanel
