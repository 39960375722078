
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import i18n from 'simple-react-i18n'
import ToastrAction from 'toastr/actions/ToastrAction'
import Table from '../../../../components/datatable/Table'
import Select from '../../../../components/forms/Select'
import { nbPerPageLabelTiny } from '../../../../referencial/constants/ReferencialConstants'
import AppStore from '../../../../store/AppStore'
import Job from '../../../dto/DtoJob'
import { integrationModeHelpIcon } from '../../../utils/ImportUtils'
import SurfaceQualitometerStationDto from '../../../dto/SurfaceQualitometerStationDto'
import { Buffer } from 'buffer'

class EcologicalStatePanel extends Component {
    constructor(props) {
        super(props)
        this.state = {
            measureOptions: [
                {
                    value: '',
                    label: i18n.noDataIntegration,
                },
                {
                    value: 'dataIntegration',
                    label: i18n.lastDataIntegration,
                },
                {
                    value: 'dataFullIntegration',
                    label: i18n.fullDataIntegration,
                },
            ],
        }
    }

    onDeleteCode = ({ code }) => {
        if (this.props.isEditMode) {
            const codes = this.props.job.parameters.parameters.filter(p => p != code)
            this.props.addCode(codes)
        }
    }

    addCode = () => {
        if (this.props.isEditMode) {
            const code = this.refs.txtAddCode.value
            const exist = this.props.job.parameters.parameters.find(p => p.toLowerCase() == code.toLowerCase())
            if (!code) {
                AppStore.dispatch(ToastrAction.error(i18n.youMustEnterAtLeastOneBssCode))
            } else if (exist) {
                AppStore.dispatch(ToastrAction.error(i18n.youHaveAlreadyEnteredThisBssCode))
            } else {
                this.props.addCode([code, ...this.props.job.parameters.parameters])
                this.refs.txtAddCode.value = ''
            }
        }
    }

    getStations = () => {
        return this.props.job.parameters.parameters.map(code => new SurfaceQualitometerStationDto({ code }))
    }

    getFilters = () => {
        const { parameters } = this.props.job
        if (parameters.filters) {
            return parameters.filters.join(',')
        }
        return ''
    }

    getDataTypes = type => {
        const { parameters } = this.props.job
        if (parameters.dataTypes && parameters.dataTypes.length) {
            return parameters.dataTypes.find(d => d.includes(type)) || ''
        }
        return ''
    }

    handleChangeDataIntegration = integration => {
        const { parameters } = this.props.job
        const dataTypes = parameters.dataTypes.filter(d => !d.includes('data'))
        if (integration) {
            dataTypes.push(integration)
        }
        this.props.onChangeDataTypes(dataTypes)
    }

    handleChangeStationIntegration = integration => {
        const { parameters } = this.props.job
        const dataTypes = parameters.dataTypes.filter(d => !d.includes('station'))
        if (integration) {
            dataTypes.push('stationIntegration')
        }
        this.props.onChangeDataTypes(dataTypes)
    }

    handleChangefilters = (filters = '') => {
        this.props.onChangeFilters(filters.replace(/\s/g, '').split(','))
    }

    onChangeFile = e => {
        if (this.props.isEditMode) {
            const reader = new FileReader()
            const file = e.target.files[0]
            reader.onload = upload => {
                const split = upload.target.result.split(',')
                if (split[0].indexOf('text/csv') > 0 || split[0].indexOf('vnd.ms-excel') > 0) {
                    const buf = Buffer.from(split[1], 'base64').toString()
                    const codes = buf.replace(/;/g, '').split('\n').map(b => b.trim())
                    const codesToAdd = [...this.props.job.parameters.parameters, ...codes]
                    this.props.addCode(codesToAdd)
                } else {
                    AppStore.dispatch(ToastrAction.error(i18n.theSelectedFileMustBeInCsvFormat))
                }
                this.refs.importFile.value = ''
            }
            reader.readAsDataURL(file)
        }
    }

    render() {
        const disabled = { disabled: !this.props.isEditMode }
        return (
            <div>
                <div className='row no-margin valign-wrapper'>
                    <div className='col s4'>
                        <Select
                            value={ this.getDataTypes('data') }
                            options={ this.state.measureOptions }
                            label={ i18n.dataIntegration }
                            labelSpan={ integrationModeHelpIcon() }
                            col={ 12 }
                            onChange={ this.handleChangeDataIntegration }
                            { ...disabled }
                        />
                    </div>
                </div>
                <div className='row no-margin valign-wrapper'>
                    <div className='col s3'>
                        <label>{ i18n.importFile }</label>
                    </div>
                    <div className='file-field col s9 no-padding input-field'>
                        <div className='col s3 no-padding btn'>
                            <span>{ i18n.importLabel }</span>
                            <input
                                type='file'
                                ref='importFile'
                                accept='.csv'
                                onChange={ this.onChangeFile }
                                disabled={ !this.props.isEditMode }
                            />
                        </div>
                        <div className='file-path-wrapper col s9'>
                            <input
                                className='file-path'
                                ref='fileInput'
                                type='text'
                                placeholder={ i18n.selectFile }
                                disabled={ !this.props.isEditMode }
                            />
                        </div>
                    </div>
                </div>
                <div className='row valign-wrapper'>
                    <div className='col s3'>
                        <label>{ i18n.addCredential }</label>
                    </div>
                    <div className='input-field col s9 no-padding'>
                        <div className='col s8'>
                            <input id='txtAddCode' type='text' placeholder={ i18n.surfaceQualitometerExample }
                                ref='txtAddCode'
                            />
                            <label
                                className='tinyLabel'
                            >{ i18n.code }</label>
                        </div>
                        <a
                            className='waves-effect waves-light btn col offset-s1 s3'
                            onClick={ this.addCode }
                        >
                            { i18n.add }
                        </a>
                    </div>
                </div>
                <div className='row valign-wrapper'>
                    <div className='col s12'>
                        <Table
                            type={ new SurfaceQualitometerStationDto() }
                            data={ this.getStations() }
                            title={ i18n.credentials }
                            nbPerPageLabel={ nbPerPageLabelTiny }
                            onDelete={ this.onDeleteCode }
                            deletable={ this.props.isEditMode }
                            showNbElements
                            paging
                            exportButtonOnHeader
                        />
                    </div>
                </div>
            </div>
        )
    }
}

EcologicalStatePanel.propTypes = {
    job: PropTypes.instanceOf(Job).isRequired,
    addCode: PropTypes.func.isRequired,
    isEditMode: PropTypes.bool,
    onChangeDataTypes: PropTypes.func,
    onChangeFilters: PropTypes.func,
}


export default EcologicalStatePanel
