import ApplicationConf from 'conf/ApplicationConf'
import { genericFetch, genericPromise2, promiseAllProgress } from 'utils/ActionUtils'
// import i18n from 'simple-react-i18n'
import { QualityActionConstant } from 'quality/reducers/QualityReducer'
// import LogAction from 'log/actions/LogAction'
// import ToastrAction from 'toastr/actions/ToastrAction'
import { chunk, flatten } from 'lodash'

const AnalysisAction = {
    getAnalysis: (filter, signal) => genericPromise2(
        ApplicationConf.analysis.default(), { method: 'POST', body: filter, signal }
    ).catch((err) => {
        if (err.name === 'AbortError') return
        console.error('An error happened while fetching analysis')
    }),
    fetchGenericAnalysis: (filter, signal) => genericFetch(
        AnalysisAction.getAnalysis(filter, signal), 'POST'
    ),
    fetchAnalysis: (filter = {}, signal, onProgress = () => {}) => dispatch => {
        const {
            stations,
            ...searchFilter
        } = filter
        const group = Math.floor(stations.length / 25)
        const promises = chunk(stations, group > 0 ? group : 1).map(stationsGroup => genericPromise2(ApplicationConf.analysis.default(), { method: 'POST', body: { ...searchFilter, stations: stationsGroup }, signal })
            .catch(() => []))
        return promiseAllProgress(promises, onProgress)
            .then(jsonTab => {
                if (signal.aborted) {
                    return 0
                }
                const results = flatten(jsonTab)
                dispatch(QualityActionConstant.receiveSearchAnalysis(results))
                return results.length
            })
    },

    getLastOperationAnalysis: (ids, date, signal) => genericPromise2(ApplicationConf.qualitometer.lastOperationAnalysis(ids, date), { method: 'GET', signal }),

    getControledAnalysis: (controlType = {}, filters = {}, signal) => genericPromise2(ApplicationConf.analysis.control(), { method: 'POST', body: { ...controlType, filters }, signal }),
}

export default AnalysisAction