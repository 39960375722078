import React, {Component} from 'react'
import {arrayOf, getLabel} from '../../../../utils/StoreUtils'
import i18n from 'simple-react-i18n'
import Input from '../../../../components/forms/Input'
import Select from '../../../../components/forms/Select'
import Row from '../../../../components/react/Row'
import {getIntegrationModes} from '../../../../utils/JobUtils'
import PropTypes from 'prop-types'
import Job from '../../../dto/DtoJob'
import {connect} from 'react-redux'
import CityDto from '../../../../referencial/components/city/dto/CityDto'
import Button from '../../../../components/forms/Button'
import AppStore from '../../../../store/AppStore'
import CityAction from '../../../../referencial/components/city/actions/CityAction'
import Table from '../../../../components/datatable/Table'
import {integrationModeHelpIcon} from '../../../utils/ImportUtils'
import RadioButtons from '../../../../components/forms/RadioButtons'
import {nbPerPageLabelTiny} from '../../../../referencial/constants/ReferencialConstants'
import CartographyPanel from '../../../../components/map/CartographyPanel'
import ToastrAction from '../../../../toastr/actions/ToastrAction'
import {round} from '../../../../utils/NumberUtil'
import Checkbox from '../../../../components/forms/Checkbox'

class MeteoFranceRadarPanel extends Component {
    constructor(props) {
        super(props)
        this.state = {
            x: 0,
            y: 0,
            name: '',
            city: null,
        }
    }

    componentDidMount() {
        if (!this.props.cities.length) {
            AppStore.dispatch(CityAction.fetchCities())
        }
    }

    onChangeFilters = (changes) => {
        const parameters = {
            ...this.props.job.parameters,
            filters: [JSON.stringify({
                ...this.getFilters(),
                ...changes,
            })],
        }
        this.props.onChangeJob({ parameters })
    }

    getFilters = () => {
        const newFilters = this.props.job.parameters.filters.length ? JSON.parse(this.props.job.parameters.filters[0]) : {}
        return {
            importMode: 'DATA_ADD_LAST_DATA',
            group: 'daily',
            stations: [],
            ...newFilters }
    }

    onAddStation = () => {
        if (!this.state.name || !this.state.city || !this.state.x || !this.state.y) {
            AppStore.dispatch(ToastrAction.info('Veuillez renseigner un nom et une commune'))
        } else {
            const filters = this.getFilters()
            this.onChangeFilters({ stations: [...filters.stations, [`${round(this.state.x)}_${round(this.state.y)}`, this.state.name, this.state.city]] })
        }
    }

    render() {
        const disabled = !this.props.isEditMode
        const filters = this.getFilters()
        const groupTypes = [
            { code: 'daily', name: i18n.daily },
            { code: 'hourly', name: 'horaire' },
        ]
        const stationsFormatted = filters.stations.map(s => ({
            code: `MF_${s[0]}`,
            name: s[1],
            city: getLabel(this.props.cities, s[2], 'labelWithCode'),
            townCode: s[2],
            x: parseFloat(s[0].split('_')[0]),
            y: parseFloat(s[0].split('_')[1]),
            projection: 16,
            typeName: 'pluviometry'
        }))
        return (
            <div>
                <div className='row no-margin valign-wrapper'>
                    <fieldset className='width-100'>
                        <legend>&nbsp;{ i18n.parameters }&nbsp;</legend>
                        <Row className='valign-wrapper'>
                            <Select col={ 3 } options={ getIntegrationModes() } obligatory={ true }
                                label={ i18n.dataIntegration } value={ filters.importMode }
                                labelSpan={ integrationModeHelpIcon() }
                                onChange={ v => this.onChangeFilters({ importMode: v }) }
                                disabled={ disabled }
                            />
                            <RadioButtons col={ 3 } elements={ groupTypes } selected={ filters.group }
                                onChange={ v => this.onChangeFilters({ group: v }) }
                                title='Type de cumul' disabled={ disabled }
                            />
                            <div className='col s6' />
                        </Row>
                        <Row className='valign-wrapper'>
                            <Checkbox
                                col={4}
                                label='Importer les données de prévisions AROME (3 jours)'
                                checked={filters.addAROMEdata}
                                onChange={c => this.onChangeFilters({ addAROMEdata: c })}
                                disabled={ disabled }
                            />
                            <Checkbox
                                col={4}
                                label='Importer les données de prévisions ARPEGE (5 jours)'
                                checked={filters.addARPEGEdata}
                                onChange={c => this.onChangeFilters({ addARPEGEdata: c })}
                                disabled={ disabled }
                            />
                            <Checkbox
                                col={4}
                                label='Importer les données de prévisions ECMWF (15 jours)'
                                checked={filters.addECMWFdata}
                                onChange={c => this.onChangeFilters({ addECMWFdata: c })}
                                disabled={ disabled }
                            />
                        </Row>
                    </fieldset>
                </div>
                <div className='row no-margin padding-top-1'>
                    <fieldset>
                        <legend>&nbsp;{ i18n.stations }&nbsp;</legend>
                        <div className='row no-margin'>
                            <Row className='padding-top-1'>
                                <Table data={ stationsFormatted } showTitle={ false }
                                    type={ { headers: ['code', 'name', 'city'] } }
                                    condensed sortable showNbElements={ false }
                                    deletable={this.props.isEditMode} onDelete={ v => this.onChangeFilters({ stations: filters.stations.filter(s => !s[0].includes(v.code.replace('MF_', '')))}) }
                                    nbPerPageLabel={ nbPerPageLabelTiny } paging
                                />
                            </Row>
                        </div>
                        <div className='row no-margin'>
                            <p>Pour ajouter de nouvelles stations, naviguez sur la carte, cliquez sur l'endroit où vous
                                souhaitez ajouter la station. Ensuite, choisissez un nom, et une commune pour la station, puis cliquez sur 'Ajouter'.</p>
                            <p>Ces stations sont des stations 'virtuelles' qui représentent chacune une surface
                                d'environ 1km carré. La grille représentant ces surfaces est disponible parmi les
                                couches carto (Grille Radar et AROME/ARPEGE). Si elle n'y est pas, vous pouvez demander à
                                Aquasys de la mettre à disposition.</p>
                        </div>
                        <div className='row no-margin'>
                            <Row className='valign-wrapper'>
                                <Input col={ 3 } title='X' disabled value={ this.state.x }/>
                                <Input col={ 3 } title='Y' disabled value={ this.state.y }/>
                                <Input col={ 3 } title={i18n.name} value={ this.state.name } obligatory onChange={v => this.setState({ name: v })} disabled={ disabled }/>
                                <Select col={4} options={this.props.cities} label={i18n.city}
                                    onChange={v => this.setState({ city: v })} obligatory value={this.state.city} disabled={ disabled }
                                />
                                <Button col={ 3 } title={ i18n.add } onClick={ this.onAddStation } disabled={ disabled }/>
                                <div className='col s6'/>
                            </Row>
                        </div>
                        <div className='row no-margin'>
                            <CartographyPanel
                                layers={['STATIONS_POINTS']}
                                componentType='all'
                                stationsPoints={stationsFormatted}
                                stationsPanelTitle={i18n.stations}
                                noStationPanel={false}
                                height={800}
                                onClickMap={ v => this.setState({ x: v[0], y: v[1] }) }
                            />
                        </div>
                    </fieldset>
                </div>
            </div>
        )
    }
}

MeteoFranceRadarPanel.propTypes = {
    job: PropTypes.instanceOf(Job).isRequired,
    onChangeJob: PropTypes.func.isRequired,
    isEditMode: PropTypes.bool,
    cities: arrayOf(CityDto),
}

const mapStateToProps = store => ({
    cities: store.CityReducer.cities,
})

export default connect(mapStateToProps)(MeteoFranceRadarPanel)