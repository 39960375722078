import React from 'react'
import i18n from 'simple-react-i18n'
import PropTypes from 'prop-types'
import { StyledFieldSet, StyledLegend, StyledSpan } from 'components/StyledElements'
import Checkbox from 'components/forms/Checkbox'
import Select from 'components/forms/Select'
import { getIntegrationModes } from 'utils/JobUtils'
import { integrationModeHelpIcon } from 'import/utils/ImportUtils'
import { Grid } from '@mui/material'
import DtoHydrometricStation from 'hydrometry/dto/DtoHydrometricStation'
import { hasValue } from 'utils/NumberUtil'
import StationImportCodeMessage from 'import/components/utils/StationImportCodeMessage'
import Input from 'components/forms/Input'
import Icon from 'components/icon/Icon'

const AddHydroStepOptionsHP = ({
    station,
    changeStation,
    jobDataTypes,
    setJobDataTypes,
}) => {
    const integrationModes = getIntegrationModes().map(im => im.value)
    const isNotValid = hasValue(station.code) && ![8, 10, 12].includes(station.code.length)

    const onChangeDataTypes = (dataType) => {
        const newDataTypes = jobDataTypes.includes(dataType) ?
            jobDataTypes.filter(jdt => jdt !== dataType) :
            [...jobDataTypes, dataType]

        setJobDataTypes(newDataTypes)
    }

    const onChangeDataIntegration = (dataType) => {
        const filteredDataTypes = jobDataTypes.filter(jdt => !integrationModes.includes(jdt))
        setJobDataTypes(dataType && [...filteredDataTypes, dataType] || filteredDataTypes)
    }

    return (
        <StyledFieldSet >
            <StyledLegend>
                <StyledSpan>{i18n.options}</StyledSpan>
            </StyledLegend>
            <Grid>
                <Grid container justifyContent='space-between' spacing={2} sx={{ paddingTop: '10px', paddingBottom: '10px' }}>
                    <Grid item xs={3}>
                        <Input value={station.code} onChange={v => changeStation({ code: v })} title={i18n.code} noSpace />
                    </Grid>
                    <Grid container item xs={9}>
                        {(isNotValid) ? (
                            <Grid container justifyContent='space-between' alignItems='center' spacing={2}>
                                <Grid item xs={1}>
                                    <Icon icon='warning' style={{ color: 'orange' }} />
                                </Grid>
                                <Grid item xs={11}>
                                    <h6>{i18n.invalidHydroCode}</h6>
                                </Grid>
                            </Grid>
                        ) : (hasValue(station.code) && <StationImportCodeMessage code={station.code} stationType='hydrometry' />)}
                    </Grid>
                </Grid>
                <Grid container justifyContent='space-between' alignItems='center' spacing={2} sx={{ paddingBottom: '10px' }}>
                    <Grid item xs={6}>
                        <Select
                            value={jobDataTypes.find(d => integrationModes.includes(d))}
                            options={getIntegrationModes()}
                            label={i18n.dataIntegration}
                            labelSpan={integrationModeHelpIcon()}
                            onChange={onChangeDataIntegration}
                        />
                    </Grid>
                    <Grid item>
                        <Checkbox
                            checked={jobDataTypes.find(d => d.includes('withPred'))}
                            label={`${i18n.importPrevData} Vigicrues`}
                            onChange={() => onChangeDataTypes('withPred')}
                        />
                    </Grid>
                </Grid>
                <Grid container justifyContent='space-between' alignItems='center' spacing={2} sx={{ paddingBottom: '10px' }}>
                    <Grid item>
                        <Checkbox
                            checked={jobDataTypes.find(d => d.includes('heightIntegration'))}
                            label={i18n.heightObs}
                            onChange={() => onChangeDataTypes('heightIntegration')}
                        />
                    </Grid>
                    <Grid item>
                        <Checkbox
                            checked={jobDataTypes.find(d => d.includes('debitIntegration'))}
                            label={i18n.debitObs}
                            onChange={() => onChangeDataTypes('debitIntegration')}
                        />
                    </Grid>
                    <Grid item>
                        <Checkbox
                            checked={jobDataTypes.find(d => d.includes('withEvents'))}
                            label={i18n.importEvents}
                            onChange={() => onChangeDataTypes('withEvents')}
                        />
                    </Grid>
                    <Grid item>
                        <Checkbox
                            checked={jobDataTypes.find(d => d.includes('withThresholds'))}
                            label={i18n.importThresholds}
                            onChange={() => onChangeDataTypes('withThresholds')}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </StyledFieldSet>
    )
}

AddHydroStepOptionsHP.propTypes = {
    station: PropTypes.instanceOf(DtoHydrometricStation),
    changeStation: PropTypes.func,
    jobDataTypes: PropTypes.arrayOf(PropTypes.string),
    setJobDataTypes: PropTypes.func,
}

export default AddHydroStepOptionsHP