/* eslint-disable camelcase */
import { Button, Grid, Icon } from '@mui/material'
import { styled } from '@mui/styles'
import SettingDto from 'administration/dto/SettingDto'
import AgriAction from 'agriAdministration/actions/AgriAction'
import ProgressBar from 'components/progress/ProgressBar'
import { push } from 'connected-react-router'
import DtoAgriTanksType from 'exploitations/dto/DtoAgriTanksType'
import { compact, groupBy, keys, orderBy, uniqBy } from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import DtoDeclaration from 'survey/dto/DtoDeclaration'
import DtoSurvey from 'survey/dto/DtoSurvey'
import DtoTankDeclaration from 'survey/dto/DtoTankDeclaration'
import { getSetting, getSettingInt } from 'utils/SettingUtils'
import { formatMilliers } from 'utils/StringUtil'
import ModalNothingDeclared from '../modals/ModalNothingDeclared'
import ModalSynthese from '../modals/ModalSynthese'
import Textarea from 'components/forms/Textarea'
import ModalWarnNoConso from '../modals/ModalWarnNoConso'
import { CHRONICLES_CONSTANTS, POINT_STATUS_DECLARATION, SURVEY_TYPES } from 'agriAdministration/constants/AgriConstants'
import moment from 'moment'
import { hasValue } from 'utils/NumberUtil'
import DtoVariousMateriel from 'station/dto/materiel/DtoVariousMateriel'
import DtoExploitation from 'exploitations/dto/DtoExploitation'
import DtoInstallationWithGeoItem from 'installation/components/installations/dto/DtoInstallationWithGeoItem'

const ButtonStep = styled(Button)({
    width: '100%',
})

class DeclarationRecapStep extends Component {
    constructor(props) {
        super(props)
        this.state = {
            openModal: false,
            openModalNothingDeclared: false,
            usedPoints: [],
            totalVolumeAsked: 0,
            totalTanks: 0,
            declaration: props.declaration,
            instsWithoutIndex: [],
            volumesPoints: [],
        }
    }

    componentDidMount() {
        const { declaration, agriTanksTypes, applicationSettings, survey } = this.props
        const minVolumeEligibilityTank = getSettingInt(applicationSettings, 'minVolumeEligibilityTank')
        let totalVolumeAsked = 0
        let nbUsagesReal = 0
        let nbUsagesPrevi = 0
        let totalTanks = 0
        this.props.calculSurveyExploitationVolumes(survey.idSurvey, declaration.idExploitation).then(() => {
            const idsTanks = []
            const volumesPoints = declaration.link_declarationInstallation.map((point) => {
                nbUsagesReal = nbUsagesReal + (point?.link_usagesCurrent?.length || 0)
                nbUsagesPrevi = nbUsagesPrevi + (point?.link_usages?.length || 0)
                const pointExpoit = declaration?.link_exploitationInstallation?.find((p) => p.idInstallation === point.idInstallation) || {}
                if (point.stateCode === 1) {
                    const totalTanksPoint = (pointExpoit.link_sampleTanks || []).map((retenue) => {
                        if (!idsTanks.includes(retenue.idTank)) {
                            const updatedRetenue = (point.link_sampleTanks || []).find((o) => o.idTank === retenue.idTank)
                            const retenueDecla = updatedRetenue ? {
                                ...retenue,
                                ...new DtoTankDeclaration(updatedRetenue),
                            } : retenue
                            const type = agriTanksTypes.find((t) => t.id === retenueDecla.tankType) || {}
                            idsTanks.push(retenue.idTank)
                            return type.eligibility && retenueDecla.capacity >= minVolumeEligibilityTank ? (retenueDecla.capacity / 100 * (type.percent || 100)) : 0
                        }
                        return 0
                    }).reduce((acc, v) => acc + v, 0)
                    totalTanks = totalTanks + totalTanksPoint
                }
                const volumesAsked = point.link_usages.reduce((acc, usage) => acc + (isNaN(parseInt(usage.requestedYearVolume)) ? 0 : usage.requestedYearVolume), 0)
                const lowWaterVolumeAsked = point.link_usages.reduce((acc, usage) => acc + (isNaN(parseInt(usage.lowWaterVolume)) ? 0 : usage.lowWaterVolume), 0)
                totalVolumeAsked = totalVolumeAsked + volumesAsked
                return { id: point.idInstallation, volumesAsked, lowWaterVolumeAsked, nbUsages: point.link_usages.length }
            })
            const usedPoints = declaration.link_declarationInstallation.filter((p) => p.stateCode === 1)
            if (usedPoints.length === 0 && totalVolumeAsked === 0 && getSettingInt(survey.link_params, 'step3') === 1) {
                this.setState({ openModalNothingDeclared: true })
            }
            this.setState({ usedPoints, totalVolumeAsked, dataLoaded: true, nbUsagesReal, nbUsagesPrevi, totalTanks, volumesPoints })
        })
    }

    toggleModal = () => {
        this.setState(({ openModal }) => ({ openModal: !openModal }))
    }

    getMateriels = (idInstallation) => {
        const { declaration, variousMateriels, exploitation } = this.props
        const link_equipments = declaration.link_declarationInstallation.find((p) => p.idInstallation === idInstallation).link_equipments || []
        const newMats = link_equipments.filter((mLink) => !mLink.idMatAttachment && mLink.mode !== 'd')
        const deletedMats = link_equipments.filter((mLink) => !mLink.idMatAttachment && mLink.mode === 'd')
        const deletedCounters = link_equipments.filter((mLink) => mLink.idMatAttachment && mLink.mode === 'd')
        const exploitationPumps = declaration.link_exploitationMateriel.filter((m) => m.siteType === 7 &&
            m.siteCode === idInstallation &&
            !deletedMats.find((mLink) => m.idVarious === mLink.idMat)
        ) || []
        const mergedPumpsIds = uniqBy([
            ...exploitationPumps.map((m) => m.idVarious),
            ...newMats.map((m) => m.idMat),
        ], obj => obj)
        const exploitationCountersId = compact(mergedPumpsIds.flatMap((pumpId) => {
            const pumpSitu = declaration.link_exploitationMateriel.filter((situ) => situ.siteType === 8 &&
                situ.siteCode === pumpId &&
                !deletedCounters.find((mLink) => pumpId === mLink.idMat)
            )
            if (pumpSitu) {
                return pumpSitu.map((s) => s.idVarious)
            }
            return null
        }))
        const newCounters = link_equipments.filter((mLink) => mLink.idMatAttachment && mLink.mode !== 'd' && mergedPumpsIds.includes(mLink.idMat))
        const mergedCountersIds = uniqBy([
            ...exploitationCountersId,
            ...newCounters.map((m) => m.idMatAttachment),
        ], obj => obj)
        const materiels = variousMateriels.filter((m) => [...mergedPumpsIds, ...mergedCountersIds].includes(m.id) &&
            (!hasValue(m.administrator) || m.administrator === exploitation.operatorCode)
        )
        return materiels
    }

    onClickValidateDeclaration = () => {
        const { readMode, survey } = this.props
        const { declaration } = this.state
        if (readMode) {
            this.props.push(`/survey/${survey.idSurvey}`)
        } else {
            const instsToCheck = declaration.link_declarationInstallation.filter((i) => [1, 3].includes(i.stateCode))
            const instsWithoutIndex = compact(instsToCheck.map((i) => {
                if (!i.link_usagesCurrent?.length) {
                    return i.idInstallation
                }
                const materiels = this.getMateriels(i.idInstallation)
                const matsIds = materiels.map((m) => m.id)
                const chroniclesByMat = groupBy(declaration.link_chronicles.filter((c) => matsIds.includes(c.idMat)), 'idMat')
                const indexsFoundForYear = compact(keys(chroniclesByMat).map((matId) => {
                    const lastIndex = orderBy(chroniclesByMat[matId], 'measureDate', 'desc')[0]
                    if (lastIndex && moment(lastIndex.measureDate).year() >= (survey.surveyType === SURVEY_TYPES.INTERMEDIATE ? survey.year : survey.year - 1)) {
                        return true
                    }
                    return undefined
                }))
                if (indexsFoundForYear.length === 0) {
                    return i.idInstallation
                }
                return undefined
            }))
            if (instsWithoutIndex.length && getSettingInt(survey.link_params, 'consos') === 1 && getSettingInt(survey.link_params, 'step3') === 1) {
                this.setState({ openModalNoConso: true, instsWithoutIndex })
            } else {
                this.validateDeclaration()
            }
        }
    }

    getStatusLabel = (code) => {
        switch (code) {
            case POINT_STATUS_DECLARATION.USED:
                return i18n.used
            case POINT_STATUS_DECLARATION.REMOVED:
                return i18n.shut
            case POINT_STATUS_DECLARATION.ADJOURNED:
                return i18n.adjourned
            default:
                return i18n.unknown
        }
    }

    validateDeclaration = () => {
        const { declaration } = this.state
        this.props.updateDeclaration({ ...declaration, lastStep: 4, statusCode: 3 }).then(() => {
            this.toggleModal()
        })
    }

    onComment = (value) => {
        const { declaration } = this.state
        const updateDeclaration = {
            ...declaration,
            comment: value,
        }
        this.setState({ declaration: updateDeclaration })
    }

    getEstimConso = (materielsIds, linkPoint) => {
        const { survey, exploitation } = this.props
        const yearReal = survey.surveyType === SURVEY_TYPES.INTERMEDIATE ? survey.year : survey.year - 1
        const chroniclesMat = exploitation.link_chronicles.filter((c) => c.measureType === CHRONICLES_CONSTANTS.TYPE_ESTIM
            && materielsIds.includes(c.idMat)
            && moment(c.measureDate).year() === yearReal
            && (!linkPoint.startDate || c.measureDate >= linkPoint.startDate)
            && (!linkPoint.endDate || c.measureDate < linkPoint.endDate))
        const groupedByMat = Object.values(groupBy(chroniclesMat, 'idMat'))
        return groupedByMat.map((chroniques) => {
            return chroniques.reduce((acc, c) => acc + c.value, 0) || 0
        }).reduce((acc, c) => acc + c, 0)
    }

    render() {
        const { openModal, openModalNothingDeclared, dataLoaded, declaration, openModalNoConso, instsWithoutIndex, volumesPoints } = this.state
        const { survey, readMode, installationsWithGeo, exploitation, surveyVolumesExploitation } = this.props
        const date = survey.endDate ? new Date(survey.endDate).toLocaleString() : ''

        if (!dataLoaded) {
            return <ProgressBar indeterminate />
        }
        return (
            <Grid
                item
                container
                direction='column'
                justifyContent='space-between'
                alignItems='stretch'
                style={{ paddingBottom: 60, backgroundColor: 'white' }}
            >
                <Grid item container alignItems='center' className='padding-1' style={{ overflowY: 'auto' }}>
                    <Grid item container className='bold' style={{ borderTop: '1px solid black' }}>
                        <Grid item xs={1.5} className='padding-1' style={{ borderLeft: '1px solid black', borderBottom: '1px solid black' }}>
                            {i18n.equipmentsNum}
                        </Grid>
                        <Grid item xs={1.5} className='padding-1' style={{ borderLeft: '1px solid black', borderBottom: '1px solid black' }}>
                            {i18n.samplingPointNum}
                        </Grid>
                        <Grid item xs={2} className='padding-1' style={{ borderLeft: '1px solid black', borderBottom: '1px solid black', borderRight: '1px solid black' }}>
                            {i18n.pointLocation}
                        </Grid>
                        <Grid item xs={7} container style={{ borderRight: '1px solid black' }}>
                            <Grid item xs={12/7*3} className='padding-1' style={{ borderBottom: '1px solid black' }}>
                                {i18n.consumptionReport}
                            </Grid>
                            <Grid item xs={12/7*4} className='padding-1' style={{ borderLeft: '1px solid black', borderBottom: '1px solid black' }}>
                                {i18n.estimated}
                            </Grid>
                            <Grid item xs={12/7} className='padding-1' style={{ borderBottom: '1px solid black' }}>
                                {i18n.estimatedVolume}
                            </Grid>
                            <Grid item xs={12/7} className='padding-1' style={{ borderLeft: '1px solid black', borderBottom: '1px solid black' }}>
                                {i18n.yearly}
                            </Grid>
                            <Grid item xs={12/7} className='padding-1' style={{ borderLeft: '1px solid black', borderBottom: '1px solid black' }}>
                                {i18n.lowWater}
                            </Grid>
                            <Grid item xs={12/7} className='padding-1' style={{ borderLeft: '1px solid black', borderBottom: '1px solid black' }}>
                                {i18n.pointStatus}
                            </Grid>
                            <Grid item xs={12/7} className='padding-1' style={{ borderLeft: '1px solid black', borderBottom: '1px solid black' }}>
                                {i18n.usesNumber}
                            </Grid>
                            <Grid item xs={12/7} className='padding-1' style={{ borderLeft: '1px solid black', borderBottom: '1px solid black' }}>
                                {i18n.yearVolume}
                            </Grid>
                            <Grid item xs={12/7} className='padding-1' style={{ borderLeft: '1px solid black', borderBottom: '1px solid black' }}>
                                {i18n.volumeLowWater}
                            </Grid>
                        </Grid>
                    </Grid>
                    {declaration.link_declarationInstallation.map((dI) => {
                        const inst = installationsWithGeo.find((i) => i.id === dI.idInstallation) || {}
                        const volumesConso = surveyVolumesExploitation.find((v) => v.idInstallation === dI.idInstallation) || {}
                        const volumes = volumesPoints.find((v) => v.id === dI.idInstallation) || {}
                        const materiels = this.getMateriels(dI.idInstallation)
                        const linkPoint = exploitation.link_samplings.find((l) => l.idInstallation === dI.idInstallation) || {}
                        const estimConso = this.getEstimConso(materiels.map((m) => m.id), linkPoint)
                        return (
                            <Grid item container>
                                <Grid item xs={1.5} className='padding-1' style={{ borderLeft: '1px solid black', borderBottom: '1px solid black' }}>
                                    {materiels.map((m) => m.reference).join(', ')}
                                </Grid>
                                <Grid item xs={1.5} className='padding-1' style={{ borderLeft: '1px solid black', borderBottom: '1px solid black' }}>
                                    {inst.code}
                                </Grid>
                                <Grid item xs={2} className='padding-1' style={{ borderLeft: '1px solid black', borderBottom: '1px solid black', borderRight: '1px solid black' }}>
                                    {inst.location}
                                </Grid>
                                <Grid item xs={1} className='padding-1' style={{ borderBottom: '1px solid black' }}>
                                    {formatMilliers(estimConso)}
                                </Grid>
                                <Grid item xs={1} className='padding-1' style={{ borderLeft: '1px solid black', borderBottom: '1px solid black' }}>
                                    {formatMilliers(volumesConso?.total)}
                                </Grid>
                                <Grid item xs={1} className='padding-1' style={{ borderLeft: '1px solid black', borderBottom: '1px solid black' }}>
                                    {formatMilliers(volumesConso?.lowWater)}
                                </Grid>
                                <Grid item xs={1} className='padding-1' style={{ borderLeft: '1px solid black', borderBottom: '1px solid black' }}>
                                    {this.getStatusLabel(dI.stateCode)}
                                </Grid>
                                <Grid item xs={1} className='padding-1' style={{ borderLeft: '1px solid black', borderBottom: '1px solid black' }}>
                                    {volumes.nbUsages}
                                </Grid>
                                <Grid item xs={1} className='padding-1' style={{ borderLeft: '1px solid black', borderBottom: '1px solid black' }}>
                                    {formatMilliers(volumes.volumesAsked)}
                                </Grid>
                                <Grid item xs={1} className='padding-1' style={{ borderLeft: '1px solid black', borderBottom: '1px solid black', borderRight: '1px solid black' }}>
                                    {formatMilliers(volumes.lowWaterVolumeAsked)}
                                </Grid>
                            </Grid>
                        )
                    })}
                    {(getSetting(survey.link_params, 'step3') || '1') !== '0' && (
                        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', marginTop: 8 }}>
                            <Button variant='contained' color='primary' onClick={() => this.props.onGetBack(3)} disabled={readMode}>
                                {i18n.change}
                            </Button>
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <p style={{ textAlign: 'center' }}>
                            Cliquez ci-dessous pour enregistrer votre déclaration. Elle reste modifiable jusqu'au {date}.
                        </p>
                    </Grid>
                    <Textarea
                        col={12}
                        id='comment'
                        title={i18n.comment}
                        value={declaration.comment}
                        onChange={(v) => this.onComment(v)}
                        style={{ minHeight: '35%' }}
                        rows={10}
                        disabled={readMode}
                    />
                    {openModal && (
                        <ModalSynthese
                            open={openModal}
                            toggleModal={() => this.setState({ openModal: false })}
                            push={() => this.props.push(`/survey/${survey.idSurvey}`)}
                        />
                    )}
                    {openModalNothingDeclared && (
                        <ModalNothingDeclared
                            open={openModalNothingDeclared}
                            toggleModal={() => this.setState({ openModalNothingDeclared: false })}
                            push={() => this.props.onGetBack(3)}
                        />
                    )}
                    {openModalNoConso && (
                        <ModalWarnNoConso
                            open={openModalNoConso}
                            toggleModal={() => this.setState({ openModalNoConso: false })}
                            onValidateDeclaration={this.validateDeclaration}
                            instsWithoutIndex={instsWithoutIndex}
                        />
                    )}
                </Grid>
                <Grid
                    item
                    container
                    direction='row'
                    justifyContent='space-around'
                    alignItems='center'
                    style={{
                        position: 'fixed',
                        bottom: 0,
                        height: 60,
                        backgroundColor: 'white',
                        borderTop: '1px solid grey',
                        width: 'calc(100% - 60px)',
                    }}
                >
                    <Grid item xs={2}>
                        <ButtonStep variant='contained' color='primary' onClick={this.props.previousStep}>
                            <Icon>keyboard_arrow_left</Icon>
                        </ButtonStep>
                    </Grid>
                    <Grid item xs={2} />
                    <Grid item xs={2}>
                        <ButtonStep variant='contained' color='primary' onClick={this.onClickValidateDeclaration}>
                            {i18n.validate}
                        </ButtonStep>
                    </Grid>
                </Grid>
            </Grid>
        )
    }
}

DeclarationRecapStep.propTypes = {
    toggleEdit: PropTypes.func,
    updateDeclaration: PropTypes.func,
    calculSurveyExploitationVolumes: PropTypes.func,
    push: PropTypes.func,
    onGetBack: PropTypes.func,
    previousStep: PropTypes.func,
    readMode: PropTypes.bool,
    declaration: PropTypes.instanceOf(DtoDeclaration).isRequired,
    classes: PropTypes.instanceOf(PropTypes.shape({})),
    survey: PropTypes.instanceOf(DtoSurvey),
    agriTanksTypes: PropTypes.arrayOf(PropTypes.instanceOf(DtoAgriTanksType)),
    applicationSettings: PropTypes.arrayOf(PropTypes.instanceOf(SettingDto)),
    variousMateriels: PropTypes.arrayOf(PropTypes.instanceOf(DtoVariousMateriel)),
    exploitation: PropTypes.instanceOf(DtoExploitation),
    installationsWithGeo: PropTypes.arrayOf(PropTypes.instanceOf(DtoInstallationWithGeoItem)),
    surveyVolumesExploitation: PropTypes.arrayOf(PropTypes.shape({})),
}

const mapStateToProps = (store) => {
    return {
        survey: store.AgriReducer.survey,
        agriTanksTypes: store.AgriReducer.agriTanksTypes,
        applicationSettings: store.AdministrationReducer.applicationSettings,
        variousMateriels: store.VariousMaterielReducer.variousMateriels,
        exploitation: store.AgriReducer.exploitation,
        surveyVolumesExploitation: store.AgriReducer.surveyVolumesExploitation,
        installationsWithGeo: store.InstallationReducer.installationsWithGeo,
    }
}

const mapDispatchToProps = {
    updateDeclaration: AgriAction.updateDeclaration,
    calculSurveyExploitationVolumes: AgriAction.calculSurveyExploitationVolumes,
    push,
}

export default connect(mapStateToProps, mapDispatchToProps)(DeclarationRecapStep)
