import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import HomeAction from '../../../home/actions/HomeAction'
import Select from '../../../components/forms/Select'
import Card from '../../../components/card/Card'
import ExportAction from '../../../export/actions/ExportAction'
import SelectStationModal from '../../../components/modal/SelectStationModal'
import ToastrAction from 'toastr/actions/ToastrAction'
import WaitAction from 'wait/WaitAction'
import { getDateExport, getFullDate, shortenHumanize } from '../../../utils/DateUtil'
import {
    CSV_EXPORT,
    EXCEL_EXPORT,
    EXPORT_JOB_STATUS,
    EXPORT_STATIONS_MEASURES,
    EXPORT_STATIONS_MODELS,
    EXPORT_TYPE_OPTIONS,
    MODEL_EXPORT,
} from '../../../export/constants/ExportConstants'
import StationsModelExportPanel from '../../../station/components/StationsModelExportPanel'
import StationsCSVExportPanel from '../../../station/components/StationsCSVExportPanel'
import StationsExcelExportPanel from '../../../station/components/StationsExcelExportPanel'
import Table from '../../../components/datatable/Table'
import StationAction from '../../../station/actions/StationAction'
import DtoExportJob from '../../../station/dto/DtoExportJob'
import { orderBy, pick } from 'lodash'
import { getLogin } from '../../../utils/SettingUtils'
import { getCurrentLang } from '../../../utils/LangUtils'
import { nbPerPageLabelTiny } from '../../../referencial/constants/ReferencialConstants'
import { getDuration, getLoadingBar, getStatusIcon, getStatusLabel } from '../../../utils/ExportJobUtils'
import moment from 'moment'
import { nFormatter } from '../../../utils/NumberUtil'
import { componentHasHabilitations } from '../../../utils/HabilitationUtil'
import { H_QUALITO_EXPORT } from '../../../account/constants/AccessRulesConstants'
import { push } from 'connected-react-router'
import DtoQualitometer from '../../dto/DtoQualitometer'
import AppStore from '../../../store/AppStore'
import QualityAction from '../../actions/QualityAction'
import { STATION_TYPE_NAME } from '../../../station/constants/StationConstants'


class QualitometersExportApp extends Component {
    isCheckingProgress = false

    state = {
        exportType: null,
        model: 0,
        modelStartDate: null,
        modelEndDate: null,
        tmpFilter: {
            startDate: null,
            endDate: null,
        },
        selectStationIsOpen: false,
        selectedStations: [],
    }

    setTitle = () => {
        this.props.setTitle([{
            title: i18n.quality,
            href: 'quality',
        }, {
            title: i18n.export,
            href: 'quality/export',
        }])
    }

    recursiveFetchExportJob = () => {
        if (!this.isCheckingProgress) {
            return
        }
        this.props.fetchExportJobsTmp('quality').then(response => {
            if (response.some(e => e.status === EXPORT_JOB_STATUS.WAITING || e.status === EXPORT_JOB_STATUS.IN_PROGRESS)) {
                setTimeout(() => this.recursiveFetchExportJob(), 5000)
            } else {
                this.isCheckingProgress = false
            }
        })
    }

    regularProgressUpdate = () => {
        this.isCheckingProgress = true
        this.recursiveFetchExportJob()
    }

    componentDidMount() {
        if (!componentHasHabilitations(H_QUALITO_EXPORT)) { // A modifier quand react-router sera à jour
            this.props.push('/unauthorized')
            return
        }
        if (!this.props.qualitometers.length) {
            AppStore.dispatch(QualityAction.fetchQualitometersLight())
        }
        this.setTitle()
        this.props.fetchEnvironmentModelsByType('quality')
        this.regularProgressUpdate()
    }

    componentWillUnmount() {
        this.isCheckingProgress = false
    }

    onChangeFilter = (value) => {
        this.setState(({ tmpFilter }) => ({ tmpFilter: { ...tmpFilter, ...value } }))
    }

    onChange = (value) => {
        this.setState(value)
    }

    onCloseModal = () => {
        this.setState({ selectStationIsOpen: false })
    }

    onApplyExport = (type) => {
        const { tmpFilter, selectedStations } = this.state
        const { waitStart, waitStop } = this.props
        waitStart()
        const exportData = {
            exportData: selectedStations.map(h => ({
                station: pick({ ...h, stationType: h.typeName }, ['id', 'code', 'name', 'stationType']),
                exportType: type,
                filename: `${h.code}_${getDateExport()}`,
            })),
            startDate: tmpFilter.startDate,
            endDate: tmpFilter.endDate,
            dataTypes: [{ id: 0, name: 'analysis' }],
        }
        if (exportData.exportData.length === 0) {
            this.props.toastrWarning(i18n.noDataOverSelectedStationsAndPeriod)
            waitStop()
            return
        }
        const exportTmpObj = {
            id: 0,
            exportType: EXPORT_STATIONS_MEASURES,
            login: getLogin(),
            creationDate: 0,
            status: EXPORT_JOB_STATUS.WAITING,
            stationType: 'quality',
            params: JSON.stringify(exportData),
        }
        this.props.runExportTmp(exportTmpObj).then(() => {
            if (!this.isCheckingProgress) {
                this.regularProgressUpdate()
            }
        })
        waitStop()
    }
    onApplyExportModel = (modelFileName) => {
        const { selectedStations } = this.state
        const { waitStart, waitStop } = this.props
        const fileNameSplit = modelFileName.split('.')
        const type = fileNameSplit[fileNameSplit.length - 1]
        waitStart()
        const exportData = {
            exportData: selectedStations.map(h => ({
                station: pick({ ...h, stationType: h.typeName }, ['id', 'code', 'name', 'stationType']),
                exportType: type,
                filename: `${h.code}_${getDateExport()}`,
            })),
            model: modelFileName,
            startDate: this.state.modelStartDate,
            endDate: this.state.modelEndDate,
        }

        if (exportData.exportData.length === 0) {
            this.props.toastrWarning(i18n.noDataOverSelectedStationsAndModel)
            waitStop()
            return
        }
        const exportTmpObj = {
            id: 0,
            exportType: EXPORT_STATIONS_MODELS,
            login: getLogin(),
            creationDate: 0,
            status: EXPORT_JOB_STATUS.WAITING,
            stationType: 'quality',
            params: JSON.stringify(exportData),
        }
        this.props.runExportTmp(exportTmpObj).then(() => {
            if (!this.isCheckingProgress) {
                this.regularProgressUpdate()
            }
        })
        waitStop()
    }
    getExportPanel = () => {
        const { exportType, tmpFilter, selectedStations, model, modelStartDate, modelEndDate } = this.state
        const { typeEnvironmentModels } = this.props
        const modelTypesOptions = typeEnvironmentModels.filter(t => !t.includes('.xls') || !t.includes('.doc')).map((t, i) => ({ value: i, name: t }))
        switch (exportType) {
            case MODEL_EXPORT:
                return (
                    <StationsModelExportPanel
                        onApplyExportModel = {this.onApplyExportModel}
                        selectedStations={selectedStations}
                        model={model}
                        modelTypesOptions={modelTypesOptions}
                        onChange={this.onChange}
                        startDate={modelStartDate}
                        endDate={modelEndDate}
                    />
                )
            case CSV_EXPORT:
                return (
                    <StationsCSVExportPanel
                        onChangeFilter={this.onChangeFilter}
                        onChange={this.onChange}
                        onApplyExport={this.onApplyExport}
                        selectedStations={selectedStations}
                        tmpFilter={tmpFilter}
                        dataTypes={[{ id: 0, name: i18n.analysis }]}
                        selectedDataType={0}
                    />
                )
            case EXCEL_EXPORT:
                return (
                    <StationsExcelExportPanel
                        onChangeFilter={this.onChangeFilter}
                        onChange={this.onChange}
                        onApplyExport={this.onApplyExport}
                        selectedStations={selectedStations}
                        tmpFilter={tmpFilter}
                        dataTypes={[{ id: 0, name: i18n.analysis }]}
                        selectedDataType={0}
                        stationType={STATION_TYPE_NAME.qualitometer}
                    />
                )
            default:
                return null
        }
    }

    render() {
        const data = orderBy(this.props.exportJobsTmp, 'updateDate', 'desc').map(e => ({
            ...e,
            status: { color: 'white', value: getStatusIcon(e.status, 50), setTooltip: () => (<div className='row no-margin valign-wrapper'>{getStatusIcon(e.status, 20)}<div className='padding-left-1'/>{getStatusLabel(e.status)}</div>) },
            type: { value: e.fileType },
            creationDate: { value: getFullDate(e.creationDate), format: 'dd/MM/yyyy HH:mm:ss', cellType: 'date' },
            updateDate: { value: getFullDate(e.updateDate), format: 'dd/MM/yyyy HH:mm:ss', cellType: 'date' },
            author: { value: e.login },
            exportName: { value: i18n[e.exportType] },
            fileLabel: { value: e.filePath },
            progression: { value: getLoadingBar(e.status, e.progress, e.filePath) },
            size: { value: nFormatter(e.fileSize) },
            duration: { value: e.status === EXPORT_JOB_STATUS.FINISHED || e.status === EXPORT_JOB_STATUS.ERROR ? shortenHumanize((moment.utc(moment(e.updateDate).diff(moment(e.creationDate))).valueOf() || 0), { language: getCurrentLang()[0] }) : getDuration(e.creationDate) },
        }))
        return (
            <div className='row no-margin padding-1'>
                <Card title={ i18n.export }>
                    <div className='card-content'>
                        <div className='row valign-wrapper padding-top-1 no-margin'>
                            <div className='col s12'>
                                <Select
                                    col={3}
                                    label={i18n.exportName}
                                    options={EXPORT_TYPE_OPTIONS}
                                    onChange={v => this.setState({ exportType: v })}
                                    value={this.state.exportType}
                                />
                            </div>
                        </div>
                        { this.getExportPanel() }
                        <SelectStationModal
                            onClose={ this.onCloseModal }
                            selectStationIsOpen={ this.state.selectStationIsOpen }
                            selectedStations={this.state.selectedStations}
                            stationType='quality'
                            onChangeSelectedStation={tmpStations => this.setState({ selectedStations: tmpStations })}
                        />
                    </div>
                </Card>
                <Table
                    title={ i18n.exportsInProgress }
                    data={data}
                    type={ { headers: ['status', 'fileLabel', 'duration', 'size', 'creationDate', 'updateDate', 'exportName', 'progression'] } }
                    color
                    sortable
                    paging
                    nbPerPageLabel={ nbPerPageLabelTiny }
                />
            </div>
        )
    }
}

QualitometersExportApp.propTypes = ({
    qualitometers: PropTypes.arrayOf(PropTypes.instanceOf(DtoQualitometer)),
    setTitle: PropTypes.func,
    fetchEnvironmentModelsByType: PropTypes.func,
    typeEnvironmentModels: PropTypes.arrayOf(PropTypes.string),
    exportModel: PropTypes.func,
    waitStart: PropTypes.func,
    waitStop: PropTypes.func,
    toastrWarning: PropTypes.func,
    exportJobsTmp: PropTypes.arrayOf(PropTypes.instanceOf(DtoExportJob)),
    fetchExportJobsTmp: PropTypes.func,
    runExportTmp: PropTypes.func,
    push: PropTypes.func,
})

const mapStateToProps = store => ({
    qualitometers: store.QualityReducer.qualitometersLight,
    typeEnvironmentModels: store.ExportReducer.typeEnvironmentModels,
    exportJobsTmp: store.StationReducer.exportJobsTmp,
})

const mapDispatchToProps = {
    setTitle: HomeAction.setTitle,
    fetchEnvironmentModelsByType: ExportAction.fetchEnvironmentModelsByType,
    exportModel: ExportAction.exportModel,
    toastrWarning: ToastrAction.warning,
    waitStart: WaitAction.waitStart,
    waitStop: WaitAction.waitStop,
    fetchExportJobsTmp: StationAction.fetchExportJobsTmp,
    runExportTmp: ExportAction.runExportTmp,
    push,
}

export default connect(mapStateToProps, mapDispatchToProps)(QualitometersExportApp)
